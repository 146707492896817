import React, {useState} from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Media from 'react-bootstrap/Media';

import LoadingAnimation from '../LoadingAnimation'
import LoadingAnimationMiniSize from '../LoadingAnimationMiniSize' 

import Button from 'react-bootstrap/Button';

import Modal from 'react-bootstrap/Modal'
import { Link } from 'react-router-dom';
import imgBigOportunity from '../../assets/img/ic-oportunidad-big.png'
import { createInteraction} from '../../api_communication/service/ClientService'
import {ShowPhotosAndDocsPreview} from '../../componentes/MediaManager'

// Fn backend

import { archiveInteraction } from '../../api_communication/service/ClientService'
import { listLostOpportunitiesForClient, listOpenOpportunitiesForClient, listWonOpportunitiesForClient } from '../../api_communication/service/OpportunityService'

import {truncateText, OpportunityIconState} from '../CommonScripts'

//Imagenes
import ImgOportunidad from '../../assets/img/ic-oportunidad-big.png';
import btnMas from '../../assets/img/btn-mas.png'
import filterBtn from '../../assets/img/ic-search.png'

import moment from 'moment'

// COMPONENTS
import FilesDragAndDrop from '../FilesDragAndDrop';


//MODAL PARA CREAR UNA NUEVA OPORTUNIDAD

function Example(props) {
    const [show, setShow] = useState(false);
    const [idCompany, setIdCompany] = useState(props.client.id)
    const [nameCompany, setNameCompany] = useState(props.client.alias)

    // States to save and preview docs and images
    const [preview, setPreview] = useState(false)
    const [imageOrDocToShow, setImageOrDocToShow] = useState(null)
    const [saveImagesAndDocs, setSaveImagesAndDocs] = useState(null)
    const [interaction, setInteraction] = useState(null)
    const [savingAnimation, setSavingAnimation] = useState(false)

    const createOpportunity = () => {

      var opportunityName = document.getElementById("opportunityName").value
      var opportunityDescription = document.getElementById("opportunityDescription").value
      if(opportunityName == ''){
        props.showUpSalesMessageFn('danger','Debe añadir un título para la oportunidad.', true, false)
        } else {
            if(idCompany == null){
            alert("Debe seleccionar un nombre de compañía existente")
            }
            setSavingAnimation(true)
            let interactionData = {
            date: moment().format("DD/MM/YYYY") + ' ' +  moment().format("HH:mm:ss"),
            content: 'Oportunidad Creada',
            mode: "text",
            opportunity:{
                name: opportunityName,
                description: opportunityDescription ? opportunityDescription : null,
                state: 'OPEN'
            }
            }
            // save interaction
            let savedInteraction = createInteraction(idCompany, interactionData)
            .catch(error => {
                console.log('ERROR CREATE OPPORTUNITY ERROR: ', error)
                throw error;
            }).then( async result => {
                //console.log('CREATE-OPPORTUNITY:  _SAVE-DATA: .then : result: ',result )
                setInteraction(result)
                setSaveImagesAndDocs(true)
                if(!imageOrDocToShow){
                    let arch = await archiveInteraction(result.companyId, result.id)
                    cancelForm()
                    props.showUpSalesMessageFn('success','Oportunidad creada. Refrescando...', true, true)
                    setTimeout(() => {
                        props.refresh()
                    }, 1500); 
                }
            })
        }
    }

    const callToShowImageOrDoc = e => {
        setImageOrDocToShow(e)
        setPreview(true)
    }

    const cancelForm =  () => {
        document.getElementById("companyName").value = ""
        setShow(false)
    }

    const uploadingFinished = (value)=>{
        if(value) {
            cancelForm()
            props.showUpSalesMessageFn('success','Oportunidad creada. Refrescando...', true, true)
            setTimeout(() => {
                props.refresh()
            }, 1500);
        } else {
            props.showUpSalesMessage('danger', 'Ha ocurrido un error guardando archivos.', true, false)
        }
        setSavingAnimation(false)
      }

    return (
      <React.Fragment>
        <Button className="modal-small-button pos-a d-center btn btn-primary z-index-10" variant="primary" onClick={() => setShow(true)}>
          +
        </Button>

        <Modal
          id="modalPositionRight"
          show={show}
          onHide={() => setShow(false)}
          dialogClassName="modal-dialog bg-white modal-dialog-centered"
          aria-labelledby="example-custom-modal-styling-title"
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header className="justify-content-center modal-border">
            <Modal.Title id="example-custom-modal-styling-title" className="dark-blue d-column">
              <b className="mb-4">
                CREAR UNA OPORTUNIDAD
              </b>
              <Media><img src={imgBigOportunity}></img></Media>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body >
            <form className="d-column generaltextInputStyle">
              <input  id="companyName" className="my-3" placeholder="Nombre Empresa"
                type="text"
                name='compañias'
                value={nameCompany}
                disabled
              >
              </input>

              <input type="text" className="my-3" id="opportunityName" placeholder="Nombre Oportunidad"></input>

              <textarea className="my-3" id="opportunityDescription" type="text"  placeholder="Descripción"></textarea>

              <div className='py-3'>
                <FilesDragAndDrop fileSelected={callToShowImageOrDoc}/>
            </div>
              
              {preview && imageOrDocToShow && <div id="preview" className="w-100 py-3">
                <ShowPhotosAndDocsPreview 
                    isItATask={false} 
                    imageOrDocToShow={imageOrDocToShow} 
                    saveImagesAndDocs={saveImagesAndDocs} 
                    idCompany={idCompany} 
                    idTaskOrInteraction={interaction ? interaction.id : null}
                    uploadingFinished={uploadingFinished}
                />
              </div>}
              
              <div className="d-inline-flex mt-5">
                  <div className="d-center boton-cancelar mr-3" onClick={cancelForm}>Cancelar</div>
                  <button disabled={savingAnimation ? true : false} className="d-center boton-guardar" onClick={createOpportunity}>
                    {savingAnimation && <span className="spinner-grow spinner-grow-sm mr-2" role="status" aria-hidden="true"></span>}
                    Guardar
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      </React.Fragment>
    );
  }

class OpportunityRow extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            image: null
        }
    }

    render(){
        return(
            <Row className="py-1 justify-content-center">
                <Col className="d-flex justify-content-center align-items-start" xs={4}>
                    <div className="max-ic-op">
                      <OpportunityIconState opportunity={this.props.data}/>
                    </div>
                </Col>
                <Col xs={8} className="justify-self-start align-self-center">
                    <Link to={{pathname:'/DetallesOportunidad',state: this.props.data}} className="text-start light-grey l-fs-14" title={this.props.data.name}>{truncateText(this.props.data.name,25)}</Link>
                </Col>
                <Col md={10} className="brd-b-blue pt-1"></Col>
            </Row>
        )
    }
}

class OportunityTable extends React.Component { // cada línea es un cliente
    constructor(props){
        super(props)
        this.refreshMe = this.props.refreshMe
        this.state ={
            filter: 'OPEN'
        }
    }

    componentDidUpdate(prevProps){
        if (this.props.oportunidades != prevProps.oportunidades)
        this.scrollToBottom()
    }

    scrollToBottom = ()=>{
        var objDiv = document.getElementById("accordion-scroll");
        objDiv.scrollTop = objDiv.scrollHeight;
   }

   refresh = ()=>{
       this.props.refreshMe()
   }

   showUpSalesMessage = (tipo,mensaje, muestra, cierra) =>{
       this.props.showUpSalesMessageFn(tipo,mensaje, muestra, cierra)
   }

   userSelectOpportunityFilter = (e) =>{
    this.setState(
        {filter: e.target.value}
    )
    this.openCloseBoxFilter()
    this.props.getOpportunityFilter(e.target.value)
   }

   openCloseBoxFilter = ()=>{
    let div = document.getElementById('opportunitiesBoxFilter')
    if (div.classList.contains('d-none')) {
        div.classList.remove('d-none')
    } else {
        div.classList.add('d-none')
    }
   }

    render(){
        const rows = [];
        this.props.oportunidades != null && this.props.oportunidades.forEach((oportunidad) => {
            rows.push(<OpportunityRow 
                data={oportunidad}
                indice={oportunidad.id}
                key={oportunidad.id}
                />
            );
        })
        return(
            <Row >
                <Col xs={12} className="bg-white pt-4 justify-content-center">
                    <Row className="align-items-center justify-content-center pos-r">
                        <Example showUpSalesMessageFn={this.showUpSalesMessage} client={this.props.compañia} refresh={this.refreshMe}/>
                        <div title="Filtrar oportunidades" style={{borderRadius: '50%', width: 40, backgroundColor: 'orange', borderColor: 'orange', height: 40, top: 0, right:65, cursor:'pointer'}} class="pos-a d-center  z-index-10 ">
                            <img onClick={this.openCloseBoxFilter} src={filterBtn}/>
                            <div id="opportunitiesBoxFilter" className='py-4 px-4 bg-white pos-a d-none' style={{top:45, right:'0px', boxShadow:'1px 1px 5px grey', borderRadius:4}}>
                                <p className='pb-3 light-grey'>Filtrar por:</p>
                                <select value={this.state.filter}  onChange={this.userSelectOpportunityFilter} className=" inputDynamicSelectOnlyDropdown l-fs-14" aria-label="Default select" style={{backgroundColor: 'white', width:160}}>
                                    <option id={'filter1'}  value={'OPEN'}>Abiertas</option>
                                    <option id={'filter2'}  value={'CLOSED-WON'}>Cerradas ganadas</option>
                                    <option id={'filter3'}  value={'CLOSED-LOST'}>Cerradas perdidas</option>
                                </select>
                            </div>
                        </div>
                        <Col className="d-center" md={4}>
                            <div className="pos-r">
                                <img className="max-h-70" src={ImgOportunidad}></img>
                            </div>
                            
                        </Col>
                        <Col className="dark-blue" md={8}>
                            <p className="mb-0 l-fs-16"><b>OPORTUNIDADES</b></p>
                            
                        </Col>
                        <Col className="brd-b-blue py-1" md={10}></Col>
                        
                    </Row>
                </Col>
                <Col xs={12} id="accordion-scroll" className="accordion-scroll pt-3">
                    {this.props.loadingOpportunities ? <Row className='py-5 d-center'><LoadingAnimationMiniSize /></Row> : <Row>
                        <Col>
                        {rows}
                        </Col>
                    </Row>}
                </Col>
            </Row>
        );
    }
}

export default class OportunidadDetallesClientes extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            listaClientes: [], // array con info q se pasa como prop a <OportunityTable>
            loadingAnimation: true, // if true se muestra animacion de carga al cargar página entera
            paginationCount: 0, // contador para llamar a backend (info paginada)
            pageSize: 10,
            loadingMoreCompanies: false, //if true muestra pequeño loading animation en lugar del btn +
            showBtnMoreCompanies: true, // si de backend no hay mas datos se oculta btn + 
            noMoreDataAvailable: false ,// if true muestra mensajes al usuario de que no hay mas info de backend por mostra
            filteredBy:'OPEN',
            loadingOpportunities:false
        };
    }

    async componentDidMount() {
        this.cargaInicialListaClientes(this.state.paginationCount)
    }

    refreshMe = async () => {
        this.props.refreshFn()
    }

    cargaInicialListaClientes = async () => {
        let listadoPaginado = []
        let listadoPaginadoSiguiente = []
        try {
            listadoPaginado = await listOpenOpportunitiesForClient(this.props.client.id, 0, this.state.pageSize)
            listadoPaginadoSiguiente = await listOpenOpportunitiesForClient(this.props.client.id, 1, this.state.pageSize)

            if (listadoPaginado.length === 0 || listadoPaginadoSiguiente.length === 0)
                this.setState({showBtnMoreCompanies: false, noMoreDataAvailable: true}) // quitamos btn + y mostramos mensajes
            
            this.setState({ 
                listaClientes: listadoPaginado,
                loadingAnimation: false,
            })
            
        } catch(error) {
            console.log(error)
        }
    }

    cargaListaClientes = async (paginationCount) => {
        let finalResult = []
        let listadoPaginado = []
        let listadoPaginadoSiguiente = []
        try {
            if (this.state.filteredBy === 'OPEN'){
                listadoPaginado = await listOpenOpportunitiesForClient(this.props.client.id, paginationCount, this.state.pageSize)
                listadoPaginadoSiguiente = await listOpenOpportunitiesForClient(this.props.client.id, paginationCount + 1, this.state.pageSize)
                //console.log('open: result: ', listadoPaginado)
            }
            if (this.state.filteredBy === 'CLOSED-WON'){
                listadoPaginado = await listWonOpportunitiesForClient(this.props.client.id, paginationCount, this.state.pageSize)
                listadoPaginadoSiguiente = await listWonOpportunitiesForClient(this.props.client.id, paginationCount + 1, this.state.pageSize)
                //console.log('closed-won: result: ', listadoPaginado)
            }
            if (this.state.filteredBy === 'CLOSED-LOST'){
                listadoPaginado = await listLostOpportunitiesForClient(this.props.client.id, paginationCount, this.state.pageSize)
                listadoPaginadoSiguiente = await listLostOpportunitiesForClient(this.props.client.id, paginationCount + 1, this.state.pageSize)
                //console.log('closed-lost: result: ', listadoPaginado)
            }

            let listadoInicial = this.state.listaClientes // siempre tendrá algo cuando entramos en esta función

            if (listadoPaginado !== null && listadoPaginado.length > 0) {
                finalResult = listadoInicial.concat(listadoPaginado) // 'sumamos' lista anterior con la nueva de backend
            }

            if (listadoPaginadoSiguiente.length === 0) {
                this.setState({showBtnMoreCompanies: false, noMoreDataAvailable: true})
            } else {
                this.setState({showBtnMoreCompanies: true, noMoreDataAvailable: false})
            }

            if (listadoPaginado === null) {
                finalResult = listadoInicial;
                this.setState({showBtnMoreCompanies: false, noMoreDataAvailable: true})
            }
            this.setState({
                listaClientes: finalResult, 
                loadingAnimation: false,
                loadingMoreCompanies:false,
                loadingOpportunities:false
            })
            
        } catch(error) {
            console.log(error)
        }
    }

    showUpSalesMessage = (tipo,mensaje, muestra, cierra)=> {
        this.props.showUpSalesMessageFn(tipo,mensaje, muestra, cierra)
    }

    moreCompanies = (e)=>{
        e.preventDefault();
        this.cargaListaClientes(this.state.paginationCount + 1)
        this.setState({paginationCount: this.state.paginationCount + 1, loadingMoreCompanies: true})
        
    }

    getOpportunityFilter = (opFilter)=>{
        this.setState({filteredBy:opFilter, listaClientes:[], loadingOpportunities: true, paginationCount: 0})
        setTimeout(() => {
            this.cargaListaClientes(0)
        }, 1000);
    }

    render(){
        if (this.state.loadingAnimation == false)
            return(
                <Row id="div" className="mt-3" style={{backgroundCcolor:'white'}}>
                    <Col>
                        <OportunityTable 
                            showUpSalesMessageFn={this.showUpSalesMessage} 
                            oportunidades={this.state.listaClientes} 
                            compañia={this.props.client} 
                            refreshMe={this.refreshMe}
                            getOpportunityFilter={this.getOpportunityFilter}
                            loadingOpportunities={this.state.loadingOpportunities}
                        />
                        {!this.state.loadingOpportunities && this.state.showBtnMoreCompanies && 
                        <Row>
                            <Col className="btn-mas-container flex-column">
                                {this.state.loadingMoreCompanies && <LoadingAnimationMiniSize></LoadingAnimationMiniSize> }
                                {!this.state.loadingMoreCompanies && <a href="#" onClick={this.moreCompanies}>
                                    <img src={btnMas}></img>
                                </a>}
                            </Col>
                        </Row>}
                        {!this.state.loadingOpportunities && this.state.noMoreDataAvailable && this.state.listaClientes && this.state.listaClientes.length > 0 &&
                        <Row>
                            <Col className="col-12 bg-white d-center pt-4 pb-3">
                                <p className="light-grey l-fs-14">No hay más oportunidades para mostrar...</p>
                            </Col>    
                        </Row>}
                        {(!this.state.loadingOpportunities && this.state.listaClientes.length === 0) &&
                        <Row>
                            <Col className="col-12 bg-white d-center pt-4 pb-3">
                                <p className="light-grey l-fs-14">Aún no hay oportunidades para mostrar...</p>
                            </Col>    
                        </Row>}
                    </Col>
                </Row>
            )
        else return <LoadingAnimation text={'Cargando datos...'}/>
    }
}