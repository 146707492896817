// --------------------------------------------
// Created by Pablo Collado in 03/12/2020
// --------------------------------------------

// FROM PARENT:
// <VentasActualVsAnteriorChart data={company object} year={year} /> 
// PROP data is the client/company object 
// PROP year = actual year

import React, {useState, useEffect} from 'react'
import {Line} from 'react-chartjs-2';

// BACKEND FUNCTIONS
import {listSalesForGraph} from '../api_communication/service/ClientService'

// COMPONENTS
import LoadingAnimationMiniSize from './LoadingAnimationMiniSize'

const configData = {
    labels: ['Enero', 'Febrero', 'Marzo',
             'Abril', 'Mayo', 'Junio', 'Julio',
            'Agosto', 'Septiembre', 'Octubre', 
            'Noviembre', 'Diciembre'],
    datasets: [
      {
        label: 'Ventas Año Actual',
        fill: false,
        lineTension: 0,
        backgroundColor: '#A0CBE7',
        borderColor: '#006AC1', 
        borderWidth: 2,
        data: null
      },
      {
        label: 'Ventas Año Anterior',
        fill: false,
        lineTension: 0,
        backgroundColor: '#7B98B0', //'#ED255F',
        borderColor: '#7B98B0', //'#ED255F', 
        borderWidth: 2,
        data: null
      },
    ]
  }

export default function VentasActualVsAnteriorChart(props) {
    const [loadingAnimation, setLoadingAnimation] = useState(true)
    const [chartData, setChartData] = useState(configData)
    const [showChart, setShowChart] = useState(true)
    const [reDraw, setReDraw] = useState(false)

    useEffect(()=>{
        setLoadingAnimation(true)
        setReDraw(false)
        let year = props.year
        let company = props.company
        async function initChart(){
            
            try{
              // INICIALIZAMOS VALORES GRÁFICA A CERO
              let ventasAñoActual= [0,0,0,0,0,0,0,0,0,0,0,0]
              let ventasAñoAnterior = [0,0,0,0,0,0,0,0,0,0,0,0,0]
              configData.datasets[0].data = ventasAñoActual
              configData.datasets[1].data = ventasAñoAnterior

              let actual = await listSalesForGraph(company.id, year)
              //console.log('CHART-COMP >>> listSalesForGraph: ', sales)
              let anterior = await listSalesForGraph(company.id, year -1)

              // Vamos modificando esos 0 por valor del mes. De backend puede ser que 
              // no exista objecto si no hay nada para ese mes
              actual.forEach((item) => {
                let month = (parseInt(item.date.split('/')[1])-1)
                ventasAñoActual[month] = Math.round(item.amount * 10) / 10
              })
              //console.log('CHART-COMP >>> ventasAñoActual: ', ventasAñoActual)
              anterior.forEach((item) => {
                let month = (parseInt(item.date.split('/')[1])-1)
                ventasAñoAnterior[month] = Math.round(item.amount * 10) / 10
              })
              //console.log('CHART-COMP >>> ventasAñoAnterior: ', ventasAñoAnterior)

              // PONEMOS INFO EN configData objeto presupuestos
              configData.datasets[0].data = ventasAñoActual
              configData.datasets[1].data = ventasAñoAnterior
              setChartData(configData)
              setLoadingAnimation(false)
              setReDraw(true)
              setShowChart(true)
            }catch(e){
              console.log(e)
            }
            
        }

        // INICIAMOS 
        if( props.company && props.year) {
          initChart()
        } 

      }, [props])

      
      return (
        <div style={{position: 'relative'}}>

            {loadingAnimation ?  <div style={{paddingRight: '20%'}}><LoadingAnimationMiniSize/></div> : <div className="py-2"></div>}
            <div>
              {showChart ? <Line
                data={chartData}
                redraw={reDraw}
                options={{
                    stacked: true,
                    title:{
                        display:false,
                        text:'texto del título',
                        fontSize:16
                    },
                    legend:{ // hace ref a la 'label' 
                        display:true,
                        position:'right'
                    },
                    scales: {
                      y: {
                        ticks: {
                          beginAtZero: true,
                          min: 0
                        }
                      }
                    },
                    plugins: {
                      datalabels: {
                        display: false
                      },
                      legend: {
                        position: 'top',
                        labels: {
                          padding: 15
                        }
                      },
                    }
                  
                }}
              /> : <p className="text-center light-grey l-fs-14">No hay datos</p>}
            </div> 
        </div>
      );
}