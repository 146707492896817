// Created by Pablo Collado 14 Jun2022
// --------------------------------------------
// <TaskTabList>  
//    opportunity={opportunity object}
//    project={project}
//    interaction={interaction}
//    client={client}
//    companyId={companyId} // for all but dashboard
//    entity={{type:'all/opportunity/project/...', id:X}} -> Object{type: string, id:integer}
//    loggedUser={loggerUser} - User data. To know user role
//    hideCreateTasksButton={true/false/null} - if true hide create task button
// </TaskTabList>
// --------------------------------------------
import React, { useState, useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Media from 'react-bootstrap/Media';
import { Link } from 'react-router-dom';

// COMPONENTS
import UpSalesMessage from '../UpSalesMessage';
import { ResolveTaskModal } from './ResolveTaskModal';
import { ScheduleTaskModal } from './ScheduleTaskModal';
import { isTaskOverdue } from '../../api_communication/service/TaskService';
import CreateTaskModal from './CreateTaskModal'
import ShowTaskModal from './ShowTaskModal';
import ShowArchivedTasks from './ShowArchivedTasks'
import LoadingAnimation from '../LoadingAnimation'
import BtnArchivar from '../BtnArchivar';
import LoadingAnimationMiniSize from '../LoadingAnimationMiniSize' 
import EditTaskModal from './EditTaskModal';

// COMMON SCRIPTS 
import {truncateText, InteractionModeIcon, OpportunityIconState} from '../CommonScripts'

// EXTERNAL LIBRARIES
import moment from 'moment';

// BACKEND FUNCTIONS
import { 
  listTasksInTimeIntervalFilteredByState,
  listTasksForAnOpportunity,
  listTasksForACompany,
  tasksForAnInteraction
} from '../../api_communication/service/ClientService';
import { addDueDateTask } from '../../api_communication/service/TaskService';
import {listTasksForAProject} from '../../api_communication/service/ProjectService'

// IMGS
import defaultLogo from '../../assets/img/logoPorDefecto.png'
import btnMas from '../../assets/img/btn-mas.png'
import reloj from '../../assets/img/ic-reloj-nrja.png'
import ProjectStateIcon from '../proyectos/ProjectStateIcon'

export const ClientInfo = (props) => {
    const [client, setClient] = useState(null)
    const [logo, setLogo] = useState(null)

    useEffect(() => {
        setClient(props.client)
        if (props.client.thumbnail === null){setLogo(defaultLogo)}
        else setLogo(props.client.thumbnail)
    }, [props])

    const hasAccessToClientPage = () => {
      return !props.loggedUser.roles.includes("PROJECT_MANAGER")
    }

    var mediaContent = (<Media>
                          <div className="pos-r" title="Click para ver detalles de esta empresa.">
                              <img style={{width:34, height:30, borderRadius: 50,}} src={logo}></img>
                          </div>
                        </Media>);

    return (
        <React.Fragment>
            <Col className="align-self-center l-fs-14 light-grey d-center" md={1}>
              {(props.loggedUser && !hasAccessToClientPage()) ?
                <div>{mediaContent}</div> :
                <Link className="row color-link" to={{pathname:'/DetallesClientes', state: client}}>
                  {mediaContent}
                </Link>
                }
            </Col>
            <Col className="align-self-center l-fs-14 light-grey" md={3}>{client && client.alias}</Col>
        </React.Fragment>
    )
}

export const TaskItem = (props) => {
    const [addOrChangeScheduleTaskShowModal, setAddOrChangeScheduleTaskShowModal] = useState(false)
    const [openResolveTaskModal, setOpenResolveTaskModal] = useState(false)

    const closeTaskScheduleModal = () => {
      setAddOrChangeScheduleTaskShowModal(false)
    }
 
    const scheduleDateCallback = (date) => {
      const body = {
        dueDate: date,
        creationDate: moment().format('DD/MM/YYYY HH:mm:ss'),
      }
      addDueDateTask(props.task.company.id, props.task.id, body)
      .then( result => {
        props.refresh()
      })
      .catch(error => {
        console.log(error)
      })
    }

    return(
      <Row className="row justify-content-center py-2">
        <Col xs={11}>
        <Row className="justify-content-center no-gutters">

          <Col xs={10}>
            <Row>
              <ClientInfo client={props.task.company}
                          loggedUser={props.loggedUser}>
              </ClientInfo>
              {!props.showingClosedTasks && <Col className={`align-self-center l-fs-14 ${isTaskOverdue(props.task.scheduling.dueDate) ? "rojo" : " light-grey"}`} md={3}>
                {props.task.scheduling.dueDate}
              </Col>}
              {props.showingClosedTasks && <Col className="align-self-center l-fs-14 light-grey" md={3}>
                {props.task.scheduling.dueDate}
              </Col>}
              <Col md={5} className="align-self-center l-fs-14 light-grey" title={props.task.content}>
                  <div className='d-flex align-items-center'>

                    {props.task.opportunity && 
                      <Link className="color-link" to={{pathname:'/DetallesOportunidad',state: props.task.opportunity}}>
                        <OpportunityIconState littleIcon={true} opportunity={props.task.opportunity}/>
                      </Link>}
                    {props.task.project && 
                      <Link className="color-link" to={{pathname:'/DetalleProyecto',state: props.task.project}}>
                        <ProjectStateIcon styles={{maxWidth: 21}} projectState={props.task.project.state}/>
                      </Link>}

                    {props.task.opportunity && <p className="align-self-center l-fs-14 light-grey ml-2">
                      <b>{props.task.opportunity ? props.task.opportunity.name : null}</b>
                    </p>}
                    {props.task.project && <p className="align-self-center l-fs-14 light-grey ml-2">
                      <b>{props.task.project ? props.task.project.name : null}</b>
                    </p>}
                  </div>
                  <div className='d-flex align-items-start mt-2'>
                    <div style={{marginRight: 5}}><img style={{width:19}} src={InteractionModeIcon(props.task.mode)}/></div>
                    <p className="l-fs-14 light-grey cursor-menu">{truncateText(props.task.content, 35)}</p>
                  </div>
              </Col>
            </Row>
          </Col>
          <Col xs={2} className="d-center justify-content-md-start">
            <Row>
              <Col className="d-center flex-column flex-md-row justify-content-center  l-fs-14 light-grey" md={12}>
                    {props.task && <div style={{marginLeft: props.showingClosedTasks ? 40 : 0}}>
                          <ShowTaskModal 
                            task={props.task}
                        />
                      </div>}
                    {props.showingClosedTasks === false && <>
                      <EditTaskModal  
                          task={props.task}
                          showUpSalesMessageFn={props.showUpSalesMessageFn}
                          refresh={props.refresh}
                      />
                      <BtnArchivar interaction = {props.task} timeline={false}/>

                      <Media title="Cambiar fecha" className="mouse" onClick={()=>setAddOrChangeScheduleTaskShowModal(true)}>
                        <img src={reloj}></img>  
                      </Media>

                      <ScheduleTaskModal
                        resolve={true}
                        isItATask={true} 
                        task={props.task}  
                        show={addOrChangeScheduleTaskShowModal} 
                        onHide={closeTaskScheduleModal} 
                        scheduleDateCallbackFn={scheduleDateCallback} 
                        refresh={props.refresh}
                      />
                    
                      <ResolveTaskModal 
                        fromTaskTabList={true} 
                        show={openResolveTaskModal} 
                        refresh={props.refresh} 
                        task={props.task}
                      /> 
                    </>}
              </Col>
            </Row>
          </Col>
      </Row>
    </Col>
    <Col className="brd-b-blue mt-2" md={11}></Col>
  </Row>
    )
}

export const TasksList = props => {
  let rows = []
  props.tasks && props.tasks.map(task => {
    rows.push(<TaskItem
      entity={props.entity}
      key={task.id}
      task={task}
      opportunity={props.opportunity ? props.opportunity : null}
      refresh={props.refresh}
      showUpSalesMessageFn={props.showUpSalesMessage}
      loggedUser={props.loggedUser}
      showingClosedTasks={props.showingClosedTasks}
      />)
  })
  return (
    <React.Fragment>
      {rows}
    </React.Fragment>
  );
}

export default function TaskTabList(props) {

  const [entity, setEntity] = useState('all')
  const [loadingAnimation, setLoadingAnimation] = useState(true)
  const [loadingMoreCompanies, setLoadingMoreCompanies] = useState(false)
  const [currentTab, setCurrentTab] = useState(null)

  const [todayTasks, setTodayTasks] = useState(null)
  const [overdueTasks, setOverdueTasks] = useState(null)
  const [upcomingTasks, setUpcomingTasks] = useState(null)
  const [closedTasks, setClosedTasks] = useState(null)

  const [todayPaginationCount, setTodayPaginationCount] = useState(0)
  const [overduePaginationCount, setOverduePaginationCount] = useState(0)
  const [upcomingPaginationCount, setUpcomingPaginationCount] = useState(0)
  const [closedTasksPaginationCount, setClosedTasksPaginationCount] = useState(0)

  const [todayPlusButton, setTodayPlusButton] = useState(true)
  const [overduePlusButton, setOverduePlusButton] = useState(true)
  const [upcomingPlusButton, setUpcomingPlusButton] = useState(true)
  const [closedTasksPlusButton, setClosedTasksPlusButton] = useState(true)

  const [numeroDeTareasVencidas, setNumeroDeTareasVencidas] = useState(0)
  const [numeroDeTareasHoy, setNumeroDeTareasHoy] = useState(0)
  const [numeroDeTareasProximas, setNumeroDeTareasProximas] = useState(0)
  const [numeroDeTareasCerradas, setNumeroDeTareasCerradas] = useState(0)

  const [showingClosedTasks, setShowingClosedTasks] = useState(false)

  // showUpSalesMessage
  const[toastLevel, setToastLevel] = useState(null)
  const[toastMessage, setToastMessage] = useState(null)
  const[showToast, setShowToast] = useState(null)
  const[autoClose, setAutoClose] = useState(null)

  const TASKS_PAGE_SIZE = 5
  const TODAY_START = moment.utc(0, "HH:mm:ss").format("DD/MM/YYYY HH:mm:ss")
  const TODAY_END = moment.utc(0, "HH:mm:ss'").add(1, "day").format("DD/MM/YYYY HH:mm:ss")

  useEffect(() => {

    if (props.entity) setEntity(props.entity)

    setLoadingAnimation(true)
    getClosedTasks(closedTasksPaginationCount)
    getTodayTasks(todayPaginationCount)
    getOverdueTasks(overduePaginationCount)
    getUpcomingTasks(upcomingPaginationCount)
    

    goToTodayTasksTab(props.entity)
  }, [props.entity, props.companyId, props.opportunity, props.interaction, props.client, props.project])

  const goToUpcomingTasksTab = async () => {
    setShowingClosedTasks(false)
    setCurrentTab('upcoming')
    document.getElementById('tareasCerradas').classList.remove('active')
    document.getElementById('proximasTareas').classList.add('active')
    document.getElementById('tareasHoy').classList.remove('active')
    document.getElementById('tareasVencidas').classList.remove('active')
  }

  const goToOverdueTasksTab = async () => {
    setShowingClosedTasks(false)
    setCurrentTab('overdue')
    document.getElementById('tareasCerradas').classList.remove('active')
    document.getElementById('tareasVencidas').classList.add('active')
    document.getElementById('tareasHoy').classList.remove('active')
    document.getElementById('proximasTareas').classList.remove('active')
  }

  const goToTodayTasksTab = async () => {
    setShowingClosedTasks(false)
    document.getElementById('tareasVencidas').classList.remove('active')
    document.getElementById('tareasHoy').classList.add('active')
    document.getElementById('proximasTareas').classList.remove('active')
    document.getElementById('tareasCerradas').classList.remove('active')
    setCurrentTab('today')
  }

  const goToClosedTasksTab = async () => {
    setShowingClosedTasks(true)
    setCurrentTab('closed')
    document.getElementById('tareasCerradas').classList.add('active')
    document.getElementById('proximasTareas').classList.remove('active')
    document.getElementById('tareasHoy').classList.remove('active')
    document.getElementById('tareasVencidas').classList.remove('active')
  }

  const getTasks = async (page, startingDate, endDate, scheduling) => {
    let tasks
    try {
          if (props.entity.type === 'all') {
            tasks = await listTasksInTimeIntervalFilteredByState(page, TASKS_PAGE_SIZE, startingDate, endDate, scheduling)
            return tasks
          } else if (props.entity.type === 'opportunity') {
            tasks = await listTasksForAnOpportunity(page, TASKS_PAGE_SIZE, startingDate, endDate, props.entity.id, scheduling)
            return tasks
          } else if (props.entity.type === 'project') {
            tasks = await listTasksForAProject(props.entity.id, page, TASKS_PAGE_SIZE, startingDate, endDate, scheduling)
            return tasks
          } else if (props.entity.type === 'interaction') {
            tasks = await tasksForAnInteraction(page, TASKS_PAGE_SIZE, startingDate, endDate, props.companyId, props.entity.id, scheduling)
            return tasks
          } else if (props.entity.type === 'client'){
            tasks = await listTasksForACompany(page, TASKS_PAGE_SIZE, startingDate, endDate, props.entity.id, scheduling)
            return tasks
          }
    } catch (error) {
      showUpSalesMessage('danger', 'Ha ocurrido un error al obtener las tareas', true, true)
    }
  }

  const getTodayTasks = async (page) => {
    setShowingClosedTasks(false)
    try {
      let tasks = await getTasks(page, TODAY_START, TODAY_END, "ACTIVE")
      if (page === 0) {
        setTodayTasks(tasks.data)
        setNumeroDeTareasHoy(tasks.totalElements)
        setLoadingAnimation(false)
      } else {
        setTodayTasks([...todayTasks, ...tasks.data])
        setLoadingMoreCompanies(false)
      }

      if (tasks.currentPage + 1 >= tasks.totalPages) {
        setTodayPlusButton(false)
      } else
        setTodayPlusButton(true)

      setTodayPaginationCount(page)
      
    } catch (error) {
      showUpSalesMessage('danger', 'Ha ocurrido un error al obtener las tareas de hoy', true, true)
    }
  }

  const getOverdueTasks = async (page) => {
    setShowingClosedTasks(false)
    try {
      let tasks = await getTasks(page, null, TODAY_START, "ACTIVE")
    
      if (page === 0) {
        setOverdueTasks(tasks.data)
        setNumeroDeTareasVencidas(tasks.totalElements)
        setLoadingAnimation(false)
      } else {
        setOverdueTasks([...overdueTasks, ...tasks.data])
        setLoadingMoreCompanies(false)
      }

      if (tasks.currentPage + 1 >= tasks.totalPages)
        setOverduePlusButton(false)
      else
        setOverduePlusButton(true)

      setOverduePaginationCount(page)
    } catch (error) {
      showUpSalesMessage('danger', 'Ha ocurrido un error al obtener las tareas vencidas', true, false)
    }

  }

  const getUpcomingTasks = async (page) => {
    setShowingClosedTasks(false)
    try {
      let tasks = await getTasks(page, TODAY_END, null, "ACTIVE")
      
      if (page === 0) {
        setUpcomingTasks(tasks.data)
        setNumeroDeTareasProximas(tasks.totalElements)
        setLoadingAnimation(false)
      } else {
        setUpcomingTasks([...upcomingTasks, ...tasks.data])
        setLoadingMoreCompanies(false)
      }

      if (tasks.currentPage + 1 >= tasks.totalPages)
        setUpcomingPlusButton(false)
      else
        setUpcomingPlusButton(true)

      setUpcomingPaginationCount(page)
      
    } catch (error) {
      showUpSalesMessage('danger', 'Ha ocurrido un error al obtener las tareas próximas', true, false)
    }
  }

  const  getClosedTasks = async (page) => {
    setShowingClosedTasks(true)
    try {
      let tasks = await getTasks(page, null, null, "CLOSED")
      if (page === 0) {
        setClosedTasks(tasks.data)
        setNumeroDeTareasCerradas(tasks.totalElements)
        setLoadingAnimation(false)
      } else {
        setClosedTasks([...closedTasks, ...tasks.data])
        setLoadingMoreCompanies(false)
      }

      if (tasks.currentPage + 1 >= tasks.totalPages)
        setClosedTasksPlusButton(false)
      else
        setClosedTasksPlusButton(true)

      setClosedTasksPaginationCount(page)
      
    } catch (error) {
      showUpSalesMessage('danger', 'Ha ocurrido un error al obtener las tareas cerradas', true, false)
    }
  }

  const getMoreTasks = (e, tasksToday) => {
    
    setLoadingMoreCompanies(true)
    e.preventDefault()
    
    if (tasksToday === "today")
      getTodayTasks(todayPaginationCount + 1)
    else if (tasksToday === "overdue")
      getOverdueTasks(overduePaginationCount + 1)
    else if (tasksToday === "upcoming")
      getUpcomingTasks(upcomingPaginationCount + 1)
    else if (tasksToday === "closed")
      getClosedTasks(closedTasksPaginationCount + 1)

  }

  const refreshMe = () => {
    getTodayTasks(0)
    getOverdueTasks(0)
    getUpcomingTasks(0)
    goToTodayTasksTab()
  }

  const showUpSalesMessage = (level, message, show, close) => {
    setToastLevel(level)
    setToastMessage(message)
    setShowToast(show)
    setAutoClose(close)
  }

  const closeMessage = () => {
    setToastLevel(null)
    setToastMessage(null)
    setShowToast(false)
    setAutoClose(false)
  }

  if (!loadingAnimation && entity) return (
    <React.Fragment>
      <Row className={[(entity.type==="all" || entity.type==='project') ? ' mx-1 bg-white py-4' : 'bg-white py-4 mt-3' ]}>
        <Col>
          <Row >
            <Col xs={12} style={{position: 'relative'}}>
              <div className="dark-blue text-center pb-5"><b>LISTADO DE TAREAS</b></div>
              <div className="row d-flex flex-row justify-content-end align-items-center" style={{position: 'absolute', left:0, top:0, width: '100%'}}>
                  <ShowArchivedTasks 
                      opportunity={props.opportunity ? props.opportunity : null}
                      project={props.project}
                      interaction={props.interaction}
                      client={props.client}
                      refresh={refreshMe} 
                      msg={showUpSalesMessage}
                  />
                  {/* {entity && entity.type === 'all' && <DashboardCreateTaskModal
                    showUpSalesMessageFn={showUpSalesMessage}
                    refresh={refreshMe} 
                    option={"crear"}
                  />} */}
                  {/* {entity && entity.type !== 'all' && */}
                   {(!props.hideCreateTaskButton || props.hideCreateTaskButton === false) && <CreateTaskModal
                    showUpSalesMessageFn={showUpSalesMessage}
                    refresh={refreshMe}
                    refreshCloseOpModal={props.refreshCloseOpModal} 
                    opportunity={props.opportunity}
                    companyId={props.companyId ? props.companyId : null}
                    interaction={props.interaction}
                    project={props.project}
                    loggedUser={props.loggedUser}
                  />}
                  {/* } */}
              </div>
            </Col>
          </Row>
          <UpSalesMessage
            level={toastLevel}
            message={toastMessage}
            visible={showToast}
            autoClose={autoClose}
            closeMessage={closeMessage}
          />
          <Row className="justify-content-center py-3">
            <Col 
              md={3} id={"tareasHoy"} 
              onClick={()=>goToTodayTasksTab()} 
              className="mouse unselected active text-center mouse">
              HOY 
              (<span className="l-fs-14" style={{color: currentTab === 'today' ? '#006AC1' : numeroDeTareasHoy !== 0 ? 'orange' : '#B7CCE2'}}>
                {numeroDeTareasHoy}
              </span>)
            </Col>
            <Col 
              md={3} id={"tareasVencidas"} 
              onClick={()=>goToOverdueTasksTab()} 
              className="mouse unselected text-center mouse">
              VENCIDAS 
              (<span className="l-fs-14" style={{color: currentTab === 'overdue' ? '#006AC1' : numeroDeTareasVencidas !== 0 ? 'orange' : '#B7CCE2'}}>
                {numeroDeTareasVencidas}
              </span>)
            </Col>
            <Col 
              md={3} id={"proximasTareas"} 
              onClick={()=>goToUpcomingTasksTab()} 
              className="mouse unselected text-center mouse">
              PRÓXIMAS 
              (<span className="l-fs-14" style={{color: currentTab === 'upcoming' ? '#006AC1' : numeroDeTareasProximas !== 0 ? 'orange' : '#B7CCE2'}}>
                {numeroDeTareasProximas}
              </span>)
            </Col>
            <Col 
              md={3} id={"tareasCerradas"} 
              onClick={()=>goToClosedTasksTab()} 
              className="mouse unselected text-center mouse">
              CERRADAS 
              (<span className="l-fs-14" style={{color: currentTab === 'closed' ? '#006AC1' : numeroDeTareasCerradas !== 0 ? 'orange' : '#B7CCE2'}}>
                {numeroDeTareasCerradas}
              </span>)
            </Col>
            <Col className="brd-b-blue" md={11}></Col>
          </Row>
          <Row className="justify-content-center">
            <Col xs={11}>
              <Row className="justify-content-center">
                <Col xs={10}>
                  <Row className='no-gutters'>
                    <Col className="light-grey l-fs-14 mayus my-2" md={4}><b>COMPAÑÍA</b></Col>
                    <Col className="light-grey l-fs-14 mayus my-2" md={3}><b>FECHA</b></Col>
                    <Col className="light-grey l-fs-14 mayus my-2" md={5}><b>DESCRIPCIÓN</b></Col>
                  </Row>
                </Col>
                <Col xs={2}>
                  <Row className='no-gutters'>
                    <Col className="light-grey l-fs-14 mayus my-2" md={12}><b>ACCIONES</b></Col>
                  </Row>
                </Col>
              
                <Col className="brd-b-blue" md={12}></Col>
              </Row>
            </Col>
          </Row>

          {currentTab === 'today' ?
            (
              todayTasks && todayTasks.length !==0 ? 
              <TasksList
                  entity={entity}
                  showUpSalesMessageFn={showUpSalesMessage} 
                  refresh={refreshMe} 
                  tasks={todayTasks}
                  opportunity={props.opportunity ? props.opportunity : null}
                  loggedUser={props.loggedUser}
                  showingClosedTasks={showingClosedTasks}
              /> : 
              <p style={{color: '#757575', textAlign:'center', paddingTop:23}}>
                {`No hay tareas de Hoy para mostrar`}
              </p>
            ) : null
          }

          {currentTab === 'overdue' ?
            (
              overdueTasks && overdueTasks.length !==0 ? 
              <TasksList
                entity={entity}
                showUpSalesMessageFn={showUpSalesMessage} 
                refresh={refreshMe} 
                tasks={overdueTasks}
                opportunity={props.opportunity ? props.opportunity : null}
                loggedUser={props.loggedUser}
                showingClosedTasks={showingClosedTasks}
              /> : 
              <p style={{color: '#757575', textAlign:'center', paddingTop:23}}>
                {`No hay tareas vencidas para mostrar`}
              </p>
            ) : null 
          }

          {currentTab === 'upcoming' ?
            (
              upcomingTasks && upcomingTasks.length !==0 ? 
              <TasksList
                entity={entity}
                showUpSalesMessageFn={showUpSalesMessage} 
                refresh={refreshMe} 
                tasks={upcomingTasks}
                opportunity={props.opportunity ? props.opportunity : null}
                loggedUser={props.loggedUser}
                showingClosedTasks={showingClosedTasks}
              /> : 
              <p style={{color: '#757575', textAlign:'center', paddingTop:23}}>
                {`No hay tareas próximas para mostrar`}
              </p>
            ) : null
          }

          {currentTab === 'closed' ?
            (
              closedTasks && closedTasks.length !==0 ? 
              <TasksList
                entity={entity}
                showUpSalesMessageFn={showUpSalesMessage} 
                refresh={refreshMe} 
                tasks={closedTasks}
                opportunity={props.opportunity ? props.opportunity : null}
                loggedUser={props.loggedUser}
                showingClosedTasks={showingClosedTasks}
              /> : 
              <p style={{color: '#757575', textAlign:'center', paddingTop:23}}>
                {`No hay tareas cerradas para mostrar`}
              </p>
            ) : null
          }

          {((currentTab === 'today' && todayPlusButton) || (currentTab === 'overdue' && overduePlusButton) || (currentTab === 'upcoming' && upcomingPlusButton) || (currentTab === 'closed' && closedTasksPlusButton)) &&
          <Row>
              <Col className="btn-mas-container flex-column">
                  {loadingMoreCompanies && <LoadingAnimationMiniSize></LoadingAnimationMiniSize> }
                  {!loadingMoreCompanies && <a href="#" onClick={e =>{getMoreTasks(e, currentTab)}}>
                      <img src={btnMas}></img>
                  </a>}
              </Col>
          </Row>}
        </Col>
      </Row>
    </React.Fragment>)
    else if (loadingAnimation) return (
      <React.Fragment>
        <Row className={[entity==="all" ? ' mx-1 bg-white py-4' : 'bg-white py-4 mt-3' ]}>
        <Col>
          <Row ><Col className="dark-blue text-center"><b>LISTADO DE TAREAS</b></Col></Row>
          <Row className="justify-content-center py-3">
            <Col md={3} id={"tareasHoy"} onClick={()=>{goToTodayTasksTab()}} className="mouse unselected text-center mouse">HOY</Col>
            <Col md={3} id={"tareasVencidas"} onClick={()=>{goToOverdueTasksTab()}} className="mouse unselected text-center mouse">VENCIDAS</Col>
            <Col md={3} id={"proximasTareas"} onClick={()=>{goToUpcomingTasksTab()}} className="mouse unselected text-center mouse">PRÓXIMAS</Col>
            <Col md={3} id={"tareasCerradas"} onClick={()=>{goToClosedTasksTab()}} className="mouse unselected text-center mouse">CERRADAS</Col>
            <Col className="brd-b-blue" md={11}></Col>
          </Row>
          <LoadingAnimation text={'Cargando datos...'}/>
        </Col>
      </Row>
      </React.Fragment>
    )
}
