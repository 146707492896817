import React, { useEffect } from "react";
import "../assets/css/App.css";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

// FUNCIONES IMPORTADAS
import { signin } from "../api_communication/service/AuthService";
import { x as UpSales } from "../api_communication/bundle/config";

//IMAGENES
import icPassword from "../assets/img/login/ic-password.png";
import icUser from "../assets/img/login/ic-user.png";
import logo from "../assets/img/login/logo-upsales.png";

function Login() {
  useEffect(() => {
    document.title = "Login | UpSales";
    const enterKey = () => {
      let inputDiv = document.getElementById("enter-key-trigger");
      inputDiv.addEventListener("keyup", function (event) {
        // Number 13 is the "Enter" key on the keyboard
        if (event.keyCode === 13) {
          // Cancel the default action, if needed
          event.preventDefault();
          // Trigger the button element with a click
          document.getElementById("enter-btn").click();
        }
      });
    };
    enterKey();
  }, []);

  const loginEntered = async () => {
    let login = document.getElementById("user").value;
    let pass = document.getElementById("pass").value;

    if (!login || !pass) {
      document.getElementById("emptyInputs").classList.remove("d-none");
    } else {
      try {
        let result = await signin(login, pass);
        if (
          result &&
          UpSales.Config.getStore().getJwtToken() === result.accessToken
        ) {
          
          window.location.replace("/dashboard");
          
        } else {
          document
            .getElementById("invalidPassOrUser")
            .classList.remove("d-none");
        }
      } catch (error) {
        document.getElementById("invalidPassOrUser").classList.remove("d-none");
        // console.log(error)
      }
    }
  };

  const register = () => {
    alert("IR A PÁGINA DE REGISTRO. Por implementar...");
  };

  const recoveryPassword = () => {
    alert("IR A PÁGINA DE RECUPERACIÓN DE PASSWORD. Por implementar...");
  };

  const removeErrorMsg = () => {
    if (!document.getElementById("emptyInputs").classList.contains("d-none")) {
      document.getElementById("emptyInputs").classList.add("d-none");
    }
    if (
      !document.getElementById("invalidPassOrUser").classList.contains("d-none")
    ) {
      document.getElementById("invalidPassOrUser").classList.add("d-none");
    }
  };

  return (
    <Container fluid={true}>
      <Row>
        <Col
          xs={12}
          md={6}
          className="vh100 bg-blue-login d-column white-color"
        >
          {/* TEMP */}
          <div>
            <img className="mb-4" src={logo} />
          </div>

          <div className="text-center mb-4">
            <h1 className="l-fs-21">UP SALES ASSISTANT</h1>
            <h2 className="l-fs-16">Software para gestión comercial</h2>
            <h2 className="l-fs-16">en la industria cerámica</h2>
          </div>

          <div className="d-center row">
            <div id="emptyInputs" className="col-12 d-center d-none">
              <p className="red-text l-fs-16 mb-4">Rellene los dos campos.</p>
            </div>
          </div>

          <div className="d-center row">
            <div id="invalidPassOrUser" className="col-12 d-center d-none">
              <p className="red-text l-fs-16 mb-4">
                Usuario o password inválidos.
              </p>
            </div>
          </div>

          {/* INPUTS */}
          <div className="d-column  mb-4">
            <div className="d-flex flex-row justify-content-start align-items-center loginInputStyle mb-2">
              <div>
                <img src={icUser} />
              </div>
              <input
                onClick={removeErrorMsg}
                id="user"
                className="ml-2"
                type="text"
                placeholder="Usuario"
              />
            </div>
            <div
              id="enter-key-trigger"
              className="d-flex flex-row justify-content-start align-items-center loginInputStyle"
            >
              <div>
                <img src={icPassword} />
              </div>
              <input
                onClick={removeErrorMsg}
                id="pass"
                className="ml-2"
                type="password"
                placeholder="Contraseña"
              />
            </div>
          </div>

          {/* LINK FORBIDDEN PASSWORD */}
          <div className="row">
            <div className="col-12 d-center mb-4">
              {/* <NavLink className="fobiddenPasswordText" to="#" title="Recuperar contraseña">He olvidado mi contraseña &gt;</NavLink> */}
              <p
                onClick={recoveryPassword}
                className="fobiddenPasswordText"
                title="Recuperar contraseña"
              >
                He olvidado mi contraseña &gt;
              </p>
            </div>
          </div>

          {/* BUTTONS */}
          <div className="d-center row">
            <div className="col-12 col-md-6 d-center">
              <div onClick={register} className="whiteBtnLogin d-center">
                REGISTRO
              </div>
            </div>
            <div className="col-12 col-md-6 d-center mt-4 mt-md-0">
              <div
                id="enter-btn"
                onClick={loginEntered}
                className="orangeBtnLogin d-center"
              >
                ENTRAR
              </div>
            </div>
          </div>
        </Col>
        <Col
          xs={12}
          md={6}
          className="vh100 bg-img-login d-none d-md-block"
        ></Col>
      </Row>
    </Container>
  );
}
export default Login;
