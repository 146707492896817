// --------------------------------------------
// Created by Pablo Collado in 02/12/2020
// modified by Pablo Collado 22/07/2022
// --------------------------------------------

// FROM PARENT DetalleInteraccion.js
//  <CreateAndAssociateOpportunityToInteraction 
//      company={idCompany} 
//      interaction={interaction} 
//      refreshFn={refreshFn} // Fn to refresh parent: detalle interaction
//  />


import React, {useState, useEffect} from 'react';
import Modal from 'react-bootstrap/Modal'
import Media from 'react-bootstrap/Media'

// IMAGES
import imgBigOportunity from '../../assets/img/ic-oportunidad-big.png'
import docIcon from '../../assets/img/ic-adjuntar-doc-azul.png'

// BACKEND FUNCTIONS
import { createInteraction, archiveInteraction, modifyInteraction } 
  from '../../api_communication/service/ClientService'
import { createOpportunityFn } from '../../api_communication/service/OpportunityService';

// COMPONENTS
import {ShowPhotosAndDocsPreview} from '../../componentes/MediaManager'
import FilesDragAndDrop from '../../componentes/FilesDragAndDrop'
import LoadingAnimation from '../LoadingAnimation'
import UpSalesMessage from '../UpSalesMessage'

// EXTERNAL LIBRARIES
import moment from 'moment'


export default function CreateAndAssociateOpportunityToInteraction(props) {
    const [loadingAnimation, setLoadingAnimation] = useState(true)
    const [show, setShow] = useState(false);
    const [idCompany, setIdCompany] = useState(null)
    const [opportunityObject, setOpportunityObject] = useState(null) // oportunidad recién creada en este componente
    const [interactionProp, setInteractionProp] = useState(null) // interacción desde 'DetalleInteraccion.js'

    const [preview, setPreview] = useState(false) // ?
    const [imageOrDocToShow, setImageOrDocToShow] = useState(null)
    const [saveImagesAndDocs, setSaveImagesAndDocs] = useState(null)
    const [interaction, setInteraction] = useState(null) // interaccíon 'oportunidad creada' creada al crear oportunidad

    // para mensajes emergentes (upsales msg)
    const [level, setLevel] = useState(null)
    const [message, setMessage] = useState(null)
    const [close, setClose] = useState(null)
    const [showUM, setShowUM] = useState(null)

     // FN PARA REFRESCAR PADRE
     const refreshFn = props.refreshFn

    useEffect(()=>{
      setIdCompany(props.company)
      setInteractionProp(props.interaction)
      //console.log('CreateAndAssociateOpportunityToInteraction: props.interaction > : ', props.interaction)
      setLoadingAnimation(false)
      
    }, [])

    const createOpportunity = async () => {
      var opportunityName = document.getElementById("opportunityName").value
      var opportunityDescription = document.getElementById("opportunityDescription").value
      if(opportunityName == ''){
        showUpSalesMessage('danger','Debe añadir un título/nombre para la oportunidad', true, false)
      } else {
        try {
          // CREATING OPPORTUNITY
          let body = {
            companyId: idCompany,
            name: opportunityName,
            description: opportunityDescription ? opportunityDescription : undefined,
            state: 'OPEN'
          }
          let creationDate =  moment().format('DD/MM/YYYY HH:mm:ss')

          // UpSales.Opportunity.createOpportunity(body, creationDate)
          let newOp = await createOpportunityFn(body, creationDate)
          setOpportunityObject(newOp)
          //console.log('CreateAndAssociateOpportunityToInteraction: createOpportunityFn: newOp: ',newOp)
          // CREATING INTERACTION 'oportunidad creada'
          let interactionData = {
            date: moment().format("DD/MM/YYYY") + ' ' +  moment().format("HH:mm:ss"),
            content: 'Oportunidad Creada',
            mode: "text",
            opportunity:{
              name: opportunityName,
              id: newOp.id
            }
          }
          let createdInt = await createInteraction(idCompany, interactionData)
          //console.log('CreateAndAssociateOpportunityToInteraction: createInteraction: createdInt: ',createdInt)

          // SAVING IMG/DOCS
          let intId = createdInt.id
          console.log('CreateAndAssociateOpportunityToInteraction: let intId = createdInt.id: ',intId)
          setInteraction(createdInt)
          setSaveImagesAndDocs(true)

          // AQUI ARCHIVAMOS LA INTERACCIÓN CREADA
          if (!imageOrDocToShow) {let arch = await archiveInteraction(idCompany, intId)}

          // ASSOCIATE OPPORTUNITY TO INTERACTION FROM PROPS
          body = {
            opportunity: {
              id: newOp.id
            }
          }
          let modify = await modifyInteraction(idCompany, props.interaction.id, body )
          showUpSalesMessage('success','Oportunidad asociada a la interacción. Refrescando...', true, true)
          cancelForm(false)
          setTimeout(() =>{
            refreshFn(modify)
          }, 4000);
          //document.getElementById('linkToOpportunityDetails').click()
      
        } catch (error) {
          console.log(error)
        }
      }
    }
    const showUpSalesMessage = (level, message, show, close) => {
        setLevel(level)
        setMessage(message)
        setShowUM(show)
        setClose(close)
    }
  
    const closeMsg = () => {
          setLevel(null)
          setMessage(null)
          setShowUM(false)
          setClose(null)
    }

    const callToShowImageOrDoc = e => {
      setImageOrDocToShow(e)
      setPreview(true)
    }

    const cancelForm =  () => {
      setShow(false)
    }

    const showThisModal = ()=> {
        setShow(true)
    }

    return (
      <React.Fragment>
        
        <div id="showCreateModal"  onClick={showThisModal}></div>

        <UpSalesMessage
            level={level}
            message={message}
            visible={showUM}
            autoClose={close}
            closeMessage={closeMsg}
        />
        <Modal
          id="modalPositionRight"
          show={show}
          onHide={() => setShow(false)}
          dialogClassName="bg-white"
          aria-labelledby="example-custom-modal-styling-title"
          centered
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header className="justify-content-center modal-border mt-5">
            <Modal.Title id="example-custom-modal-styling-title" className="dark-blue d-column">
              <b className="my-4 text-center">
                CREAR UNA OPORTUNIDAD
              </b>
              <Media><img src={imgBigOportunity}></img></Media>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="my-4">
            {/* {console.log('>>>>>>>>>>>> searchTerm: ', searchTerm)} */}
            {loadingAnimation && 
              <div className="overlay-loading-Animation">
                <LoadingAnimation text={'Cargando Empresas...'} />
              </div>
            }
            <form className="d-column generaltextInputStyle">
                <div>{props.company.alias}</div>

              <input type="text" className="my-3" id="opportunityName" placeholder="Nombre Oportunidad"></input>

              <textarea className="mb-3" id="opportunityDescription" type="text"  placeholder="Descripción"></textarea>

              <div className='py-3'>
              <FilesDragAndDrop fileSelected={callToShowImageOrDoc}/>
            </div>
           

            {preview && imageOrDocToShow && <div id="preview" className="w-100 py-3">
              <ShowPhotosAndDocsPreview isItATask={false} imageOrDocToShow={imageOrDocToShow} saveImagesAndDocs={saveImagesAndDocs} idCompany={idCompany} idTaskOrInteraction={interaction ? interaction.id : null}/>
             
            </div>} 
              
              <div className="d-inline-flex my-5">
                  <div className="d-center boton-cancelar mr-3" onClick={cancelForm}>Cancelar</div>
                  <div className="d-center boton-guardar" onClick={createOpportunity}>Guardar</div>
              </div>
             

            </form>
          </Modal.Body>
        </Modal>
       
      </React.Fragment>
    );
  }