// -----------------------------------
// Created by Pablo Collado 27/11/2020
// -----------------------------------

import React, {useState, useEffect} from 'react'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {Link} from 'react-router-dom'

// IMAGES
import flechaIzq from '../../assets/img/ic-arrow-left.png'
import flechaDcha from '../../assets/img/ic-arrow-right.png'
import icTel from '../../assets/img/ic-tel-blue.png'
import icInteraction from '../../assets/img/ic-interaccion-blue.png'
import icEmail from '../../assets/img/ic-correo-blue.png'
import icCam from '../../assets/img/ic-cam-blue.png'
import blueBtnMore from '../../assets/img/btn-mas.png'
import icAdjuntar from '../../assets/img/ic-adjuntar-celeste.png'
import icRelojNaranja from '../../assets/img/ic-reloj-nrja.png'
import icRelojRojo from '../../assets/img/ic-reloj-rojo.png'
import greenTick from '../../assets/img/ic-check-tarea-resuelta.png'
import userImgPlaceholder from "../../assets/img/UserAvatarIconPlaceholder.png";

// COMPONENTS
import UpSalesMessage from '../../componentes/UpSalesMessage'
import { isTaskOverdue } from '../../api_communication/service/TaskService';
import {OpportunityIconState} from '../CommonScripts'
import LoadingAnimation from '../LoadingAnimation'
import ShowArchivedInteractions from '../ShowArchivedInteractions';
import CreateInteraction from '../CreateInteractionModal';

// BACKEND FN
import { listInteractionsForAnOpportunity, fetchGetOpportunityById } from '../../api_communication/service/OpportunityService';

// -----------------------------------
// FROM PARENT > DetalleInteraccion.js: 
// -----------------------------------
// <Timeline 
//    showUpSalesMessageFn={this.showUpSalesMessage} 
//    opportunity={opportunity} 
//    refreshFn={()=>this.refresh()} NO
//    showCreateInteractionButton={}
// />

  class Item extends React.Component {
    constructor(props){
      super(props)
      this.state = {
        cutContent: '',
        imageState: null,
        interactionSelected: false
      }
      this.interactionDetailsFn = props.interactionDetailsFn
      this.refresh = props.refresh
      this.handleScrollToElement = props.handleScrollToElement
    }

    async componentDidMount(){
      if (this.props.item.createdBy) {
        document.styleSheets[0].addRule(`.timeline ol li#id${this.props.listkey}:not(:last-child):after`,`content: ""; background-image: url(${this.props.item.createdBy.profilePhoto ? this.props.item.createdBy.profilePhoto : userImgPlaceholder}); background-repeat: no-repeat; background-position: center; border-radius: 50%; background-size: cover; display: block; position: absolute; top:-10px; left:calc(172%);width:30px; height:30px; z-index:99; `);
        document.getElementById(`id${this.props.listkey}`).setAttribute("title", `${this.props.item.createdBy.username}`);
      } else {
        if (this.props.item) document.styleSheets[0].addRule(`.timeline ol li#id${this.props.listkey}:not(:last-child):after`,`content: ""; background-image: url(${userImgPlaceholder}); background-repeat: no-repeat; background-position: center; border-radius: 50%; background-size: cover; display: block; position: absolute; top:-10px; left:calc(172%);width:30px; height:30px; z-index:99; `);
      }
    }

    shortText = (text) => {
      return text.slice(0,35) + ' (...)'
    }

    cutContent = (content)=> {
      if(content.length > 35) return this.shortText(content)
      else return content
    }

    textModeImage = (mode) => {
      switch (mode) {
        case 'text':
          return icInteraction
        case 'phone':
          return icTel
        case 'email':
          return icEmail
        case 'photo':
          return icCam
        default:
          break;
      }
    }
    

    render(){
      // onClick={()=>{this.refresh(this.props.listkey) ;this.setState({interactionSelected: true}); this.interactionDetailsFn(this.props.item);this.handleScrollToElement() }}
      return(
        <li id={'id' + this.props.listkey} key={this.props.listkey} >
          <Link to={{pathname:'/DetalleInteraccion',state: this.props.item}} className="interactionContainer flex-column pos-r" style={{textDecoration: 'none'}}>
            <div className="timeline-indicators">
              {this.props.item.hasDocuments && <img style={{width: 12, height: 12,}} src={icAdjuntar} alt="Adjuntos" title="Adjuntos"/>}
              {this.props.item.schedulingState === 'closed' ? <img style={{width: 13, height: 13,}} src={greenTick} alt="Interacción con tarea resuelta" title="Interacción con tarea resuelta"/> : 
                (this.props.item.dueDate != null && this.props.item.schedulingState != 'closed' ? 
                  (isTaskOverdue(this.props.item.dueDate) ? <img style={{width: 13, height: 13,}} src={icRelojRojo} alt="Tarea programada vencida" title="Tarea programada vencida"/> : <img style={{width: 15, height: 15,}} src={icRelojNaranja} alt="Tarea programada pendiente" title="Tarea programada pendiente" />)
                  : null)
              }
            </div>
            <div   className={`${this.state.interactionSelected && this.props.intSelected == this.props.listkey ? 'bg-timeline-blue' : 'bg-timeline-grey'} py-2 interactionUp  px-2 borderTop d-flex align-items-center`}>
              <img style={{width: 25, height: 25}} src={this.textModeImage(this.props.item.mode)}/>
              {/* {this.state.imageState && <img style={{width: 26, height: 26, marginLeft: 4, marginBottom:3}} src={this.state.imageState} />} */}
              {this.props.item.opportunity && <div style={{marginLeft: 4, marginBottom:3, marginRight:4}}>
                <OpportunityIconState opportunity={this.props.item.opportunity}/>
              </div>}
              <p className="ml-2 timeline-date">{this.props.item.date}</p> 
            </div>
            <div  className={`${this.state.interactionSelected  && this.props.intSelected == this.props.listkey ? 'bg-timeline-dark-blue' : 'bg-timeline-dark-grey'} interactionDown pt-1  px-2 borderBottom`}>
              {/* {this.props.item.opportunity && <p className="timeline-opportunity-description" title={this.props.item.opportunity.name}>{this.cutContent(this.props.item.opportunity.name)}</p>} */}
              <p className="timeline-content" title={this.props.item.content}>{this.cutContent(this.props.item.content)}</p>
            </div>
          </Link>
        </li>
      )
    }
  }
  
export default class Timeline extends React.Component {

  constructor(props){
    super(props)
      this.state={
        timeline: null,
        elH: null,
        arrows: null,
        arrowPrev: null,
        arrowNext: null,
        firstItem: null,
        lastItem: null,
        xScrolling: null,
        disabledClass: "disabled",
        interactions: null,
        paginationCount: 0,
        noMoreInteractions: false,
        loadingInteractions: false,
        mediaQuery: false,
        intSelected: null,
        noInteractions: true,
        opportunityObject: null,
      }
      this.myRef = React.createRef();
      this.isElementInViewport = this.isElementInViewport.bind(this)
      this.interactionDetailsFn = props.interactionDetailsFn
      //this.refreshFn = props.refreshFn
  }

  async componentDidMount(){
  this.setState({loadingInteractions: true,})
  if (this.props.opportunity ) {
      let opObject = await fetchGetOpportunityById(this.props.opportunity.id)
      this.setState({opportunityObject: opObject})
      await this.fetchData()
      this.refreshInteractionListState()
      this.init()
  } else {this.setState({loadingInteractions: false})}
  
  }

  async componentDidUpdate(prevProps) {
    if (this.props.opportunity !== null && this.props.opportunity !== prevProps.opportunity) {
      let opObject = await fetchGetOpportunityById(this.props.opportunity.id)
      this.setState({loadingInteractions: true,opportunityObject: opObject})
      await this.fetchData()
      this.refreshInteractionListState()
      this.init()
    }
  }

  fetchData = async ()=>{
      // UpSales.Opportunity.listInteractions(opportunityId, page, pageSize, mode, states)
    let result = await listInteractionsForAnOpportunity(this.props.opportunity.id, this.state.paginationCount, '', ['ACTIVE'])
    if (result) {
      this.setState({
          noInteractions: false,
          interactions: result, 
          loadingInteractions: false
      })
    }
  }

  refreshTimeline = () => {

  }

  refreshInteractionListState = () =>{
    this.setState({
      timeline: document.querySelector(".timeline ol"),
      elH: document.querySelectorAll(".timeline li > div"),
      arrows: document.querySelectorAll(".timeline .arrows .arrow"),
      arrowPrev: document.querySelector(".timeline .arrows .arrow__prev"),
      arrowNext: document.querySelector(".timeline .arrows .arrow__next"),
      firstItem: document.querySelector(".timeline li:first-child"),
      lastItem: document.querySelector(".timeline li:last-child"),
      xScrolling:500,
      //disabledClass: "disabled",
    })
  } 

  init() {
    var x = window.matchMedia("(max-width: 768px)")
    this.mediaQueryMoreInteraction(x) // Call listener function at run time
    x.addListener(this.mediaQueryMoreInteraction) // Attach listener function on state changes
    // this.setEqualHeights(this.state.elH);
    this.animateTl(this.state.xScrolling, this.state.arrows, this.state.timeline);
    this.setSwipeFn(this.state.timeline, this.state.arrowPrev, this.state.arrowNext);
    this.setKeyboardFn(this.state.arrowPrev, this.state.arrowNext);
  }

  mediaQueryMoreInteraction = (x)=>{
    if (x.matches) { // If media query matches
      this.setState({mediaQuery: true})
    } else {
      this.setState({mediaQuery: false})
    }
  }

  moreInteractions = async()=>{
    this.setState({paginationCount: this.state.paginationCount +1})
    let result = await listInteractionsForAnOpportunity(this.props.opportunity.id, this.state.paginationCount, '', ['ACTIVE'])
    if (result) {
      this.setState({interactions: this.state.interactions.concat(result)})
      this.refreshInteractionListState ()
      } else {
        this.setState({
          noMoreInteractions: true
        })
      }
  } 
  

  // When we create a new interaction
  refreshInteractions = async ()=>{
    await this.setState({
      noMoreInteractions: false,
      paginationCount: 0,
      interactions: null,
      loadingInteractions: true,
    })
    //this.refreshTimeline()
    await this.fetchData()
    await this.refreshInteractionListState () 
    this.init()
  }

  // SET EQUAL HEIGHTS ---- NO BORRAR!
  //  setEqualHeights(el) {
  //   let counter = 0;
  //   for (let i = 0; i < el.length; i++) {
  //     const singleHeight = el[i].offsetHeight;

  //     if (counter < singleHeight) {
  //       counter = singleHeight;
  //     }
  //   }

  //   for (let i = 0; i < el.length; i++) {
  //     el[i].style.height = `${counter}px`;
  //   }
  // }

  // CHECK IF AN ELEMENT IS IN VIEWPORT
  // http://stackoverflow.com/questions/123999/how-to-tell-if-a-dom-element-is-visible-in-the-current-viewport

  isElementInViewport(el) {
    const parentPos = document.getElementById('timelineContainer').getBoundingClientRect()
    const childPos = el.getBoundingClientRect()
    const relativePos = {}
    relativePos.top = (childPos.top - parentPos.top)
    relativePos.right = (childPos.right - parentPos.right)
    relativePos.bottom = (childPos.bottom - parentPos.bottom)
    relativePos.left = (childPos.left - parentPos.left)
    return (
      relativePos.top >= 0 &&
      relativePos.left >= 0 &&
      relativePos.bottom <= 0 &&
      relativePos.right <= 0
    );
  }

  // SET STATE OF PREV/NEXT ARROWS
  setBtnState(el, flag = true) {
    //console.log('setBtnState: el: ', el)
    const disabledClass = this.state.disabledClass
    if (flag) {
      el.classList.add(disabledClass);
    } else {
      if (el.classList.contains(disabledClass)) {
        el.classList.remove(disabledClass);
      }
      el.disabled = false;
    }
  }

  // ANIMATE TIMELINE
  animateTl(scrolling, el, tl) {
    

    let counter = 0;
    for (let i = 0; i < el.length; i++) {
      el[i].addEventListener("click", ()=> {
        const arrowPrev = this.state.arrowPrev
        const arrowNext = this.state.arrowNext
        const firstItem = this.state.firstItem
        const lastItem = this.state.lastItem
        if (!arrowPrev.disabled) {
          arrowPrev.disabled = true;
        }
        if (!arrowNext.disabled) {
          arrowNext.disabled = true;
          if (this.state.noMoreInteractions == false) this.moreInteractions()
        }
        const sign = (el[i].classList.contains("arrow__prev")) ? "" : "-";
        if (counter === 0) {
          tl.style.transform = `translateX(-${scrolling}px)`;
        } else {
          const tlStyle = getComputedStyle(tl);
          // add more browser prefixes if needed here
          const tlTransform = tlStyle.getPropertyValue("-webkit-transform") || tlStyle.getPropertyValue("transform");
          const values = parseInt(tlTransform.split(",")[4]) + parseInt(`${sign}${scrolling}`);
          tl.style.transform = `translateX(${values}px)`;
        }

        setTimeout(() => {
          this.isElementInViewport(firstItem, 'first') ? this.setBtnState(arrowPrev) : this.setBtnState(arrowPrev, false);
          this.isElementInViewport(lastItem, 'last') ? (this.setBtnState(arrowNext)) : this.setBtnState(arrowNext, false);
        }, 100);

        counter++;
      });
    }
  }

  //ADD SWIPE SUPPORT FOR TOUCH DEVICES
  setSwipeFn(tl, prev, next) {
    var Hammer = require('hammerjs');
    const hammer = new Hammer(tl);
    hammer.on("swipeleft", () => next.click());
    hammer.on("swiperight", () => prev.click());
  }

  // ADD BASIC KEYBOARD FUNCTIONALITY
  setKeyboardFn(prev, next) {
    const timeline = this.state.timeline
    document.addEventListener("keydown", (e) => {
      if ((e.which === 37) || (e.which === 39)) {
        const timelineOfTop = timeline.offsetTop;
        const y = window.pageYOffset;
        if (timelineOfTop !== y) {
          window.scrollTo(0, timelineOfTop);
        }
        if (e.which === 37) {
          prev.click();
        } else if (e.which === 39) {
          next.click();
        }
      }
    });
  }

  interactionDetailsFn = (item)=>{
    this.setState({interaction: item})
  }

  showInteractions = (arrayInteractions, callback) => {
    let array = []
    arrayInteractions.forEach( element =>{
      array.push(<Item 
                    key={element.id} 
                    listkey={element.id} 
                    item={element} 
                    interactionDetailsFn={this.interactionDetailsFn} 
                    refresh={this.refresh} 
                    intSelected={this.state.intSelected} 
                    handleScrollToElement={callback}/>)
                })
    return array
  }

  refresh =  (listKey) => {
    this.setState({intSelected: listKey, interactions: this.state.interactions})
    this.showInteractions(this.state.interactions)
  }

  handleScrollToElement(event) {
    let anchor = document.getElementById('timelineContainer')
  }

  showUpSalesMessage = (level, message, show, close) => {
    this.setState({
      toastLevel: level,
      toastMessage: message,
      showToast: show,
      autoClose: close
    })
  }

  closeMessage = () => {
    this.setState({
      toastLevel: null,
      toastMessage: null,
      showToast: false,
      autoClose: false
    })
  }

  render() {
      return (
          <Row ref={this.myRef} className="bg-white pt-4  d-block d-center">
            
            <UpSalesMessage
                level={this.state.toastLevel}
                message={this.state.toastMessage}
                visible={this.state.showToast}
                autoClose={this.state.autoClose}
                closeMessage={this.closeMessage}
            />
            <Col className="dark-blue text-center pos-r mb-3">
              
              {/* <div className="btn-show-archived" title="Ver interacciones archivadas">
                <img src={icArchived}/>
              </div> */}
              <b className="light-grey l-fs-14 mayus">Interacciones de la oportunidad</b>
              <div className="row d-flex flex-row justify-content-end align-items-center" style={{position: 'absolute', left:0, top:-7, width: '100%'}}>

                {this.state.opportunityObject && 
                  <ShowArchivedInteractions 
                    opportunity={this.state.opportunityObject} 
                    refresh={this.refreshInteractions} 
                    msg={this.showUpSalesMessage}/>
                }
                {this.props.showCreateInteractionButton && this.state.opportunityObject && 
                  <CreateInteraction 
                    showUpSalesMessageFn={this.showUpSalesMessage} 
                    opportunity={this.state.opportunityObject} 
                    idCompany={this.state.opportunityObject.companyId}
                    refresh={this.refreshInteractions}/>
                }
              </div>
            </Col>
            {this.state.noInteractions === false ? <Col id="timelineContainer" className="mt-2">
              {this.state.loadingInteractions && <LoadingAnimation text="Cargando interacciones..."/>}
              {!this.state.loadingInteractions && <Row className="">
                <Col className="timeline">
                  <ol id="timeline">
                    {this.state.interactions && !this.state.loadingInteractions && this.showInteractions(this.state.interactions, this.handleScrollToElement)}
                    <li></li>
                  </ol>
                  {(this.state.mediaQuery && !this.state.noMoreInteractions) &&
                  <div className="row d-center p-3">
                      <img style={{cursor:'pointer'}} onClick={()=>this.moreInteractions()} src={blueBtnMore}/>
                  </div> 
                  }
                  <div className="arrows">
                    <button className="arrow arrow__prev disabled" disabled>
                      <img style={{width:30, height:30}} src={flechaIzq} alt="prev timeline arrow"/>
                    </button>
                    <button className="arrow arrow__next">
                      <img style={{width:30, height:30}} src={flechaDcha} alt="next timeline arrow"/>
                    </button>
                  </div>
                </Col>
              </Row>}
          </Col> : 
          <Col className="d-center py-5">No hay interacciones para esta oportunidad.</Col>
          }
      </Row>
    )
  }
}