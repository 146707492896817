import React, { useEffect, useState } from "react";

// PROPS: onChange={Function} - To return to parent country selected by user
//        CountryId={countryId} - if !==null we search provinces by country
//        resetProvince={value or null} - From parent to reset input and select
//        resetProvinceFn={Function} - To return to parent 'true' or 'false' and the parent reset province input and select
// COMPONENTES
import { DynamicSelect } from "./DynamicSelect";

// SERVICE
import { listProvinces, listProvincesByCountry } from "../../api_communication/service/ClientService";

// ************ TEMP *****************************************************
// PROPS DynamicSelect:
// optionsArray={} - array of values for show in options 
// searchTerm={} - returns user writing search word ->¿?
// onChange={handleChange} - Funcition to return user search word
// resetSearch={true or false} - prop: if true reset input value
// inputId={} - string - input id
// inputPlaceholder={} - string 'Buscar por X', 'Nombre de empresa', etc...
// ***********************************************************************

export function ProvinceDynamicSelectReports(props) {

    const [provincesArray, setProvincesArray] = useState([])
    const [resetSearch, setResetSearch] = useState(false)

    useEffect(() =>{
        if (props.resetProvince) {
            setProvincesArray([])
            setResetSearch(true)
        }
    },[props])

    const handleProvince = (userInput,userSelect)=> {
        setResetSearch(false)
        try {
            if (userSelect === null) {
                if(!userInput || userInput.length ===0) {
                    setProvincesArray([])
                    props.onChange(null)
                }
                if (userInput && userInput.length > 2 && !props.countryId) {
                    loadProvinces(userInput)
                }
                if (userInput && userInput.length > 2 && props.countryId) {
                    loadProvincesByCountry(props.countryId, userInput)
                } 
                    
                
            } else {
                props.resetProvinceFn(false)
                props.onChange(userSelect)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const loadProvinces = async (query) =>{
        try {
            let provinces = await listProvinces(0, 10000000, query)
            setProvincesArray(provinces)
        } catch (error) {
        console.log(error)
        }
    }
    
    const loadProvincesByCountry = async (countryId,query) =>{
        try {
          let provinces = await listProvincesByCountry(countryId, 0, 10000000, query)
          setProvincesArray(provinces)
        } catch (error) {
          console.log(error)
        }
      }

        return (
            <React.Fragment>
                <DynamicSelect 
                    optionsArray={provincesArray} 
                    onChange={handleProvince} 
                    resetSearch={resetSearch} 
                    inputId={'provinceFilter'} 
                    inputPlaceholder={'Provincia'}/>
            </React.Fragment>
        )
    }