import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner'

export default class LoadingAnimation extends React.Component{
    render(){
        return(
        <Row className="">
            <Col className="d-flex align-items-center justify-content-center flex-column py-5">
                <Spinner animation="grow" variant="primary" />
                <p className="pt-3 px-3">{this.props.text}</p>
            </Col>
        </Row>
        )
    }
}





