import { UpSales } from "../bundle/bundle";

// Tasks - List tasks for A PROJECT
//let tasks = UpSales.Project.listTasksForProject(projectId, page, pageSize, dateFrom, dateTo, schedulingState, states)
export const listTasksForAProjectRepository = async (projectId, page, pageSize, dateFrom, dateTo, schedulingState, states) => {
    try {
      var result = await UpSales.Project.listTasksForProject(projectId, page, pageSize, dateFrom, dateTo, schedulingState, states);
      return result;
    } catch (error) {
      console.log(error);
      throw Error (error)
    }
}

export const createTaskRepository = async (projectId, body) => {
  //console.log('createTaskRepository: projectId: ', projectId)
  //console.log('createTaskRepository: body: ', body)
  try {
    let result = await UpSales.Project.createTask(projectId, body)
    return result
  } catch (error) {
    console.log(error);
    throw Error (error)
  }
}

//UpSales.Project.listActive(page, pageSize, dateFrom, dateTo, query)
export const getProjectsListRepo = async (page, pageSize, dateFrom, dateTo, query)=>{
  try {
      let result = await UpSales.Project.listActive(page, pageSize, dateFrom, dateTo, query)
      //console.log('getProjectsListRepo: result: ', result)
      if (!result || result.error) {
          return false
        } else{
          return result
        }
  } catch (error) {
      console.log(error)
      return false
  }
}

// // UpSales.Project.listForOneCompanyWithTasksCount(page, pageSize, companyId, currentDate)
export const getProjectsListForOneCompanyWithTasksCountRepo = async (page, pageSize, companyId, currentDate)=>{
  try {
      let result = await UpSales.Project.listForOneCompanyWithTasksCount(page, pageSize, companyId, currentDate)
      //console.log('getProjectsListForOneCompanyWithTasksCountRepo: result: ', result)
      if (!result || result.error) {
          return false
        } else{
          return result
        }
  } catch (error) {
      console.log(error)
      return false
  }
}

// listTopCompaniesWithTasksCount
export const getTopCompaniesWithTasksCountListRepo = async (page, pageSize, currentDate)=>{
  try {
      let result = await UpSales.Project.listTopCompaniesWithTasksCount(page, pageSize, currentDate)
      //console.log('getTopCompaniesListRepo: result: ', result)
      if (!result || result.error) {
          return false
        } else{
          return result
        }
  } catch (error) {
      console.log(error)
      return false
  }
}

// UpSales.Project.listTopCompanies(page, pageSize)
export const getTopCompaniesListRepo = async (page, pageSize)=>{
  try {
      let result = await UpSales.Project.listTopCompanies(page, pageSize)
      //console.log('getTopCompaniesListRepo: result: ', result)
      if (!result || result.error) {
          return false
        } else{
          return result
        }
  } catch (error) {
      console.log(error)
      return false
  }
}

// UpSales.Project.countOverdueTasksForActiveProjects(currentDate)
export const getOverdueTasksForActiveProjectsRepo = async (currentDate)=>{
  try {
      let result = await UpSales.Project.countOverdueTasksForActiveProjects(currentDate)
      //console.log('getOverdueTasksForActiveProjectsRepo: result: ', result)
      if (!result || result.error) {
          return false
        } else{
          return result
        }
  } catch (error) {
      console.log(error)
      return false
  }
}

// UpSales.Project.countOverdueTasksForActiveProjectsAndCompany(currentDate, companyId)
export const countOverdueTasksForActiveProjectsAndCompanyRepo = async (currentDate, companyId)=>{
  try {
      let result = await UpSales.Project.countOverdueTasksForActiveProjectsAndCompany(currentDate, companyId)
      //console.log('countOverdueTasksForActiveProjectsAndCompanyRepo: result: ', result)
      if (!result || result.error) {
          return false
        } else{
          return result
        }
  } catch (error) {
      console.log(error)
      return false
  }
}

// UpSales.Project.listForCompany(page, pageSize, companyId, dateFrom, dateTo, query)
export const loadProjectsListByClientRepo = async (page, pageSize, clientId)=>{
  try {
      let result = await UpSales.Project.listForCompany(page, pageSize, clientId)
      //console.log('loadProjectsListByClientRepo: result : ', result)
      if (!result || result.error) {
          return false
      } else {
          return result
      }
  } catch (error) {
      console.log(error)
  }
}

// UpSales.Project.changeFabricated(projectId)
export const changeFabricatedRepo = async (projectId, fabricatedValue)=>{
  try {
      let result = await UpSales.Project.changeFabricated(projectId, fabricatedValue)
      //console.log('changeFabricatedRepoRepo: result : ', result)
      if (!result || result.error) {
          return false
      } else {
          return result
      }
  } catch (error) {
      console.log(error)
  }
}

// UpSales.Project.delete(projectId, endDate)
export const deleteProjectRepo = async (projectId, endDate)=>{
  //console.log('deleteProjectRepo: projectId : ', projectId)
  //console.log('deleteProjectRepo: endDate : ', endDate)
  try {
      let result = await UpSales.Project.delete(projectId, endDate)
      //console.log('deleteProjectRepo: result : ', result)
      if (!result || result.error) {
          return false
      } else {
          return result
      }
  } catch (error) {
      console.log(error)
  }
}


