import React, {useState, useEffect} from 'react';
import '../../assets/css/App.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {Link} from 'react-router-dom'
import UpSalesMessage from '../../componentes/UpSalesMessage'
import ShowArchivedInteractions from '../ShowArchivedInteractions'

// Imagenes
import defaultLogo from '../../assets/img/logoPorDefecto.png'
import icAdjunto from '../../assets/img/ic-adjuntar.png'

import {listInteractionsForAllCompanies, getCompany} from '../../api_communication/service/ClientService'

import {InteractionModeIcon, InteractionDueDateIcon, truncateText, OpportunityIconState} from '../CommonScripts'
import ProjectStateIcon from '../proyectos/ProjectStateIcon';

// COMPONENTS
import { CommercialDynamicSelectOnlyDropdown } from '../CommercialDynamicSelectOnlyDropdown';

export const InteractionRow = (props) => {

    const interaction = props.interaction;
    const [company, setCompany] = useState(null)
    const [userCanSeeClientDetails, setUserCanSeeClientDetails] = useState(false)

    useEffect( () => {
        if (props.loggedUser){
            setUserCanSeeClientDetails(!props.loggedUser.roles.includes('PROJECT_MANAGER'))
        }
        // async function giveMeClientObject(companyId) {
        //     try {
        //         let result = await getCompany(companyId)
        //         setCompany(result)
        //     } catch (error) {
        //         console.log(error)
        //     }
        // }
        //giveMeClientObject(interaction.company ? interaction.company.id : interaction.companyId)
        },[interaction]);

        return(  
             
                <Row className="row color-link interactions-row">
                    <Col xs={12}>
                        <Row className="h-100 interaction-size">
                            <Col className="bg-blue-light d-flex justify-content-center align-items-center " xs={12} md={1}>
                                <div>
                                    {interaction && userCanSeeClientDetails === false && <img className="logos-size" 
                                        src={interaction.company.thumbnail ? interaction.company.thumbnail : defaultLogo} 
                                        alt={interaction.company.alias} 
                                        title={interaction.company.alias}/>}
                                    {interaction && userCanSeeClientDetails === true && <Link to={{pathname:'/DetallesClientes', state: interaction.company}}  title="Ver detalles de esta empresa">
                                    <img className="logos-size" 
                                        src={interaction.company.thumbnail ? interaction.company.thumbnail : defaultLogo} 
                                        alt={interaction.company.alias} 
                                        title={interaction.company.alias}/>
                                    </Link>}
                                </div>
                            </Col>
                            <Col>
                                <Link className="row color-link" to={{pathname:'/DetalleInteraccion', state: interaction}}>  
                                    <Col className="d-flex justify-content-center align-items-center" xs={1}>
                                        <div>
                                            {interaction.opportunity && <OpportunityIconState opportunity={interaction.opportunity}/>}
                                            {interaction.project && <ProjectStateIcon projectState={interaction.project.state}/>}
                                        </div>    
                                    </Col>

                                    <Col className="d-flex justify-content-center align-items-center " xs={1}>
                                        <div style={{marginTop: 7}}><img className=" icon-mode-style" src={InteractionModeIcon(interaction.mode)}/></div>
                                    </Col>

                                    <Col className="d-flex justify-content-center align-items-start flex-column l-fs-14 pos-r">
                                        {interaction && <div className="bold">{interaction.company.alias}</div>}
                                        <div title={interaction.content} className="light-grey">{truncateText(interaction.content, 50)}</div>
                                        <div style={{position: 'absolute', top:5, right:5, color:'orange', fontSize: 14}}>
                                            {interaction.createdBy ? interaction.createdBy.username : ''}
                                        </div>
                                    </Col>
                                </Link>
                            </Col>
                            
                        </Row>
                    </Col>
                    <Col xs={12}>
                        <Row className="">
                            <Col className="bg-blue-light col-1" ></Col>
                            <Col className="l-fs-12 light-light-grey d-flex flex-row justify-content-end col-11 pb-1" >
                                <InteractionDueDateIcon interaction={interaction}/>
                                <div className="ml-1">{interaction.hasDocuments ? <img width={15} src={icAdjunto} /> : null}</div>
                                <div className="ml-2 light-grey-text">{interaction.date}</div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            
            
        )
}

export default function LastInteractions(props) {
 
    const [lastInteractions, setlastInteractions] = useState(null)
    const [toastLevel, setToastLevel] = useState(null)
    const [toastMessage, setToastMessage] = useState(null)
    const [showToast, setShowToast] = useState(null)
    const [autoClose, setAutoClose] = useState(null)

    useEffect ( () => {
        //LastInteractions: props: ', props)
        async function listOfInteractions() {
            try{
                let result = await listInteractionsForAllCompanies(0,10,'',["ACTIVE"])
                //console.log('LastInteractions: result: ', result)
                setlastInteractions(result)
            } catch(error){
                console.log(error)
            }
        }
    
        listOfInteractions()
    }, [props]);

    const refreshMe = async (userId = null) => {
        closeMessage()
        try {
            let result = await listInteractionsForAllCompanies(0,10,'',["ACTIVE"], userId)
            //console.log('refreshMe: result:: ', result)
            setlastInteractions(result)
        } catch(error) {
            console.log(error)
        }
    }

    const rows = [];
    lastInteractions && lastInteractions.forEach((interaction) => {
        rows.push(<InteractionRow loggedUser={props.loggedUser} interaction={interaction} key={interaction.id}/>)
    });

    const showUpSalesMessage = (level, message, show, close) => {
        setToastLevel(level)
        setToastMessage(message)
        setShowToast(show)
        setAutoClose(close)
    }

    const closeMessage = () => {
        setToastLevel(null)
        setToastMessage(null)
        setShowToast(false)
        setAutoClose(false)
    }

    const handleCommercialFromSelect = (commercialIdFromSelect)=>{
        refreshMe(commercialIdFromSelect)
    }

    return(
        <Container className="bg-white my-3 py-4">
            <Row>
                <UpSalesMessage
                    level={toastLevel}
                    message={toastMessage}
                    visible={showToast}
                    autoClose={autoClose}
                    closeMessage={closeMessage}
                />
                <Col className="dark-blue text-center mb-3 pos-r">
                    <CommercialDynamicSelectOnlyDropdown handleCommercialFromSelect={handleCommercialFromSelect} />
                    {lastInteractions && <ShowArchivedInteractions all={true} refresh={refreshMe} msg={showUpSalesMessage} />}
                    <b>ÚLTIMAS INTERACCIONES</b>
                </Col>
            </Row>
            {rows}
        </Container>
        
    )
}
