import React, { useState, useEffect } from "react";
import { Row } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import Col from "react-bootstrap/Col";

// COMPONENTS
import LoadingAnimationMiniSize from '../LoadingAnimationMiniSize' 
import ProjectStateIcon from "./ProjectStateIcon";
import UpSalesMessage from "../UpSalesMessage";

// FN PROYECTOS
import {changeStatusNameToSpanish, loadProjectsListByFilter, downloadCsvProject} from './funcionesProyectos'

// EXTERNAL LIBRARIES
import {Link} from 'react-router-dom'
import moment from 'moment';
import DatePicker from 'react-date-picker/dist/entry.nostyle';
import '../../assets/css/calendarDatePicker/Calendar.css'

// IMAGES
import btnMas from '../../assets/img/btn-mas.png'
import { randomId } from "../CommonScripts";

// PROPS
// show={showProjectsListModal}
// close={closeProjectsListModal}
// data={projectsListData}
// projectState={projectState}
// typology={typology}
// error={showUpSalesMessage}
// client={clientData}
// fabricated={fabricated}

export default function ListadoProyectos(props) {
  
  const [data,setData] = useState(null)
  const [showTable, setShowTable] = useState(true);
  const [pageNumber, setPageNumber] = useState(0)
  const [loadingMoreCompanies, setLoadingMoreCompanies] = useState(true)
  const [showBtnMoreData, setShowBtnMoreData] = useState(false)

  const [companyFilter, setCompanyFilter] = useState(null)
  const [companyIdFilter, setCompanyIdFilter] = useState(undefined)
  const [stateFilter, setStateFilter] = useState(undefined)
  const [fabricatedFilter, setFabricatedFilter] = useState(undefined) // selected filter from projects lists to sent to back Fn
  const [dateFromFilter, setDateFromFilter] = useState(undefined)
  const [dateToFilter, setDateToFilter] = useState(undefined)
  const [queryFilter, setQueryFilter] = useState(undefined)
  const [pasteFilter, setPasteFilter] = useState(undefined)
  const [formatFilter, setFormatFilter] = useState(undefined)
  const [originFilter, setOriginFilter] = useState(undefined)
  const [typologyFilter, setTypologyFilter] = useState(undefined)

  const [dateFromToShow, setDateFromToShow] = useState(null)
  const [dateToToShow, setDateToToShow] = useState(null)

  const [clientSelect, setClientSelect] = useState(null)
  const [formatsSelect, setFormatsSelect] = useState(null)
  const [originsSelect, setOriginsSelect] = useState(null)
  const [pastesSelect, setPastesSelect] = useState(null)
  const [statesSelect, setStatesSelect] = useState(null)
  const [typologiesSelect, setTypologiesSelect] = useState(null)
  const [fabricatedSelect, setFabricatedSelect] = useState(null) // option value from select in projects list

  const [downloadingExcel, setDownloadingExcel] = useState(false)
  const [showErrorMsg, setShowErrorMsg] = useState(false)

  const [isFiltering, setIsFiltering] = useState(false)

  const projectsListFilters = ()=>{
    let projectsFilters = {
      companyFilter:companyFilter,
      companyIdFilter: companyIdFilter,
      stateFilter:stateFilter, 
      fabricatedFilter:fabricatedFilter,
      dateFromFilter:dateFromFilter,
      dateToFilter:dateToFilter,
      queryFilter:queryFilter,
      pasteFilter:pasteFilter,
      formatFilter:formatFilter,
      originFilter:originFilter,
      typologyFilter:typologyFilter
    }
    //console.log('projectsListFilters:: ', projectsFilters)
    return projectsFilters
  }

  useEffect(()=>{
    if(props.filtersFromProjectDetail !== null){
      loadDataFilteredFromProjectDetail(props.filtersFromProjectDetail)
    }
  },[props.filtersFromProjectDetail])

  const loadDataFilteredFromProjectDetail = async (data) =>{
    try {
      let company = data.companyFilter
      let companyId = data.companyIdFilter 
      let state = data.stateFilter 
      let dateFrom = data.dateFromFilter 
      let dateTo = data.dateToFilter 
      let query = data.queryFilter 
      let paste = data.pasteFilter 
      let format = data.formatFilter
      let originVal = data.originFilter
      let typology = data.typologyFilter
      let fabricated = data.fabricatedFilter

      setPageNumber(0)

      setCompanyFilter(company)
      setCompanyIdFilter(companyId)
      setStateFilter(state)
      setFabricatedFilter(fabricated)
      setDateFromFilter(dateFrom)
      setDateToFilter(dateTo)
      setQueryFilter(query)
      setPasteFilter(paste)
      setFormatFilter(format)
      setOriginFilter(originVal)
      setTypologyFilter(typology)

      let result = await loadProjectsListByFilter(0, companyId, state, dateFrom, dateTo, query, paste, format, originVal, typology, fabricated)
      //console.log('loadDataFilteredFromProjectDetail: result:: ', result)
      setData(result.data)
      setIsFiltering(false)
      let companiesObject = []
      result.columnFilters.companies.forEach( item => {
        item.alias = item.alias.trim()
        companiesObject.push(item)
      })
      setShowTable(props.show)
      setFabricatedSelect(result.columnFilters.fabricated) 
      setFormatsSelect(result.columnFilters.formats)
      setClientSelect(companiesObject)
      setOriginsSelect(result.columnFilters.origins)
      setPastesSelect(result.columnFilters.pastes)
      setStatesSelect(result.columnFilters.states)
      setTypologiesSelect(result.columnFilters.typologies)
      let nextResults = await loadProjectsListByFilter(1, companyIdFilter, stateFilter ? stateFilter : props.projectState, dateFromFilter, dateToFilter, queryFilter, pasteFilter, formatFilter, originFilter, typologyFilter, fabricatedFilter)  
      if (nextResults.data.length !== 0) {
            setShowBtnMoreData(true)
          } else {
            setShowBtnMoreData(false)
          }
      setLoadingMoreCompanies(false)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if(props.filtersFromProjectDetail === null) {
      let companiesObject = []
      props.data.columnFilters.companies.forEach( item => {
        item.alias = item.alias.trim()
        companiesObject.push(item)
      })
      setData(props.data.data)
      setShowTable(props.show)
      setFabricatedSelect(props.data.columnFilters.fabricated) 
      setFormatsSelect(props.data.columnFilters.formats)
      setClientSelect(companiesObject)
      setOriginsSelect(props.data.columnFilters.origins)
      setPastesSelect(props.data.columnFilters.pastes)
      setStatesSelect(props.data.columnFilters.states)
      setTypologiesSelect(props.data.columnFilters.typologies)

      setStateFilter(props.projectState)
      setTypologyFilter(props.typology === null ? undefined : props.typology)
      setFabricatedFilter(props.fabricated)
      if (props.client) {
        setCompanyFilter(props.client)
        setCompanyIdFilter(props.client.id)
      }

      const loadMoreProjects = async ()=>{
    
        try {
          //UpSales.Project.listWithAllFilters(page, pageSize, companyId, state, dateFrom, dateTo, query, paste, format, origin, typology, fabricated)
          let result = await loadProjectsListByFilter(pageNumber + 1, props.client && props.client.id, props.projectState, undefined, undefined, undefined, undefined, undefined, undefined, undefined, props.fabricated )
          if (!result) {
            props.error('danger','Ha ocurrido un error cargando los datos!', true, false)
          } else {
            if (result.data.length !== 0) {
              setShowBtnMoreData(true)
            } 
          }
          setLoadingMoreCompanies(false)
        } catch (error) {
          props.error('danger','Ha ocurrido un error cargando los datos!', true, false)
          console.log(error)
        }
      }
      loadMoreProjects()
    }
  }, [props.projectState, props.typology]);

  const loadMoreProjects = async ()=>{
    try {
      //let result = await loadProjectsListByFilter(props.projectState,pageNumber + 1)
      let result = await loadProjectsListByFilter(pageNumber + 1, companyIdFilter, stateFilter ? stateFilter : props.projectState, dateFromFilter, dateToFilter, queryFilter, pasteFilter, formatFilter, originFilter, typologyFilter, fabricatedFilter)
      if (!result) {
        props.error('danger','Ha ocurrido un error cargando los datos!', true, false)
        setShowBtnMoreData(false)
      } else {
        if (result.data.length !== 0) {
          setData([...data,...result.data])
          let nextResults = await loadProjectsListByFilter(pageNumber + 2, companyIdFilter, stateFilter ? stateFilter : props.projectState, dateFromFilter, dateToFilter, queryFilter, pasteFilter, formatFilter, originFilter, typologyFilter, fabricatedFilter)
          if (nextResults.data.length !== 0) {
            setShowBtnMoreData(true)
          } else {
            setShowBtnMoreData(false)
          }
          setPageNumber(pageNumber + 1)
        } else {
          setShowBtnMoreData(false)
        }
      }
      
    } catch (error) {
      props.error('danger','Ha ocurrido un error cargando los datos!', true, false)
      console.log(error)
    }
  }

  const loadProjectsByFilter = async (filterObject) =>{
    setIsFiltering(true)

    let filter = filterObject.filter
    let value = filterObject.value

    let companyId = companyIdFilter 
    let state = stateFilter 
    let dateFrom = dateFromFilter 
    let dateTo = dateToFilter 
    let query = queryFilter 
    let paste = pasteFilter 
    let format = formatFilter
    let originVal = originFilter
    let typology = typologyFilter
    let fabricated = fabricatedFilter
    //console.log('loadProjectByFilter: filterObject: ', filterObject)
    switch (filter) {
      case 'company':
        companyId = value.id
        if (!value.id){
          setCompanyFilter(null)
        } else {
          setCompanyFilter({id: value.id, alias: value.alias, thumbnail: value.thumbnail})
        }
        setCompanyIdFilter(value.id)
        break;
      case 'paste':
        paste = value
        setPasteFilter(value)
        break;
      case 'fabricated':
        fabricated = (value =='all' ? undefined : value) // values: 'true', 'false','MUESTRAS' or 'all' from select
        setFabricatedFilter(fabricated)
        break;
      case 'typology':
        typology = value
        setTypologyFilter(value)
        break;
      case 'format':
        format = value
        setFormatFilter(value)
        break;
      case 'origin':
        originVal = value
        setOriginFilter(value)
        break;
      case 'state':
        state = value
        setStateFilter(value)
        break;
      case 'query':
        query = value
        setQueryFilter(value)
        break;
      case 'dateTo':
        dateTo = value
        setDateToFilter(value)
        break;
      case 'dateFrom':
        dateFrom = value
        setDateFromFilter(value)
        break;
      default:
          break;
    }
    setPageNumber(0)
    try {
      let result = await loadProjectsListByFilter(0, companyId, state, dateFrom, dateTo, query, paste, format, originVal, typology, fabricated)
      setData(result.data)
      setIsFiltering(false)
      let companiesObject = []
      result.columnFilters.companies.forEach( item => {
        item.alias = item.alias.trim()
        companiesObject.push(item)
      })
      if(result.data.length !== 0){
        setFormatsSelect(result.columnFilters.formats)
        setClientSelect(companiesObject)
        setOriginsSelect(result.columnFilters.origins)
        setPastesSelect(result.columnFilters.pastes)
        setStatesSelect(result.columnFilters.states)
        setTypologiesSelect(result.columnFilters.typologies)
        setFabricatedSelect(result.columnFilters.fabricated)
      }

      let nextResults = await loadProjectsListByFilter(1, companyId, state, dateFrom, dateTo, query, paste, format, originVal, typology, fabricated)
      //console.log('loadProjectsByFilter > nextResults: ', nextResults)
      if (nextResults.data.length !== 0) {
        setShowBtnMoreData(true)
      } else {
        setShowBtnMoreData(false)
      }
    } catch (error) {
      props.error('danger','Ha ocurrido un error cargando los datos!', true, false)
      console.log(error)
    }
  }

  const ShowSelectOptions = props => {
    //console.log('ShowSelectOptions: props: ', props)
    return (
      <React.Fragment>
        <option id={props.id} value={props.data}>{props.data}</option>
      </React.Fragment>
    )
  }

  const ShowSelectStatesOptions = props => {
    //console.log('ShowSelectOptions: props: ', props)
     //selected={props.projectState ===1 ? true : false}
    return (
      <React.Fragment>
        <option value={props.data}>{changeStatusNameToSpanish(props.data)}</option>
      </React.Fragment>
    )
  }

  const ShowSelectAllOption = props => {
    return (
      <React.Fragment>
        <option id="9999999" value='all'>Todos</option>
      </React.Fragment>
    )
  }

  const ShowClientSelectOptions = props => {
    return (
      <React.Fragment>
        <option id={props.id} data-thumbnail={props.thumbnail} data-id={props.id} value={props.alias}>{props.alias}</option>
      </React.Fragment>
    )
  }

  const ShowClientSelect = (props) => {
    //console.log('ShowClientSelect: props.data:', props.data)
    // id and alias company
    let rows = []
    rows.push(
      <ShowSelectAllOption key={randomId()}></ShowSelectAllOption>
    )
    props.data.forEach(item => {
      rows.push(
        <ShowClientSelectOptions
        id={item.id}
        alias = {item.alias}
        thumbnail={item.thumbnail}
        key={randomId()}
        />
      )
    })
    return rows
  }

  const ShowStatesOptionsSelect = props => {
    let rows = []
    rows.push(
      <ShowSelectAllOption key={randomId()}></ShowSelectAllOption>
    )
    props.data.forEach(item => {
      rows.push(
        <ShowSelectStatesOptions
        data={item}
        key={randomId()}
        />
      )
    })
    return rows
  }

  const ShowFabricatedOptions = props => {
    return (
      <React.Fragment>
        <option id={props.id} value={props.data}>{props.data ==="true" ? 'SI' : (props.data === 'false' ? 'NO' : 'MUESTRAS')}</option>
      </React.Fragment>
    )
  }

  const ShowFabricatedSelect = props => {
    let rows = []
    rows.push(
      <ShowSelectAllOption key={randomId()}></ShowSelectAllOption>
    )
    props.data.forEach(item => {
      rows.push(
        <ShowFabricatedOptions
        data={item}
        key={randomId()}
        />
      )
    })
    return rows
  }

  const ShowOptionsSelect = (props) => {
    //console.log('ShowFormatsSelect: props.data:', props.data)
    let rows = []
    rows.push(
      <ShowSelectAllOption key={randomId()}></ShowSelectAllOption>
    )
    props.data.forEach(item => {
      rows.push(
        <ShowSelectOptions
        data={item}
        key={randomId()}
        />
      )
    })
    return rows
  }

  const DocumentRow = (props) => {
    return (
      <React.Fragment>
        <Row id={props.data.id} className="justify-content-center">
          <Col md={12} >
            <Link id={props.data.id} style={{textDecoration:"none"}} className="mouse light-grey linksTables row" to={{pathname:'/DetalleProyecto', state: props.data, projectsFilters: projectsListFilters()}}  title="Ver detalles de este proyecto">
              
              <Col className=" l-fs-14 mayus my-2" >
                <p>{props.data.startingDate}</p>
              </Col>
              {<Col className="l-fs-14 mayus my-2" >
                <p>{props.data.company.alias}</p>
              </Col>}
              <Col className="l-fs-14 mayus my-2" >
                <p>{props.data.paste}</p>
              </Col>
              <Col className="l-fs-14 mayus my-2" >
                <p>{props.data.format}</p>
              </Col>
              <Col className="l-fs-14 mayus my-2" >
                <p>{props.data.name}</p>
              </Col>
              <Col className="l-fs-14 mayus my-2" >
                <p>{props.data.origin}</p>
              </Col>
              <Col className="l-fs-14 mayus my-2" >
                <p>{props.data.typology}</p>
              </Col>
              <Col className="d-flex align-items-start l-fs-14 mayus my-2" >
                {props.data.state && <ProjectStateIcon
                    styles={{width: '25px', height: '25px'}}
                    classNames="ml-2 mb-1"
                    projectState = {props.data.state}
                  />}
                  <p className="ml-2">{(changeStatusNameToSpanish(props.data.state))}</p>
              </Col>
              <Col className="l-fs-14 mayus my-2 text-left" >
                <p>{props.data.fabricated === 'true' ? 'SI' : (props.data.fabricated === 'false' ? 'NO' : props.data.fabricated)}</p>
              </Col>
            </Link>
          </Col>
          <Col className="brd-b-blue" md={12}></Col>
        </Row>
      </React.Fragment>
    );
  };

  const fillData = (data) => {
    let rows = [];
    data.length !== 0 &&
      data.forEach((element) => {
        rows.push(
          <DocumentRow
            data={element}
            key={element.id}
            client={companyFilter}
          />
        );
      });
    return rows;
  };

  const closeModal = ()=>{
    setCompanyIdFilter(undefined)
    setCompanyFilter(undefined)
    setFabricatedFilter(undefined)
    setStateFilter(undefined)
    setDateFromFilter(undefined)
    setDateToFilter(undefined)
    setQueryFilter(undefined)
    setPasteFilter(undefined)
    setFormatFilter(undefined)
    setOriginFilter(undefined)
    setTypologyFilter(undefined)
    setFormatsSelect(null)
    setClientSelect(null)
    setOriginsSelect(null)
    setPastesSelect(null)
    setStatesSelect(null)
    setFabricatedSelect(null)
    setTypologiesSelect(null)
    setPageNumber(0)
    props.close()
  }

  const handleClient = (event) => {
    let div = document.getElementById("clientSelect");
    let clientId = div.options[div.selectedIndex].getAttribute('data-Id');
    let clientName = div.options[div.selectedIndex].text;
    let clientThumbnail = div.options[div.selectedIndex].getAttribute('data-thumbnail');
    if (!clientId) clientId = undefined
    loadProjectsByFilter({filter: 'company', value: {id:clientId, alias:clientName, thumbnail: clientThumbnail}})
  }

  const handleFabricated = (event) => {
    loadProjectsByFilter({filter: 'fabricated', value:event.target.value})
  }
  const handlePasta = (event)=>{
    loadProjectsByFilter({filter: 'paste', value:event.target.value ==='all' ? undefined : event.target.value})
  }
  const handleState = (event)=>{
    loadProjectsByFilter({filter: 'state', value:event.target.value ==='all' ? undefined : event.target.value})
  }
  const handleTypology = (event)=>{
    loadProjectsByFilter({filter: 'typology', value:event.target.value ==='all' ? undefined : event.target.value})
  }
  const handleFormato = (event) => {
    loadProjectsByFilter({filter: 'format', value:event.target.value ==='all' ? undefined : event.target.value})
  }
  const handleOrigin = (event) => {
    loadProjectsByFilter({filter: 'origin', value:event.target.value ==='all' ? undefined : event.target.value})
  }
  const handleDateFrom = (newDate) => {
    setDateFromToShow(newDate)
    if (newDate) {
      newDate = moment(newDate).format("DD/MM/YYYY")
    } else {
      newDate = undefined
    }
    loadProjectsByFilter({filter: 'dateFrom', value:newDate})
  }
  const handleDateTo = (newDate) => {
    setDateToToShow(newDate)
    if (newDate) {
      newDate = moment(newDate).format("DD/MM/YYYY")
    } else {
      newDate = undefined
    }
    loadProjectsByFilter({filter: 'dateTo', value:newDate})
  }
  const handleProjectName = (event) => {
    //console.log('input query: ', event.target.value)
    loadProjectsByFilter({filter: 'query', value: event.target.value})
  }

  const exportToExcel = async ()=>{
    setDownloadingExcel(true)
    try {
      let result = await downloadCsvProject(companyIdFilter, (stateFilter==='ACTIVE' ? undefined : stateFilter), dateFromFilter, dateToFilter, queryFilter, pasteFilter, formatFilter, originFilter, typologyFilter, fabricatedFilter)
      if (result === false) {
        setShowErrorMsg(true)
      }
      setDownloadingExcel(false)
    } catch (error) {
      console.log(error)
    }
  }

  const closeErrorMessage = ()=>{
    setShowErrorMsg(false)
  }

  return (
    <React.Fragment>
      <UpSalesMessage
        level={'danger'}
        message={'Ha ocurrido un error descargando el archivo excel.'} 
        visible={showErrorMsg} 
        autoClose={true}
        closeMessage={closeErrorMessage}
      />
      <Modal
        style={{ overflow: "hidden" }}
        id="projects-list"
        className="modal-border"
        show={showTable}
        onHide={() => props.close()}
        dialogClassName="bg-white"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header className="justify-content-center modal-border modal-border pos-r pb-0 bg-blue-light">
          <div className="" style={{ position: "absolute", top: 25, right: 35 }}>
            <div style={{ fontWeight: "bold", cursor: "pointer", fontSize: 21 }} onClick={()=>closeModal()}>
              X
            </div>
          </div>
          <Modal.Title id="example-custom-modal-styling-title" className="dark-blue d-column w-100">
            {(companyFilter)&& 
              <React.Fragment>
                <div className="d-column pt-4">
                  <div className="d-flex flex-row justify-content-center align-items-center">
                    <div>
                        <img  width={30} height={30} src={companyFilter.thumbnail} />
                    </div>
                    <p className="ml-3 text-center">{companyFilter.alias}</p>
                  </div>
                  <div className="brd-b-blue w-100 mb-3"></div>
                </div>
              </React.Fragment>
            }
            <b className="mt-3 text-center mt-4">LISTADO DE PROYECTOS</b>
            <Row id="CreateEditProjectCss" className="justify-content-center w-100 no-gutters bg-blue-light">
              {((props.data && props.data.length !== 0) || props.filtersFromProjectDetail !== null) && <Col xs={11} className="pb-5 d-flex justify-content-end px-5 ">
                <div onClick={()=>exportToExcel()} title="Not implemented yet" className="modal-big-button d-center btn btn-primary">
                  {downloadingExcel ? <div className="mr-2"><LoadingAnimationMiniSize color={'white'}></LoadingAnimationMiniSize></div> : ''}  
                  Exportar a Excel
                </div>
              </Col>}
              <Col xs={12}>
                <Row className="justify-content-center px-3">
                  <Col md={12} className="bg-white pt-3">
                    <Row>
                      <Col id="CreateEditProjectCss" className="light-grey l-fs-14 mayus mb-2">
                        <p className="pb-1 dark-blue"><b>Fecha Solicitud</b></p>
                        <div className="d-flex align-items-center mb-1">
                          <span className="l-fs-12" style={{textTransform: "lowercase",textTransform: "capitalize"}}>Desde:&nbsp;</span>
                          <DatePicker
                            onChange={(newDate) => {handleDateFrom(newDate)}}
                            value={dateFromToShow}
                            locale="es-ES"
                          />
                        </div>
                        <div className="d-flex align-items-center mt-1 mb-1">
                          <span className="l-fs-12" style={{textTransform: "lowercase",textTransform: "capitalize"}}>Hasta:&nbsp;&nbsp;</span>
                          <DatePicker
                            onChange={(newDate) => {handleDateTo(newDate)}}
                            value={dateToToShow}
                            locale="es-ES"
                          />
                        </div>
                      </Col>
                      {<Col className="light-grey l-fs-14 mayus my-2 d-flex flex-column">
                        <p className="pb-1 dark-blue"><b>Cliente</b></p>
                        {clientSelect && <select className="my-auto" id="clientSelect" style={{width: '90%'}} value={companyFilter ? companyFilter.alias : ''} onChange={handleClient}>
                          <ShowClientSelect data={clientSelect}></ShowClientSelect>
                        </select>}
                      </Col>}
                      <Col className="light-grey l-fs-14 mayus my-2 d-flex flex-column">
                        <p className="pb-1 dark-blue"><b>Pasta</b></p>
                        <select className="my-auto" style={{width: '90%'}} value={pasteFilter} onChange={handlePasta}>
                        {pastesSelect && <ShowOptionsSelect data={pastesSelect}></ShowOptionsSelect>}
                        </select>
                      </Col>
                      <Col className="light-grey l-fs-14 mayus my-2 d-flex flex-column">
                        <p className="pb-1 dark-blue"><b>Formato</b></p>
                        <select className="my-auto" value={formatFilter} defaultValue={formatFilter} onChange={handleFormato}>
                          {formatsSelect && <ShowOptionsSelect data={formatsSelect}></ShowOptionsSelect>}
                        </select>
                      </Col>
                      <Col className="light-grey l-fs-14 mayus my-2 d-flex flex-column">
                        <b className="pb-1 dark-blue">Nombre</b>
                        <input className="my-auto" style={{width: '90%'}} type="text" placeholder="Nombre proyecto" onChange={handleProjectName}/>
                      </Col>
                      <Col className="light-grey l-fs-14 mayus my-2 d-flex flex-column">
                        <p className="pb-1 dark-blue"><b>Campaña</b></p>
                        <select className="my-auto" value={originFilter} onChange={handleOrigin}>
                        {originsSelect && <ShowOptionsSelect data={originsSelect}></ShowOptionsSelect>}
                        </select>
                      </Col>
                      <Col className="light-grey l-fs-14 mayus my-2 d-flex flex-column">
                        <p className="pb-1 dark-blue"><b>Tipología</b></p>
                        <select className="my-auto" value={typologyFilter} onChange={handleTypology}>
                        {typologiesSelect && <ShowOptionsSelect data={typologiesSelect}></ShowOptionsSelect>}
                        </select>
                      </Col>
                      <Col className="light-grey l-fs-14 mayus my-2 d-flex flex-column" >
                        <p className="pb-1 dark-blue"><b>Situación</b></p>
                        {statesSelect && <select className="my-auto" value={stateFilter} onChange={handleState}>
                            <ShowStatesOptionsSelect data={statesSelect}></ShowStatesOptionsSelect>
                          </select>}
                      </Col>
                      <Col className="light-grey l-fs-14 mayus my-2 d-flex flex-column">
                        <p className="pb-1 dark-blue"><b>Fabricado</b></p>
                        <select className="my-auto" style={{width: '90%'}} value={fabricatedFilter} onChange={handleFabricated}>
                        {fabricatedSelect && <ShowFabricatedSelect data={fabricatedSelect}></ShowFabricatedSelect>}
                        </select>
                      </Col>
                    </Row>
                  </Col>
                  <Col className="brd-b-blue" md={12}></Col>
                </Row>
              </Col>
            </Row>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="container modal-border bg-blue-light pt-0" style={{overflowY: "auto", width:'100%', minWidth:'100%' }}>
        {isFiltering === false ? <Row className="justify-content-center">
            <Col xs={12}>
              {data && data.length !== 0 ? (
                <div className="row  px-3">
                  <div className="col-12 pb-3 bg-white">
                    {fillData(data)}
                  </div>
                </div>
                
              ) : (
                <div className="d-center py-5 bg-white light-grey">
                  No hay datos para mostrar
                </div>
              )}

             
            </Col>

            <Col className=" col-12 bg-white pb-3">
              {loadingMoreCompanies && <LoadingAnimationMiniSize></LoadingAnimationMiniSize>}
            </Col>
            
            {showBtnMoreData ?
              <Col className=" col-12 btn-mas-container flex-column bg-white pb-5">
                  {!loadingMoreCompanies && <a href="#" onClick={()=>loadMoreProjects()}>
                      <img title="Ver más proyectos" src={btnMas}></img>
                  </a>}
                  
              </Col> :
              <React.Fragment>
                {!loadingMoreCompanies && data && data.length !== 0 && <Col  className=" col-12">
                  <div className="d-center mb-5 light-grey bg-white pb-5">
                    No hay más datos para mostrar
                  </div>
                </Col>}
              </React.Fragment> 
              }

          </Row>  : 
            <div className="py-5">
              <LoadingAnimationMiniSize></LoadingAnimationMiniSize>
            </div>
            }
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
}
