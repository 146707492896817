import React, { useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

// EXTERNAL LIBRARIES
import moment from 'moment';
moment.locale('es');

// DATE FORMAT TO BACKEND: moment().format("DD/MM/YYYY")
// NOTAS: Devuelve el dia inicial y el tipo de filtro ('yesterday', 'lastWeek' o 'lastMonth)
// NOTAS: En el padre, configuramos el dia final teniendo en cuenta que back incluye el dia inicial y cuenta
//        hasta el dia final sin incluir éste último.
// PROPS: sendDates={getDatesFromFilters} - Fn to return dates selected by user to parent

export default function InteractionsReportDates(props) {
    const [dateType, setDateType] = useState('yesterday') // can be 'yesterday', 'lastWeek' and 'lastMonth'

    const [dayToShow, setDayToShow] = useState(null)

    const [initWeekToShow, setInitWeekToShow] = useState()
    const [endWeekToShow, setEndWeekToShow] = useState()

    const [initMonthToShow, setInitMonthToShow] = useState()

    const [actualDate, setActualDate] = useState(null) // showing date selected by use at this moment // moment no format

    useEffect(()=>{
        let today = moment()
        let yesterday = today.subtract(1, 'day')
        setActualDate(yesterday) // save moment object
        setDayToShow(yesterday.format('dddd DD/MM/YYYY')) // to show apply .format('dddd DD/MM/YYYY')
    },[])

    const handleBtn = (dateBtnSelected)=>{
        setDateType(dateBtnSelected)
        resetDateBtnStyles()
        document.getElementById(dateBtnSelected).classList.add('dateBtnClicked')
        calculateDateByBtns(dateBtnSelected)
    }
    
    const resetDateBtnStyles = ()=>{
        document.getElementById('yesterday').classList.remove('dateBtnClicked')
        document.getElementById('lastWeek').classList.remove('dateBtnClicked')
        document.getElementById('lastMonth').classList.remove('dateBtnClicked')
        //document.getElementById('resetDate').classList.remove('dateBtnClicked')
    }

    const calculateDateByBtns = (dateBtnSelected)=>{
    // click en btn yesterday devuelve a padre initDate fecha de ayer, idem con los otros btns al clickar
    if (dateBtnSelected === 'yesterday') {
        setDateType('yesterday')
        let today = moment()
        let yesterday = today.subtract(1, 'days')
        setActualDate(yesterday)
        props.sendDates(yesterday,'yesterday')
        setDayToShow(yesterday.format('dddd DD/MM/YYYY'))
    }
    if (dateBtnSelected === 'lastWeek') {
        setDateType('lastWeek')
        let today = moment()
        today.subtract(7,'days')
        const fromDate = today.startOf('isoWeek');
        setActualDate(fromDate)
        props.sendDates(fromDate,'lastWeek')
        setInitWeekToShow(fromDate.format('DD/MM/YYYY'))
        const toDate = today.endOf('isoWeek');
        setEndWeekToShow(toDate.format('DD/MM/YYYY'))
    }
    if (dateBtnSelected === 'lastMonth') {
        setDateType('lastMonth')
        let today = moment()
        today.subtract(1,'month')
        const fromDate = today.startOf('month');
        setActualDate(fromDate)
        props.sendDates(fromDate,'lastMonth')
        setInitMonthToShow(fromDate.format('MMMM YYYY'))
    }
    }

    const dateNextOrBefore = (arrow)=>{
        let fromDate = null
        let toDate = null
        let temp = actualDate
        resetDateBtnStyles()
        switch (dateType) {
            case 'yesterday':
                if (arrow === 'before') {
                    temp.subtract(1, 'day')
                } else {
                    let today = moment().format('DD/MM/YYYY')
                    let dateChoosen = moment(actualDate).format('DD/MM/YYYY')
                    if (today == dateChoosen) {
                        return false
                    }
                    temp.add(1, 'day')
                }
                setActualDate(temp)
                setDayToShow(temp.format('dddd DD/MM/YYYY'))
                props.sendDates(temp,dateType)
                break;

            case 'lastWeek':
                if (arrow === 'before') {
                    temp.subtract(7,'days')
                } else {
                    let today = moment()
                    let firstDayOfCurrentWeek = today.startOf('isoWeek').format('DD/MM/YYYY')
                    let firstDayOfChoosenWeek = actualDate.startOf('isoWeek').format('DD/MM/YYYY')
                    //if (moment(firstDayOfCurrentWeek).isSame(actualDate.startOf('week').format('DD/MM/YYYY'))) {
                    if (firstDayOfCurrentWeek == firstDayOfChoosenWeek) {
                        return false
                    } else{
                        temp.add(7,'days')
                    }
                    
                }
                fromDate = temp.startOf('isoWeek');
                setActualDate(fromDate)
                props.sendDates(fromDate,dateType)
                setInitWeekToShow(fromDate.format('DD/MM/YYYY'))
                toDate = temp.endOf('isoWeek');
                setEndWeekToShow(toDate.format('DD/MM/YYYY'))
                temp.subtract(6,'days')
                break;
            
            case 'lastMonth':
                if (arrow === 'before') {
                    temp.subtract(1,'month')
                } else {
                    let today = moment()
                    let firstDayOfactualMonth = today.startOf('month').format('DD/MM/YYYY')
                    if (moment(firstDayOfactualMonth).isSame(actualDate.startOf('month').format('DD/MM/YYYY'))) {
                        return false
                    } else {
                        temp.add(1,'month')
                    }
                    
                }
                fromDate = temp.startOf('month');
                setActualDate(temp)
                props.sendDates(fromDate,dateType)
                setInitMonthToShow(fromDate.format('MMMM YYYY'))
                toDate = temp.endOf('month');
                temp.startOf('month');
                break;

            default:
                break;
        }
    }

    return(
        <React.Fragment>
            <Row>
                <Col xs={12} className="d-flex flex-row justify-content-center align-items-center">
                    <div onClick={()=>handleBtn('yesterday')} id="yesterday" className="dateBtn dateBtnClicked">Día</div>
                    <div onClick={()=>handleBtn('lastWeek')} id="lastWeek" className="dateBtn">Semana</div>
                    <div onClick={()=>handleBtn('lastMonth')} id="lastMonth" className="dateBtn">Mes</div>
                    {/* <div onClick={()=>handleYesterday('resetDate')} id="resetDate" className="dateBtn dateBtnClicked">X</div> */}
                </Col>
                <Col xs={12} className="d-flex flex-row align-items-center  justify-content-center pt-3 align-items-center">
                    <div onClick={()=>dateNextOrBefore('before')} className="mr-2 px-2 mouse" style={{border:'1px solid grey', borderRadius:'50%'}} title="Anterior">&lt;</div>
                    {dateType === 'yesterday' && <div style={{width:'205px'}} className="d-center black"><p>{dayToShow}</p></div>}
                    {dateType === 'lastWeek' && <div style={{width:'205px'}} className="d-center black"><p>{initWeekToShow} - {endWeekToShow}</p></div>}
                    {dateType === 'lastMonth' && <div style={{width:'205px'}} className="d-center black"><p>{initMonthToShow}</p></div>}
                    <div onClick={()=>dateNextOrBefore('next')} className="ml-2 px-2 mouse" style={{border:'1px solid grey', borderRadius:'50%'}}  title="Posterior">&gt;</div>
                </Col>
            </Row>
        </React.Fragment>
    )
}

