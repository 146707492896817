import React, { Component } from "react";

// ROUTER
import { NavLink } from "react-router-dom";

// FUNCIONES
import { abrirEsconderMenuLateral } from "../assets/js/menuLateral";

// React-Bootstrap
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Media from "react-bootstrap/Media";

// IMAGENES
import logo from "../assets/img/up-last.png";
import icdashboard from "../assets/img/ic-dashboard-blco.png";
import icteam from "../assets/img/ic-team.png";
import icoportunidad from "../assets/img/ic-oportunidad-gris.png";
import icinformes from "../assets/img/ic-informes.png";
import icclientes from "../assets/img/ic-clientes.png";
import icproyectos from "../assets/img/ic-proyectos.png";
import { getCurrentUserData } from "../api_communication/service/UsersService";

export default class LateralMenu extends Component {
  constructor(props) {
    super(props)
    this.state = {}
    this.REPORTS_MODULE_ENABLED = process.env.REACT_APP_REPORTS_MODULE_ENABLED === 'true'
    this.PROJECTS_MODULE_ENABLED = process.env.REACT_APP_PROJECTS_MODULE_ENABLED === 'true'
  }

  async componentDidMount() {
    try {
      let user
      if (!this.props.loggedUser)
        user = await getCurrentUserData();
      else
        user = this.props.loggedUser

      if (user.roles.includes("MANAGER")) {
        let hiddenUsers = document.querySelectorAll(".menu-manager");
        hiddenUsers.forEach(function (item) {
          item.classList.remove("d-none");
        });
      }
      if (user.roles.includes("SALES_DIRECTOR")) {
        let hiddenUsers = document.querySelectorAll(".menu-sales-director");
        hiddenUsers.forEach(function (item) {
          item.classList.remove("d-none");
        });
      }
      if (user.roles.includes("SALES_PERSON") || user.roles.includes("ADMINISTRATIVE")) {
        let hiddenUsersAndProjects = document.querySelectorAll(".menu-person-admtv");
        hiddenUsersAndProjects.forEach(function (item) {
          item.classList.remove("d-none");
        });
      }
      if (user.roles.includes("PROJECT_MANAGER")) {
        let hiddenOnlyProjects = document.querySelectorAll(".menu-proyectos");
        hiddenOnlyProjects.forEach(function (item) {
          item.classList.remove("d-none");
        });
      }
    } catch (error) {
      console.log(error);
    }

    window.addEventListener("resize", this.menuResponsive);
    this.menuResponsive();
  }

  componentWillUnmount() {
    window.addEventListener("resize", this.menuResponsive);
  }

  menuResponsive() {
    if (window.screen.width < 769) {
      var menu = document.getElementById("menu");
      menu.classList.add("menu-responsive");
      var iconoSacarMenu = document.getElementById("ic-menu-out");
      iconoSacarMenu.classList.remove("d-none");
      iconoSacarMenu.addEventListener("click", function () {
        menu.classList.add("d-block");
      });
      var iconoEsconderMenu = document.getElementById("ic-menu");
      iconoEsconderMenu.addEventListener("click", function () {
        menu.classList.remove("d-block");
      });
    } else {
      var menu = document.getElementById("menu");
      if (menu) {
        menu.classList.remove("menu-responsive");
      }
    }
  }

  render() {
    return (
      <div
        id="menu"
        className="brd-r-blue pl-0 col-8 col-sm-4  col-md-3 col-lg-2"
      >
        <Container id="containerBtn" className="screen-v pt-4 pos-r">
          <Row className="justify-content-center mb-5">
            <Col id="logo-menu" xs={10} md={8} className="d-center" >
              <NavLink  exact to="/Dashboard">
                <img src={logo}></img>
              </NavLink>
            </Col>
          </Row>
          <Row className="justify-content-center mt-5 d-none menu-proyectos menu-manager menu-sales-director menu-person-admtv">
            <Col xs={12} className="">
              <Media className="menu-item">
                <img
                  width="22px"
                  height="22px"
                  className="align-selft-center ic-margin mr-3"
                  src={icdashboard}
                  alt="icono potencia"
                />
                <Media.Body>
                  <NavLink
                    activeClassName="navbar__link--active"
                    className="navbar__link color-link l-fs-15"
                    exact
                    to="/Dashboard"
                  >
                    Escritorio
                  </NavLink>
                </Media.Body>
              </Media>
            </Col>
          </Row>
          <Row className="justify-content-center mt-3 d-none menu-manager">
            <Col xs={12} className="">
              <Media className="menu-item">
                <img
                  width="22px"
                  height="22px"
                  className="align-selft-center ic-margin mr-3"
                  src={icteam}
                  alt="icono potencia"
                />
                <Media.Body>
                  <NavLink
                    activeClassName="navbar__link--active"
                    className="navbar__link  color-link l-fs-15"
                    to="/Usuarios"
                  >
                    Usuarios
                  </NavLink>
                </Media.Body>
              </Media>
            </Col>
          </Row>
          <Row className="justify-content-center mt-3 d-none menu-manager menu-sales-director menu-person-admtv">
            <Col xs={12} className="">
              <Media className="menu-item">
                <img
                  width="22px"
                  height="22px"
                  className="align-selft-center ic-margin mr-3"
                  src={icclientes}
                  alt="icono potencia"
                />
                <Media.Body>
                  <NavLink
                    activeClassName="navbar__link--active"
                    className="navbar__link color-link l-fs-15"
                    to="/Clientes"
                  >
                    Clientes
                  </NavLink>
                </Media.Body>
              </Media>
            </Col>
          </Row>
          <Row className="justify-content-center mt-3 d-none menu-manager menu-sales-director menu-person-admtv">
            <Col xs={12} className="">
              <Media className="menu-item">
                <img
                  width="22px"
                  height="22px"
                  className="align-selft-center ic-margin mr-3"
                  src={icoportunidad}
                  alt="icono potencia"
                />
                <Media.Body>
                  <NavLink
                    activeClassName="navbar__link--active"
                    className="navbar__link color-link l-fs-15"
                    to="/Oportunidad"
                  >
                    Oportunidades
                  </NavLink>
                </Media.Body>
              </Media>
            </Col>
          </Row>
          { this.REPORTS_MODULE_ENABLED && <Row className="justify-content-center mt-3 d-none menu-manager menu-sales-director menu-person-admtv">
            <Col xs={12} className="">
              <Media className="menu-item">
                <img
                  width="17px"
                  height="16px"
                  className="align-selft-center ic-margin mr-3"
                  src={icinformes}
                  alt="icono informes"
                />
                <Media.Body>
                  <NavLink
                    activeClassName="navbar__link--active"
                    className="navbar__link color-link l-fs-15 ml-1"
                    to="/Informes"
                  >
                    Informes
                  </NavLink>
                </Media.Body>
              </Media>
            </Col>
          </Row>}
          { this.PROJECTS_MODULE_ENABLED && <Row className="justify-content-center mt-3 d-none menu-manager menu-sales-director menu-proyectos">
            <Col xs={12} className="">
              <Media className="menu-item">
                <img
                  width="18px"
                  height="18px"
                  className="align-selft-center ic-margin mr-3"
                  src={icproyectos}
                  alt="icono potencia"
                />
                <Media.Body>
                  <NavLink
                    activeClassName="navbar__link--active"
                    className="navbar__link color-link l-fs-15"
                    to="/Proyectos"
                  >
                    Proyectos
                  </NavLink>
                </Media.Body>
              </Media>
            </Col>
          </Row>}
          {/* 
                    <Row className="justify-content-center mt-3 visualiza-usuarios">
                        <Col xs={12} className="">
                            <Media className="menu-item">
                                <img
                                width="22px"
                                height="22px"
                                className="align-selft-center ic-margin mr-3"
                                src={ictareas}
                                alt="icono potencia"
                                />
                                <Media.Body>
                                    <a className="color-link l-fs-15" href="#">Tareas</a>
                                </Media.Body>
                            </Media>
                        </Col>
                    </Row>
                    <Row className="justify-content-center mt-3 visualiza-usuarios">
                        <Col xs={12} className="">
                            <Media className="menu-item">
                                <img
                                width="22px"
                                height="22px"
                                className="align-selft-center ic-margin mr-3"
                                src={icotizacion}
                                alt="icono potencia"
                                />
                                    <Media.Body>
                                        <a className="color-link l-fs-15" href="#">Cotizaciones</a>
                                    </Media.Body>
                            </Media>
                        </Col>
                    </Row>
                    <Row className="justify-content-center mt-3 visualiza-usuarios">
                        <Col xs={12} className="">
                            <Media className="menu-item">
                                <img
                                width="22px"
                                height="22px"
                                className="align-selft-center ic-margin mr-3"
                                src={icincidencia}
                                alt="icono potencia"
                                />
                                    <Media.Body>
                                        <a className="color-link l-fs-15" href="#">Incidencias</a>
                                    </Media.Body>
                            </Media>
                        </Col>
                    </Row>
                    <Row className="justify-content-center mt-3 visualiza-usuarios">
                        <Col xs={12} className="">
                            <Media className="menu-item">
                                <img
                                width="22px"
                                height="22px"
                                className="align-selft-center ic-margin mr-3"
                                src={icstar}
                                alt="icono potencia"
                                />
                                    <Media.Body>
                                        <a className="color-link l-fs-15" href="#">Promociones</a>
                                    </Media.Body>
                            </Media>
                        </Col>
                    </Row>
                    <Row className="justify-content-center mt-3 visualiza-usuarios">
                        <Col xs={12} className="">
                            <Media className="menu-item">
                                <img
                                width="22px"
                                height="22px"
                                className="align-selft-center ic-margin mr-3"
                                src={icazulejo}
                                alt="icono potencia"
                                />
                                    <Media.Body>
                                        <a className="color-link l-fs-15" href="#">Pedidos</a>
                                    </Media.Body>
                            </Media>
                        </Col>
                    </Row>
                    <Row className="justify-content-center mt-3 visualiza-usuarios">
                        <Col xs={12} className="">
                            <Media className="menu-item">
                                <img
                                width="22px"
                                height="22px"
                                className="align-selft-center ic-margin mr-3"
                                src={icferia}
                                alt="icono potencia"
                                />
                                    <Media.Body>
                                        <a className="color-link l-fs-15" href="#">Ferias</a>
                                    </Media.Body>
                            </Media>
                        </Col>
                    </Row>
                    <Row className="justify-content-center mt-3 visualiza-usuarios">
                        <Col xs={12} className="">
                            <Media className="menu-item">
                                <img
                                width="22px"
                                height="22px"
                                className="align-selft-center ic-margin mr-3"
                                src={icviajes}
                                alt="icono potencia"
                                />
                                    <Media.Body>
                                        <a className="color-link l-fs-15" href="#">Viajes</a>
                                    </Media.Body>
                            </Media>
                        </Col>
                    </Row>
                    <Row className="justify-content-center mt-3 visualiza-usuarios">
                        <Col xs={12} className="">
                            <Media className="menu-item">
                                <img
                                width="22px"
                                height="22px"
                                className="align-selft-center ic-margin mr-3"
                                src={icatalogo}
                                alt="icono potencia"
                                />
                                    <Media.Body>
                                        <a className="color-link l-fs-15" href="#">Catálogo</a>
                                    </Media.Body>
                            </Media>
                        </Col>
                    </Row>
                    <Row className="justify-content-center mt-3 visualiza-usuarios">
                        <Col xs={12} className="">
                            <Media className="menu-item">
                                <img
                                width="22px"
                                height="22px"
                                className="align-selft-center ic-margin mr-3"
                                src={icconfig}
                                alt="icono potencia"
                                />
                                    <Media.Body>
                                        <a className="color-link l-fs-15" href="#">Configuración</a>
                                    </Media.Body>
                            </Media>
                        </Col>
                    </Row> */}
          <a
            id="ic-menu"
            onClick={() => abrirEsconderMenuLateral()}
            className="pos-a orange-up bg-arrow d-center mov-fl"
          >
            &lt;
          </a>
        </Container>
      </div>
    );
  }
}
