// --------------------------------------------
// Created by Pablo Collado in Novembrer/2020
// --------------------------------------------

import React, { useState, useEffect } from "react";
import "../assets/css/App.css";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { NavLink } from "react-router-dom";

// FN BACKEND / PROYECTOS
import { getCompany } from "../api_communication/service/ClientService";

// IMAGES
import icBack from "../assets/img/fl-volver.png";

// COMPONENTS
import LateralMenu from "../componentes/LateralMenu";
import SearchBar from "../componentes/SearchBar";
import TaskTabList from "../componentes/task/TaskTabList";
import { InteractionModeIcon } from "../componentes/CommonScripts";
import BtnArchivar from "../componentes/BtnArchivar";
import LoadingAnimation from "../componentes/LoadingAnimation";
import { OpportunityIconState } from "../componentes/CommonScripts";
import Timeline from "../componentes/detalleInteraccion/Timeline";
import AsociateOpportunityToInteraction from "../componentes/detalleInteraccion/AsociateOpportunityToInteraction";
import CreateAndAssociateOpportunityToInteraction from "../componentes/detalleInteraccion/CreateAndAssociateOpportunityToInteraction";
import ProjectStateIcon from "../componentes/proyectos/ProjectStateIcon";

//FUNCION CERRAR MENU
import { esconderMenuLateral } from "../assets/js/menuLateral";
import AttachedFiles from "../componentes/detalleInteraccion/AttachedFiles";
import { getCurrentUserData } from "../api_communication/service/UsersService";
import { isInteractionActive } from "../api_communication/service/InteractionService";

// --------------------------------------------
// Venimos a esta pantalla con <Link to={{pathname:'/DetalleInteraccion',state: interaction}}/>
// recogemos esa info 'state' con: props.location.state
// --------------------------------------------

export default function DetalleInteraccion(props) {
  const [interactionObject, setInteractionObject] = useState(null);
  const [company, setCompany] = useState(null);
  const [modeIcon, setModeIcon] = useState(null);
  const [project, setProject] = useState(null);
  const [loggedUser, setLoggedUser] = useState(null);
  const [isActive, setIsActive] = useState(false)

  useEffect(() => {
    document.title = "Detalle Interacción | UpSales";
    return () => {};
  }, []);

  useEffect(() => {
    setInteractionObject(props.location.state);
    setModeIcon(InteractionModeIcon(props.location.state.mode));
    getData(props.location.state);
  }, [props]);

  async function getData(interaction) {
    try {
      if (interaction.company) setCompany(interaction.company);
      else {
        const companyId = interaction.company
          ? interaction.company.id
          : interaction.companyId;
        let company = await getCompany(companyId);
        setCompany(company);
      }

      if (interaction.project)
        setProject(interaction.project);

      let loggedUser = await getCurrentUserData();
      setLoggedUser(loggedUser);

      const active = await isInteractionActive(interaction)
      setIsActive(active)

    } catch (e) {
      console.log(e);
    }
  }

  const goBackFn = () => {
    window.history.back();
  };

  const refreshFn = async (interactionCreated) => {
    setInteractionObject(interactionCreated);
    getData(interactionCreated);
  };

  const refreshFnAttachedFiles = async () => {
    window.location.reload();
  };

  const openCreateOpModalFn = () => {
    document.getElementById("showCreateModal").click();
  };

  const hasAccessToClientPage = (loggedUser) => {
    return !loggedUser.roles.includes("PROJECT_MANAGER");
  };

  var logo = (
    <div>
      <img
        alt={company && company.alias}
        style={{ width: 45, height: "auto", marginLeft: 36, marginRight: 16 }}
        src={company && company.thumbnail}
      />
    </div>
  );

  return (
    <Container className="pos-r" fluid={true}>
      <div
        id="ic-menu-out"
        onClick={() => esconderMenuLateral()}
        className="bg-arrow d-center arrow-menu-out d-none orange-up"
      >
        <p>&gt;</p>
      </div>
      <Row>
        <LateralMenu loggedUser={loggedUser}></LateralMenu>

        <Col className="">
          <Row className="nav-style brd-b-blue justify-content-center">
            <Col xs={11} className="align-self-center p-0">
              <SearchBar loggedUser={loggedUser}></SearchBar>
            </Col>
          </Row>

          <Row className="justify-content-center bg-blue-light ">
            <Col xs={12} className="pt-4 pb-3 px-0 d-flex flex-row pl-3">
              {project ? (
                <NavLink className="light-grey" to="/Proyectos">
                  Proyectos&nbsp;
                </NavLink>
              ) : (
                <NavLink className="light-grey" to="/Clientes">
                  Clientes&nbsp;
                </NavLink>
              )}
              /<span className="light-grey">&nbsp;Detalle Interacción</span>
            </Col>
          </Row>

          {interactionObject && (
            <Row className="d-column bg-blue-light py-3 px-3">
              <Col
                xs={12}
                className="bg-white d-flex flex-row justify-content-between align-items-center py-4 "
              >
                <div
                  style={{ cursor: "pointer", marginRight: 25 }}
                  title="Volver a la página anterior"
                >
                  <img src={icBack} onClick={goBackFn} alt=""></img>
                </div>
                <div className="d-flex flex-row align-items-center w-100">
                  {modeIcon && (
                    <div>
                      <img src={modeIcon} style={{ marginRight: 20 }} alt="" />
                    </div>
                  )}
                  <div className="dark-blue l-fs-18">
                    {interactionObject.content}
                  </div>
                </div>
                <div
                  className="light-grey-text l-fs-12"
                  style={{ float: "right" }}
                >
                  {interactionObject.date}
                </div>
                <div title="Archivar interacción">
                  {interactionObject && (
                    <BtnArchivar
                      interaction={interactionObject}
                      timeline={false}
                    />
                  )}
                </div>
              </Col>
              <Col xs={12} className="bg-white">
                <div className="brd-b-blue "></div>
              </Col>

              <Col
                xs={12}
                className="d-flex flex-row justify-content-between align-items-center bg-white py-3 px-3"
              >
                <div className="d-flex flex-row justify-content-start align-items-center">
                  {loggedUser && !hasAccessToClientPage(loggedUser) ? (
                    <div>{logo}</div>
                  ) : (
                    <NavLink
                      className="light-grey"
                      to={{ pathname: "/DetallesClientes", state: company }}
                      title="Ver detalles de esta empresa"
                    >
                      {logo}
                    </NavLink>
                  )}
                  <div
                    className="l-fs-18"
                    style={{ textTransform: "uppercase" }}
                  >
                    {company && company.alias}
                  </div>
                </div>
              </Col>

              {project && (
                <Col
                  xs={12}
                  className="d-flex flex-row justify-content-between align-items-center bg-white py-3 px-3"
                >
                  <div className="d-flex flex-row justify-content-start align-items-center">
                    <NavLink
                      className="light-grey"
                      to={{ pathname: "/DetalleProyecto", state: project }}
                      title="Ver detalles de este proyecto"
                    >
                      <div>
                        {project.state && (
                          <ProjectStateIcon
                            styles={{
                              width: 45,
                              height: "auto",
                              marginLeft: 36,
                              marginRight: 16,
                            }}
                            projectState={project.state}
                          />
                        )}
                      </div>
                    </NavLink>
                    <div
                      className="l-fs-18 "
                      style={{ textTransform: "uppercase" }}
                    >
                      {project.name}
                    </div>
                  </div>
                </Col>
              )}

              <Col xs={12} className="mt-3 bg-white px-3">
                {interactionObject && company && (
                  <TaskTabList
                    companyId={company.id}
                    entity={{ type: "interaction", id: interactionObject.id }}
                    interaction={interactionObject}
                    loggedUser={loggedUser}
                    hideCreateTaskButton={!isActive}
                  ></TaskTabList>
                )}
              </Col>

              {!project && (
                <Col xs={12} className="mt-3 py-4 bg-white d-center px-3">
                  <span className="l-fs-18 bold-text">
                    OPORTUNIDAD ASOCIADA:
                  </span>
                  <div className="pl-3">
                    <OpportunityIconState
                      opportunity={interactionObject.opportunity ? interactionObject.opportunity : null}
                    />
                  </div>
                  <span className="l-fs-18  pl-2">
                    &nbsp;
                    {interactionObject.opportunity
                      ? interactionObject.opportunity.name
                      : "Ninguna"}
                  </span>
                  {interactionObject.opportunity ? null : (
                    <div style={{ position: "absolute", right: 15 }}>
                      {interactionObject && isActive && (
                        <AsociateOpportunityToInteraction
                          interaction={interactionObject}
                          refreshFn={refreshFn}
                          openCreateOpModalFn={openCreateOpModalFn}
                        />
                      )}

                      {isActive && interactionObject && company && (
                        <CreateAndAssociateOpportunityToInteraction
                          company={company.id}
                          interaction={interactionObject}
                          refreshFn={refreshFn}
                        />
                      )}
                    </div>
                  )}
                </Col>
              )}

              <Col xs={12} className="bg-white">
                <div className="brd-b-blue "></div>
              </Col>

              <Col xs={12} className="px-3">
                {interactionObject.opportunity && (
                  <Timeline
                    opportunity={ interactionObject.opportunity ? interactionObject.opportunity : null}
                    showCreateInteractionButton={isActive}
                  />
                )}
              </Col>

              <Col xs={12} className="mt-3 pb-4 bg-white px-3">
                {interactionObject && (
                  <AttachedFiles
                    interaction={interactionObject}
                    refreshFn={refreshFnAttachedFiles}
                    showButtonToAttach={isActive}
                  />
                )}
              </Col>
            </Row>
          )}
          {!interactionObject && <LoadingAnimation />}
        </Col>
      </Row>
    </Container>
  );
}
