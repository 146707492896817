// --------------------------------------------
// Created by Pablo Collado in 01/12/2020
// --------------------------------------------

// la idea es poder asociar op de un desplegable o link para CREAR op con otro modal el cual cerrará este y al crear la op ya la asociará a la int

// --------------------------------------------
//  - Llamado desde DetalleInteracción.js 
//      <AsociateOpportunityToInteraction interaction={interactionObject} refreshFn={refreshFn} />
// --------------------------------------------

// --------------------------------------------
//  - PROPS: 'interaction':
// --------------------------------------------
// .company {alias: "NOVEMBER TEST COMPANY", id: 2128, thumbnail: "http://api.upsalesassistant.com/companies/2128/logo?thumbnail=true"}
// .createdBy: {name: "Test Manager", profilePhoto: "http://api.upsalesassistant.com/users/5/profile-picture?thumbnail=true", username: "testmanager"}
// .date: "19/11/2020 18:13:54"
// .hasChecklist: false
// .hasDocuments: false
// .id: 2526
// .incidence: null
// .mode: "text"
// .opportunity: null
// .originalInteractionId: null
// .state: "ACTIVE"



import React, {useState, useEffect} from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Media from 'react-bootstrap/Media'

// IMG
import imgBigOportunity from '../../assets/img/ic-oportunidad-big.png'

// COMPONENTS
import LoadingAnimation from '../LoadingAnimation'
import UpSalesMessage from '../UpSalesMessage'

// BACKEND FN
import { modifyInteraction } from '../../api_communication/service/ClientService';
import {
  fetchGetOpportunityById,
  listOpenOpportunitiesForClient
} from '../../api_communication/service/OpportunityService';
import LoadingAnimationMiniSize from '../LoadingAnimationMiniSize'

// UpSales.Interaction.updateInteraction(companyId, interactionId, body) = modifyInteraction(companyId, interactionId, body)


export default function AsociateOpportunityToInteraction(props) {

    const [loadingAnimation, setLoadingAnimation] = useState(false)
    const [show, setShow] = useState(false);
    const [idCompany, setIdCompany] = useState(null)
    const [interaction, setInteraction] = useState(null)
    const [opportunities, setOpportunities] = useState(null) // listado ops para elegir en desplegable
    const [opportunity, setOpportunity] = useState(null) // op elegida para guardar

    // FN PARA REFRESCAR PADRE
    const refreshFn = props.refreshFn

    // para mensajes emergentes (upsales msg)
    const [level, setLevel] = useState(null)
    const [message, setMessage] = useState(null)
    const [close, setClose] = useState(null)
    const [showUM, setShowUM] = useState(null)

    useEffect(() => {
        async function callOpportunities(idCompany){
            try{
              let opportunities = await listOpenOpportunitiesForClient(idCompany, 0, 1000)
              setOpportunities(opportunities)
              // console.log('ASSOCIATE-OP-TO-INT: callOpportunities(): ', opportunities)
            } catch(error){
              console.log(error)
            }
        }

        setInteraction(props.interaction)
        setIdCompany(props.interaction.company ? props.interaction.company.id : props.interaction.companyId)
        setOpportunity(props.interaction.opportunity)
        //getCompanyData(props.interaction.company ? props.interaction.company.id : props.interaction.companyId)
        callOpportunities(props.interaction.company ? props.interaction.company.id : props.interaction.companyId)

      }, [props])

    const save = async () => {
        if (!opportunity) {
            showUpSalesMessage('danger','Debe seleccionar una oportunidad', true, false)
        } else {
            let body = {
                opportunity: {
                  id: opportunity.id
                }
            }
            try {
                // UpSales.Interaction.updateInteraction(companyId, interactionId, body)
                let result = await modifyInteraction(idCompany, interaction.id, body )
                //console.log('ASSOCIATE-OP-TO-INT > updating int with op: result:  ', result)
                showUpSalesMessage('success','Oportunidad asociada a la interacción. Refrescando...', true, true)
                setTimeout(function(){ cancelForm(); refreshFn(result) }, 3000);
            }catch(e){
                console.log(e)
            }        
        }
    }

    // SELECT OPPORTUNITY FROM LIST FUNCTIONS
    const clickOnOpportunity = () =>{
        var selectOp = document.getElementById("oportunidadSeleccionada")
        // console.log('oportunidad seleccionada',selectOp.value)
      //   setIdOpportunity(selectOp.value)
        var valorIdOpportunity = selectOp.value
        if (valorIdOpportunity !== 'whitoutOpportunity') {obtenerOpportunidad(valorIdOpportunity)}
        else {
          setOpportunity(null)
        }
        // console.log('ESTO ES LA OPORTUNIDAD', OportunidadSeleccionada)
      }
  
    const obtenerOpportunidad = async(idOpportunity) =>{
        try{
            fetchGetOpportunityById(idOpportunity)
            .catch(error => {
            console.log(error)
            throw error;
            })
            .then(result => {
            setOpportunity(result)
            //console.log('ASSOCIATE-OP-TO-INT > Selected Op: ', result)
            return result
            })
        }catch(error){
            console.log(error)
        }
    }

    const cancelForm =  () => {
        setInteraction(null)
        setOpportunity(null)
        setOpportunities(null)
        setIdCompany(null)
        setShow(false)
    }

    const cancelByUser = () => {
        setOpportunity(null)
        setShow(false)
    }

    const showUpSalesMessage = (level, message, show, close) => {
      setLevel(level)
      setMessage(message)
      setShowUM(show)
      setClose(close)
  }

    const closeMsg = () => {
      setLevel(null)
      setMessage(null)
      setShowUM(false)
      setClose(null)
    }

    const closeThisAndOpenCreateOp = ()=> {
      props.openCreateOpModalFn()
      cancelByUser()
    }

    
    return (
      <React.Fragment>
        

        <UpSalesMessage
            level={level}
            message={message}
            visible={showUM}
            autoClose={close}
            closeMessage={closeMsg}
        />
        {/* MUESTRA BOTON (+) QUE ABRE MODAL DE ABAJO */}
        <Button title="Asociar oportunidad" className="modal-small-button d-center" variant="primary" onClick={() => setShow(true)}>
          +
        </Button>

        <Modal
            id="modalPositionRight" // id solo para css panel izq
            show={show}
            onHide={() => setShow(false)}
            dialogClassName="bg-white"
            aria-labelledby="example-custom-modal-styling-title"
            centered
            backdrop="static"
            keyboard={false}
        >
          <Modal.Header className="justify-content-center modal-border mt-5">
            <Modal.Title id="example-custom-modal-styling-title" className="dark-blue d-column">
            <b className="mt-4 my-4 text-center">ASOCIAR OPORTUNIDAD</b>

            
              
            <Media><img src={imgBigOportunity}></img></Media>

            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="mt-2">
            {loadingAnimation && 
              <div className="overlay-loading-Animation">
                <LoadingAnimation text={'Cargando Empresas...'} />
              </div>
            }
            <div className="d-column generaltextInputStyle">
                <p className="mb-3 l-fs-18 blue-up text-center">{interaction ? interaction.content : null}</p>
                <div  className="bg-white w-100 mt-2 mb-5">
                  <div className="brd-b-blue "></div>
                </div>
                {opportunities  ? <select autoFocus={true} id="oportunidadSeleccionada" className="custom-select" style={{width: '90%'}}  onClick={()=>{clickOnOpportunity()}} >
                    <option className="opcion l-fs-14" value="whitoutOpportunity">Seleccionar oportunidad:</option>
                    {opportunities.map(item=>{
                    if(item.state === 'OPEN'){
                        return(
                        <React.Fragment key={item.id}>
                            <option className="opcion l-fs-14"  value={item.id}>{item.name}</option>
                        </React.Fragment>
                        )
                    }
                    })}
                </select> : opportunities !== null ? <LoadingAnimationMiniSize/> : <p>No hay oportunidades creadas para seleccionar.</p>}

                <div className="d-center boton-guardar my-5 px-3" title="Crear oportunidad" onClick={closeThisAndOpenCreateOp}>Crear Oportunidad</div>
               
                <div  className="bg-white w-100">
                  <div className="brd-b-blue "></div>
                </div>

                <div className="d-inline-flex my-5">
                    <div className="d-center boton-cancelar mr-3" onClick={cancelByUser}>Cancelar</div>
                    {props.option != "mostrar" && <div className="d-center boton-guardar" onClick={save}>Guardar</div>}
                </div>
            </div>
          </Modal.Body>
        </Modal>
      </React.Fragment>
    );
  }