// --------------------------------------------
// Created by Pablo Collado 05/02/2021
// --------------------------------------------

import React, { useState, useEffect, useRef } from "react";
import { Row } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import Col from "react-bootstrap/Col";

// EXTERNAL LIBRARIES
import moment from 'moment';
import DatePicker from 'react-date-picker/dist/entry.nostyle';
import '../../assets/css/calendarDatePicker/Calendar.css'

//PROYECTOS FUNCTIONS
import { convertDate, projectCreateInteraction, changeStatusNameToSpanish } from "./funcionesProyectos";
import {updateProject,projectEditedValuesDifference} from "./funcionesProyectos"
import {findAllClientsService} from '../../api_communication/service/ClientService'

// IMAGES
import icProyectos from '../../assets/img/ic-proyecto-nuevo-big.png'

export default function EditarProyecto(props) {
  const [showEditProject, setShowEditProject] = useState(false);

  const [originalClient, setOriginalClient] = useState(null) // client Object (id, alias...)
  const [newClient, setNewClient] = useState(null) // client Id
  const [searchResults, setSearchResults] = useState([])
  const [searchTerm, setSearchTerm] = useState('')

  const [nombreProyecto, setNombreProyecto] = useState("")
  const [fabricado, setFabricado] = useState(false)
  const [pasta, setPasta] = useState("")
  const [origin, setOrigin] = useState("")
  const [formato, setFormato] = useState("")
  const [perimetro, setPerimetro] = useState("")
  const [superficie, setSuperficie] = useState("")
  const [colores, setColores] = useState("")
  const [tipologia, setTipologia] = useState("")
  const [numRelieves, setNumRelieves] = useState(0)
  const [lectorRelieves, setLectorRelieves] = useState(false)
  const [nombreRelieve, setNombreRelieve] = useState("")
  const [rodillo, setRodillo] = useState(false)
  const [pantalla, setPantalla] = useState(false)
  const [mica, setMica] = useState(false)
  const [esmalte, setEsmalte] = useState("")
  const [inyeccion, setInyeccion] = useState(false)
  const [brillo, setBrillo] = useState(false)
  const [granilla, setGranilla] = useState(false)
  const [rocker, setRocker] = useState(false)
  const [errorMsgClientInput, setErrorMsgClientInput] = useState(false)
  const [dateValue, setDateValue] = useState(null);
  const [dateValueToShow, setDateValueToShow] = useState(null)
  const [savingAnimation, setSavingAnimation] = useState(false)
  const [projectState, setProjectState] = useState(null)
  const [description, setDescription] = useState('')

  useEffect(() => {
    setShowEditProject(props.show)

    let date = convertDate(props.project.startingDate)
    //console.log('EDITAR-PROY: props: ', props)
    setDateValueToShow(new Date(date))
    setDateValue(new Date(date))

    let data = props.project
    //console.log('EDIT: props.project: ',props.project)
    setOriginalClient(data.company)
    setSearchTerm(data.company.alias)
    setNombreProyecto(data.name)
    setFabricado(data.fabricated)
    setPasta(data.paste)
    setOrigin(data.origin)
    setFormato(data.format)
    setPerimetro(data.perimeter)
    setSuperficie(data.surface)
    setColores(data.colour)
    setTipologia(data.typology)
    setNumRelieves(data.numberOfReliefs)
    setNombreRelieve(data.reliefName)
    setLectorRelieves(data.hasReliefReader)
    setRodillo(data.roller)
    setPantalla(data.screen)
    setMica(data.mica)
    setEsmalte(data.glaze)
    setInyeccion(data.injection)
    setBrillo(data.shine)
    setGranilla(data.grain)
    setRocker(data.rocker)
    setProjectState(data.state)
    setDescription(data.description)
  }, [props]);

const submitForm = async()=>{
  if (nombreProyecto ==="" || !dateValue) {
    setErrorMsgClientInput(true)
  } else {
      setSavingAnimation(true)
      setErrorMsgClientInput(false)
      let date = moment(dateValue).format("DD/MM/YYYY")
      let obj = {
        name: nombreProyecto,
        company:{id: newClient ? newClient : originalClient.id, alias: searchTerm}, // CLIENT ID NUEVO CLIENTE SI HA CAMBIADO!!!
        startingDate: date,
        fabricated: fabricado,
        paste: pasta ==="" ? 'Gres' : pasta,
        origin: origin ==="" ? 'Cevisama' : origin,
        format: formato==="" ? '5X50' : formato,
        perimeter: perimetro === "" ? 'Regular' : perimetro,
        surface: superficie ==="" ? 'Lisa' : superficie,
        colour: colores,
        numberOfReliefs: superficie ==="Relieves" ? numRelieves : 0,
        hasReliefReader: superficie ==="Relieves" ? lectorRelieves : false,
        typology: tipologia ==="" ? 'Madera' : tipologia,
        reliefName: superficie ==="Relieves" ? nombreRelieve : "",
        roller: rodillo,
        screen: pantalla,
        mica: mica,
        glaze: esmalte ==="" ? 'Antislip C3' : esmalte,
        injection: inyeccion,
        shine: brillo,
        grain: granilla,
        rocker: rocker,
        state: projectState,
        description: description,
      }
      //('EDIT-PROJ: guardar: obj: ', obj)
      let interactionContent = projectEditedValuesDifference(props.project, obj)
      //console.log('interactionContent: ', interactionContent)
      //return false
      try {
        let response = await updateProject(props.project.id,obj)
        if (!response) {
          props.showUpSalesMessageFn('danger','Error al editar el proyecto!', true, false)
        } else {
          let now = new Date().getTime();
          let time = moment().format("HH:mm:ss")
          //console.log('TIME EDIT PRO: ', time)
          let body = {
            date: moment().format("DD/MM/YYYY") + ' ' + time,
            content: interactionContent,
            mode: "text"
          }
          let projectId = response.id
          let interaction = await projectCreateInteraction(projectId, body)
          setSavingAnimation(false)
          if (interaction) {
            props.showUpSalesMessageFn('success','Proyecto editado correctamente!', true, true)
            // Go back and refresh page
            props.refresh()
            // clear form
            clearForm()
          } else {
            props.showUpSalesMessageFn('danger','Error al crear la interacción!', true, false)
            props.refresh()
            clearForm()
          }
        }
      } catch (error) {
        props.showUpSalesMessageFn('danger','Error al editar proyecto!', true, false)
        setSavingAnimation(false)
        console.log(error)
      }
    
    }
}

const clearForm = ()=>{
  setOriginalClient(originalClient)
  setNewClient(null)
  setErrorMsgClientInput(false)
  setNombreProyecto("")
  setFabricado("")
  setPasta("")
  setOrigin("")
  setFormato("")
  setPerimetro("")
  setSuperficie("")
  setColores("")
  setTipologia("")
  setNumRelieves(0)
  setLectorRelieves(false)
  setNombreRelieve("")
  setRodillo(false)
  setPantalla(false)
  setMica(false)
  setEsmalte("")
  setInyeccion(false)
  setBrillo(false)
  setRocker(false)
  setGranilla(false)
  setDateValue(new Date())
  setDescription('')
  props.close()
}

// handles
const handleNombreProyecto = (event)=>{
  setNombreProyecto(event.target.value)
}
const handleFabricado = (event) =>{
  setFabricado(event.target.value)
}
const handlePasta = (event)=>{
  setPasta(event.target.value)
}
const handleOrigin = (event)=>{
  setOrigin(event.target.value)
}
const handleFormato = (event)=>{
  setFormato(event.target.value)
}
const handlePerimetro = (event)=>{
  setPerimetro(event.target.value)
}
const handleSuperficie = (event)=>{
  setSuperficie(event.target.value)
}
const handleColores = (event)=>{
  setColores(event.target.value)
}
const handleTipologia = (event)=>{
  setTipologia(event.target.value)
}
const handleNumRelieves = (event)=>{
  setNumRelieves(event.target.value)
}
const handleLectorRelieves = (event)=>{
  setLectorRelieves(event.target.value)
}
const handleNombreRelieve = (event)=>{
  setNombreRelieve(event.target.value)
}
const handleRodillo = (event)=>{
  setRodillo(event.target.value)
}
const handlePantalla = (event)=>{
  setPantalla(event.target.value)
}
const handleMica = (event)=>{
  setMica(event.target.value)
}
const handleEsmalte = (event)=>{
  setEsmalte(event.target.value)
}
const handleInyeccion = (event)=>{
  setInyeccion(event.target.value)
}
const handleBrillo = (event)=>{
  setBrillo(event.target.value)
}
const handleGranilla = (event)=>{
  setGranilla(event.target.value)
}
const handleRocker = (event)=>{
  setRocker(event.target.value)
}

const handleState = (event) => {
  setProjectState(event.target.value)
}
const handleDescription = (event) => {
  setDescription(event.target.value)
}

const handleClientChange = async()  => {
  let inputUserCompanyName= document.getElementById("companyName1").value.toUpperCase();
  let userSelectedCompanyName= document.querySelector("#compañias1"  + " option[value='" + inputUserCompanyName + "']")
  setSearchTerm(inputUserCompanyName)
  //console.log('Crear-Proyecto: handleChange: inputUserCompanyName: ', inputUserCompanyName)
  //console.log('Crear-Proyecto: handleChange: userSelectedCompanyName: ', userSelectedCompanyName)
  if (inputUserCompanyName.length > 2) {
    await userChooseCompany(inputUserCompanyName)
    if (inputUserCompanyName === '' &&  !userSelectedCompanyName) {
      setNewClient(null)
    } 
      let companyIdSelected = null
      if (userSelectedCompanyName) companyIdSelected = userSelectedCompanyName.dataset.id
      if(companyIdSelected != undefined){
        setNewClient(companyIdSelected)
      } else{
        setNewClient(null)
      }
  } else {
    setSearchResults([])
  }
}
const inputRef = useRef(null);
const ShowDataListCompanies = (props) => {
  return (
    <React.Fragment>
      <input ref={inputRef} autoFocus={inputRef.current === document.activeElement} autoComplete="off" id="companyName1" className="" placeholder={props.placeholder}
        type="text"
        name="compañias1"
        list="compañias1"
        value={props.searchTerm}
        onChange={props.onChange}
        style={{width: "350px"}}
      >
      </input>
       <datalist id="compañias1" >
        {props.list.length !==0 && props.list.map(item => 
            <option key={item.id} data-id={item.id} value={item.alias.toUpperCase()}></option>
        )}
        </datalist>
    </React.Fragment>
  )
}

const userChooseCompany = async (word) => {
  try {
    setSearchResults([])
    const companies = await findAllClientsService(0,100, word)
    //console.log('Crear-Proyecto: userChooseCompany: findAllClients: companies: ', companies)
    setSearchResults(companies)
  } catch(error){
    console.log(error)
  }
}

  return (
    <React.Fragment>
      <Modal
        style={{ overflow: "hidden" }}
        id="showDocuments"
        className="modal-border"
        show={showEditProject}
        //onHide={() => setShowEditProject(false)}
        dialogClassName="bg-white"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header className="justify-content-center modal-border pos-r">
          <div className="" style={{ position: "absolute", top: 25, right: 35 }}>
            <div style={{ fontWeight: "bold", cursor: "pointer", fontSize: 21 }} onClick={()=>clearForm()}>
              X
            </div>
          </div>
          <Modal.Title id="example-custom-modal-styling-title" className="dark-blue d-flex align-items-center mt-4">
            <div>
              <img src={icProyectos} width={30} height={30}/>
            </div>
            <b className="ml-3 text-center">EDITAR PROYECTO</b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-border container" style={{ maxHeight: "calc(100vh - 100px)", overflowY: "auto" }}>
          <form id="CreateEditProjectCss" className="row l-fs-19 light-grey">
              <Col className="col-12">
                <p className="dark-blue">DATOS BASE</p>
                <div className="grey-underline"></div>
                <div className="row px-5 d-flex justify-content-start aling-items-center pt-3">
                  <div className="col-6 col-md-3">
                    <div className="">
                      <p className="">Empresa*:</p>
                      <ShowDataListCompanies 
                        list={searchResults} 
                        searchTerm={searchTerm}
                        onChange={handleClientChange}
                        placeholder={originalClient && originalClient.alias}
                      />
                    </div>
                  </div>
                </div>
                <div className="row px-5 d-flex justify-content-start aling-items-center pt-3">
                  <div className="col-6 col-md-3 ">
                    <p className="">Nombre*:</p>
                    <input type="text" className="" defaultValue={props.project.name} placeholder={props.project.name} onChange={handleNombreProyecto}/>
                  </div>
                  {/*<div className="col-6 col-md-3 ">
                    <p className="">Empresa:</p>
                    <input type="text" title="No editable" defaultValue={props.project.company.alias} placeholder={props.project.company.alias} disabled/>
                  </div>*/}
                  <div className="col-6 col-md-3">
                    <p className="">Fecha Solicitud*:</p>
                    <div style={{width:"200px"}}>
                      <DatePicker
                        onChange={(newDate) => {setDateValue(newDate); setDateValueToShow(newDate)}}
                        value={dateValueToShow}
                        locale="es-ES"
                      />
                    </div>
                  </div>
                  <div className="col-6 col-md-3">
                    <p className="">Situación</p>
                    <select value={projectState} onChange={handleState}>
                        <option value="STARTING">Comenzado</option>
                        <option value="IN_PROGRESS">En desarrollo</option>
                        <option value="ADVANCED">Terminado</option>
                      </select>
                  </div>
                  <div className="col-6 col-md-3">
                    <div>
                        <p>Fabricado:</p>
                        <select value={fabricado} onChange={handleFabricado}>
                          <option value={'true'}>Si</option>
                          <option value={'false'} defaultValue>No</option>
                          <option value={'MUESTRAS'}>Muestras</option>
                        </select>
                      </div>
                    </div>
                  {errorMsgClientInput && <div className="col-12 pt3 rojo">* Es obligatorio nombre de proyecto y fecha válida.</div>}
                </div>
              </Col>
              <Col className="col-12 pt-5">
                <p className="dark-blue">CARACTERÍSTICAS DEL PRODUCTO</p>
                <div className="grey-underline"></div>
                <div className="row px-5 d-flex justify-content-start aling-items-center pt-3">
                  <div className="col-6 col-md-3 pb-4">
                    <div>
                      <p>Pasta:</p>
                      <select className="" value={pasta} onChange={handlePasta}>
                        <option value="Gres">Gres</option>
                        <option value="Porcelánico">Porcelánico</option>
                        <option value="Pasta blanca">Pasta Blanca</option>
                        <option value="Porosa">Porosa</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-6 col-md-3 pb-4">
                    <div>
                      <p>Orígen:</p>
                      <select className="" value={origin} onChange={handleOrigin}>
                        <option value="Cevisama">Cevisama</option>
                        <option value="Cersiae">Cersiae</option>
                        <option value="Coverings">Coverings</option>
                        <option value="resto">Resto</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-6 col-md-3 pb-3">
                  <div>
                      <p>Formato:</p>
                      <select className="" value={formato} onChange={handleFormato}>
                        <option value="5X50">5X50</option>
                        <option value="7X28">7X28</option>
                        <option value="7,5X28">7,5X28</option>
                        <option value="7,5X30">7,5X30</option>
                        <option value="8X33">8X33</option>
                        <option value="8X40 CHV">8X40 CHV</option>
                        <option value="8X44,25">8X44,25</option>
                        <option value="10X30">10X30</option>
                        <option value="10X60">10X60</option>
                        <option value="11X22">11X22</option>
                        <option value="12,5X25">12,5X25</option>
                        <option value="13x15">13X15</option>
                        <option value="15X15">15X15</option>
                        <option value="15X60">15X60</option>
                        <option value="15X90">15X90</option>
                        <option value="15,4X15,4">15,4X15,4</option>
                        <option value="15,4X31">15,4X31</option>
                        <option value="15,5X67">15,5X67</option>
                        <option value="16,3X51,7 R">16,3X51,7 R</option>
                        <option value="17X52">17X52</option>
                        <option value="18,7X18,7">18,7X18,7</option>
                        <option value="19,8X22,8 HEX">19,8X22,8 HEX</option>
                        <option value="20X20">20X20</option>
                        <option value="20X24 HEX">20X24 HEX</option>
                        <option value="20X60">20X60</option>
                        <option value="22,3X22,3">22,3X22,3</option>
                        <option value="25,8X29 HEX">25,8X29 HEX</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-6 col-md-3 pb-4">
                    <div>
                      <p>Perímetro:</p>
                      <select className="" value={perimetro} onChange={handlePerimetro}>
                        <option value="Regular">Regular</option>
                        <option value="Irregular">Irregular</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-6 col-md-3 pb-3">
                    <div className="">
                      <p>Colores:</p>
                      <textarea className="" value={colores ? colores : ''} onChange={handleColores} />
                    </div>
                  </div>
                  <div className="col-6 col-md-3 pb-3">
                    <div>
                      <p>Tipología:</p>
                      <select className="" value={tipologia} onChange={handleTipologia}>
                        <option value="Madera">Madera</option>
                        <option value="Piedra">Piedra</option>
                        <option value="Pizarra">Pizarra</option>
                        <option value="Cemento">Cemento</option>
                        <option value="Granito">Granito</option>
                        <option value="Mármol">Mármol</option>
                        <option value="Metal">Metal</option>
                        <option value="Hidráulico">Hidráulico</option>
                        <option value="Textil">Textil</option>
                        <option value="Monocolores">Monocolores</option>
                        <option value="Otros">Otros</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-6 col-md-3 pb-3">
                    <div>
                      <p>Superficie:</p>
                      <select className="" value={superficie} onChange={handleSuperficie}>
                        <option value="Lisa">Lisa</option>
                        <option value="Relieves">Relieves</option>
                      </select>
                    </div>
                  </div>
                  {superficie === 'Relieves' &&
                  <React.Fragment>
                    <div className="col-6 col-md-3">
                      <p>Nombre Relieve:</p>
                      <input value={nombreRelieve} type="text" placeholder="Nombre Relieve" onChange={handleNombreRelieve}/>
                    </div>
                    <div className="col-6 col-md-3 pb-3 ">
                      <p>Nº relieves:</p>
                      <input value={numRelieves} type="number" min="0" placeholder="Nº de Relieves" onChange={handleNumRelieves}/>
                    </div>
                    <div className="col-6 col-md-3 pb-3">
                      <div className="">
                        <p>Lector de Relieves?:</p>
                        <select className="" value={lectorRelieves} onChange={handleLectorRelieves}>
                          <option value={true}>Si</option>
                          <option value={false}>No</option>
                        </select>
                      </div>
                    </div>
                  </React.Fragment>
                  }
                </div>
              </Col>
              <Col className="col-12 pt-4">
                <p className="dark-blue">TÉCNICA</p>
                <div className="grey-underline"></div>
                <div className=" row px-5">
                  <div className="col-6 col-md-3">
                    <div className="my-3">
                      <p>Rodillo:</p>
                      <select className="" value={rodillo} onChange={handleRodillo}>
                        <option value={true}>Si</option>
                        <option value={false}>No</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-6 col-md-3">
                    <div className="my-3">
                      <p>Pantalla:</p>
                      <select className="" value={pantalla} onChange={handlePantalla}>
                        <option value={true}>Si</option>
                        <option value={false}>No</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-6 col-md-3">
                  <div className="my-3">
                      <p>Esmalte:</p>
                      <select className="" value={esmalte} onChange={handleEsmalte}>
                        <option value="Antislip_C3">Antislip C3</option>
                        <option value="Mate C2">Mate C2</option>
                        <option value="Sedoso">Sedoso</option>
                        <option value="Brillo">Brillo</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-6 col-md-3">
                    <div className="my-3">
                      <p>Inyección:</p>
                      <select className="" value={inyeccion} onChange={handleInyeccion}>
                        <option value={true}>Si</option>
                        <option value={false}>No</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-12 pt-3">
                    <p className="dark-blue">Efectos:</p>
                    <div className="grey-underline"></div>
                    <Row className="">
                      <div className="col-6 col-md-3 ">
                        <div className="my-3">
                          <p>Mica:</p>
                          <select className="" value={mica} onChange={handleMica}>
                            <option value={true}>Si</option>
                            <option value={false}>No</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-6 col-md-3 ">
                        <div className="my-3">
                          <p>Brillo:</p>
                          <select className="" value={brillo} onChange={handleBrillo}>
                            <option value={true}>Si</option>
                            <option value={false}>No</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-6 col-md-3 ">
                        <div className="my-3">
                          <p>Granilla:</p>
                          <select className="" value={granilla} onChange={handleGranilla}>
                            <option value={true}>Si</option>
                            <option value={false}>No</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-6 col-md-3 ">
                        <div className="my-3">
                          <p>Rocker:</p>
                          <select className="" value={rocker} onChange={handleRocker}>
                            <option value={true}>Si</option>
                            <option value={false}>No</option>
                          </select>
                        </div>
                      </div>
                    </Row>
                  </div>
                </div>
              </Col>

              <Col className="col-12 pt-4">
                <p className="dark-blue">COMENTARIOS</p>
                <div className="grey-underline"></div>
                <div className=" row px-5 d-flex justify-content-start aling-items-center">
                  <div className="col-12 ">
                  <textarea style={{width:'75%', height:150}} value={description} placeholder={description} onChange={handleDescription} />
                  </div>
                </div>
              </Col>

              <Col className="col-12 d-flex justify-content-end">
                <div className="d-inline-flex my-5">
                  <div onClick={()=>clearForm()} className="d-center boton-cancelar mr-3">Cancelar</div>
                  <div type="submit" onClick={()=>submitForm()} className="d-center boton-guardar">
                    {savingAnimation && <span className="spinner-grow spinner-grow-sm pr-3" role="status" aria-hidden="true"></span>}
                    Guardar</div>
                </div>
              </Col>
          </form>
            
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
}
