// --------------------------------------------
// Created by Pablo Collado 2020
// --------------------------------------------

import React, {useState, useEffect} from 'react'
import Modal from 'react-bootstrap/Modal'

// IMAGES
import icArchived from '../assets/img/ic-ver-archivados.png'
import icTel from '../assets/img/ic-tel-blue.png'
import icInteraction from '../assets/img/ic-interaccion-blue.png'
import icEmail from '../assets/img/ic-correo-blue.png'
import icCam from '../assets/img/ic-cam-blue.png'
import icAdjuntar from '../assets/img/ic-adjuntar-celeste.png'
import btnPlus from '../assets/img/btn-mas.png'
import userImgPlaceholder from "../assets/img/UserAvatarIconPlaceholder.png";

// COMPONENTS
import {OpportunityIconState} from '../componentes/CommonScripts'
import ProjectStateIcon from '../componentes/proyectos/ProjectStateIcon'

// BACKEND FUNCTIONS
import {  LoadInteractionsByCompany, archiveInteraction,
    listInteractionsForAllCompanies} from '../api_communication/service/ClientService';
  import { opportunityListArchivedInteractions } from '../api_communication/service/OpportunityService';
import { listProjectArchivedInteractions } from './proyectos/funcionesProyectos'

// ONLY FOR INTERACTIONS
// CALL FROM PARENT LIKE THIS:
// ------------------------------------------------------------------------------------
//  <ShowArchivedInteractions 
//    all={true or false} -> To list all interactions in Dashboard page
//    client={this.props.client} -> To list in 'Detalle Cliente' page
//    opportunity={this.props.opportunity} -> To list in 'Detalle Oportunidad' page
//    refresh={this.refreshInteractions} 
//    msg={this.showUpSalesMessage}
//  />
// ------------------------------------------------------------------------------------

  const ShowItem = (props) => {
    const refreshFn = props.refreshFn
    const msg = props.msg

    const restoreInteraction = async (companyId, interactionId) => {
      try {
        let result = await archiveInteraction(companyId, interactionId)
        msg('success','Restaurando interacción...', 'true', 'true')
        props.hideModal()
        setTimeout(function(){
          refreshFn()
        },3000)
      } catch(e) {
        console.log(e)
      }
  }

  const textModeImage = (mode) => {
    switch (mode) {
      case 'text':
        return icInteraction
      case 'phone':
        return icTel
      case 'email':
        return icEmail
      case 'photo':
        return icCam
      default:
        return icInteraction
    }
  }

  const shortText = (text) => {
    return text.slice(0,50) + ' (...)'
  }

  const cutContent = (content)=> {
    if(content.length > 50) return shortText(content)
    else return content
  }

  return (
    <React.Fragment>
      <div className="col my-1 px-4 px-xl-5">
        <div className="row flex-column">

          <div className="col p-2 bg-timeline-grey d-flex flex-row align-items-center" style={{borderTopLeftRadius: '5px', borderTopRightRadius: '5px', position:'relative', minHeight:40}}>
            <div className="pr-2">
              <img style={{width: 25, height: 25}} src={textModeImage(props.item.mode)} title={props.item.mode}/>
            </div>
              {props.item.opportunity && <OpportunityIconState opportunity={props.item.opportunity}/>}
              {props.item.project && <ProjectStateIcon projectState={props.item.project.state}/>}
            <p className="timeline-date pl-2">{props.item.date}</p>
            <div style={{position:'absolute', top:5, right:5}}>
            <img src={props.item.createdBy ? (props.item.createdBy.profilePhoto ? props.item.createdBy.profilePhoto : userImgPlaceholder) : userImgPlaceholder} style={{borderRadius:'50%', width:'30px', height:'30px'}} title={props.item.createdBy ? props.item.createdBy.username : '?'}/>
          </div>
          </div>

          <div className="col bg-timeline-dark-grey p-2 pos-r" 
            style={props.showCompany ? {borderBottomLeftRadius: '5px', borderBottomRightRadius: '5px', minHeight:'110px', position: 'relative'}  : {borderBottomLeftRadius: '5px', borderBottomRightRadius: '5px', minHeight:'90px', position: 'relative'}}>
          {props.showCompany && <p className="timeline-opportunity-description" style={{textTransform: 'uppercase'}}>{props.item.company.alias}</p>}
          {props.item.opportunity && <p className="timeline-opportunity-description" title={props.item.opportunity.name}>{cutContent(props.item.opportunity.name)}</p>}
          {props.item.project && <p className="timeline-opportunity-description" title={props.item.project.name}>{cutContent(props.item.project.name)}</p>}
          <p className="timeline-content" title={props.item.content}>{cutContent(props.item.content)}</p>
          {props.item.hasDocuments && <img style={{width: 12, height: 12, position:'absolute', left:10, bottom:10}} src={icAdjuntar} alt="Adjuntos" title="Adjuntos"/>}
          <div style={{position:'absolute', bottom:10, right:10}}>
            <div onClick={()=> restoreInteraction(props.item.company.id, props.item.id)} title="Desarchivar interacción" className="d-center btn-restore">Restaurar</div>
          </div>
          </div>

        </div>
      </div>
    </React.Fragment>
  )
}

export default function ShowArchivedInteractions(props) {
   
    const [show, setShow] = useState(false)
    const [paginationCount, setPaginationCount] = useState(0)
    const [interactionsList, setInteractionList] = useState(null)
    
    // Btn for more
    const [showPlusBtn, setShowPlusBtn] = useState(false)

    const [companyId, setCompanyId] = useState(null)
    const [propReceived, setPropReceived] = useState(null)

    const refreshFn = props.refresh
    const msg = props.msg

    // Number of interactions to show
    const NUMBER_OF_ELEMENTS = 5

    useEffect(() => {
      const fetchDataAll = async ()=>{
        setShowPlusBtn(true) 
        let result = await listInteractionsForAllCompanies(paginationCount,NUMBER_OF_ELEMENTS, '', ['ARCHIVED'])
        let result2 = await listInteractionsForAllCompanies(paginationCount + 1,NUMBER_OF_ELEMENTS, '', ['ARCHIVED'])
        if (!result2 || result2.length === 0) setShowPlusBtn(false) 
        setInteractionList(result)
      }
      const fetchDataClient = async ()=>{
        setShowPlusBtn(true) 
        let result = await LoadInteractionsByCompany(id, paginationCount,NUMBER_OF_ELEMENTS, null, ['ARCHIVED'])
        let result2 = await LoadInteractionsByCompany(id, paginationCount + 1,NUMBER_OF_ELEMENTS, null, ['ARCHIVED'])
        if (!result2 || result2.length === 0) setShowPlusBtn(false) 
        setInteractionList(result)
      }
      const fetchDataOpportunity = async ()=>{
        setShowPlusBtn(true)
        let result = await opportunityListArchivedInteractions(id, paginationCount,NUMBER_OF_ELEMENTS, null, ['ARCHIVED'])
        let result2 = await opportunityListArchivedInteractions(id, paginationCount + 1,NUMBER_OF_ELEMENTS, null, ['ARCHIVED'])
        if (!result2 || result2.length === 0) setShowPlusBtn(false) 
        setInteractionList(result)
      }
      const fetchDataProject = async () => {
        setShowPlusBtn(true)
        let result = await listProjectArchivedInteractions(id, paginationCount, NUMBER_OF_ELEMENTS)
        let result2 = await listProjectArchivedInteractions(id, paginationCount + 1, NUMBER_OF_ELEMENTS)
        if (!result2 || result2.length === 0) setShowPlusBtn(false) 
        setInteractionList(result)
      }

      let id = null
      
      if (props.client) {
        id = props.client.id
        setCompanyId(id)
        setPropReceived('client')
        fetchDataClient()
      } else if (props.opportunity) {
        id = props.opportunity.id
        setPropReceived('opportunity')
        setCompanyId(id)
        fetchDataOpportunity()
      } else if (props.projectId) {
        id = props.projectId
        setPropReceived('project')
        setCompanyId(id)
        fetchDataProject()
      } else if (props.all) {
        setPropReceived('all')
        fetchDataAll()
      }
    }, [props]);

    const moreInteractions = async () => {
      try {
        if (propReceived === 'client') {
          // UpSales.Interaction.listAllInteractionsForACompany(clientId, page, pageSize, query, state)
          let more = await LoadInteractionsByCompany(companyId, paginationCount + 1, NUMBER_OF_ELEMENTS, null, ['ARCHIVED'])
          let more2 = await LoadInteractionsByCompany(companyId, paginationCount + 2,NUMBER_OF_ELEMENTS, null, ['ARCHIVED'])
          if (!more2) setShowPlusBtn(false)
          setPaginationCount(paginationCount + 1)
          if (more) {
            await setInteractionList([...interactionsList, ...more])
            scrollToBottom()
          }

        } else if (propReceived === 'opportunity') {
          // UpSales.Opportunity.listInteractions(opportunityId, page, pageSize, mode, states)
          let more = await opportunityListArchivedInteractions(companyId, paginationCount + 1, NUMBER_OF_ELEMENTS, null, ['ARCHIVED'])
          let more2 = await opportunityListArchivedInteractions(companyId, paginationCount + 2,NUMBER_OF_ELEMENTS, null, ['ARCHIVED'])
          if (!more2) setShowPlusBtn(false)
          setPaginationCount(paginationCount + 1)
          if (more) {
            await setInteractionList([...interactionsList, ...more])
            scrollToBottom()
          } 

        } else if (propReceived === 'project') {
          let more = await listProjectArchivedInteractions(companyId, paginationCount + 1, NUMBER_OF_ELEMENTS)
          let more2 = await listProjectArchivedInteractions(companyId, paginationCount + 2, NUMBER_OF_ELEMENTS)
          if (!more2) setShowPlusBtn(false)
          setPaginationCount(paginationCount + 1)
          if (more) {
            await setInteractionList([...interactionsList, ...more])
            scrollToBottom()
          } 

        } else if (propReceived === 'all') {
            // UpSales.Interaction.listInteractionsForAllCompanies(page, pageSize, query,state)
            let more = await listInteractionsForAllCompanies(paginationCount + 1,NUMBER_OF_ELEMENTS, '', ['ARCHIVED'])
            let more2 = await listInteractionsForAllCompanies(paginationCount + 2,NUMBER_OF_ELEMENTS, '', ['ARCHIVED'])
            if (!more2 || more2.length === 0) setShowPlusBtn(false)
          setPaginationCount(paginationCount + 1)
          if (more) {
            await setInteractionList([...interactionsList, ...more])
            scrollToBottom()
          }
        }
        
      } catch (error) {
        console.log(error)
      }
    }

    const scrollToBottom = ()=>{
      var objDiv = document.getElementById("scrollToBottom");
      //objDiv.scrollTop = objDiv.scrollHeight/2;
      objDiv.scrollBy(0,680)
    }

    const cancelForm =  () => {
      setShow(false)
    }

    const showInteractions = (arrayInteractions, refreshFn, msg) => {
      let array = []
      arrayInteractions.forEach( element =>{
        array.push(<ShowItem 
                      key={element.id} 
                      listkey={element.id} 
                      item={element}
                      refreshFn={refreshFn}
                      msg={msg}
                      hideModal={cancelForm}
                      showCompany={propReceived === 'all' ? true : false}
                      />)
                  })
      return array
    }
    
    return (
      <React.Fragment>
        <div title="Ver interacciones archivadas" className={(propReceived === 'all') ? 'btn-show-archived-no-margin' : "btn-show-archived"} onClick={() => setShow(true)}>
            <img src={icArchived} style={{width:40}}/>
        </div>

        <Modal
          style={{overflow:'hidden'}}
          id="showArchivedInteractions"
          className="modal-border"
          show={show}
          onHide={() => setShow(false)}
          dialogClassName="bg-white"
          aria-labelledby="example-custom-modal-styling-title"
        >
          <Modal.Header className="justify-content-center modal-border modal-border">
            <Modal.Title id="example-custom-modal-styling-title" className="dark-blue d-column">
              <b className="mt-4 text-center">
                INTERACCIONES ARCHIVADAS
              </b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="modal-border" >
            <div id="scrollToBottom" className="row px-4" style={{overflowY:'auto', maxHeight: 'calc(100vh - 240px)', display:'block', scrollBehavior:'smooth'}}>
              {(interactionsList && interactionsList.length !== 0) ? showInteractions(interactionsList, refreshFn, msg) : <div className="d-center mt-5">No hay interacciones archivadas</div>}
            </div>
            { showPlusBtn && <div className="d-center mt-3">
              <div onClick={()=>moreInteractions()}>
                <img src={btnPlus} style={{cursor:'pointer'}}></img>
              </div>
            </div> }
            <div className="d-center mb-5 mt-3">
                <div className="d-center boton-cancelar" onClick={cancelForm}>Cerrar</div>
            </div>
          </Modal.Body>
        </Modal>
      </React.Fragment>
    );
  }