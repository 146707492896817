import React from 'react'

import { changeStatusNameToSpanish } from './funcionesProyectos';

// images
import startingProjectIcon from "../../assets/img/ic-proyecto-abierto-gris.png";
import archivedProjectIcon from "../../assets/img/ic-proyecto-cerrado-aspas.png";
import successProjectIcon from "../../assets/img/ic-proyecto-finalizado-exito-verde.png";
import inProgressProjectIcon from "../../assets/img/ic-proyecto-endesarrollo-am.png"
import advancedProjectIcon from "../../assets/img/ic-proyecto-avanzado-verde.png"

export default function ProjectStateIcon(props) {

    const iconPath = (projectState)=>{
        switch (projectState) {
            case 'STARTING':
                return startingProjectIcon
            case 'IN_PROGRESS':
                return inProgressProjectIcon
            case 'ADVANCED':
                return advancedProjectIcon
            case 'ARCHIVED':
                return archivedProjectIcon
            case 'FINISHED':
                return successProjectIcon
            default:
                break;
        }
    }

    return (
        <React.Fragment>
            <img style={props.styles} 
                 className={props.classNames} 
                 title={changeStatusNameToSpanish(props.projectState)} 
                 src={iconPath(props.projectState)}/>
        </React.Fragment>
        
    )
}
