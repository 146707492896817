import {
  findAllClientsRepository,
  pinClientRepository,
  findClientByIdRepository,
  deleteClientByIdRepository,
  modifyCompanyDataRepository,
  saveNewCompanyRepository,
  LoadInteractionsByCompanyRepository,
  saveInteractionByCompanyRepository,
  saveNewIncidentRepository,
  findIncidencesByClientIdRepository,
  CountIncidencesByStateRepository,
  listCompaniesByOpportunityStateRepository,
  countIncidencesByCompanyRepository,
  listCompaniesByIncidenceStateRepository,
  listIncidencesByCompanyRepository,
  listInteractionsForAnIncidenceRepository,
  fetchGetIncidenceByIdRepository,
  globalFilterInteractionsByWordRepository,
  archiveInteractionRepository,
  logoCompanyRepository,
  saveDocumentsRepository,
  listEmailsRepository,
  listPhonesRepository,
  updateCompanyLogoRepository,
  modifyCompanyRepository,
  markUnmarkCompanyAsFavoriteRepository,
  pinUnpinCompanyRepository,
  createInteractionRepository,
  modifyInteractionRepository,
  getInteractionImagesForACompanyRepository,
  getInteractionDocsForACompanyRepository,
  listTasksForACompanyRepository,
  listTasksForAnOpportunityRepository,
  createTaskRepository,
  addDocsToTaskRepository,
  listInteractionsForAllCompaniesRepository,
  closeInteractionRepository,
  listTasksInTimeIntervalFilteredByStateRepository,
  getATaskRepository,
  modifyTaskRepository,
  tasksForAnInteractionRepo,
  getListSalesForecastRepo,
  listSalesForGraphRepo,
  listSalesForModalRepo,
  computeTotalSalesByYearRepo,
  computeTotalSalesForecastsByYearRepo,
  getBalanceListRepo,
  getSaldoVivoRepo,
  getSaldoVencidoRepo,
  getPdfRepo,
  createStaffMemberRepo,
  getListStaffRepo,
  saveEditedStaffMemberRepo,
  setCompanyTagsRepository,
  getTagsRepository,
  deleteStaffMemberRepo,
  getOtherlDirectionsRepo,
  addOtherDirectionRepo,
  saveEditedOtherDirectionRepo,
  deleteOtherDirectionRepo,
  modifyFinanceRepo,
  getListPaymentMethodsRepo,
  createPaymentMethodRepo,
  listCountriesRepo, listProvincesByCountryRepo, listProvincesRepo, listLocalitiesRepo
} from "../repository/ClientRepository";

// Si nos llega de BD objeto y métodos next/previous quitamos estos últimos y nos quedamos con objeto
var processingBackend = (data) => {
  var newArray = [];
  for (var prop in data) {
    if (data[prop].hasOwnProperty("id")) {
      newArray.push(data[prop]);
    }
  }
  if (newArray.length == 0) newArray = null;
  return newArray;
};

export var findAllClientsService = async (page, pageSize, query,countryId, provinceId, localityId, postalCode) => {
  try {
    var result = await findAllClientsRepository(page, pageSize, query,countryId, provinceId, localityId, postalCode);
    return result.data;
  } catch (error) {
    console.log("ERROR CLIENT-SERVICE: findAllClients: ", error);
  }
};

// Search Client by Id
export var findClientById = async (clientId) => {
  var result = await findClientByIdRepository(clientId);
  return result;
};

// Delete company
export var deleteClientById = (clientId) => {
  var result = deleteClientByIdRepository(clientId);
  return result;
};

// Modify Company Data
export var modifyCompanyData = (data, id) => {
  var result = modifyCompanyDataRepository(data, id);
  return result;
};

// createCompany
export var saveNewCompany = async (data) => {
  var result = await saveNewCompanyRepository(data);
  return result;
};

//Load interactions
export var LoadInteractionsByCompany = async (
  clientId,
  page,
  pageSize,
  query,
  state
) => {
  var result = processingBackend(
    await LoadInteractionsByCompanyRepository(
      clientId,
      page,
      pageSize,
      query,
      state
    )
  );
  return result;
};

//saveInteractionByCompanyRepository
export var saveInteractionByCompany = async (clientId, newInteraction) => {
  var result = await saveInteractionByCompanyRepository(
    clientId,
    newInteraction
  );
  return result;
};

//saveNewIncident
export var saveNewIncident = async (data) => {
  var result = await saveNewIncidentRepository(data);
  return result;
};

// Incidences List LineaTiempo
export var findIncidencesByClientId = async (clientId) => {
  try {
    var result = processingBackend(
      await findIncidencesByClientIdRepository(clientId)
    );
  } catch (error) {
    console.log("ERROR CLIENT-SERVICE: findIncidencesByClientId: ", error);
  }
  return result;
};

//CountIncidencesByState for an organization
export var CountIncidencesByState = (companyId, stateName) => {
  if (companyId == null || companyId == undefined) companyId = "";
  var result = CountIncidencesByStateRepository(companyId, stateName);
  return result;
};

// Company - List companies filtered by opportunity state

export var listCompaniesByOpportunityState = async (
  stateName,
  page,
  pageSize
) => {
  try {
    var result = processingBackend(
      await listCompaniesByOpportunityStateRepository(stateName, page, pageSize)
    );
  } catch (error) {
    console.log(
      "ERROR CLIENT-SERVICE: listCompaniesByOpportunityState: ",
      error
    );
  }
  return result;
};

// Company - List companies filtered by incidence state
export var listCompaniesByIncidenceState = async (clientId) => {
  var result = processingBackend(
    await listCompaniesByIncidenceStateRepository(clientId)
  );
  return result;
};

// Company - List incidences filtered by state
export var listIncidencesByCompany = async (clientId) => {
  var result = processingBackend(
    await listIncidencesByCompanyRepository(clientId)
  );
  return result;
};

// Company - Count incidences by state
export var countIncidencesByCompany = async (clientId) => {
  var result = await countIncidencesByCompanyRepository(clientId);
  return result;
};

// List interactions for a incidence
export var listInteractionsForAnIncidence = async (id, numPage) => {
  var result = processingBackend(
    await listInteractionsForAnIncidenceRepository(id, numPage)
  );
  return result;
};

// Incidence - Get incidence by Id  fetchGetIncidenceById
export var fetchGetIncidenceById = async (id) => {
  var result = await fetchGetIncidenceByIdRepository(id);
  return result;
};

// Global filter interactions by word // Company - List interactions for a company
export var globalFilterInteractionsByWord = async (companyId, word) => {
  var result = processingBackend(
    await globalFilterInteractionsByWordRepository(companyId, word)
  );
  if (result == null) result = null;
  return result;
};

// Archive / Restore Interaction
export var archiveInteraction = async (companyId, interactionId) => {
  var result = await archiveInteractionRepository(companyId, interactionId);
  return result;
};

// Logo company
export var logoCompany = async (companyId, file) => {
  await logoCompanyRepository(companyId, file);
};
export var updateCompanyLogo = async (companyId, file) => {
  await updateCompanyLogoRepository(companyId, file);
};

// Save document
export var saveDocuments = async (companyId, interactionId, files) => {
  let result = await saveDocumentsRepository(companyId, interactionId, files);
  return result;
};

// UpSales.Task.closeTask(companyId, interactionId, body)
export var closeInteraction = async (companyId, interactionId, body) => {
  try {
    let result = await closeInteractionRepository(
      companyId,
      interactionId,
      body
    );
    return result;
  } catch (error) {
    console.log(error);
  }
};

// UpSales client calls

export var getCompany = async (companyId) => {
  //console.log('CLIENT-SERVICE: archiveInteraction: companyId: ', companyId, ' / interactionId: ', interactionId)
  var result = await findClientByIdRepository(companyId);
  return result;
};

// company list emails
export var listEmails = async (companyId) => {
  let result = await listEmailsRepository(companyId);
  //console.log('SERVICE: LIST-EMAILS: result: ', result)
  return result;
};

// company list phones
export var listPhones = async (companyId) => {
  let result = await listPhonesRepository(companyId);
  //console.log('SERVICE: LIST-Phones: result: ', result)
  return result;
};

//modify company data
export var modifyCompany = async (companyId, body) => {
  try {
    let result = await modifyCompanyRepository(companyId, body);
    return result;
  } catch (error) {
    console.log("REPO", error);
  }
};

//get companyTags
export var getCompanyTags = async (companyId, familyId) => {
  var result = await getTagsRepository(companyId, familyId);
  return result;
};

//Set company tags
export var setCompanyTags = async (companyId, familyId, tags) => {
  try {
    let result = await setCompanyTagsRepository(companyId, familyId, tags);
    return result;
  } catch (error) {
    console.log("setCompanyTags error", error);
  }
};

// UpSales.Company.markCompanyAsFavorite(companyId)
export var markUnmarkCompanyAsFavorite = async (companyId) => {
  try {
    let result = await markUnmarkCompanyAsFavoriteRepository(companyId);
    return result;
  } catch (error) {
    console.log(error);
  }
};

// UpSales.Company.pinUnpinCompany(companyId)
export var pinUnpinCompany = async (companyId) => {
  try {
    let result = await pinUnpinCompanyRepository(companyId);
    return result;
  } catch (error) {
    console.log(error);
  }
};

//UpSales.Interaction.createInteraction(companyId, body)
export var createInteraction = async (companyId, body) => {
  try {
    let result = await createInteractionRepository(companyId, body);
    return result;
  } catch (error) {
    console.log(error);
  }
};

// Modify Interaction Data
export var modifyInteraction = async (companyId, interactionId, body) => {
  try {
    var result = await modifyInteractionRepository(
      companyId,
      interactionId,
      body
    );
    return result;
  } catch (error) {
    console.log(error);
  }
};

// Interactions - Get documents for all the active interactions from a company
//----------------------------------------------------------------------------
// UpSales.Interaction.getAllInteractionDocumentsForACompany(companyId, page, pageSize)
// UpSales.Interaction.getInteractionImagesForACompany(companyId, page, pageSize)
// UpSales.Interaction.getInteractionOtherDocumentsForACompany(companyId, page, pageSize)

export const getInteractionImagesForACompany = async (
  companyId,
  page,
  pageSize
) => {
  try {
    var result = await getInteractionImagesForACompanyRepository(
      companyId,
      page,
      pageSize
    );
    return result;
  } catch (error) {
    console.log(error);
  }
};

export const getInteractionDocsForACompany = async (
  companyId,
  page,
  pageSize
) => {
  try {
    var result = await getInteractionDocsForACompanyRepository(
      companyId,
      page,
      pageSize
    );
    return result;
  } catch (error) {
    console.log(error);
  }
};

export const getATask = async (taskId) => {
  try {
    var response = await getATaskRepository(taskId);
    return response;
  } catch (error) {
    console.log(error);
  }
};

// Tasks - List tasks filtered by state for one company
// ----------------------------------------------------
// let interactions = UpSales.Task.listTasksInTimeIntervalForACompanyFilteredByState(page, pageSize, dateFrom, dateTo, companyId, stateName)
// interactions.then(result => result.next())
// interactions.then(result => result.previous())
export const listTasksForACompany = async (
  page,
  pageSize,
  dateFrom,
  dateTo,
  companyId,
  schedulingState,
  stateName
) => {
  try {
    var result = await listTasksForACompanyRepository(
      page,
      pageSize,
      dateFrom,
      dateTo,
      companyId,
      schedulingState,
      stateName
    );
    return result;
  } catch (error) {
    console.log(error);
  }
};

// Tasks - List tasks filtereb by interval of time
export const listTasksInTimeIntervalFilteredByState = async (
  page,
  pageSize,
  dateFrom,
  dateTo,
  schedulingState,
  stateName
) => {
  try {
    var result = await listTasksInTimeIntervalFilteredByStateRepository(
      page,
      pageSize,
      dateFrom,
      dateTo,
      schedulingState,
      stateName
    );
    return result;
  } catch (error) {
    console.log("SERVICE: ERROR listTasksInTimeIntervalFilteredByState", error);
  }
};

// UpSales.Task.listTasksInTimeIntervalForAOpportunityFilteredByState(page, pageSize, dateFrom, dateTo, opportunityId,schedulingState, stateName)
export const listTasksForAnOpportunity = async (
  page,
  pageSize,
  dateFrom,
  dateTo,
  opportunityId,
  schedulingState,
  stateName
) => {
  try {
    let result = await listTasksForAnOpportunityRepository(
      page,
      pageSize,
      dateFrom,
      dateTo,
      opportunityId,
      schedulingState,
      stateName
    );
    return result;
  } catch (error) {
    console.log("SERVICE: ERROR listTasksForAnOpportunity", error);
  }
};

// CREATE TASK MODAL.JS - UpSales.Task.createTask(body)
export const createTask = async (body) => {
  try {
    let result = await createTaskRepository(body);
    return result;
  } catch (error) {
    console.log("SERVICE: ERROR > CREATE TASK:", error);
  }
};

// Task - Modify Task
// UpSales.Task.updateTask(taskId, body)
export const modifyTask = async (taskId, body) => {
  try {
    let result = await modifyTaskRepository(taskId, body);
    return result;
  } catch (error) {
    console.log("SERVICE: ERROR modifyTask", error);
    throw Error(error);
  }
};

// CREATE TASK MODAL.JS - ADD DOCUMENTS TO TASK
// UpSales.Task.uploadDocuments(companyId, taskId, files)
export const addDocsToTask = async (companyId, taskId, files) => {
  try {
    let result = await addDocsToTaskRepository(companyId, taskId, files);
    return result;
  } catch (error) {
    console.log("SERVICE: ERROR > addDocsToTask:", error);
  }
};

// Interaction - List active interactions for all companies
// UpSales.Interaction.listInteractionsForAllCompanies(page, pageSize, query)
export const listInteractionsForAllCompanies = async (
  page,
  pageSize,
  query,
  state,
  userId = null
) => {
  try {
    let result = await listInteractionsForAllCompaniesRepository(
      page,
      pageSize,
      query,
      state,
      userId
    );

    return result;
  } catch (error) {
    console.log("SERVICE: ERROR > listInteractionsForAllCompanies:", error);
  }
};

// LISTADO TAREAS ASOCIADAS A UNA INTERACCIÓN
// let interactions = UpSales.Task.listTasksInTimeIntervalForOneInteractionFilteredByState(page, pageSize, dateFrom, dateTo, companyId, interactionId, schedulingState, states)
export const tasksForAnInteraction = async (
  page,
  pageSize,
  dateFrom,
  dateTo,
  companyId,
  interactionId,
  schedulingState,
  states
) => {
  try {
    let result = await tasksForAnInteractionRepo(
      page,
      pageSize,
      dateFrom,
      dateTo,
      companyId,
      interactionId,
      schedulingState,
      states
    );
    // console.log('>>>> SERVICE: tasksForAnInteractionRepo: result: ', result)
    return result;
  } catch (e) {
    console.log(e);
  }
};

// PRESUPUESTOS
// salesForecastList = UpSales.Finance.listSalesForecast(companyId, page, pageSize, dateFrom, dateTo)
export const getListSalesForecast = async (
  companyId,
  page,
  pageSize,
  dateFrom,
  dateTo
) => {
  try {
    let result = await getListSalesForecastRepo(
      companyId,
      page,
      pageSize,
      dateFrom,
      dateTo
    );
    //console.log('>>>> SERVICE: getListSalesForecast: result: ', result)
    return result;
  } catch (e) {
    console.log(e);
  }
};

// VENTAS
// let sales = UpSales.Finance.listSalesForGraph(companyId, year)
export const listSalesForGraph = async (companyId, year) => {
  try {
    let result = await listSalesForGraphRepo(companyId, year);
    return result;
  } catch (e) {
    console.log(e);
  }
};

// VENTAS: data for modal
// UpSales.Finance.listSales(companyId, page, pageSize, dateFrom, dateTo)
export const listSalesForModal = async (
  companyId,
  page,
  pageSize,
  dateFrom,
  dateTo
) => {
  try {
    let result = await listSalesForModalRepo(
      companyId,
      page,
      pageSize,
      dateFrom,
      dateTo
    );
    return result;
  } catch (e) {
    console.log(e);
  }
};

// UpSales.Finance.computeTotalSalesByYear(companyId, year)
export const computeTotalSalesByYear = async (companyId, year) => {
  try {
    let result = await computeTotalSalesByYearRepo(companyId, year);
    return result;
  } catch (e) {
    console.log(e);
  }
};

// UpSales.Finance.computeTotalSalesForecastsByYear(companyId, year)
export const computeTotalSalesForecastsByYear = async (companyId, year) => {
  try {
    let result = await computeTotalSalesForecastsByYearRepo(companyId, year);
    return result;
  } catch (e) {
    console.log(e);
  }
};

// let balances = UpSales.Finance.listBalances(companyId, page, pageSize, dateFrom, dateTo)
export const getBalanceList = async (
  companyId,
  page,
  pageSize,
  dateFrom,
  dateTo
) => {
  try {
    let result = await getBalanceListRepo(
      companyId,
      page,
      pageSize,
      dateFrom,
      dateTo
    );
    return result;
  } catch (e) {
    console.log(e);
  }
};

export const getSaldoVencido = async (companyId, currentDate) => {
  try {
    let saldoVencido = await getSaldoVencidoRepo(companyId, currentDate);
    return saldoVencido;
  } catch (error) {
    console.log(error);
  }
};

export const getSaldoVivo = async (companyId) => {
  try {
    let saldoVencido = await getSaldoVivoRepo(companyId);
    return saldoVencido;
  } catch (error) {
    console.log(error);
  }
};

export const getPdf = async (companyId, saleId) => {
  try {
    let doc = await getPdfRepo(companyId, saleId);
    return doc;
  } catch (error) {
    console.log(error);
  }
};

// COMPANY STAFF
// UpSales.Company.addStaffMember(companyId, body)
export const createStaffMember = async (companyId, body) => {
  try {
    let result = await createStaffMemberRepo(companyId, body);
    return result;
  } catch (error) {
    throw Error(error);
  }
};

//UpSales.Company.listStaffMembers(companyId, page, pageSize, query)
export const getListStaff = async (
  companyId,
  page = 0,
  pageSize = 1000,
  query = ""
) => {
  try {
    let result = await getListStaffRepo(companyId, page, pageSize, query);
    return result;
  } catch (error) {
    throw Error(error);
  }
};

//UpSales.Company.modifyStaffMember(companyId, staffId, body)
export const saveEditedStaffMember = async (companyId, staffId, body) => {
  try {
    let result = await saveEditedStaffMemberRepo(companyId, staffId, body);
    return result;
  } catch (error) {
    throw Error(error);
  }
};

//UpSales.Company.deleteStaffMember(companyId, staffId)
export const deleteStaffMember = async (companyId, staffId) => {
  try {
    let result = await deleteStaffMemberRepo(companyId, staffId);
    return result;
  } catch (error) {
    throw Error(error);
  }
};

//UpSales.Company.listAddresses(companyId, staffId)
export const getOtherDirections = async (
  companyId,
  page = 0,
  pageSize = 1000,
  query = ""
) => {
  try {
    let result = await getOtherlDirectionsRepo(
      companyId,
      page,
      pageSize,
      query
    );
    return result;
  } catch (error) {
    throw Error(error);
  }
};

//UpSales.Company.addAddress(companyId, body)
export const addOtherDirection = async (companyId, body) => {
  try {
    let result = await addOtherDirectionRepo(companyId, body);
    return result;
  } catch (error) {
    throw Error(error);
  }
};

//UpSales.Company.updateAddress(companyId,directionId,body);
export const saveEditedOtherDirection = async (
  companyId,
  directionId,
  body
) => {
  try {
    let result = await saveEditedOtherDirectionRepo(
      companyId,
      directionId,
      body
    );
    return result;
  } catch (error) {
    throw Error(error);
  }
};

//UpSales.Company.deleteAddress(companyId, addressId)
export const deleteOtherDirection = async (companyId, addressId) => {
  try {
    let result = await deleteOtherDirectionRepo(companyId, addressId);
    return result;
  } catch (error) {
    throw Error(error);
  }
};

//UpSales.Company.modifyFinance(companyId, body)
export const modifyFinance = async (companyId, body) => {
  try {
    let result = await modifyFinanceRepo(companyId, body);
    return result;
  } catch (error) {
    throw Error(error);
  }
};

//UpSales.Company.getPaymentMethods(companyId)
export const getListPaymentMethods = async (companyId, body) => {
  try {
    let result = await getListPaymentMethodsRepo(companyId, body);
    return result;
  } catch (error) {
    throw Error(error);
  }
};

//UpSales.Company.createPaymentMethod(body)
export const createPaymentMethod = async (body) => {
  try {
    let result = await createPaymentMethodRepo(body);
    return result;
  } catch (error) {
    throw Error(error);
  }
};

//
const filterArrayByName = (array, nameToExclude='UNDEFINED') => {
  let result = array.filter(item => item.name !== nameToExclude && item.name.length > 2)
  return result
}

// UpSales.PostalAddress.listCountries(page, pageSize, query)
export const listCountries = async (page, pageSize, query) => {
  try {
    let result = await listCountriesRepo(page, pageSize, query);
    return filterArrayByName(result.data);
  } catch (error) {
    throw Error(error);
  }
};

// UpSales.PostalAddress.listProvincesByCountry(countryId, page, pageSize, query)
export const listProvincesByCountry = async (page, pageSize, query) => {
  try {
    let result = await listProvincesByCountryRepo(page, pageSize, query);
    return filterArrayByName(result.data);
  } catch (error) {
    throw Error(error);
  }
};

// UpSales.PostalAddress.listProvinces(page, pageSize, query)
export const listProvinces = async (page, pageSize, query) => {
  try {
    let result = await listProvincesRepo(page, pageSize, query);
    return filterArrayByName(result.data);
  } catch (error) {
    throw Error(error);
  }
};

export const listLocalities = async (page, pageSize, query, countryId, provinceId) => {
  try {
    let result = await listLocalitiesRepo(page, pageSize, query, countryId, provinceId)
    return filterArrayByName(result.data);
  } catch (error) {
    throw Error(error);
  }
};
