// Created by Pablo Collado 26 Sept 2022

import React, { useEffect, useState } from "react";
import Media from 'react-bootstrap/Media';
import defaultLogo from '../assets/img/logoPorDefecto.png'

// PROPS:
// client = client object data
// indicator = number 

export default function ClientLogo(props) {

    return(
        <Media>
            <div className="pos-r">
                <img style={{width:40, height:40, borderRadius: 50,}} src={props.client.thumbnail ? props.client.thumbnail : defaultLogo}></img>
                {props.indicator !=0 ? <div style={{position: 'absolute', top:0, right:-5,}} className="indicator-styles">
                    {props.indicator}
                </div> : ''}
            </div>
        </Media>
    )
}