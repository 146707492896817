import React from "react";

import { randomId } from "../CommonScripts";

  

export default function ShowTextWithBreakLines(props) {


    const ShowInteractionContentParagrah = (props) => {
        return <p>{props.line}</p>;
      };
    let result = [];
    if (props.content) {
        let words = props.content.split("\n");
        words.forEach((word) => {
        result.push(
            <ShowInteractionContentParagrah
            key={randomId()}
            line={word}
            ></ShowInteractionContentParagrah>
        );
        });
    }
return result;
};