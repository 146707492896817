// Created by Pablo Collado 23 Sept 2022

import React, { useEffect, useState } from "react";

// COMMON SCRIPTS
import {truncateText} from '../CommonScripts'

// COMPONENTS
import LoadingAnimation from '../LoadingAnimation'
import LoadingAnimationMiniSize from '../LoadingAnimationMiniSize' 
import UpSalesMessage from "../UpSalesMessage";

// BACKEND FUNCTIONS
import { getTopCompaniesWithTasksCountList, getOverdueTasksForActiveProjects,
    getProjectsListForOneCompanyWithTasksCount } from "../../api_communication/service/ProjectService"; 

// IMAGES
import icProyectos from '../../assets/img/ic-proyecto-nuevo-big.png'
import btnMas from '../../assets/img/btn-mas.png'

// EXTERNAL LIBRARIES
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {Link} from 'react-router-dom'
import Accordion from 'react-bootstrap/Accordion';
import moment from 'moment'
import ClientLogo from "../ClientLogo";
import ProjectStateIcon from "./ProjectStateIcon";


export default function DashboardProjectsList(){

    const [clientsListToShow, setClientsListToShow] = useState([])
    
    const [clientPage, setClientPage] = useState(0)
    const [clientPaginationSize, setClientPaginationSize] = useState(10)

    const [clientLoadingSpinner, setClientLoadingSpinner] = useState(false)
    const [showErrorUpsalesMsg, setShowUpsalesMsg] = useState(false)

    const [showBtnMoreClients, setShowBtnMoreClients] = useState(false)

    const [countOverdueTasksForActiveProjects, setCountOverdueTasksForActiveProjects] = useState(null)

    useEffect(()=>{
        getOverdueTasksForAllActiveProjects()
        loadClientes(clientPage, clientPaginationSize)
    },[])

    const getOverdueTasksForAllActiveProjects = async ()=> {
        let today = moment().format('DD/MM/YYYY HH:mm:ss')
        try {
            let count = await getOverdueTasksForActiveProjects(today)
            //console.log('getOverdueTasksForActiveProjects: result: ', count)
            if (!count || count !== false) {
                setCountOverdueTasksForActiveProjects(count)
            } else {
                setShowUpsalesMsg(true)
            }
        } catch (error) {
            setShowUpsalesMsg(true)
            console.log(error)
        }
    }

    const loadClientes = async (page, pageSize)=>{
        let currentDate = moment().format('DD/MM/YYYY HH:mm:ss')
        try {
            setClientLoadingSpinner(true)
            const [clientsList, nextClientsList] = await Promise.all(
                [getTopCompaniesWithTasksCountList(page, pageSize, currentDate), getTopCompaniesWithTasksCountList(page + 1, pageSize, currentDate)]
            )
            //console.log('DashboardProjectsList: clientLsit: ', clientsList)
            //console.log('DashboardProjectsList: nextClientsList: ', nextClientsList)

            setClientLoadingSpinner(false)

            if (!nextClientsList || nextClientsList.length === 0) {
                setShowBtnMoreClients(false)
            } else {
                setShowBtnMoreClients(true)
            }

            if (clientsList === false || nextClientsList === false) {
                setShowUpsalesMsg(true)
                return false
            } else {
                setClientsListToShow([...clientsListToShow, ...clientsList])
                setClientPage(page)
            }
        } catch (error) {
            console.log(error)
            setShowUpsalesMsg(true)
        }
    }

    const loadMoreClients = ()=>{
        loadClientes(clientPage + 1, clientPaginationSize)
    }

    const closeMessage = ()=> {
        setShowUpsalesMsg(false)
    }

    // Projects
    const ShowProjectsListForAClient = (props)=> {
        return (
            <Link className="color-link row w-100 py-2" to={{pathname:'/DetalleProyecto',state: props.project}}>
                    <Col className="d-flex justify-content-center align-items-start" xs={4}>
                        <div style={{position: 'relative'}}>
                            <ProjectStateIcon projectState={props.project.state} />
                            {props.project.taskCount.overdue !==0 ? <div style={{position: 'absolute', bottom:-4, right:0,}} className="indicator-styles">
                                {props.project.taskCount.overdue}
                            </div> : ''} 
                        </div>
                        
                    </Col>
                    <Col xs={8} className="justify-self-start align-self-center">
                        <p title={props.project.name} className="text-start light-grey">{truncateText(props.project.name, 40)}</p>
                    </Col>
            </Link>
        )
    }

    // Clients
    const ShowIndividualClient = (props)=> {
        //console.log('ProjectsListForACompany: props: ', props)
        const [projectsList, setProjectsList] = useState([])
        const [loadingProjectsAnimation, setLoadingProjectsAnimation] = useState(false)
        const [currentProjectsPage, setCurrentProjectsPage] = useState(0)
        const [projectsTotalPages, setProjectsTotalPages] = useState(0)
        const [showMoreProjectsButton, setShowMoreProjectsButton] = useState(false)

        useEffect(()=>{
            
        },[])

        const scrollToBottom = (clientId)=>{
            let objDiv = document.getElementById(clientId);
            objDiv.style = "scroll-behavior: smooth"
            objDiv.scrollTop = objDiv.scrollHeight;
        }

        const loadProjectsListForThisClient = async (currentPage = 0, clientId = null)=>{
            const PAGE_SIZE = 10
            let currentDate = moment().format('DD/MM/YYYY HH:mm:ss')
            try {
                if (!clientId) setLoadingProjectsAnimation(true)
                let projects = await getProjectsListForOneCompanyWithTasksCount(currentPage, PAGE_SIZE, props.client.id, currentDate)
                //console.log('projects: ', projects)
                if (projects === false) {
                    setShowUpsalesMsg(true)
                } else {
                    if (!clientId) setLoadingProjectsAnimation(false)
                    setProjectsTotalPages(projects.totalPages -1)
                    setCurrentProjectsPage(currentPage)
                    setProjectsList([...projectsList, ...projects.data])
                    if (currentPage < projects.totalPages -1) {
                        setShowMoreProjectsButton(true)
                    } else {
                        setShowMoreProjectsButton(false)
                    }
                    setTimeout(() => {
                        if (clientId) scrollToBottom(clientId)
                    }, 500);
                }
               
            } catch (error) {
                console.log(error)
                setShowUpsalesMsg(true)
            }
        }

        const loadMoreProjectsForThisClient = (clientId) => {
            if (currentProjectsPage < projectsTotalPages ) {
                loadProjectsListForThisClient(currentProjectsPage + 1, clientId)
            }
            
        }

        return (
            <React.Fragment>
                <Accordion.Toggle as={Col} xs={12} eventKey={props.indice} className="pt-2 accordion-row justify-content-center align-items-center" onClick={()=>loadProjectsListForThisClient()}>
                    <Row className="accordion-row-hover justify-content-center">
                        <Col xs={5} lg={4} className="d-center"><ClientLogo client={props.client} indicator={props.client.taskCount.overdue}/></Col>
                        <Col xs={10} lg={8} className="align-self-center">
                            <b className="text-center dark-blue">{props.client.alias}</b>
                        </Col>
                        <Col md={10} className="brd-b-blue py-1"></Col>
                    </Row>
                </Accordion.Toggle>
                <Accordion.Collapse id={props.client.id} as={Col} eventKey={props.indice} className="col-12 accordion-scroll" style={{position: 'relative', overflowY:'auto', maxHeight:550}}>
                    <Row  className="row justify-content-center" >
                        {(loadingProjectsAnimation) ? <LoadingAnimation/> 
                        : (projectsList.length !==0 && projectsList.map((project, index) => 
                            <ShowProjectsListForAClient project={project} id={project.id} key={index} />))}
                        <Col xs={12}>
                            {showMoreProjectsButton && !loadingProjectsAnimation &&  <div className="btn-mas-container row" onClick={()=>loadMoreProjectsForThisClient(props.client.id)}>
                                <img src={btnMas}></img>
                            </div>}
                            <Row className="d-center"><Col md={10} className="brd-b-blue py-1"></Col></Row>
                        </Col>
                    </Row>
                </Accordion.Collapse>
            </React.Fragment>
        )
    }

    return (
        <React.Fragment>

            <UpSalesMessage
                level={'danger'}
                message={'Ha ocurrido un error cargando los datos.'} 
                visible={showErrorUpsalesMsg} 
                autoClose={false}
                closeMessage={closeMessage} 
            />

            {clientLoadingSpinner ? <LoadingAnimation /> : 
                <React.Fragment>
                    <p></p>
                    <Row className="pb-4 bg-white">
                        <Col xs={12} className="bg-white pt-3 justify-content-center">
                            <Row className="align-items-center justify-content-center">
                                <Col className="d-center" md={4}>
                                    <div className="pos-r">
                                        <img src={icProyectos}></img>
                                        {countOverdueTasksForActiveProjects !==0 && 
                                            <div style={{position: 'absolute', top:0, right:10,}} className="indicator-styles">
                                                {countOverdueTasksForActiveProjects}
                                            </div>
                                        }
                                    </div>
                                </Col>
                                <Col className="dark-blue" md={8}>
                                    <p className="mb-0 l-fs-18"><b>PROYECTOS</b></p>
                                </Col>
                                <Col className="brd-b-blue py-1" md={10}></Col>
                            </Row>
                        </Col>
                        <Col className="flex-column">

                            {clientLoadingSpinner && <LoadingAnimationMiniSize></LoadingAnimationMiniSize> }

                            {!clientLoadingSpinner && clientsListToShow.length !==0 && 
                                <Row >
                                    <Col xs={12}  className="accordion-scroll" style={{maxHeight:'1500px'}}>
                                        <Accordion as={Row}>
                                        {clientsListToShow.map(function(client, index){
                                            return (<ShowIndividualClient client={client} indice={client.id} key={index}/>)
                                        })}
                                        </Accordion>
                                    </Col>
                                </Row>}

                            {clientsListToShow.length === 0 && <Row>
                                    <Col xs={12} className="my-5 d-center">
                                        <p>No hay información para mostrar</p>
                                    </Col>
                                </Row>}

                            {!clientLoadingSpinner && showBtnMoreClients && <div className="btn-mas-container row" onClick={()=>loadMoreClients()}>
                                <img src={btnMas}></img>
                            </div>}
                        </Col>
                    </Row>
                </React.Fragment>
            }
        </React.Fragment>
    )
}