import React, { useState, useEffect } from "react";
import {listInteractionsForAnOpportunity} from "../../api_communication/service/OpportunityService";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

// LIBRERIAS EXTERNAS
import { Link } from "react-router-dom";

// IMAGES
import icAdjunto from "../../assets/img/ic-adjuntar.png";

// COMPONENTES
import CreateInteraction from "../CreateInteractionModal";
import ProjectStateIcon from "../proyectos/ProjectStateIcon";
import {
  InteractionModeIcon, InteractionDueDateIcon, truncateText, OpportunityIconState
} from "../CommonScripts";
import ShowArchivedInteractions from "../ShowArchivedInteractions";
import ShowTextWithBreakLines from '../commons/ShowTextWithBreakLines'
import LoadingAnimation from "../LoadingAnimation";

// BACKEND FUNCTIONS
import { getCurrentUserData } from "../../api_communication/service/UsersService";
import { getProjectActiveInteractions } from "../proyectos/funcionesProyectos";

// PROPS
// project={ProjectDetailsData}
// showUpSalesMessageFn={showUpSalesMessage}
// refresh={refreshWithNewData}
// hideCreateInteractionButton={true/false/null} - if true hide create interaction button

export default function InteractionTimelineChat(props) {

  const [loadingAnimation, setLoadingAnimation] = useState(true)
  const [interactions, setInteractions] = useState(null)
  const [currentUser, setCurrentUser] = useState(null)

  // TODO Paginación
  const NUMBER_OF_ELEMENTS = 5

  useEffect(() => {

    const getInteractions = async () => {

      setLoadingAnimation(true)
      let interactions
      try {
        if (props.project)
          interactions = await getProjectActiveInteractions(props.project.id, 0, NUMBER_OF_ELEMENTS)
        else if (props.opportunity)
          interactions = await listInteractionsForAnOpportunity(props.opportunity.id, 0, NUMBER_OF_ELEMENTS)
      } catch (error) {
        props.showUpSalesMessageFn('danger', 'Ha ocurrido un error al obtener las interacciones', true, false)
      }
      setInteractions(interactions)
      setLoadingAnimation(false)
    }
    getInteractions()
        
    const getCurrentUser = async () => {
      const currentUser = await getCurrentUserData()
      setCurrentUser(currentUser)
    }
    getCurrentUser()

  }, [props])

  const showInteractions = () => {

    let rows = []
    interactions.forEach(element =>{
        rows.push(
            <InteractionItem interaction={element} key={element.id} />
          );
    })
    
    return rows
  }

  const InteractionItem = (props) => {
    return(
        <Row className={[
            `${
              props.interaction.createdBy && currentUser &&
              props.interaction.createdBy.username === currentUser.username
                ? "containerRight row interactions-row"
                : "containerLeft row interactions-row"
            }`,
          ]}>
            <Link to={{pathname:'/DetalleInteraccion',state: props.interaction}} className="col-12 py-1 color-link">
                <Row>
                    <Col xs={12} className="align-self-end">
                        <img className={[
                            `${
                                props.interaction.createdBy && currentUser &&
                                props.interaction.createdBy.username ===
                                currentUser.username
                                ? "floatLeft"
                                : "floatRight"
                            }` + " icon-mode-style"
                            ]} src={InteractionModeIcon(props.interaction.mode)}/>
                        <p
                            className={[
                            `${
                                props.interaction.createdBy && currentUser &&
                                props.interaction.createdBy.username ===
                                currentUser.username
                                ? "floatRight orange-up"
                                : "floatLeft orange-up"
                            }`,
                            ]}
                        >
                            {props.interaction.createdBy && currentUser
                            ? props.interaction.createdBy.username ===
                                currentUser.username
                                ? "Yo"
                                : props.interaction.createdBy.username
                            : ""}
                        </p>
                    </Col>
                </Row>
                <Row className="d-center">
                    <Col xs={2} className={[
                          `${
                            props.interaction.createdBy && currentUser &&
                            props.interaction.createdBy.username ===
                            currentUser.username
                              ? "d-center-column-sm order-2"
                              : "d-center-column-sm"
                          }`,
                        ]}
                    >
                      {props.interaction.opportunity && <OpportunityIconState opportunity={props.interaction.opportunity}/>}
                      {props.interaction.project && <ProjectStateIcon projectState={props.interaction.project.state}/>}
                    </Col>
                    <Col className="col-10 py-2">
                        <div id="interactionContent" className={[
                            `${
                                props.interaction.createdBy && currentUser &&
                                props.interaction.createdBy.username ===
                                currentUser.username
                                ? "light-grey d-flex justify-content-end"
                                : "light-grey"
                            }`,
                            ]}
                            title={props.interaction.content}
                        >
                            {/*truncateText(props.interaction.content, 60)*/}
                            <div className="text-left">
                                <ShowTextWithBreakLines content={truncateText(props.interaction.content, 70)} ></ShowTextWithBreakLines>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                  <Col className={[
                          `${
                              props.interaction.createdBy && currentUser &&
                              props.interaction.createdBy.username ===
                              currentUser.username
                              ? "col-12 d-flex align-items-center justify-content-start"
                              : "col-12 d-flex align-items-center justify-content-end"
                          }`,
                          ]}>
                      <div className="">
                          <div className="mx-2 light-grey-text l-fs-12">
                              {props.interaction.date}
                          </div>
                      </div>
                      <div className="ml-1">
                          {props.interaction.hasDocuments ? (
                          <img width={15} src={icAdjunto} />
                          ) : null}
                      </div>
                      <InteractionDueDateIcon
                          interaction={props.interaction}
                      />
                  </Col>
                </Row>
            </Link>
        </Row>
    )
  }
    
  return (
    <Row className={[props.project ? "mx-1 py-5 mt-3 bg-white justify-content-center" : "py-5 mt-3 bg-white justify-content-center"]}>
      <Col className="interactionWindow" xs={12}>
        <Row>
          <Col xs={12} className="  pb-4 ">
            <div className="text-md-center text-left dark-blue pos-r">
              <b>
                HISTORIAL {props.opportunity ? 'DE LA OPORTUNIDAD' : 'DEL PROYECTO'}
                <br />
                LÍNEA DE TIEMPO{" "}
              </b>
              <div
                className="row d-flex flex-row justify-content-end align-items-center"
                style={{
                  position: "absolute",
                  left: '18px',
                  top: 0,
                  width: "100%",
                }}
              >
                {props.opportunity && 
                  <ShowArchivedInteractions 
                    opportunity={props.opportunity} 
                    projectId={props.project ? props.project.id : null} 
                    refresh={props.refresh} 
                    msg={props.showUpSalesMessageFn}
                  />
                }

                {(!props.hideCreateInteractionButton || props.hideCreateInteractionButton===false) && 
                  <CreateInteraction 
                    opportunity={props.opportunity} 
                    project={props.project} 
                    idCompany={props.opportunity ? props.opportunity.companyId : props.project.company.id}
                    showUpSalesMessageFn={props.showUpSalesMessageFn} 
                    refresh={props.refresh} 
                  />
                }
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          {loadingAnimation ?
            <div className="col-12 pb-5 bg-white">
              <LoadingAnimation text={'Cargando datos...'}/>
            </div> :
            <div className={`col-12 pb-5 mt-5 px-5 bg-white ${interactions && interactions.length !==0 && 'interaction-list-scroll'}`}>
              {(interactions && interactions.length !== 0) ? showInteractions() :
                <p style={{color: 'red', textAlign:'center', paddingTop:23}}>
                No hay interacciones para mostrar
                </p>
              }
            </div>
          }
        </Row>
        
      </Col>
    </Row>
  ) 
}
