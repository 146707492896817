import React, { useEffect, useState } from "react";

// PROPS: setValue={Function} - To return to parent selected by user
//        countryId={countryId} - if !==null we search localities by country
//        provinceId={provinceId} - if !==null we search localities by province

// COMPONENTES
import { DynamicSelect } from "./DynamicSelect";

// SERVICE
import { listLocalities } from "../../api_communication/service/ClientService";

// ************ TEMP *****************************************************
// PROPS DynamicSelect:
// optionsArray={} - array of values for show in options 
// searchTerm={} - returns user writing search word ->¿?
// onChange={handleChange} - Function to return user search word
// resetSearch={true or false} - prop: if true reset input value
// inputId={} - string - input id
// inputPlaceholder={} - string 'Buscar por X', 'Nombre de empresa', etc...
// ***********************************************************************

export function LocalityDynamicSelect(props) {

    const [countryId, setCountryId] = useState([])
    const [provinceId, setProvinceId] = useState([])
    const [array, setArray] = useState([])
    const [resetSearch, setResetSearch] = useState(false)
    const [userInput, setUserInput] = useState(null)

    useEffect(() => {

        if (props.countryId !== countryId || props.provinceId !== provinceId) {
            setUserInput(null)
            setArray([])
            setCountryId(props.countryId)
            setProvinceId(props.provinceId)
            setResetSearch(true)
            props.setValue(null)
        }

        // console.log("LOCALITY SELECT :: countryId :: " + props.countryId + ", provinceId :: " + props.provinceId, ", user input :: ", userInput)

        loadLocalities(userInput, props.countryId, props.provinceId)
    },[props.countryId, props.provinceId, userInput])

    const handleUserInput = (input, userSelectId) => {
        setResetSearch(false)
        try {
            if (userSelectId === null) {
                
                if (input && input.length > 2)
                    setUserInput(input)
                else {
                    setUserInput(null)
                    props.setValue(null)
                }
                
            } else {
                props.setValue(userSelectId)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const loadLocalities = async (query, countryId, provinceId) => {
        try {
            let localities = await listLocalities(0, 50, query, countryId, provinceId)
            setArray(localities)
        } catch (error) {
        console.log(error)
        }
    }

    return (
        <React.Fragment>
            <DynamicSelect
                optionsArray={array} 
                onChange={handleUserInput} 
                resetSearch={resetSearch} 
                inputId={'locality-filter'} 
                inputPlaceholder={'Localidad'}/>
        </React.Fragment>
    )
}