// --------------------------------------------
// Created by Pablo Collado 24/11/2020
// --------------------------------------------

import React, {useState, useEffect} from 'react'
import Modal from 'react-bootstrap/Modal'

// IMAGES
import icArchived from '../../assets/img/ic-ver-archivados.png'
import icAdjuntar from '../../assets/img/ic-adjuntar-celeste.png'
import btnPlus from '../../assets/img/btn-mas.png'
import userImgPlaceholder from "../../assets/img/UserAvatarIconPlaceholder.png";
import icTel from '../../assets/img/ic-tel-blue.png'
import icInteraction from '../../assets/img/ic-interaccion-blue.png'
import icEmail from '../../assets/img/ic-correo-blue.png'
import icCam from '../../assets/img/ic-cam-blue.png'

// COMPONENTS
import {OpportunityIconState, truncateText} from '../CommonScripts'
import ProjectStateIcon from '../proyectos/ProjectStateIcon'

// BACKEND FUNCTIONS
import {
    tasksForAnInteraction,
    listTasksInTimeIntervalFilteredByState,
    listTasksForACompany,
    listTasksForAnOpportunity
} from '../../api_communication/service/ClientService';
import { restoreTask } from '../../api_communication/service/TaskService';

import { listTasksForAProject } from '../../api_communication/service/ProjectService'

// ONLY FOR TASKS
// CALL FROM PARENT LIKE THIS:
// ------------------------------------------------------------------------------------
//  <ShowArchivedTasks 
//    all={true or false} -> To list all tasks in Dashboard page
//    client={client} -> To list in 'Detalle Cliente' page
//    opportunity={opportunity} -> To list in 'Detalle Oportunidad' page
//    interaction={interaction} -> To list in 'Detalle Interacción' page
//    project={project} -> To list in 'Detalle proyecto' page
//    refresh={this.refreshInteractions} 
//    msg={this.showUpSalesMessage}
//  />
// ------------------------------------------------------------------------------------

const ArchivedTaskItem = (props) => {
  const refreshFn = props.refreshFn
  const msg = props.msg

  const restore = async (task) => {
    try {
      await restoreTask(task)
      msg('success','Restaurando tarea...', true, true)
      props.hideModal()
      setTimeout(function(){
        refreshFn()
      }, 3000)
    } catch(e) {
      msg('danger','Ha ocurrido un error. No se ha podido restaurar la tarea.', true, true)
      console.log(e)
    }
    
  }

  const textModeImage = (mode) => {
    switch (mode) {
      case 'text':
        return icInteraction
      case 'phone':
        return icTel
      case 'email':
        return icEmail
      case 'photo':
        return icCam
      default:
        return icInteraction
    }
  }

  return (
    <React.Fragment>
      <div className="col my-1 px-4 px-xl-5">
        <div className="row flex-column">

          <div className="col p-2 bg-timeline-grey d-flex flex-row align-items-center" 
               style={{borderTopLeftRadius: '5px', borderTopRightRadius: '5px', position:'relative', minHeight:40}}>
            <div className="pr-2">
              <img style={{width: 25, height: 25}} src={textModeImage(props.task.mode)} title={props.task.mode ? props.task.mode : 'texto'}/>
            </div>   
            {props.task.opportunity && <OpportunityIconState opportunity={props.task.opportunity}/>}
            {props.task.project && <ProjectStateIcon projectState={props.task.project.state}/>}
            <div className="pl-2">
              <div style={{fontSize: 12, color:'#FFFFFF'}}>{props.task.scheduling.dueDate}</div>
            </div>
            
            <p className="timeline-date pl-2">{props.task.date}</p>
            <div style={{position:'absolute', top:5, right:5}}>
              <img src={props.task.createdBy ? (props.task.createdBy.profilePhoto ? props.task.createdBy.profilePhoto : userImgPlaceholder) : userImgPlaceholder} 
                   style={{borderRadius:'50%', width:'30px', height:'30px'}} 
                   title={props.task.createdBy ? props.task.createdBy.username : '?'}/>
            </div>
          </div>

          <div className="col bg-timeline-dark-grey p-2 pos-r" 
            style={{borderBottomLeftRadius: '5px', borderBottomRightRadius: '5px', minHeight:'90px', position: 'relative'}}>

            {props.task.company &&  <p className="timeline-opportunity-description" title={props.task.company.alias}>
                                      {props.task.company.alias}
                                    </p>}
            {props.task.opportunity && <p className="timeline-opportunity-description" title={props.task.opportunity.name}>
                                      {truncateText(props.task.opportunity.name)}
                                    </p>}
            {props.task.project && <p className="timeline-opportunity-description" title={props.task.project.name}>
                                      {truncateText(props.task.project.name)}
                                    </p>}

            <p className="timeline-content" title={props.task.content}>{truncateText(props.task.content)}</p>

            {props.task.hasDocuments && <img style={{width: 12, height: 12, position:'absolute', left:10, bottom:10}} 
                                             src={icAdjuntar} alt="Adjuntos" title="Adjuntos"/>}

            <div style={{position:'absolute', bottom:10, right:10}}>
              <div onClick={()=> restore(props.task)} title="Desarchivar interacción" 
                   className="d-center btn-restore">Restaurar</div>
            </div>
          </div>

        </div>
      </div>
    </React.Fragment>
  )
}

export default function ShowArchivedTasks(props) {

    const [show, setShow] = useState(false)
    const [paginationCount, setPaginationCount] = useState(0)
    const [tasksList, setTasksList] = useState(null)
    
    // Btn for more
    const [showPlusBtn, setShowPlusBtn] = useState(false)

    const refreshFn = props.refresh
    const msg = props.msg

    // Max Number of tasks to show
    const NUMBER_OF_ELEMENTS = 5

    useEffect(() => {
      setTasksList([])
      getTasks(0)
    }, [props.project, props.opportunity, props.interaction, props.client]);

    const getTasks = async (page) => {
      try {
        let tasks
        if (props.client) {
          tasks = await listTasksForACompany(page, NUMBER_OF_ELEMENTS, '', '', props.client.id, 'ACTIVE', ['ARCHIVED'])
        } else if (props.opportunity) {
          tasks = await listTasksForAnOpportunity(page, NUMBER_OF_ELEMENTS, '', '', props.opportunity.id, 'ACTIVE', ['ARCHIVED'])
        } else if (props.interaction) {
          let companyId = props.interaction.company ? props.interaction.company.id : props.interaction.companyId
          tasks = await tasksForAnInteraction(page, NUMBER_OF_ELEMENTS, '', '', companyId, props.interaction.id, 'ACTIVE', ['ARCHIVED'])
        } else if (props.project) {
          tasks = await listTasksForAProject(props.project.id, page, NUMBER_OF_ELEMENTS, "", "",  "ACTIVE", ['ARCHIVED'])
        } else {
          tasks = await listTasksInTimeIntervalFilteredByState(page, NUMBER_OF_ELEMENTS, '', '', 'ACTIVE', ['ARCHIVED'])
        }

        if (tasks && tasks.totalPages > tasks.currentPage + 1) 
          setShowPlusBtn(true)
        else
          setShowPlusBtn(false)
  
        if (tasksList && page > 0) {
          setTasksList([...tasksList, ...tasks.data])
        } else
          setTasksList(tasks.data)
      } catch (error) {
        msg('danger','Ha ocurrido un error al obtener las tareas archivadas.', true, true)
      }
    }
    
    const moreTasks = async () => {
      try {
        await getTasks(paginationCount + 1)
        setPaginationCount(paginationCount + 1)
        scrollToBottom()
      } catch (error) {
        msg('danger','Ha ocurrido un error al obtener las tareas archivadas.', true, true)
        console.log(error)
      }
    }

    const scrollToBottom = () => {
      var objDiv = document.getElementById("scrollToBottom");
      objDiv.scrollBy(0, 680)
 }

    const showTasks = (arrayTasks, refreshFn, msg) => {
      let array = []
      arrayTasks.forEach( element => {
        array.push(<ArchivedTaskItem
                      key={element.id}
                      task={element}
                      refreshFn={refreshFn}
                      msg={msg}
                      hideModal={cancelForm}
                      />)
                  })
      return array
    }
    
    const cancelForm =  () => {
      if (paginationCount > 0) {
        getTasks(0)
        setPaginationCount(0)
      }
      setShow(false)
    }

    return (
      <React.Fragment>
        <div title="Ver tareas archivadas" 
              className={(props.client) ? 'btn-show-archived-no-margin' : "btn-show-archived"} 
              onClick={() => setShow(true)}>
            <img src={icArchived} style={{width:40}}/>
        </div>

        <Modal
          style={{overflow:'hidden'}}
          id="showArchivedInteractions"
          className="modal-border"
          show={show}
          onHide={() => setShow(false)}
          dialogClassName="bg-white"
          aria-labelledby="example-custom-modal-styling-title"
        >
          <Modal.Header className="justify-content-center modal-border modal-border">
            <Modal.Title id="example-custom-modal-styling-title" className="dark-blue d-column">
              <b className="mt-4 text-center">
                TAREAS ARCHIVADAS
              </b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="modal-border" >
            <div id="scrollToBottom" className="row px-4" 
                 style={{overflowY:'auto', maxHeight: 'calc(100vh - 240px)', display:'block', scrollBehavior:'smooth'}}>
              {(tasksList && tasksList.length !== 0) ? 
                              showTasks(tasksList, refreshFn, msg) : 
                              <div className="d-center mt-5">No hay tareas archivadas</div>}
            </div>
            { showPlusBtn && <div className="d-center mt-3">
              <div onClick={()=>moreTasks()}>
                <img src={btnPlus} style={{cursor:'pointer'}}></img>
              </div>
            </div> }
            <div className="d-center mb-5 mt-3">
                <div className="d-center boton-cancelar" onClick={cancelForm}>Cerrar</div>
            </div>
          </Modal.Body>
        </Modal>
      </React.Fragment>
    );
  }