// Create by Pablo Collado 19-Sept-2022

import React, {useState, useEffect} from 'react'
import Modal from 'react-bootstrap/Modal'

// COMMON SCRIPTS
import { randomId, downloadFile } from '../CommonScripts'

// SERVICE
import { exportReport } from '../../api_communication/service/ReportsService'

// EXTERNAL LIBRARIES
import moment from 'moment'
import UpSalesMessage from '../UpSalesMessage'


// PROPS:
// showExportReportModal (true/false): to show or close this modal
// closeExportReportModal: Fn (from parent) to close modal 
// dateFrom: InitialDate, example string "18/09/2022"
// dateTo: EndDate, idem dateFrom.


export default function ExportReport(props) {

    const [monthDateName, setMonthDateName] = useState(null)
    const [month, setMonth] = useState(null)
    const [year, setYear] = useState(null)
    const [currentYear, setCurrentYear] = useState(null)
    const [downloadingFileSpinner, setDownloadingFileSpinner] = useState(false)
    const [showError, setShowError] = useState(false)

    useEffect(() => {
        //console.log('ExportReport: PROPS:: ', props)
        
        let dateFromTemp = moment(props.dateFrom, 'DD/MM/YYYY')
        let monthFileName = dateFromTemp.format('MMMM')
        setMonthDateName(monthFileName.toUpperCase())
        setYear(dateFromTemp.format('YYYY'))
        setMonth(dateFromTemp.format('MM'))
        
        let currentDate = moment();
        setCurrentYear(currentDate.format('YYYY'))
        
      },[props]);

    const getMonthName = (monthNumber) => {
        const date = new Date();
        date.setMonth(monthNumber - 1);
        return date.toLocaleString('es-ES', { month: 'long' });
    }

    const callToBackAndDownloadFile = async (from, to, filename) => {
        //console.log('callToBackAndDownloadFile: params: ', from, ' ', to, ' ', filename)
        try {
            setDownloadingFileSpinner(true)
            let result= await exportReport(from, to, filename)
            //console.log('exportToExcel: result: ', result)
            setDownloadingFileSpinner(false)
            if (result.ok) {
                await downloadFile(result, filename)
                props.closeExportReportModal()
            } else {
                setShowError(true)
            }
            
            
        } catch (error) {
            setShowError(true)
            setDownloadingFileSpinner(false)
            console.log(error)
        }
    }

    const transformDateFromSelects = ()=>{
        let dateFrom = '01/' + month + '/' + year
        let dateTo
        if (month == '12') {
            dateTo = '01/01/' + (parseInt(year) + 1)
        } else {
            dateTo = '01/' + (((parseInt(month) + 1) < 10 ? '0': '') + (parseInt(month) + 1)) + '/' + year
        }
        let monthName = getMonthName(parseInt(month))
        let filename = 'Informe_' + monthName + year
        return {dateFrom: dateFrom, dateTo: dateTo, filename: filename}
    }

    const exportToExcel = async ()=>{
        let result = transformDateFromSelects()
        callToBackAndDownloadFile(result.dateFrom, result.dateTo, result.filename)
    }

    const YearAddSelectOption = props => {
        return (
          <React.Fragment>
            <option value={props.year}>{props.year}</option>
          </React.Fragment>
        )
    }

    const YearSelectOptions = () => {
        // Making select options: from current year to 2021
        let rows = []
        let yearsList= []
        if (!year) yearsList.push(currentYear)
        let currentYearFormatYYYY = moment(currentYear).format('YYYY')
        let lastYear = 2021
        let temp = moment(currentYear)
        for (let i = currentYearFormatYYYY; i > lastYear; i--) {
            temp = temp.subtract(1,'year')
            yearsList.push(temp.format('YYYY'))
          }
        yearsList.forEach(item => {
          rows.push(
            <YearAddSelectOption
            year={item}
            key={randomId()}
            />
          )
        })
        return rows
    }

    const onChangeMonthSelect = (e) =>{
        if (e.target.value !=='Elegir mes') {
            setMonth(e.target.value)
        } else {
            setMonth(null)
        }
    }

    const onChangeYearSelect = (e) =>{
        if (e.target.value !== null) {
            setYear(e.target.value)
        }
    }

    const closeErrorMessage = ()=>{
        setShowError(false)
    }


    return (
    <React.Fragment>
        <UpSalesMessage
            level={'danger'}
            message={'Ha ocurrido un error al realizar la exportación.'}
            visible={showError}
            autoClose={false}
            closeMessage={closeErrorMessage}
        />
        <Modal
            id="exporReportModal"
            show={props.showExportReportModal}
            onHide={props.closeExportReportModal}
            dialogClassName="bg-white"
            keyboard={false}
        >

            <Modal.Header className="justify-content-center modal-border">
                <Modal.Title id="" className="dark-blue d-column">
                    <b className="text-center mt-3">
                        EXPORTAR INFORME A EXCEL  
                    </b>
                    {monthDateName && <b>{monthDateName} {year}</b>}
                </Modal.Title>
            </Modal.Header>

            <Modal.Body >
                <div className="row d-center mb-5">
                    <div className='col-6'>
                    <select onChange={onChangeMonthSelect} id="monthSelected" className=' inputDynamicSelectOnlyDropdown l-fs-14 bg-white'>
                        <option defaultValue={monthDateName ? monthDateName : null}>{monthDateName ? monthDateName : 'Elegir mes'}</option>
                        <option value="01">Enero</option>
                        <option value="02">Febrero</option>
                        <option value="03">Marzo</option>
                        <option value="04">Abril</option>
                        <option value="05">Mayo</option>
                        <option value="06">Junio</option>
                        <option value="07">Julio</option>
                        <option value="08">Agosto</option>
                        <option value="09">Septiembre</option>
                        <option value="10">Octubre</option>
                        <option value="11">Noviembre</option>
                        <option value="12">Diciembre</option>
                    </select>
                    <select onChange={onChangeYearSelect} id="yearSelected" className=' inputDynamicSelectOnlyDropdown l-fs-14 bg-white'>
                        <option defaultValue={year ? year : null}>{year ? year : 'Elegir año'}</option>
                        <YearSelectOptions/>
                    </select>
                    </div>
                </div>
            <div className="d-flex justify-content-center my-4">
                <div className="d-center boton-cancelar mr-3" title="Cancelar" onClick={props.closeExportReportModal}>Cancelar</div>
                <button  title={'Exportar'} onClick={exportToExcel} className="d-center boton-guardar">
                    {downloadingFileSpinner && <span className="spinner-grow spinner-grow-sm mr-2" role="status" aria-hidden="true"></span>}    
                    Exportar
                </button>
            </div>
            </Modal.Body>
        </Modal>
    </React.Fragment>
    )
}