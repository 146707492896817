// COMPONENT TO SHOW TASK FOR PROJECTS AND OPPORTUNITY
//<ShowTaskModal task={task} />

import React, {useState, useEffect} from 'react'
import Modal from 'react-bootstrap/Modal'
import Media from 'react-bootstrap/Media'

// IMAGES
import imgBigOportunity from '../../assets/img/ic-oportunidad-big.png'
import icProyectos from '../../assets/img/ic-proyecto-nuevo-big.png'
import icVer from '../../assets/img/ic-ver.png'
import ShowTextWithBreakLines from '../commons/ShowTextWithBreakLines'

// COMPONENTS
import LoadingAnimation from '../LoadingAnimation'
import {ShowPhotosAndDocumentsMiniatures} from '../MediaManager';
import { OpportunityIconState, InteractionModeIcon } from '../CommonScripts'
import ProjectStateIcon from '../proyectos/ProjectStateIcon'

// BACKEND FUNCTIONS
import {getATask} from '../../api_communication/service/ClientService';


export default function ShowTaskModal(props) {

    const [loadingAnimation, setLoadingAnimation] = useState(false)
    const [show, setShow] = useState(false);
    const [task, setTask] = useState(null)
      
    useEffect(() => {
      //console.log('ShowTaskModal: props: ', props)
      async function getAllInformationAboutTheTask() {
        try{
          setLoadingAnimation(true)
          let task = await getATask(props.task.id)
          setTask(task)
          setLoadingAnimation(false)
        } catch(error) {
          console.log(error)
        }
      }
      getAllInformationAboutTheTask()
      }, [props])
    
    const cancelForm = ()=> {
      setShow(false)
    }

    return (
      <React.Fragment>
        <Media>
          <img src={icVer} className="mr-2 mouse ml-2" title="Mostrar la tarea" onClick={() => setShow(true)}></img>
        </Media>

        <Modal
        id="modalPositionRight"
          show={show}
          onHide={() => setShow(false)}
          dialogClassName="bg-white"
          aria-labelledby="example-custom-modal-styling-title"
          centered
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header className="justify-content-center modal-border mt-5">
            <Modal.Title id="example-custom-modal-styling-title" className="dark-blue d-column">
              <b className="mt-4 my-4 text-center">
                  MOSTRAR TAREA
              </b>
              <Media>
                {props.task.project ? <img src={icProyectos}></img> : <img src={imgBigOportunity}></img>}
              </Media>
             
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="mt-2">
            {loadingAnimation && 
              <div className="overlay-loading-Animation">
                <LoadingAnimation text={'Cargando...'} />
              </div>
            }
            <div className="d-column generaltextInputStyle">
              <p className="mb-3 l-fs-18 blue-up text-center">{props.task ? props.task.company.alias : null}</p>

              {props.task.opportunity && <div>
                <OpportunityIconState opportunity={props.task.opportunity}/>
                {<span className="my-3 light-grey ml-2">{props.task.opportunity.name}</span>}
              </div>}

              {props.task.project && <div>
                <ProjectStateIcon projectState={props.task.project.state}/>
                {<span className="my-3 light-grey ml-2">{props.task.project.name}</span>}
              </div>}
              <div>
                <div className="mt-2 mb-3 mt-5 l-fs-16 light-grey w-100 text-left px-4">
                  <b>Tarea: </b>
                  <ShowTextWithBreakLines
                    content={props.task.content}
                  />
                </div>
                <p className="mb-3 light-grey w-100 text-left px-4"><b>Modo: </b><img style={{width:22, marginLeft: 5, marginBottom: 3}} src={InteractionModeIcon(props.task.mode)}/></p>
                <p className="mb-3 light-grey w-100 text-left px-4"><b>Fecha: </b>{props.task.scheduling ? props.task.scheduling.dueDate.substring(0,10) : null}</p>
                <p className="mb-3 light-grey w-100 text-left px-4"><b>Hora: </b> {props.task.scheduling ? props.task.scheduling.dueDate.substring(11) : null}</p>
              </div>
              {task && task.documents.length != 0 && <ShowPhotosAndDocumentsMiniatures documents={task.documents}/>}

              <div className="d-inline-flex my-5">
                  <div className="d-center boton-cancelar mr-3" onClick={cancelForm}>Cancelar</div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </React.Fragment>
    );
  }