import React, {useState, useEffect} from 'react';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import {updateUserPassword} from '../../api_communication/service/UsersService'
import LoadingAnimationMiniSize from '../../componentes/LoadingAnimationMiniSize'

// CALL FROM PARENT
// <UserEditaPasswordModal userId={userId} show={showEditPasswordModal} hide={hiddeEditUserPasswordModalFn}/>

// NOTE: add Profile pending !!!

export default function UserEditPasswordModal(props) {
    const [show, setShow] = useState(false)
    const [loadingAnimation, setLoadingAnimation] = useState(false)

    let currentPassword = React.createRef() //Referencia al input de la contraseña actual
    let newPassword1 = React.createRef() //Referencia al input 1 de la nueva contraseña
    let newPassword2 = React.createRef() //Referencia al input 2 de la nueva contraseña


    useEffect(() => {
        if(show !== props.show) {
            setShow(props.show)
            console.log('userEdit Modal UPDATE: props: ', props)
        }
    });

    const hiddeEditUserPasswordModalFn = ()=> {
      props.hide()
    }

    //Oculta los errores 
    function hideErrors() {
      document.getElementById('campoVacio').style.display = 'none';
      document.getElementById('nuevaPassNoCoinciden').style.display = 'none';
      document.getElementById('passActualNoCoincide').style.display = 'none';
    }

    //función cambiar contraseña
    const changePassword = async () => {

     /* Variables que almacenan los valores de los imputs del modal */
      let currentPassValue = currentPassword.current.value.trim() //trim para eliminar los espacios en blanco del principio y del final
      let newPass1Value = newPassword1.current.value.trim()
      let newPass2Value = newPassword2.current.value.trim()

      /* Variables que almacenan los párrafos dentro del div de muestra de mensajes */
      let errorCampoVacio = document.getElementById('campoVacio');
      let errorNuevaPassNoCoinciden = document.getElementById('nuevaPassNoCoinciden');
      let errorPassActualNoCoincide = document.getElementById('passActualNoCoincide');
      let passChangeCorrect = document.getElementById('passChangeCorrect');

      /* Variables que almacenan los botones del modal */
      let guardarChangePassword = document.getElementById('guardarChangePassword');
      let cancelarChangePassword = document.getElementById('cancelarChangePassword');
      let cerrarChangePassword =document.getElementById('cerrarChangePassword');


      if(currentPassValue.length <= 0 || newPass1Value.length <= 0 || newPass2Value.length <= 0 ) { //Comprobar que campos no estén vacíos ninguno de los 3 campos
        errorCampoVacio.style.display = 'contents' //mostramos el error de que hay algún campo vacío
      } else { //Si no están vacíos...
          if(newPass1Value == newPass2Value) { //Si nueva pass 1 y nueva pass 2 iguales
            //Creamos objeto password para pasarle a la función de cambiar contraseña  
            let password = { 
                new1: newPass1Value,
                new2: newPass2Value,
                old: currentPassValue,
              }
              try {
                setLoadingAnimation(true)
                let result = await updateUserPassword(props.userId,password)
                setLoadingAnimation(false)
                if(result === 'error') /*contraseña antigua no coincide*/ {
                  errorPassActualNoCoincide.style.display = 'contents' //mostramos error
                } else {
                  //Si todo bien...
                  cancelarChangePassword.style.display = 'none' //oculto botón cancelar
                  guardarChangePassword.style.display = 'none' //oculto botón guardar
                  passChangeCorrect.style.display = 'contents'  //muestro msj ok
                  cerrarChangePassword.style.display = 'flex' //muestro botón cerrar
                }
              } catch(error) {
                console.log('ERROR USER-EDIT-PASSWORD: updateUserPassword:')
              }
          } else { //Si nuevapass1 nuevapass2 no coinciden
            errorNuevaPassNoCoinciden.style.display = 'contents'
          }
      }
    }

  return (
    <React.Fragment>
      <Modal
        id="userEditPasswordModal"
        //size="sm"
        show={show}
        onHide={hiddeEditUserPasswordModalFn}
        dialogClassName="bg-white"
        aria-labelledby="example-custom-modal-styling-title"
        //centered
        // backdrop="static"
        // keyboard={false}
      >
        <Modal.Header className="justify-content-center modal-border">
          <Modal.Title id="example-custom-modal-styling-title" className="dark-blue d-column">
            <b className="my-2 text-center l-fs-16 bold">
              Cambiar contraseña</b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="">
        <Row className="d-flex flex-column">
            <Col className="d-flex justify-content-center">
              <Row className="d-flex flex-column">
                 <Col>
                    <p className="light-blue-modal l-fs-14 mb-2 generaltextInputStyle"> Contraseña actual: 
                      <input ref={currentPassword} type="password" name="currentPassword" id="currentPassword" onFocus={hideErrors}/>
                      </p>
                </Col>
                <Col>
                    <p className="light-blue-modal l-fs-14 mb-2 generaltextInputStyle"> Nueva contraseña: 
                      <input ref={newPassword1} type="password" name="newPassword1" id="newPassword1" onFocus={hideErrors}/>
                      </p>
                </Col>
                <Col>
                    <p className="light-blue-modal l-fs-14 mb-2 generaltextInputStyle"> Repetir nueva contraseña: 
                      <input ref={newPassword2} type="password" name="newPassword2" id="newPassword2" onFocus={hideErrors}/>
                      </p>
                </Col>
              </Row>
            </Col>
            <Col>
            <br></br>
              {/*DIV que mostrarán el error que ocurra */}
              <div className="changePasswordMessages">
                <p className="campoVacio" id="campoVacio"> Por favor rellene todos los campos. </p>
                <p className="nuevaPassNoCoinciden" id="nuevaPassNoCoinciden"> Los campos de la nueva contraseña no coinciden </p>
                <p className="passActualNoCoincide" id="passActualNoCoincide"> La contraseña escrita no coincide con la actual </p>
                <p className="passChangeCorrect" id="passChangeCorrect"> Contraseña actualizada correctamente </p>
                {loadingAnimation && <LoadingAnimationMiniSize></LoadingAnimationMiniSize>}
              </div>
            </Col>

            <div className="border-bottom-modal pb-4"></div>
            <Col>
                
                <div className="d-center row mt-4">
                    <div className="d-inline-flex">
                        <div onClick={hiddeEditUserPasswordModalFn} className="d-center boton-cancelar mr-3" id="cancelarChangePassword">Cancelar</div>
                        <div onClick={changePassword} className="d-center boton-guardar" id="guardarChangePassword">Guardar</div>
                        <div onClick={hiddeEditUserPasswordModalFn} className="d-center boton-cancelar mr-3" id="cerrarChangePassword">Cerrar</div>
                    </div>
                </div>
            </Col>
        </Row>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
}