// --------------------------------------------
// Created by Pablo Collado 05/02/2021
// --------------------------------------------

import React, { useState, useEffect } from "react";
import { Row } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import Col from "react-bootstrap/Col";

// EXTERNAL LIBRARIES

//IMAGES
import icDownload from "../../assets/img/ic-download-orange.png";
//COMPONENTS
import LoadingAnimationMiniSize from "../LoadingAnimationMiniSize";
import UpSalesMessage from "../../componentes/UpSalesMessage";
//BACKEND FUNCTIONS
import { x as UpSales } from "../../api_communication/bundle/config";
import {
  getPdf,
  listSalesForModal,
} from "../../api_communication/service/ClientService";

// FROM PARENT:  <SalesDataModal clientId={this.state.client.id} actualYear={this.state.actualYear}></SalesDataModal>
export default function SalesDataModal(props) {
  const [show, setShow] = useState(false);
  const [actualYear, setActualYear] = useState(null);
  const [documents, setDocuments] = useState([]);
  const [pagination, setPagination] = useState(0);
  const [showMoreDataBtn, setShowMoreDataBtn] = useState(true);
  const [loadingAnimation, setLoadingAnimation] = useState(false);
  const [downloadingPdf, setDownloadingPdf] = useState(null);
  const [docIdToShowWaitingIcon, docIdToShowWaitingIconToShowWaitingIcon] =
    useState(null);
  const [showToast, setShowToast] = useState(false);

  useEffect(() => {
    async function getData() {
      let year = props.actualYear;
      setActualYear(year);
      setDocuments([]);
      setPagination(0);
      setShowMoreDataBtn(true);
      try {
        let initialDate = "01/01/" + year;
        let finalDate = "31/12/" + year;
        let result = await listSalesForModal(
          props.clientId,
          pagination,
          15,
          initialDate,
          finalDate
        );
        //console.log("SalesDataModal: result: ", result);
        let nextResult = await listSalesForModal(
          props.clientId,
          pagination + 1,
          15,
          initialDate,
          finalDate
        );
        if (nextResult.length === 0) {
          setShowMoreDataBtn(false);
        }
        //console.log('SALES-DATA-MODAL: result: ', result)
        setDocuments(result);
      } catch (e) {
        console.log(e);
      }
    }

    getData();
  }, [props]);

  //RECIBE EL DOCUMENTO PARA AÑADIR EN LA FILA props.document
  const DocumentRow = (props) => {
    let amount = props.document.amount;
    if (props.document.saleType === "payment") amount = -amount;
    return (
      <React.Fragment>
        <Row key={props.document.key} className="justify-content-center">
          <Col md={11} xs={8}>
            <Row>
              <Col className="light-grey l-fs-14 mayus my-2" md={2}>
                <b>{props.document.number}</b>
              </Col>
              <Col className="light-grey l-fs-14 mayus my-2" md={2}>
                <b>{props.document.documentIssueDate}</b>
              </Col>
              <Col className="light-grey l-fs-14 mayus my-2" md={2}>
                <b>{props.document.paymentMethod}</b>
              </Col>
              <Col className="light-grey l-fs-14 mayus my-2" md={2}>
                <b>{props.document.paymentTerm}</b>
              </Col>
              <Col className="light-grey l-fs-14 mayus my-2" md={2}>
                <b>{props.document.dueDate}</b>
              </Col>
              <Col className="light-grey l-fs-14 mayus my-2" md={2}>
                <b>{round(amount)}</b>
              </Col>
            </Row>
          </Col>
          <Col
            className="d-flex justify-content-center align-items-center"
            md={1}
            xs={4}
          >
            <Row>
              <Col className="light-grey " md={12}>
                {downloadingPdf &&
                docIdToShowWaitingIcon === props.document.id ? (
                  <div>
                    <LoadingAnimationMiniSize />
                  </div>
                ) : (
                  <div
                    onClick={() =>
                      saveFile(
                        props.clientId,
                        props.document.id,
                        props.document.number
                      )
                    }
                    title="Descarga PDF"
                  >
                    <img
                      style={{ width: 20, height: 20, cursor: "pointer" }}
                      src={icDownload}
                    />
                  </div>
                )}
              </Col>
            </Row>
          </Col>

          <Col className="brd-b-blue" md={12}></Col>
        </Row>
      </React.Fragment>
    );
  };

  const round = (num) => {
    let twoDecimals = parseFloat(num.toFixed(2));
    let result = twoDecimals.toLocaleString("es-ES", {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    });
    return result;
  };

  // Download a file from a url.
  const saveFile = async (clientId, docId, fileName) => {
    let token = "Bearer " + UpSales.Config.getStore().getJwtToken();
    docIdToShowWaitingIconToShowWaitingIcon(docId);
    setDownloadingPdf(true);
    try {
      let pdf = await getPdf(clientId, docId);
      let urlFile = pdf.url;
      console.log("saveFile: urlFile >>> ", urlFile);
      let response = await fetch(urlFile, {
        method: "GET",
        headers: {
          Authorization: token,
          // "Content-Type": "application/pdf",
          // Accept: "application/pdf",
        },
      });
      console.log(response);
      if (response.status !== 200) {
        //console.log("Error: response status = ", response.status);
        setShowToast(true);
        setDownloadingPdf(false);
        docIdToShowWaitingIconToShowWaitingIcon(null);
        throw new Error("Request Failed");
      }
      let obj = await response.blob();
      //console.log("obj: ", obj);
      // Create blob link to download
      const url = window.URL.createObjectURL(new Blob([obj]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        props.companyName + "- Doc " + fileName + ".pdf"
      );

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      link.parentNode.removeChild(link);
      setDownloadingPdf(false);
      docIdToShowWaitingIconToShowWaitingIcon(null);
    } catch (error) {
      console.log("SAVE FILE ERROR: ", error);
    }
  };

  const cancelForm = () => {
    setPagination(0);
    setShow(false);
  };

  const fillData = () => {
    let rows = [];
    documents.length !== 0 &&
      documents.forEach((element) => {
        rows.push(
          <DocumentRow
            document={element}
            clientId={props.clientId}
            key={element.id}
          />
        );
      });
    return rows;
  };

  const more = async () => {
    try {
      setLoadingAnimation(true);
      let initialDate = "01/01/" + actualYear;
      let finalDate = "31/12/" + actualYear;
      let result = await listSalesForModal(
        props.clientId,
        pagination + 1,
        15,
        initialDate,
        finalDate
      );
      let nextResult = await listSalesForModal(
        props.clientId,
        pagination + 2,
        15,
        initialDate,
        finalDate
      );
      setPagination(pagination + 1);
      if (nextResult.length === 0) {
        setShowMoreDataBtn(false);
      }
      //console.log('SALES-DATA-MODAL: MORE result: ', result)
      setLoadingAnimation(false);
      setDocuments([...documents, ...result]);
      fillData();
    } catch (e) {
      console.log(e);
    }
  };

  const closeMessage = () => {
    setShowToast(false);
  };

  return (
    <React.Fragment>
      <div
        className="mouse"
        style={{ width: "100%", height: "100%", position: "absolute" }}
        title="Ver Ventas"
        onClick={() => setShow(true)}
      ></div>
      <UpSalesMessage
        level={"danger"}
        message={"Error al descargar pdf!"}
        visible={showToast}
        autoClose={false}
        closeMessage={closeMessage}
      />

      <Modal
        style={{ overflow: "hidden" }}
        id="showDocuments"
        className="modal-border"
        show={show}
        onHide={() => setShow(false)}
        dialogClassName="bg-white"
        aria-labelledby="example-custom-modal-styling-title"
        //   centered
        //   backdrop="static"
        //   keyboard={false}
      >
        <Modal.Header className="justify-content-center modal-border modal-border  pos-r">
          <div
            className=""
            style={{ position: "absolute", top: 25, right: 35 }}
          >
            <div
              style={{ fontWeight: "bold", cursor: "pointer", fontSize: 21 }}
              onClick={cancelForm}
            >
              X
            </div>
          </div>
          <Modal.Title
            id="example-custom-modal-styling-title"
            className="dark-blue d-column"
          >
            <b className="mt-4 text-center">VENTAS</b>
            {/* <Media><img src={imgBigOportunity}></img></Media> */}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          className="modal-border container"
          style={{ maxHeight: "calc(100vh - 200px)", overflowY: "auto" }}
        >
          <Row className="justify-content-center">
            <Col xs={11}>
              <Row className="justify-content-center">
                <Col md={11} xs={8}>
                  <Row>
                    <Col className="light-grey l-fs-14 mayus my-2" md={2}>
                      <b>Nº Documento</b>
                    </Col>
                    <Col className="light-grey l-fs-14 mayus my-2" md={2}>
                      <b>Fecha emisión</b>
                    </Col>
                    <Col className="light-grey l-fs-14 mayus my-2" md={2}>
                      <b>Cód. forma pago</b>
                    </Col>
                    <Col className="light-grey l-fs-14 mayus my-2" md={2}>
                      <b>Cód. términos pago</b>
                    </Col>
                    <Col className="light-grey l-fs-14 mayus my-2" md={2}>
                      <b>Fecha vencimiento</b>
                    </Col>
                    <Col className="light-grey l-fs-14 mayus my-2" md={2}>
                      <b>Importe </b>
                    </Col>
                  </Row>
                </Col>
                <Col md={1} xs={4}>
                  <Row>
                    <Col className="light-grey l-fs-14 mayus my-2" md={12}>
                      <b>PDF</b>
                    </Col>
                  </Row>
                </Col>

                <Col className="brd-b-blue" md={12}></Col>
              </Row>

              {documents && documents.length !== 0 ? (
                fillData()
              ) : (
                <div className="d-center my-5">
                  No hay datos de ventas sobre este cliente
                </div>
              )}
            </Col>
          </Row>
          {/* <div id="scrollToBottom" className="row px-4" style={{overflowY:'auto', maxHeight: 'calc(100vh - 240px)', display:'block', scrollBehavior:'smooth'}}>
                {(tasksList && tasksList.length !== 0) ? showTasks(tasksList, refreshFn, msg) : <div className="d-center mt-5">No hay tareas archivadas</div>}
              </div>
              { showPlusBtn && <div className="d-center mt-3">
                <div onClick={()=>moreTasks()}>
                  <img src={btnPlus} style={{cursor:'pointer'}}></img>
                </div>
              </div> } */}
          {loadingAnimation && showMoreDataBtn ? (
            <div className="pt-3">
              <LoadingAnimationMiniSize />
            </div>
          ) : (
            ""
          )}
          {documents && documents.length !== 0 && (
            <div className="d-center mb-5 mt-3">
              {showMoreDataBtn && (
                <div className="d-center boton-cancelar" onClick={more}>
                  Ver más
                </div>
              )}
              {!showMoreDataBtn && (
                <div className="d-center">No hay más datos para mostrar</div>
              )}
            </div>
          )}
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
}
