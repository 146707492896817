import React, { useEffect, useState } from "react";
// PROPS:
// optionsArray={} - array of values for show in options 
// searchTerm={} - returns user writing search word
// onChange={handleChange} - Funcition to return user search word
// resetSearch={true or false} - prop: if true reset input value
// inputId={} - string - input id
// inputPlaceholder={} - string 'Buscar por X', 'Nombre de empresa', etc...

export function DynamicSelectOnlyDropdown(props) {

  const [userValue, setUserValue] = useState('')

  useEffect(() =>{
    if (props.resetSearch) setUserValue('')
  },[props])

  const userSelectAnOption = (e)=>{
    let div = document.getElementById(("#optionsValues" + props.inputId))
    var userValue = div.value;
    var id = div.options[div.selectedIndex].id;
    //console.log('userSelectAnOption: e: ', userValue)
    //console.log('userSelectAnOption: id: ', id)
    setUserValue(userValue)
    props.onChange(id)
  }

    return (
        <React.Fragment>
          {props.optionsArray.length !==0  && props.inputId &&
          <select  id={"#optionsValues" + props.inputId} value={userValue}  onChange={userSelectAnOption} className=" inputDynamicSelectOnlyDropdown l-fs-14" aria-label="Default select" style={{backgroundColor: 'white'}}>

            <option key={'allOptionsSelected' + props.inputId} id={'allOptionsSelected'+ props.inputId} data-id={'allOptionsSelected'} value={'TODOS'}>TODOS</option>

            {props.optionsArray.map((item) => (<option 
                key={item.id} 
                id={item.id} 
                value={item.alias ? item.alias.toUpperCase() : item.name.toUpperCase()}
                data-id={item.id}
            >{item.alias ? item.alias.toUpperCase() : item.name.toUpperCase()}</option>))}
           
          </select>}
          
        </React.Fragment>
      )
}