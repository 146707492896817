import {signinRepository, signoutRepository} from  "../repository/AuthRepository"

export var signin = async (username, password) => {
    try{
        var result = await signinRepository(username, password)
        return result;
    } catch (error){
        // console.log('ERROR AUTH-SERVICE: signin: ', error)
    }
}

export var signout = async () => {
    try{
        var result = await signoutRepository()
        return result;
    } catch (error){
        // console.log('ERROR AUTH-SERVICE: signout: ', error)
    }
}