import React, { useState, useEffect } from "react";
import "../assets/css/App.css";
import "../assets/css/proyectos.css";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { Link } from "react-router-dom";

// COMPONENTES
import LateralMenu from "../componentes/LateralMenu";
import SearchBar from "../componentes/SearchBar";
import ListadoProyectos from "../componentes/proyectos/ListadoProyectos";
import CrearProyecto from "../componentes/proyectos/CrearProyecto";
import UpSalesMessage from "../componentes/UpSalesMessage";
import LoadingAnimation from "../componentes/LoadingAnimation";
import LoadingAnimationMiniSize from "../componentes/LoadingAnimationMiniSize";
import ProjectStateIcon from "../componentes/proyectos/ProjectStateIcon";

//FUNCION CERRAR MENU
import { esconderMenuLateral } from "../assets/js/menuLateral";

//IMAGENES
import defaultLogo from "../assets/img/logoPorDefecto.png";

// FN PROYECTOS
import {
  loadTopClientes,
  loadCirclesData,
  loadUltimosProyectos,
  loadChartData,
  retailCompanyName,
  capitalizeString,
  changeStatusNameToSpanishInArray,
  loadProjectsListByFilter,
  translateStatusNameToEnglish,
} from "../componentes/proyectos/funcionesProyectos";
// EXTERNAL LIBRARIES
import PieChart from "../componentes/proyectos/PieChart";


export default function ProyectosDashboard(props) {
  const [showProjectsListModal, setShowProjectsListModal] = useState(false);
  const [showCreateProjectModal, setShowCreateProjectModal] = useState(false);

  const [projectsListData, setProjectsListData] = useState([]);
  const [clientData, setClientData] = useState(null);
  const [fabricated, setFabricated] = useState(null)

  const [clientChartData, setClientChartData] = useState(null);
  const [typologyChartData, setTypologyChartData] = useState(null);
  const [statesChartData, setStatesChartData] = useState(null);
  const [fabricatedChartData, setFabricatedChartData] = useState(null)

  const [circlesData, setCirclesData] = useState(null);
  const [listadoUltimosProyectos, setListadoUltimosProyectos] = useState([]);
  const [listadoTopClientes, setListadoTopClientes] = useState([]);

  const [loadingTopClientes, setLoadingTopClientes] = useState(true);
  const [loadingUltimosClientes, setLoadingUltimosClientes] = useState(true);

  const [projectState, setProjectState] = useState(null);
  const [typology, setTypology] = useState(null);

  const [filtersFromProjectDetail, setFiltersFromProjectDetail] = useState(null)

  const [msgLevel, setMsgLevel] = useState(null);
  const [msgMessage, setMsgMessage] = useState(null);
  const [msgShowUM, setMsgShowUM] = useState(null);
  const [msgClose, setMsgClose] = useState(null);

  const MAX_ELEMENTS_IN_CHART = 8;

  const defaultColours = [
    "rgba(169, 191, 48, 1)",
    "rgba(250, 196, 66, 1)",
    "rgba(165, 138, 190, 1)",
    "rgba(252, 128, 171, 1)",
    "rgba(252, 93, 148, 1)",
    "rgba(73, 150, 234, 1)",
    "rgba(244, 144, 28, 1)",
    "rgba(74, 101, 101, 1)",
    "rgba(26, 71, 108, 1)",
  ];

  const chartTempData1 = {
    labels: [],
    datasets: [
      {
        label: "# of Votes",
        data: [],
        backgroundColor: defaultColours,
        borderWidth: 1,
      },
    ],
  };

  const chartTempData2 = {
    labels: [],
    datasets: [
      {
        data: [],
        backgroundColor: defaultColours,
        borderWidth: 1,
      },
    ],
  };

  const chartTempData3 = {
    labels: [],
    datasets: [
      {
        data: [],
        backgroundColor: defaultColours,
        borderWidth: 1,
      },
    ],
  };

  const chartTempData4 = {
    labels: [],
    datasets: [
      {
        data: [],
        backgroundColor: defaultColours,
        borderWidth: 1,
      },
    ],
  };

  useEffect(() => {
    document.title = "Proyectos dashboard | UpSales";
    loadData();
  }, []);

  useEffect(() => {
    if (props.location.projectsFilters != null ||props.location.projectsFilters !== undefined) {
      setFiltersFromProjectDetail(props.location.projectsFilters)
      setShowProjectsListModal(true)
    }
  }, [props.location.projectsFilters]);

  async function loadData() {
    let error = false;
    try {
      let circles = await loadCirclesData();
      if (circles.error) {
        error = true;
      } else {
        setCirclesData(circles);
      }

      let result = await loadUltimosProyectos();
      if (result.error) {
        error = true;
      } else {
        setLoadingUltimosClientes(false);
        setListadoUltimosProyectos(result);
      }

      let result2 = await loadTopClientes();
      if (result2.error) {
        error = true;
      } else {
        setLoadingTopClientes(false);
        setListadoTopClientes(result2);
      }

      const [chart1, chart2, chart3, chart4] = await Promise.all([
        loadChartData("companies"),
        loadChartData("typologies"),
        loadChartData("states"),
        loadChartData("fabricated")
      ]);
      if (chart1 && chart2 && chart3 && chart4) {

        // CHART 1
        chartTempData1.labels = capitalizeString(
          retailCompanyName(chart1.newNames.slice(0, MAX_ELEMENTS_IN_CHART))
        );
        chartTempData1.datasets[0].data = chart1.newValues.slice(0,MAX_ELEMENTS_IN_CHART
        );
        setClientChartData(chartTempData1);

        // CHART 2
        chartTempData2.labels = capitalizeString(
          chart2.newNames.slice(0, MAX_ELEMENTS_IN_CHART)
        );
        chartTempData2.datasets[0].data = chart2.newValues.slice(0,MAX_ELEMENTS_IN_CHART
        );
        setTypologyChartData(chartTempData2);

        // CHART 3
        chartTempData3.labels = changeStatusNameToSpanishInArray(
          chart3.newNames.slice(0, MAX_ELEMENTS_IN_CHART)
        );
        chartTempData3.datasets[0].data = chart3.newValues.slice(0,MAX_ELEMENTS_IN_CHART
        );
        setStatesChartData(chartTempData3);

        // CHART 4
               
        chartTempData4.labels = chart4.newNames
        chartTempData4.datasets[0].data = chart4.newValues
        setFabricatedChartData(chartTempData4)
      } else {
        error = true;
      }

      if (error) {
        showUpSalesMessage(
          "danger",
          "Ha ocurrido un error cargando los datos!",
          true,
          false
        );
      }
    } catch (error) {
      showUpSalesMessage(
        "danger",
        "Ha ocurrido un error cargando los datos!",
        true,
        false
      );
      console.log(error);
    }
  }

  const showUpSalesMessage = (level, message, show, close) => {
    setMsgLevel(level);
    setMsgMessage(message);
    setMsgShowUM(show);
    setMsgClose(close);
  };

  const closeMsg = () => {
    setMsgLevel(null);
    setMsgMessage(null);
    setMsgShowUM(false);
    setMsgClose(null);
  };

  // LISTS

  const closeProjectsListModal = () => {
    setFiltersFromProjectDetail(null)
    setFabricated(null)
    setProjectsListData([]);
    setShowProjectsListModal(false);
    setProjectState(null);
    setClientData(null);
  };

  const openProjectByState = async (state, translate = false) => {
    try {
      let translatedState = state;
      if (translate)
        translatedState = translateStatusNameToEnglish(state);
      let result = await loadProjectsListByFilter(undefined, undefined, translatedState, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined);
      //console.log('openProjectByState: result: ', result)
      if (!result) {
        showUpSalesMessage(
          "danger",
          "Ha ocurrido un error cargando los datos!",
          true,
          false
        );
      } else {
        setProjectsListData(result);
        setProjectState(translatedState);
        setShowProjectsListModal(true);
        setClientData(null);
        setTypology(null);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const openProjectByFabricated = async (value = null) => {
    let fabricated
    fabricated = value
    if (value){
      if (value === 'Si' || value === 'Sí') {
        fabricated = 'true'
      } else {
        if (value === 'No'){
          fabricated = 'false'
        } else {
          if (value === 'MUESTRAS' || value === 'Muestras'){
            fabricated = 'MUESTRAS'
          }
        }
      }
    }
    setFabricated(fabricated)
    try {
      let result = await loadProjectsListByFilter(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, fabricated);
      //console.log('openProjectByFabricated: result: ', result)
      if (!result) {
        showUpSalesMessage(
          "danger",
          "Ha ocurrido un error cargando los datos!",
          true,
          false
        );
      } else {
        setProjectsListData(result);
        setShowProjectsListModal(true);
        setClientData(null);
        setTypology(null);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const openProjectListByClientName = async (companyName) => {
    const topClientes = await loadTopClientes(10);
    topClientes.forEach((client) => {
      if (client.alias.toUpperCase().startsWith(companyName.toUpperCase()))
        openClientProjectList(client);
    });
  };

  const openClientProjectList = async (clientData) => {
    setClientData(clientData);
    try {
      let result = await loadProjectsListByFilter(0, clientData.id,"ACTIVE");
      if (!result) {
        showUpSalesMessage(
          "danger",
          "Ha ocurrido un error cargando los datos!",
          true,
          false
        );
      } else {
        setProjectsListData(result);
        setProjectState("ACTIVE");
        setShowProjectsListModal(true);
        setTypology(null);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const openTypologyProjectList = async (typology) => {
    try {
      let result = await loadProjectsListByFilter(0,undefined,"ACTIVE",undefined,undefined,undefined,undefined,undefined,undefined,typology
      );
      if (!result) {
        showUpSalesMessage(
          "danger",
          "Ha ocurrido un error cargando los datos!",
          true,
          false
        );
      } else {
        setProjectsListData(result);
        setProjectState("ACTIVE");
        setClientData(null);
        setTypology(typology);
        setShowProjectsListModal(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

   const openCreateProject = () => {
    setShowCreateProjectModal(true);
  };

  const closeCreateProjectModal = () => {
    setShowCreateProjectModal(false);
  };

  const refreshWithNewData = () => {
    loadData();
  };

  const ShowDataInDashboardTableRow = (props) => {
    if (props.element === "latest") {
      return (
        <React.Fragment>
          <Link
            key={props.data.id}
            style={{ textDecoration: "none" }}
            className="mouse linksTables"
            to={{ pathname: "/DetalleProyecto", state: props.data }}
            title="Ver detalles de este proyecto"
          >
            <div className="l-fs-14 ml-2 d-flex flex-row align-items-center">
              <div>
                <img
                  width={30}
                  height={30}
                  src={props.data.company.thumbnail}
                />
              </div>
              <div>
                {props.data.state && (
                  <ProjectStateIcon
                    classNames="ml-2 mb-1"
                    projectState={props.data.state}
                  />
                )}
              </div>
              <div className="pl-3">
                <p className="color-link">{props.data.company.alias}</p>
                <p>{props.data.name}</p>
              </div>
            </div>
            <div className="grey-underline mt-1"></div>
          </Link>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <div
            onClick={() => openClientProjectList(props.data)}
            key={props.data.id}
            style={{ textDecoration: "none" }}
            className="linksTables"
            title="Ver proyectos de esta empresa"
          >
            <div className={"mouse"}>
              <img
                className=""
                width={30}
                height={30}
                src={props.data.thumbnail ? props.data.thumbnail : defaultLogo}
                alt={props.data.alias}
              />
              <span className="l-fs-14 ml-2">{props.data.alias}</span>
              <div className="grey-underline"></div>
            </div>
          </div>
        </React.Fragment>
      );
    }
  };

  const showTopClients = () => {
    let rows = [];
    listadoTopClientes.length !== 0 &&
      listadoTopClientes.forEach((element) => {
        rows.push(
          <ShowDataInDashboardTableRow
            element="top"
            data={element}
            key={element.id}
          />
        );
      });
    return rows;
  };

  const showUltimosProyectos = () => {
    let rows = [];
    listadoUltimosProyectos.length !== 0 &&
      listadoUltimosProyectos.forEach((element) => {
        rows.push(
          <ShowDataInDashboardTableRow
            element="latest"
            data={element}
            key={element.id}
          />
        );
      });
    return rows;
  };

  return (
    <Container className="pos-r" fluid={true}>
      {msgLevel && msgMessage && msgShowUM !== null && msgClose !== null && (
        <UpSalesMessage
          level={msgLevel}
          message={msgMessage}
          visible={msgShowUM}
          autoClose={msgClose}
          closeMessage={closeMsg}
        />
      )}

      <CrearProyecto
        show={showCreateProjectModal}
        close={closeCreateProjectModal}
        refresh={refreshWithNewData}
        showUpSalesMessageFn={showUpSalesMessage}
      ></CrearProyecto>

      {showProjectsListModal && (
        <ListadoProyectos
          show={showProjectsListModal}
          close={closeProjectsListModal}
          data={projectsListData}
          projectState={projectState}
          typology={typology}
          error={showUpSalesMessage}
          client={clientData}
          fabricated={fabricated}
          filtersFromProjectDetail={filtersFromProjectDetail}
        ></ListadoProyectos>
      )}

      {/*clientProjectsData && clientData && <ListadoProyectosEmpresa
        show={showClientProjectsListModal}
        close={closeClientProjectList}
        data={clientProjectsData}
        client={clientData}
      ></ListadoProyectosEmpresa>*/}

      <div
        id="ic-menu-out"
        onClick={() => esconderMenuLateral()}
        className="bg-arrow d-center arrow-menu-out d-none orange-up"
      >
        <p>&gt;</p>
      </div>
      <Row>
        <LateralMenu></LateralMenu>

        <Col className="bg-blue-light">
          <Row className="nav-style brd-b-blue bg-white justify-content-center">
            <Col xs={11} className="align-self-center p-0">
              <SearchBar />
            </Col>
          </Row>

          <Row>
            <Col className="bg-blue-light pt-4">
              <span className="light-grey pl-3">Proyectos</span>
            </Col>
          </Row>

          <Row>
            <Col className="pb-4 d-flex justify-content-end px-5 col">
              <div
                onClick={openCreateProject}
                title="Crear un nuevo proyecto"
                className="modal-big-button d-center btn btn-primary"
              >
                Crear proyecto
              </div>
            </Col>
          </Row>

          <Row className="bg-blue-light pl-3">
            <Col className="col-12 col-md-6 mt-3 bg-white">
              <div className="light-grey pb-5">
                <p className="text-center l-fs-18 py-5 dark-blue">
                  <b>INDICADORES</b>
                </p>
                <Row className="d-flex justify-content-center align-items-start px-5">
                    <Col id="total-proyectos" className="p-3 d-column indicator-wrapper col-4" onClick={()=>openProjectByState(undefined)} title="Ver Total Proyectos">
                      <div className="indicator-button">{circlesData ? circlesData.total : <LoadingAnimationMiniSize/>}</div>
                      <p className="l-fs-14 black text-center pt-3">TOTAL PROYECTOS</p>
                    </Col>

                    <Col id="fase-start" className="p-3 d-column indicator-wrapper col-4" onClick={()=>openProjectByState('STARTING')} title="Ver Comenzados">
                      <div className="indicator-button">{circlesData ? circlesData.started : <LoadingAnimationMiniSize/>}</div>
                      <p className="l-fs-14 black text-center pt-3">COMENZADO</p>
                    </Col>

                    <Col id="en-desarrollo" className="p-3 d-column indicator-wrapper col-4" onClick={()=>openProjectByState('IN_PROGRESS')} title="Ver en Desarrollo">
                      <div className="indicator-button">{circlesData ? circlesData.inProgress : <LoadingAnimationMiniSize/>}</div>
                      <p className="l-fs-14 black text-center pt-3">EN DESARROLLO</p>
                    </Col>

                    <Col id="avanzado"className="p-3 d-column indicator-wrapper col-4" onClick={()=>openProjectByState('ADVANCED')} title="Ver en Terminado">
                      <div className="indicator-button">{circlesData ? circlesData.advanced : <LoadingAnimationMiniSize/>}</div>
                      <p className="l-fs-14 black text-center pt-3">TERMINADO</p>
                    </Col>

                    <Col id="fin-exito"className="p-3 d-column indicator-wrapper col-4" onClick={()=>openProjectByState('FINISHED')} title="Ver Finalizado con Éxito">
                      <div className="indicator-button">{circlesData ? circlesData.finished : <LoadingAnimationMiniSize/>}</div>
                      <p className="l-fs-14 black text-center pt-3">ÉXITO</p>
                    </Col>

                    <Col id="fin-archivado" className="p-3 d-column indicator-wrapper col-4" onClick={()=>openProjectByState('ARCHIVED')} title="Ver Finalizado Archivado">
                      <div className="indicator-button">{circlesData ? circlesData.archived : <LoadingAnimationMiniSize/>}</div>
                      <p className="l-fs-14 black text-center pt-3">ANULADO</p>
                    </Col>
                    <Col id="fin-archivado" className="p-3 d-column indicator-wrapper col-4" onClick={()=>openProjectByFabricated('true')} title="Ver Fabricados">
                      <div className="indicator-button">{circlesData ? circlesData.fabricated : <LoadingAnimationMiniSize/>}</div>
                      <p className="l-fs-14 black text-center pt-3">FABRICADOS</p>
                    </Col>
                    <Col id="fin-archivado" className="p-3 d-column indicator-wrapper col-4" onClick={()=>openProjectByFabricated('MUESTRAS')} title="Ver con Muestras">
                      <div className="indicator-button">{circlesData ? circlesData.muestras : <LoadingAnimationMiniSize/>}</div>
                      <p className="l-fs-14 black text-center pt-3">MUESTRAS</p>
                    </Col>
                </Row>
              </div>
            </Col>
            <Col className="col-12 col-md-6  mt-3">
              <div className="bg-white light-grey py-5">
                <Row>
                  <Col className="col-12">
                    <div className="d-center">
                      <div className="container-90">
                        <p className="text-center l-fs-16 pb-5 dark-blue">
                          <b>TOP CLIENTES PROYECTOS</b>
                        </p>
                        {loadingTopClientes && (
                          <LoadingAnimation text={"Cargando datos..."} />
                        )}
                        {listadoTopClientes.length !== 0 &&
                        !loadingTopClientes ? (
                          showTopClients()
                        ) : (
                          <div></div>
                        )}
                      </div>
                    </div>
                  </Col>
                  <Col className="col-12 mt-5">
                    <div className="bg-white d-center">
                      <div className="container-90">
                        <p className="text-center l-fs-16 pb-5 dark-blue">
                          <b>ÚLTIMOS PROYECTOS CREADOS</b>
                        </p>

                        {loadingUltimosClientes && (
                          <LoadingAnimation text={"Cargando datos..."} />
                        )}
                        {listadoUltimosProyectos.length !== 0 &&
                        !loadingUltimosClientes ? (
                          showUltimosProyectos()
                        ) : (
                          <div></div>
                        )}
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
          <Row className="bg-blue-light  mt-3">
            <Col className="col-12">
              <div className="bg-white py-5">
                <p className="text-center l-fs-18 dark-blue">
                  <b>GRÁFICOS DESTACADOS</b>
                </p>
              </div>
              <div className="pb-5 bg-white ">
                <Row className="">
                  <Col className="col-12 col-md-6 col-xl-3 px-5">
                    <div className="bg-white light-grey">
                      <PieChart
                        data={clientChartData}
                        title="TOP CLIENTES"
                        id="pie-chart-clients"
                        listProjects={openProjectListByClientName}
                      ></PieChart>
                    </div>
                  </Col>
                  <Col className="col-12 col-md-6 col-xl-3 px-5 ">
                    <div className="bg-white light-grey">
                      <PieChart
                        data={typologyChartData}
                        title="ESTILO"
                        id="pie-chart-style"
                        listProjects={openTypologyProjectList}
                      ></PieChart>
                    </div>
                  </Col>
                  <Col className="col-12 col-md-6 col-xl-3 px-5">
                    <div className="bg-white light-grey">
                      <PieChart
                        data={statesChartData}
                        title="SITUACIÓN"
                        id="pie-chart-state"
                        listProjects={openProjectByState}
                      ></PieChart>
                    </div>
                  </Col>
                  <Col className="col-12 col-md-6 col-xl-3 px-5">
                    <div className="bg-white light-grey">
                      <PieChart
                        data={fabricatedChartData}
                        title="FABRICADOS"
                        id="pie-chart-fabricated"
                        listProjects={openProjectByFabricated}
                      ></PieChart>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}
