import React from "react";
import "../assets/css/App.css";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import LateralMenu from "../componentes/LateralMenu";
import NavBarClients from "../componentes/NavBarClients";
import ClientInformation from "../componentes/detallesClientes/ClientInformation";
import ClientInteractions from "../componentes/detallesClientes/ClientInteractions";
import { NavLink } from "react-router-dom";
import OportunidadDetallesClientes from "../componentes/detallesClientes/OportunidadDetallesClientes";
import UpSalesMessage from "../componentes/UpSalesMessage";
import { getCompany } from "../api_communication/service/ClientService";

//FUNCION CERRAR MENU
import { esconderMenuLateral } from "../assets/js/menuLateral";
import LoadingAnimation from "../componentes/LoadingAnimation";
import TaskTabList from "../componentes/task/TaskTabList";

class DetallesClientes extends React.Component {
  componentDidMount() {
    document.title = "Detalles clientes | UpSales";
  }

  constructor(props) {
    super(props);
    this.state = {
      filterText: "",
      clientInfo: null,
      loadingAnimation: true,
      toastLevel: null,
      toastMessage: null,
      showToast: false,
      autoClose: false,
      refreshNavBarClientsState:false
    };

    this.handleFilterTextChange = this.handleFilterTextChange.bind(this);
  }

  componentDidMount() {
    this.refreshAllClientDetails();
  }

  handleFilterTextChange(filterText) {
    this.setState({
      filterText: filterText,
    });
  }

  refreshAllClientDetails = async () => {
    this.setState({ loadingAnimation: true });
    try {
      let result = await getCompany(this.props.location.state.id);
      this.setState({
        clientInfo: result,
        loadingAnimation: false,
        refreshNavBarClientsState: !this.state.refreshNavBarClientsState
      });
    } catch (error) {
      console.log(error);
    }
  };

  showUpSalesMessage = (level, message, show, close) => {
    this.setState({
      toastLevel: level,
      toastMessage: message,
      showToast: show,
      autoClose: close,
    });
  };

  closeMessage = () => {
    this.setState({
      toastLevel: null,
      toastMessage: null,
      showToast: false,
      autoClose: false,
    });
  };

  refreshNavBarClients = ()=>{
    this.setState({
      refreshNavBarClientsState: !this.state.refreshNavBarClientsState
    })
  }

  render() {
    if (this.state.loadingAnimation == false)
      return (
        <Container className="pos-r" fluid={true}>
          <UpSalesMessage
            level={this.state.toastLevel}
            message={this.state.toastMessage}
            visible={this.state.showToast}
            autoClose={this.state.autoClose}
            closeMessage={this.closeMessage}
          />
          <div
            id="ic-menu-out"
            onClick={() => esconderMenuLateral()}
            className="bg-arrow d-center arrow-menu-out d-none orange-up"
          >
            <p>&gt;</p>
          </div>
          <Row>
            <LateralMenu></LateralMenu>

            <div id="" className="col min-width-0">
              <Row className="nav-style brd-b-blue justify-content-center">
                <Col xs={11} className="align-self-center p-0">
                  <NavBarClients
                    showUpSalesMessageFn={this.showUpSalesMessage}
                    filterText={this.state.filterText}
                    onFilterTextChange={this.handleFilterTextChange}
                    client={this.state.clientInfo}
                    refresh={this.state.refreshNavBarClientsState}
                  />
                </Col>
              </Row>

              <Row className="justify-content-center bg-blue-light px-3">
                <Col xs={12} className="pt-4 pb-3 px-0 d-flex flex-row">
                  {/* <Link className="light-grey" to={"/Oportunidad"}>Oportunidades</Link> */}
                  <NavLink className="light-grey" to="/Clientes">
                    Clientes&nbsp;
                  </NavLink>{" "}
                  /<p className="light-grey">&nbsp;Detalles Cliente</p>
                </Col>
              </Row>

              <Row className="bg-blue-light h-100 justify-content-center px-3">
                <Col xs={12}>
                  {this.props.location.state && (
                    <ClientInformation
                      showUpSalesMessageFn={this.showUpSalesMessage}
                      client={this.props.location.state}
                      refreshNavBarClients={this.refreshNavBarClients}
                    />
                  )}
                  {this.props.location.state && (
                    <Row className="justify-content-center">
                      <Col xs={12}>
                        <ClientInteractions
                          showUpSalesMessageFn={this.showUpSalesMessage}
                          client={this.props.location.state}
                          refreshFn={() => this.refreshAllClientDetails()}
                        />
                      </Col>
                    </Row>
                  )}
                  {this.props.location.state && this.state.interaction && (
                    <Row className="justify-content-center"></Row>
                  )}
                  <Row className="bg-blue-light">
                    <Col xs={12} md={4} className="pr-4">
                      {this.props.location.state && (
                        <OportunidadDetallesClientes
                          showUpSalesMessageFn={this.showUpSalesMessage}
                          client={this.props.location.state}
                          refreshFn={() => this.refreshAllClientDetails()}
                        />
                      )}
                    </Col>

                    <Col xs={12} md={8} className="pl-4">
                      {this.props.location.state && (
                        <TaskTabList
                          companyId={this.props.location.state.id}
                          entity={{
                            type: "client",
                            id: this.props.location.state.id,
                          }}
                          client={this.props.location.state}
                        ></TaskTabList>
                      )}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </Row>
        </Container>
      );
    else return <LoadingAnimation text={"Cargando datos..."} />;
  }
}

export default DetallesClientes;
