import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import LoadingAnimation from '../LoadingAnimation'
import LoadingAnimationMiniSize from '../LoadingAnimationMiniSize' 

import Accordion from 'react-bootstrap/Accordion';

import ClientLogo from '../ClientLogo';

// Fn backend
import {truncateText} from '../CommonScripts'

import { listCompaniesByOpportunityState } from '../../api_communication/service/ClientService'
import {
    countOverdueTasksForOpenOpportunities,
    countOverdueTasksForOpenOpportunitiesForOneCompany,
    listOpenOpportunitiesForClient} from '../../api_communication/service/OpportunityService'

import {OpportunityIconState} from '../CommonScripts'

import {Link} from 'react-router-dom'

//Imagenes
import ImgOportunidad from '../../assets/img/ic-oportunidad-big.png';
import btnMas from '../../assets/img/btn-mas.png'

import moment from 'moment'

// receive props:
// data={item} key={index} data = some opportunity
class OpportunityRow extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            data:[],
            image: null
        }
    }

    render(){
        return(
            <Link className="color-link row w-100 py-2" to={{pathname:'/DetallesOportunidad',state: this.props.data}}>
                    <Col className="d-flex justify-content-center align-items-start" xs={4}>
                        <div>
                            <OpportunityIconState opportunity={this.props.data}/>
                        </div>
                        
                    </Col>
                    <Col xs={8} className="justify-self-start align-self-center">
                        <p title={this.props.data.name} className="text-start light-grey">{truncateText(this.props.data.name, 40)}</p>
                    </Col>
            </Link>
        )
    }
}

// receive props:
// data={this.state.opportunityData}
class OpportunityRows extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            ready: false,
            data: []
          };
    }

    componentDidMount(){
        // console.log('componentDidMount: this.props.data: ', this.props.data)
        if (this.props.data) {
            this.setState({data: this.props.data, ready: true})
        } else {
            //console.log('componentDidMount: !this.props.data: ', this.props.data)
        }
        
    }

    componentDidUpdate(prevProps){
        if (this.props.data != prevProps.data){
            this.setState({data: this.props.data, ready: true})
            }
        }

    render(){
        let show = this.props.data
        //console.log('DASHBOARD: OPPORTUNITY: OpportunitRows: show: ', show)
        if (this.state.ready) {
            return(
                <React.Fragment>
                    {show.map(function(item, index){
                        return (<OpportunityRow data={item} key={index}/>)
                    })}
                </React.Fragment>
            )
            
        } else return <LoadingAnimationMiniSize text={'Cargando oportunidades...'}/>
    }
}


// receive props:
// client={client}
// indice={client.id}
// key={client.id}
class CompanyRow extends React.Component{ 
    constructor(props) {
        super(props);
        this.state = {
            numberDueDateInteractions: 0,
            opportunityData: null,
            isOpenTheRow: false,
            loadingAnimation: false
          };
    }
    async componentDidMount() {
        try{
            let currentDate = moment().format("DD/MM/YYYY HH:mm:ss")
            
            let result = await countOverdueTasksForOpenOpportunitiesForOneCompany(this.props.indice, currentDate)
            this.setState({numberDueDateInteractions: result.count})
        } catch(error){
            console.log(error)
        }
    }

    loadOpportunities = async (clientId)=> {
        if (this.state.isOpenTheRow == false) {
            this.setState({
                loadingAnimation: true
            })
            try{
                var result = await listOpenOpportunitiesForClient(clientId, 0, 10000)
                this.setState({opportunityData: result, isOpenTheRow: true, loadingAnimation: false})
            }catch(error){
                console.log(error)
            }
        } else {
            this.setState({isOpenTheRow: false, loadingAnimation: false})
        }
    }

    render(){
        const client = this.props.client;

        return(
        <React.Fragment>
            <Accordion.Toggle as={Col} xs={12} eventKey={this.props.indice} className="pt-2 accordion-row justify-content-center align-items-center" onClick={()=>this.loadOpportunities(this.props.indice)}>
                <Row className="accordion-row-hover justify-content-center">
                    <Col xs={5} lg={4} className="d-center"><ClientLogo client={client} indicator={this.state.numberDueDateInteractions}/></Col>
                    <Col xs={10} lg={8} className="align-self-center">
                        <b className="text-center dark-blue">{client.alias}</b>
                    </Col>
                    <Col md={10} className="brd-b-blue py-1"></Col>
                </Row>
            </Accordion.Toggle>
            <Accordion.Collapse as={Col} eventKey={this.props.indice} className="col-12">
                {!this.state.loadingAnimation ? <Row className="row justify-content-center" >
                    {this.state.opportunityData && <OpportunityRows data={this.state.opportunityData} />}
                    <Col className="brd-b-blue py-1" md={10}></Col>
                </Row> : <LoadingAnimation />}
            </Accordion.Collapse>
        </React.Fragment>
        
        )
    }
}

class OportunityTable extends React.Component { // cada línea es un cliente

    componentDidUpdate(prevProps){
        if (this.props.clients != prevProps.clients)
        this.scrollToBottom()
    }

    scrollToBottom = ()=>{
        var objDiv = document.getElementById("accordion-scroll");
        objDiv.scrollTop = objDiv.scrollHeight;
   }
    render(){
        const rows = [];
        //console.log('this.props.clients: ', this.props.clients)
        this.props.clients != null && this.props.clients.forEach((client) => {
            rows.push(<CompanyRow 
                client={client}
                indice={client.id}
                key={client.id}
                />
            );
        })
        
        return(
            <Row >
                <Col xs={12} className="bg-white pt-3 justify-content-center">
                    <Row className="align-items-center justify-content-center">
                        <Col className="d-center" md={4}>
                            <div className="pos-r">
                                <img src={ImgOportunidad}></img>
                                {this.props.totalOpportunities !=0 && 
                                    <div style={{position: 'absolute', top:0, right:10,}} className="indicator-styles">
                                        {this.props.totalOpportunities}
                                    </div>
                                }
                            </div>
                            
                        </Col>
                        <Col className="dark-blue" md={8}>
                            <p className="mb-0 l-fs-18"><b>OPORTUNIDADES</b></p>
                        </Col>
                        <Col className="brd-b-blue py-1" md={10}></Col>
                    </Row>
                </Col>
                <Col xs={12} id="accordion-scroll" className="accordion-scroll" style={{scrollBehavior:'smooth'}}>
                    <Accordion as={Row}>
                        {rows}
                    </Accordion>
                </Col>
            </Row>
        );
    }
}

// Padre. Al cargarse llama a backend y trae lista clientes con oportunidades y las pasa a hijo
export default class OportunidadDashboard extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            listaClientes: [], // array con info q se pasa como prop a <OportunityTable>
            loadingAnimation: true, // if true se muestra animacion de carga al cargar página entera
            paginationCount: 0, // contador para llamar a backend (info paginada)
            loadingMoreCompanies: false, //if true muestra pequeño loading animation en lugar del btn +
            showBtnMoreCompanies: false, // si de backend no hay mas datos se oculta btn + 
            noMoreDataAvailable: false // if true muestra mensajes al usuario de que no hay mas info de backend por mostrar
          };
      }

    async componentDidMount() {
        let currentDate = moment().format("DD/MM/YYYY HH:mm:ss")
        try{
            let num = await countOverdueTasksForOpenOpportunities(currentDate)
            //console.log('NUM: ', num.count)
            //debugger
            this.setState({totalOpportunities: num.count,})
            this.cargaInicialListaClientes(this.state.paginationCount)
        }catch(error){
            console.log(error)
        }
        
    }

    cargaInicialListaClientes = async (paginationCount)=> {
        try{
            let listadoPaginado = await listCompaniesByOpportunityState('open', paginationCount, 10)
            let listadoPaginadoSiguiente = await listCompaniesByOpportunityState('open', paginationCount + 1, 10)
            if ((!listadoPaginado || (listadoPaginado && listadoPaginado.length == 0)) || (!listadoPaginadoSiguiente || (listadoPaginadoSiguiente && listadoPaginadoSiguiente.length == 0))) {
                this.setState({showBtnMoreCompanies: false, noMoreDataAvailable: true}) // quitamos btn + y mostramos mensajes
            } else {
                this.setState({showBtnMoreCompanies: true})
            }
            this.setState({ 
                listaClientes: listadoPaginado ? listadoPaginado : [],
                loadingAnimation: false,
            })
            
        } catch(error) {
            console.log(error)
        }
        //console.log('OPORTUNIDAD-DASHBOARD.JS: this.state.listaClientes: ', this.state.listaClientes)
    }

    

    cargaListaClientes = async (paginationCount)=> {
        let finalResult = []
        try{
            let listadoPaginado = await listCompaniesByOpportunityState('open', paginationCount, 10)
            let listadoPaginadoSiguiente = await listCompaniesByOpportunityState('open', paginationCount + 1, 10)

            let listadoInicial=this.state.listaClientes // siempre tendrá algo cuando entramos en esta función

            
            if (listadoPaginado || listadoPaginado.length !==0) {
                finalResult = listadoInicial.concat(listadoPaginado) // 'sumamos' lista anterior con la nueva de backend
                if (listadoPaginadoSiguiente === null) {
                    this.setState({showBtnMoreCompanies: false, noMoreDataAvailable: true})
                }
            } 

            if (!listadoPaginado || listadoPaginado.length == 0) {
                finalResult = listadoInicial;
                this.setState({showBtnMoreCompanies: false, noMoreDataAvailable: true})
            }
            
            if (!listadoPaginadoSiguiente || listadoPaginadoSiguiente.length == 0) {
                this.setState({showBtnMoreCompanies: false, noMoreDataAvailable: true})
            }

           // console.log('finalResult >>>>>>>>>>>>>>> ', finalResult)
            this.setState({
                listaClientes: finalResult, 
                loadingAnimation: false,
                loadingMoreCompanies:false,
            })
            
        } catch(error) {
            console.log(error)
        }
        //console.log('OPORTUNIDAD-DASHBOARD.JS: this.state.listaClientes: ', this.state.listaClientes)
    }

    moreCompanies = (e)=>{
        let newPaginationCount = this.state.paginationCount + 1
        e.preventDefault();
        this.setState({paginationCount: newPaginationCount, loadingMoreCompanies: true})
        this.cargaListaClientes(newPaginationCount)
    }

    render(){
        if (this.state.loadingAnimation == false)
            return(
                <Row id="div" style={{backgroundCcolor:'white'}}>
                    <Col>
                        <OportunityTable clients={this.state.listaClientes} totalOpportunities={this.state.totalOpportunities} />
                        {this.state.showBtnMoreCompanies && 
                        <Row>
                            <Col className="btn-mas-container flex-column">
                                {this.state.loadingMoreCompanies && <LoadingAnimationMiniSize></LoadingAnimationMiniSize> }
                                {!this.state.loadingMoreCompanies && <a href="#" onClick={this.moreCompanies}>
                                    <img src={btnMas}></img>
                                </a>}
                                
                            </Col>
                        </Row>}
                        {this.state.noMoreDataAvailable && this.state.paginationCount >0 && 
                        <Row>
                            <Col className="col-12 bg-white d-center py-3">
                                <p className="light-grey">No hay más datos por mostrar.</p>
                            </Col>    
                        </Row>}
                        {this.state.listaClientes.length ===0 && 
                        <Row>
                            <Col className="col-12 bg-white d-center py-3">
                                <p className="light-grey">No información para mostrar.</p>
                            </Col>
                        </Row>}
                    </Col>
                </Row>
            )
        else return <LoadingAnimation text={'Cargando datos...'}/>
    }
}

