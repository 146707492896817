import { UpSales } from "../bundle/bundle";

export const getATaskRepository = async (taskId) => {
  try {
    var response = await UpSales.Task.getTask(taskId);
    return response;
  } catch (error) {
    console.log(error);
    throw Error(error);
  }
};

// UpSales.Task.closeTask(interactionId, body)
export var closeTaskRepository = async (companyId, interactionId, body) => {
  try {
    let result = await UpSales.Task.closeTask(companyId, interactionId, body);
    return result;
  } catch (error) {
    console.log(error);
    throw Error(error);
  }
};

export var deleteTaskRepository = async (companyId, taskId) => {
  try {
    let result = await UpSales.Task.deleteTask(companyId, taskId);
    return result;
  } catch (error) {
    console.log(error);
    throw Error(error);
  }
};

export var addDueDateTaskRepository = async (companyId, taskId, body) => {
  try {
    let result = await UpSales.Task.postponeDueDate(companyId, taskId, body);
    return result;
  } catch (error) {
    console.log(error);
    throw Error(error);
  }
};

// CREATE TASK MODAL.JS - UpSales.Task.createTask(body)
export const createTaskRepository = async (body) => {
  try {
    let result = await UpSales.Task.createTask(body);
    return result;
  } catch (error) {
    console.log("REPO: ERROR > CREATE TASK:", error);
    throw Error(error);
  }
};

// UpSales.Task.updateTask(taskid, body)
export const modifyTaskRepository = async (taskId, body) => {
  try {
    let result = await UpSales.Task.updateTask(taskId, body);
    return result;
  } catch (error) {
    console.log("REPO: ERROR modifyTaskRepository", error);
    throw Error(error);
  }
};

// UpSales.Task.uploadDocuments(companyId, taskId, files)
export const addDocsToTaskRepository = async (companyId, taskId, files) => {
  try {
    let result = await UpSales.Task.uploadDocuments(companyId, taskId, files);
    return result;
  } catch (error) {
    console.log("REPO: ERROR > addDocsToTaskRepository:", error);
    throw Error(error);
  }
};

// UpSales.Task.archiveTask
export const archiveTaskRepo = async (companyId, taskId) => {
  try {
    let result = await UpSales.Task.archiveTask(companyId, taskId);
    return result;
  } catch (error) {
    console.log(error);
    throw Error(error);
  }
};