import React, {createContext} from 'react';
import { configBundle } from '../api_communication/bundle/config';
const DataContext = createContext({});
export const ContextProvider = ({children}) => {
    // Global Variables
    let bundle = configBundle()
    
    return (
      <DataContext.Provider
        value={{
          bundle,
        }}>
        {children}
      </DataContext.Provider>
    );
  };