import moment from 'moment';
import { archiveTaskRepo, deleteTaskRepository, closeTaskRepository, addDueDateTaskRepository } 
  from '../repository/TaskRepository';

export var deleteTask = async (companyId, taskId) => {
  try {
    let result = await deleteTaskRepository(companyId, taskId);
    return result;
  } catch (error) {
    console.log(error);
  }
};

export var closeTask = async (companyId, taskId, body) => {
  try {
    let result = await closeTaskRepository(companyId, taskId, body);
    return result;
  } catch (error) {
    console.log(error);
  }
};

export const isTaskOverdue = (date) => {
  let interactionDate = moment(date, "DD/MM/YYYY HH:mm:ss")
  let currentDate = moment()

  return currentDate.isAfter(interactionDate)
}

export const isTaskResolved = (task) => {
  return task.scheduling.state === 'closed'
}

export const isTaskArchived = (task) => {
  return task.state === 'ARCHIVED'
}

export const archiveTask = async (task) => {
  try {

    if (task.state !== 'ACTIVE')
      throw Error("Task is not active")

    let result = await archiveTaskRepo(task.company.id, task.id);
    return result;
  } catch (e) {
    console.log(e);
  }
};

export const restoreTask = async (task) => {
  try {

    if (task.state !== 'ARCHIVED')
      throw Error("Task is not archived")

    let result = await archiveTaskRepo(task.company.id, task.id);
    return result;
  } catch (e) {
    console.log(e);
  }
};

export var addDueDateTask = async (companyId, taskId, body) => {
  try {
    var result = await addDueDateTaskRepository(companyId, taskId, body);
    return result;
  } catch (error) {
    console.log(error);
  }
};
