import React, { useEffect, useState } from "react";
import "../assets/css/App.css";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import LateralMenu from "../componentes/LateralMenu";
import SearchBar from "../componentes/SearchBar";
import TaskTabList from "../componentes/task/TaskTabList";
import OportunidadDashboard from "../componentes/dashboard/OportunidadDashboard";
import DashboardProjectsList from "../componentes/proyectos/DashboardProjectsList";
import LastInteractions from "../componentes/dashboard/LastInteractions";

//FUNCION CERRAR MENU
import { esconderMenuLateral } from "../assets/js/menuLateral";
import { getCurrentUserData } from "../api_communication/service/UsersService";

//IMAGENES

function App() {

  const [loggedUser,setLoggedUser] = useState(null)
  const [userIsProjectManager, setUserIsProjectManager] = useState(null)
  const [userIsManager, setUserIsManager] = useState(null)

  const PROJECTS_MODULE_ENABLED = process.env.REACT_APP_PROJECTS_MODULE_ENABLED === 'true'

  useEffect(() => {
    loadCurrentUser()
    document.title = "Dashboard | UpSales";
    return () => {};
  }, []);

  const loadCurrentUser = async () => {
    try {
      let loggedUser = await getCurrentUserData()
      //console.log('Dashboard: loggedUser: ', loggedUser)
      if (loggedUser.roles.includes('PROJECT_MANAGER')) {
        setUserIsProjectManager(true)
      } else {
        setUserIsProjectManager(false)
      }
      if (loggedUser.roles.includes('MANAGER')) {
        setUserIsManager(true)
      } else {
        setUserIsManager(false)
      }
      setLoggedUser(loggedUser)
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Container className="pos-r" fluid={true}>
      <div
        id="ic-menu-out"
        onClick={() => esconderMenuLateral()}
        className="bg-arrow d-center arrow-menu-out d-none orange-up"
      >
        <p>&gt;</p>
      </div>
      <Row>
        <LateralMenu loggedUser={loggedUser}></LateralMenu>

        <Col>
          <Row className="nav-style brd-b-blue justify-content-center">
            <Col xs={11} className="align-self-center p-0">
              <SearchBar />
            </Col>
          </Row>

          <Row className="justify-content-center bg-blue-light">
            <Col xs={12} className="pt-4 pb-3">
              <p className="light-grey">Escritorio</p>
            </Col>
          </Row>

          <Row className="bg-blue-light justify-content-center pr-3">
            <Col xs={12}>
              <Row className="pb-5">
                <Col md={8}>
                  <TaskTabList loggedUser={loggedUser} entity={{ type: "all" }} />{" "}
                  {/* Tabs tareas para hoy / tareas vencidas - eliminado clients={CLIENTES}*/}
                  <LastInteractions loggedUser={loggedUser ? loggedUser : null}/>
                </Col>
                <Col md={4}>
                  {(loggedUser && userIsProjectManager === false) && <div className="mb-3"><OportunidadDashboard /></div>}
                  {(PROJECTS_MODULE_ENABLED && loggedUser && (userIsProjectManager === true || userIsManager === true)) && <DashboardProjectsList />} 
                  
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}

export default App;
