import React, { useEffect, useState } from "react";

// PROPS: setValue={Function} - To return countryId selected by user to parent

// COMPONENTES
import { DynamicSelect } from "./DynamicSelect";

// SERVICE
import { findAllClientsService } from "../../api_communication/service/ClientService";

// *****************************************************************
// PROPS ClientDynamicSelect  
//  setValue={function handleCountry} - in parent to receive country choosen by user in this component
// *****************************************************************
// PROPS DynamicSelect:
// optionsArray={} - array of values for show in options 
// searchTerm={} - returns user writing search word ->¿?
// onChange={handleChange} - Funcition to return user search word
// resetSearch={true or false} - prop: if true reset input value
// inputId={} - string - input id
// inputPlaceholder={} - string 'Buscar por X', 'Nombre de empresa', etc...
// ******************************************************************

export function ClientDynamicSelect(props) {

    const [array, setArray] = useState([])
    const [userInput, setUserInput] = useState(null)

    useEffect(() => {
        //console.log("ClientDynamicSelect, props:: ", props)
        loadClients(userInput)
    },[userInput])

    const handleUserInput = async (input, userSelectId) => {
        try {
            if (userSelectId === null) {
                
                if (input && input.length > 2)
                    setUserInput(input)
                else {
                    setUserInput(null)
                    props.setValue(null)
                }

            } else {
                props.setValue(userSelectId)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const loadClients = async (query) =>{
        try {
            let result = await findAllClientsService(0, 100, query)
            setArray(result)
        } catch (error) {
            console.log(error)  
        }
    }

    return (
        <React.Fragment>
          <DynamicSelect 
            optionsArray={array} 
            onChange={handleUserInput} 
            resetSearch={null} 
            inputId={'clientFilter'} 
            inputPlaceholder={'Cliente'}/>
        </React.Fragment>
      )
}