import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import defaultLogo from "../../assets/img/logoPorDefecto.png";
import redStarFavorite from "../../assets/img/ic-favorito.png";
import greyStarFavorite from "../../assets/img/ic-star-off.png";
import icPinAzul from "../../assets/img/ic-pin-azul.png";
import icPinGris from "../../assets/img/ic-pin-gris.png";
import changeLogoIcon from "../../assets/img/ic-editar-azul.png";
import DataClientPanel from "./DataClientPanel";
import LoadingAnimationMiniSize from "../LoadingAnimationMiniSize";
import UpSalesMessage from "../UpSalesMessage";

import { getFinance } from "../../api_communication/repository/ClientRepository";
import {
  findClientById,
  getCompany,
  updateCompanyLogo,
  logoCompany,
  modifyCompany,
  markUnmarkCompanyAsFavorite,
  pinUnpinCompany,
  computeTotalSalesByYear,
  computeTotalSalesForecastsByYear,
  createStaffMember,
  getListStaff,
  saveEditedStaffMember,
  setCompanyTags,
  getCompanyTags,
  deleteStaffMember,
  getOtherDirections,
  addOtherDirection,
  saveEditedOtherDirection,
  deleteOtherDirection,
  modifyFinance,
  getListPaymentMethods,
  createPaymentMethod,
} from "../../api_communication/service/ClientService";
import { Form, Modal } from "react-bootstrap";
import VentasVsPresupuestoChart from "../VentasVsPresupuestoChart";
import VentasActualVsAnteriorChart from "../VentasActualVsAnteriorChart";
import FinancialDataModal from "./FinancialDataModal";
import SalesDataModal from "./SalesDataModal";
import {
  clientDetailAcquisitionTranslations,
  clientDetailStatusTranslations,
  clientDetailTipologyTranslations,
  ListYearsToChose,
  ListYearsToChoseTab2,
} from "../CommonScripts";
import {
  getSaldoVivo,
  getSaldoVencido,
} from "../../api_communication/service/ClientService";
import moment from "moment";
import NavbarCollapse from "react-bootstrap/NavbarCollapse";
import lapizEditar from "../../assets/img/ic-editar-azul.png";
import basuraBorrar from "../../assets/img/ic-basura-roja.png";
import { getCurrentUserData } from "../../api_communication/service/UsersService";

class ClientLogo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      clientLogo: null,
      loadingAnimationFinished: false,
    };
    this.changeLogoFn = props.logoChangedMsg;
  }

  async componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {
    try {
      let clientLogo = await findClientById(this.props.client);
      this.setState({
        clientLogo: clientLogo.thumbnail ? clientLogo.thumbnail : defaultLogo,
        loadingAnimationFinished: true,
      });
    } catch (error) {
      console.log(error);
    }
  };

  callToSaveImage = async (e) => {
    // console.log('CAMBIAR LOGO: e.target.value: ', e.target.value)
    // console.log('CAMBIAR LOGO: e.target.files: ', e.target.files[0])
    const image = e.target.files[0];
    var imageData = new FormData();
    imageData.append("file", image);
    //console.log('CAMBIAR LOGO: formData: imageData: ', imageData, this.props.client)
    if (this.state.clientLogo == defaultLogo) {
      try {
        this.setState({ loadingAnimationFinished: false });
        await logoCompany(this.props.client, imageData);

        let clientLogo = await findClientById(this.props.client);
        //console.log('CAMBIAR LOGO: formData: updateCompanyLogo: clientLogo: ', clientLogo.thumbnail)
        this.setState({
          clientLogo: clientLogo.thumbnail,
          loadingAnimationFinished: true,
        });
        this.changeLogoFn();
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        this.setState({ loadingAnimationFinished: false });
        await updateCompanyLogo(this.props.client, imageData);

        let clientLogo = await findClientById(this.props.client);
        //console.log('CAMBIAR LOGO: updateCompanyLogo: clientLogo: ', clientLogo.thumbnail)
        this.setState({
          clientLogo: clientLogo.thumbnail,
          loadingAnimationFinished: true,
        });
        this.changeLogoFn();
      } catch (error) {
        console.log(error);
      }
    }
  };

  render() {
    //console.log('CAMBIAR LOGO: render > this.state.clientLogo: ', this.state.clientLogo)
    if (this.state.loadingAnimationFinished) {
      return (
        <div className="pos-r company-logo-styles">
          <img
            title="Logo"
            style={{ width: 70, height: 70, borderRadius: 50 }}
            src={this.state.clientLogo + "&" + new Date().getTime()}
          ></img>

          <div>
            <label htmlFor="file-input" className="changeLogoHover">
              <img
                title="Cambiar logo"
                className="edit-icon"
                src={changeLogoIcon}
              />
            </label>
            <input
              encType="multipart/form-data"
              id="file-input"
              type="file"
              multiple={false}
              accept="image/*"
              onChange={this.callToSaveImage}
              style={{ visibility: "hidden", width: 0, height: 0 }}
            />
          </div>
        </div>
      );
    } else {
      return <LoadingAnimationMiniSize />;
    }
  }
}
const ShowStaffMember = (props) => { 
  return (
    <div
      style={{
        marginBottom: "5px",
        marginLeft: "10px",
        padding: "5px",
        border: "1px solid #C7E6F9",
        width: "300px",
        position: "relative",
      }}
    >
      <div
        style={{ position: "absolute", top: 5, right: 5, cursor: "pointer" }}
        onClick={() => props.editFn(props.staffMember)}
      >
        <img title="Editar contacto" src={lapizEditar} width={20} />
      </div>

      {props.user &&
        (props.user.roles.includes("SALES_DIRECTOR") ||
          props.user.roles.includes("MANAGER")) && (
          <div
            style={{
              position: "absolute",
              bottom: 5,
              right: 5,
              cursor: "pointer",
            }}
            onClick={() => props.deleteStaffMember(props.staffMember)}
          >
            {props.loadingAnimation && (props.staffMember.id === props.idStaffMemberToDelete) && (
              <LoadingAnimationMiniSize color={"text-light"} />
            )}
            <img title="Borrar contacto" src={basuraBorrar} width={15} />
          </div>
        )}

      <p>
        <span style={{ color: "#898989" }}>Nombre: </span>
        {props.staffMember.name ? (
          props.staffMember.name
        ) : (
          <span className="color-link">Sin nombre</span>
        )}
      </p>

      <p>
        <span style={{ color: "#898989" }}>Apellidos: </span>
        {props.staffMember.surname ? (
          props.staffMember.surname
        ) : (
          <span className="color-link">Sin apellido</span>
        )}
      </p>

      <p>
        <span style={{ color: "#898989" }}>Teléfono: </span>
        {(props.staffMember.contacts&&
          props.staffMember.contacts.find((contact) => contact.type === "phone") &&
            props.staffMember.contacts.find((contact) => contact.type === "phone").value) || 
              <span className="color-link">Sin teléfono</span>}
      </p>

      <p>
        <span style={{ color: "#898989" }}>E-mail: </span>
        {(props.staffMember.contacts &&
          props.staffMember.contacts.find((contact) => contact.type === "Email") &&
            props.staffMember.contacts.find((contact) => contact.type === "Email").value) || 
              <span className="color-link">Sin e-mail</span>}
      </p>

      <p>
        <span style={{ color: "#898989" }}>Descripción: </span>
        {props.staffMember.description ? (
          props.staffMember.description
        ) : (
          <span className="color-link">Sin descripción</span>
        )}
      </p>
    </div>
  );
};

const ShowOtherDirection = (props) => {
  /* console.log("showStaffMember: props: ", props); */
  return (
    <div
      style={{
        marginBottom: "5px",
        marginLeft: "10px",
        padding: "5px",
        border: "1px solid #C7E6F9",
        width: "300px",
        position: "relative",
      }}
    >
      <div
        style={{ position: "absolute", top: 5, right: 5, cursor: "pointer" }}
        onClick={() => props.editFn(props.direction)}
      >
        <img title="Editar dirección" src={lapizEditar} width={20} />
      </div>

      {props.user &&
        (props.user.roles.includes("SALES_DIRECTOR") ||
          props.user.roles.includes("MANAGER")) && (
          <div
            style={{
              position: "absolute",
              bottom: 5,
              right: 5,
              cursor: "pointer",
            }}
            onClick={() => props.deleteOtherDirection(props.direction)}
          >
            {props.loadingAnimation && (
              <LoadingAnimationMiniSize color={"text-light"} />
            )}
            <img title="Borrar dirección" src={basuraBorrar} width={15} />
          </div>
        )}

      <p>
        <span style={{ color: "#898989" }}>Tipo: </span>
        {props.direction.usage ? (
          props.direction.usage
        ) : (
          <span className="color-link">Sin tipo</span>
        )}
      </p>
      <p>
        <span style={{ color: "#898989" }}>Calle : </span>
        {props.direction.street ? (
          props.direction.street
        ) : (
          <span className="color-link">Sin Calle</span>
        )}
      </p>
      <p>
        <span style={{ color: "#898989" }}>Localidad: </span>
        {props.direction.locality ? (
          props.direction.locality
        ) : (
          <span className="color-link">Sin localidad</span>
        )}
      </p>
      <p>
        <span style={{ color: "#898989" }}>CP: </span>
        {props.direction.postalCode ? (
          props.direction.postalCode
        ) : (
          <span className="color-link">Sin código postal</span>
        )}
      </p>
      <p>
        <span style={{ color: "#898989" }}>Provincia: </span>
        {props.direction.province ? (
          props.direction.province
        ) : (
          <span className="color-link">Sin provincia</span>
        )}
      </p>

      <p>
        <span style={{ color: "#898989" }}>País: </span>
        {props.direction.country ? (
          props.direction.country
        ) : (
          <span className="color-link">Sin país</span>
        )}
      </p>
    </div>
  );
};

class ClientInformation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      showData: "economic",
      showForm: "",
      showIcons: "economic",
      client: null,
      financialData: {},
      toastLevel: null,
      toastMessage: null,
      showToast: false,
      autoClose: false,
      ventasPresupuestoYear: null,
      comparaVentasYear: null,
      actualYear: null,
      staffMemberContactPhoneId: null,
      staffMemberContactEmailId: null,
      staffMemberName: "",
      staffMembersurname: "",
      staffMemberPhone: "",
      staffMemberEmail: "",
      staffMemberDescription: "",
      loadingAnimation: false,
      staffMemberToEdit: null,
      staffList: null,
      showDeleteStaffModal: false,
      otherDirections: null,
      otherDirectionsUsage: "",
      otherDirectionsStreet: "",
      otherDirectionsLocality: "",
      otherDirectionsPostalCode: "",
      otherDirectionsProvince: "",
      otherDirectionsCountry: "",
      otherDirectionToEdit: null,
      economicRisk: "",
      showAddPaymentMethodModal: false,
      newPaymentMethodName: "",
      paymentMethodsLists: null,
      idStaffMemberToDelete: null,
    };
    this.refreshNavBarClients = props.refreshNavBarClients
  }

  async componentDidMount() {
    let date = new Date().getFullYear();
    /* console.log(">>>>>>>> DATE: ", date); */
    this.setState({
      ventasPresupuestoYear: date,
      comparaVentasYear: date,
      actualYear: date,
    });
    /*await this.fetchData();
    await this.getVentas(date);
    await this.getPresupuesto(date);
    await this.getStaff(this.props.client.id);
    await this.getOtherDirections(this.props.client.id);
    await this.getPaymentMethods(this.props.client.id);*/
    await Promise.all([this.fetchData(),this.getVentas(date),this.getPresupuesto(date),this.getStaff(this.props.client.id),this.getOtherDirections(this.props.client.id),this.getPaymentMethods(this.props.client.id)])
    let currentDate = moment().format("DD/MM/YYYY");
    /*let user = await getCurrentUserData();
    let saldoVivo = await getSaldoVivo(this.props.client.id);
    let saldoVencido = await getSaldoVencido(this.props.client.id, currentDate);*/
    const [user, saldoVivo, saldoVencido] = await Promise.all([getCurrentUserData(),getSaldoVivo(this.props.client.id),getSaldoVencido(this.props.client.id, currentDate)])
    /*     console.log(
      ">>>> saldoVivo: " + saldoVivo + " / saldoVencido: " + saldoVencido
    ); */
    this.setState({
      user: user,
      saldoVivo: saldoVivo,
      saldoVencido: saldoVencido,
    });
    document.getElementById(
      "uncontrolled-tab-example-tab-Ventas/AñoAnterior"
    ) &&
      document
        .getElementById("uncontrolled-tab-example-tab-Ventas/AñoAnterior")
        .addEventListener("click", () => this.getVentasAndPresupuesto());
    document.getElementById(
      "uncontrolled-tab-example-tab-Ventas/Presupuesto"
    ) &&
      document
        .getElementById("uncontrolled-tab-example-tab-Ventas/Presupuesto")
        .addEventListener("click", () => this.getVentasAndPresupuesto());
    // get saldo vivo y vencido set
  }

  fetchData = async () => {
    try {
      /*var result = await getCompany(this.props.client.id);
      var financialData = await getFinance(this.props.client.id); // ara només serveix per a obtindre el RISK (Pablo 16/08/21)
      var statusTag = await getCompanyTags(this.props.client.id, 1);
      var typologyTag = await getCompanyTags(this.props.client.id, 2);
      var acquisitionTag = await getCompanyTags(this.props.client.id, 3);*/
      const [result, financialData, statusTag,typologyTag, acquisitionTag] = await Promise.all([
        getCompany(this.props.client.id),getFinance(this.props.client.id),getCompanyTags(this.props.client.id, 1),
        getCompanyTags(this.props.client.id, 2),getCompanyTags(this.props.client.id, 3)
      ])
      this.setState({
        client: result,
        financialData: financialData,
        statusTagId:
          statusTag[0] && statusTag[0].id.toString()
            ? statusTag[0].id.toString()
            : "0",
        typologyTagId:
          typologyTag[0] && typologyTag[0].id.toString()
            ? typologyTag[0].id.toString()
            : "0",
        acquisitionTagId:
          acquisitionTag[0] && acquisitionTag[0].id.toString()
            ? acquisitionTag[0].id.toString()
            : "0",
      });
    } catch (error) {
      console.log(error);
    }
  };

  getStaff = async (clientId) => {
    try {
      let result = await getListStaff(clientId);
      this.setState({ staffList: result });
    } catch (error) {
      console.log(error);
    }
  };

  getPaymentMethods = async (clientId) => {
    try {
      let result = await getListPaymentMethods(clientId);
      this.setState({ paymentMethodsLists: result });
    } catch (error) {
      console.log(error);
    }
  };

  getOtherDirections = async (clientId) => {
    try {
      let result = await getOtherDirections(clientId);
      this.setState({ otherDirections: result });
    } catch (error) {
      console.log(error);
    }
  };

  getVentas = async (year) => {
    try {
      let result = await computeTotalSalesByYear(this.props.client.id, year);
      this.setState({ ventas: result.value });
    } catch (e) {
      console.log(e);
    }
  };

  getPresupuesto = async (year) => {
    try {
      let result = await computeTotalSalesForecastsByYear(
        this.props.client.id,
        year
      );
      this.setState({ presupuesto: result.value });
    } catch (e) {
      console.log(e);
    }
  };

  getVentasAndPresupuesto = async () => {
    try {
      this.setState({
        comparaVentasYear: this.state.actualYear,
        ventasPresupuestoYear: this.state.actualYear,
      });
      let result = await computeTotalSalesByYear(
        this.props.client.id,
        this.state.actualYear
      );
      this.setState({ ventas: result.value });
      result = await computeTotalSalesForecastsByYear(
        this.props.client.id,
        this.state.actualYear
      );
      this.setState({ presupuesto: result.value });
    } catch (e) {
      console.log(e);
    }
  };

  markUnmarkCompanyAsFavorite = async (companyId) => {
    try {
      let favorite = await markUnmarkCompanyAsFavorite(companyId);
      let result = await getCompany(this.props.client.id);
      this.setState({
        client: result,
      });
    } catch (error) {
      console.log(error);
    }
  };

  pinUnpinCompany = async (companyId) => {
    try {
      let favorite = await pinUnpinCompany(companyId);
      let result = await getCompany(this.props.client.id);
      this.setState({
        client: result,
      });
    } catch (error) {
      console.log(error);
    }
  };

  changeShowData = (estado) => {
    this.setState({
      showIcons: estado,
      showData: estado,
    });
  };

  // Muestra inputs editar: general, economico, fiscal y comerciales
  showForm = (estado) => {
    this.setState({
      showForm: estado,
    });
  };

  cancelForm = async () => {
    try {
      var result = await getCompany(this.props.client.id);
      var financialData = await getFinance(this.props.client.id);
      var statusTag = await getCompanyTags(this.props.client.id, 1);
      var typologyTag = await getCompanyTags(this.props.client.id, 2);
      var acquisitionTag = await getCompanyTags(this.props.client.id, 3);
    } catch (error) {
      console.log(error);
    }

    this.setState({
      client: result,
      financialData: financialData,
      staffMemberName: "",
      staffMembersurname: "",
      staffMemberEmail: "",
      staffMemberDescription: "",
      staffMemberPhone: "",
      staffMemberContactEmailId: null,
      staffMemberContactPhoneId: null,
      statusTagId:
        statusTag[0] && statusTag[0].id.toString()
          ? statusTag[0].id.toString()
          : "0",
      typologyTagId:
        typologyTag[0] && typologyTag[0].id.toString()
          ? typologyTag[0].id.toString()
          : "0",
      acquisitionTagId:
        acquisitionTag[0] && acquisitionTag[0].id.toString()
          ? acquisitionTag[0].id.toString()
          : "0",
      otherDirectionsUsage: "",
      otherDirectionsStreet: "",
      otherDirectionsLocality: "",
      otherDirectionsPostalCode: "",
      otherDirectionsProvince: "",
      otherDirectionsCountry: "",
      economicRisk: "",
    });
    // quita formulario
    this.showForm("");
  };

  // EDITING BASIC COMPANY DATA
  basicDataOnchangeAlias = (e) => {
    /*     console.log(
      "CLIENT-INFORMATION: basicDataOnchangeAlias: e.value: ",
      e.target.value
    ); */
    this.setState({
      client: { ...this.state.client, alias: e.target.value },
    });
    e.preventDefault();
  };
  basicDataOnchangeAddress = (e) => {
    //alert('Address de momento no está implementado. Preguntar Carlos por Fn backend')
    //console.log('CLIENT-INFORMATION: basicDataOnchangeAlias: e.value: ', e.target.value)
    // this.setState({
    //     client: {...this.state.client, address: e.target.value}
    // })
    // e.preventDefault();
  };
  basicDataOnchangePhone = (e) => {
    //console.log('CLIENT-INFORMATION: basicDataOnchangeAlias: e.value: ', e.target.value)
    this.setState({
      client: { ...this.state.client, phone: e.target.value },
    });
    e.preventDefault();
  };
  basicDataOnchangeEmail = (e) => {
    //console.log('CLIENT-INFORMATION: basicDataOnchangeAlias: e.value: ', e.target.value)
    this.setState({
      client: { ...this.state.client, email: e.target.value },
    });
    e.preventDefault();
  };
  basicDataOnchangeWeb = (e) => {
    //console.log('CLIENT-INFORMATION: basicDataOnchangeAlias: e.value: ', e.target.value)
    this.setState({
      client: { ...this.state.client, web: e.target.value },
    });
    e.preventDefault();
  };
  basicDataOnchangeObservations = (e) => {
    /*  console.log(
      "CLIENT-INFORMATION: basicDataOnchangeObservations: e.value: ",
      e.target.value
    ); */
    this.setState({
      client: { ...this.state.client, observations: e.target.value },
    });
    e.preventDefault();
  };

  basicDataOnchangeStatus = (e) => {
    /*  console.log(
      "CLIENT-INFORMATION: basicDataOnchangeStatus: e.value: ",
      e.target.value
    ); */
    this.setState({
      statusTagId: e.target.value,
    });
    e.preventDefault();
  };

  basicDataOnchangeTypology = (e) => {
     /*console.log(
      "CLIENT-INFORMATION: basicDataOnchangeTypology: e.value: ",
      e.target.value
    );*/ 
    this.setState({
      typologyTagId: e.target.value,
    });
    e.preventDefault();
  };

  basicDataOnchangeAcquisition = (e) => {
    /*console.log(
      "CLIENT-INFORMATION: basicDataOnchangeAcquisition: e.value: ",
      e.target.value
    );*/ 
    this.setState({
      acquisitionTagId: e.target.value,
    });
    e.preventDefault();
  };

  saveFormBasicData = async () => {
    //guardar datos introducidos en el formulario
    //let resultForm = document.getElementById('basicForm').children
    this.setState({ loadingAnimation: true });
    let body = {
      alias: this.state.client.alias, //resultForm[0].value
      address: this.state.client.address, //resultForm[1].value
      phone: this.state.client.phone, // resultForm[2].value
      email: this.state.client.email, //resultForm[3].value
      web: this.state.client.web, // resultForm[4].value
      observations: this.state.client.observations,
    };
    /* console.log("BODY -> ", body); */
    try {
      await modifyCompany(this.props.client.id, body);

      console.log("status TAG --> ", this.state.statusTagId);
      await setCompanyTags(this.props.client.id, 1, [
        { id: parseInt(this.state.statusTagId == '0' ? '3' : this.state.statusTagId) },
      ]);

      /* console.log("typology TAG --> ", typologyTagId); */
      await setCompanyTags(this.props.client.id, 2, 
        this.state.typologyTagId != 0 ? [{ id: parseInt(this.state.typologyTagId) }] : [],
      );

      /* console.log("acquisition TAG --> ", acquisitionTagId); */
      await setCompanyTags(this.props.client.id, 3, 
        this.state.acquisitionTagId != 0 ? [{ id: parseInt(this.state.acquisitionTagId) }] : [],
      );
      this.setState({ loadingAnimation: false });
      this.refreshNavBarClients()
      this.cancelForm();
    } catch (error) {
      console.log(error);
      this.setState({ loadingAnimation: false });
    }
  };

  saveFormNewStaffMember = async () => {
    let name = this.state.staffMemberName;
    let staffMemberPhone = this.state.staffMemberPhone;
    let staffMemberEmail = this.state.staffMemberEmail;

    if (
      name.trim() !== ""
    ) {
      this.setState({ loadingAnimation: true });
      let body = {
        name: this.state.staffMemberName,
        surname: this.state.staffMembersurname,
        contacts: [],
        //ESTO DE DESCRIPTION HABRÁ QUE CAMBIARLO CUANDO CARLOS HAGA EL BACK Y VEAMOS COMO SE LLAMA EL CAMPO
        description: this.state.staffMemberDescription,
      };

      if (staffMemberPhone){
        body.contacts.push({
          value: staffMemberPhone.trim(),
          type: "phone",
          label: "work",
        })
      }

      if (staffMemberEmail) {
        body.contacts.push({
          value: staffMemberEmail,
          type: "email",
          label: "work",
        })
      }
      try {
        await createStaffMember(this.state.client.id, body);
        this.getStaff(this.state.client.id);
        this.setState({ loadingAnimation: false });
        this.refreshNavBarClients()
        this.cancelForm();
        // LLAMAR LISTADO COMERCIALES
      } catch (error) {
        console.log(error);
      }
    } else {
      this.showUpSalesMessage(
        "warning",
        "Por favor, es obligatorio rellenar el campo 'Nombre'",
        true,
        true
      );
    }
  };

  // EDITING FINANCIAL COMPANY DATA
  // Ventas
  economicDataOnchangeSales = (e) => {
    //console.log('CLIENT-INFORMATION: economicDataOnchangeSales: e.value: ', e.target.value)
    this.setState({
      financialData: { ...this.state.client, sales: e.target.value },
    });
    e.preventDefault();
  };
  // previsión
  economicDataOnchangeForecast = (e) => {
    //console.log('CLIENT-INFORMATION: economicDataOnchangeForecast: e.value: ', e.target.value)
    this.setState({
      financialData: { ...this.state.client, forecast: e.target.value },
    });
    e.preventDefault();
  };
  // Riesgo
  economicDataOnchangeRisk = (e) => {
    //console.log('CLIENT-INFORMATION: economicDataOnchangeRisk: e.value: ', e.target.value)
    this.setState({
      financialData: { ...this.state.financialData, risk: e.target.value },
    });
    e.preventDefault();
  };

  economicDataOnchangePaymentMethod = (e) => {
    this.setState({
      financialData: {
        ...this.state.financialData,
        paymentMethod: { id: e.target.value !== "0" ? e.target.value : 0 },
      },
    });
    e.preventDefault();
  };

  saveEconomicaFormData = async () => {
    this.setState({ loadingAnimation: true });
    let body = {
      risk:
        this.state.financialData.risk !== ""
          ? this.state.financialData.risk
          : 0,
      paymentMethod: this.state.financialData.paymentMethod,
    };
    try {
      await modifyFinance(this.props.client.id, body);

      this.setState({ loadingAnimation: false });
      this.cancelForm();
    } catch (error) {
      console.log(error);
      this.setState({ loadingAnimation: false });
    }
  };

  fiscalDataOnchangeName = (e) => {
    /* console.log(
      "CLIENT-INFORMATION: fiscalDataOnchangeName: e.value: ",
      e.target.value
    ); */
    this.setState({
      client: { ...this.state.client, name: e.target.value },
    });
    e.preventDefault();
  };

  fiscalDataOnchangeVat = (e) => {
    /*   console.log(
      "CLIENT-INFORMATION: fiscalDataOnchangeVat: e.value: ",
      e.target.value
    ); */
    this.setState({
      client: { ...this.state.client, vat: e.target.value },
    });
    e.preventDefault();
  };

  fiscalDataOnchangeStreet = (e) => {
    /* console.log(
      "CLIENT-INFORMATION: fiscalDataOnchangeStreet: e.value: ",
      e.target.value
    ); */
    this.setState({
      client: {
        ...this.state.client,
        address: { ...this.state.client.address, street: e.target.value },
      },
    });
    e.preventDefault();
  };

  fiscalDataOnchangeLocality = (e) => {
    /* console.log(
      "CLIENT-INFORMATION: fiscalDataOnchangeLocality: e.value: ",
      e.target.value
    ); */
    this.setState({
      client: {
        ...this.state.client,
        address: { ...this.state.client.address, locality: e.target.value },
      },
    });
    e.preventDefault();
  };

  fiscalDataOnchangePostalCode = (e) => {
    /*  console.log(
      "CLIENT-INFORMATION: fiscalDataOnchangePostalCode: e.value: ",
      e.target.value
    ); */
    this.setState({
      client: {
        ...this.state.client,
        address: { ...this.state.client.address, postalCode: e.target.value },
      },
    });
    e.preventDefault();
  };

  fiscalDataOnchangeProvince = (e) => {
    /* console.log(
      "CLIENT-INFORMATION: fiscalDataOnchangeProvince: e.value: ",
      e.target.value
    ); */
    this.setState({
      client: {
        ...this.state.client,
        address: { ...this.state.client.address, province: e.target.value },
      },
    });
    e.preventDefault();
  };

  fiscalDataOnchangeCountry = (e) => {
    /* console.log(
      "CLIENT-INFORMATION: fiscalDataOnchangeCountry: e.value: ",
      e.target.value
    ); */
    this.setState({
      client: {
        ...this.state.client,
        address: { ...this.state.client.address, country: e.target.value },
      },
    });
    e.preventDefault();
  };

  saveFormFiscalData = async () => {
    this.setState({ loadingAnimation: true });
    let body = {
      name: this.state.client.name,
      vat: this.state.client.vat,
      address: {
        postalCode:
          this.state.client.address && this.state.client.address.postalCode,
        street: this.state.client.address && this.state.client.address.street,
        locality:
          this.state.client.address && this.state.client.address.locality,
        province:
          this.state.client.address && this.state.client.address.province,
        country: this.state.client.address && this.state.client.address.country,
      },
    };
    /* console.log("BODY -> ", body); */
    try {
      await modifyCompany(this.props.client.id, body);
      this.setState({ loadingAnimation: false });
      this.cancelForm();
    } catch (error) {
      console.log(error);
      this.setState({ loadingAnimation: false });
    }
  };

  openCloseModal = (estado) => {
    // console.log('---------------------------------------------')
    // console.log('openCloseModal:  this.state.showData: ', this.state.showData)
    // console.log('openCloseModal:  this.state.showIcons: ', this.state.showData)
    // console.log('openCloseModal:  estado (props.showicons) >>>>>>>> ', estado)
    // console.log('---------------------------------------------')

    if (estado === "all") {
      this.setState({
        showIcons: this.state.showData,
      });
    } else {
      this.setState({
        showIcons: "all",
        //prevShowData: estado
      });
    }
  };

  logoChangedMsg = () => {
    this.showUpSalesMessage("success", "Logo cambiado.", true, true);
  };

  showUpSalesMessage = (level, message, show, close) => {
    this.setState({
      toastLevel: level,
      toastMessage: message,
      showToast: show,
      autoClose: close,
    });
  };

  closeMessage = () => {
    this.setState({
      toastLevel: null,
      toastMessage: null,
      showToast: false,
      autoClose: false,
    });
  };

  ventasPresupuestosCambiaAño = async (condition) => {
    let year = null;
    if (condition === "before") {
      year = this.state.ventasPresupuestoYear - 1;
      this.setState({
        ventasPresupuestoYear: this.state.ventasPresupuestoYear - 1,
      });
    } else {
      year =
        this.state.ventasPresupuestoYear < this.state.actualYear &&
        this.state.ventasPresupuestoYear + 1;
      this.state.ventasPresupuestoYear < this.state.actualYear &&
        this.setState({
          ventasPresupuestoYear: this.state.ventasPresupuestoYear + 1,
        });
    }
    try {
      await this.getVentas(year);
      await this.getPresupuesto(year);
    } catch (e) {
      console.log(e);
    }
  };

  comparaVentasCambiaAño = async (condition) => {
    let year = null;
    if (condition === "before") {
      year = this.state.comparaVentasYear - 1;
      this.setState({ comparaVentasYear: this.state.comparaVentasYear - 1 });
    } else {
      year =
        this.state.comparaVentasYear < this.state.actualYear &&
        this.state.comparaVentasYear + 1;
      this.state.comparaVentasYear < this.state.actualYear &&
        this.setState({ comparaVentasYear: this.state.comparaVentasYear + 1 });
    }
    try {
      await this.getVentas(year);
      await this.getPresupuesto(year);
    } catch (e) {
      console.log(e);
    }
  };

  // Fiscal directions
  showOtherDirectionsList = () => {
    let directions = [];
    let list = this.state.otherDirections;
    list &&
      list &&
      list.forEach((direction, index) => {
        if (direction.usage !== "Fiscal") {
          directions.push(
            <ShowOtherDirection
              key={index}
              direction={direction}
              editFn={this.editOtherDirection}
              deleteOtherDirection={this.deleteOtherDirection}
              loadingAnimation={this.state.loadingAnimation}
              user={this.state.user}
            />
          );
        }
      });
    return directions;
  };

  saveOtherDirectionForm = async () => {
    if (
      this.state.otherDirectionsUsage.trim() !== "" &&
      this.state.otherDirectionsStreet.trim() !== "" &&
      this.state.otherDirectionsLocality.trim() !== "" &&
      this.state.otherDirectionsPostalCode.trim() !== "" &&
      this.state.otherDirectionsProvince.trim() !== "" &&
      this.state.otherDirectionsCountry.trim() !== "" &&
      this
    ) {
      this.setState({ loadingAnimation: true });
      let body = {
        postalCode: this.state.otherDirectionsPostalCode,
        street: this.state.otherDirectionsStreet,
        country: this.state.otherDirectionsCountry,
        province: this.state.otherDirectionsProvince,
        locality: this.state.otherDirectionsLocality,
        usage: this.state.otherDirectionsUsage,
      };
      try {
        /* console.log("saveEditStaffMember: body: ", body); */
        let result = await addOtherDirection(this.state.client.id, body);
        /* console.log("saveEditStaffMember: result: ", result); */
        this.getOtherDirections(this.state.client.id);
        this.setState({ loadingAnimation: false });
        this.cancelForm();
      } catch (error) {
        console.log(error);
      }
    } else {
      this.showUpSalesMessage(
        "warning",
        "Por favor, rellene todos los campos.",
        true,
        true
      );
      this.setState({ loadingAnimation: false });
    }
  };

  editOtherDirection = (direction) => {
    this.setState({
      showForm: "editOtherDirection",
      otherDirectionToEdit: direction,
      otherDirectionsUsage: direction.usage,
      otherDirectionsStreet: direction.street,
      otherDirectionsLocality: direction.locality,
      otherDirectionsPostalCode: direction.postalCode,
      otherDirectionsProvince: direction.province,
      otherDirectionsCountry: direction.country,
    });
  };

  saveEditOtherDirection = async () => {
    if (
      this.state.otherDirectionsUsage.trim() !== "" &&
      this.state.otherDirectionsStreet.trim() !== "" &&
      this.state.otherDirectionsLocality.trim() !== "" &&
      this.state.otherDirectionsPostalCode.trim() !== "" &&
      this.state.otherDirectionsProvince.trim() !== "" &&
      this.state.otherDirectionsCountry.trim() !== "" &&
      this
    ) {
      this.setState({ loadingAnimation: true });
      let body = {
        postalCode: this.state.otherDirectionsPostalCode,
        street: this.state.otherDirectionsStreet,
        country: this.state.otherDirectionsCountry,
        province: this.state.otherDirectionsProvince,
        locality: this.state.otherDirectionsLocality,
        usage: this.state.otherDirectionsUsage,
      };
      try {
        /* console.log("saveEditStaffMember: body: ", body); */
        let result = await saveEditedOtherDirection(
          this.state.client.id,
          this.state.otherDirectionToEdit.id,
          body
        );
        /* console.log("saveEditStaffMember: result: ", result); */
        this.getOtherDirections(this.state.client.id);
        this.setState({ loadingAnimation: false });
        this.cancelForm();
      } catch (error) {
        console.log(error);
      }
    } else {
      this.showUpSalesMessage(
        "warning",
        "Por favor, rellene todos los campos.",
        true,
        true
      );
      this.setState({ loadingAnimation: false });
    }
  };

  deleteOtherDirection = async (otherDirectionData) => {
    try {
      this.setState({ loadingAnimation: true });
      await deleteOtherDirection(this.state.client.id, otherDirectionData.id);
      this.setState({ loadingAnimation: false });
      this.getOtherDirections(this.state.client.id);
    } catch (e) {
      console.log("deleteOtherDirection err ->", e);
    }
  };

  // Staff
  showStaffMemberList = () => {
    let rows = [];
    let list = this.state.staffList;
    /*  console.log("STAFF MEMBERS -> ", list); */
    list &&
      list.forEach((member, index) => {
        rows.push(
          <ShowStaffMember
            key={index}
            staffMember={member}
            editFn={this.editStaffMemberFn}
            deleteStaffMember={this.deleteStaffMember}
            loadingAnimation={this.state.loadingAnimation}
            user={this.state.user}
            idStaffMemberToDelete={this.state.idStaffMemberToDelete}
          />
        );
      });
    return rows;
  };

  deleteStaffMember = async (staffMemberData) => {
    try {
      this.setState({ idStaffMemberToDelete: staffMemberData.id , loadingAnimation: true});
      await deleteStaffMember(this.state.client.id, staffMemberData.id);
      this.setState({ loadingAnimation: false });
      this.getStaff(this.state.client.id);
      this.refreshNavBarClients()
    } catch (e) {
      console.log("deleteStaffMember error ->", e);
    }
  };

  editStaffMemberFn = (staffMemberData) => {
    /* console.log("editStaffMemberFn: staffMemberData: ", staffMemberData); */
    if (staffMemberData.contacts && staffMemberData.contacts.length > 0) {
      staffMemberData.contacts.forEach((contact, index) => {
        if (contact.type === "phone") {
          this.setState({
            staffMemberContactPhoneId: contact.id,
            staffMemberPhone: contact.value,
          });
        }
        if (contact.type === "Email") {
          this.setState({
            staffMemberContactEmailId: contact.id,
            staffMemberEmail: contact.value,
          });
        }
      });
    }
    this.setState({
      staffMemberToEdit: staffMemberData,
      staffMemberName: staffMemberData.name,
      staffMembersurname: staffMemberData.surname,
      staffMemberDescription: staffMemberData.description,
      showForm: "editStaff",
    });
  };

  saveEditStaffMember = async () => {
    if (
      this.state.staffMemberName &&
      this.state.staffMemberName.trim() !== "" 
    ) {
      this.setState({ loadingAnimation: true });
      let tempContacts = [];

      if (
        this.state.staffMemberPhone &&
        this.state.staffMemberPhone.trim() !== ""
      ) {
        tempContacts.push({
          id: this.state.staffMemberContactPhoneId,
          value:
            this.state.staffMemberPhone &&
            this.state.staffMemberPhone.trim() !== ""
              ? this.state.staffMemberPhone
              : null,
          type: "phone",
          label: "work",
        });
      }

      if (
        this.state.staffMemberEmail &&
        this.state.staffMemberEmail.trim() !== null
      ) {
        tempContacts.push({
          id: this.state.staffMemberContactEmailId,
          value:
            this.state.staffMemberEmail === ""
              ? null
              : this.state.staffMemberEmail,
          type: "email",
          label: "work",
        });
      }
      let body = {
        name: this.state.staffMemberName,
        surname: this.state.staffMembersurname,
        description: this.state.staffMemberDescription,
        contacts: tempContacts,
      };
      try {
        let result = await saveEditedStaffMember(
          this.state.client.id,
          this.state.staffMemberToEdit.id,
          body
        );
        this.getStaff(this.state.client.id);
        this.setState({ loadingAnimation: false });
        this.refreshNavBarClients()
        this.cancelForm();
      } catch (error) {
        console.log(error);
      }
    } else {
      this.showUpSalesMessage(
        "warning",
        "Por favor, rellene el campo 'Nombre'.",
        true,
        true
      );
      this.setState({ loadingAnimation: false });
    }
  };

  closeAddPaymentMethodModal = () => {
    this.setState({
      showAddPaymentMethodModal: false,
      newPaymentMethodName: "",
    });
  };

  openAddPaymentMethodModal = () => {
    this.setState({
      showAddPaymentMethodModal: true,
      newPaymentMethodName: "",
    });
  };

  saveNewPaymentMethod = async () => {
    this.setState({ loadingAnimation: true });
    if (this.state.newPaymentMethodName !== "") {
      await createPaymentMethod({ name: this.state.newPaymentMethodName });
      this.setState({
        newPaymentMethodName: "",
      });
      this.closeAddPaymentMethodModal();
      this.getPaymentMethods();
      this.setState({ loadingAnimation: false });
    } else {
      this.showUpSalesMessage(
        "warning",
        "Por favor, rellene el nombre.",
        true,
        true
      );
      this.setState({ loadingAnimation: false });
    }
  };

  // ShowData = all (show all icons)
  // ShowData = basic (general data) byDefault
  // ShowData = fiscal (fiscal data)
  // ShowData = comercial (comercial data)
  // ShowData = economic (economic data)
  render() {
    //console.log('client info:', this.state.client, )
    return (
      <Row id="clientEditingInputs" className="justify-content-center">
        <UpSalesMessage
          level={this.state.toastLevel}
          message={this.state.toastMessage}
          visible={this.state.showToast}
          autoClose={this.state.autoClose}
          closeMessage={this.closeMessage} // PASSING CLOSE MESSAGE
          // callBackAnswerQuestionFn={callBackAnswerQuestion} // Fn que pasamos para que se ejecute cuando user click en ok// OPTIONAL
        />
        {this.state.client && (
          <Col xs={12} className="bg-white">
            {this.state.showData == "basic" && this.state.showForm != "basic" && (
              <Row className="py-3">
                <Col xs={2} className="brd-r-blue d-column">
                  <ClientLogo
                    client={this.props.client.id}
                    logoChangedMsg={this.logoChangedMsg}
                  />
                  <p className="bold-text pt-2 text-center mayus">
                    {this.state.client.alias == null ||
                    this.state.client.alias === ""
                      ? "Nombre sin definir."
                      : this.state.client.alias}
                  </p>
                </Col>
                <Col xs={10}>
                  <div>
                    <div className="d-flex flex-row justify-content-start align-items-center">
                      <p>
                        <b>
                          {this.state.client.alias == null
                            ? "No se ha definido un nombre para la compañía."
                            : this.state.client.alias}
                        </b>
                      </p>
                      {this.state.client.favorite ? (
                        <img
                          title="Cambiar estado favorito"
                          onClick={() =>
                            this.markUnmarkCompanyAsFavorite(
                              this.props.client.id
                            )
                          }
                          style={{
                            cursor: "pointer",
                            width: 20,
                            height: 20,
                            marginLeft: 5,
                          }}
                          src={redStarFavorite}
                        ></img>
                      ) : (
                        <img
                          title="Cambiar estado favorito"
                          onClick={() =>
                            this.markUnmarkCompanyAsFavorite(
                              this.props.client.id
                            )
                          }
                          style={{
                            cursor: "pointer",
                            width: 17,
                            height: 17,
                            marginLeft: 6,
                            marginBottom: 4,
                          }}
                          src={greyStarFavorite}
                        ></img>
                      )}
                      {this.state.client.pinned ? (
                        <img
                          title="Cambiar estado pin"
                          onClick={() =>
                            this.pinUnpinCompany(this.props.client.id)
                          }
                          style={{
                            cursor: "pointer",
                            width: 20,
                            height: 20,
                            marginLeft: 5,
                          }}
                          src={icPinAzul}
                        ></img>
                      ) : (
                        <img
                          title="Cambiar estado pin"
                          onClick={() =>
                            this.pinUnpinCompany(this.props.client.id)
                          }
                          style={{
                            cursor: "pointer",
                            width: 17,
                            height: 17,
                            marginLeft: 6,
                            marginBottom: 4,
                          }}
                          src={icPinGris}
                        ></img>
                      )}
                    </div>

                    {/* <p>{this.state.client.address == null ? "No se ha registrado la dirección. " : this.state.client.address}</p> */}
                    <p>
                      <span style={{ color: "#898989" }}>Teléfono: </span>
                      {this.state.client.phone == null ? (
                        "No hay un número de teléfono guardado."
                      ) : (
                        <a href={"tel:" + this.state.client.phone}>
                          {this.state.client.phone}
                        </a>
                      )}
                    </p>
                    <p>
                      <span style={{ color: "#898989" }}>E-mail: </span>
                      {this.state.client.email == null ? (
                        "No se ha registrado ningún e-mail."
                      ) : (
                        <a
                          className="color-link"
                          href={"mailto:" + this.state.client.email}
                        >
                          {this.state.client.email}
                        </a>
                      )}
                    </p>
                    <p>
                      <span style={{ color: "#898989" }}>Web: </span>
                      {this.state.client.web == null ? (
                        "No se ha registrado ninguna web."
                      ) : (
                        <a
                          className="color-link"
                          href={"https://" + this.state.client.web}
                        >
                          {this.state.client.web}
                        </a>
                      )}
                    </p>
                    <p>
                      <span style={{ color: "#898989" }}>Observaciones: </span>
                      {this.state.client.observations == null ||
                      this.state.client.observations == "" ? (
                        "No se ha registrado ninguna observación."
                      ) : (
                        <a className="color-link">
                          {this.state.client.observations}
                        </a>
                      )}
                    </p>
                    <p>
                      <span style={{ color: "#898989" }}>Estado: </span>
                      {clientDetailStatusTranslations(this.state.statusTagId)}
                    </p>
                    <p>
                      <span style={{ color: "#898989" }}>Tipología: </span>
                      {clientDetailTipologyTranslations(
                        this.state.typologyTagId
                      )}
                    </p>
                    <p>
                      <span style={{ color: "#898989" }}>Adquisición: </span>
                      {clientDetailAcquisitionTranslations(
                        this.state.acquisitionTagId
                      )}
                    </p>
                  </div>
                  <div
                    className="mt-3 d-center boton-editar"
                    onClick={() => this.showForm("basic")}
                  >
                    Editar
                  </div>
                </Col>
              </Row>
            )}
            {this.state.showData == "basic" && this.state.showForm == "basic" && (
              <Row className="py-3">
                <Col xs={2} className="brd-r-blue d-column">
                  <ClientLogo
                    client={this.props.client.id}
                    logoChangedMsg={this.logoChangedMsg}
                  />
                  <p className="bold-text pt-2 text-center mayus">
                    {this.state.client.alias == null ||
                    this.state.client.alias === ""
                      ? "Nombre sin definir."
                      : this.state.client.alias}
                  </p>
                </Col>
                <Col xs={10}>
                  <p className="l-fs-16 pb-2">
                    Modificar información general del cliente:
                  </p>
                  <Row id="basicForm" className="generaltextInputStyle">
                    <Col xs={4}>
                      <input
                        type="text"
                        placeholder={"Nombre"}
                        value={
                          this.state.client.alias != null
                            ? this.state.client.alias
                            : ""
                        }
                        onChange={(e) => this.basicDataOnchangeAlias(e)}
                      ></input>
                      {/* <input type="text" placeholder={'Dirección'} value={this.state.client.address != null ? this.state.client.address : ''} onChange={(e)=>this.basicDataOnchangeAddress(e)}></input> */}
                      <input
                        type="text"
                        placeholder={"Teléfono"}
                        value={
                          this.state.client.phone != null
                            ? this.state.client.phone
                            : ""
                        }
                        onChange={(e) => this.basicDataOnchangePhone(e)}
                      ></input>
                      <input
                        type="text"
                        placeholder={"E-mail"}
                        value={
                          this.state.client.email != null
                            ? this.state.client.email
                            : ""
                        }
                        onChange={(e) => this.basicDataOnchangeEmail(e)}
                      ></input>
                      <input
                        type="text"
                        placeholder={"Web"}
                        value={
                          this.state.client.web != null
                            ? this.state.client.web
                            : ""
                        }
                        onChange={(e) => this.basicDataOnchangeWeb(e)}
                      ></input>
                      <textarea
                        style={{ width: "181px" }}
                        placeholder={"Observaciones"}
                        value={
                          this.state.client.observations != null
                            ? this.state.client.observations
                            : ""
                        }
                        onChange={(e) => this.basicDataOnchangeObservations(e)}
                      ></textarea>
                    </Col>
                    <Col xs={4}>
                      <p>Estado:</p>
                      <select
                        name="status"
                        className="custom-select"
                        defaultValue={
                          this.state.statusTagId != null
                            ? this.state.statusTagId
                            : "3"
                        }
                        style={{
                          width: "180px",
                          height: "31px",
                          fontSize: "14px",
                        }}
                        onChange={(e) => this.basicDataOnchangeStatus(e)}
                      >
                        <option value="3">Potencial</option>
                        <option value="1">Activo</option>
                        <option value="2">No activo</option>
                      </select>

                      <p className="mt-2">Tipologia:</p>
                      <select
                        name="typology"
                        className="custom-select"
                        defaultValue={
                          this.state.typologyTagId != null
                            ? this.state.typologyTagId
                            : "0"
                        }
                        style={{
                          width: "180px",
                          height: "31px",
                          fontSize: "14px",
                        }}
                        onChange={(e) => this.basicDataOnchangeTypology(e)}
                      >
                        <option value="0">Sin definir</option>
                        <option value="4">Piscinero</option>
                        <option value="5">Arquitecto</option>
                        <option value="6">Diseñador</option>
                        <option value="7">Importador</option>
                        <option value="8">Distribuidor</option>
                        <option value="9">Tienda</option>
                        <option value="23">Sitio Web</option>
                        <option value="10">Gran Superficie</option>
                      </select>

                      <p className="mt-2">Adquisición:</p>
                      <select
                        name="acquisition"
                        className="custom-select"
                        defaultValue={
                          this.state.acquisitionTagId != null
                            ? this.state.acquisitionTagId
                            : "0"
                        }
                        style={{
                          width: "180px",
                          height: "31px",
                          fontSize: "14px",
                        }}
                        onChange={(e) => this.basicDataOnchangeAcquisition(e)}
                      >
                        <option value="0">Sin definir</option>
                        <option value="11">Llamada</option>
                        <option value="12">Redes Sociales</option>
                        <option value="13">Feria Ceramica Cevisama</option>
                        <option value="14">Feria Ceramica Cersaie</option>
                        <option value="15">Feria Ceramica Covering</option>
                        <option value="16">Feria Mosaico Barcelona</option>
                        <option value="17">Feria Mosaico Lyon</option>
                        <option value="18">Feria Mosaico Madrid</option>
                        <option value="19">Visita</option>
                        <option value="20">Agente</option>
                        <option value="21">Web</option>
                        <option value="22">Recomendación</option>
                      </select>
                    </Col>
                  </Row>
                  <div className="d-inline-flex mt-3">
                    <div
                      className="d-center boton-cancelar mr-3"
                      onClick={this.cancelForm}
                    >
                      Cancelar
                    </div>
                    <div
                      className="d-center boton-guardar"
                      onClick={this.saveFormBasicData}
                    >
                      {this.state.loadingAnimation && (
                        <LoadingAnimationMiniSize color={"text-light"} />
                      )}
                      {""}
                      Guardar
                    </div>
                  </div>
                </Col>
              </Row>
            )}

            {this.state.showData == "fiscal" &&
              this.state.showForm != "fiscal" && (
                <Row className="py-3">
                  <Col xs={2} className="brd-r-blue d-column">
                    <ClientLogo
                      client={this.props.client.id}
                      logoChangedMsg={this.logoChangedMsg}
                    />
                    <p className="bold-text pt-2 text-center mayus">
                      {this.state.client.alias == null ||
                      this.state.client.alias === ""
                        ? "Nombre sin definir."
                        : this.state.client.alias}
                    </p>
                  </Col>
                  <Col xs={3} className="brd-r-blue">
                    <div>
                      <div className="d-flex justify-content-start align-items-center">
                        <span style={{ color: "#898989" }}>Nombre: &nbsp;</span>
                        {this.state.client.name == null ||
                        this.state.client.name.includes("Desconocido")
                          ? "No se ha definido un Nombre Fiscal para la compañía."
                          : this.state.client.name}
                      </div>
                      {this.state.client.address == null ? (
                        "No hay datos sobre la dirección de esta empresa"
                      ) : (
                        <div>
                          {/* <p>{this.state.client.phone == null ? "No hay datos sobre el teléfono" : this.state.client.address.phone}</p> */}
                          <p>
                            <span style={{ color: "#898989" }}>CIF: </span>
                            {this.state.client.vat == null ||
                            this.state.client.vat === ""
                              ? "No hay datos sobre el CIF de la empresa"
                              : this.state.client.vat}
                          </p>

                          <p>
                            <span style={{ color: "#898989" }}>
                              Dirección:{" "}
                            </span>
                            {this.state.client.address.street == null ||
                            this.state.client.address.street === ""
                              ? "No hay datos sobre la calle"
                              : this.state.client.address.street}
                          </p>
                          <p>
                            <span style={{ color: "#898989" }}>
                              Localidad:{" "}
                            </span>
                            {this.state.client.address.locality == null ||
                            this.state.client.address.locality === ""
                              ? "No se ha definido localidad"
                              : this.state.client.address.locality}
                          </p>

                          <p>
                            <span style={{ color: "#898989" }}>C.P: </span>
                            {this.state.client.address.postalCode == null ||
                            this.state.client.address.postalCode === ""
                              ? "No hay datos sobre el código postal de la empresa"
                              : this.state.client.address.postalCode}
                          </p>

                          <p>
                            <span style={{ color: "#898989" }}>
                              Provincia:{" "}
                            </span>
                            {this.state.client.address.province == null ||
                            this.state.client.address.province === ""
                              ? "No se ha definido la provincia"
                              : this.state.client.address.province}
                          </p>

                          <p>
                            <span style={{ color: "#898989" }}>País: </span>
                            {this.state.client.address.country == null ||
                            this.state.client.address.country === ""
                              ? "No se ha definido un país de origen"
                              : this.state.client.address.country}
                          </p>
                        </div>
                      )}
                    </div>
                    <div
                      className="mt-3 d-center boton-editar"
                      onClick={() => this.showForm("fiscal")}
                    >
                      Editar
                    </div>
                  </Col>
                  <Col xs={7}>
                    <div style={{ position: "absolute", top: 0, right: 100 }}>
                      <button
                        title="Crear otra dirección"
                        type="button"
                        className="modal-small-button pos-a d-center btn btn-primary btn btn-primary"
                        onClick={() => this.showForm("createOtherDirection")}
                      >
                        +
                      </button>
                    </div>
                    &nbsp;
                    <Row>
                      <Col xs={12}>
                        {this.state.showForm !== "editOtherDirection" && (
                          <div>
                            {this.state.showForm === "createOtherDirection" ? (
                              <div>
                                CREAR DIRECCIÓN <br />
                                <div
                                  id="basicForm"
                                  className="generaltextInputStyle"
                                >
                                  <input
                                    type="text"
                                    placeholder={"Tipo"}
                                    value={this.state.otherDirectionsUsage}
                                    onChange={(e) =>
                                      this.setState({
                                        otherDirectionsUsage: e.target.value,
                                      })
                                    }
                                  ></input>
                                  <input
                                    type="text"
                                    placeholder={"Calle"}
                                    value={this.state.otherDirectionsStreet}
                                    onChange={(e) =>
                                      this.setState({
                                        otherDirectionsStreet: e.target.value,
                                      })
                                    }
                                  ></input>
                                  <input
                                    type="text"
                                    placeholder={"Localidad"}
                                    value={this.state.otherDirectionsLocality}
                                    onChange={(e) =>
                                      this.setState({
                                        otherDirectionsLocality: e.target.value,
                                      })
                                    }
                                  ></input>
                                  <input
                                    type="text"
                                    placeholder={"Código postal"}
                                    value={this.state.otherDirectionsPostalCode}
                                    onChange={(e) =>
                                      this.setState({
                                        otherDirectionsPostalCode:
                                          e.target.value,
                                      })
                                    }
                                  ></input>
                                  <input
                                    type="text"
                                    placeholder={"Provincia"}
                                    value={this.state.otherDirectionsProvince}
                                    onChange={(e) =>
                                      this.setState({
                                        otherDirectionsProvince: e.target.value,
                                      })
                                    }
                                  ></input>
                                  <input
                                    type="text"
                                    placeholder={"País"}
                                    value={this.state.otherDirectionsCountry}
                                    onChange={(e) =>
                                      this.setState({
                                        otherDirectionsCountry: e.target.value,
                                      })
                                    }
                                  ></input>
                                </div>
                                <div className="d-inline-flex mt-3">
                                  <div
                                    className="d-center boton-cancelar mr-3"
                                    onClick={this.cancelForm}
                                  >
                                    Cancelar
                                  </div>
                                  <div
                                    className="d-center boton-guardar"
                                    onClick={() =>
                                      this.saveOtherDirectionForm()
                                    }
                                  >
                                    {this.state.loadingAnimation && (
                                      <LoadingAnimationMiniSize
                                        color={"text-light"}
                                      />
                                    )}
                                    Guardar
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <div>
                                <p>
                                  <b>OTRAS DIRECCIONES: </b>
                                </p>
                                {this.state.otherDirections && (
                                  <div
                                    style={{
                                      display: "flex",
                                      flexWrap: "wrap",
                                      paddingRight: "100px",
                                    }}
                                  >
                                    {this.state.otherDirections &&
                                    this.state.otherDirections.length > 1 ? (
                                      this.showOtherDirectionsList()
                                    ) : (
                                      <em>
                                        No hay otras direcciones añadidas
                                        actualmente.
                                      </em>
                                    )}
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        )}
                        {this.state.showForm === "editOtherDirection" &&
                          this.state.otherDirectionToEdit && (
                            <div>
                              EDITAR DIRECCIÓN <br />
                              <div
                                id="basicForm"
                                className="generaltextInputStyle"
                              >
                                <input
                                  type="text"
                                  placeholder={"Tipo"}
                                  value={this.state.otherDirectionsUsage}
                                  onChange={(e) =>
                                    this.setState({
                                      otherDirectionsUsage: e.target.value,
                                    })
                                  }
                                ></input>
                                <input
                                  type="text"
                                  placeholder={"Calle"}
                                  value={this.state.otherDirectionsStreet}
                                  onChange={(e) =>
                                    this.setState({
                                      otherDirectionsStreet: e.target.value,
                                    })
                                  }
                                ></input>
                                <input
                                  type="text"
                                  placeholder={"Localidad"}
                                  value={this.state.otherDirectionsLocality}
                                  onChange={(e) =>
                                    this.setState({
                                      otherDirectionsLocality: e.target.value,
                                    })
                                  }
                                ></input>
                                <input
                                  type="text"
                                  placeholder={"Código postal"}
                                  value={this.state.otherDirectionsPostalCode}
                                  onChange={(e) =>
                                    this.setState({
                                      otherDirectionsPostalCode: e.target.value,
                                    })
                                  }
                                ></input>
                                <input
                                  type="text"
                                  placeholder={"Provincia"}
                                  value={this.state.otherDirectionsProvince}
                                  onChange={(e) =>
                                    this.setState({
                                      otherDirectionsProvince: e.target.value,
                                    })
                                  }
                                ></input>
                                <input
                                  type="text"
                                  placeholder={"País"}
                                  value={this.state.otherDirectionsCountry}
                                  onChange={(e) =>
                                    this.setState({
                                      otherDirectionsCountry: e.target.value,
                                    })
                                  }
                                ></input>
                              </div>
                              <div className="d-inline-flex mt-3">
                                <div
                                  className="d-center boton-cancelar mr-3"
                                  onClick={this.cancelForm}
                                >
                                  Cancelar
                                </div>
                                <div
                                  className="d-center boton-guardar"
                                  onClick={() => this.saveEditOtherDirection()}
                                >
                                  {this.state.loadingAnimation && (
                                    <LoadingAnimationMiniSize
                                      color={"text-light"}
                                    />
                                  )}
                                  Guardar
                                </div>
                              </div>
                            </div>
                          )}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              )}
            {this.state.showData == "fiscal" &&
              this.state.showForm == "fiscal" && (
                <Row className="py-3">
                  <Col xs={2} className="brd-r-blue d-column">
                    <ClientLogo
                      client={this.props.client.id}
                      logoChangedMsg={this.logoChangedMsg}
                    />
                    <p className="bold-text pt-2 text-center mayus">
                      {this.state.client.alias == null ||
                      this.state.client.alias === ""
                        ? "Nombre sin definir."
                        : this.state.client.alias}
                    </p>
                  </Col>
                  <Col xs={10}>
                    <p className="l-fs-16 my-2">
                      Introduce los datos fiscales de la empresa
                    </p>

                    <Form id="addressForm" className="generaltextInputStyle">
                      <input
                        type="text"
                        placeholder="Introduce Nombre Fiscal"
                        value={
                          this.state.client.name == null ||
                          this.state.client.name.includes("Desconocido")
                            ? ""
                            : this.state.client.name
                        }
                        onChange={(e) => this.fiscalDataOnchangeName(e)}
                      ></input>
                      <input
                        type="text"
                        placeholder="Introduce un CIF"
                        value={
                          this.state.client.vat != null
                            ? this.state.client.vat
                            : ""
                        }
                        onChange={(e) => this.fiscalDataOnchangeVat(e)}
                      ></input>
                      <input
                        type="text"
                        placeholder="Introduce Dirección"
                        value={
                          this.state.client.address &&
                          this.state.client.address.street != null
                            ? this.state.client.address.street
                            : ""
                        }
                        onChange={(e) => this.fiscalDataOnchangeStreet(e)}
                      ></input>
                      <input
                        type="text"
                        placeholder="Introduce Localidad"
                        value={
                          this.state.client.address &&
                          this.state.client.address.locality != null
                            ? this.state.client.address.locality
                            : ""
                        }
                        onChange={(e) => this.fiscalDataOnchangeLocality(e)}
                      ></input>
                      <input
                        type="text"
                        placeholder="Introduce Código Postal"
                        value={
                          this.state.client.address &&
                          this.state.client.address.postalCode != null
                            ? this.state.client.address.postalCode
                            : ""
                        }
                        onChange={(e) => this.fiscalDataOnchangePostalCode(e)}
                      ></input>
                      <input
                        type="text"
                        placeholder="Introduce Provincia"
                        value={
                          this.state.client.address &&
                          this.state.client.address.province != null
                            ? this.state.client.address.province
                            : ""
                        }
                        onChange={(e) => this.fiscalDataOnchangeProvince(e)}
                      ></input>
                      <input
                        type="text"
                        placeholder="Introduce País"
                        value={
                          this.state.client.address &&
                          this.state.client.address.country != null
                            ? this.state.client.address.country
                            : ""
                        }
                        onChange={(e) => this.fiscalDataOnchangeCountry(e)}
                      ></input>
                    </Form>

                    <div className="d-inline-flex mt-3">
                      <div
                        className="d-center boton-cancelar mr-3"
                        onClick={this.cancelForm}
                      >
                        Cancelar
                      </div>
                      <div
                        className="d-center boton-guardar"
                        onClick={this.saveFormFiscalData}
                      >
                        {this.state.loadingAnimation && (
                          <LoadingAnimationMiniSize color={"text-light"} />
                        )}
                        {""}
                        Guardar
                      </div>
                    </div>
                  </Col>
                </Row>
              )}

            {this.state.showData == "economic" &&
              this.state.showForm != "economic" && (
                <Row className="py-3">
                  <Col xs={2} className="brd-r-blue d-column">
                    <ClientLogo
                      client={this.props.client.id}
                      logoChangedMsg={this.logoChangedMsg}
                    />
                    <p className="bold-text pt-2 text-center mayus">
                      {this.state.client.alias == null ||
                      this.state.client.alias === ""
                        ? "Nombre sin definir."
                        : this.state.client.alias}
                    </p>
                  </Col>
                  <Col xs={10}>
                    <Row className="d-center">
                      <Col xs={3}>
                        <div>
                          <div className="d-flex justify-content-start align-items-center bold-text">
                            {this.state.client.alias == null
                              ? "No se ha definido un nombre para la compañía."
                              : this.state.client.alias}
                          </div>
                          <div
                            className="economicOptionHover"
                            style={{ position: "relative" }}
                          >
                            {this.state.client && (
                              <SalesDataModal
                                clientId={this.state.client.id}
                                actualYear={this.state.ventasPresupuestoYear}
                                companyName={this.props.client.alias}
                              ></SalesDataModal>
                            )}

                            <p>
                              <span style={{ color: "#898989" }}>Ventas: </span>
                              {this.state.ventas ? this.state.ventas : "0"}
                            </p>
                          </div>
                          <p>
                            <span style={{ color: "#898989" }}>
                              Previsión:{" "}
                            </span>
                            {this.state.presupuesto
                              ? this.state.presupuesto
                              : "0"}
                          </p>

                          <div>
                            <p>
                              <span style={{ color: "#898989" }}>Riesgo: </span>
                              {this.state.financialData.risk == null
                                ? "0"
                                : this.state.financialData.risk % 1 !== 0
                                ? (
                                    Math.round(
                                      this.state.financialData.risk * 100
                                    ) / 100
                                  ).toFixed(2)
                                : this.state.financialData.risk}
                            </p>
                          </div>

                          <div
                            className="economicOptionHover"
                            style={{ position: "relative" }}
                          >
                            <FinancialDataModal
                              data={this.props.client.id}
                            ></FinancialDataModal>
                            <p>
                              <span style={{ color: "#898989" }}>
                                Saldo vivo:{" "}
                              </span>
                              {this.state.financialData.outstandingAmount ==
                              null
                                ? "0"
                                : `${this.state.saldoVivo}`}
                            </p>

                            <p>
                              <span style={{ color: "#898989" }}>
                                Saldo vencido:{" "}
                              </span>
                              {this.state.financialData.balanceDue == null
                                ? "0"
                                : `${this.state.saldoVencido}`}
                            </p>
                          </div>
                          <p>
                            <span style={{ color: "#898989" }}>
                              Forma de pago:{" "}
                            </span>
                            {this.state.financialData.paymentMethod == null
                              ? "Sin definir"
                              : `${this.state.financialData.paymentMethod.name}`}
                          </p>
                        </div>
                        <div
                          className="mt-3 d-center boton-editar"
                          onClick={() => this.showForm("economic")}
                        >
                          Editar
                        </div>
                      </Col>
                      <Col xs={9} className="d-flex justify-content-start">
                        <div id="tabs-grafica">
                          <Tabs
                            defaultActiveKey="Ventas/Presupuesto"
                            id="uncontrolled-tab-example"
                          >
                            <Tab
                              className="py-3"
                              eventKey="Ventas/Presupuesto"
                              title="Ventas vs Presupuesto"
                            >
                              <div
                                className="d-flex flex-row align-items-center justify-content-center"
                                style={{ paddingRight: "0%" }}
                              >
                                <div
                                  onClick={() =>
                                    this.ventasPresupuestosCambiaAño("before")
                                  }
                                  className="pr-4"
                                  style={{ cursor: "pointer" }}
                                  title="Año anterior"
                                >
                                  &lt;
                                </div>
                                <div>{this.state.ventasPresupuestoYear}</div>
                                <div
                                  onClick={() =>
                                    this.ventasPresupuestosCambiaAño("next")
                                  }
                                  className="pl-4"
                                  style={{ cursor: "pointer" }}
                                  title="Año siguiente"
                                >
                                  &gt;
                                </div>
                              </div>
                              {/* <ListYearsToChose actualYear={this.state.actualYear} yearSelected= {this.state.ventasPresupuestoYear} callBackFn={this.userChooseYear}/> */}
                              <div>
                                {this.state.client && (
                                  <VentasVsPresupuestoChart
                                    company={this.state.client}
                                    year={this.state.ventasPresupuestoYear}
                                  />
                                )}
                              </div>
                            </Tab>
                            <Tab
                              className="py-3"
                              eventKey="Ventas/AñoAnterior"
                              title="Año Actual vs Anterior"
                            >
                              {/* <ListYearsToChose actualYear={this.state.actualYear} yearSelected= {this.state.comparaVentasYear} callBackFn={this.userChooseYearTab2}/> */}
                              <div
                                className="d-flex flex-row align-items-center justify-content-center"
                                style={{ paddingRight: "0%" }}
                              >
                                <div
                                  onClick={() =>
                                    this.comparaVentasCambiaAño("before")
                                  }
                                  className="pr-4"
                                  style={{ cursor: "pointer" }}
                                  title="Anterior"
                                >
                                  &lt;
                                </div>
                                <div>
                                  {this.state.comparaVentasYear} /{" "}
                                  <span style={{ color: "#7B98B0" }}>
                                    {this.state.comparaVentasYear - 1}
                                  </span>
                                </div>
                                <div
                                  onClick={() =>
                                    this.comparaVentasCambiaAño("next")
                                  }
                                  className="pl-4"
                                  style={{ cursor: "pointer" }}
                                  title="Siguiente"
                                >
                                  &gt;
                                </div>
                              </div>
                              <div>
                                {this.state.client && (
                                  <VentasActualVsAnteriorChart
                                    company={this.state.client}
                                    year={this.state.comparaVentasYear}
                                  />
                                )}
                              </div>
                            </Tab>
                          </Tabs>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              )}
            {this.state.showData == "economic" &&
              this.state.showForm == "economic" && (
                <Row className="py-3">
                  {/**MODAL PARA AÑADIR FORMAS DE PAGO */}
                  <Modal
                    show={this.state.showAddPaymentMethodModal}
                    onHide={this.closeAddPaymentMethodModal}
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>Añadir forma de pago</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                      <Row id="basicForm" className="generaltextInputStyle">
                        <Col
                          xs={12}
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <p style={{ paddingBottom: 8 }}>Nombre: </p>
                          <input
                            type="text"
                            placeholder={"Nombre"}
                            value={this.state.newPaymentMethodName}
                            className="ml-3"
                            onChange={(e) =>
                              this.setState({
                                newPaymentMethodName: e.target.value,
                              })
                            }
                          ></input>
                        </Col>
                        <div
                          className="d-inline-flex mt-3"
                          style={{ marginLeft: 20 }}
                        >
                          <div
                            className="d-center boton-cancelar mr-3"
                            onClick={this.closeAddPaymentMethodModal}
                          >
                            Cancelar
                          </div>
                          <div
                            className="d-center boton-guardar"
                            onClick={this.saveNewPaymentMethod}
                          >
                            {this.state.loadingAnimation && (
                              <LoadingAnimationMiniSize color={"text-light"} />
                            )}
                            {""}
                            Guardar
                          </div>
                        </div>
                      </Row>
                    </Modal.Body>
                  </Modal>

                  <Col xs={2} className="brd-r-blue d-column">
                    <ClientLogo
                      client={this.props.client.id}
                      logoChangedMsg={this.logoChangedMsg}
                    />
                    <p className="bold-text pt-2 text-center mayus">
                      {this.state.client.alias == null ||
                      this.state.client.alias === ""
                        ? "Nombre sin definir."
                        : this.state.client.alias}
                    </p>
                  </Col>
                  <Col xs={10}>
                    <div className="d-flex justify-content-start align-items-center">
                      <b>
                        {this.state.client.alias == null
                          ? "No se ha definido un nombre para la compañía."
                          : this.state.client.alias}
                      </b>
                    </div>

                    <div id="basicForm" className="generaltextInputStyle">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <p style={{ marginRight: 7 }}>Riesgo: </p>
                        <input
                          style={{ width: 100 }}
                          type="number"
                          placeholder={"Riesgo"}
                          value={
                            this.state.financialData.risk != null
                              ? this.state.financialData.risk
                              : "0"
                          }
                          onChange={(e) => this.economicDataOnchangeRisk(e)}
                        ></input>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <p style={{ marginRight: 7 }}> Forma de pago: </p>
                        <select
                          name="paymentMethod"
                          className="custom-select"
                          defaultValue={
                            this.state.financialData.paymentMethod &&
                            this.state.financialData.paymentMethod.id != null
                              ? this.state.financialData.paymentMethod.id
                              : "0"
                          }
                          style={{
                            width: "180px",
                            height: "31px",
                            fontSize: "14px",
                          }}
                          onChange={(e) =>
                            this.economicDataOnchangePaymentMethod(e)
                          }
                        >
                          <option value="0">SIN DEFINIR</option>
                          {this.state.paymentMethodsLists &&
                            this.state.paymentMethodsLists !== null &&
                            this.state.paymentMethodsLists.length > 0 &&
                            this.state.paymentMethodsLists.map(
                              (paymentMethod, index) => {
                                /*                                 console.log(
                                  "Name: " +
                                    paymentMethod.name +
                                    "   | id: " +
                                    paymentMethod.id
                                ); */
                                return (
                                  <option value={paymentMethod.id} key={index}>
                                    {paymentMethod.name}
                                  </option>
                                );
                              }
                            )}
                        </select>

                        <div style={{ transform: "scale(0.7)" }}>
                          <button
                            title="Añadir nueva forma de pago"
                            type="button"
                            className="modal-small-button btn btn-primary btn btn-primary"
                            onClick={this.openAddPaymentMethodModal}
                          >
                            <p style={{ transform: "scale(1.7)" }}>+</p>
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="d-inline-flex mt-3">
                      <div
                        className="d-center boton-cancelar mr-3"
                        onClick={this.cancelForm}
                      >
                        Cancelar
                      </div>
                      <div
                        className="d-center boton-guardar"
                        onClick={this.saveEconomicaFormData}
                      >
                        {this.state.loadingAnimation && (
                          <LoadingAnimationMiniSize color={"text-light"} />
                        )}
                        {""}
                        Guardar
                      </div>
                    </div>
                  </Col>
                </Row>
              )}

            {this.state.showData == "comercial" &&
              this.state.showForm != "comercial" && (
                <Row className="py-3">
                  <Col xs={2} className="brd-r-blue d-column">
                    <ClientLogo
                      client={this.props.client.id}
                      logoChangedMsg={this.logoChangedMsg}
                    />
                    <p className="bold-text pt-2 text-center mayus">
                      {this.state.client.alias == null ||
                      this.state.client.alias === ""
                        ? "Nombre sin definir."
                        : this.state.client.alias}
                    </p>
                  </Col>
                  <Col xs={10}>
                    <div style={{ position: "absolute", top: 0, right: 100 }}>
                      <button
                        title="Crear contacto"
                        type="button"
                        className="modal-small-button pos-a d-center btn btn-primary btn btn-primary"
                        onClick={() => this.showForm("createStaff")}
                      >
                        +
                      </button>
                    </div>
                    &nbsp;
                    <Row>
                      <Col xs={12}>
                        {this.state.showForm !== "editStaff" && (
                          <div>
                            {this.state.showForm === "createStaff" ? (
                              <div>
                                CREAR CONTACTO <br />
                                <div
                                  id="basicForm"
                                  className="generaltextInputStyle"
                                >
                                  <input
                                    type="text"
                                    placeholder={"Nombre"}
                                    value={this.state.staffMemberName}
                                    onChange={(e) =>
                                      this.setState({
                                        staffMemberName: e.target.value,
                                      })
                                    }
                                  ></input>
                                  <input
                                    type="text"
                                    placeholder={"Apellidos"}
                                    value={this.state.staffMembersurname}
                                    onChange={(e) =>
                                      this.setState({
                                        staffMembersurname: e.target.value,
                                      })
                                    }
                                  ></input>
                                  <input
                                    type="text"
                                    placeholder={"Teléfono"}
                                    value={this.state.staffMemberPhone}
                                    onChange={(e) =>
                                      this.setState({
                                        staffMemberPhone: e.target.value,
                                      })
                                    }
                                  ></input>
                                  <input
                                    type="text"
                                    placeholder={"E-mail"}
                                    value={this.state.staffMemberEmail}
                                    onChange={(e) =>
                                      this.setState({
                                        staffMemberEmail: e.target.value,
                                      })
                                    }
                                  ></input>
                                  <textarea
                                    style={{ width: "300px" }}
                                    placeholder={"Descripción"}
                                    value={this.state.staffMemberDescription}
                                    onChange={(e) =>
                                      this.setState({
                                        staffMemberDescription: e.target.value,
                                      })
                                    }
                                  ></textarea>
                                </div>
                                <div className="d-inline-flex mt-3">
                                  <div
                                    className="d-center boton-cancelar mr-3"
                                    onClick={this.cancelForm}
                                  >
                                    Cancelar
                                  </div>
                                  <div
                                    className="d-center boton-guardar"
                                    onClick={() =>
                                      this.saveFormNewStaffMember()
                                    }
                                  >
                                    {this.state.loadingAnimation && (
                                      <LoadingAnimationMiniSize
                                        color={"text-light"}
                                      />
                                    )}
                                    Guardar
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <div>
                                LISTADO CONTACTOS
                                {this.state.staffList && (
                                  <div
                                    style={{
                                      display: "flex",
                                      flexWrap: "wrap",
                                      paddingRight: "100px",
                                    }}
                                  >
                                    {this.state.staffList &&
                                      this.showStaffMemberList()}
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        )}
                        {this.state.showForm === "editStaff" &&
                          this.state.staffMemberToEdit && (
                            <div>
                              EDITAR CONTACTO <br />
                              <div
                                id="basicForm"
                                className="generaltextInputStyle"
                              >
                                <input
                                  type="text"
                                  placeholder={"Nombre"}
                                  value={this.state.staffMemberName}
                                  onChange={(e) =>
                                    this.setState({
                                      staffMemberName: e.target.value,
                                    })
                                  }
                                ></input>
                                <input
                                  type="text"
                                  placeholder={"Apellidos"}
                                  value={this.state.staffMembersurname}
                                  onChange={(e) =>
                                    this.setState({
                                      staffMembersurname: e.target.value,
                                    })
                                  }
                                ></input>
                                <input
                                  type="text"
                                  placeholder={"Teléfono"}
                                  value={
                                    this.state.staffMemberPhone !== null
                                      ? this.state.staffMemberPhone
                                      : ""
                                  }
                                  onChange={(e) =>
                                    this.setState({
                                      staffMemberPhone: e.target.value,
                                    })
                                  }
                                ></input>
                                <input
                                  type="text"
                                  placeholder={"E-mail"}
                                  value={
                                    this.state.staffMemberEmail !== null
                                      ? this.state.staffMemberEmail
                                      : ""
                                  }
                                  onChange={(e) =>
                                    this.setState({
                                      staffMemberEmail: e.target.value,
                                    })
                                  }
                                ></input>
                                <textarea
                                  style={{ width: "300px" }}
                                  placeholder={"Descripción"}
                                  value={
                                    this.state.staffMemberDescription !== null
                                      ? this.state.staffMemberDescription
                                      : ""
                                  }
                                  onChange={(e) =>
                                    this.setState({
                                      staffMemberDescription: e.target.value,
                                    })
                                  }
                                ></textarea>
                              </div>
                              <div className="d-inline-flex mt-3">
                                <div
                                  className="d-center boton-cancelar mr-3"
                                  onClick={this.cancelForm}
                                >
                                  Cancelar
                                </div>
                                <div
                                  className="d-center boton-guardar"
                                  onClick={() => this.saveEditStaffMember()}
                                >
                                  {this.state.loadingAnimation && (
                                    <LoadingAnimationMiniSize
                                      color={"text-light"}
                                    />
                                  )}
                                  Guardar
                                </div>
                              </div>
                            </div>
                          )}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              )}
            {/*this.state.showData == "comercial" &&
              this.state.showForm == "comercial" && (
                <Row className="py-3">
                  <Col xs={2} className="brd-r-blue d-column">
                    <ClientLogo
                      client={this.props.client.id}
                      logoChangedMsg={this.logoChangedMsg}
                    />
                    <p className="bold-text pt-2 text-center word-break-all mayus">
                      {this.state.client.alias == null ||
                      this.state.client.alias === ""
                        ? "Nombre sin definir."
                        : this.state.client.alias}
                    </p>
                  </Col>
                  <Col xs={10}>
                    Viendo info comercial
                    <div>
                      Industrial Els Cubs <br></br> 12100 Onda - castellón -
                      Tel: 965523890
                    </div>
                    <div className="d-inline-flex mt-3">
                      <div
                        className="d-center boton-cancelar mr-3"
                        onClick={this.cancelForm}
                      >
                        Cancelar
                      </div>
                      <div
                        className="d-center boton-guardar"
                        onClick={this.saveFormData}
                      >
                        Guardar
                      </div>
                    </div>
                  </Col>
                </Row>
              )*/}
            <DataClientPanel
              changeShowData={this.changeShowData}
              openCloseModal={this.openCloseModal}
              showIcons={this.state.showIcons}
              showData={this.state.showData}
            />
          </Col>
        )}
      </Row>
    );
  }
}

export default ClientInformation;

//comentario test
