import { UpSales } from "../bundle/bundle";


//UpSales.InteractionReport.countByModeSalesPersonTable(companyId, page, pageSize, dateFrom, dateTo)
export const commercialsInteractionsReportListRepo = async (companyId, page, pageSize, dateFrom, dateTo, countryId, provinceId, localityId, postalCode) => {
    try {
      let response = await UpSales.InteractionReport.countByModeSalesPersonTable(companyId, page, pageSize, dateFrom, dateTo, countryId, provinceId, localityId, postalCode);
      return response;
    } catch (error) {
      console.log("ERROR USERS-REPO: commercialInteractionReportListRepo: ", error);
    }
  };

//UpSales.InteractionReport.countByModeCompanyTable(userId, page, pageSize, dateFrom, dateTo)
export const clientsInteractionsReportListRepo = async (userId, page, pageSize, dateFrom, dateTo, countryId, provinceId, localityId, postalCode) => {
    try {
      let response = await UpSales.InteractionReport.countByModeCompanyTable(userId, page, pageSize, dateFrom, dateTo, countryId, provinceId, localityId, postalCode)
      return response;
    } catch (error) {
      console.log("ERROR USERS-REPO: clientsInteractionsReportList: ", error);
    }
  };