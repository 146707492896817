// --------------------------------------------
// Created by Pablo Collado in 27/04/2022
// --------------------------------------------

import React, { useState, useEffect, useRef } from "react";

// EXTERNAL LIBRARIES
import { Pie } from "react-chartjs-2";
import "chartjs-plugin-datalabels";
import { Chart } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";

// COMPONENTS
import LoadingAnimationMiniSize from "../LoadingAnimationMiniSize";

Chart.register(ChartDataLabels);

export default function PieChart(props) {
  const [loadingAnimation, setLoadingAnimation] = useState(true);
  const [chartData, setChartData] = useState(null);
  const [reDraw, setReDraw] = useState(false);

  useEffect(() => {
    setLoadingAnimation(true);
    setChartData(props.data);
    setLoadingAnimation(false);
    setReDraw(true);
  }, [props.data]);

  const options = {
    onHover: (evt, activeEls) => {
      activeEls.length > 0 ? evt.chart.canvas.style.cursor = 'pointer' : evt.chart.canvas.style.cursor = 'default';
    },
    maintainAspectRatio: false,
    legend: {
      display: true,
    },
    layout: {
      padding: {
        left: 50,
        right: 50,
      },
    },
    // tooltipTemplate: "<%= value + ' %' %>",
    plugins: {
      title: {
        display: true,
        text: props.title,
      },
      legend: {
        display: false,
        position: "right",
        labels: {
          boxWidth: 10,
          boxHeight: 10,
        },
      },
      datalabels: {
        display: true,
        color: "black",
        cursor: "pointer",
        font: {
          //weight: 'bold',
          size: 11,
        },
        //anchor: 'end',
        align: "center",
        //offset: 50,
        display: "auto",
        //clip: false,
        //rotation: 15,
        formatter: function (value, context) {
          return context.chart.data.labels[context.dataIndex];
        },
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem, data) {
            // debugger
            var total = tooltipItem.dataset.data.reduce((a, b) => a + b, 0);
            var percentage = parseFloat(
              ((tooltipItem.raw / total) * 100).toFixed(1)
            );
            // return  tooltipItem.label + ': ' + tooltipItem.formattedValue + ' (' + percentage + ' %)'
            return tooltipItem.label + ": " + tooltipItem.formattedValue;
          },
          title: function (tooltipItem, data) {
            return tooltipItem.label;
          },
          tooltip: {
            callbacks: {
              label: function(tooltipItem, data) {
                var total = tooltipItem.dataset.data.reduce((a, b) => a + b, 0);
                var percentage = parseFloat((tooltipItem.raw/total*100).toFixed(1));
                // return  tooltipItem.label + ': ' + tooltipItem.formattedValue + ' (' + percentage + ' %)'
                return tooltipItem.label + ': ' + tooltipItem.formattedValue
              },
              title: function(tooltipItem, data) {
                return tooltipItem.label;
              }
            }
          } 
        }
      }
    }
  }

  const onClick = (event) => {
    const chart = Chart.getChart(props.id);
    var activePoints = chart.getElementsAtEventForMode(
      event,
      "nearest",
      { intersect: true },
      true
    );
    if (activePoints[0]) {
      var idx = activePoints[0].index;
      var label = activePoints[0].element.$context.chart.data.labels[idx];
      props.listProjects(label, true);
    }
  };

  return (
    <div style={{ position: "relative" }}>
      {loadingAnimation ? (
        <div style={{ paddingRight: "20%" }}>
          <LoadingAnimationMiniSize />
        </div>
      ) : (
        <div className="py-2"></div>
      )}
      <div>
        {chartData ? (
          <Pie
            id={props.id}
            width={400}
            height={400}
            //plugins={[ChartDataLabels]}
            data={chartData}
            redraw={reDraw}
            options={options}
            onClick={onClick}
          />
        ) : (
          <p className="text-center light-grey l-fs-14">No hay datos</p>
        )}
      </div>
    </div>
  );
}
