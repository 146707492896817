// --------------------------------------------
// Created by Miguel Marín 10/12/2020
// --------------------------------------------

import React, { useState, useEffect } from "react";
import { Row } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import Col from "react-bootstrap/Col";
import ReactDOM from "react-dom";
import { getBalanceListRepo } from "../../api_communication/repository/ClientRepository";

//IMAGES

//COMPONENTS

//BACKEND FUNCTIONS

//RECIBE EL DOCUMENTO PARA AÑADIR EN LA FILA props.document
const DocumentRow = (props) => {
  return (
    <Row key={props.document.key} className="justify-content-center">
      <Col md={11} xs={8}>
        <Row>
          <Col className="light-grey l-fs-14 mayus my-2" md={2}>
            <b>{props.document.number}</b>
          </Col>
          <Col className="light-grey l-fs-14 mayus my-2" md={2}>
            <b>{props.document.postingDate}</b>
          </Col>
          <Col className="light-grey l-fs-14 mayus my-2" md={2}>
            <b>{props.document.dueDate}</b>
          </Col>
          <Col className="light-grey l-fs-14 mayus my-2" md={2}>
            <b>{props.document.balanceSituation}</b>
          </Col>
          <Col className="light-grey l-fs-14 mayus my-2" md={2}>
            <b>{round(props.document.originalAmount)}</b>
          </Col>
          <Col className="light-grey l-fs-14 mayus my-2" md={2}>
            <b>{round(props.document.remainingAmount)}</b>
          </Col>
        </Row>
      </Col>
      <Col md={1} xs={4}>
        <Row>
          <Col className="light-grey l-fs-14 mayus my-2" md={12}>
            <b>-</b>
          </Col>
        </Row>
      </Col>

      <Col className="brd-b-blue" md={12}></Col>
    </Row>
  );
};

const round = (num) => {
  let twoDecimals = parseFloat(num.toFixed(2));
  let result = twoDecimals.toLocaleString("es-ES", {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  });
  return result;
};

// RECIBE EL ID DEL CLIENTE EN: props.id
export default function FinancialDataModal(props) {
  const [show, setShow] = useState(false);
  const [documents, setDocuments] = useState([]);

  useEffect(() => {
    async function getData() {
      try {
        // let balances = UpSales.Finance.listBalances(companyId, page, pageSize, dateFrom, dateTo)
        let data = await getBalanceListRepo(props.data, 0, 100000);
        setDocuments(data);
      } catch (error) {
        console.log(error);
      }
    }
    getData();

    fillData();
  }, [show]);

  const cancelForm = () => {
    setShow(false);
  };

  const fillData = () => {
    let rows = [];
    documents.length !== 0 &&
      documents.forEach((element) => {
        rows.push(<DocumentRow document={element} key={element.id} />);
      });
    return rows;
  };

  return (
    <React.Fragment>
      <div
        className="mouse"
        style={{ width: "100%", height: "100%", position: "absolute" }}
        title="Ver tareas archivadas"
        onClick={() => setShow(true)}
      ></div>

      <Modal
        //style={{ overflow: "hidden" }}
        id="showDocuments"
        className="modal-border"
        show={show}
        onHide={() => setShow(false)}
        dialogClassName="bg-white"
        aria-labelledby="example-custom-modal-styling-title"
        //   centered
        //   backdrop="static"
        //   keyboard={false}
      >
        <Modal.Header className="justify-content-center modal-border modal-border">
          <Modal.Title
            id="example-custom-modal-styling-title"
            className="dark-blue d-column"
          >
            <b className="mt-4 text-center">DATOS ECONÓMICOS</b>
            {/* <Media><img src={imgBigOportunity}></img></Media> */}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-border container">
          <Row className="justify-content-center">
            <Col xs={11}>
              <Row className="justify-content-center">
                <Col md={11} xs={8}>
                  <Row>
                    <Col className="light-grey l-fs-14 mayus my-2" md={2}>
                      <b>Nº Documento</b>
                    </Col>
                    <Col className="light-grey l-fs-14 mayus my-2" md={2}>
                      <b>Fecha registro</b>
                    </Col>
                    <Col className="light-grey l-fs-14 mayus my-2" md={2}>
                      <b>Fecha vencimiento</b>
                    </Col>
                    <Col className="light-grey l-fs-14 mayus my-2" md={2}>
                      <b>Situación documento</b>
                    </Col>
                    <Col className="light-grey l-fs-14 mayus my-2" md={2}>
                      <b>Importe inicial</b>
                    </Col>
                    <Col className="light-grey l-fs-14 mayus my-2" md={2}>
                      <b>Importe pendiente</b>
                    </Col>
                  </Row>
                </Col>
                <Col md={1} xs={4}>
                  <Row>
                    <Col className="light-grey l-fs-14 mayus my-2" md={12}>
                      <b>PDF</b>
                    </Col>
                  </Row>
                </Col>

                <Col className="brd-b-blue" md={12}></Col>
              </Row>
              {documents && documents.length !== 0 ? (
                fillData()
              ) : (
                <div className="d-center my-5">
                  No hay datos económicos sobre este cliente
                </div>
              )}
            </Col>
          </Row>
          {/* <div id="scrollToBottom" className="row px-4" style={{overflowY:'auto', maxHeight: 'calc(100vh - 240px)', display:'block', scrollBehavior:'smooth'}}>
                {(tasksList && tasksList.length !== 0) ? showTasks(tasksList, refreshFn, msg) : <div className="d-center mt-5">No hay tareas archivadas</div>}
              </div>
              { showPlusBtn && <div className="d-center mt-3">
                <div onClick={()=>moreTasks()}>
                  <img src={btnPlus} style={{cursor:'pointer'}}></img>
                </div>
              </div> } */}
          <div className="d-center mb-5 mt-3">
            <div className="d-center boton-cancelar" onClick={cancelForm}>
              Cerrar
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
}
