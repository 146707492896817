import { listTasksForAProjectRepository, 
  createTaskRepository, getProjectsListRepo, 
  getTopCompaniesListRepo, getOverdueTasksForActiveProjectsRepo,
  countOverdueTasksForActiveProjectsAndCompanyRepo,
  getTopCompaniesWithTasksCountListRepo,
  getProjectsListForOneCompanyWithTasksCountRepo,
  loadProjectsListByClientRepo, changeFabricatedRepo,
  deleteProjectRepo } from "../repository/ProjectRepository";


export const isProjectActive = (project) => {
  let result =  ["STARTING", "IN_PROGRESS", "ADVANCED"].includes(project.state)
  return result
}

//let tasks = UpSales.Project.listTasksForProject(projectId, page, pageSize, dateFrom, dateTo, schedulingState, states)
export const listTasksForAProject = async (projectId, page, pageSize, dateFrom, dateTo, schedulingState, states) => {
    try {
      let result = await listTasksForAProjectRepository(projectId, page, pageSize, dateFrom, dateTo, schedulingState, states);
      if (!result || result.error) {
        return false
    } else {
        return result
    }
    } catch (error) {
      console.log(error);
      throw Error (error)
    }
}

export const createTaskForAProject = async (projectId, body) => {
  try {
    let result = await createTaskRepository(projectId, body)
    if (!result || result.error) {
      return false
  } else {
      return result
  }
  } catch (error) {
    console.log(error);
    throw Error (error)
  }
}


//UpSales.Project.listActive(page, pageSize, dateFrom, dateTo, query)
export const getProjectsList = async (page, pageSize, dateFrom, dateTo, query)=>{
  try {
      let result = await getProjectsListRepo(page, pageSize, dateFrom, dateTo, query)
      if (!result || result.error) {
          return false
        } else{
          return result.data
        }
  } catch (error) {
      console.log(error)
      return false
  }
}

// // UpSales.Project.listForOneCompanyWithTasksCount(page, pageSize, companyId, currentDate)
export const getProjectsListForOneCompanyWithTasksCount = async (page, pageSize, companyId, currentDate)=>{
  try {
      let result = await getProjectsListForOneCompanyWithTasksCountRepo(page, pageSize, companyId, currentDate)
      if (!result || result.error) {
          return false
        } else{
          return result
        }
  } catch (error) {
      console.log(error)
      return false
  }
}

export const getTopCompaniesWithTasksCountList = async (page, pageSize, currentDate)=>{
  try {
      let result = await getTopCompaniesWithTasksCountListRepo(page, pageSize, currentDate)
      //console.log('getTopCompaniesListRepo: result: ', result)
      if (!result || result.error) {
          return false
        } else{
          return result.data
        }
  } catch (error) {
      console.log(error)
      return false
  }
}

// UpSales.Project.listTopCompanies(page, pageSize)
export const getTopCompaniesList = async (page, pageSize)=>{
  try {
      let result = await getTopCompaniesListRepo(page, pageSize)
      if (!result || result.error) {
          return false
        } else{
          return result
        }
  } catch (error) {
      console.log(error)
      return false
  }
}

// UpSales.Project.countOverdueTasksForActiveProjects(¿currentDate?)
export const getOverdueTasksForActiveProjects = async (currentDate)=>{
  try {
      let result = await getOverdueTasksForActiveProjectsRepo(currentDate)
      if (!result || result.error) {
          return false
        } else{
          return result.count
        }
  } catch (error) {
      console.log(error)
      return false
  }
}

// UpSales.Project.countOverdueTasksForActiveProjectsAndCompany(currentDate, companyId)
export const countOverdueTasksForActiveProjectsAndCompany = async (currentDate, companyId)=>{
  try {
      let result = await countOverdueTasksForActiveProjectsAndCompanyRepo(currentDate, companyId)
      if (!result || result.error) {
          return false
        } else{
          return result.count
        }
  } catch (error) {
      console.log(error)
      return false
  }
}

// UpSales.Project.listForCompany(page, pageSize, companyId, dateFrom, dateTo, query)
export const loadProjectsListByClient = async (page, pageSize, clientId)=>{
  try {
      let result = await loadProjectsListByClientRepo(page, pageSize, clientId)
      //console.log('loadProjectsListByClient: result : ', result)
      if (!result || result.error) {
          return false
      } else {
          return result.data
      }
  } catch (error) {
      console.log(error)
  }
}

// UpSales.Project.changeFabricated(projectId)
export const changeFabricated = async (projectId, fabricatedValue)=>{
  try {
      let result = await changeFabricatedRepo(projectId, fabricatedValue)
      //console.log('changeFabricated: result : ', result)
      if (!result || result.error) {
          return false
      } else {
          return result
      }
  } catch (error) {
      console.log(error)
  }
}

// UpSales.Project.delete(projectId, endDate)
export const deleteProject = async (projectId, endDate)=>{
  try {
      let result = await deleteProjectRepo(projectId, endDate)
      //console.log('deleteProject: result : ', result)
      if (!result || result.error) {
          return false
      } else {
          return result
      }
  } catch (error) {
      console.log(error)
  }
}

