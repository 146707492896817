import React, { useEffect, useState } from "react";

// PROPS:
// optionsArray={} - array of values for show in options 
// searchTerm={} - returns user writing search word ->¿?
// onChange={handleChange} - Funcition to return user search word
// resetSearch={true or false} - prop: if true reset input value
// inputId={} - string - input id
// inputPlaceholder={} - string 'Buscar por X', 'Nombre de empresa', etc...

export function DynamicSelect(props) {

  const [userValue, setUserValue] = useState('')

  useEffect(() =>{
    if (props.resetSearch) setUserValue('')
  },[props])

  const userSelectAnOption = ()=>{
    let inputElement = document.getElementById(props.inputId)
    let userInput= inputElement.value.toUpperCase();
    setUserValue(userInput)
    let userOptionSelected= document.querySelector(("#optionsValues" +  props.inputId)  + " option[value='" + userInput + "']")
    if (userInput && !userOptionSelected) {
      inputElement.setAttribute ('style', 'border-bottom: 1px solid red !important; color: #757575 !important;');
    }
    if (userOptionSelected || userInput ==='') {
      inputElement.setAttribute ('style', 'border-bottom: 1px solid #c7e6f9 !important; color: black !important;');
    }
    props.onChange(userInput, userOptionSelected ? userOptionSelected.dataset.id : null)
  }

    return (
        <React.Fragment>
          <input autoComplete="off" id={props.inputId} className=" inputDynamicSelect l-fs-14" placeholder={props.inputPlaceholder}
            type="text"
            name={"optionsValues" + props.inputId}
            list={"optionsValues" + props.inputId}
            value={userValue}
            onChange={userSelectAnOption}
          >
          </input>
          {props.optionsArray.length !==0  && <datalist className="datalistStyleRemoveIcon" id={"optionsValues" + props.inputId} >
             {props.optionsArray.map(item => {
              if (item.alias!=='') {
                return <option key={item.id} data-id={item.id} value={item.alias ? item.alias.toUpperCase() : item.name.toUpperCase()}></option>
              }
             })}
            </datalist>}
        </React.Fragment>
      )
}