// --------------------------------------------
// <CreateTaskModal>  
//    opportunity={opportunity object}
//    project={project object}
//    interaction={interaction object} // for interaction
//    companyId={companyId} // for all cases
// </TaskTabList>
// --------------------------------------------

import React, {useState, useEffect} from 'react'

import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Media from 'react-bootstrap/Media'

import imgBigOportunity from '../../assets/img/ic-oportunidad-big.png'
import icProyectos from '../../assets/img/ic-proyecto-nuevo-big.png'
import docIcon from '../../assets/img/ic-adjuntar-doc-azul.png'
import imgClock from '../../assets/img/ic-reloj-nrja.png'
import imgModeText from '../../assets/img/ic-interaccion-blue.png'
import imgModePhone from '../../assets/img/ic-tel-peq-azul.png'
import imgModeEmail from '../../assets/img/ic-correo-blue.png'
import imgModePhoto from '../../assets/img/ic-cam-blue.png'

import LoadingAnimation from '../LoadingAnimation'
import {ShowPhotosAndDocsPreview} from '../MediaManager'
import { ScheduleTaskModal } from './ScheduleTaskModal'
import {OpportunityIconState} from '../CommonScripts'
import ProjectStateIcon from '../proyectos/ProjectStateIcon'

import moment from 'moment'

import { createTask, getCompany} from '../../api_communication/service/ClientService';
import { createTaskForAProject } from '../../api_communication/service/ProjectService'

import UpSalesMessage from '../UpSalesMessage'
import FilesDragAndDrop from '../FilesDragAndDrop'
import {ClientDynamicSelect} from '../selects/ClientDynamicSelect'
import { ProjectDynamicSelect } from '../selects/ProjectDynamicSelect'

export default function CreateTaskModal(props) {

    const [loadingAnimation, setLoadingAnimation] = useState(false)
    const [show, setShow] = useState(false);

    const [isUserProjectManager, setUserProjectManager] = useState(false)
    const [clientId, setClientId] = useState(null)
    const [projectId, setProjectId] = useState(null)

    // States to save and preview docs and images
    const [preview, setPreview] = useState(false)
    const [imageOrDocToShow, setImageOrDocToShow] = useState(null)
    const [saveImagesAndDocs, setSaveImagesAndDocs] = useState(null)
    const [savingAnimation, setSavingAnimation] = useState(false)
    
    const [task, setTask] = useState(null)
    const [modeIcon, setModeIcon] = useState('text')

    const [scheduleDate, setScheduleDate] = useState(null)
    // Llama fn de SchedulteTaskManager.js
    const [addOrChangeScheduleTaskShowModal, setAddOrChangeScheduleTaskShowModal] = useState(false)

    const [level, setLevel] = useState(null)
    const [message, setMessage] = useState(null)
    const [close, setClose] = useState(null)
    const [showUM, setShowUM] = useState(null)
    
    const [companyName, setCompanyName] = useState(null)

    useEffect(() => {

        //console.log('CreateTaskModal: props: ', props)

        async function getCompanyName() {
          try {
              const company = await getCompany(props.companyId)
              setCompanyName(company.alias)
              setLoadingAnimation(false)
          } catch(error) {
            console.log(error)
          }
        }

        setLoadingAnimation(true)

        if (props.project && props.project.company) {
          setCompanyName(props.project.company.alias)
          setLoadingAnimation(false)
        } else if (props.opportunity && props.opportunity.companyName) {
          setCompanyName(props.opportunity.companyName)
          setLoadingAnimation(false)
        } else if (props.interaction && props.interaction.company) {
          setCompanyName(props.interaction.company.alias)
          setLoadingAnimation(false)
        } else if (props.companyId) {
          getCompanyName()
        } else {
            if (props.loggedUser && props.loggedUser.roles.includes('PROJECT_MANAGER')) {
              setUserProjectManager(true)
              setLoadingAnimation(false)
            }
            if (props.loggedUser && !props.loggedUser.roles.includes('PROJECT_MANAGER')) {
              setUserProjectManager(false)
              setLoadingAnimation(false)
            }
        }
          

    }, [props])

    //UpSales.Task.createTask(body)
    // schedule date required
    const saveTask = async() => {
      setSavingAnimation(true)
      let contentUser = document.getElementById("taskForm").value
      if (contentUser == '') {
        setSavingAnimation(false)
        showUpSalesMessage('danger','Debe añadir un comentario para la tarea', true, false)
        return false
      }
            
      if(scheduleDate === null) {
        setSavingAnimation(false)
        showUpSalesMessage('danger','Debe añadir una fecha a esta tarea', true, false)
        return false
      }

      // Build body
      let taskData = {
        content: contentUser,
        mode: modeIcon,
        scheduling: {
          dueDate: scheduleDate,
          creationDate: moment().format('DD/MM/YYYY HH:mm:ss'),
        },
        company: {
          id: props.companyId ? props.companyId : clientId
        }
      }

      // Oportunidad
      if (props.opportunity || (props.interaction && props.interaction.opportunity)) {
        taskData.opportunity = {id: (props.opportunity ? props.opportunity.id : props.interaction.opportunity.id)} 
      }

      if (props.interaction) {
        taskData.originalInteraction = {id: props.interaction.id}
      }

      let savedTask
      try {
        if (!projectId && !props.project && !(props.interaction && props.interaction.project))
          {savedTask = await createTask(taskData)}
        else {
          const project_Id = projectId ? projectId :(props.project ? props.project.id : props.interaction.project.id)
          savedTask = await createTaskForAProject(project_Id, taskData)
        }
      setTask(savedTask)
      setClientId(savedTask.company.id)
      
      setTimeout(() => {
        if (savedTask) {
          if (imageOrDocToShow) setSaveImagesAndDocs(true)
          else uploadingFinished(true)
        }
      }, 1000);
    } catch (error) {
      showUpSalesMessage('danger','Error al crear la Tarea!', true, false)
      console.log(error)
    }
    }

    const cancelForm = () => {
      setShow(false)
      setPreview(false)
      setImageOrDocToShow(null)
      setSaveImagesAndDocs(false)
      setTask(null)
      setScheduleDate(null)
      setProjectId(null)
      setClientId(null)
    }

    const callToShowImageOrDoc = e => {
        setImageOrDocToShow(e)
        setPreview(true)
      }
    
    const closeTaskScheduleModal = () => {
      setAddOrChangeScheduleTaskShowModal(false)
    }

    // vuelve de AddScheduleInCreateTaskOrInteraction con la fecha seleccionada por el usuario
    const scheduleDateCallback = (date) => {
      setScheduleDate(date)
    }

    const showUpSalesMessage = (level, message, show, close) => {
      setClose(close)
      setLevel(level)
      setMessage(message)
      setShowUM(show)
      
    }

    const closeMsg = () => {
      setShowUM(false)
    }

    const uploadingFinished = (value)=>{
      if(value) {
        showUpSalesMessage('success','Tarea creada.', true, true)
        if (props.refreshCloseOpModal) {
          cancelForm()
          props.refresh()
          props.refreshCloseOpModal()
        } else {
          cancelForm()
          props.refresh()
        }
      } else {
          showUpSalesMessage('danger', 'Ha ocurrido un error guardando archivos.', true, false)
      }
      setSavingAnimation(false)
    }

    const getClientId = (id) => {
      setClientId(id)
    }

    const getProjectId = (id) =>{
      setProjectId(id)
    }

    return (
      <React.Fragment>
        {/* MUESTRA BOTON (+) QUE ABRE MODAL DE ABAJO */}

        <UpSalesMessage
            level={level}
            message={message}
            visible={showUM}
            autoClose={close}
            closeMessage={closeMsg}
        />
        
        <Button title="Crear una tarea" className="modal-small-button d-center" variant="primary" onClick={() => setShow(true)}>
          +
        </Button>

        <Modal
          onEntered={() => document.getElementById('taskForm').focus()}
          id="modalPositionRight"
          show={show}
          onHide={() => setShow(false)}
          dialogClassName="bg-white"
          aria-labelledby="example-custom-modal-styling-title"
          centered
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header className="justify-content-center modal-border">
            <Modal.Title id="example-custom-modal-styling-title" className="dark-blue d-column">
              <b className="mb-3 text-center">
                CREAR TAREA
              </b>
              
              <Media>
                {props.project ? <img src={icProyectos}></img> : <img src={imgBigOportunity}></img>}
              </Media>

              {/* <b className="my-2 text-center">{props.opportunity.alias}</b> */}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {loadingAnimation && 
              <div className="overlay-loading-Animation">
                <LoadingAnimation text={'Cargando...'} />
              </div>
            }
            <div className="d-column generaltextInputStyle">

            <p className="mb-3 l-fs-18 blue-up text-center">{companyName ? companyName : null}</p>

            {!companyName ? 
                (isUserProjectManager ? 
                  <div className="px-5">
                    <ProjectDynamicSelect setValue={getProjectId} />
                  </div>
                  : 
                  <div className="px-5">
                    <ClientDynamicSelect setValue={getClientId} />
                  </div>)
            :
                <React.Fragment></React.Fragment>
            }

            

            {(props.opportunity || (props.interaction && props.interaction.opportunity)) && <div>
              <OpportunityIconState opportunity={props.opportunity ? props.opportunity : props.interaction.opportunity}/>
              {<span className="my-3 light-grey ml-2">{props.opportunity ? props.opportunity.name : props.interaction.opportunity.name}</span>}
            </div>}

            {(props.project || (props.interaction && props.interaction.project)) && <div>
              <ProjectStateIcon projectState={props.project ? props.project.state : props.interaction.project.state}/>
              {<span className="my-3 light-grey ml-2">{props.project ? props.project.name : props.interaction.project.name}</span>}
            </div>}

            <div className="d-flex flex-row justify-content-center align-items-center mt-3">
                <div>
                    <span className="light-grey pr-3 l-fs-14">Seleccionar: </span>
                </div>
                <div onClick={()=> setModeIcon('text')}>
                    <img className={`${modeIcon == 'text' ? 'icon-mode-style-selected' : ''} icon-mode-style `} 
                      src={imgModeText} alt="Icono Texto" title="Icono Texto"/>
                </div>
                <div onClick={()=> setModeIcon('phone')}>
                    <img className={`${modeIcon == 'phone' ? 'icon-mode-style-selected' : ''} icon-mode-style `} 
                      src={imgModePhone} alt="Icono Teléfono" title="Icono Teléfono"/>
                </div>
                <div onClick={()=> setModeIcon('email')}>
                    <img className={`${modeIcon == 'email' ? 'icon-mode-style-selected' : ''} icon-mode-style `} 
                      src={imgModeEmail} alt="Icono E-mail" title="Icono E-mail"/>
                </div>
                <div onClick={()=> setModeIcon('photo')}>
                    <img className={`${modeIcon == 'photo' ? 'icon-mode-style-selected' : ''} icon-mode-style `} 
                      src={imgModePhoto} alt="Icono Foto" title="Icono Foto"/>
                </div>
            </div>

              <textarea style={{width: '90%'}} rows="5" className="my-3" id="taskForm" type="text" placeholder="Comentario">
              </textarea>

              <div className="mt-3">

                <ScheduleTaskModal
                  isItATask={true} 
                  show={addOrChangeScheduleTaskShowModal} 
                  onHide={closeTaskScheduleModal} 
                  scheduleDateCallbackFn={scheduleDateCallback}
                />

                <div className="pb-3 d-flex flex-row align-items-center justify-content-center mouse" onClick={()=>setAddOrChangeScheduleTaskShowModal(true)}>
                    <img  width="25" height="25" className="mr-2" src={imgClock}/>
                    <span className="l-fs-14 light-grey text-hover-bold pt-1"><u>{!scheduleDate ? 'Añadir Fecha' : 'Modificar Fecha'}</u></span>
                </div>
                {scheduleDate && 
                  <div className="text-center">
                    <p className="l-fs-14 light-grey pb-3">(Fecha seleccionada: {scheduleDate})</p>
                  </div>
                }
                <div className='py-3'>
                  <FilesDragAndDrop fileSelected={callToShowImageOrDoc}/>
                </div>

              </div>

               {/* MEDIA MANAGER.JS*/}
              {preview && imageOrDocToShow && <div id="preview" className="w-100 py-3">
                <ShowPhotosAndDocsPreview isItATask={true} 
                                          imageOrDocToShow={imageOrDocToShow} 
                                          saveImagesAndDocs={saveImagesAndDocs} 
                                          idCompany={clientId ? clientId : props.companyId} 
                                          idTaskOrInteraction={task && task.id}
                                          uploadingFinished={uploadingFinished}                          
                />
              </div>}

              <div className="d-inline-flex my-5">
                  <div className="d-center boton-cancelar mr-3" onClick={cancelForm}>Cancelar</div>
                  <button disabled={savingAnimation ? true : false} className="d-center boton-guardar" onClick={saveTask}>
                    {savingAnimation && <span className="spinner-grow spinner-grow-sm mr-2" role="status" aria-hidden="true"></span>}
                    Guardar
                  </button>

              </div>
            </div>
          </Modal.Body>
        </Modal>
      </React.Fragment>
    );
  }