import { commercialsInteractionsReportListRepo, clientsInteractionsReportListRepo } from "../repository/InteractionsReportRepository";

export const commercialsInteractionsReportList = async (companyId, page, pageSize, dateFrom, dateTo, countryId, provinceId, localityId, postalCode) => {
    try {
      let response = await commercialsInteractionsReportListRepo(companyId, page, pageSize, dateFrom, dateTo, countryId, provinceId, localityId, postalCode);
      return response;
    } catch (error) {
      console.log("ERROR USERS-SERVICE: commercialInteractionReportListRepo: ", error);
    }
  };

  export const clientsInteractionsReportList = async (userId, page, pageSize, dateFrom, dateTo, countryId, provinceId, localityId, postalCode) => {
    try {
      let response = await clientsInteractionsReportListRepo(userId, page, pageSize, dateFrom, dateTo, countryId, provinceId, localityId, postalCode)
      return response;
    } catch (error) {
      console.log("ERROR USERS-REPO: clientsInteractionsReportList: ", error);
    }
  };
  