import React, { useState, useEffect } from "react";
import "../assets/css/App.css";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

// COMPONENTES
import LateralMenu from "../componentes/LateralMenu";
import SearchBar from "../componentes/SearchBar";
import LoadingAnimation from "../componentes/LoadingAnimation";
import LoadingAnimationMiniSize from "../componentes/LoadingAnimationMiniSize";
import UpSalesMessage from "../componentes/UpSalesMessage";
import EditarProyecto from "../componentes/proyectos/EditarProyecto";
import CloseProject from "../componentes/proyectos/CloseProject";
import ProjectStateIcon from "../componentes/proyectos/ProjectStateIcon";

// EXTERNAL LIBRARIES
import { Link } from "react-router-dom";
import moment from "moment";

// CLOSE LATERAL MENU FUNCTION
import { esconderMenuLateral } from "../assets/js/menuLateral";

// IMAGES
import icCollapse from "../assets/img/rectangle5.png";
import icPdf from "../assets/img/ic-download-orange.png";
import btnMas from '../assets/img/btn-mas.png'
import backToProjectsList from '../assets/img/ic-arrow-left.png'

// SERVICE
import { changeFabricated, deleteProject, loadProjectsListByClient } from '../api_communication/service/ProjectService'

// FN PROYECTOS
import {loadProjectDetails, projectCreateInteraction, updateProject} from "../componentes/proyectos/funcionesProyectos";
import ShowTextWithBreakLines from '../componentes/commons/ShowTextWithBreakLines'
import InteractionTimelineChat from "../componentes/interaccion/InteractionTimeLineChat";
import TaskTabList from "../componentes/task/TaskTabList";
import { getCurrentUserData } from "../api_communication/service/UsersService";
import ChangeProjectState from "../componentes/proyectos/ChangeProjectState";
import PrintReportPdf from "../componentes/proyectos/PrintReportPdf";


export default function DetalleProyecto(props) {
  const [ProjectDetailsData, setProjectDetailsData] = useState(null);
  const [projectId, setProjectId] = useState(null);
  const [clientId, setClientId] = useState(null)
  const [clientProjects, setClientProjects] = useState([]);
  const [loggedUser, setLoggedUser] = useState(null);
  const [userConfirmsDeleteProject, setUserConfirmsDeleteProject] = useState(false)

  const [loadingClientProjects, setLoadingClientProjects] = useState(false)
  const [changingFabricatedStateSpinner, setChangingFabricatedStateSpinner] = useState(false)
  const [moreClientProjectsButton, setMoreClientProjectsButton] = useState(false)
  const [clientProjectsPage, setClientProjectsPage] = useState(0)

  const [isProjectClosedOrArchived, setIsProjectClosedOrArchived] = useState(false);
  const [pdfTitle, setPdfTitle] = useState('')

  const [fabricatedChecked, setFabricatedChecked] = useState('')

  const [projectsFilters, setProjectsFilters] = useState(false)

  const [showEditProjectModal, setShowEditProjectModal] = useState(false);
  const [showCloseProject, setShowCloseProject] = useState(false);
  const [showChangeProjectState, setShowChangeProjectState] = useState(false);
  const [showReportPdfToPrintModal, setShowReportPdfToPrintModal] = useState(false)

  const [msgLevel, setMsgLevel] = useState(null);
  const [msgMessage, setMsgMessage] = useState(null);
  const [msgShowUM, setMsgShowUM] = useState(null);
  const [msgClose, setMsgClose] = useState(null);

  const PAGE_SIZE = 10

  useEffect(() => {
    document.title = "Detalle proyecto | UpSales";
    return () => {};
  }, []);

  useEffect(() => {
    setIsProjectClosedOrArchived(false);
    if (props.location.projectsFilters) {
      setProjectsFilters(props.location.projectsFilters)
    }
    loadData();
  }, [props.location.state, props.location.projectsFilters]);

  const loadData = async (reset) => {
    let error = false;
    try {
      let project_Id = props.location.state.id;
      setProjectId(project_Id);

      const [projectDetails, loggedUser] = await Promise.all([
        loadProjectDetails(project_Id),
        getCurrentUserData(),
      ]);

      if (projectDetails) {
        setProjectDetailsData(projectDetails);
        setIsProjectClosedOrArchived(false);
        if (projectDetails.fabricated === 'true') setFabricatedChecked('SI')
          else if (projectDetails.fabricated === 'false') setFabricatedChecked('NO')
            else setFabricatedChecked(projectDetails.fabricated)
        if (projectDetails.state == "FINISHED" || projectDetails.state == "ARCHIVED") {
          setIsProjectClosedOrArchived(true);
        }
      } else error = true;
      if (loggedUser) setLoggedUser(loggedUser);
      else error = true;

      setClientId(projectDetails.company.id)

      loadClientProjects(0, projectDetails.company.id, reset ? true : false)

    } catch (error) {
      showUpSalesMessage(
        "danger",
        "Ha ocurrido un error cargando los datos!",
        true,
        false
      );
      console.log(error);
    }
    if (error)
      showUpSalesMessage(
        "danger",
        "Ha ocurrido un error cargando los datos!",
        true,
        false
      );
  };

  const loadClientProjects = async (page, clientId, reset)=>{
    try {
      setLoadingClientProjects(true)

      const [client_Projects, nextClientProjects] = await Promise.all([loadProjectsListByClient(page, PAGE_SIZE, clientId),loadProjectsListByClient(page + 1, PAGE_SIZE, clientId)])

      if (client_Projects === false || nextClientProjects === false) {
        showUpSalesMessage("danger","Ha ocurrido un error cargando los datos!",true,false);
        setClientProjects([])
        return false
      }

      if (nextClientProjects.length !== 0) {
        setMoreClientProjectsButton(true)
      } else {
        setMoreClientProjectsButton(false)
      }

      setClientProjectsPage(page)
      setLoadingClientProjects(false)

      if (reset) setClientProjects(client_Projects)
      else setClientProjects([...clientProjects, ...client_Projects])
      
      setTimeout(() => {
        scrollToBottom('divScroll')
      }, 500);

    } catch (error) {
      showUpSalesMessage("danger","Ha ocurrido un error cargando los datos!",true,false);
      console.log(error)
    }
  }

  const reset = () => {
    setProjectDetailsData(null);
    setClientProjects([]);
  };

  const ShowData = (props) => {
    return (
        <Link
          onClick={() => reset()}
          style={{ textDecoration: "none" }}
          className="mouse linksTables"
          to={{ pathname: "/DetalleProyecto", state: props.data }}
          title="Ver detalles de este proyecto"
        >
          <div className="l-fs-14 ml-2 d-flex flex-row align-items-center">
            <div>
              <img width={30} height={30} src={props.data.company.thumbnail} />
            </div>
            <div>
              {props.data.state && (
                <ProjectStateIcon
                  classNames="ml-2 mb-1"
                  projectState={props.data.state}
                />
              )}
            </div>
            <div className="pl-2">
              <p className="color-link">{props.data.company.alias}</p>
              <p>{props.data.name}</p>
            </div>
          </div>
          <div className="grey-underline mt-1"></div>
        </Link>
    );
  };

  const showClientProjects = () => {
    let rows = [];
    clientProjects.length != 0 &&
      clientProjects.forEach((element,index) => {
        rows.push(<ShowData data={element} key={index} />);
      });
    return rows;
  };

  const showUpSalesMessage = (level, message, show, close) => {
    setMsgLevel(level);
    setMsgMessage(message);
    setMsgShowUM(show);
    setMsgClose(close);
  };

  const openEditProject = () => {
    setShowEditProjectModal(true);
  };

  const closeEditProjectModal = () => {
    setShowEditProjectModal(false);
  };

  const openCloseProjectModal = () => {
    setShowCloseProject(true);
  };

  const openChangeProjectStateModal = () => {
    setShowChangeProjectState(true);
  };

  const closeCloseProjectModal = () => {
    setShowCloseProject(false);
  };

  const closeChangeProjectStateModal = () => {
    setShowChangeProjectState(false);
  };

  const refreshWithNewData = () => {
    loadData(true);
  };

  const closeMsg = () => {
    setMsgLevel(null);
    setMsgMessage(null);
    setMsgShowUM(false);
    setMsgClose(null);
    setUserConfirmsDeleteProject(false)
  };

  const printProjectDetail = () => {
    let title = `FICHA TÉCNICA: ` + ProjectDetailsData.name;
    setPdfTitle(title)
    setShowReportPdfToPrintModal(true)
    // document
    //   .getElementById("printable")
    //   .firstChild.classList.remove("col-xl-8");
    // let isDivCollapsed = document.getElementById("datos-proyecto");
    // isDivCollapsed.classList.remove("collapse");
    // let title = `FICHA TÉCNICA: ${ProjectDetailsData.name}`;
    // printDiv("printable", title);
  };

  const scrollToBottom = (divId)=>{
    let objDiv = document.getElementById(divId);
    if (objDiv) {
      objDiv.scrollTop = objDiv.scrollHeight;
    }
    
  }

  const handleFabricatedCheck = async (event)=>{
    let fabricated = event.target.value
    setFabricatedChecked(fabricated)
    try {
      setChangingFabricatedStateSpinner(true)

      let response = await changeFabricated(ProjectDetailsData.id, fabricated)
      
      if (response === false){
        setChangingFabricatedStateSpinner(false)
        showUpSalesMessage('danger', 'Ha ocurrido un error cambiando el estado "Fabricado".', true, false)
        return false
      }

      showUpSalesMessage('success', '"Fabricado" cambiado correctamente.', true, true)

      // hacer interacción
      let body = {
        date: moment ().format("DD/MM/YYYY HH:mm:ss"),
        content: 'Estado fabricado cambiado a ' + (fabricated ==='true' ? 'SI' : (fabricated ==='false' ? 'NO' : 'MUESTRAS')),
        mode: "text"
      }
      let interaction = await projectCreateInteraction(ProjectDetailsData.id, body)

      if (interaction.error === false){ // back retunr error: false when an error happens
        setChangingFabricatedStateSpinner(false)
        showUpSalesMessage('danger', 'Ha ocurrido un error creando la interacción correspondiente a esta operación.', true, false)
        return false
      }

      showUpSalesMessage('success', 'Estado "Fabricado" cambiado correctamente.', true, true)
      setChangingFabricatedStateSpinner(false)
      loadData(true)
    } catch (error) {
      showUpSalesMessage('danger', 'Ha ocurrido un error.', true, false)
      setChangingFabricatedStateSpinner(false)
      console.log(error)
    }
  }

  const loggedUserCanDeleteProject = ()=>{
    if (loggedUser.roles.includes("MANAGER")) return true
    else return false
  }

  const confirmDeleteProject = ()=> {
    setUserConfirmsDeleteProject(true)
    showUpSalesMessage('warning', '¿Está seguro de que desea eliminar este proyecto?', true, false)
  }

  const removeProject = async ()=> {
    closeMsg()
    let date = moment().format("DD/MM/YYYY")
    try {
      let result = await deleteProject (projectId, date)
      window.location.replace("/Proyectos");
    } catch (error) {
      showUpSalesMessage('danger', 'Ha ocurrido un error al eliminar proyecto', true, false)
      console.log(error)
    }
  }

  return (
    <Container className="pos-r non-printable" fluid={true}>
      {msgLevel && msgMessage && msgShowUM != null && msgClose != null && (
        <UpSalesMessage
          level={msgLevel}
          message={msgMessage}
          visible={msgShowUM}
          autoClose={msgClose}
          closeMessage={closeMsg}
          callBackAnswerQuestionFn={userConfirmsDeleteProject ? removeProject : null}
        />
      )}

      {showReportPdfToPrintModal && 
        <PrintReportPdf 
          ProjectDetailsData={ProjectDetailsData}
          isProjectClosedOrArchived={isProjectClosedOrArchived}
          title={pdfTitle}
        />
      }

      {ProjectDetailsData && showEditProjectModal && (
        <EditarProyecto
          project={ProjectDetailsData}
          show={showEditProjectModal}
          close={closeEditProjectModal}
          refresh={refreshWithNewData}
          showUpSalesMessageFn={showUpSalesMessage}
        ></EditarProyecto>
      )}

      {ProjectDetailsData && (
        <ChangeProjectState
          show={showChangeProjectState}
          close={closeChangeProjectStateModal}
          project={ProjectDetailsData}
          refresh={refreshWithNewData}
          showUpSalesMessageFn={showUpSalesMessage}
        ></ChangeProjectState>
      )}

      {projectId && showCloseProject && (
        <CloseProject
          show={showCloseProject}
          projectId={projectId}
          close={closeCloseProjectModal}
          refresh={refreshWithNewData}
          showUpSalesMessageFn={showUpSalesMessage}
        ></CloseProject>
      )}

      <div
        id="ic-menu-out"
        onClick={() => esconderMenuLateral()}
        className="bg-arrow d-center arrow-menu-out d-none orange-up"
      >
        <p>&gt;</p>
      </div>
      <Row>
        <LateralMenu loggedUser={loggedUser}></LateralMenu>

        <Col className=" bg-blue-light">
          <Row className="nav-style brd-b-blue bg-white justify-content-center">
            <Col xs={11} className="align-self-center p-0">
              <SearchBar loggedUser={loggedUser}></SearchBar>
            </Col>
          </Row>

          <Row className="justify-content-center bg-blue-light ">
            <Col xs={12} className="pt-4 d-flex flex-row">
              <Link className="light-grey" to={"/Proyectos"}>
                Proyectos&nbsp;
              </Link>
              /<p className="light-grey">&nbsp;Detalles Proyecto</p>
            </Col>
            {isProjectClosedOrArchived === false && (
              <Col xs={12} className="">
                {loggedUser && loggedUserCanDeleteProject() && <div className="float-right ">
                  <div
                    onClick={() => confirmDeleteProject()}
                    title="Eliminar proyecto"
                    className="red-modal-big-button d-center btn btn-primary"
                  >
                    Eliminar proyecto
                  </div>
                </div>}
                <div className="float-right mr-3">
                  <div
                    onClick={() => openEditProject()}
                    title="Editar proyecto"
                    className="modal-big-button d-center btn btn-primary"
                  >
                    Editar proyecto
                  </div>
                </div>
              </Col>
            )}
          </Row>

          {projectsFilters && <Row>
            <Col xs={12} className={[isProjectClosedOrArchived && "pt-4"]}>
              <Link className="mouse light-grey linksTables" to={{pathname:'/Proyectos', projectsFilters: projectsFilters}}  title="Volver al listado de proyectos">
                <img src={backToProjectsList}/>
                <span className="ml-2">Volver al listado de proyectos</span>
              </Link>
            </Col>
            </Row>}

          {ProjectDetailsData ? (
            <Row id="printable" className="bg-blue-light my-3 l-fs-19">
              <Col className="col-12 col-xl-8">
                <Row className="greyColor pt-4">
                  <div className="col-12 ">
                    <div className="row px-3 divWithBorder">
                      <div className="col-12 bg-white py-5">
                        <div className="pl-3 dark-blue mb-3 d-flex flex-row align-items-center">
                          <div
                            className="non-printable"
                            style={{
                              position: "absolute",
                              top: "35px",
                              right: "26px",
                            }}
                          >
                            <img
                              onClick={() => printProjectDetail()}
                              className="ml-4 mouse"
                              width={35}
                              src={icPdf}
                              title="Guardar Datos Proyecto como pdf"
                            />
                          </div>
                          <div className="mr-2">
                            <img
                              id="projectCompanyLogo"
                              width={30}
                              src={ProjectDetailsData.company.thumbnail}
                            />
                          </div>
                          <b>
                            {ProjectDetailsData &&
                              ProjectDetailsData.company.alias}
                          </b>
                        </div>
                        <div className="grey-underline"></div>
                        <div className="row pl-3 d-flex justify-content-start align-items-start ">
                          <div className="col-12 pt-3 pb-3 ">
                            <div className="d-flex justify-content-start align-items-center">
                              {ProjectDetailsData && (
                                <ProjectStateIcon
                                  classNames="mr-2"
                                  projectState={ProjectDetailsData.state}
                                />
                              )}
                              <p className="dark-blue">
                                <b>
                                  {ProjectDetailsData &&
                                    ProjectDetailsData.name}
                                </b>
                              </p>
                            </div>
                            {/*<p className=" pt-3">{ProjectDetailsData && ProjectDetailsData.description}</p>*/}
                            <div className="pt-3">
                              <ShowTextWithBreakLines
                                content={
                                  ProjectDetailsData &&
                                  ProjectDetailsData.description
                                }
                              ></ShowTextWithBreakLines>
                            </div>
                          </div>
                          <div className="col-12 d-flex justify-content-start align-items-start pb-3">
                            <p className="bolder mr-3">Fabricado:</p>
                            {isProjectClosedOrArchived === true && <span>{fabricatedChecked}</span>}
                            {isProjectClosedOrArchived === false && <div className="d-flex flex-row">
                              <label className="container-checkbox" title="Click para cambiar estado">
                              <p className="l-fs-16 mr-3">SI</p>
                                <input 
                                  type="checkbox"
                                  checked={fabricatedChecked === 'SI'}
                                  value={'true'}
                                  onChange={handleFabricatedCheck} 
                                  name="Tipo" 
                                  className="mouse" 
                                />
                                <span className="checkmark"></span>
                              </label>
                              <label className="container-checkbox" title="Click para cambiar estado">
                                <input 
                                  type="checkbox"
                                  checked={fabricatedChecked === 'NO'}
                                  value={'false'}
                                  onChange={handleFabricatedCheck} 
                                  name="Tipo" 
                                  className="mouse" 
                                />
                                <span className="checkmark"></span>
                                <p className="l-fs-16 mr-3">NO</p>
                              </label>
                              <label className="container-checkbox" title="Click para cambiar estado">
                                <input 
                                  type="checkbox"
                                  checked={fabricatedChecked === 'MUESTRAS'}
                                  value={'MUESTRAS'}
                                  onChange={handleFabricatedCheck} 
                                  name="Tipo" 
                                  className="mouse" 
                                />
                                <span className="checkmark"></span>
                                <p className="l-fs-16 mr-3">MUESTRAS</p>
                              </label>
                            </div>}
                            
                            {changingFabricatedStateSpinner && <div className="ml-3"><LoadingAnimationMiniSize/></div>}
                          </div>
                          <div className="col-12 d-flex justify-content-start align-items-start ">
                            <p className="bolder">Fecha Solicitud:</p>
                            <p className="pl-3">
                              {ProjectDetailsData &&
                                ProjectDetailsData.startingDate}
                            </p>
                          </div>
                          {isProjectClosedOrArchived === true && (
                            <div className="col-12 d-flex justify-content-start align-items-start ">
                              <p className="bolder">Fecha Cierre:</p>
                              <p className="pl-3">
                                {ProjectDetailsData &&
                                  ProjectDetailsData.endDate}
                              </p>
                            </div>
                          )}
                          {isProjectClosedOrArchived === false && (
                            <div className="pb-5"></div>
                          )}
                        </div>

                        {isProjectClosedOrArchived === false && (
                          <div
                            onClick={() => openChangeProjectStateModal()}
                            style={{
                              position: "absolute",
                              bottom: "30px",
                              right: "240px",
                            }}
                            title="Cambiar estado"
                            className="non-printable modal-big-button-inverse d-center btn btn-primary"
                          >
                            Cambiar estado
                          </div>
                        )}
                        {isProjectClosedOrArchived === false && (
                          <div
                            onClick={() => openCloseProjectModal()}
                            style={{
                              position: "absolute",
                              bottom: "30px",
                              right: "20px",
                            }}
                            title="Cerrar proyecto"
                            className="non-printable modal-big-button-inverse d-center btn btn-primary"
                          >
                            Cerrar este proyecto
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </Row>

                <Row className="greyColor pt-3">
                  <div className="col-12 ">
                    <div className="row px-3 divWithBorder">
                      <div className="col-12 py-5 bg-white dark-blue text-center pos-r">
                        <b className="">DATOS DEL PROYECTO</b>
                        <div
                          className="non-printable"
                          style={{
                            position: "absolute",
                            top: "48px",
                            right: "34px",
                          }}
                        >
                          <a
                            id="toggle-img-datos-proyectos"
                            data-toggle="collapse"
                            href="#datos-proyecto"
                            role="button"
                            aria-expanded="true"
                            aria-controls="datos-proyecto"
                          >
                            <img
                              width={12}
                              height={12}
                              src={icCollapse}
                              title="Mostrar/Esconder datos del proyecto"
                            />
                          </a>
                        </div>
                      </div>
                      <div className="col-12 bg-white">
                        <div className="row show" id="datos-proyecto">
                          <div className="col-12 px-4">
                            <p className="title">
                              CARACTERÍSTICAS DEL PRODUCTO
                            </p>
                            <div className="grey-underline"></div>
                            <div className="row px-3 d-flex justify-content-start align-items-start pt-3">
                              <div className="col-6 col-md-4 d-flex justify-content-start align-items-start mb-3">
                                <p className="bolder">Pasta:</p>
                                <p className="pl-3">
                                  {ProjectDetailsData &&
                                  ProjectDetailsData.paste
                                    ? ProjectDetailsData.paste
                                    : "-"}
                                </p>
                              </div>
                              <div className="col-6 col-md-4 d-flex justify-content-start align-items-start mb-3">
                                <p className="bolder">Orígen:</p>
                                <p className="pl-3">
                                  {ProjectDetailsData &&
                                  ProjectDetailsData.origin
                                    ? ProjectDetailsData.origin
                                    : "-"}
                                </p>
                              </div>
                              <div className="col-6 col-md-4 d-flex justify-content-start align-items-start mb-3">
                                <p className="bolder">Formato:</p>
                                <p className="pl-3">
                                  {ProjectDetailsData &&
                                  ProjectDetailsData.format
                                    ? ProjectDetailsData.format
                                    : "-"}
                                </p>
                              </div>
                              <div className="col-6 col-md-4 d-flex justify-content-start align-items-start mb-3">
                                <p className="bolder">Perímetro:</p>
                                <p className="pl-3">
                                  {ProjectDetailsData &&
                                  ProjectDetailsData.perimeter
                                    ? ProjectDetailsData.perimeter
                                    : "-"}
                                </p>
                              </div>
                              <div className="col-6 col-md-4 d-flex justify-content-start align-items-start mb-3">
                                <p className="bolder">Nombre Colores:</p>
                                <p className="pl-3">
                                  {ProjectDetailsData &&
                                  ProjectDetailsData.colour
                                    ? ProjectDetailsData.colour
                                    : "-"}
                                </p>
                              </div>
                              <div className="col-6 col-md-4 d-flex justify-content-start align-items-start mb-3">
                                <p className="bolder">Superficie:</p>
                                <p className="pl-3">
                                  {ProjectDetailsData &&
                                  ProjectDetailsData.surface
                                    ? ProjectDetailsData.surface
                                    : "-"}
                                </p>
                              </div>
                              {ProjectDetailsData &&
                                ProjectDetailsData.surface === "Relieves" && (
                                  <React.Fragment>
                                    <div className="col-6 col-md-4 d-flex justify-content-start align-items-start mb-3">
                                      <p className="bolder">Nombre Relieve:</p>
                                      <p className="pl-3">
                                        {ProjectDetailsData &&
                                        ProjectDetailsData.reliefName
                                          ? ProjectDetailsData.reliefName
                                          : "-"}
                                      </p>
                                    </div>
                                    <div className="col-6 col-md-4 d-flex justify-content-start align-items-start mb-3">
                                      <p className="bolder">
                                        Número de Relieves:
                                      </p>
                                      <p className="pl-3">
                                        {ProjectDetailsData &&
                                        ProjectDetailsData.numberOfReliefs
                                          ? ProjectDetailsData.numberOfReliefs
                                          : "-"}
                                      </p>
                                    </div>
                                    <div className="col-6 col-md-4 d-flex justify-content-start align-items-start mb-3">
                                      <p className="bolder">
                                        Lector de Relieves:
                                      </p>
                                      <p className="pl-3">
                                        {ProjectDetailsData &&
                                        ProjectDetailsData.hasReliefReader
                                          ? "Si"
                                          : "No"}
                                      </p>
                                    </div>
                                  </React.Fragment>
                                )}
                              <div className="col-6 col-md-4 d-flex justify-content-start align-items-start mb-3">
                                <p className="bolder">Tipología:</p>
                                <p className="pl-3">
                                  {ProjectDetailsData &&
                                  ProjectDetailsData.typology
                                    ? ProjectDetailsData.typology
                                    : "-"}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 py-5 px-4">
                            <p className="title">TÉCNICA</p>
                            <div className="grey-underline"></div>
                            <div className="row px-3 d-flex justify-content-start align-items-start pt-3">
                              <div className="col-6 col-md-4 d-flex justify-content-start align-items-start mb-3">
                                <p className="bolder">Glaze:</p>
                                <p className="pl-3">
                                  {ProjectDetailsData &&
                                  ProjectDetailsData.glaze
                                    ? ProjectDetailsData.glaze
                                    : "-"}
                                </p>
                              </div>
                              <div className="col-6 col-md-4 d-flex justify-content-start align-items-start mb-3">
                                <p className="bolder">Rodillo:</p>
                                <p className="pl-3">
                                  {ProjectDetailsData &&
                                  ProjectDetailsData.roller
                                    ? "Si"
                                    : "No"}
                                </p>
                              </div>
                              <div className="col-6 col-md-4 d-flex justify-content-start align-items-start mb-3">
                                <p className="bolder">Pantalla:</p>
                                <p className="pl-3">
                                  {ProjectDetailsData &&
                                  ProjectDetailsData.screen
                                    ? "Si"
                                    : "No"}
                                </p>
                              </div>

                              <div className="col-6 col-md-4 d-flex justify-content-start align-items-start mb-3">
                                <p className="bolder">Inyección:</p>
                                <p className="pl-3">
                                  {ProjectDetailsData &&
                                  ProjectDetailsData.injection
                                    ? "Si"
                                    : "No"}
                                </p>
                              </div>
                              <div className="col-12 pt-2">
                                <p>Efectos:</p>
                                <div className="grey-underline"></div>
                              </div>
                              <div className="col-6 col-md-4 d-flex justify-content-start align-items-start mb-3">
                                <p className="bolder">Mica:</p>
                                <p className="pl-3">
                                  {ProjectDetailsData && ProjectDetailsData.mica
                                    ? "Si"
                                    : "No"}
                                </p>
                              </div>
                              <div className="col-6 col-md-4 d-flex justify-content-start align-items-start mb-3">
                                <p className="bolder">Brillo:</p>
                                <p className="pl-3">
                                  {ProjectDetailsData &&
                                  ProjectDetailsData.shine
                                    ? "Si"
                                    : "No"}
                                </p>
                              </div>
                              <div className="col-6 col-md-4 d-flex justify-content-start align-items-start mb-3">
                                <p className="bolder">Granilla:</p>
                                <p className="pl-3">
                                  {ProjectDetailsData &&
                                  ProjectDetailsData.grain
                                    ? "Si"
                                    : "No"}
                                </p>
                              </div>
                              <div className="col-6 col-md-4 d-flex justify-content-start align-items-start mb-3">
                                <p className="bolder">Rocker:</p>
                                <p className="pl-3">
                                  {ProjectDetailsData &&
                                  ProjectDetailsData.rocker
                                    ? "Si"
                                    : "No"}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Row>
                <Row className="non-printable greyColor pt-3">
                  <div className="col-12">
                    {projectId && ProjectDetailsData && (
                      <TaskTabList
                        companyId={ProjectDetailsData.company.id}
                        project={ProjectDetailsData}
                        entity={{ type: "project", id: projectId }}
                        loggedUser={loggedUser}
                        hideCreateTaskButton={isProjectClosedOrArchived}
                      />
                    )}
                  </div>
                </Row>
                <Row className="non-printable greyColor">
                  <div className="col-12">
                    {ProjectDetailsData && (
                      <InteractionTimelineChat
                        project={ProjectDetailsData}
                        showUpSalesMessageFn={showUpSalesMessage}
                        refresh={refreshWithNewData}
                        hideCreateInteractionButton={isProjectClosedOrArchived}
                      />
                    )}
                  </div>
                </Row>
              </Col>
              <Col className="non-printable col-12 col-xl-4 ">
                <Row className="greyColor pt-4 pr-3">
                  <Col className="col-12 bg-white pt-4">
                    <div className="dark-blue text-center">
                      <b>PROYECTOS DE</b>
                    </div>
                    <div className="dark-blue text-center pb-5">
                      <b>
                        {ProjectDetailsData && ProjectDetailsData.company.alias}
                      </b>
                    </div>
                  </Col>
                  <Col id="divScroll" className="col-12 bg-white pb-3" style={{height: '634px', overflowY: 'auto', scrollBehavior:'smooth'}}>
                    {!clientProjects && (
                      <LoadingAnimation text={"Cargando datos..."} />
                    )}
                    {clientProjects ? (
                      showClientProjects()
                    ) : (
                      <div>No hay datos para mostrar</div>
                    )}

                    {(moreClientProjectsButton === true && !loadingClientProjects) && <div className="btn-mas-container row mb-2" onClick={()=>loadClientProjects(clientProjectsPage + 1, clientId)}>
                        <img src={btnMas}></img>
                    </div>}
                    {loadingClientProjects && <LoadingAnimationMiniSize />}

                  </Col>
                </Row>
              </Col>
            </Row>
          ) : (
            <LoadingAnimation text={"Cargando datos..."} />
          )}
        </Col>
      </Row>
    </Container>
  );
}
