import { UpSales } from "../bundle/bundle";

// Create opportunity
export const createOpportunityRepository = async (body) => {
  let result = await UpSales.Opportunity.createOpportunity(body);
  if (result.error) throw new Error(result.message)
  return result;
};

// Get opportunity
export const fetchGetOpportunityByIdRepository = async (id) => {
  let response = await UpSales.Opportunity.getOpportunity(id);
  if (response.error) throw new Error(response.message)
  return response;
}

// Opportunity - Modify opportunity
// UpSales.Opportunity.updateOpportunity(opportunityId, body)
// body{ name, description, state: 'CLOSED WON' OR 'CLOSED LOST'}
export const modifyOpportunityRepository = async (opportunityId, body) => {
  let result = await UpSales.Opportunity.updateOpportunity(opportunityId, body);
  if (result.error) throw new Error(result.message)
  return result;
};

export const closeLostOpportunityRepository = async (id) => {
  return await closeOpportunity(id, 'CLOSED LOST')
}

export const closeWonOpportunityRepository = async (id) => {
 return await closeOpportunity(id, 'CLOSED WON')
}

// state: 'CLOSED WON' or 'CLOSED LOST'
const closeOpportunity = async (id, state) => {
  let opportunity = await fetchGetOpportunityByIdRepository(id)
  const body = {
    companyId: opportunity.companyId,
    state: state
  }
  return await modifyOpportunityRepository(id, body)
}

// List opportunities filtered by state (one company)
export const listOpportunitiesFilteredByStateRepository = async (clientId, state, page , pageSize) => {
  let response = await UpSales.Company.listOpportunitiesFilteredByState(clientId, state, page, pageSize);
  if (response.error) throw new Error(response.message)
  return response ? response : null
};

// Count opportunities by state (all companies)
export async function countOpportunitiesByStateRepository(stateName) {
  let response = await UpSales.Opportunity.countFilteredByState(stateName);
  if (response.error) throw new Error(response.message)
  return response.count;
}

// List opportunities (one company)
export async function listOpportunitiesByCompanyRepository(clientId, page, pageSize) {
  let response = await UpSales.Company.listOpportunities(clientId, page, pageSize);
  if (response.error) throw new Error(response.message)
  return response;
}

// Count opportunities by state (one company)
export async function countOpportunitiesByCompanyRepository(clientId, oportState) {
  let response = await UpSales.Company.countOpportunitiesFilteredByState(clientId, oportState);
  if (response.error) throw new Error(response.message)
  return response.count;
}

// UpSales.Opportunity.listInteractions(opportunityId, page, pageSize, mode)
export function listInteractionsForAnOpportunityRepository(opportunityId, numPage) {
  let response = UpSales.Opportunity.listInteractions(opportunityId, numPage);
  if (response.error) throw new Error(response.message)
  return (response === []) ? null : response
}

// UpSales.Opportunity.listAllOpportunitiesFilteredByState
export async function listAllOpportunitiesFilteredByStateRepository(stateName, page, pageSize, query) {
  let result = await UpSales.Opportunity.listAllOpportunitiesFilteredByState(stateName, page, pageSize, query);
  if (result.error) throw new Error(result.message)
  return result;
}

// UpSales.Opportunity.listInteractions
export async function listOpportunityInteractionsRepository(opportunityId, page = 0, pageSize = 50000) {
  let result = await UpSales.Opportunity.listInteractions(opportunityId, page, pageSize);
  if (result.error) throw new Error(result.message)
  return result;
}

// UpSales.Opportunity.listAllOpportunities - UpSales.Opportunity.listAllOpportunities(page, pageSize, query)
export async function listAllOpportunitiesRepository(page, pageSize, query) {
  let result = await UpSales.Opportunity.listAllOpportunities(page, pageSize, query);
  if (result.error) throw new Error(result.message)
  return result;
}

//Opportunity - List opportunities filtered by name and company name
export const listOpportunitiesbyNameAndCompanyRepository = async (stateName, page, pageSize, query) => {
  let result = await UpSales.Opportunity.listByNameCompanyNameAndState(stateName, page, pageSize, query);
  if (result.error) throw new Error(result.message)
  return result;
};

// Interaction_Task - Count tasks for one opportunity filtered by scheduling state.
// let countActive = UpSales.Task.countActiveTasksForOneOpportunity(opportunityId)
// Tb nos sirve para poner icono candado naranja o rojo
export const countActiveTasksForOneOpportunityRepository = async (opportunityId) => {
  let result = await UpSales.Task.countActiveTasksForOneOpportunity(opportunityId);
  if (result.error) throw new Error(result.message)
  return result;
};

// Interaction_Task - Count tasks for one opportunity filtered by scheduling state.
// let countActive = UpSales.Task.countActiveTasksForOneOpportunity(opportunityId)
// Tb nos sirve para poner icono candado naranja o rojo
export const countOverdueTasksForOneOpportunityRepository = async (opportunityId, currentDate) => {
  let result = await UpSales.Task.countOverdueTasksForOneOpportunity(opportunityId, currentDate);
  if (result.error) throw new Error(result.message)
  return result;
};

// total count UpSales.Task.countOverdueTasksForOpenOpportunities(currentDate)
export const countOverdueTasksForOpenOpportunitiesRepository = async (currentDate) => {
  let result = await UpSales.Task.countOverdueTasksForOpenOpportunities(currentDate);
  if (result.error) throw new Error(result.message)
  return result;
};

//Interaction_Task - Count tasks for one company filtered by opportunity and scheduling states.
// UpSales.Task.countOverdueTasksForOpenOpportunitiesForOneCompany(companyId, currentDate)
export const countOverdueTasksForOpenOpportunitiesForOneCompanyRepository =
  async (companyId, currentDate) => {
    let result = await UpSales.Task.countOverdueTasksForOpenOpportunitiesForOneCompany(companyId, currentDate);
    if (result.error) throw new Error(result.message)
    return result;
};

//  Interaction - List by mode (one opportunity)
// UpSales.Opportunity.listInteractions(opportunityId, page, pageSize, mode, states)
export const opportunityListArchivedInteractionsRepository = 
  async (opportunityId, page = 0, pageSize = 10, mode = "", states = "") => {

  let result = await UpSales.Opportunity.listInteractions(opportunityId, page, pageSize, mode, states);
  if (result.error) throw new Error(result.message)
  return result;
};
