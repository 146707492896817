import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import { listOpenOpportunitiesForClient } from '../../api_communication/service/OpportunityService'
import LoadingAnimationMiniSize from '../LoadingAnimationMiniSize'
import {OpportunityIconState} from '../CommonScripts'

class InfoRow extends React.Component{
    constructor(props){
        super(props)
        this.callbackGetClient = props.propToInfoRow
        this.state = {
            image:null,
        }
    }

    render(){
        if(this.props.oportunidad){
            return(
                <Row key={this.props.oportunidad.id} className="bg-white bg-white-hover mb-3">
                    <Col className="mouse" onClick={()=>this.callbackGetClient(this.props.oportunidad)}>
                        <Row className="my-2"> 
                            <Col xs={4} className="d-center align-self-start">
                            <div className="ml-2 max-ic-op">
                                <OpportunityIconState opportunity={this.props.oportunidad}/>
                            </div>    
                            </Col>
                            <Col xs={8} className="pl-0">
                                <Row><Col><p className="l-fs-16 dark-blue">{this.props.oportunidad.name}</p></Col></Row>
                                <Row><Col><p className="l-fs-14 light-grey">
                                        {this.props.oportunidad.description}
                                    </p></Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>)
        } else {
            return(
                <p>No hay oportunidades</p>
            )
        }
            
    }
}


  class GetClient extends React.Component{
    constructor(props){
        super(props)

        this.state = {
            cliente: null,
            opportunities: null,
            randomOpportunity: null,
            ready: false
        }

        this.callbackRandomOpportunity = props.unochangeActualOpportunity
    }

    async componentDidMount(){
        
        if(this.props.oportunidad) this.getClientOpportunities(this.props.oportunidad && this.props.oportunidad.companyId )
    }

    componentDidUpdate(prevProps){
        if(this.props.oportunidad != prevProps.oportunidad){
            this.getClientOpportunities(this.props.oportunidad && this.props.oportunidad.companyId)
        }
    }
    
    async getClientOpportunities(clientId){
        try {
            let oportunidad = await listOpenOpportunitiesForClient(clientId)
        this.setState({
            opportunities: oportunidad
        })
        this.randomOpportunity()
        } catch (error) {
            console.log(error)
        }
    }
    
    randomOpportunity(){
        var oportunidades = this.state.opportunities && this.state.opportunities.filter(element => (element.id != this.props.oportunidad.id))
        if(oportunidades !== null && oportunidades.length < 10){
            this.setState({randomOpportunity: oportunidades})
        } else {
            this.setState({randomOpportunity:null})
            let twoRandomOp = []
            for(let i=0; i < 10; i++){
                var oportunidadAleatoria = oportunidades[Math.floor(Math.random() * oportunidades.length + 1)]
                if (!twoRandomOp.includes(oportunidadAleatoria)) twoRandomOp.push(oportunidadAleatoria)
            }
            this.setState({
                randomOpportunity: twoRandomOp
            })
        }
        this.setState({
            ready: true
        })
    }
    
    
   
    render(){
        if(this.state.ready && this.state.randomOpportunity !=undefined){
            const rows=[]
            this.state.randomOpportunity && this.state.randomOpportunity.forEach((opportunity) =>{
                if(opportunity != undefined){
                    rows.push(
                        <InfoRow propToInfoRow={this.callbackRandomOpportunity}
                            oportunidad={opportunity}
                            key={opportunity.id}
                        />
                    )
                } else {}
            })
            if(this.state.randomOpportunity.length == 0){
                return(
                    <Row key={this.props.oportunidad.id} className="bg-white bg-white-hover mb-3">
                        <Col xs={12} className="my-2">
                            <Row><Col><p className="l-fs-16 dark-blue">No hay oportunidades</p></Col></Row>
                        </Col>
                    </Row>
                )
            } else {
                return(
                    <React.Fragment>
                        {this.state.randomOpportunity && 
                            rows               
                        }
                    </React.Fragment>
                )
            }
        } else {
         return <LoadingAnimationMiniSize text={'Cargando oportunidades...'}/>
        }
    }
}

export default class RandomOpportunity extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            loadingState: false,
        }

        this.unochangeActualOpportunity = props.changeActualOpportunity
    }
    componentDidMount(){
        if(this.props.oportunidad){
            this.setState({
                loadingState: true
            })
        } else {
        
        }
    }

    render(){
        return(
            <Row className="justify-content-center">
                <Col xs={12} lg={11}>
                    <Row>
                        <Col className="dark-blue l-fs-16 bg-white mb-3 py-3 text-center ">OTRAS OPORTUNIDADES DE ESTE CLIENTE</Col>
                    </Row>
                    <Row className="justify-content-center">
                        <Col xs={12}>

                            {this.state.loadingState && <GetClient key={this.props.oportunidad.id} unochangeActualOpportunity={this.unochangeActualOpportunity} oportunidad={this.props.oportunidad ? this.props.oportunidad : null}/>}

                        </Col>
                    </Row>
                </Col>
            </Row>
        )
    }
}