import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {
  addProfilePicture,
  updateProfilePicture,
} from "../../api_communication/service/UsersService";
import userImgPlaceholder from "../../assets/img/UserAvatarIconPlaceholder.png";

// CALL FROM PARENT
// <UserEditModal userId={userId} userName={userName} show={showModal} hide={hiddeEditUserModalFn}/>


export default function UserEditModal(props) {
  const [show, setShow] = useState(false);
  const [imageToShow, setImageToShow] = useState(null);
  const [imageFileToSave, setImageFileToSave] = useState(null);

  useEffect(() => {
    if (show !== props.show) {
      setShow(props.show);
      setImageToShow(props.photo ? props.photo : userImgPlaceholder);
    }
  });

  const hiddeEditUserModalFn = () => {
    setImageToShow(null);
    props.hide();
  };

  const saveUserAvatar = async (userId) => {
    var imageData = new FormData();
    imageData.append("file", imageFileToSave);
    try {
      if (props.photo) {
        let result = await updateProfilePicture(userId, imageData);
      } else {
        let result = await addProfilePicture(userId, imageData);
      }
      hiddeEditUserModalFn();
      window.location.reload();
    } catch (e) {
      console.log(e);
    }
  };

  const callToShowImage = (e) => {
    setImageFileToSave(e.target.files[0]);
    let newImage = URL.createObjectURL(e.target.files[0]);
    setImageToShow(newImage);
  };

  return (
    <React.Fragment>
      <Modal
        id="userEditModal"
        show={show}
        onHide={hiddeEditUserModalFn}
        dialogClassName="bg-white"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header className="justify-content-center modal-border">
          <Modal.Title
            id="example-custom-modal-styling-title"
            className="dark-blue d-column"
          >
            <b className="my-2 text-center l-fs-16 bold">Editar Usuario</b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="">
          <Row className="d-flex flex-column">
            <Col>
              <p className="light-blue-modal l-fs-14 mb-2">
                Nombre usuario:{" "}
                <span className="l-fs-14 bold light-grey">
                  {props.userName}
                </span>
              </p>
            </Col>
            <Col>
              <p className="light-blue-modal l-fs-14 mb-2">Cambiar imagen:</p>
              <Row>
                <Col className="d-center">
                  <div
                    style={{ cursor: "pointer" }}
                    title="Cambiar imagen del usuario"
                  >
                    <label className="mouse" htmlFor="archivoOpp">
                      {/* <ShowUserImage src={imageToShow}></ShowUserImage> */}
                      <img
                        alt="User Avatar"
                        className="avatarUsersList"
                        src={imageToShow}
                      ></img>
                    </label>
                    <input
                      encType="multipart/form-data"
                      id="archivoOpp"
                      type="file"
                      multiple={false}
                      accept="file/*"
                      onChange={callToShowImage}
                      style={{ visibility: "hidden", width: 0, height: 0 }}
                    />
                  </div>
                </Col>
              </Row>

              <div className="border-bottom-modal pb-4"></div>

              {/* BUTTONS */}
              <div className="d-center row mt-4">
                <div className="d-inline-flex">
                  <div
                    onClick={hiddeEditUserModalFn}
                    className="d-center boton-cancelar mr-3"
                  >
                    Cancelar
                  </div>
                  <div
                    onClick={() => saveUserAvatar(props.userId)}
                    className="d-center boton-guardar"
                  >
                    Guardar
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
}