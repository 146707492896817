import React, { useEffect } from "react";
import "../assets/css/App.css";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import LateralMenu from "../componentes/LateralMenu";
import SearchBar from "../componentes/SearchBar";

//FUNCION CERRAR MENU
import { esconderMenuLateral } from "../assets/js/menuLateral";

import FilterableClientsTable from "../componentes/clientes/FilterableClientsTable";

function App() {
  useEffect(() => {
    document.title = "Clientes | UpSales";
    return () => {};
  }, []);

  return (
    <Container className="pos-r" fluid={true}>
      <div
        id="ic-menu-out"
        onClick={() => esconderMenuLateral()}
        className="bg-arrow d-center arrow-menu-out d-none orange-up"
      >
        <p>&gt;</p>
      </div>
      <Row>
        <LateralMenu></LateralMenu>

        <Col>
          <Row className="nav-style brd-b-blue justify-content-center">
            <Col xs={11} className="align-self-center p-0">
              {/* Este SearchBar es la sección de arriba donde está el avatar del usuario */}
              <SearchBar />
            </Col>
          </Row>

          <Row className="justify-content-center bg-blue-light">
            <Col xs={12} className="pt-4 pb-3 px-3">
              <a className="light-grey" href="#">
                {" "}
                Clientes
              </a>
            </Col>
          </Row>

          <Row className="bg-blue-light h-100">
            <FilterableClientsTable></FilterableClientsTable>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}

export default App;
