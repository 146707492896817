import React, { useState, useEffect } from "react";
import "../../assets/css/App.css";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import LoadingAnimation from "../../componentes/LoadingAnimation";
import btnPlus from "../../assets/img/btn-mas.png";

import { ShowImageInModal, returnDocumentIcon } from "../MediaManager";

import {
  getInteractionImagesForACompany,
  getInteractionDocsForACompany,
} from "../../api_communication/service/ClientService";

export const fetchImages = async (clientId, page, pageSize) => {
  try {
    let images = await getInteractionImagesForACompany(
      clientId,
      page,
      pageSize
    );

    //console.log('CLIENT-DOCUMENT-LIST: useEffect: images: ', images)
  } catch (error) {
    console.log(error);
  }
};

export const showPhotos = (array, showPhotoFn) => {
  let result = array.map((element, index) => {
    return (
      <div key={index} onClick={() => showPhotoFn(element)}>
        <img
          className="imagesIconStyle"
          src={element.fileDownloadUri}
          alt="Documento adjunto"
          title="Click para ver imagen aumentada."
        />
      </div>
    );
  });
  return result;
};

export const showDocs = (array, showDocFn) => {
  let result = array.map((element, index) => {
    return (
      <Row
        className="px-4 py-2 flex-row justify-content-center align-items-center w-100 document-list"
        key={index}
        onClick={() => showDocFn(element)}
      >
        <Col xs={12} md={1} className="justify-content-start">
          <img
            width={25}
            height={25}
            src={returnDocumentIcon(element)}
            alt="Documento adjunto"
            title="Click para visualizar documento."
          />
        </Col>
        <Col className="justify-content-start">
          <p className="text-left">
            Nombre:{" "}
            <span className="light-grey word-break-all">
              {element.fileName}
            </span>
          </p>
        </Col>
      </Row>
    );
  });
  return result;
};

// --------------------------------------------------------
//  <ClientDocumentList clientId={this.props.client.id}/>
// --------------------------------------------------------
export default function ClientDocumentList(props) {
  const NUMBEROFDOCS = 20;
  const [key, setKey] = useState("Imágenes");
  const [loadingAnimation, setLoadingAnimation] = useState(true);
  const [imagesPageCount, setImagesPageCount] = useState(0);
  const [docsPageCount, setDocsPageCount] = useState(0);

  const [arrayImages, setArrayImages] = useState(null);
  const [arrayDocs, setArrayDocs] = useState(null);

  const [thereAreNoMoreImages, setThereAreNoMoreImages] = useState(false);
  const [thereAreNoMoreDocs, setThereAreNoMoreDocs] = useState(false);

  const [modalShow, setModalShow] = useState(false);
  const [photoToShow, setPhotoToShow] = useState(null);

  const [docToShow, setDocToShow] = useState(null);

  useEffect(() => {
    setLoadingAnimation(true);
    setImagesPageCount(0);
    setDocsPageCount(0);
    // carga imágenes
    const images = async (clientId, page, pageSize) => {
      try {
        let images = await getInteractionImagesForACompany(
          clientId,
          page,
          pageSize
        );
        if (images.length !== 0) setArrayImages(images);
        setLoadingAnimation(false);
      } catch (error) {
        console.log(error);
      }
    };
    // carga docs
    const docs = async (clientId, page, pageSize) => {
      try {
        let docs = await getInteractionDocsForACompany(
          clientId,
          page,
          pageSize
        );
        if (docs.length !== 0) setArrayDocs(docs);
        setLoadingAnimation(false);
      } catch (error) {
        console.log(error);
      }
    };
    images(props.clientId, imagesPageCount, NUMBEROFDOCS);
    docs(props.clientId, docsPageCount, NUMBEROFDOCS);
  }, [props.clientId]);

  useEffect(() => {
    setLoadingAnimation(true);
    // carga datos
    const giveMeMoreImages = async (clientId, page, pageSize) => {
      try {
        let moreImages = await getInteractionImagesForACompany(
          clientId,
          page,
          pageSize
        );
        //console.log('CLIENT-DOCUMENT-LIST: moreImages: ', moreImages)
        if (moreImages.length === 0 || moreImages.length !== NUMBEROFDOCS)
          setThereAreNoMoreImages(true);
        if (moreImages.length !== 0 && arrayImages) {
          let newArray = [...arrayImages, ...moreImages];
          setArrayImages(newArray);
        }
        setLoadingAnimation(false);
      } catch (error) {
        console.log(error);
      }
    };
    giveMeMoreImages(props.clientId, imagesPageCount, NUMBEROFDOCS);
  }, [imagesPageCount]);

  useEffect(() => {
    setLoadingAnimation(true);
    // carga datos
    const giveMeMoreDocs = async (clientId, page, pageSize) => {
      try {
        let moreDocs = await getInteractionDocsForACompany(
          clientId,
          page,
          pageSize
        );
        //console.log('CLIENT-DOCUMENT-LIST: moreDocs: ', moreDocs)
        if (moreDocs.length == 0 || moreDocs.length != NUMBEROFDOCS)
          setThereAreNoMoreDocs(true);
        if (moreDocs.length != 0 && arrayDocs) {
          let newArray = [...arrayDocs, ...moreDocs];
          setArrayDocs(newArray);
        }
        setLoadingAnimation(false);
      } catch (error) {
        console.log(error);
      }
    };
    giveMeMoreDocs(props.clientId, imagesPageCount, NUMBEROFDOCS);
  }, [docsPageCount]);

  const showPhotoFn = (item) => {
    setPhotoToShow(item);
    setModalShow(true);
  };

  const showDocFn = (item) => {
    setDocToShow(item);
    setModalShow(true);
  };

  return (
    <React.Fragment>
      {photoToShow && (
        <ShowImageInModal
          show={modalShow}
          onHide={() => setModalShow(false)}
          photo={photoToShow}
          doc="false"
        />
      )}
      {docToShow && (
        <ShowImageInModal
          show={modalShow}
          onHide={() => setModalShow(false)}
          photo={docToShow}
          doc="true"
        />
      )}
      {!loadingAnimation && (
        <Tabs
          mountOnEnter={true}
          unmountOnExit={true}
          id="client-document-list"
          activeKey={key}
          onSelect={(k) => setKey(k)}
          className="justify-content-center"
        >
          <Tab
            unmountOnExit={true}
            mountOnEnter={true}
            eventKey="Imágenes"
            title="Imágenes"
          >
            <Row className="pt-3 px-4 ">
              {arrayImages && showPhotos(arrayImages, showPhotoFn)}
            </Row>
            {!thereAreNoMoreImages ? (
              <div
                onClick={() => setImagesPageCount(imagesPageCount + 1)}
                className="text-align-center mouse py-4"
              >
                <img
                  src={btnPlus}
                  alt="Cargar más fotos"
                  title="Cargar más fotos"
                />
              </div>
            ) : arrayImages === null ? (
              <p className="py-4">No hay imágenes para mostar</p>
            ) : (
              <p className="py-4">No hay más imágenes.</p>
            )}
          </Tab>
          <Tab
            unmountOnExit={true}
            mountOnEnter={true}
            eventKey="Documentos"
            title="Documentos"
          >
            <Row className="pt-3 px-4  justify-content-center">
              {arrayDocs && showDocs(arrayDocs, showDocFn)}
            </Row>
            {!thereAreNoMoreDocs ? (
              <div
                onClick={() => setDocsPageCount(docsPageCount + 1)}
                className="text-align-center mouse py-4 w-100"
              >
                <img
                  src={btnPlus}
                  alt="Cargar más fotos"
                  title="Cargar más documentos"
                />
              </div>
            ) : arrayDocs === null ? (
              <p className="py-4">No hay documentos para mostar</p>
            ) : (
              <p className="py-4">No hay más documentos.</p>
            )}
          </Tab>
        </Tabs>
      )}
      {loadingAnimation && <LoadingAnimation text="Cargando documentos" />}
    </React.Fragment>
  );
}
