import React, { useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

// COMPONENTS
import LoadingAnimationMiniSize from "../LoadingAnimationMiniSize";

export default function InteractionsReportIndicators(props) {


    return(
        <React.Fragment>
            <Row className="d-flex justify-content-center align-items-start px-5 light-grey ">
                  <Col
                    id="total"
                    className="p-1 d-column col-4" style={indicatorsStyles.indicatorWrapper}
                    //onClick={() => openProjectsList("ACTIVE")}
                    title="Total"
                  >
                    <div style={indicatorsStyles.indicatorButton}>
                      {props.indicatorsData ? (
                        props.indicatorsData.total
                      ) : (
                        <LoadingAnimationMiniSize />
                      )}
                    </div>
                    <p className="l-fs-14  text-center pt-2">
                      TOTAL
                    </p>
                  </Col>

                  <Col
                    id="texto"
                    className="p-1 d-column col-4" style={indicatorsStyles.indicatorWrapper}
                    //onClick={() => openProjectsList("STARTING")}
                    title="texto"
                  >
                    <div style={indicatorsStyles.indicatorButton}>
                      {props.indicatorsData ? (
                        props.indicatorsData.text
                      ) : (
                        <LoadingAnimationMiniSize />
                      )}
                    </div>
                    <p className="l-fs-14  text-center pt-2">TEXTO</p>
                  </Col>

                  <Col
                    id="tlf"
                    className="p-1 d-column col-4" style={indicatorsStyles.indicatorWrapper}
                    //onClick={() => openProjectsList("IN_PROGRESS")}
                    title="Teléfono"
                  >
                    <div style={indicatorsStyles.indicatorButton}>
                      {props.indicatorsData ? (
                        props.indicatorsData.phone
                      ) : (
                        <LoadingAnimationMiniSize />
                      )}
                    </div>
                    <p className="l-fs-14  text-center pt-2">
                      TELÉFONO
                    </p>
                  </Col>

                  <Col
                    id="email"
                    className="p-1 d-column col-4" style={indicatorsStyles.indicatorWrapper}
                    //onClick={() => openProjectsList("ADVANCED")}
                    title="E-mail"
                  >
                    <div style={indicatorsStyles.indicatorButton}>
                      {props.indicatorsData ? (
                        props.indicatorsData.email
                      ) : (
                        <LoadingAnimationMiniSize />
                      )}
                    </div>
                    <p className="l-fs-14  text-center pt-2">E-MAIL</p>
                  </Col>

                  <Col
                    id="foto"
                    className="p-1 d-column col-4" style={indicatorsStyles.indicatorWrapper}
                    //onClick={() => openProjectsList("FINISHED")}
                    title="Ver Finalizado con Éxito"
                  >
                    <div style={indicatorsStyles.indicatorButton}>
                      {props.indicatorsData ? (
                        props.indicatorsData.photo
                      ) : (
                        <LoadingAnimationMiniSize />
                      )}
                    </div>
                    <p className="l-fs-14  text-center pt-2">
                      FOTO
                    </p>
                  </Col>
                </Row>
        </React.Fragment>
    )
}

const indicatorsStyles ={
    indicatorWrapper :{
        width: '90px'
    },
   
    indicatorButton: {
        borderRadius: '50%',
        width: '60px',
        backgroundColor: '#c7e6f9',
        borderColor: '#c7e6f9',
        height: '60px',
        color: '#006ac1',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    }
  }