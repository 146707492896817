import React, { useEffect, useState } from "react";

// PROPS: setValue={Function} - To return countryId selected by user to parent

// COMPONENTES
import { DynamicSelect } from "./DynamicSelect";

// SERVICE
import { getProjectsList } from '../../api_communication/service/ProjectService'

// *****************************************************************
// PROPS ProjectDynamicSelect  
//  setValue={function handleCountry} - in parent to receive country choosen by user in this component
// *****************************************************************
// PROPS DynamicSelect:
// optionsArray={} - array of values for show in options 
// searchTerm={} - returns user writing search word ->¿?
// onChange={handleChange} - Funcition to return user search word
// resetSearch={true or false} - prop: if true reset input value
// inputId={} - string - input id
// inputPlaceholder={} - string 'Buscar por X', 'Nombre de empresa', etc...
// ******************************************************************

export function ProjectDynamicSelect(props) {

    const [array, setArray] = useState([])
    const [userInput, setUserInput] = useState(null)

    useEffect(() => {
        //console.log("ClientDynamicSelect, props:: ", props)
        loadProjects(userInput)
    },[userInput])

    const handleUserInput = async (input, userSelectId) => {
        try {
            if (userSelectId === null) {
                
                if (input && input.length > 2)
                    setUserInput(input)
                else {
                    setUserInput(null)
                    props.setValue(null)
                }

            } else {
                props.setValue(userSelectId)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const loadProjects = async (query) =>{
        try {
            let result = await getProjectsList(0, 100, null,null, query)
            //console.log('Project: loadProjects: ', result)
            setArray(result)
        } catch (error) {
            console.log(error)  
        }
    }

    return (
        <React.Fragment>
          <DynamicSelect 
            optionsArray={array} 
            onChange={handleUserInput} 
            resetSearch={null} 
            inputId={'projectsList'} 
            inputPlaceholder={'Proyecto'}/>
        </React.Fragment>
      )
}