// TEMP imports
//import {Clientes, DatosEmpresas, Actividades, Agentes, AgentClients, Empresas, interactions } from '../DymmyData'
// END TEMP imports

import { UpSales } from "../bundle/bundle";

export const findAllClientsRepository = async (page, pageSize, query, countryId, provinceId, localityId, postalCode) => {
  try {
    let response = await UpSales.Company.listCompaniesBasic(
      page,
      pageSize,
      query,countryId, provinceId, localityId, postalCode
    );
    return response;
  } catch (error) {
    console.log("ERROR CLIENT-REPO: findAllClients: ", error);
  }
};

export const findClientByIdRepository = async (clientId) => {
  try {
    let responseJson = await UpSales.Company.getCompany(clientId);
    setTimeout(() => null, 0);
    return responseJson;
  } catch (error) {
    console.error(error);
  }
};

// delete client
export const deleteClientByIdRepository = async (clientId) => {
  try {
    let result = await UpSales.Company.deleteCompany(clientId);
    return result;
  } catch (error) {
    console.log(error);
  }
};

// SAVE NEW COMPANY // Used in ClientList: newCompany fn

export async function saveNewCompanyRepository(body) {
  // Receives an object {alias: 'alias', name:'name'}
  try {
    let result = await UpSales.Company.createCompany(body);
    return result;
  } catch (error) {
    console.log(error);
  }
}

// SAVE MODIFY COMPANY DATA // Used in AltaEmpresa > DatosGeneralesAltaEmpresaTabComp
export async function modifyCompanyDataRepository(data, id) {
  // Receives an object {data: name:'name', ...}
  try {
    let result = await UpSales.Company.updateCompany(id, data);
  } catch (error) {
    console.log(error);
  }
}

export const getATaskRepository = async (taskId) => {
  try {
    var response = await UpSales.Task.getTask(taskId);
    return response;
  } catch (error) {
    console.log(error);
    throw Error(error);
  }
};

export var LoadInteractionsByCompanyRepository = async (
  clientId,
  page,
  pageSize,
  query,
  state
) => {
  if (!pageSize) pageSize = 10;
  if (!query) query = "";
  if (!state) state = "";
  try {
    var response = await UpSales.Interaction.listAllInteractionsForACompany(
      clientId,
      page,
      pageSize,
      query,
      state
    );
    if (response.length == 0) response = null;
    return response;
  } catch (error) {
    console.log(error);
  }
};

// Save Interaction
export const saveInteractionByCompanyRepository = async (
  clientId,
  newInteraction
) => {
  try {
    let response = await UpSales.Interaction.createInteraction(
      clientId,
      newInteraction
    );
    return response;
  } catch (error) {
    console.error(error);
  }
}

// SAVE NEW INCIDENT // Used in CreateIncident

export async function saveNewIncidentRepository(body) {
  // Receives an object {companyId: 'asdfasdfa', name:'name',....}
  try {
    let result = await UpSales.Incidence.createIncidence(body);
    return result;
  } catch (error) {
    console.log(error);
  }
}

// Incidences List Linea Tiempo

export async function findIncidencesByClientIdRepository(clientId) {
  try {
    let responseJson = await UpSales.Company.listIncidences(clientId, 0, 100);
    return responseJson;
  } catch (error) {
    console.error(error);
  }
}

// Count incidences by state
export async function CountIncidencesByStateRepository(stateName) {
  try {
    let response = await UpSales.Incidence.countFilteredByState(stateName);
    return response.count;
  } catch (error) {
    console.log("ERROR : REPO: CountIncidencesByStateRepository: ", error);
  }
}

export async function listCompaniesByOpportunityStateRepository(
  stateName,
  page,
  pageSize
) {
  try {
    let response =
      await UpSales.Company.listCompaniesFilteredByOpportunityState(
        stateName,
        page,
        pageSize
      );
    return response;
  } catch (error) {
    console.log(
      "ERROR : REPO: listCompaniesByOpportunityStateRepository: ",
      error
    );
  }
}

// Company - List companies filtered by incidence state
export async function listCompaniesByIncidenceStateRepository(state) {
  try {
    let response = await UpSales.Company.listCompaniesFilteredByIncidenceState(
      "open"
    );
    return response;
  } catch (error) {
    console.log(
      "ERROR : REPO: listCompaniesByIncidenceStateRepository: ",
      error
    );
  }
}
// Company - List incidences filtered by state listIncidencesByCompanyRepository
export async function listIncidencesByCompanyRepository(clientId) {
  try {
    let response = await UpSales.Company.listIncidencesFilteredByState(
      clientId,
      "open"
    );
    return response;
  } catch (error) {
    console.log("ERROR : REPO: listIncidencesByCompanyRepository: ", error);
  }
}
//  Company - Count incidences by state
export async function countIncidencesByCompanyRepository(clientId) {
  try {
    let response = await UpSales.Company.countIncidencesFilteredByState(
      clientId,
      "open"
    );
    return response.count;
  } catch (error) {
    console.log("ERROR : REPO: countIncidencesByCompanyRepository: ", error);
  }
}

// List interactions for an incidence
export async function listInteractionsForAnIncidenceRepository(
  incidenceId,
  numPage
) {
  try {
    let response = await UpSales.Incidence.listInteractions(
      incidenceId,
      numPage
    );
    setTimeout(() => null, 0);
    if (response == []) {
      response = null;
    }
    return response;
  } catch (error) {
    console.log(
      "ERROR : REPO: listInteractionsForAnIncidenceRepository: ",
      error
    );
  }
}

// Incidence - Get Incidence by Id
export async function fetchGetIncidenceByIdRepository(id) {
  try {
    let response = await UpSales.Incidence.getIncidence(id);
    setTimeout(() => null, 0);
    return response;
  } catch (error) {
    console.log("ERROR : REPO: fetchGetIncidenceByIdRepository: ", error);
  }
}

// globalFilterInteractionsByWordRepository
// Global filter interactions by word // Company - List interactions for a company
export async function globalFilterInteractionsByWordRepository(
  companyId,
  word
) {
  try {
    let response = await UpSales.Interaction.listAllInteractionsForACompany(
      companyId,
      0,
      100,
      word
    );
    setTimeout(() => null, 0);
    return response;
  } catch (error) {
    console.log(
      "ERROR : REPO: globalFilterInteractionsByWordRepository: ",
      error
    );
  }
}

// Archive / Restore Interaction
export async function archiveInteractionRepository(companyId, interactionId) {
  try {
    // UpSales.Interaction.archiveInteraction(companyId, interactionId)
    let result = await UpSales.Interaction.archiveInteraction(
      companyId,
      interactionId
    );
    return result;
  } catch (error) {
    console.log(error);
  }
}

export async function logoCompanyRepository(companyId, file) {
  let result = await UpSales.Company.uploadLogo(companyId, file);
}

export async function updateCompanyLogoRepository(companyId, file) {
  try {
    let result = await UpSales.Company.updateLogo(companyId, file);
    return result;
  } catch (error) {
    console.log(error);
  }
}

export async function saveDocumentsRepository(companyId, interactionId, files) {
  if (files.length !== 0)
    try {
      let result = await UpSales.Interaction.uploadDocuments(
        companyId,
        interactionId,
        files
      );
      return result;
    } catch (error) {
      console.log("REPO: ERROR saveDocumentRepository", error);
    }
}

export var closeInteractionRepository = async (
  companyId,
  interactionId,
  body
) => {
  try {
    let result = await UpSales.Interaction.closeTask(
      companyId,
      interactionId,
      body
    );
    return result;
  } catch (error) {
    console.log(error);
  }
};

// company list emails
export var listEmailsRepository = async (companyId) => {
  try {
    let result = await UpSales.Company.listEmails(companyId);
    if (result.length != 0) return result;
    else return null;
  } catch (error) {
    console.log("REPO: ", error);
  }
};

// company list phones
export var listPhonesRepository = async (companyId) => {
  try {
    let result = await UpSales.Company.listPhones(companyId);
    if (result.length != 0) return result;
    else return null;
  } catch (error) {
    console.log("REPO: ", error);
  }
};

//company financial data
export var getFinance = async (companyId) => {
  try {
    let result = await UpSales.Company.getFinance(companyId);
    return result;
  } catch (error) {
    console.log("REPO", error);
  }
};

//modify company data
export var modifyCompanyRepository = async (companyId, body) => {
  try {
    let result = await UpSales.Company.updateCompany(companyId, body);
    return result;
  } catch (error) {
    console.log("REPO", error);
  }
};

//Set company tags
export var setCompanyTagsRepository = async (companyId, familyId, tags) => {
  try {
    let result = await UpSales.Company.setTags(companyId, familyId, tags);
    return result;
  } catch (error) {
    console.log("setCompanyTagsRepository error", error);
  }
};

export var getTagsRepository = async (companyId, familyId) => {
  try {
    let result = await UpSales.Company.getTags(companyId, familyId);
    return result;
  } catch (error) {
    console.log("getTagsRepository error", error);
  }
};

//modify interaction data
export var modifyInteractionRepository = async (
  companyId,
  interactionId,
  body
) => {
  try {
    let result = await UpSales.Interaction.updateInteraction(
      companyId,
      interactionId,
      body
    );
    return result;
  } catch (error) {
    console.log("REPO", error);
  }
};

// UpSales.Company.markCompanyAsFavorite(companyId)
export var markUnmarkCompanyAsFavoriteRepository = async (companyId) => {
  try {
    let result = await UpSales.Company.markCompanyAsFavorite(companyId);
    return result;
  } catch (error) {
    console.log(error);
  }
};

// UpSales.Company.pinCompany(companyId)
export var pinUnpinCompanyRepository = async (companyId) => {
  try {
    let result = await UpSales.Company.pinCompany(companyId);
    return result;
  } catch (error) {
    console.log(error);
  }
};

//UpSales.Interaction.createInteraction(companyId, body)
export var createInteractionRepository = async (companyId, body) => {
  try {
    let result = await UpSales.Interaction.createInteraction(companyId, body);
    return result;
  } catch (error) {
    console.log(error);
  }
};

// Interactions - Get documents for all the active interactions from a company
// ----------------------------------------------------------------------------
// getInteractionImagesForACompanyRepository
export const getInteractionImagesForACompanyRepository = async (
  companyId,
  page,
  pageSize
) => {
  try {
    var result = await UpSales.Interaction.getInteractionImagesForACompany(
      companyId,
      page,
      pageSize
    );
    return result;
  } catch (error) {
    console.log(error);
  }
};

export const getInteractionDocsForACompanyRepository = async (
  companyId,
  page,
  pageSize
) => {
  try {
    var result =
      await UpSales.Interaction.getInteractionOtherDocumentsForACompany(
        companyId,
        page,
        pageSize
      );
    return result;
  } catch (error) {
    console.log(error);
  }
};

// let interactions = UpSales.Task.listTasksInTimeIntervalForACompanyFilteredByState(page, pageSize, dateFrom, dateTo, companyId, schedulingState, stateName)
// interactions.then(result => result.next())
// interactions.then(result => result.previous())
export const listTasksForACompanyRepository = async (
  page,
  pageSize,
  dateFrom,
  dateTo,
  companyId,
  schedulingState,
  stateName
) => {
  if (companyId) {
    try {
      var result =
        await UpSales.Task.listTasksInTimeIntervalForACompanyFilteredByState(
          page,
          pageSize,
          dateFrom,
          dateTo,
          companyId,
          schedulingState,
          stateName
        );
      return result;
    } catch (error) {
      console.log(error);
    }
  }
};

//UpSales.
export async function listTasksInTimeIntervalFilteredByStateRepository(
  page,
  pageSize,
  dateFrom,
  dateTo,
  schedulingState,
  stateName
) {
  try {
    let result = await UpSales.Task.listTasksInTimeIntervalFilteredByState(
      page,
      pageSize,
      dateFrom,
      dateTo,
      schedulingState,
      stateName
    );
    return result;
  } catch (error) {
    console.log("REPO: ERROR listTasksInTimeIntervalFilteredByState", error);
  }
}

// UpSales.Task.listTasksInTimeIntervalForAOpportunityFilteredByState(page, pageSize, dateFrom, dateTo, opportunityId, schedulingState, stateName)
export const listTasksForAnOpportunityRepository = async (
  page,
  pageSize,
  dateFrom,
  dateTo,
  opportunityId,
  schedulingState,
  stateName
) => {
  try {
    let result =
      await UpSales.Task.listTasksInTimeIntervalForAOpportunityFilteredByState(
        page,
        pageSize,
        dateFrom,
        dateTo,
        opportunityId,
        schedulingState,
        stateName
      );
    return result;
  } catch (error) {
    console.log("REPO: ERROR listTasksForAnOpportunityRepository", error);
  }
};

// CREATE TASK MODAL.JS - UpSales.Task.createTask(body)
export const createTaskRepository = async (body) => {
  try {
    let result = await UpSales.Task.createTask(body);
    return result;
  } catch (error) {
    console.log("REPO: ERROR > CREATE TASK:", error);
  }
};

// Task - Modify task
// UpSales.Task.updateTask(taskid, body)
export const modifyTaskRepository = async (taskId, body) => {
  try {
    let result = await UpSales.Task.updateTask(taskId, body);
    return result;
  } catch (error) {
    console.log("REPO: ERROR modifyTaskRepository", error);
    throw Error(error);
  }
};

// CREATE TASK MODAL.JS - ADD DOCUMENTS TO TASK
// UpSales.Task.uploadDocuments(companyId, taskId, files)
export const addDocsToTaskRepository = async (companyId, taskId, files) => {
  try {
    let result = await UpSales.Task.uploadDocuments(companyId, taskId, files);
    return result;
  } catch (error) {
    console.log("REPO: ERROR > addDocsToTaskRepository:", error);
  }
};

// Interaction - List active interactions for all companies
// UpSales.Interaction.listInteractionsForAllCompanies(page, pageSize, query, states, userId)
export const listInteractionsForAllCompaniesRepository = async (page,pageSize,query,state,userId = null) => {
  try {
    let result = await UpSales.Interaction.listInteractionsForAllCompanies(page,pageSize,query,state,userId);
    return result;
  } catch (error) {
    console.log(
      "REPO: ERROR > listInteractionsForAllCompaniesRepository:",
      error
    );
  }
};

// LISTADO TAREAS ASOCIADAS A UNA INTERACCIÓN
// let interactions = UpSales.Task.listTasksInTimeIntervalForOneInteractionFilteredByState(page, pageSize, dateFrom, dateTo, companyId, interactionId, schedulingState, states)
export const tasksForAnInteractionRepo = async (
  page,
  pageSize,
  dateFrom,
  dateTo,
  companyId,
  interactionId,
  schedulingState,
  states
) => {
  try {
    let result =
      await UpSales.Task.listTasksInTimeIntervalForOneInteractionFilteredByState(
        page,
        pageSize,
        dateFrom,
        dateTo,
        companyId,
        interactionId,
        schedulingState,
        states
      );
    return result;
  } catch (e) {
    console.log(e);
  }
};

// UpSales.Interaction.getDocument(companyId, interactionId, documentId)
export const getInteractionDocumentsRepo = async (
  companyId,
  interactionId,
  documentId
) => {
  try {
    let result = await UpSales.Interaction.getDocument(
      companyId,
      interactionId,
      documentId
    );
    return result;
  } catch (e) {
    console.log(e);
  }
};

// PRESUPUESTOS
// salesForecastList = UpSales.Finance.listSalesForecast(companyId, page, pageSize, dateFrom, dateTo)
export const getListSalesForecastRepo = async (
  companyId,
  page,
  pageSize,
  dateFrom,
  dateTo
) => {
  try {
    let result = await UpSales.Finance.listSalesForecast(
      companyId,
      page,
      pageSize,
      dateFrom,
      dateTo
    );
    return result;
  } catch (e) {
    console.log(e);
  }
};

// VENTAS
// let sales = UpSales.Finance.listSalesForGraph(companyId, year)
export const listSalesForGraphRepo = async (companyId, year) => {
  try {
    let result = await UpSales.Finance.listSalesForGraph(companyId, year);
    return result;
  } catch (e) {
    console.log(e);
  }
};

// VENTAS: data for modal
// UpSales.Finance.listSales(companyId, page, pageSize, dateFrom, dateTo)
export const listSalesForModalRepo = async (
  companyId,
  page,
  pageSize,
  dateFrom,
  dateTo
) => {
  try {
    let result = await UpSales.Finance.listSales(
      companyId,
      page,
      pageSize,
      dateFrom,
      dateTo
    );
    return result;
  } catch (e) {
    console.log(e);
  }
};

// UpSales.Finance.computeTotalSalesByYear(companyId, year)
export const computeTotalSalesByYearRepo = async (companyId, year) => {
  try {
    let result = await UpSales.Finance.computeTotalSalesByYear(companyId, year);
    return result;
  } catch (e) {
    console.log(e);
  }
};

// UpSales.Finance.computeTotalSalesForecastsByYear(companyId, year)
export const computeTotalSalesForecastsByYearRepo = async (companyId, year) => {
  try {
    let result = await UpSales.Finance.computeTotalSalesForecastsByYear(
      companyId,
      year
    );
    return result;
  } catch (e) {
    console.log(e);
  }
};

// let balances = UpSales.Finance.listBalances(companyId, page, pageSize, dateFrom, dateTo)
export const getBalanceListRepo = async (
  companyId,
  page,
  pageSize,
  dateFrom,
  dateTo
) => {
  try {
    let result = await UpSales.Finance.listBalances(
      companyId,
      page,
      pageSize,
      dateFrom,
      dateTo
    );
    return result;
  } catch (e) {
    console.log(e);
  }
};

// let amount = UpSales.Finance.computeTotalRemainingBalance(companyId)
export const getSaldoVivoRepo = async (companyId) => {
  try {
    let saldoVivo = await UpSales.Finance.computeTotalRemainingBalance(
      companyId
    );
    return saldoVivo.value;
  } catch (error) {
    console.log(error);
  }
};
// let amount = UpSales.Finance.computeOverdueRemainingBalance(companyId, currentDate)
export const getSaldoVencidoRepo = async (companyId, currentDate) => {
  try {
    let saldoVencido = await UpSales.Finance.computeOverdueRemainingBalance(
      companyId,
      currentDate
    );
    return saldoVencido.value;
  } catch (error) {
    console.log(error);
  }
};

// let sales = UpSales.Finance.getDocumentForSale(companyId, saleId)
export const getPdfRepo = async (companyId, saleId) => {
  try {
    let doc = await UpSales.Finance.getDocumentForSale(companyId, saleId);
    return doc;
  } catch (error) {
    console.log(error);
  }
};

// COMPANY STAFF
// UpSales.Company.addStaffMember(companyId, body)
export const createStaffMemberRepo = async (companyId, body) => {
  try {
    let result = await UpSales.Company.addStaffMember(companyId, body);
    return result;
  } catch (error) {
    throw Error(error);
  }
};

//UpSales.Company.listStaffMembers(companyId, page, pageSize, query)
export const getListStaffRepo = async (
  companyId,
  page = 0,
  pageSize = 1000,
  query = ""
) => {
  try {
    let result = await UpSales.Company.listStaffMembers(
      companyId,
      page,
      pageSize,
      query
    );
    return result;
  } catch (error) {
    throw Error(error);
  }
};

//UpSales.Company.modifyStaffMember(companyId, staffId, body)
export const saveEditedStaffMemberRepo = async (companyId, staffId, body) => {
  try {
    let result = await UpSales.Company.modifyStaffMember(
      companyId,
      staffId,
      body
    );
    return result;
  } catch (error) {
    throw Error(error);
  }
};

//UpSales.Company.deleteStaffMember(companyId, staffId)
export const deleteStaffMemberRepo = async (companyId, staffId) => {
  try {
    let result = await UpSales.Company.deleteStaffMember(companyId, staffId);
    return result;
  } catch (error) {
    throw Error(error);
  }
};

//UpSales.Company.listAddresses(companyId, staffId)
export const getOtherlDirectionsRepo = async (
  companyId,
  page = 0,
  pageSize = 1000
) => {
  try {
    let result = await UpSales.Company.listAddresses(companyId, page, pageSize);
    return result.data;
  } catch (error) {
    throw Error(error);
  }
};

//UpSales.Company.addAddress(companyId, body)
export const addOtherDirectionRepo = async (companyId, body) => {
  try {
    let result = await UpSales.Company.addAddress(companyId, body);
    return result;
  } catch (error) {
    throw Error(error);
  }
};

//UpSales.Company.updateAddress(companyId, staffId, body)
export const saveEditedOtherDirectionRepo = async (
  companyId,
  directionId,
  body
) => {
  try {
    let result = await UpSales.Company.updateAddress(
      companyId,
      directionId,
      body
    );
    return result;
  } catch (error) {
    throw Error(error);
  }
};

//UpSales.Company.deleteAddress(companyId, directionId)
export const deleteOtherDirectionRepo = async (companyId, directionId) => {
  try {
    let result = await UpSales.Company.deleteAddress(companyId, directionId);
    return result;
  } catch (error) {
    throw Error(error);
  }
};

//UpSales.Company.modifyFinance(companyId, body)
export const modifyFinanceRepo = async (companyId, body) => {
  try {
    let result = await UpSales.Company.modifyFinance(companyId, body);
    return result;
  } catch (error) {
    throw Error(error);
  }
};

//UpSales.Company.getPaymentMethods(companyId)
export const getListPaymentMethodsRepo = async (companyId) => {
  try {
    let result = await UpSales.Company.getPaymentMethods();
    return result;
  } catch (error) {
    throw Error(error);
  }
};

//UpSales.Company.createPaymentMethod(body)
export const createPaymentMethodRepo = async (body) => {
  try {
    let result = await UpSales.Company.createPaymentMethod(body);
    return result;
  } catch (error) {
    throw Error(error);
  }
};

// UpSales.PostalAddress.listCountries(page, pageSize, query)
export const listCountriesRepo = async (page, pageSize, query) => {
  try {
    let result = await UpSales.PostalAddress.listCountries(page, pageSize, query);
    return result;
  } catch (error) {
    throw Error(error);
  }
};

// UpSales.PostalAddress.listProvincesByCountry(countryId, page, pageSize, query)
export const listProvincesByCountryRepo = async (page, pageSize, query) => {
  try {
    let result = await UpSales.PostalAddress.listProvincesByCountry(page, pageSize, query);
    return result;
  } catch (error) {
    throw Error(error);
  }
};

// UpSales.PostalAddress.listProvinces(page, pageSize, query)
export const listProvincesRepo = async (page, pageSize, query) => {
  try {
    let result = await UpSales.PostalAddress.listProvinces(page, pageSize, query);
    return result;
  } catch (error) {
    throw Error(error);
  }
};

export const listLocalitiesRepo = async (page, pageSize, query, countryId, provinceId) => {
  try {
    let result = await UpSales.PostalAddress.listLocalities(page, pageSize, query, countryId, provinceId);
    return result;
  } catch (error) {
    throw Error(error);
  }
};