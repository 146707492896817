import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import userImgPlaceholder from "../../assets/img/UserAvatarIconPlaceholder.png";
import newClientIcon from "../../assets/img/ic-new-big.png"
import { Bubble } from "react-chartjs-2";

// ShowRow component
 const ShowRow = (props)=> {

    const avatar = (
        <div className="mr-2">
            <img
                alt={props.item.user && props.item.user.username}
                style={{width:25, height:25, borderRadius: 50}}
                src={props.item.user && (props.item.user.profilePhoto ? props.item.user.profilePhoto : userImgPlaceholder)}
            />
        </div>
    );

    const logo = (
        <div className="mr-2">
            <img
                alt={props.item.company && props.item.company.alias}
                style={{width:25, height:25, borderRadius: 50}}
                src={props.item.company && props.item.company.thumbnail}
            />
        </div>
    );

    return (
        <div className="row brd-b-blue py-2 interactionsReportRow">
            <div className="col-3" >

                    {props.item.company ?
                        <Link className="row align-items-center" style={{textDecoration:'none', color:'#757575', lineHeight:'18px'}} title="Ver detalle cliente"  to={{pathname:'/DetallesClientes', state: props.item.company}}>
                            <div className="col-1">{logo}</div>
                            <div className="col d-flex align-items-center">
                                {props.item.company.alias.toUpperCase()}
                                {props.item.isCompanyNew === true && //<img width={32} src={newClientIcon} title="icono nueva empresa"/>}
                                <div title="Cliente creado durante el periodo seleccionado" style={{marginLeft: 10, borderRadius: 7, backgroundColor: '#418ee2', color:'white', padding:3, paddingLeft:6, paddingRight:6, fontSize:'10px', textAlign:'center'}}>NEW</div>    }
                            </div>
                            
                        </Link> :
                        
                        <div className="row align-items-center" style={{lineHeight:'18px'}}>
                            <div className="col-1">{avatar}</div>
                            <div className="col">
                                {props.item.user.username.toUpperCase()}
                            </div>
                        </div>
                    }
            </div>
            <div className="col text-center">{props.item.counts.text}</div>
            <div className="col text-center">{props.item.counts.phone}</div>
            <div className="col text-center">{props.item.counts.email}</div>
            <div className="col text-center">{props.item.counts.photo}</div>
            <div className="col text-center">{props.item.counts.total}</div>
        </div>
    )
 }

// PROPS: list={array}
export default function InteractionsReportList(props) {

    const [clientData, setClientData] = useState([])

    useEffect(()=>{
        
        if (props.list.lenght !==0) {
            setClientData(clientData.concat(props.list))
        } else {
            setClientData([])
        }
    },[props.list])

    let rows = []

    clientData && clientData.length !==0 && 
    clientData.forEach((item) => {
            rows.push(
            <ShowRow item={item} key={item.user ? item.user.id : item.company.id} />
            );
        })
    
    return rows
            
}