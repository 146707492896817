import React, {useState, useEffect} from 'react';
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import imgBigOportunity from '../../assets/img/ic-oportunidad-big.png'
import Media from 'react-bootstrap/Media'

import LoadingAnimation from '../LoadingAnimation'
import { findClientById, createInteraction, archiveInteraction, findAllClientsService } 
  from '../../api_communication/service/ClientService'
import { listOpportunitiesbyNameAndCompany, listOpenOpportunities,listWonOpportunities,listLostOpportunities} 
  from '../../api_communication/service/OpportunityService'

import defaultLogo from '../../assets/img/logoPorDefecto.png'
import opportunityClosedWon from '../../assets/img/ic-op-cerrada-verde.png'
import opportunityClosedLost from '../../assets/img/ic-op-cerrada-rojo.png'
import opportunityOpen from '../../assets/img/ic-op-abierta-gris.png'

import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import { Link } from 'react-router-dom';
import LoadingAnimationMiniSize from '../LoadingAnimationMiniSize'
import btnMas from '../../assets/img/btn-mas.png'
import {listOpportunityInteractionsRepository, } from '../../api_communication/repository/OpportunityRepository'

import {ShowPhotosAndDocsPreview} from '../../componentes/MediaManager'
import FilesDragAndDrop from '../../componentes/FilesDragAndDrop'
import {OpportunityIconState} from '../CommonScripts'

import moment from 'moment'
import UpSalesMessage from '../UpSalesMessage';

const ShowDataListCompanies = (props) => {
  return (
    <React.Fragment>
      <input autoComplete="off" id="companyName" className="my-3" placeholder="Nombre Empresa"
        type="text"
        name="compañias"
        list="compañias"
        value={props.searchTerm}
        onChange={props.onChange}
      >
      </input>
       <datalist id="compañias" >
        {props.list.length !==0 && props.list.map(item => 
            <option key={item.id} data-id={item.id} value={item.alias.toUpperCase()}></option>
        )}
        </datalist>
    </React.Fragment>
  )
}

  class ClientLogo extends React.Component{
    constructor(props){
      super(props)
      this.state = {
        clientLogo: []
      }
    }

    async componentDidMount(){
      try{
      let clientLogo = await findClientById(this.props.client)
      this.setState({
        clientLogo: clientLogo
      })
      } catch(error) {
        console.log(error)
      }
    }

    render(){
        //let showLogo = null
        // if (this.state.clientLogo.thumbnail === null) showLogo = defaultLogo
        // else showLogo = this.state.clientLogo.thumbnail
        //console.log('>>> CLIENT-LOGO: this.state.clientLogo: ', this.state.clientLogo)
        return(
                <div>
                  <Link to={{pathname:'/DetallesClientes', state: this.state.clientLogo}}>
                    {this.state.clientLogo && <img style={{width:40, height:40, borderRadius: 50,}} src={this.state.clientLogo.thumbnail === null ? defaultLogo : this.state.clientLogo.thumbnail}></img>}
                  </Link>
                  
                </div>
        )
    }
}

// PROPS:
// oportunidad={oportunidad}
// key={oportunidad.id}
  class OportunityCol extends React.Component {
    constructor(props){
      super(props);
      this.myRef = React.createRef()
      this.state = {
        data: [],
        image: null,
        nombreCompañia: null,
        lastInteraction: null,
        opportunityLoaded: false,
      }
    }

    async componentDidMount(){
      let date = this.props.oportunidad.lastInteractionDate
      date = date.split(' ')
      this.setState({
        nombreCompañia: this.props.oportunidad.company.alias,
        lastInteraction: date[0],
        opportunityLoaded: true,
      })
      if (this.props.scrollToLastElement) this.myRef.current.scrollIntoView({behavior: 'smooth'})
    }
    

    cortarDescripcion(contenido){
      let leerMas = '...'
      if(contenido.length > 30){
        return(
          contenido.substring(0,40).concat(leerMas)
        )
      } else {
        return(contenido)
      }
    }

    render() {
      const oportunidad = this.props.oportunidad;
      // console.log('OportunityCol: this.props.oportunidad: ', oportunidad)

      
      return (
        <Col xs={12} md={6} xl={3} ref={this.myRef} >
          <Row className=" p-1">
            <Col className="brd-b-blue py-2 oportunity-card-no-border bg-white-hover">
              <Link className="color-link" to={{pathname:'/DetallesOportunidad',state: oportunidad}}>
                  <Row className="py-2 brd-b-blue">
                    <Col xs={3}><ClientLogo client={oportunidad.companyId}/></Col>
                    <Col xs={9} className="dark-blue l-fs-12 align-self-center font-weight-bolder light-grey">{this.state.nombreCompañia}</Col>
                    {this.state.lastInteraction && <p className="text-right light-grey fecha"><span className="l-fs-10">{this.state.lastInteraction}</span></p>}
                  </Row>
                  <Row className="py-3">
                    <Col className="max-ic-op" xs={2}>
                      <OpportunityIconState opportunity={oportunidad}/>
                    </Col>
                    {/* { this.state.opportunityLoaded && 
                    <Col xs={3} className="d-center h-100"><img className="ml-2 max-ic-op" src={this.state.image} alt={`${oportunidad.state}`}></img></Col>} */}
                    { this.state.opportunityLoaded && oportunidad.name && 
                    <Col title={oportunidad.description} xs={9} className=" align-self-center"><div className="l-fs-12 mb-1">{oportunidad.name}</div>
                    {oportunidad.description && <p className="l-fs-11 light-grey cursor-help">{this.cortarDescripcion(oportunidad.description)}</p>}</Col>}
                    { !this.state.opportunityLoaded && 
                    <Col className="d-center" style={{height: 60}}>
                      <LoadingAnimationMiniSize/>
                    </Col>
                    }
                  </Row>
              </Link>
            </Col>
          </Row>
        </Col>
      );
    }
  }
  
  // PROPS:
  // oportunidades={this.state.listaOportunidades}
  // filterText={this.state.filterText}
  // handleUserIsSearching={this.handleUserIsSearching}
  class OportunityTable extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
          // clientName:[],
          lettersNumber: null,
          newList: [],
          nextNewList: [],
          nothingFound: false,
          paginationCount: 0,
          loadingMoreCompaniesFiltered: false,
          searchFinished: true
        };
        this.handleUserIsSearching = props.handleUserIsSearching
    }

    componentDidUpdate(prevProps){
      if(this.props.filterText != prevProps.filterText) {
        //console.log('DID-UPDATE:OportunityTable: this.props.filter: ', this.props.filter)
        this.setState({newList: [],nextNewList: [],paginationCount: 0})
        this.state.searchFinished && this.searchClient(this.props.filterText, 0)
        if(this.props.filterText === null || this.props.filterText ===''){
          this.handleUserIsSearching(false)
        } else {
          this.handleUserIsSearching(true)
        }
      }
    }

    searchClient = async (letters, paginationCount = 0) => {
      
      const LISTMAXLENGHT = 1000
      // if (this.props.filterText === null || this.props.filterText ==='') this.handleUserIsSearching(false)
      // if (this.props.filterText !== null || this.props.filterText !=='') this.handleUserIsSearching(true)
      if (this.props.filterText.length > 1) {
        this.setState({ loadingMoreCompaniesFiltered: true });
        this.setState({ searchFinished: false });
          try{
            //console.log('>>> searchClient: letters: ', letters)
            //console.log('>>> searchClient: this.props.filterText: ', this.props.filterText)
            let result = await listOpportunitiesbyNameAndCompany(this.props.selectedOption, paginationCount, LISTMAXLENGHT, letters)
            //console.log('>>> searchClient: result: ', result)
            let resultTemp = []
            result.map((item) => {
              if (item.name.toLowerCase().indexOf(this.props.filterText.toLowerCase()) !== -1)
                {resultTemp.push(item);}
              })
            if (resultTemp.length !==0) {
              result = resultTemp
            } else {
              result = []
            }
            //console.log('>>> searchClient: resultTemp: ', resultTemp)

            let nextResult = await listOpportunitiesbyNameAndCompany(this.props.selectedOption, paginationCount+1, LISTMAXLENGHT, letters)
            //console.log('>>> searchClient: nextResult: ', nextResult)
            let nextTemp = []
            nextResult.map((item) => {
              if (item.name.toLowerCase().indexOf(this.props.filterText.toLowerCase()) !== -1)
                {nextTemp.push(item);}
              })
            if (nextTemp.length !==0) {
              nextResult = nextTemp
            } else {
              nextResult = []
            }
            //console.log('>>> searchClient: nextTemp: ', nextTemp)
            //console.log('>>> searchClient: result: ', result)
            this.setState({searchFinished: true})
            this.setState({loadingMoreCompaniesFiltered: false})
            if(result.length !== 0){ 
                  //console.log('>>> searchClient: this.state.newList: ', this.state.newList)
                  //console.log('>>> searchClient: this.state.newList.concat(result): ', this.state.newList.concat(result))
                  this.setState({
                    newList: this.state.newList.concat(result),
                    nothingFound: false,
                    nextNewList: nextResult
                  });
            } else {
              this.setState({
                nothingFound: true,
                newList:[],
                nextNewList: [],
              })
              //console.log('>>> result === null: result: ', result)
            }
          }catch(error){
            console.log(error)
          }
      } else {
        if (this.props.filterText.length === 0) {
          // this.handleUserIsSearching(false)
          this.setState({
            newList: [],
            nextNewList:[],
            paginationCount: 0,
            nothingFound: false
          });
          this.setState({loadingMoreCompaniesFiltered: false})
        }
      }
    }

    moreCompaniesFiltered = () => {
      this.searchClient(this.props.filterText, this.state.paginationCount + 1)
      this.setState({paginationCount: this.state.paginationCount +1})
    }

    render() {
      const filterText = this.props.filterText;
      const cols = [];
      //console.log('---------------')
      // console.log('OportunityTABLE: props.filertText state: ', this.props.filterText)
      // console.log('OportunityTABLE: lettersNumber state: ', this.state.lettersNumber)
      // console.log('OportunityTABLE: haveWeFoundIt state: ', this.state.haveWeFoundIt)
      // console.log('OportunityTABLE: companiesFound state: ', this.state.companiesFound)
      let filteredNewList = [];
      //console.log('RENDER: newList: ', this.state.newList)
      this.state.newList.map((item) => {
        //console.log('RENDER: filterText: ', filterText)
        if (item.name.toLowerCase().indexOf(filterText.toLowerCase()) !== -1)
          {filteredNewList.push(item);
            //console.log('>> RENDER: filteredNewList.push(item): ', item)
          }
      });
      this.props.filterText && 
      this.props.filterText.length > 1 && 
      filteredNewList.length !== 0 && 
      filteredNewList.forEach(oportunidad =>{
              cols.push(<OportunityCol 
                oportunidad={oportunidad}
                indice={oportunidad.id}
                key={oportunidad.id}/>
            );
     })

     //if (this.props.filterText && this.props.filterText.length == 1) this.setState({loadingMoreCompaniesFiltered: false})

      this.props.oportunidades !== null && 
      (this.props.filterText.length === null || this.props.filterText.length === 0)  && 
      this.props.oportunidades.forEach((oportunidad) => {
          cols.push(<OportunityCol 
              oportunidad={oportunidad}
              indice={oportunidad.id}
              key={oportunidad.id}
              scrollToLastElement={this.props.scrollToLastElement}/>
          );
        })

      //  searchingMsg: ArrayCompaniesFound ? 'Buscando entre: ' + this.getNamesFromArrayOfCompaniesFoundForMessage(ArrayCompaniesFound) + '...' : null
      return (
        <>
          <Row className="d-flex justify-content-center">
            {this.state.loadingMoreCompaniesFiltered && <Col xs={12}>{<LoadingAnimationMiniSize></LoadingAnimationMiniSize>}</Col> }
            {this.state.nothingFound &&  <div><p className='light-grey'>No hay resultados de la búsqueda.</p></div>}
            {!this.state.nothingFound && cols}
            {!this.state.nothingFound && 
              this.props.filterText && 
              this.props.filterText.length > 1 && 
              this.state.nextNewList.length !== 0 && 
              <div className="btn-mas-container-blue flex-column col-12 py-4">
                {!this.state.loadingMoreCompaniesFiltered && <div onClick={this.moreCompaniesFiltered}><img src={btnMas}></img></div>}
              </div>
            }
          </Row>
          {this.props.filterText && 
      this.props.filterText.length > 1 && 
      filteredNewList.length !== 0 && this.state.nextNewList.length ===0 &&  <div className='d-center pt-5 light-grey'><p>No hay más resultados de la búsqueda.</p></div>}
        </>
      );
    }
  }
  
  class SearchBar extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        upsalesMsgLevel:null,
        upsalesMsgMessage:null,
        upsalesMsgVisible:false,
        upsalesMsgAutoclose:false,
        selectedOption: 'OPEN'
      }
      this.handleFilterTextChange = this.handleFilterTextChange.bind(this);
      //this.onChangeValue = this.onChangeValue.bind(this);
      this.refresh = this.props.refresh
    }
    
    componentDidMount(){
      this.setState({
        selectedOption: this.props.selectedOption ? this.props.selectedOption : this.state.selectedOption
      })
      document.getElementById('oportunityInput').focus()
    }

    handleFilterTextChange(e) {
      this.props.onFilterTextChange(e.target.value);
    }

    closeMessage = () => {
      this.setState({
        upsalesMsgLevel: null,
        upsalesMsgMessage: null,
        upsalesMsgVisible: false,
        upsalesMsgAutoclose: false
      })
    }

    showUpSalesMessage = (level, msg, visible, autoclose) => {
      this.setState({
        upsalesMsgLevel: level,
        upsalesMsgMessage: msg,
        upsalesMsgVisible: visible,
        upsalesMsgAutoclose: autoclose
      })
    }

    onValueChange = (event) => {
      this.setState({
        selectedOption: event.target.value
      });
      this.props.onStateFilterChange(event.target.value)
    }
    
    render() {
      return (
        <Row className="bg-blue-light p-1 pb-4">
          <UpSalesMessage
            level={this.state.upsalesMsgLevel} 
            message={this.state.upsalesMsgMessage}
            visible={this.state.upsalesMsgVisible} 
            autoClose={this.state.upsalesMsgAutoclose}
            closeMessage={this.closeMessage}
          />
          <Col xs={12} className="align-items-center">
            <Row className='no-gutters '>
              <Col className='col-9 d-flex flex-row'>
                <Form onSubmit={e => { e.preventDefault(); }} className="d-flex flex-row justify-content-between align-items-center bg-white py-2 px-3">
                  <input
                    id="oportunityInput"
                    style={{width:300}}
                    type="text"
                    placeholder="Filtrar por: Nombre Oportunidad"
                    value={this.props.filterText}
                    onChange={this.handleFilterTextChange}
                    />
                </Form>
                <Row className='ml-5 bg-white py-2'>
                    <Col className='d-flex flex-row justify-content-end align-items-center light-grey'>
                      <p className='pr-5'>Ver:</p>
                      <div className="radio mr-5 d-flex flex-row align-items-center">
                        <label className='mouse d-flex flex-row align-items-center' title="Ver oportunidades Abiertas" style={{marginBottom:0}}>
                          <input
                            className='mouse'
                            style={{marginRight: 10}}
                            type="radio"
                            value="OPEN"
                            checked={this.state.selectedOption === "OPEN"}
                            onChange={this.onValueChange}
                          />
                          
                          <img src={opportunityOpen} style={{width:22, marginRight:3}}/>
                         Abiertas
                        </label>
                      </div>
                      <div className="radio mr-5">
                        <label className='mouse d-flex flex-row align-items-center' title="Ver oportunidades Cerradas Ganadas" style={{marginBottom:0}}>
                          <input
                            className='mouse'
                            style={{marginRight: 10}}
                            type="radio"
                            value="CLOSED-WON"
                            checked={this.state.selectedOption === "CLOSED-WON"}
                            onChange={this.onValueChange}
                          />
                          <img src={opportunityClosedWon} style={{width:19, marginRight:3}}/>
                          Ganadas
                        </label>
                      </div>
                      <div className="radio">
                        <label className='mouse d-flex flex-row align-items-center' title="Ver oportunidades Cerradas Perdidas" style={{marginBottom:0}}>
                          <input
                            className='mouse'
                            style={{marginRight: 10}}
                            type="radio"
                            value="CLOSED-LOST"
                            checked={this.state.selectedOption === "CLOSED-LOST"}
                            onChange={this.onValueChange}
                          />
                          <img src={opportunityClosedLost} style={{width:19, marginRight:3}}/>
                          Perdidas
                        </label>
                      </div>
                    </Col>
                  </Row>
              </Col>
              <Col className="justify-content-end d-center w-100">
                <CreateOpportunity
                  showUpSalesMessageFn={this.showUpSalesMessage}
                  closeMessage={this.closeMessage} 
                  refresh={this.refresh}/>
              </Col>
            </Row>
          </Col>
        </Row>
      );
    }
  }

  
// TO DO remover este createOpportunity y aplicar componente CreateOpportunity.js
// PROPS:
//showUpSalesMessageFn={this.showUpSalesMessage}
//closeMessage={this.closeMessage} 
//refresh={this.refresh}
  function CreateOpportunity(props) {
    const [show, setShow] = useState(false);
    const [searchTerm, setSearchTerm] = useState('')
    const [searchResults, setSearchResults] = useState([])
    const [idCompany, setIdCompany] = useState(null)
    const [opportunityObject, setOpportunityObject] = useState(null)

    // States to save and preview docs and images
    const [preview, setPreview] = useState(false)
    const [imageOrDocToShow, setImageOrDocToShow] = useState(null)
    const [saveImagesAndDocs, setSaveImagesAndDocs] = useState(null)
    const [interaction, setInteraction] = useState(null)

    const [savingAnimation, setSavingAnimation] = useState(false)

    useEffect(()=>{
    }, [])

    //SearchTerm

    const userChooseCompany = async (word) => {
      try {
        //setLoadingAnimation(true)
        setSearchResults([])
        const companies = await findAllClientsService(0,100, word)
        //console.log('userChooseCompany: findAllClients: companies: ', companies)
        setSearchResults(companies)
        // setSearchResults(
        //   companies && companies.map(company => {
        //     return company
        //   })
        // )
        //setLoadingAnimation(false)
      } catch(error){
        console.log(error)
      }
    }

    const handleChange = async () => {
      let myId
      let inputUserCompanyName= document.getElementById("companyName").value.toUpperCase();
      let userSelectedCompanyName= document.querySelector("#compañias"  + " option[value='" + inputUserCompanyName+ "']")
      setSearchTerm(inputUserCompanyName)
      if (inputUserCompanyName.length > 2) {
        await userChooseCompany(inputUserCompanyName)
        if (inputUserCompanyName === '' &&  !userSelectedCompanyName) {
          setIdCompany(null)
        } 
          let companyIdSelected = null
          if (userSelectedCompanyName) companyIdSelected = userSelectedCompanyName.dataset.id
          if(companyIdSelected != undefined){
            try{
              await setIdCompany(companyIdSelected)
            } catch(error){
              console.log(error)
            }
          } else{
            setIdCompany(null)
          }
      } else {
        setSearchResults([])
      }
      
    }

    const createOpportunity = async () => {
      var opportunityName = document.getElementById("opportunityName").value
      var opportunityDescription = document.getElementById("opportunityDescription").value
      if(opportunityName == ''){
        props.showUpSalesMessageFn('warning', "Debe añadir un nombre para la oportunidad", true, true)
        return false
      } else {
        if(idCompany == null){
          props.showUpSalesMessageFn('warning', "Debe seleccionar de la lista un nombre de compañía existente", true, true)
        } else {
          setSavingAnimation(true)
          let interactionData = {
            date: moment().format("DD/MM/YYYY") + ' ' +  moment().format("HH:mm:ss"),
            content: 'Oportunidad Creada',
            mode: "text",
            opportunity:{
              name: opportunityName,
              description: opportunityDescription ? opportunityDescription : null,
              state: 'OPEN'
            }
          }
          // save interaction
          try {
            let result = await createInteraction(idCompany, interactionData)
            if (result.error) {
              setSavingAnimation(false)
              props.showUpSalesMessageFn('danger', "Ha ocurrido un error al crear la oportunidad.", true, false)
            } else {
              setOpportunityObject(result.opportunity)
              setInteraction(result)
              if(!imageOrDocToShow){await archiveInteraction(result.companyId, result.id)}
              if(imageOrDocToShow) setSaveImagesAndDocs(true);
              props.showUpSalesMessageFn('success', "Oportunidad creada correctamente.", true, true)
              setTimeout(() =>{
                setSavingAnimation(false)
                props.refresh()
                document.getElementById('linkToOpportunityDetails').click()
              }, 3000);
            }
          } catch (error) {
            props.showUpSalesMessageFn('danger', "Ha ocurrido un error al crear la oportunidad.", true, false)
            console.log('ERROR CREATE OPPORTUNITY ERROR: ', error)
          }
        
        }
      }
    }

    const callToShowImageOrDoc = e => {
      setImageOrDocToShow(e)
      setPreview(true)
    }

    const cancelForm =  () => {
      //resetear formulario
      var valor = document.getElementById("companyName").value = ""
      setSearchTerm('')
      setShow(false)
    }

    return (
      <React.Fragment>
        <Button className="modal-big-button" variant="primary" onClick={() => setShow(true)}>
          Crear Oportunidad
        </Button>
       

        <Modal
          onEntered={()=>document.getElementById('companyName').focus()}
          id="modalPositionRight"
          show={show}
          onHide={() => setShow(false)}
          dialogClassName="bg-white"
          aria-labelledby="example-custom-modal-styling-title"
          centered
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header className="justify-content-center modal-border ">
            <Modal.Title id="example-custom-modal-styling-title" className="dark-blue d-column">
              <b className="mb-4 text-center">
                CREAR UNA OPORTUNIDAD
              </b>
              <Media><img src={imgBigOportunity}></img></Media>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body >
            <div className="d-column generaltextInputStyle">
            <ShowDataListCompanies 
                  list={searchResults} 
                  searchTerm={searchTerm}
                  onChange={handleChange}
            />

              <input type="text" className="my-3" id="opportunityName" placeholder="Nombre Oportunidad"></input>

              <textarea className="mb-3" id="opportunityDescription" type="text"  placeholder="Descripción"></textarea>

              <div className='py-3'>
                <FilesDragAndDrop fileSelected={callToShowImageOrDoc}/>
              </div>

              {preview && imageOrDocToShow && <div id="preview" className="w-100 py-3">
                <ShowPhotosAndDocsPreview isItATask={false} imageOrDocToShow={imageOrDocToShow} saveImagesAndDocs={saveImagesAndDocs} idCompany={idCompany} idTaskOrInteraction={interaction ? interaction.id : null}/>
              </div>}
              
              <div className="d-inline-flex my-5">
                  <div className="d-center boton-cancelar mr-3" onClick={cancelForm}>Cancelar</div>
                  <button disabled={savingAnimation ? true : false} className="d-center boton-guardar" onClick={createOpportunity}>
                    {savingAnimation && <span className="spinner-grow spinner-grow-sm mr-2" role="status" aria-hidden="true"></span>}
                    Guardar
                  </button>
              </div>
              
              <Link id="linkToOpportunityDetails" className="row" to={{pathname:'/DetallesOportunidad',state: opportunityObject}}/>

            </div>
          </Modal.Body>
        </Modal>
       
      </React.Fragment>
    );
  }

export default class FilterableOportunityTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filterText: '',
      listaOportunidades: [],
      paginationCount: 0, // contador para llamar a backend (info paginada)
      loadingAnimation: true,
      loadingMoreCompanies: false, //if true muestra pequeño loading animation en lugar del btn +
      showBtnMoreCompanies: true, // si de backend no hay mas datos se oculta btn + 
      noMoreDataAvailable: false,
      userIsSearching: false,
      searchingMsg:null,
      opportunityStateFilter: 'OPEN',
      pageSize: 12,
      scrollToLastElement: false
    };
    
    this.handleFilterTextChange = this.handleFilterTextChange.bind(this);
    this.handleOpportunityStateFilter = this.handleOpportunityStateFilter.bind(this);
  }

  handleOpportunityStateFilter(stateFilter) {
    //console.log('handleOpportunityStateFilter: stateFilter: ', stateFilter)
    this.setState({
      opportunityStateFilter: stateFilter,
      listaOportunidades: [],
      paginationCount:0,
      noMoreDataAvailable:false,
      loadingMoreCompanies: true,
      showBtnMoreCompanies: true,
      userIsSearching:false,
      filterText:'',
    })
    setTimeout(() => {
      this.cargaInicialListaOportunidades(0)
    }, 1000);
  }

  handleFilterTextChange(filterText) {
    this.setState({
      filterText: filterText
    });
  }

  handleUserIsSearching = (trueOrFalse) => {
    //console.log('>>> PARENT: handleUserIsSearching: ', trueOrFalse)
    this.setState({
      userIsSearching: trueOrFalse,
    })
  }

  componentDidMount() {
    this.cargaInicialListaOportunidades(this.state.paginationCount)
  }

  refreshMe = async() => {
    this.setState({
      paginationCount:0
    })

    this.cargaInicialListaOportunidades(this.state.paginationCount)
  }

  cargaInicialListaOportunidades = async (paginationCount)=> {
    let listadoPaginadoO = []
    let listadoPaginadoSiguiente = []
    try{
        if (this.state.opportunityStateFilter === 'OPEN'){
          listadoPaginadoO = await listOpenOpportunities(paginationCount, this.state.pageSize)
          listadoPaginadoSiguiente = await listOpenOpportunities(paginationCount + 1, this.state.pageSize) 
        }
        if (this.state.opportunityStateFilter === 'CLOSED-WON'){
          listadoPaginadoO = await listWonOpportunities(paginationCount, this.state.pageSize)
          listadoPaginadoSiguiente = await listWonOpportunities(paginationCount + 1, this.state.pageSize) 
        }
        if (this.state.opportunityStateFilter === 'CLOSED-LOST'){
          listadoPaginadoO = await listLostOpportunities(paginationCount, this.state.pageSize)
          listadoPaginadoSiguiente = await listLostOpportunities(paginationCount + 1, this.state.pageSize) 
        }
        // console.log('FilterableOportunitTable: INICIAL listadoPaginado0: ', listadoPaginadoO)
        // console.log('FilterableOportunitTable: INICIAL listadoPaginadoSiguiente: ', listadoPaginadoSiguiente)

        if ((listadoPaginadoO === null || (listadoPaginadoO && listadoPaginadoO.length ==0))) {
          this.setState({showBtnMoreCompanies: false, noMoreDataAvailable: true})
        }

        if ((listadoPaginadoSiguiente === null || (listadoPaginadoSiguiente && listadoPaginadoSiguiente.length ==0))) {
            this.setState({showBtnMoreCompanies: false, noMoreDataAvailable: true}) // quitamos btn + y mostramos mensajes
        }
        this.setState({ 
            listaOportunidades: listadoPaginadoO,
            loadingAnimation: false,
            loadingMoreCompanies:false
        })
        
    } catch(error) {
        console.log(error)
    }
  }


  cargaListaOportunidades = async (paginationCount)=> {
    let finalResult = []
    let listadoPaginado = []
    let listadoPaginadoSiguiente = []
    try{
      if (this.state.opportunityStateFilter === 'OPEN'){
        listadoPaginado = await listOpenOpportunities(paginationCount, this.state.pageSize)
        listadoPaginadoSiguiente = await listOpenOpportunities(paginationCount + 1, this.state.pageSize)
      }
      if (this.state.opportunityStateFilter === 'CLOSED-WON'){
        listadoPaginado = await listWonOpportunities(paginationCount, this.state.pageSize)
        listadoPaginadoSiguiente = await listWonOpportunities(paginationCount + 1, this.state.pageSize)
      }
      if (this.state.opportunityStateFilter === 'CLOSED-LOST'){
        listadoPaginado = await listLostOpportunities(paginationCount, this.state.pageSize)
        listadoPaginadoSiguiente = await listLostOpportunities(paginationCount + 1, this.state.pageSize)
      }
      
      //console.log('FilterableOportunitTable: MORE listadoPaginado0: ', listadoPaginado)
      //console.log('FilterableOportunitTable: MORE listadoPaginadoSiguiente: ', listadoPaginadoSiguiente)

      let listadoInicial=this.state.listaOportunidades // siempre tendrá algo cuando entramos en esta función

        if (listadoPaginado !== null) {
            finalResult = listadoInicial.concat(listadoPaginado) // 'sumamos' lista anterior con la nueva de backend
            if (listadoPaginadoSiguiente.length === 0) {
                this.setState({showBtnMoreCompanies: false, noMoreDataAvailable: true})
            }
        } 

        if (listadoPaginado.length === 0) {
            finalResult = listadoInicial;
            this.setState({showBtnMoreCompanies: false, noMoreDataAvailable: true})
        }
        
        this.setState({
            listaOportunidades: finalResult, 
            loadingAnimation: false,
            loadingMoreCompanies:false,
        })
        
    } catch(error) {
        console.log(error)
    }
  }

  moreOpportunities = (e)=>{
    let newPaginationCount = this.state.paginationCount + 1
    e.preventDefault();
    this.setState({paginationCount: newPaginationCount, loadingMoreCompanies: true, scrollToLastElement: true})
    this.cargaListaOportunidades(newPaginationCount)
  }
  
  render() {
    if(this.state.loadingAnimation == false){
        return (
          <Col className="mx-3">
            <SearchBar
              refresh = {this.refreshMe}
              filterText={this.state.filterText}
              onFilterTextChange={this.handleFilterTextChange}
              onStateFilterChange={this.handleOpportunityStateFilter}
              selectedOption={this.state.opportunityStateFilter}
              />
            <OportunityTable
              oportunidades={this.state.listaOportunidades}
              filterText={this.state.filterText}
              handleUserIsSearching={this.handleUserIsSearching}
              selectedOption={this.state.opportunityStateFilter}
              scrollToLastElement={this.state.scrollToLastElement}
              //searchingMessage={this.state.searchingMsg ? this.state.searchingMsg : null}
              />
                {this.state.showBtnMoreCompanies && this.state.userIsSearching === false && 
                <Row>
                    <Col className="btn-mas-container-blue flex-column">
                        {this.state.loadingMoreCompanies && <LoadingAnimationMiniSize></LoadingAnimationMiniSize> }
                        {!this.state.loadingMoreCompanies && <a href="#" onClick={this.moreOpportunities}>
                            <img src={btnMas}></img>
                        </a>}
                    </Col>
                </Row>}
                {this.state.listaOportunidades.length !== 0 && this.state.noMoreDataAvailable  && !this.state.userIsSearching && 
                <Row>
                    <Col className="col-12  d-center py-5">
                        <p className="light-grey">No hay más oportunidades para mostrar.</p>
                    </Col>    
                </Row>}
                {this.state.listaOportunidades.length === 0 && !this.state.loadingMoreCompanies &&
                <Row>
                    <Col className="col-12  d-center py-5 ">
                        <p className="light-grey">Aún no hay oportunidades para mostrar.</p>
                    </Col>    
                </Row>}
          </Col>
        )
  } else return <Col>
  <LoadingAnimation/>
  </Col>
}}