import React, { useEffect, useState } from "react";

// PROPS: setValue={Function} - To return countryId selected by user to parent

// COMPONENTES
import { DynamicSelect } from "./DynamicSelect";

// SERVICE
import { listCountries } from "../../api_communication/service/ClientService";

// *****************************************************************
// PROPS CountryDynamicSelect  
//  setValue={function handleCountry} - in parent to receive country choosen by user in this component
// *****************************************************************
// PROPS DynamicSelect:
// optionsArray={} - array of values for show in options 
// searchTerm={} - returns user writing search word ->¿?
// onChange={handleChange} - Funcition to return user search word
// resetSearch={true or false} - prop: if true reset input value
// inputId={} - string - input id
// inputPlaceholder={} - string 'Buscar por X', 'Nombre de empresa', etc...
// ******************************************************************

export function CountryDynamicSelect(props) {

    const [array, setArray] = useState([])
    const [userInput, setUserInput] = useState(null)

    useEffect(() => {
        // console.log("COUNTRY, User Input :: ", userInput)
        loadCountries(userInput)
    },[userInput])

    const handleUserInput = async (input, userSelectId) => {
        try {
            if (userSelectId === null) {
                
                if (input && input.length > 2)
                    setUserInput(input)
                else {
                    setUserInput(null)
                    props.setValue(null)
                }

            } else {
                props.setValue(userSelectId)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const loadCountries = async (query) =>{
        try {
            let countries = await listCountries(0, 250, query)
            setArray(countries)
        } catch (error) {
            console.log(error)  
        }
    }

    return (
        <React.Fragment>
          <DynamicSelect 
            optionsArray={array} 
            onChange={handleUserInput} 
            resetSearch={null} 
            inputId={'countryFilter'} 
            inputPlaceholder={'País'}/>
        </React.Fragment>
      )
}