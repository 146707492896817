import { x as UpSales } from "../bundle/config";

export const signinRepository = async (username, password) => {
  //console.log('AUTH REPO : signin username: ', username, ' - pass: ', password)

  try {
    let response = await UpSales.Auth.signin(username, password);
    //console.log('AUTH REPO : signin: response: ', response)
    return response;
  } catch (error) {
    // console.log("ERROR AUTH-REPO: signin: ", error);
  }
};

export const signoutRepository = async () => {

  try {
    let response = await UpSales.Auth.signout();
    return response;
  } catch (error) {
    // console.log("ERROR AUTH-REPO: signout: ", error);
  }
};
