import React, {useState, useEffect} from 'react'
import Row from 'react-bootstrap/Row'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Media from 'react-bootstrap/Media'
// IMAGES
import imgBigOportunity from '../assets/img/ic-oportunidad-big.png'
import imgModeText from '../assets/img/ic-interaccion-blue.png'
import imgModePhone from '../assets/img/ic-tel-peq-azul.png'
import imgModeEmail from '../assets/img/ic-correo-blue.png'
import imgModePhoto from '../assets/img/ic-cam-blue.png'
import icProyectos from '../assets/img/ic-proyecto-nuevo-big.png'
// COMPONENTS
import {ShowPhotosAndDocsPreview} from '../componentes/MediaManager'
import { OpportunityIconState } from './CommonScripts'
import ProjectStateIcon from './proyectos/ProjectStateIcon'
import FilesDragAndDrop from '../componentes/FilesDragAndDrop'
// EXTERNAL LIBRARIES
import moment from 'moment'
// PROJECTS FN
import {projectCreateInteraction} from "./proyectos/funcionesProyectos"
// SERVICE FN
import { createInteraction } from '../api_communication/service/ClientService';
import { listOpenOpportunitiesForClient } from '../api_communication/service/OpportunityService';


// FROM PARENT
// ------------------------------------------------------------------------------------
// <CreateInteraction 
//    client={client} // For client pages
//    project={project} // For project pages // added by Pablo Collado May 2022
//    opportunity={opportunity} // For opportunity and interaction pages
//    idCompany={idCompany} // 
//    showUpSalesMessageFn={this.showUpSalesMessage}
//    refresh={refreshInteractions} />
// ------------------------------------------------------------------------------------

export default function CreateInteraction(props) {
   
    const [show, setShow] = useState(false);
    const [idCompany, setIdCompany] = useState(null)
    const [project, setProject] = useState(null)
    const [opportunities, setOpportunities] = useState([])
    const [opportunity, setOpportunity] = useState(null)
    const [preview, setPreview] = useState(false)
    const [imageOrDocToShow, setimageOrDocToShow] = useState(null)
    const [modeIcon, setModeIcon] = useState('text')
   
    const [saveImagesAndDocs, setSaveImagesAndDocs] = useState(null)
    const [interaction, setInteraction] = useState(null)
    const [savingAnimation, setSavingAnimation] = useState(false)

    useEffect(() => {
      props.project && setProject(props.project)
      props.idCompany && setIdCompany(props.idCompany)
      props.client && setIdCompany(props.client.id)
      props.client && callOpportunities(props.client.id)
      props.opportunity && setOpportunity(props.opportunity)
    },[]);

    const saveInteraction = async () => {
      let interactionContent = document.getElementById("interactionForm").value
      if (!interactionContent || interactionContent == '') {
        props.showUpSalesMessageFn('danger','Debe añadir un comentario para la interacción', true, false)
      } else {
      
        let interactionData = {
          date: moment().format('DD/MM/YYYY HH:mm:ss'),
          content: interactionContent,
          mode: modeIcon,
        }

        try {
          let savedInteraction
          setSavingAnimation(true)

          if (props.project) {
            savedInteraction = await projectCreateInteraction(project.id, interactionData)
            if (!savedInteraction) props.showUpSalesMessageFn('danger','Error al crear la interacción.', true, false)

          } else {

            // Get opportunity from select (clients)
            let selectedOpportunity = document.getElementById("oportunidadSeleccionada")

            if (opportunity)
              interactionData.opportunity = { id: opportunity.id }
            else if (selectedOpportunity && selectedOpportunity.value !== 'whitoutOpportunity')
              interactionData.opportunity = { id: selectedOpportunity.value }

            savedInteraction = await createInteraction(idCompany, interactionData)
            if (!savedInteraction) props.showUpSalesMessageFn('danger','Error al crear la interacción.', true, false)
          }

          setInteraction(savedInteraction)
          setIdCompany(savedInteraction.companyId)
          
          setTimeout(() => {
            if (savedInteraction && imageOrDocToShow) {
              setSaveImagesAndDocs(true)
            } else {
              uploadingFinished(true)
            }
          }, 1000);

        } catch (error) {
          // console.log('ERROR CREATE INTERACTION ERROR: ', error)
          props.showUpSalesMessageFn('danger','Error al crear la interacción.', true, false)
        }

      }
    }

    async function callOpportunities(idClient) {
        try{
          let opportunities = await listOpenOpportunitiesForClient(idClient, 0, 1000)
          setOpportunities(opportunities)
        } catch(error){
          console.log(error)
        }
    }

    const cancelForm =  () => {
      setShow(false)
      setOpportunity(null)
      setPreview(false)
      setimageOrDocToShow(null)
      setSaveImagesAndDocs(false)
      setInteraction(null)
      setModeIcon('text')
    }

    const callToShowImageOrDoc = e => {
        setimageOrDocToShow(e)
        setPreview(true)
    }

    const uploadingFinished = (value)=>{
      if(value) {
        props.showUpSalesMessageFn('success','Interacción creada. Refrescando...', true, true)
        setTimeout(() => {
          cancelForm() 
          props.refresh()
        }, 1500);
      } else {
          props.showUpSalesMessage('danger', 'Ha ocurrido un error guardando archivos.', true, false)
      }
      setSavingAnimation(false)
    }

    return (
      <React.Fragment>
        <Button title="Crear una interacción" className="modal-small-button d-center" variant="primary" onClick={() => setShow(true)}>
          +
        </Button>

        <Modal
          onEntered={()=>document.getElementById('interactionForm').focus()}
          id="modalPositionRight"
          show={show}
          onHide={() => setShow(false)}
          dialogClassName="bg-white"
          aria-labelledby="example-custom-modal-styling-title"
          centered
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header className="justify-content-center modal-border">
            <Modal.Title id="example-custom-modal-styling-title" className="dark-blue d-column">
              <b className="mb-4 text-center">
                CREAR UNA INTERACCIÓN
              </b>
              <Media><img src={props.project ? icProyectos: imgBigOportunity}></img></Media>
              {((opportunity && opportunity.name) || project) && <div>
                {opportunity && <OpportunityIconState opportunity={props.opportunity}/>}
                {project && <ProjectStateIcon projectState={props.project.state}/>}
                <span className="l-fs-18 mb-5 text-center mr-2">{opportunity && opportunity.name ? opportunity.name : project.name}</span>
              </div>}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body >
            <div className="d-column generaltextInputStyle">
            <Row className="d-flex flex-row justify-content-center align-items-center">
                <div>
                    <span className="light-grey pr-3 l-fs-14">Seleccionar: </span>
                </div>
                <div onClick={()=> setModeIcon('text')}>
                    <img className={`${modeIcon == 'text' ? 'icon-mode-style-selected' : ''} icon-mode-style `} 
                      src={imgModeText} alt="Icono Texto" title="Icono Texto"/>
                </div>
                <div onClick={()=> setModeIcon('phone')}>
                    <img className={`${modeIcon == 'phone' ? 'icon-mode-style-selected' : ''} icon-mode-style `} 
                      src={imgModePhone} alt="Icono Teléfono" title="Icono Teléfono"/>
                </div>
                <div onClick={()=> setModeIcon('email')}>
                    <img className={`${modeIcon == 'email' ? 'icon-mode-style-selected' : ''} icon-mode-style `} 
                      src={imgModeEmail} alt="Icono E-mail" title="Icono E-mail"/>
                </div>
                <div onClick={()=> setModeIcon('photo')}>
                    <img className={`${modeIcon == 'photo' ? 'icon-mode-style-selected' : ''} icon-mode-style `} 
                      src={imgModePhoto} alt="Icono Foto" title="Icono Foto"/>
                </div>
            </Row>
                
              <textarea style={{width: '90%'}} rows="5" className="my-3" id="interactionForm" type="text" placeholder="Comentario..."></textarea>

              {opportunities && opportunities.length !== 0 && 
              <select autoFocus={true} id="oportunidadSeleccionada" className="custom-select" style={{width: '90%'}} 
                  placeholder="Puedes asociar la interacción a una de las siguientes oportunidades">
                <option className="opcion l-fs-14" value="whitoutOpportunity">Sin oportunidad asociada</option>
                {opportunities.map(item => {
                  if(item.state === 'OPEN'){
                    return(
                      <React.Fragment key={item.id}>
                        <option className="opcion l-fs-14" value={item.id}>{item.name}</option>
                      </React.Fragment>
                    )
                  }
                })}
              </select>}
              
              <div className='py-3'>
              <FilesDragAndDrop fileSelected={callToShowImageOrDoc}/>
            </div>
              
              {preview && imageOrDocToShow && <div id="preview" className="w-100 py-3">
                <ShowPhotosAndDocsPreview 
                  isItATask={false} 
                  imageOrDocToShow={imageOrDocToShow} 
                  saveImagesAndDocs={saveImagesAndDocs} 
                  idCompany={idCompany} 
                  idTaskOrInteraction={interaction ? interaction.id : null}
                  uploadingFinished={uploadingFinished}
                />
              </div>}

              <div className="d-inline-flex my-5">
                  <div className="d-center boton-cancelar mr-3" onClick={cancelForm}>Cancelar</div>
                  <button disabled={savingAnimation ? true : false} className="d-center boton-guardar" onClick={saveInteraction}>
                    {savingAnimation && <span className="spinner-grow spinner-grow-sm mr-2" role="status" aria-hidden="true"></span>}
                    Guardar
                  </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </React.Fragment>
    );
  }