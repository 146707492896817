import React, {useState, useEffect} from 'react';
import '../../assets/css/calendarDatePicker/Calendar.css'
import '../../assets/css/calendarDatePicker/DatePicker.css'
import moment from 'moment';
import { isTaskOverdue, addDueDateTask } from '../../api_communication/service/TaskService';
import { ResolveTaskModal } from './ResolveTaskModal';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import icScheduleToday from '../../assets/img/ic-schedule-today.png'
import icScheduleTomorrow from '../../assets/img/ic-schedule-tomorrow.png'
import icScheduleNextWeek from '../../assets/img/ic-schedule-next-week.png'
import icScheduleChooseDate from '../../assets/img/ic-schedule-chooseDate.png'
import icConfig from '../../assets/img/ic-config.png'
import icTime from '../../assets/img/ic-time-picker.png'
import icTimeHover from '../../assets/img/ic-time-picker-blue.png'

import DatePicker from 'react-date-picker/dist/entry.nostyle';
import TimeKeeper from 'react-timekeeper';

// TEMP hour (in future from Config user)
const tempHour = ' 18:00'

const currentDateComplete = () => moment().format('DD/MM/YYYY HH:mm:ss')

const todayDatePlus2h = () => moment().add('2','hours').format('HH:mm')
const tomorrowDateToShow = () => moment().add('1','day').format("dddd") + tempHour
const nextWeekDateToShow = () => moment().add('7','day').format("dddd") + tempHour

const todayDatePlus2hToSave = () => moment().add('2','hours').format('DD/MM/YYYY HH:mm')
const tomorrowDateToSave = () => moment().add('1','day').format("DD/MM/YYYY") + tempHour
const nextWeekDateToSave = () => moment().add('7','day').format("DD/MM/YYYY") + tempHour

export const scheduleDateCallback = (date, item) => {
  
  const body = {
    dueDate: date,
    creationDate: currentDateComplete(),
  }

  addDueDateTask(item.company.id, item.id, body)
    .catch(error => {
      console.log(error)
      throw error;
    }).then( result => {
      //console.log('FECHA task MODIFICADA OK')
    })
}  

  //  <AddOrChangeScheduleTask 
  // show={addOrChangeScheduleTaskShowModal} 
  // resolve={true} onHide={closeTaskScheduleModal} 
  // scheduleDateCallback={scheduleDateCallback} 
  // interaction={this.props.interaction}
  // />
  // resolve=true --> show resolve btn
  // interaction={this.props.interaction} --> interaction or task
  // <AddOrChangeScheduleTask 
  //     show={addOrChangeScheduleTaskShowModal} 
  //     onHide={closeTaskScheduleModal} 
  //     scheduledatecallbackfn={scheduleDateCallback} 
  //     interaction={item}
  // />

  //--------------------------------------------------
  // THIS COMPONENT CAN RECEIVE A TASK
  // <AddOrChangeScheduleTask 
  //    isItATask={true OR false} 
  //    task={task} 
  //    scheduleDateCallbackFn={FN to return to parent the selected date} // OPTIONAL
  //    show={addOrChangeScheduleTaskShowModal} // true or false show modal
  //    onHide={closeTaskScheduleModal} 
  //    scheduleDateCallbackFn={scheduleDateCallback} />
  //--------------------------------------------------
  export const ScheduleTaskModal = (props) => {
    
    const [resolverModalOpenClose, setRevolverModalOpenClose] = useState(false)
    const [dueDateValue, setDueDateValue] = useState(null)
    const [onlyReturnDateToParent, setOnlyReturnDateToParent] = useState(false)
    const [dateValue, setDateValue] = useState(new Date());
    const [newDateValue, setNewDateValue] = useState(new Date());
    const [time, setTime] = useState(moment().add('2','hours').format('HH:mm'))
    const [newTime, setNewTime] = useState(moment().add('2','hours').format('HH:mm'))
    const [showTime, setShowTime] = useState(false)
    const fnToReturnDateToParent = props.scheduleDateCallbackFn

    useEffect(() => {
      // if parent requires the date, it will return it
      if (props.scheduleDateCallbackFn) {setOnlyReturnDateToParent(true) }

      if (props.task && props.task.hasOwnProperty('scheduling')) {
          setDueDateValue(props.task.scheduling.dueDate)
      } else {
          props.task && setDueDateValue(props.task.dueDate)
      }
      },[props.task]);

    const isItSolved = (trueOrFalse) => {
      setRevolverModalOpenClose(false)
      if (trueOrFalse) props.onHide('refresh')
    }

    const cancelTime = () => {
      setDateValue(new Date())
      setNewDateValue(null)
      setNewTime(null)
      setTime('00:00')
      document.getElementById('dateTimePickerContainer').classList.add('d-none'); 
      document.getElementById('footerBtns').classList.remove('d-none')
      let array = document.querySelectorAll('.hideMe');
      for (let i = 0; i < array.length; i++) {
        array[i].classList.remove('d-none')
      }
    }

    const resetTimeFn = ()=>{
      setNewTime(null)
      setTime('00:00')
    }

    const saveUserDateAndTime = ()=> {
      let st = newTime.split(':')
      let num = parseInt(st[0])
      
      let result
      if (num < 10) {
        let final
        let hour = num.toString()
        final = '0' + hour + ':' + st[1]
        result = moment(newDateValue).format("DD/MM/YYYY") + ' ' + final
      } else {
        result = moment(newDateValue).format("DD/MM/YYYY") + ' ' + newTime
      }
      onlyReturnDateToParent ? fnToReturnDateToParent(result) : scheduleDateCallback(result, props.task); props.onHide()
    }

    const timeIconOnHover = ()=> {
      document.getElementById('timeIconHover').src = icTimeHover;
    }
    const timeIconHoverOut = ()=> {
      document.getElementById('timeIconHover').src = icTime;
    }

    return (
      <Modal
        dialogClassName="modal-width"
        // size="md"
        onHide={props.onHide}
        show={props.show}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body className="text-center light-grey ">
        <Row className="justify-content-center p-3 ">

          <Col xs={12} className="borderBottom pos-r">
            <div className="ic-config-container" onClick={()=> alert('Config de usuario por implementar.')}>
              <img src={icConfig} width="25" height="25"/>
            </div>
          {dueDateValue ? 
            <p className={isTaskOverdue(dueDateValue) ? "rojo l-fs-16 pb-3 text-left" : "l-fs-16 pb-3 text-left"}>
              Programado para:<br /> {props.task && dueDateValue}
            </p> : <p className="l-fs-18 text-left pb-3">Seleccionar fecha:</p>
          }
          </Col>
          <Col>
            <Row className=" borderBottom hideMe">
              <Col 
                onClick={() => { onlyReturnDateToParent ? 
                  fnToReturnDateToParent(todayDatePlus2hToSave()) : 
                  scheduleDateCallback(todayDatePlus2hToSave(), props.task); props.onHide()}
                }
                className="justify-content-center align-items-center scheduleTaskContainer  py-3">
                <img className="mb-2" width="35" height="35" src={icScheduleToday}/>
                <p className="bold-text">Hoy más tarde:</p>
                <p>{todayDatePlus2h()}h</p>
              </Col>
              <Col 
                onClick={() => { onlyReturnDateToParent ? 
                  fnToReturnDateToParent(tomorrowDateToSave()) : 
                  scheduleDateCallback(tomorrowDateToSave(), props.task); props.onHide()}
                } 
                className="justify-content-center align-items-center scheduleTaskContainer  py-3">
              <img className="mb-2" width="35" height="35" src={icScheduleTomorrow}/>
                <p className="bold-text">Mañana:</p>
                <p>{tomorrowDateToShow()}h</p>
              </Col>
            </Row>
            <Row className=" borderBottom hideMe">
              <Col 
                onClick={()=>{onlyReturnDateToParent ? 
                  fnToReturnDateToParent(nextWeekDateToSave()) : 
                  scheduleDateCallback(nextWeekDateToSave(), props.task); props.onHide()}
                } 
                className="justify-content-center align-items-center scheduleTaskContainer  py-3">
                <img className="mb-2" width="35" height="35" src={icScheduleNextWeek}/>
                <p className="bold-text">Próxima semana:</p>
                <p>{nextWeekDateToShow()}h</p>
              </Col>
              <Col onClick={()=>{
                  document.getElementById('dateTimePickerContainer').classList.remove('d-none'); 
                  document.getElementById('footerBtns').classList.add('d-none')
                  let array = document.querySelectorAll('.hideMe');
                  for (let i = 0; i < array.length; i++) {
                    array[i].classList.add('d-none')
                  }
                }} 
                className="justify-content-center align-items-center scheduleTaskContainer hideMe py-3" >
                <img className="mb-2" width="35" height="35" src={icScheduleChooseDate}/>
                <p className="bold-text">Elegir fecha y hora</p>

              </Col>
            </Row>
            <Row id="dateTimePickerContainer" className="d-none pt-5 mt-4 ">
              <Col>
                <Row className="borderBottom pb-4">
                  <Col>
                    <div className="mb-5">
                      <DatePicker
                        onChange={(newDate) => {setDateValue(newDate);setNewDateValue(newDate)}}
                        value={dateValue}
                        locale="es-ES"
                        //minDate={moment().add(1, 'days').toDate()}
                        minDate={moment().toDate()}
                      />
                    </div>
                  </Col>
                  <Col>
                    <div className="d-column pb-5">
                      {showTime &&
                          <TimeKeeper
                              time={time}
                              onChange={(newTime) => {setTime(newTime.formatted24);setNewTime(newTime.formatted24);}}
                              doneButton={() => (
                                  <div onClick={()=>setShowTime(false)} 
                                      style={{ textAlign: 'center', padding: '10px 0', cursor: 'pointer' }}>
                                      Guardar
                                  </div>
                              )}
                              switchToMinuteOnHourSelect
                              hour24Mode
                          />
                      }
                      
                      <div className="d-flex flex-row align-items-center justify-content-end" style={{width: 157, height:29}}>
                      <div className="d-flex flex-row align-items-center justify-content-end" style={{border: 'thin solid #A0CBE7', width: 125, height: 29}}>
                        {!showTime && !newTime &&  <span style={{color: 'black', padding: '4px 6px'}} onClick={() => setShowTime(true)}>{time}h</span>}
                        {!showTime && newTime &&  <span style={{color: 'black', padding: '4px 6px'}} onClick={() => setShowTime(true)}>{newTime}h</span>}
                        <div onClick={()=>resetTimeFn()} style={{cursor: 'pointer', padding: '4px 6px'}}>
                          <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" stroke="black" strokeWidth="2" 
                               className="react-date-picker__clear-button__icon react-date-picker__button__icon">
                            <line x1="4" x2="15" y1="4" y2="15"></line>
                            <line x1="15" x2="4" y1="4" y2="15"></line>
                          </svg>
                        </div>
                      </div>

                      {!showTime && <div style={{cursor: 'pointer',padding: '4px 6px'}} 
                      onClick={() => setShowTime(true)}
                      onMouseOver = {()=>timeIconOnHover()}
                      onMouseOut =  {()=> timeIconHoverOut()}
                      >
                          <img id="timeIconHover" style={{width: 19, height:19, position: 'absolute', top: -27, left: 70}} src={icTime}/>
                        </div>}
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <div className="d-flex flex-row align-items-center justify-content-around pt-5 col">
                    <Button onClick={()=>cancelTime()} className="modal-button-cancel">Cancelar</Button>
                    <Button onClick={()=>{saveUserDateAndTime();props.onHide()}} className="modal-button">Guardar</Button>
                  </div>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer id="footerBtns" className="modal-border d-center mb-3 px-1">
        <Button onClick={()=>props.onHide()} className="modal-button">Cancelar</Button>

        {props.resolve && <ResolveTaskModal 
          show={resolverModalOpenClose} 
          onHide={isItSolved} 
          refresh={()=>props.refresh()} 
          task={props.task ? props.task : null}
        />}

      </Modal.Footer>
    </Modal>
    );
  }