import React, { useEffect, useState } from "react";

// PROPS: onChange={Function} - To return to parent country selected by user
//        resetProvinceFn={Function} - To return to parent 'true' or 'false' and the parent reset province input and select

// COMPONENTES
import { DynamicSelect } from "./DynamicSelect";

// SERVICE
import { listCountries } from "../../api_communication/service/ClientService";

// ************ TEMP *****************************************************
// PROPS DynamicSelect:
// optionsArray={} - array of values for show in options 
// searchTerm={} - returns user writing search word ->¿?
// onChange={handleChange} - Funcition to return user search word
// resetSearch={true or false} - prop: if true reset input value
// inputId={} - string - input id
// inputPlaceholder={} - string 'Buscar por X', 'Nombre de empresa', etc...
// ***********************************************************************

export function CountryDynamicSelectReports(props) {

    const [countriesArray, setCountriesArray] = useState([])

    useEffect(() =>{
    },[props])

    const handleFilterCountry = async (userInput, userSelect) => {
        props.resetProvinceFn(true)
        try {
            if (userSelect === null) {
                if(!userInput || userInput.length ===0) {
                    setCountriesArray([])
                    props.onChange(null)
                }
                if (userInput && userInput.length > 2) loadCountries(userInput)
            } else {
                props.resetProvinceFn(false)
                props.onChange(userSelect)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const loadCountries = async (query) =>{
        try {
        let countries = await listCountries(0, 10000000, query)
        setCountriesArray(countries)
        } catch (error) {
        console.log(error)
        }
    }


    return (
        <React.Fragment>
          <DynamicSelect 
            optionsArray={countriesArray} 
            onChange={handleFilterCountry} 
            resetSearch={null} 
            inputId={'countryFilter'} 
            inputPlaceholder={'País'}/>
        </React.Fragment>
      )
}