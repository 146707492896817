import React from 'react';

import '../assets/css/dragAndDropFiles.css';
import docIcon from '../assets/img/ic-adjuntar-doc-azul.png'
import { isItImageOrDoc } from './MediaManager';

// FROM PARENT: <FilesDragAndDrop fileSelected={callToShowImageOrDoc}/>
// with 'fileSelected' prop this component returns the file selected by user
// callToShowImageOrDoc = fn to assign the file selected to <ShowPhotosAndDocsPreview/> component in parent, to show the preview, etc...

export default function FilesDragAndDrop({fileSelected}) {

    const [dragActive, setDragActive] = React.useState(false);

    const inputRef = React.useRef(null);
    
    const handleDrag = function(e) {
      e.preventDefault();
      e.stopPropagation();
      if (e.type === "dragenter" || e.type === "dragover") {
        setDragActive(true);
      } else if (e.type === "dragleave") {
        setDragActive(false);
      }
    };
    
    const handleDrop = function(e) {
      let file = e.dataTransfer.files
      e.preventDefault();
      e.stopPropagation();
      setDragActive(false);
      if (file && file[0]) {
          fileSelected(file[0])
      }
    };
    
    const handleChange = function(e) {
      let file = e.target.files
      e.preventDefault();
      if (file && file[0]) {
          fileSelected(file[0])
      }
    };
    
    const onButtonClick = () => {
      inputRef.current.click();
    };
    
    return (
      <form id="form-file-upload" onDragEnter={handleDrag} onSubmit={(e) => e.preventDefault()}>
         <input ref={inputRef} encType="multipart/form-data" id="archivoOpp" type="file" multiple={false} onChange={handleChange} style={{display: 'none'}} />
        <label id="label-file-upload"  className={dragActive ? "drag-active" : "" }>
          <div className='p-4'>
            <p className='l-fs-14 light-grey '>Arrastre y suelte su archivo aquí o</p>
            <div className="pb-1 text-center">
              <div onClick={onButtonClick} className="mouse text-hover-bold" htmlFor="archivoOpp">
                <img  width="20" height="20" className="mr-2" src={docIcon}/>
                <span className="l-fs-14 light-grey  pt-1"><u>Adjunte archivo</u></span>
              </div>
            </div>
            <div className="d-flex align-items-center pt-3">
              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" className="bi bi-exclamation-circle" viewBox="0 0 16 16">
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z"/>
              </svg>
              <p id="imagesAccepted" style={{fontSize: '10px', marginLeft: '5px'}}>Imágenes: *.jpg / *.jpeg / *.png / *.bmp / *.gif / *.webp</p>
            </div>
          </div> 
        </label>
        { dragActive && <div id="drag-file-element" onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop}></div> }
      </form>
    );
  };
