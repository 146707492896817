import React, {  } from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Media from "react-bootstrap/Media";

import {
  findClientById,
  findAllClientsService,
  LoadInteractionsByCompany,
} from "../../api_communication/service/ClientService";
import {
  countOverdueTasksForOpenOpportunitiesForOneCompany,
  listOpenOpportunitiesForClient
} from "../../api_communication/service/OpportunityService";
import defaultLogo from "../../assets/img/logoPorDefecto.png";

import { Link } from "react-router-dom";
import LoadingAnimationMiniSize from "../LoadingAnimationMiniSize";
import btnMas from "../../assets/img/btn-mas.png";

import redStar from "../../assets/img/ic-star-red.png";
import icPin from "../../assets/img/ic-pin-azul.png";

import UpSalesMessage from "../UpSalesMessage";

import moment from "moment";

import { CreateCompany } from "./CreateClient";
import { LocalityDynamicSelect } from "../selects/LocalityDynamicSelect";
import { ProvinceDynamicSelect } from "../selects/ProvinceDynamicSelect";
import { CountryDynamicSelect } from "../selects/CountryDynamicSelect";

class ClientLogo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      clientLogo: [],
    };
  }

  async componentDidMount() {
    try {
      let clientLogo = await findClientById(this.props.client);
      this.setState({
        clientLogo: clientLogo,
      });
    } catch (error) {
      console.log(error);
    }
  }

  render() {
    // let showLogo = null
    // if (this.state.clientLogo.thumbnail === null) showLogo = defaultLogo
    // else showLogo = this.state.clientLogo.thumbnail
    return (
      <Media>
        {this.state.clientLogo && (
          <img
            style={{ width: 40, height: 40, borderRadius: 50 }}
            src={
              this.state.clientLogo.thumbnail === null
                ? defaultLogo
                : this.state.clientLogo.thumbnail
            }
          ></img>
        )}
      </Media>
    );
  }
}

// PROPS:
// client={client}
// indice={client.id}
// key={client.id}

class ClientCol extends React.Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef()
    this.state = {
      data: [],
      image: null,
      numberDueDateInteractions: 0,
      numberOpportunities: 0,
      lastInteraction: null,
      companyLoaded: false,
    };
  }

  async componentDidMount() {
    try {
      let interactions = await LoadInteractionsByCompany(
        this.props.client.id,
        0,
        1
      );
      let currentDate = moment().format("DD/MM/YYYY HH:mm:ss");
      let result = await countOverdueTasksForOpenOpportunitiesForOneCompany(
        this.props.indice,
        currentDate
      );
      let opportunities = await listOpenOpportunitiesForClient(this.props.indice, 0, 1000);
      let lengthOpportunities = opportunities ? opportunities.length : null;
      this.setState({
        numberDueDateInteractions: result.count,
        numberOpportunities: lengthOpportunities,
        companyLoaded: true,
        // Si hay interacciones que lastInteraction añada la fecha de la primera y sino que no añada fecha
        lastInteraction: interactions ? interactions[0].date.split(" ")[0] : "",
      });
    } catch (error) {
      console.log(error);
    }
  }

  componentDidUpdate(){
    if (this.props.scrollToLastElement) this.myRef.current.scrollIntoView({behavior: 'smooth'})
  }

  render() {
    const client = this.props.client;
    const data = {
      client: client,
    };
      return (
        <Col
        ref={this.myRef} 
        id = {client.id}
          xs={10}
          md={5}
          xl={3}
          className={
            this.state.numberDueDateInteractions
              ? " px-4   bg-blue-light my-3 "
              : " px-4   bg-blue-light my-3 "
          }
        >
          <Link
            className="row color-link bg-white"
            to={{ pathname: "/DetallesClientes", state: client }}
          >
            <Col className={
                this.state.numberDueDateInteractions
                  ? " client-card-border-red "
                  : "client-card-no-border "
              }
              xs={12}>
              <Row className="py-2 brd-b-blue d-flex flex-row">
                {client.favorite && (
                  <Col
                    className=" justify-content-center align-items-center"
                    xs={1}
                  >
                    <img width={20} height={20} src={redStar}></img>
                  </Col>
                )}
                {client.pinned && (
                  <Col
                    className=" justify-content-center align-items-center"
                    xs={1}
                  >
                    <img width={20} height={20} src={icPin}></img>
                  </Col>
                )}
                <Col xs={1} />
                {/* <Col className=" justify-content-center align-items-center" xs={1} ><img width={20} height={20} src={perfilCompleto}></img></Col> */}
                <Col className="light-grey d-flex justify-content-end align-items-center">
                  <small className="l-fs-12">
                    {this.state.lastInteraction
                      ? this.state.lastInteraction
                      : "Sin interacciones"}
                  </small>
                </Col>
                {this.state.numberDueDateInteractions !== 0 && (
                  <Col xs={2} className="light-grey">
                    <Row className="d-flex justify-content-center align-items-center">
                      <Col>
                        <div className="indicator-styles">
                          {this.state.numberDueDateInteractions}
                        </div>
                      </Col>
                    </Row>
                  </Col>
                )}
              </Row>
              <Row className="my-1 body-clients-card">
                <Col xs={7} className="align-item-center justify-content-start">
                  <Row>
                    <Col xs={12} className="d-center mt-2">
                      <ClientLogo client={client.id} />
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      xs={12}
                      className="d-center text-center mt-2 dark-blue mayus l-fs-14"
                    >
                      {client.alias}
                    </Col>
                  </Row>
                </Col>
                <Col xs={5} className="align-self-center">
                  <Row className="justify-content-center text-center light-grey l-fs-12">
                    {this.state.numberOpportunities
                      ? this.state.numberOpportunities
                      : 0}
                    <br />
                    Oportunidades
                  </Row>
                  {/*<Row><Col xs={10} className="brd-b-blue my-3"></Col></Row> 
                      <Row className="justify-content-center text-center light-grey"><Col>100.000€</Col></Row>*/}
                </Col>
              </Row>
            </Col>
          </Link>
        </Col>
      );
  }
}

// PROPS:
// clients={this.state.listaClientes}
// userQuery={this.state.userQuery}
// handleUserIsSearching={this.handleUserIsSearching}
class ClientsTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      clientList: []
    };
  }

  render() {
    const cols = [];

    this.props.clients.length !== 0 &&
      this.props.clients.forEach((client) => {
        cols.push(
          <ClientCol scrollToLastElement={this.props.scrollToLastElement} client={client} indice={client.id} key={client.id} />
        );
      });

    return (
      <Row className="d-flex justify-content-center px-2">
        {cols}
      </Row>
    );
  }
}

class SearchBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userQuery: '',
      postalCode: ''
    };
    this.handleCompanyNameChange = this.handleCompanyNameChange.bind(this);
    this.handlePostalCodeChange = this.handlePostalCodeChange.bind(this);
    this.showUpSalesMessage = this.props.showUpSalesMessageFn;
  }

  componentDidMount() {
    document.getElementById("oportunityInput").focus();
  }

  handleCompanyNameChange(e) {
    let userQuery = e.target.value
    this.setState({userQuery: userQuery})

    if (userQuery.length > 2) 
      this.props.setCompanyName(userQuery);
    else
      this.props.setCompanyName('');
  }

  handlePostalCodeChange(e) {
    let postalCode = e.target.value
    this.setState({postalCode: postalCode})

    if (postalCode.length > 2) 
      this.props.setPostalCode(postalCode);
    else
      this.props.setPostalCode(null);
  }

  render() {
    return (
      <Row className="justify-content-center bg-blue-light pb-4">
        <Col xs={12}>
          <Row className="align-items-center">
            <Col xs={12} md={3}>
              <Form autocomplete="off" 
                onSubmit={(e) => {
                  e.preventDefault();
                }}
                className="bg-input"
              >
                <input
                  id="oportunityInput"
                  className="w-100 l-fs-14"
                  type="text"
                  placeholder="Filtrar por: Nombre Cliente "
                  value={this.state.userQuery.toUpperCase()}
                  onChange={this.handleCompanyNameChange}
                />
              </Form>
            </Col>
            <Col xs={12} md={3}>
                <div className="bg-input">
                  <CountryDynamicSelect
                    setValue={this.props.handleCountry}
                  />
                </div>
            </Col>
            <Col xs={12} md={3}>
                <div className="bg-input">
                  <ProvinceDynamicSelect
                    countryId={this.props.countryId}
                    setValue={this.props.handleProvince}
                  />
                </div>
            </Col>
            <Col
              xs={12}
              md={3}
              className="d-flex justify-content-end align-items-center"
            >
              <CreateCompany
                showUpSalesMessageFn={this.showUpSalesMessage}
                refresh={this.refresh}
              />
            </Col>
          </Row>
          <Row className="align-items-center">
            <Col xs={12} md={3}>
              
            </Col>
            <Col xs={12} md={3}>
                <div className="bg-input">
                  <LocalityDynamicSelect
                    countryId={this.props.countryId}
                    provinceId={this.props.provinceId}
                    setValue={this.props.handleLocality}
                  />
                </div>
            </Col>
            <Col xs={12} md={3}>
              <Form autocomplete="off"
                  onSubmit={(e) => {
                    e.preventDefault();
                  }}
                  className="bg-input"
              >
                <input
                  id="oportunityInput"
                  className="w-100 l-fs-14"
                  type="text"
                  placeholder="Filtrar por: Código postal"
                  value={this.state.postalCode}
                  onChange={this.handlePostalCodeChange}
                />
              </Form>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}

export default class FilterableClientsTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      listaClientes: [],
      paginationCount: 0, // contador para llamar a backend (info paginada)
      loadingAnimation: true,
      loadingData: false, //if true muestra pequeño loading animation en lugar del btn +
      showBtnMoreCompanies: true, // si de backend no hay mas datos se oculta btn +
      noMoreDataAvailable: false,
      userQuery:'',
      countryId: null,
      provinceId: null,
      localityId: null,
      postalCode: '',
      messageToUser:'',
      pageSize: 12,
      scrollToLastElement: false
    };
  }

  componentDidMount() {
    this.cargaListaClientes(this.state.paginationCount, this.state.pageSize, this.state.userQuery, 
      this.state.countryId, this.state.provinceId, this.state.localityId, this.state.postalCode
    );
  }

  componentDidUpdate(prevProps, prevState) {

    if (prevState.userQuery !== this.state.userQuery || prevState.countryId !== this.state.countryId || 
      prevState.provinceId !== this.state.provinceId || prevState.localityId !== this.state.localityId || 
      prevState.postalCode !== this.state.postalCode) {

        this.setState({scrollToLastElement:false})

        clearTimeout(this.timer);
        this.timer = setTimeout(() => {

          // console.log("componentDidUpdate :: ", 0, this.state.pageSize, ", companyName=", this.state.userQuery, 
          //             ", countryId=", this.state.countryId, ", provinceId=", this.state.provinceId, 
          //             ", localityId=", this.state.localityId, ", postalCode=", this.state.postalCode)

          this.cargaListaClientes(0, this.state.pageSize, this.state.userQuery, this.state.countryId, 
            this.state.provinceId, this.state.localityId, this.state.postalCode
          );
        }, 200);

      }
  }

  setCompanyName = (query) => {
    this.setState({userQuery: query})
  }

  setCountryId = (id) => {
    this.setState({countryId: id})
  }

  setProvinceId = (id) => {
    this.setState({provinceId:id})
  }

  setLocalityId = (id) => {
    this.setState({localityId:id})
  }

  setPostalCode = (code) => {
    this.setState({postalCode:code})
  }

  cargaListaClientes = async (paginationCount, pageSize, query, countryId, provinceId, localityId, postalCode) => {
    this.setState({
      noMoreDataAvailable: false,
      loadingData: true,
      messageToUser:'',
      showBtnMoreCompanies: true,
      paginationCount: paginationCount,
    })
    try {
      let listadoPaginadoO = await findAllClientsService(paginationCount, pageSize, query, countryId, provinceId, localityId, postalCode);
      let listadoPaginadoSiguiente = await findAllClientsService(paginationCount + 1,pageSize , query, countryId, provinceId, localityId, postalCode); 

      if ((listadoPaginadoO === null || listadoPaginadoO.length === 0)) {
        this.setState({
          showBtnMoreCompanies: false,
          noMoreDataAvailable: true,
          messageToUser:'No hay datos para mostrar'
        }); 
      } else {
        if ((listadoPaginadoSiguiente === null || listadoPaginadoSiguiente.length === 0)) {
          this.setState({
            showBtnMoreCompanies: false,
            noMoreDataAvailable: true,
            messageToUser:'No hay más datos para mostrar'
          });
        }
      }

      this.setState({
        // listaClientes: this.state.listaClientes.length !== 0 ? this.state.listaClientes.concat(listadoPaginadoO) : listadoPaginadoO,
        listaClientes: paginationCount !== 0 ? this.state.listaClientes.concat(listadoPaginadoO) : listadoPaginadoO,
        loadingData: false,
      });
      
    } catch (error) {
      this.showUpSalesMessage("Ha ocurrido un error al carga la lista de clientes")
    }
  };

  moreCompanies = () => {
    let newPaginationCount = this.state.paginationCount + 1;
    this.setState({
      loadingData: true,
      scrollToLastElement: true
    });
    this.cargaListaClientes(newPaginationCount, this.state.pageSize, this.state.userQuery, this.state.countryId, this.state.provinceId, this.state.localityId, this.state.postalCode);
  };

  showUpSalesMessage = (level, message, show, close) => {
    this.setState({
      toastLevel: level,
      toastMessage: message,
      showToast: show,
      autoClose: close,
    });
  };

  closeMessage = () => {
    this.setState({
      toastLevel: null,
      toastMessage: null,
      showToast: false,
      autoClose: false,
    });
  };

  render() {
      return (
        <Col xs={12}>
          <SearchBar
            userQuery={this.state.userQuery}
            countryId={this.state.countryId}
            provinceId={this.state.provinceId}
            showUpSalesMessageFn={this.showUpSalesMessage}
            setCompanyName={this.setCompanyName}
            handleCountry={this.setCountryId}
            handleProvince={this.setProvinceId}
            handleLocality={this.setLocalityId}
            setPostalCode={this.setPostalCode}
          />
          {this.state.loadingData && <div className="d-center"><LoadingAnimationMiniSize></LoadingAnimationMiniSize></div>} 

          <ClientsTable
              scrollToLastElement={this.state.scrollToLastElement}
              userQuery={this.state.userQuery}
              handleUserIsSearching={this.handleUserIsSearching}
              clients={this.state.listaClientes}
          />
          
          <UpSalesMessage
            level={this.state.toastLevel}
            message={this.state.toastMessage}
            visible={this.state.showToast}
            autoClose={this.state.autoClose}
            closeMessage={this.closeMessage}
          />

          {this.state.showBtnMoreCompanies && (
              <Row id="BtnMoreCompanies">
                <Col className="btn-mas-container-blue flex-column">
                  {!this.state.loadingData && (
                    <a href="#" onClick={this.moreCompanies}>
                      <img src={btnMas}></img>
                    </a>
                  )}
                </Col>
              </Row>
            )}

          {this.state.noMoreDataAvailable &&  (
            <Row>
              <Col className="col-12 d-center py-3">
                <p className="light-grey">{this.state.messageToUser}</p>
              </Col>
            </Row>
          )}
        </Col>
      );
    } 
}
