import React, {useState, useEffect} from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'
import Media from 'react-bootstrap/Media'
import { ModalBody } from 'react-bootstrap'

// COMPONENTS
import UpSalesMessage from "../UpSalesMessage";
// PROJECT FUNCTIONS
import { archiveProject, closeProject, projectCreateInteraction} from './funcionesProyectos'
// PROJECT SERVICE FUNCTIONS
import {listTasksForAProject} from '../../api_communication/service/ProjectService'
import { closeTask } from '../../api_communication/service/TaskService'
// EXTERNAL LIBRARIES
import moment from 'moment';
import DatePicker from 'react-date-picker/dist/entry.nostyle';
import '../../assets/css/calendarDatePicker/Calendar.css'
// IMAGES
import icProyectos from '../../assets/img/ic-proyecto-nuevo-big.png'


export default function CloseProject(props) {
    const [showModal, setShowModal] = useState(false)
    const [dateValue, setDateValue] = useState(null)
    const [isArchived, setIsArchived]= useState(false)
    const [thereAreActiveTasks, setThereAreActiveTasks] = useState(false)
    const [savingAnimation, setSavingAnimation] = useState(false)
    const [showProjectConfirmationAlert, setShowProjectConfirmationAlert] = useState(false)
    const [tasksList, setTaskList] = useState(null)
    const [clientId, setClientId] = useState(null)

    useEffect(()=>{
        setDateValue(new Date())
        setShowModal(props.show)
    },[props.show])

    useEffect(()=>{
        const getTasks = async ()=>{
            try {
                let result = await listTasksForAProject(props.projectId, 0, 10, null, null, 'ACTIVE')
                console.log('getTasks: result.data: ', result.data)
                if (!result || result.error) {
                    props.showUpSalesMessageFn('danger','Ha ocurrido un error cargando datos.', true, false)
                } else {
                    if (result.totalElements !==0) {
                        let client_id = result.data[0].company.id
                        setClientId(client_id)
                        setThereAreActiveTasks(true)
                        setTaskList(result.data)
                    }
                }
            } catch (error) {
                console.log(error)
            }
        }
        if (props.projectId) getTasks()
    },[props.projectId])

    

    const SubmitForm = async ()=>{
        setSavingAnimation(true)
        let projectId = props.projectId
        let interactionComment = document.getElementById('closeProjectTextarea').value
        let interactionTime = moment().format("HH:mm:ss")
        let interactionBody = {
            date: moment().format("DD/MM/YYYY") + ' ' + interactionTime,
            content: "",
            mode: "text"
        }
        if (isArchived) {
            if (dateValue === null) {
                props.showUpSalesMessageFn('warning','Si cierra el proyecto debe indicar una fecha válida.', true, true)
                return false
            } else {
                try {
                    let date = moment(dateValue).format("DD/MM/YYYY") 
                    await resolveAllTasks()
                    let result = await archiveProject(projectId,date)
                    
                    if (result) {
                        let comment = interactionComment ? interactionComment : "Proyecto anulado."
                        interactionBody.content = comment
                        //console.log('PROJECT ID: ',projectId)
                        //console.log('BODY: ', interactionBody)
                        let interaction = await projectCreateInteraction(projectId, interactionBody)
                        if (interaction) {
                            props.showUpSalesMessageFn('success','Proyecto anulado correctamente.', true, true)
                            props.refresh()
                            closeThisModal()
                        } else {
                            props.showUpSalesMessageFn('danger','Proyecto anulado correctamente pero ha ocurrido un error al crear la interacción asociada.', true, true)
                        }
                        
                    } else {
                        props.showUpSalesMessageFn('danger','Ha ocurrido un problema al anular el proyecto.', true, false)
                    }
                } catch (error) {
                    console.log(error)
                    props.showUpSalesMessageFn('danger','Ha ocurrido un problema al anular el proyecto.', true, false)
                }
            }
        } else {
            if (dateValue === null) {
                props.showUpSalesMessageFn('warning','Si cierra el proyecto debe indicar una fecha válida.', true, true)
                return false
            } else {
                try {
                    let date = moment(dateValue).format("DD/MM/YYYY") 
                    let result = await closeProject(props.projectId, date)
                    await resolveAllTasks()
                    if (result) {
                        let comment = interactionComment ? interactionComment : "Proyecto finalizado con éxito."
                        interactionBody.content = comment
                        //console.log('PROJECT ID: ',projectId)
                        //console.log('BODY: ', interactionBody)
                        let interaction = await projectCreateInteraction(projectId, interactionBody)
                        if (interaction) {
                            props.showUpSalesMessageFn('success','Proyecto finalizado con éxito correctamente.', true, true)
                            props.refresh()
                            closeThisModal()
                        } else {
                            props.showUpSalesMessageFn('danger','Proyecto finalizado correctamente pero ha ocurrido un error al crear la interacción asociada.', true, true)
                        }
                    } else {
                        props.showUpSalesMessageFn('danger','Ha ocurrido un problema al finalizar el proyecto.', true, false)
                    }
                } catch (error) {
                    console.log(error)
                    props.showUpSalesMessageFn('danger','Ha ocurrido un problema al finalizar el proyecto.', true, false)
                }
            }
        }
    }
// UpSales.Task.closeTask(companyId, taskId, body)
// body: {
//    "scheduling": {
//        "creationDate": "05/06/2020 10:20:00" == currentDate
//    }
//}
    const resolveAllTasks = async ()=> {
        let currentDate = moment().format('DD/MM/YYYY HH:mm:ss')
        let body = {
            creationDate: currentDate
        }
        try {
            await Promise.all(tasksList.map(async (taskItem) => {
                let result = await closeTask(clientId, taskItem.id, body)
                //console.log('result: ', result)
            }))
        } catch (error) {
            props.showUpSalesMessageFn('danger','Ha ocurrido un error al resolver las tareas del proyecto.', true, false)
            console.log(error)
        }
    }
  
    const handleCloseCheckChange = () =>{
        setIsArchived(false)
    }

    const handleArchiveCheckChange = (event) => {
        setIsArchived(true)
    }

    const closeThisModal = ()=>{
        setThereAreActiveTasks(false)
        setSavingAnimation(false)
        setDateValue(new Date())
        setIsArchived(false)
        props.close()
    }

    const closeMessage = ()=> {
        setShowProjectConfirmationAlert(false)
    }

    const callBackAnswerQuestion = ()=> {
        SubmitForm()
        closeMessage()
    }

    const closeTaskBtn = ()=>{
        if (thereAreActiveTasks) setShowProjectConfirmationAlert(true)
        else SubmitForm()
    }

    return (
        <React.Fragment>
            <UpSalesMessage
                level={'warning'}
                message={'Todas las tareas asociadas a este proyecto se resolverán. Desea continuar?'} 
                visible={showProjectConfirmationAlert} 
                autoClose={false}
                closeMessage={closeMessage} // PASSING CLOSE MESSAGE FUNCTION
                callBackAnswerQuestionFn={callBackAnswerQuestion} // Fn que pasamos para que se ejecute cuando user click en ok// OPTIONAL
            />
            <Modal
                style={{ overflow: "hidden" }}
                id=""
                className="modal-border"
                show={showModal}
                //onHide={() => setShowEditProject(false)}
                dialogClassName="bg-white"
                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Header className="justify-content-center modal-border">
                    <Modal.Title id="example-custom-modal-styling-title" className="dark-blue d-column">
                        <Media><img src={icProyectos}></img></Media>
                        <b className="my-2 text-center">
                            CERRAR PROYECTO
                        </b>
                    </Modal.Title>
                </Modal.Header>
                <ModalBody>
                    <form id="closeProject" className="d-column light-grey">
                        <div>
                            <Form.Check
                            custom
                            type="radio"
                            name="closeProject"
                            label={`Éxito`}
                            id={`close`}
                            onChange={handleCloseCheckChange}
                            defaultChecked
                            />
                            <Form.Check
                            custom
                            type="radio"
                            name="closeProject"
                            label={`Anulado`}
                            id={`archive`}
                            onChange={handleArchiveCheckChange} 
                            />
                        </div>
                        <div className="mt-4">
                            <p className='text-center'>Fecha cierre:</p>
                            <DatePicker
                                onChange={(newDate) => {setDateValue(newDate)}}
                                value={dateValue}
                                locale="es-ES"
                            />
                        </div>
                        {thereAreActiveTasks  && 
                        <div>
                            <p className='redColor pt-5'>Atención! Hay Tareas activas asociadas a este proyecto.</p>
                        </div>
                        }
                        <Row className="mt-5">
                            <Col xs={12}>
                                <p className="light-grey">(Opcional) Agregar comentario:</p>
                                <textarea style={{width: '100%'}} rows="5" className="my-3" id="closeProjectTextarea" type="text"  placeholder="Comentario..."></textarea>
                            </Col>
                        </Row>
                        <div className="d-flex justify-content-end my-5">
                            <div title="Cancelar operación" className="d-center boton-cancelar mr-3" onClick={()=>closeThisModal()}>Cancelar</div>
                            <div title="Cerrar proyecto" className="d-center boton-guardar px-2" onClick={()=>closeTaskBtn()}>
                                {savingAnimation && <span className="spinner-grow spinner-grow-sm pr-3" role="status" aria-hidden="true"></span>}
                                Cerrar proyecto</div>
                        </div>
                    </form>
                </ModalBody>
            </Modal>
        </React.Fragment>)
}