import React, { useEffect, useState } from "react";

// PROPS: countryId={countryId} - if !==null we search provinces by country
//        setValue={value or null} - Function to set provinceId in parent

// COMPONENTES
import { DynamicSelect } from "./DynamicSelect";

// SERVICE
import { listProvinces, listProvincesByCountry } from "../../api_communication/service/ClientService";

// ************ TEMP *****************************************************
// PROPS DynamicSelect:
// optionsArray={} - array of values for show in options 
// searchTerm={} - returns user writing search word ->¿?
// onChange={handleChange} - Funcition to return user search word
// resetSearch={true or false} - prop: if true reset input value
// inputId={} - string - input id
// inputPlaceholder={} - string 'Buscar por X', 'Nombre de empresa', etc...
// ***********************************************************************

export function ProvinceDynamicSelect(props) {

    const [countryId, setCountryId] = useState(null)
    const [array, setArray] = useState([])
    const [resetSearch, setResetSearch] = useState(false)
    const [userInput, setUserInput] = useState(null)

    useEffect(() => {

        if (props.countryId !== countryId) {
            setUserInput(null)
            setArray([])
            setCountryId(props.countryId)
            setResetSearch(true)
            props.setValue(null)
        }

        // console.log("PROVINCE SELECT despues :: props.countryId :: " + props.countryId, " , userInput :: ", userInput)

        if (props.countryId)
            loadProvincesByCountry(props.countryId, userInput)
        else
            loadProvinces(userInput)

    },[props.countryId, userInput])

    const handleUserInput = (input, userSelectId)=> {
        setResetSearch(false)
        try {
            if (userSelectId === null) {
                
                if (input && input.length > 2)
                    setUserInput(input)
                else {
                    setUserInput(null)
                    props.setValue(null)
                }
                
            } else {
                props.setValue(userSelectId)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const loadProvinces = async (query) =>{
        try {
            let provinces = await listProvinces(0, 100, query)
            setArray(provinces)
        } catch (error) {
        console.log(error)
        }
    }
    
    const loadProvincesByCountry = async (countryId, query) =>{
        try {
          let provinces = await listProvincesByCountry(countryId, 0, 100, query)
          setArray(provinces)
        } catch (error) {
          console.log(error)
        }
    }

    return (
        <React.Fragment>
            <DynamicSelect 
                optionsArray={array} 
                onChange={handleUserInput} 
                resetSearch={resetSearch} 
                inputId={'provinceFilter'} 
                inputPlaceholder={'Provincia'}/>
        </React.Fragment>
    )
}