// import fn backend 
import { UpSales } from "../../api_communication/bundle/bundle";

const PAGINATION_SIZE = 10

export const projectCreateInteraction = async (projectId, body)=>{
    //console.log('CREATE INT: projectId: ', projectId)
    //console.log('CREATE INT: body: ', body)
    try {
        let result = await UpSales.Project.createInteraction(projectId, body)
        //console.log('CREATE INT: result: ', result)
        if (!result || result.error) {
            return false
        } else {
            return result
        }
    } catch (error) {
        console.log(error)
    }
}

export const getProjectActiveInteractions = async (projectId) => {
    //UpSales.Project.listActiveInteractions(projectId, page, pageSize)
    try {
        let result = await UpSales.Project.listActiveInteractions(projectId, 0, 10000)
        if (!result || result.error) {
            return false
        } else {
            return result
        }
    } catch (error) {
        console.log(error)
    }
}

export const listProjectArchivedInteractions = async (projectId, page, pageSize) => {
    try {
        let result = await UpSales.Project.listArchivedInteractions(projectId, page, pageSize)
        if (!result || result.error) {
            return false
        } else {
            return result
        }
    } catch (error) {
        console.log(error)
    }
}

export const loadProjectsListByFilter = async (page=undefined, companyId=undefined, state=undefined, dateFrom=undefined, 
    dateTo=undefined, query=undefined, paste=undefined, format=undefined, originVal=undefined, typology=undefined, fabricated=undefined) => {
    
    /*console.log('---------------------------')
    console.log('loadProjectsListByFilter:')
    console.log('companyId: ', companyId)
    console.log('dateFrom: ', dateFrom)
    console.log('dateTo: ', dateTo)
    console.log('query: ', query)
    console.log('state: ', state)
    console.log('paste: ',paste)
    console.log('typology: ',typology)
    console.log('format: ',format)
    console.log('originVal: ',originVal)
    console.log('>>> upsales fn: fabricated: ', fabricated)
    console.log('---------------------------')*/
    
    try {
        let result = await UpSales.Project.listWithAllFilters(page, PAGINATION_SIZE, companyId, state, dateFrom, dateTo, query, paste, format, originVal, typology, fabricated)
        //console.log('>>> loadProjectsListByFilter: result: ',result)
        if (!result || result.error) {
            return false
        } else {
            return result
        }
    } catch (error) {
        console.log(error)
    }
}

export const loadProjectDetails = async (projectId) => {
    try {
        let result = await UpSales.Project.getProject(projectId)
        if (!result || result.error) {
            return false
        } else {
            return result
        }
        
    } catch (error) {
        console.log(error)
    }
}



export const loadCirclesData = async () => {
    try {
        let result = await UpSales.Project.getKPIs()
        if (!result || result.error) {
            return false
          } else{
            return result
          }
    } catch (error) {
        console.log(error)
    }
}

export const saveProject = async (project)=>{
    //console.log('saveProject: ', project)
    try {
        let result = await UpSales.Project.createProject(project)
        //console.log('saveProject: result: ', result)
        if (!result || result.error) return false
        return result
    } catch (error) {
        console.log('ERROR save Project: ', error)
        return false
        
    }
}

export const updateProject = async (projectId, body) => {
    try {
        let result = await UpSales.Project.update(projectId, body)
        //console.log('--------------------------')
        //console.log('updateProject > projectId: ', projectId)
        //console.log('updateProject > body: ', body)
        //console.log('updateProject > result: ', result)
        //console.log('--------------------------')
        //console.log('result repo:: ', result)
        if (!result || result.error) return false
        return result
    } catch (error) {
        console.log('ERROR updating Project: ', error)
        return false
        
    }
}

export const loadTopClientes = async (pageSize = 5) => {
    try {
        let result = await UpSales.Project.listTopCompanies(0, pageSize)
        //console.log('PROYECTOS: loadTopClientes: result: ', result)
        if (!result || result.error) {
            //console.log('PROYECTOS: loadTopClientes: ERROR: ', result)
            return false
          } else{
            return result
          }
    } catch (error) {
        console.log('ERROR load top clientes: ', error)
        return false
    }
}

export const loadUltimosProyectos = async()=> {
    try {
        //UpSales.Project.listOrderedByCreationDate(page, pageSize, dateFrom, dateTo, query)
        let result = await UpSales.Project.listOrderedByCreationDate(0, 5)
        //let result = await UpSales.Project.listByState(0, 5, 'STARTING')
        //console.log('PROYECTOS: loadUltimosProyectos: result: ', result)
        if (!result || result.error) {
            //console.log('PROYECTOS: loadUltimosProyectos: ERROR: ', result)
            return false
          } else{
            return result.data
          }
    } catch (error) {
        console.log('ERROR load ultimos clientes: ', error)
        return false
    }
}

export const archiveProject = async (projectId,endDate) => {
    //('ARCHIVE id: ', projectId)
    //console.log('ARCHIVE date: ', endDate)
    try {
        let result = await UpSales.Project.archive(projectId, endDate)
        console.log('ARCHIVE: result: ', result)
        if (!result || result.error) {
            return false
          } else{
            return result
          }
    } catch (error) {
        console.log(error)
        return false
    }
}

export const closeProject = async (projectId, endDate) => {
    //console.log('CLOSE: id: ', projectId)
    //console.log('CLOSE: endDate: ', endDate)
    
    try {
        let result = await UpSales.Project.closeWithSuccess(projectId, endDate)
        //console.log('CLOSE: result: ', result)
        if (!result || result.error) {
            return false
          } else{
            return result
          }
    } catch (error) {
        console.log(error)
        return false
    }
    
}

export const loadChartData = async (dataType)=>{
    // let sales = UpSales.Project.getDataForPieChart(dataType, dateFrom, dateTo)
    const N_ELEMENTS = 10
    try {
        let allResults = await UpSales.Project.getDataForPieChart(dataType)
        //console.log('loadChartData: ', allResults)
        let newNames = allResults.names.slice(0,N_ELEMENTS)
        let newValues = allResults.values.slice(0,N_ELEMENTS)
        let result = {newNames, newValues}
        if (!allResults || allResults.error) {
            return false
          } else{
            return result
          }
    } catch (error) {
        console.log(error)
        return false
    }
}

export const downloadCsvProject = async (companyId = undefined, state = undefined, dateFrom = undefined, dateTo = undefined, query = undefined, paste = undefined, format = undefined, origin = undefined, typology = undefined, fabricated = undefined)=>{
    //UpSales.Project.exportCSV(companyId, state, dateFrom, dateTo, query, paste, format, origin, typology, fabricated)
    // console.log('downloadCsvProject: companyId: ', companyId)
    // console.log('downloadCsvProject: state: ', state)
    // console.log('downloadCsvProject: dateFrom: ', dateFrom)
    // console.log('downloadCsvProject: dateTo: ', dateTo)
    // console.log('downloadCsvProject: query: ', query)
    // console.log('downloadCsvProject: paste: ', paste)
    // console.log('downloadCsvProject: format: ', format)
    // console.log('downloadCsvProject: origin: ', origin)
    // console.log('downloadCsvProject: typology: ', typology)
    // console.log('downloadCsvProject: fabricated: ', fabricated)

    let filename = 'Proyecto'
    let extension = 'xlsx'
    try {
        let result = await UpSales.Project.exportExcel(companyId, state, dateFrom, dateTo, query, paste, format, origin, typology, fabricated)
        //console.log('downloadCsvProject: result: ', result)
        if (!result || result.ok === false || result.error) {
            return false
          } else{
            const blob = await result.blob();
            const newBlob = new Blob([blob]);

            const blobUrl = window.URL.createObjectURL(newBlob);

            const link = document.createElement('a');
            link.href = blobUrl;
            link.setAttribute('download', `${filename}.${extension}`);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);

            // clean up Url
            window.URL.revokeObjectURL(blobUrl);
                return true
            }
    } catch (error) {
        console.log(error)
        return false
    }
}

export const retailCompanyName = (chart1) => {
    let newArray = []
    chart1.forEach(element => {
        let secondWord = element.split(' ')[1] ? element.split(' ')[1] : ''
        let twoWords = element.split(' ')[0] + ' '  + secondWord
        newArray.push(twoWords)
    });
    return newArray
}

export const translateBackObjectProjectAttributes = (word) => {
    switch (word) {
        case "true":
            return 'Si'
        case "false":
            return 'No'
        case true:
            return 'Si'
        case false:
            return 'No'
        case 'STARTING':
            return 'Comenzado'
        case 'IN_PROGRESS':
            return 'En desarrollo'
        case 'ADVANCED':
            return 'Avanzado'
        case 'FINISHED':
            return 'Éxito'
        case 'ARCHIVED':
            return 'Anulado'
        case 'colour':
            return 'Color'
        case 'description':
            return 'Comentarios'
        case 'format':
            return 'Formato'
        case 'glaze':
            return 'Esmalte'
        case 'grain':
            return 'Granilla'
        case 'hasReliefReader':
            return 'Lector Relieves'
        case 'injection':
            return 'Inyección'
        case 'mica':
            return 'Mica'
        case 'name':
            return 'Nombre'
        case 'numberOfReliefs':
            return 'Nº Relieves'
        case 'origin':
            return 'Orígen'
        case 'paste':
            return 'Pasta'
        case 'perimeter':
            return 'Perímetro'
        case 'reliefName':
            return 'Nombre Relieve'
        case 'rocker':
            return 'Rocker'
        case 'roller':
            return 'Rodillo'
        case 'screen':
            return 'Pantalla'
        case 'shine':
            return 'Brillo'
        case 'state':
            return 'Situación'
        case 'surface':
            return 'Superficie'
        case 'startingDate':
            return 'Fecha Solicitud'
        case 'typology':
            return 'Tipología'
        case 'fabricated':
            return 'fabricado'
        case null:
            return '""'
        case "":
            return '""'
        default:
            return word
            break;
    }
}

export const projectEditedValuesDifference = (oldObj, newObj) => {
    let result = []
    Object.keys(oldObj).forEach(key => {
        if (oldObj[key] || newObj[key]) {
            if (key !=='company' && key !=="id") {
                if(oldObj[key] !== newObj[key]){
                    result.push({key: key, oldValue: oldObj[key], newValue: newObj[key]})
                }
            } 
            if ( key ==="company") {
                if(oldObj[key].alias !== newObj[key].alias){
                    result.push({key: key, oldValue: oldObj[key].alias, newValue: newObj[key].alias})
                }
            } 
        }
    });
    let finalInteractionContent = 'Proyecto modificado:\n'
    result.forEach(item => {
        let key = translateBackObjectProjectAttributes(item.key)
        let oldValue = translateBackObjectProjectAttributes(item.oldValue)
        let newValue = translateBackObjectProjectAttributes(item.newValue)
        if (key === 'Comentarios') {
            finalInteractionContent +=  'Comentarios: Texto modificado.' + '\n'
        } else {
            if(key ==='company'){
               finalInteractionContent +=  'Compañía: ' + '"' + oldValue + '"' + ' cambiado a ' + '"' + newValue + '"' + '\n'
            } else {
                finalInteractionContent +=  key + ': ' + '"' + oldValue + '"' + ' cambiado a ' + '"' + newValue + '"' + '\n'
            }
        }
      })
   return finalInteractionContent 
} 

export const capitalizeString = (data) => {
    let newArray = []
    data.forEach(element =>{
        element = element.toLowerCase()
        element = element.replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())));
        newArray.push(element)
    })
    return newArray
}

export const changeStatusNameToSpanishInArray = (data)=>{
    let newArray = []
    data.forEach(element =>{
        element = changeStatusNameToSpanish(element)
        newArray.push(element)
    })
    return newArray
}

export const changeStatusNameToSpanish =(word)=>{
    switch (word) {
      case 'STARTING':
        return 'Comenzado'
      case 'IN_PROGRESS':
        return 'En desarrollo'
      case 'ADVANCED':
        return 'Terminado'
      case 'FINISHED':
        return 'Éxito'
      case 'ARCHIVED':
        return 'Anulado'
      case 'fabricated':
        return 'Fabricado'
      default:
        break;
    }
  }

  export const translateStatusNameToEnglish =(word)=>{
    switch (word) {
        case 'Comenzado':
            return 'STARTING'
        case 'En desarrollo':
            return 'IN_PROGRESS'
        case 'Terminado':
            return 'ADVANCED'
        case 'Éxito':
            return 'FINISHED'
        case 'Anulado':
            return 'ARCHIVED'
        case 'Fabricado':
            return 'fabricated'
        default:
            break;
    }
  }

export const convertDate = (date) => {
    let temp = date.split('/')
    let newDate = temp[2] + '-' + temp[1] + '-' + temp[0]
    return newDate
}


