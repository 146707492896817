import React, { Component } from "react";
import Media from "react-bootstrap/Media";

//import userImg from '../assets/img/UserAvatarImage.png'
import icTelNrnj from "../assets/img/ic-phone.png";
import icDownloadNrnj from "../assets/img/ic-download-orange.png";
import icCorreoNrnj from "../assets/img/ic-correo-naranja.png";
import icGeoNrnj from "../assets/img/ic-geolocaliza.png";
import icLupa from "../assets/img/ic-lupa.png";
import userImgPlaceholder from "../assets/img/UserAvatarIconPlaceholder.png";

import fakeGoogleMaps from "../assets/img/fakeGoogleMaps.png";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Accordion } from "react-bootstrap";
import {
  listEmails,
  listPhones,
} from "../api_communication/service/ClientService";

import ClientDocumentList from "./detallesClientes/ClientDocumentList";

import UserEditModal from "./usuarios/UserEdit";
import UserEditPasswordModal from "../componentes/usuarios/UserEditPassword";

import { getCurrentUserData } from "../api_communication/service/UsersService";
import { signout } from "../api_communication/service/AuthService";

function ShowArrayEmails(props) {
  var array = props.emails;
  return (
    <div>
      {array.map((element, i) => {
        return (
          <p style={{textAlign:'left'}} key={i}>
            <span style={{ color: "#000" }}>{element.name ? element.name + ': ' : ''}</span>
            <a
              href={"mailto:" + `${element.value}`}
              className="textHoverDefault"
              title="Enviar e-mail a esta dirección"
            >
              {element.value}
            </a>
          </p>
        );
      })}
    </div>
  );
}

function ShowArrayPhones(props) {
  var array = props.phones;
  return (
    <div>
      {array.map((element, i) => {
        return (
          <p style={{textAlign:'left'}} key={i}>
            <span style={{ color: "#000" }}>{element.name ? element.name + ': ' : ''}</span>
            <a
              href={"tel:" + `${element.value}`}
              className="textHoverDefault"
              title="Llamar a este número de teléfono"
            >
              {element.value}
            </a>
            
          </p>
        );
      })}
    </div>
  );
}

// <NavBarClients filterText = {this.state.filterText} onFilterTextChange={this.handleFilterTextChange} client = {this.state.clientInfo}/>
export default class NavBarClients extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listEmails: null,
      listPhones: null,
      searchInputValue: "",
      searchInputPlaceholder: "Buscar...",
      storageId: null,
      userName: null,
      showModal: false,
      showEditPasswordModal: false,
      srcPhoto: null,
    };
    this.handleFilterTextChange = this.handleFilterTextChange.bind(this);
    this.handleKeyboardInputSubmit = this.handleKeyboardInputSubmit.bind(this);
  }

  sendInputInfo = (value) => {
    let newPlaceholderAddon = this.state.searchInputValue;
    if (this.state.searchInputValue == "") {
      this.setState({
        searchInputPlaceholder: "Buscar...",
        searchInputValue: "",
      });
    } else {
      this.setState({
        searchInputPlaceholder: "Filtrando por: " + newPlaceholderAddon,
        searchInputValue: "",
      });
    }
    this.props.onFilterTextChange(value);
  };

  handleFilterTextChange(e) {
    this.setState({ searchInputValue: e.target.value });
    //this.props.onFilterTextChange(e.target.value)
  }

  handleKeyboardInputSubmit(event) {
    if (event.keyCode == 13) {
      this.sendInputInfo(this.state.searchInputValue);
    }
  }

  async componentDidMount() {
    let user = await getCurrentUserData();
    this.setState({
      srcPhoto: user.profilePhoto ? user.profilePhoto : null,
      storageId: user.id,
      userName: user.username,
    });
    if (this.props.client) {
      try {
        let phones = await listPhones(this.props.client.id);
        let emails = await listEmails(this.props.client.id);
        this.setState({ listEmails: emails, listPhones: phones });
      } catch (error) {
        console.log(error);
      }
    }
  }

  async componentDidUpdate(prevProps) {
    if (this.props.client && this.props.refresh != prevProps.refresh) {
      try {
        let phones = await listPhones(this.props.client.id);
        let emails = await listEmails(this.props.client.id);
        this.setState({ listEmails: emails, listPhones: phones });
      } catch (error) {
        console.log(error);
      }
    }
  }
  showEditUserModalFn = () => {
    this.setState({
      showModal: true,
    });
  };

  hiddeEditUserModalFn = () => {
    this.setState({
      showModal: false,
    });
  };

  showEditUserPasswordModalFn = () => {
    this.setState({
      showEditPasswordModal: true,
    });
  };

  hiddeEditUserPasswordModalFn = () => {
    this.setState({
      showEditPasswordModal: false,
    });
  };

  logout = () => {
    signout();
  };

  render() {
    return (
      <React.Fragment>
        <Row>
          <UserEditModal
            userId={this.state.storageId}
            userName={this.state.userName}
            show={this.state.showModal}
            hide={this.hiddeEditUserModalFn}
            photo={this.state.srcPhoto}
          />
          <UserEditPasswordModal
            userId={this.state.storageId}
            userName={this.state.userName}
            show={this.state.showEditPasswordModal}
            hide={this.hiddeEditUserPasswordModalFn}
          />
          <Col xs={12}>
            <Accordion className="d-flex text-right row">
              <Col
                className="d-flex justify-content-end align-items-center"
                xs={12}
              >
                {/* <Accordion.Toggle as={'div'} eventKey="0">
                                <Media className="ic-nrnj user-img mx-1">
                                    <img
                                    alt="Búsqueda de interacciones"
                                    title="Búsqueda de interacciones"
                                    className="img-resp"
                                    src={icLupaNrnj}
                                    ></img>
                                </Media>
                            </Accordion.Toggle> */}

                <Accordion.Toggle as={"div"} eventKey="1">
                  <Media className="ic-nrnj user-img mx-1">
                    <img
                      alt="Listado de teléfonos"
                      title="Listado de teléfonos"
                      className="img-resp"
                      src={icTelNrnj}
                    ></img>
                  </Media>
                </Accordion.Toggle>

                <Accordion.Toggle as={"div"} eventKey="2">
                  <Media className="ic-nrnj user-img mx-1">
                    <img
                      alt="Listado de e-mails"
                      title="Listado de e-mails"
                      className="img-resp"
                      src={icCorreoNrnj}
                    ></img>
                  </Media>
                </Accordion.Toggle>

                <Accordion.Toggle as={"div"} eventKey="3">
                  <Media className="ic-nrnj user-img mx-1">
                    <img
                      alt="Listado de Archivos"
                      title="Listado de Archivos"
                      className="img-resp"
                      src={icDownloadNrnj}
                    ></img>
                  </Media>
                </Accordion.Toggle>

                <Accordion.Toggle as={"div"} eventKey="4">
                  <Media className="ic-nrnj user-img mx-1">
                    <img
                      alt="Google Maps"
                      title="Google Maps"
                      className="img-resp"
                      src={icGeoNrnj}
                    ></img>
                  </Media>
                </Accordion.Toggle>

                <div id="dropdownUserMenu" className="dropdown">
                  <div
                    className="dropdown-toggle"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <Media className=" mouse ml-1 d-center" title="logout">
                      <div>
                        <img
                          alt="User Avatar"
                          className="user-img"
                          src={
                            this.state.storageId
                              ? (this.state.srcPhoto ? this.state.srcPhoto : userImgPlaceholder)
                              : userImgPlaceholder
                          }
                        ></img>
                      </div>
                    </Media>
                  </div>
                  <div
                    className="dropdown-menu dropdown-menu-right"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <p
                      onClick={this.showEditUserModalFn}
                      className="dropdown-item mouse color-link l-fs-15"
                    >
                      Editar usuario
                    </p>
                    {/* <p className="dropdown-item mouse color-link l-fs-15" >Otra opción</p>
                                    <p className="dropdown-item mouse color-link l-fs-15" >Otra opción hover</p> */}
                    <div className="dropdown-divider"></div>
                    {/* Opción cambiar contraseña */}
                    <p
                      onClick={this.showEditUserPasswordModalFn}
                      className="dropdown-item mouse color-link l-fs-15"
                    >
                      Cambiar contraseña
                    </p>
                    <div className="dropdown-divider"></div>
                    <p
                      onClick={this.logout}
                      className="dropdown-item mouse color-link l-fs-15"
                    >
                      Logout
                    </p>
                  </div>
                </div>
              </Col>
              <Col xs={12} className="text-center">
                <Accordion.Collapse eventKey="0" className="bg-blue-light my-3">
                  <div className="py-5 d-flex flex-row justify-content-center ">
                    <input
                      id="filterInteractions"
                      className="input-index pl-3 pr-3"
                      placeholder={this.state.searchInputPlaceholder}
                      type="text"
                      value={this.state.searchInputValue}
                      onChange={this.handleFilterTextChange}
                      onKeyDown={this.handleKeyboardInputSubmit}
                    />
                    <div
                      onClick={() =>
                        this.sendInputInfo(this.state.searchInputValue)
                      }
                      className="clientSearchFormBtn d-center"
                    >
                      <img className="" src={icLupa}></img>
                    </div>
                  </div>
                </Accordion.Collapse>
                <Accordion.Collapse eventKey="1" className="bg-blue-light my-3">
                  <div className="py-4 d-column">
                    <p style={{marginBottom:10}}>
                      <b>Listado de teléfonos:</b>
                    </p>
                    {this.state.listPhones && (
                      <ShowArrayPhones phones={this.state.listPhones} />
                    )}
                  </div>
                </Accordion.Collapse>
                <Accordion.Collapse eventKey="2" className="bg-blue-light my-3">
                  <div className="py-4 d-column">
                    <p style={{marginBottom:10}}>
                      <b>Listado de Emails:</b>
                    </p>
                    {this.state.listEmails && (
                      <ShowArrayEmails emails={this.state.listEmails} />
                    )}
                  </div>
                </Accordion.Collapse>
                <Accordion.Collapse eventKey="3" className="bg-blue-light my-3">
                  <React.Fragment>
                    <p className="py-4">Listado de Archivos:</p>
                    {this.props.client && (
                      <ClientDocumentList clientId={this.props.client.id} />
                    )}
                  </React.Fragment>
                </Accordion.Collapse>
                <Accordion.Collapse className="bg-blue-light" eventKey="4">
                  <div className="my-3">
                    <img src={fakeGoogleMaps} />
                  </div>
                </Accordion.Collapse>
              </Col>
            </Accordion>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}
