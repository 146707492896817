import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Media from "react-bootstrap/Media";

import { Link } from "react-router-dom";
import moment from "moment";

import imgBigOportunity from "../../assets/img/ic-oportunidad-big.png";
import FilesDragAndDrop from '../FilesDragAndDrop'
import {
  archiveInteraction,
  createInteraction,
} from "../../api_communication/service/ClientService";

import { ShowPhotosAndDocsPreview } from "../MediaManager";

// TO DO Unificar componente con CreateOpportunity en fichero FilterableOpportunityTable.js
export default function CreateOpportunity(props) {

  const [show, setShow] = useState(false);
  const [idCompany, setIdCompany] = useState(null);
  const [companyName, setCompanyName] = useState(null);
  const [opportunityObject, setOpportunityObject] = useState(null);

  // Save and preview docs and images
  const [preview, setPreview] = useState(false);
  const [imageOrDocToShow, setImageOrDocToShow] = useState(null);
  const [saveImagesAndDocs, setSaveImagesAndDocs] = useState(null);
  const [interaction, setInteraction] = useState(null);
  const [savingAnimation, setSavingAnimation] = useState(false)

  useEffect(() => {
    setIdCompany(props.idCompany);
    setCompanyName(props.companyName);
  }, []);

  const createOpportunity = () => {

    var opportunityName = document.getElementById("opportunityName").value;
    var opportunityDescription = document.getElementById("opportunityDescription").value;

    if (opportunityName == "") {
      props.showUpSalesMessageFn("warning", "Debe añadir un título para la oportunidad", true, true);

    } else {
      if (idCompany == null) {
        props.showUpSalesMessageFn("warning", "Debe seleccionar un nombre de compañía existente", true, true);
      } else {
        setSavingAnimation(true)
        let interactionBody = {
          date: moment().format("DD/MM/YYYY") + " " + moment().format("HH:mm:ss"),
          content: "Oportunidad Creada",
          mode: "text",
          opportunity: {
            name: opportunityName,
            description: opportunityDescription ? opportunityDescription : null,
            state: "OPEN",
          },
        };
        let savedInteraction = createInteraction(idCompany, interactionBody)
          .catch((error) => {
            console.log("Ha ocurrido un error al crear la interacción. ", error);
          })
          .then(async (result) => {

            setOpportunityObject(result.opportunity);
            setInteraction(result);
            if(imageOrDocToShow) setSaveImagesAndDocs(true);
            if(!imageOrDocToShow) {
              await archiveInteraction(result.companyId, result.id);
              props.showUpSalesMessageFn("success", "Oportunidad creada. Redirigiendo...", true, true);
              document.getElementById("linkToOpportunityDetails").click();
              setTimeout(() => {
                window.location.reload();
              }, 1500);
            }            
          });
      }
    }
  };

  const callToShowImageOrDoc = (e) => {
    setImageOrDocToShow(e);
    setPreview(true);
  };

  const cancelForm = () => {
    setShow(false);
  };

  const uploadingFinished = (value)=>{
    if(value) {
      cancelForm();
      props.showUpSalesMessageFn("success", "Oportunidad creada. Redirigiendo...", true, true);
      document.getElementById("linkToOpportunityDetails").click();
      setTimeout(() => {
        window.location.reload();
      }, 1500);
    } else {
        props.showUpSalesMessage('danger', 'Ha ocurrido un error guardando archivos.', true, false)
    }
    setSavingAnimation(false)
  }

  return (
    <React.Fragment>
      <Button
        className="modal-big-button"
        variant="primary"
        onClick={() => setShow(true)}
      >
        Crear Oportunidad
      </Button>

      <Modal
        id="modalPositionRight"
        show={show}
        onHide={() => setShow(false)}
        dialogClassName="bg-white"
        aria-labelledby="example-custom-modal-styling-title"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header className="justify-content-center modal-border">
          <Modal.Title
            id="example-custom-modal-styling-title"
            className="dark-blue d-column"
          >
            <b className="mb-4 text-center">CREAR UNA OPORTUNIDAD</b>
            <Media>
              <img src={imgBigOportunity}></img>
            </Media>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-column generaltextInputStyle">
            <div>{companyName}</div>

            <input
              type="text"
              className="my-3"
              id="opportunityName"
              placeholder="Nombre Oportunidad"
            ></input>

            <textarea
              className="my-3"
              id="opportunityDescription"
              type="text"
              placeholder="Descripción"
            ></textarea>

            <div className='py-3'>
              <FilesDragAndDrop fileSelected={callToShowImageOrDoc}/>
            </div>

            {preview && imageOrDocToShow && (
              <div id="preview" className="w-100 py-3">
                <ShowPhotosAndDocsPreview
                  isItATask={false}
                  imageOrDocToShow={imageOrDocToShow}
                  saveImagesAndDocs={saveImagesAndDocs}
                  idCompany={idCompany}
                  idTaskOrInteraction={interaction ? interaction.id : null}
                  uploadingFinished={uploadingFinished} 
                />
              </div>
            )}

            <div className="d-inline-flex my-5">
              <div
                className="d-center boton-cancelar mr-3"
                onClick={cancelForm}
              >
                Cancelar
              </div>
              <button disabled={savingAnimation ? true : false} className="d-center boton-guardar" onClick={createOpportunity}>
                {savingAnimation && <span className="spinner-grow spinner-grow-sm mr-2" role="status" aria-hidden="true"></span>}
                Guardar
              </button>
            </div>
            <Link
              id="linkToOpportunityDetails"
              className="row"
              to={{
                pathname: "/DetallesOportunidad",
                state: opportunityObject,
              }}
            />
          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
}
