import { UpSales } from "../bundle/bundle";

// UpSales.InteractionReport.countByModeKPIs(companyId, userId, dateFrom, dateTo)
export const loadReportsIndicatorsRepo = async (companyId, userId, dateFrom, dateTo, countryId, provinceId, localityId, postalCode) => {
    try {
      let response = await UpSales.InteractionReport.countByModeKPIs(companyId, userId, dateFrom, dateTo, countryId, provinceId, localityId, postalCode)
      return response;
    } catch (error) {
      console.log("ERROR Reports-REPO: loadReportsIndicatorsRepo: ", error);
      throw Error(error);
    }
  };

// UpSales.InteractionReport.countByModeCompanyTableExportExcel(dateFrom, dateTo, filename)
export const exportReportRepo = async (dateFrom, dateTo, filename) => {
  try {
    let response = await UpSales.InteractionReport.countByModeCompanyTableExportExcel(dateFrom, dateTo, filename)
    return response;
  } catch (error) {
    console.log("ERROR Reports-REPO: exportReportRepo: ", error);
    throw Error(error);
  }
};