// cuando el menu está desplegado
export function abrirEsconderMenuLateral(){ //client-body
        document.getElementById('menu').classList.add('d-none')
        document.getElementById('ic-menu-out').classList.remove('d-none')
        if (document.getElementById('client-body')) {
        document.getElementById('client-body').classList.add('col')}
}
// cuando el menu está escondido
export function esconderMenuLateral(){
    document.getElementById('menu').classList.remove('d-none')
    document.getElementById('ic-menu-out').classList.add('d-none')
    if (document.getElementById('client-body')) {
    document.getElementById('client-body').classList.remove('d-block')}
    
}