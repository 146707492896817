import React, {useState, useEffect} from 'react'
import Modal from 'react-bootstrap/Modal'
import Media from 'react-bootstrap/Media'
import { ModalBody } from 'react-bootstrap'

// PROJECT FUNCTIONS
import { updateProject, projectCreateInteraction,changeStatusNameToSpanish } from './funcionesProyectos'
// EXTERNAL LIBRARIES
import moment from 'moment';
import '../../assets/css/calendarDatePicker/Calendar.css'
// IMAGES
import icProyectos from '../../assets/img/ic-proyecto-nuevo-big.png'


export default function ChangeProjectState(props) {
    const [showModal, setShowModal] = useState(false)
    const [savingAnimation, setSavingAnimation] = useState(false)
    const [projectState, setProjectState] = useState(null)
    const [oldProjectState, setOldProjectState] =useState(null)

    useEffect(() => {
        setShowModal(props.show)
        setProjectState(props.project.state)
        setOldProjectState(props.project.state)
    }, [props.show])

    const SubmitForm = async () => {
      setSavingAnimation(true)
      let body = props.project
      body.state = projectState

      try {
        let response = await updateProject(props.project.id, body)
        props.showUpSalesMessageFn('success','Estado cambiado satifactoriamente!', true, true)
        let time = moment().format("HH:mm:ss")
        let bodyInteraction = {
            date: moment().format("DD/MM/YYYY") + ' ' + time,
            content: `Estado del proyecto cambiado: ${changeStatusNameToSpanish(oldProjectState)} -> ${changeStatusNameToSpanish(projectState)}`,
            mode: "text"
            }
        let interaction = await projectCreateInteraction(props.project.id, bodyInteraction)
        setSavingAnimation(false)
        if (interaction) {
            props.refresh()
            props.close()
            } else {
            props.showUpSalesMessageFn('danger','Error al crear la interacción!', true, false)
            props.refresh()
            }
        
      } catch (error) {
        console.log(error)
        props.showUpSalesMessageFn('danger','Error al cambiar el estado del proyecto.', true, false)
        setSavingAnimation(false)
      }
    }

    const closeThisModal = ()=>{
        setSavingAnimation(false)
        props.close()
    }

    const handleState = (event) => {
        setProjectState(event.target.value)
    }

    return (
        <React.Fragment>
            <Modal
                style={{ overflow: "hidden" }}
                id=""
                className="modal-border"
                show={showModal}
                dialogClassName="bg-white"
                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Header className="justify-content-center modal-border">
                    <Modal.Title id="example-custom-modal-styling-title" className="dark-blue d-column">
                        <Media><img src={icProyectos}></img></Media>
                        <b className="my-2 text-center">
                            CAMBIAR ESTADO DEL PROYECTO
                        </b>
                    </Modal.Title>
                </Modal.Header>
                <ModalBody>
                    <form id="closeProject" className="d-column generaltextInputStyle light-grey">
                    <div className="col-6 d-column">
                        <p className="mb-2">Situación</p>
                        <div className='bg-input'>
                            <select className=' inputDynamicSelectOnlyDropdown bg-white l-fs-14' value={projectState} onChange={handleState}>
                                <option value="STARTING">Comenzado</option>
                                <option value="IN_PROGRESS">En desarrollo</option>
                                <option value="ADVANCED">Terminado</option>
                            </select>
                        </div>
                    </div>
                        <div className="d-flex justify-content-end my-5">
                            <div title="Cancelar operación" className="d-center boton-cancelar mr-3" onClick={()=>closeThisModal()}>Cancelar</div>
                            <div title="Cambiar estado" className="d-center boton-guardar px-2" onClick={()=>SubmitForm()}>
                                {savingAnimation && <span className="spinner-grow spinner-grow-sm pr-3" role="status" aria-hidden="true"></span>}
                                Guardar</div>
                        </div>
                    </form>
                </ModalBody>
            </Modal>
        </React.Fragment>)
}