import React, { useState } from "react";

import Media from "react-bootstrap/Media";

import LoadingAnimation from "../LoadingAnimation";
import {
  findClientById,
  saveNewCompany,
  updateCompanyLogo,
  setCompanyTags
} from "../../api_communication/service/ClientService";
import defaultLogo from "../../assets/img/logoPorDefecto.png";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";

import changeLogo from "../../assets/img/ic-editar-azul.png";

import moment from "moment";


export function CreateCompany(props) {
  const [show, setShow] = useState(false);
  const [clientObject, setClientObject] = useState(null);
  const [clientLogo, setClientLogo] = useState(defaultLogo);
  const [imagenParaGuardar, setImagenParaGuardar] = useState(null);
  const [loadingAnimation, setLoadingAnimation] = useState(false);

  const saveClient = async () => {
    var nameEnterprise = document.getElementById("enterpriseName").value;
    if (nameEnterprise == "") {
      props.showUpSalesMessageFn("error", "", true, false);
    } else {
      let body = {
        alias: nameEnterprise,
        name: `Desconocido #${moment().format("DD/MM/YYYY HH:mm")}`,
      };
      try {
        setLoadingAnimation(true);
        let nuevaEmpresa = await saveNewCompany(body);
        setCompanyTags(nuevaEmpresa.id, 1, [{id:3}]) // add state 'potencial'
        await callToSaveImage(nuevaEmpresa);
        setClientObject(nuevaEmpresa);
        setTimeout(() => {
          document.getElementById("linkToClientDetails").click();
        }, 2000);
        props.showUpSalesMessageFn(
          "success",
          "Se ha creado una nueva empresa con éxito. Redirigiendo...",
          true,
          false
        );
      } catch (error) {
        props.showUpSalesMessageFn(
            "danger",
            "Ha ocurrido un error al crear la empresa!",
            true,
            false
          );
        console.log(error);
      }
    }
  };

  const callToSaveImage = async (company) => {
    let imageData = new FormData();
    if (imagenParaGuardar){
        imageData.append("file", imagenParaGuardar);
        try {
        await updateCompanyLogo(company.id, imageData);
        let clientObject = await findClientById(company.id);
        setClientObject(clientObject);
        } catch (error) {
            props.showUpSalesMessageFn(
                "danger",
                "Ha ocurrido un error al guardar el logo.",
                true,
                false
              );
            console.log(error);
        }
    }
  };

  const callToShowLogo = (e) => {
    const image = e.target.files[0];
    var newLogo = URL.createObjectURL(image);
    setImagenParaGuardar(image);
    setClientLogo(newLogo);
  };

  const cancelForm = () => {
    setClientLogo(defaultLogo);
    setShow(false);
  };

  return (
    <>
      <Button
        className="modal-big-button"
        variant="primary"
        onClick={() => setShow(true)}
      >
        Crear Empresa
      </Button>

      <Modal
        // onEntered={()=>document.getElementById('enterpriseName').focus()}
        id="modalPositionRight"
        show={show}
        onHide={() => setShow(false)}
        dialogClassName="bg-white"
        aria-labelledby="example-custom-modal-styling-title"
        centered
        backdrop="static"
        keyboard={false}
      >
        {loadingAnimation && (
          <React.Fragment>
            {" "}
            <LoadingAnimation
              text={"Creando la nueva empresa, por favor espera un momento..."}
            />{" "}
            <Link
              id="linkToClientDetails"
              className="row"
              to={{ pathname: "/DetallesClientes", state: clientObject }}
            />{" "}
          </React.Fragment>
        )}
        {loadingAnimation == false && (
          <React.Fragment>
            <Modal.Header className="justify-content-center modal-border mt-5">
              <Modal.Title
                id="example-custom-modal-styling-title"
                className="dark-blue d-column"
              >
                <div className="my-4 text-center">
                  <b>CREAR UNA NUEVA EMPRESA</b>
                </div>
                <Media className="pos-r">
                  <img className="logo-modal" src={clientLogo}></img>
                  <div>
                    <label htmlFor="file-input">
                      <img
                        title="Cambiar logo"
                        className="edit-icon"
                        src={changeLogo}
                      />
                    </label>
                    <input
                      encType="multipart/form-data"
                      id="file-input"
                      type="file"
                      multiple={false}
                      accept="image/*"
                      onChange={callToShowLogo}
                      style={{ visibility: "hidden", width: 0, height: 0 }}
                    />
                  </div>
                </Media>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="mt-5">
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                }}
                id="createOportunity"
                className="d-column"
              >
                <input
                  className="my-3"
                  id="enterpriseName"
                  type="text"
                  placeholder="Nombre Empresa"
                ></input>

                <div className="d-inline-flex my-5">
                  <div
                    className="d-center boton-cancelar mr-3"
                    onClick={cancelForm}
                  >
                    Cancelar
                  </div>
                  <div className="d-center boton-guardar" onClick={saveClient}>
                    Guardar
                  </div>
                </div>
              </form>
            </Modal.Body>
          </React.Fragment>
        )}
      </Modal>
    </>
  );
}

