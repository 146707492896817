import {
  createOpportunityRepository,
  listOpportunitiesFilteredByStateRepository,
  countOpportunitiesByStateRepository,
  listOpportunitiesByCompanyRepository,
  countOpportunitiesByCompanyRepository,
  listInteractionsForAnOpportunityRepository,
  fetchGetOpportunityByIdRepository,
  listOpportunityInteractionsRepository,
  listAllOpportunitiesFilteredByStateRepository,
  listAllOpportunitiesRepository,
  listOpportunitiesbyNameAndCompanyRepository,
  modifyOpportunityRepository,
  countActiveTasksForOneOpportunityRepository,
  countOverdueTasksForOneOpportunityRepository,
  countOverdueTasksForOpenOpportunitiesRepository,
  countOverdueTasksForOpenOpportunitiesForOneCompanyRepository,
  opportunityListArchivedInteractionsRepository,
  closeLostOpportunityRepository,
  closeWonOpportunityRepository
} from "../repository/OpportunityRepository"
import { listTasksForAnOpportunity } from "./ClientService";
import { closeTask } from "./TaskService";

import moment from 'moment';

// UpSales.Opportunity.createOpportunity(body)
export const createOpportunityFn = async (body) => {
  try {
    let result = await createOpportunityRepository(body);
    return result;
  } catch (e) {
    console.log(e);
  }
};

// Opportunity - Get opportunity
export var fetchGetOpportunityById = async (id) => {
  var result = await fetchGetOpportunityByIdRepository(id);
  return result;
};

export const solveAllTasksForOpportunity = async (opportunityId) => {
  let body = {
    creationDate:  moment().format('DD/MM/YYYY HH:mm:ss')
  }
  
  try {
    let allTasks = await listTasksForAnOpportunity(0, 10000000, '', '', opportunityId, 'ACTIVE')
    allTasks.data.forEach(async element => {
        await closeTask(element.company.id, element.id, body)
    });
    
  } catch(error) {
    console.log(error)
  }
}

export const isOpportunityOpen = (opportunity) => {
  return opportunity.state === 'OPEN'
}

export const closeLostOpportunity = async (id) => {
  return closeLostOpportunityRepository(id)
}

export const closeWonOpportunity = async (id) => {
  return closeWonOpportunityRepository(id)
}

// Opportunity - Modify opportunity
export const modifyOpportunity = async (opportunityId, body) => {
  let result = await modifyOpportunityRepository(opportunityId, body);
  return result;
};

// List all opportunities

export async function listAllOpportunities(page, pageSize, query) {
  let result = await listAllOpportunitiesRepository(page, pageSize, query);
  return result;
}

export var listAllOpportunitiesFilteredByState = async (stateName, page, pageSize, query) => {
  let result = await listAllOpportunitiesFilteredByStateRepository(stateName, page, pageSize, query);
  return result;
};

export var listOpenOpportunities = async (page, pageSize, query) => {
  let result = await listAllOpportunitiesFilteredByStateRepository('OPEN', page, pageSize, query);
  return result;
};

export var listWonOpportunities = async (page, pageSize, query) => {
  let result = await listAllOpportunitiesFilteredByStateRepository('CLOSED-WON', page, pageSize, query);
  return result;
};

export var listLostOpportunities = async (page, pageSize, query) => {
  let result = await listAllOpportunitiesFilteredByStateRepository('CLOSED-LOST', page, pageSize, query);
  return result;
};

// List opportunities for one client

export var listOpportunitiesByCompany = async (clientId, page, pageSize) => {
  var result = await listOpportunitiesByCompanyRepository(clientId, page, pageSize)
  return result;
};

// state = OPEN, CLOSED-WON, CLOSED-LOST
export var listOpportunitiesFilteredByState = async (clientId, state, page, pageSize) => {
  let result = await listOpportunitiesFilteredByStateRepository(clientId, state, page, pageSize);
  return result;
};

export var listOpenOpportunitiesForClient = async (clientId, page, pageSize) => {
  let result = await listOpportunitiesFilteredByStateRepository(clientId, 'OPEN', page, pageSize);
  return result;
};

export var listLostOpportunitiesForClient = async (clientId, page, pageSize) => {
  let result = await listOpportunitiesFilteredByStateRepository(clientId, 'CLOSED-LOST', page, pageSize);
  return result;
};

export var listWonOpportunitiesForClient = async (clientId, page, pageSize) => {
  let result = await listOpportunitiesFilteredByStateRepository(clientId, 'CLOSED-WON', page, pageSize);
  return result;
};

// Count opportunities

export var countOpportunitiesByState = (stateName) => {
  var result = countOpportunitiesByStateRepository(stateName);
  return result;
};

export var countOpenOpportunities = () => {
  var result = countOpportunitiesByStateRepository('OPEN');
  return result;
};

export var countLostOpportunities = () => {
  var result = countOpportunitiesByStateRepository('CLOSED-LOST');
  return result;
};

export var countWonOpportunities = () => {
  var result = countOpportunitiesByStateRepository('CLOSED-WON');
  return result;
};

// Count opportunities for client

export var countOpportunitiesByCompany = async (clientId, oportState) => {
  var result = await countOpportunitiesByCompanyRepository(clientId, oportState);
  return result;
};

export var countOpenOpportunitiesForClient = async (clientId) => {
  var result = await countOpportunitiesByCompanyRepository(clientId, 'OPEN');
  return result;
};

export var countWonOpportunitiesForClient = async (clientId) => {
  var result = await countOpportunitiesByCompanyRepository(clientId, 'CLOSED-WON');
  return result;
};

export var countLostOpportunitiesForClient = async (clientId) => {
  var result = await countOpportunitiesByCompanyRepository(clientId, 'CLOSED-LOST');
  return result;
};

// listOpportunityInteractions
export const listOpportunityInteractions = async (opportunityId) => {
  try {
    let listInteractions = await listOpportunityInteractionsRepository(
      opportunityId
    );
    return listInteractions;
  } catch (error) {
    console.log(error);
  }
};

//Opportunity - List opportunities filtered by name and company name
export const listOpportunitiesbyNameAndCompany = async (stateName, page, pageSize, query) => {
  let result = await listOpportunitiesbyNameAndCompanyRepository(stateName, page, pageSize, query);
  return result;
};

// Interaction_Task - Count tasks for one opportunity filtered by scheduling state.
// let countActive = UpSales.Task.countActiveTasksForOneOpportunity(opportunityId)
// Tb nos sirve para poner icono candado naranja o rojo
export const countActiveTasksForOneOpportunity = async (opportunityId) => {
  let result = await countActiveTasksForOneOpportunityRepository(opportunityId);
  return result;
};

// Interaction_Task - Count tasks for one opportunity filtered by scheduling state.
// let countActive = UpSales.Task.countActiveTasksForOneOpportunity(opportunityId)
// Tb nos sirve para poner icono candado naranja o rojo
export const countOverdueTasksForOneOpportunity = async (opportunityId, currentDate) => {
  let result = await countOverdueTasksForOneOpportunityRepository(opportunityId, currentDate);
  return result;
};

// total count UpSales.Task.countOverdueTasksForOpenOpportunities(currentDate)
export const countOverdueTasksForOpenOpportunities = async (currentDate) => {
  let result = await countOverdueTasksForOpenOpportunitiesRepository(currentDate);
  return result;
};

//Interaction_Task - Count tasks for one company filtered by opportunity and scheduling states.
// UpSales.Task.countOverdueTasksForOpenOpportunitiesForOneCompany(companyId, currentDate)
export const countOverdueTasksForOpenOpportunitiesForOneCompany = async (companyId, currentDate) => {
  let result = await countOverdueTasksForOpenOpportunitiesForOneCompanyRepository(companyId, currentDate);
  return result;
};

// Opportunity - List interactions for an opportunity // De momento sin paginación listInteractionsForAnOpportunityRepository
export var listInteractionsForAnOpportunity = async ( opportunityId, numPage ) => {
  var result = await listInteractionsForAnOpportunityRepository(opportunityId, numPage);
  return result;
};

//  Interaction - List by mode (one opportunity)
// UpSales.Opportunity.listInteractions(opportunityId, page, pageSize, mode, states)
export const opportunityListArchivedInteractions = async (opportunityId, page, pageSize, mode, states) => {
  let result = await opportunityListArchivedInteractionsRepository(opportunityId, page, pageSize, mode, states);
  return result;
};
