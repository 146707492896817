import React, { useState, useEffect } from "react";
import "../../assets/css/App.css";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { Link, NavLink, useLocation } from "react-router-dom";

// COMPONENTS
import LateralMenu from "../LateralMenu";
import SearchBar from "../SearchBar";
import LoadingAnimation from "../LoadingAnimation";
import LoadingAnimationMiniSize from "../LoadingAnimationMiniSize";
import UpSalesMessage from "../UpSalesMessage";
import { DynamicSelect } from "../selects/DynamicSelect";

// SERVICES
import {
  listCompaniesForAUser,
  removeUserFromCompany,
  addUserFromCompany
} from "../../api_communication/service/UsersService";

import { findAllClientsService } from "../../api_communication/service/ClientService";

//FUNCION CERRAR MENU
import { esconderMenuLateral } from "../../assets/js/menuLateral";

//IMAGENES
import defaultImg from "../../assets/img/up-last.png"; // img by default
import icVer from '../../assets/img/ic-ver.png'
import btnMas from "../../assets/img/btn-mas.png";
import icEliminar from "../../assets/img/ic-link-roto-gris-oscuro.png"

function Rowuser(props) {
  const company = props;
  const companyObject = {
    id: company.id
  }
  return (
    <Col xs={3} className="bg-white py-4 m-2 d-flex justify-content-between align-items-center" style={{position: 'relative'}}>
     <div className="d-flex align-items-center pr-3">
        <div className="mr-3">
          <Link to={{pathname:'/DetallesClientes', state: companyObject}} title="Ver detalles de esta empresa">
            <img
              className="listCompaniesForAUserImg"
              src={company.thumbnail ? company.thumbnail : defaultImg}
            />
          </Link>
        </div>
        <Link to={{pathname:'/DetallesClientes', state: companyObject}} style={{color: '#757575', textDecoration:'none'}} title="Ver detalles de esta empresa">
          <div style={{fontSize: '15px',lineHeight:'17px',wordBreak: 'break-word'}} className="">{company.name !=='' ? company.name : 'Sin nombre asignado'}</div>
        </Link>
      </div>
      
      <div className="d-flex justify-content-end align-items-center">
        <Link to={{pathname:'/DetallesClientes', state: companyObject}} className="d-flex align-items-center" style={{color: '#757575', textDecoration:'none'}} title="Ver detalles de esta empresa">
          <div className="mr-3 mb-1">
            <img
              className=""
              style={{width:21}}
              src={icVer}
            />
          </div>
        </Link>
        <div onClick={() => props.deleteCompany(company.id)} className="deleteUserCompany mr-2" style={{position:'relative',top:0,right:0}}>
          {/* <div
            id={company.id + "-company"}
            className="mouse"
            title={`Eliminar cliente`}>
            X
          </div> */}
          <img  className="mouse mb-1" title={`Desvincular cliente`} src={icEliminar}/>
        </div>
      </div>
    </Col>
  );
}

function CompaniesList(props) {
  const rowlist = [];

  props.listProp &&
    props.listProp.forEach((element) => {
      rowlist.push(
        <Rowuser
          id={element.id}
          thumbnail={element.thumbnail}
          name={element.alias}
          key={element.id}
          deleteCompany={props.deleteCompany}
        />
      );
    });
  if (props.listProp && props.listProp.length !== 0) {
    return <React.Fragment>{rowlist}</React.Fragment>;
  } else {
    if (props.listProp !== null && props.listProp.length === 0) {
      return (
        <React.Fragment>
          {!props.showMiniLoadingAnimation && <Col className="text-center light-grey">
           No hay empresas para este usuario
        </Col>}
        </React.Fragment>
      );
    } else {
      if (props.listProp === null)
        return (
          <Col>
            <LoadingAnimation />
          </Col>
        );
    }
  }
}

export default function UsuariosEmpresas() {
  const location = useLocation();
  const [userObject, setUserObject] = useState(null);
  const [userId, setUserId] = useState(null);
  const [userName, setUserName] = useState(null)
  const [companies, setCompanies] = useState(null);
  const [userFilterInputWriting, setUserFilterInputWriting] = useState('')
  // UPSALES MSGS
  const [showModal, setShowModal] = useState(false);
  const [msg,setMsg] = useState(null)
  const [errorFromBackend, setErrorFromBackend] = useState(false)
  
  const [companyIdToDelete, setCompanyIdToDelete] = useState(null);
  const [showLoadingAnimation, setShowLoadingAnimation] = useState(true);
  const [showMiniLoadingAnimation, setShowMiniLoadingAnimation] = useState(false);
  const [currentPage, setCurrentPage] = useState(0)
  const [totalCompanies, setTotalCompanies] = useState(0)
  const [totalPages, setTotalPages] = useState(0)
  const [maxPageSize, setMaxPageSize] = useState(24)
  // ADD CLIENT TO USER
  const [clientsArray, setClientsArray] = useState([]) // para rellenear select clients
  const [clientId, setClientId] = useState(null) // state id cliente seleccionado por user (null / valor)
  const [resetClientSearch, setResetClientSearch] = useState(false)

  const [searchTerm, setSearchTerm] = useState(null)

  useEffect(() => {
    let user = location.state;
    setUserObject(user);
    let userId = user.id;
    setUserId(userId);
    let userName = user.name
    setUserName(userName)

    const timer = setTimeout(() => {
      getUserData(userId, searchTerm);
    }, 200)

    return () => clearTimeout(timer)

  }, [location, searchTerm]);

  useEffect(() => {
    document.title = "Usuarios empresas| UpSales";
    return () => {};
  }, []);

  async function getUserData(userId, searchTerm) {
    try {
      let companies = await listCompaniesForAUser(userId, currentPage, maxPageSize, searchTerm);
      if (errorManager(companies) === false){
        setTotalCompanies(companies.totalElements)
        setCompanies(companies.data);
        setTotalPages(companies.totalPages)
        setShowLoadingAnimation(false);
      } 
    } catch (error) {
      console.log(error);
    }
  }

  // handle select con input 
  const handleClient = async (userClientInput, userClientSelect) => {
    // userClientInput: user está escribiendo en input 
    // userClientSelect: user ha seleccionado una opción del desplegable
    const CLIENT_PAGINATION_SIZE = 100000
    setResetClientSearch(false)
    document.getElementById('addUserErrorMsg').classList.add('d-none')
    try {
        if (userClientSelect === null) {
            // SI USER BORRA BÚSQUEDA, INICIALIZAMOS TABLAS
            if(!userClientInput || userClientInput.length ===0) {
                setClientId(null)
                setClientsArray([])
            }
            // RELLENAMOS DESPLEGABLE
            if (userClientInput && userClientInput.length > 2) {
              // findAllClientsRepository = async (page, pageSize, query, countryId, provinceId)
              let result = await findAllClientsService(0, CLIENT_PAGINATION_SIZE, userClientInput)
              // TO DO: CAMBIAR EL RETORNO DE ESTA FUNCIÓN EN TODA LA APP PARA GESTIONAR 
              // LOS ERRORES CON THROW ERROR
              setClientsArray(result)
            }
        } else {
            // check if client is in companies array
            let isClientIdInCompaniesArray = companies.find(element=>{return element.id == userClientSelect})
            if (isClientIdInCompaniesArray){
              // show message
              document.getElementById('addUserErrorMsg').classList.remove('d-none')
            }  else {
              setClientId(userClientSelect)
              document.getElementById('addUserBtn').classList.remove('d-none')
            }
            
        }
    } catch (error) {
        console.log(error)
    }
  }

  const addUserToCompany = async()=>{
      try {
        let result = await addUserFromCompany(clientId, userId)
        if (errorManager(result) === false){
          cancelAddUserToClient()
          refreshClientsList()
        } 
      } catch (error) {
        console.log(error)
      }    
  }

  const deleteCompany = (companyId) => {
    setCompanyIdToDelete(companyId);
    setMsg("Está seguro de eliminar este cliente?")
    setErrorFromBackend(false)
    setShowModal(true);
  };

  const closeUpsalesMsg = () => {
    setShowModal(false);
  };

  const callBackAnswerQuestion = async () => {
    closeUpsalesMsg();
    try {
      let result = await removeUserFromCompany(companyIdToDelete, userId);
      if (errorManager(result) === false){
        setShowLoadingAnimation(false);
        refreshClientsList()
      }
    } catch (error) {
      console.log(error);
    }
  };

  const refreshClientsList = async()=>{
    setCurrentPage(0)
    try {
      let companies = await listCompaniesForAUser(userId, 0, maxPageSize);
      if (errorManager(companies) === false){
        setCompanies(companies.data);
      } 
    } catch (error) {
      console.log(error)
    }
  }

  const cancelAddUserToClient = ()=>{
    document.getElementById('addUserBtn').classList.add('d-none')
    document.getElementById('addClientContainer').classList.add('d-none')
    document.getElementById('addUserErrorMsg').classList.add('d-none')
    setResetClientSearch(true)
  }

  const moreCompanies = async ()=>{
    let nextPage = currentPage + 1
    setCurrentPage(nextPage)
    setShowMiniLoadingAnimation(true)
    try {
      let nextCompanies = await listCompaniesForAUser(userId, nextPage, maxPageSize, searchTerm);
      if (errorManager(nextCompanies) === false){
        let companiesList = nextCompanies.data
        setCompanies(companies.concat(companiesList));
        setShowMiniLoadingAnimation(false)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const errorManager = (msgFromBackend) =>{
    if (!msgFromBackend || msgFromBackend.error) {
      setMsg("Ha ocurrido un error. Verifique su conexión y vuelva a intentarlo.")
      setErrorFromBackend(true)
      setShowLoadingAnimation(false)
      setShowModal(true);
    } else {
      return false
    }
  }

  const handleFilterUserInput = async (input) => {
    let query
    if (input)
      query = input.target.value.toUpperCase()
    setUserFilterInputWriting(query)
    if (query && query.length > 2)
      setSearchTerm(query)
    else
      setSearchTerm(null)
  }

  return (
    <Container className="pos-r" fluid={true}>
      <UpSalesMessage
        level={"danger"}
        message={msg}
        visible={showModal}
        autoclose={false}
        closeMessage={closeUpsalesMsg}
        callBackAnswerQuestionFn={errorFromBackend ? null : callBackAnswerQuestion }
      />
      <div
        id="ic-menu-out"
        onClick={() => esconderMenuLateral()}
        className="bg-arrow d-center arrow-menu-out d-none orange-up"
      >
        <p>&gt;</p>
      </div>
      <Row>
        <LateralMenu></LateralMenu>

        <Col className="bg-blue-light">
          <Row className="nav-style brd-b-blue justify-content-center">
            <Col xs={11} className="align-self-center p-0">
              <SearchBar />
            </Col>
          </Row>

          <Row>
            <Col className="pt-4 pb-3 bg-blue-light">
              <NavLink className="light-grey" to="/Usuarios">
                Usuarios{" "}
              </NavLink>

              <span className="light-grey">/ Clientes</span>
            </Col>
          </Row>

          <Row className="bg-blue-light px-3">
            <Col className="bg-white py-3 d-flex flex-row justify-content-between align-items-center">
              <div className="d-flex flex-row justify-content-center align-items-center">
                <div className="mr-3">
                  <img
                    className="avatarImgUsers"
                    src={userObject && userObject.photo}
                  />
                </div>
                <div>{userObject && userObject.name}</div>
              </div>
              <div className="d-flex flex-row justify-content-between align-items-center">
                <input
                  id="oportunityInput"
                  className="w-100 l-fs-14"
                  type="text"
                  placeholder="Filtrar por Cliente "
                  value={userFilterInputWriting}
                  onChange={handleFilterUserInput}
                />
                <div onClick={()=>document.getElementById('addClientContainer').classList.remove('d-none')} 
                  className="ml-5 modal-big-button d-center btn btn-primary" title="Añadir cliente">Añadir cliente
                </div>
              </div>
            </Col>
          </Row>

          <Row id="addClientContainer" className="bg-blue-light px-3 mt-3 d-none d-center">
            <Col xs={12} className="bg-white py-4 d-flex flex-row align-items-center">
                <div className="bg-input mr-5" style={{width:'250px'}}>
                  <DynamicSelect
                      optionsArray={clientsArray}
                      searchTerm={null}
                      onChange={handleClient}
                      inputId={'clients'}
                      inputPlaceholder={'Buscar cliente'}
                      resetSearch={resetClientSearch}
                  />
                </div>
                <div id="addUserBtn" onClick={addUserToCompany} className="boton-guardar d-center btn btn-primary mr-3 d-none" title="Añadir" style={{minWidth:'120px'}}>Añadir</div>
                <div onClick={cancelAddUserToClient} className="boton-cancelar d-center btn btn-primary" title="Cancelar" style={{minWidth:'120px'}}>Cancelar</div>
                <div id="addUserErrorMsg" className="ml-4 light-grey d-none">¡{userName} ya está asignado a este cliente!</div>
            </Col>
          </Row>

          {!showLoadingAnimation ? (
            <Row className="bg-blue-light px-3 py-5 d-center">
              <CompaniesList
                listProp={companies}
                deleteCompany={deleteCompany}
                showMiniLoadingAnimation={showMiniLoadingAnimation}
              />
            </Row>
          ) : (
            <LoadingAnimation />
          )}

          {!showLoadingAnimation && currentPage !== totalPages-1 && totalCompanies != 0 ? <Row>
            <Col xs={12} className="btn-mas-container-blue flex-column pb-5">
              {showMiniLoadingAnimation && <LoadingAnimationMiniSize />}
              {!showMiniLoadingAnimation && <img id="BtnMoreCompanies" onClick={moreCompanies} title="Ver más" src={btnMas}></img>}
            </Col>
          </Row>
           : <Row>
            <Col xs={12} className="d-center pb-5">
              {!showLoadingAnimation && totalCompanies != 0 && <p>No hay más clientes por mostrar</p>}
            </Col>
          </Row>}

        </Col>
      </Row>
    </Container>
  );
}
