// COMPONENT TO EDIT TASK FOR PROJECTS, INTERACTION AND OPPORTUNITY
//<EditTaskModal 
//  task={task} 
//  showUpSalesMessageFn={showUpSalesMessage} 
//  refresh={refresh} />

import React, {useState, useEffect} from 'react'
import Modal from 'react-bootstrap/Modal'
import Media from 'react-bootstrap/Media'
// IMGS
import imgBigOportunity from '../../assets/img/ic-oportunidad-big.png'
import docIcon from '../../assets/img/ic-adjuntar-doc-azul.png'
import imgClock from '../../assets/img/ic-reloj-nrja.png'
import icProyectos from '../../assets/img/ic-proyecto-nuevo-big.png'
import lapiz from '../../assets/img/ic-editar-azul@2x.svg'
// NO BORRAR ESTAS IMGS. PENDIENTE IMPLEMENTAR MODEICON UPDATE TASK EN BACKEND
import imgModeText from '../../assets/img/ic-interaccion-blue.png'
import imgModePhone from '../../assets/img/ic-tel-peq-azul.png'
import imgModeEmail from '../../assets/img/ic-correo-blue.png'
import imgModePhoto from '../../assets/img/ic-cam-blue.png'
// COMPONENTS
import ProjectStateIcon from '../proyectos/ProjectStateIcon'
import UpSalesMessage from '../UpSalesMessage'
import LoadingAnimation from '../LoadingAnimation'
import {ShowPhotosAndDocsPreview} from '../MediaManager'
import { ScheduleTaskModal } from './ScheduleTaskModal'
import {ShowPhotosAndDocumentsMiniatures} from '../MediaManager';
import {OpportunityIconState, InteractionModeIcon} from '../CommonScripts'
import FilesDragAndDrop from '../FilesDragAndDrop'
// EXTERNAL LIBRARIES
import moment from 'moment'
// BACKEND FUNCTIONS
import { modifyTask, getATask} from '../../api_communication/service/ClientService';
import { addDueDateTask } from '../../api_communication/service/TaskService'


export default function EditTaskModal(props) {

    const [loadingAnimation, setLoadingAnimation] = useState(false)
    const [show, setShow] = useState(false);
    const [idCompany, setIdCompany] = useState(null)

    // States to save and preview docs and images
    const [preview, setPreview] = useState(false)
    const [imageOrDocToShow, setImageOrDocToShow] = useState(null)
    const [saveImagesAndDocs, setSaveImagesAndDocs] = useState(null)
    const [savingAnimation, setSavingAnimation] = useState(false)
    
    const [taskOrInt, setTaskOrInt] = useState(null)
    const [modeIcon, setModeIcon] = useState('text')

    const [scheduleDate, setScheduleDate] = useState(null)
    // Llama fn de SchedulteTaskManager.js
    const [addOrChangeScheduleTaskShowModal, setAddOrChangeScheduleTaskShowModal] = useState(false)

    const [level, setLevel] = useState(null)
    const [message, setMessage] = useState(null)
    const [close, setClose] = useState(null)
    const [showUM, setShowUM] = useState(null)
    
    useEffect(()=>{
      getTaskCompleteInfo()
      setIdCompany(props.task.company.id)
      setScheduleDate(props.task.scheduling.dueDate)
    }, [props])

    const getTaskCompleteInfo = async ()=>{
      try {
        setLoadingAnimation(true)
        let taskOrInt = await getATask(props.task.id)
        setTaskOrInt(taskOrInt)
        taskOrInt.mode && setModeIcon(taskOrInt.mode)
        setLoadingAnimation(false)
      } catch (error) {
        console.log(error)
      }
    }

    // schedule date required
    const saveTask = async() => {
      let contentUser = document.getElementById("taskForm").value

    if(contentUser == ''){
        showUpSalesMessage('danger','Debe añadir un comentario para la tarea', true, false)
        return false
      } else {
        setSavingAnimation(true)
        let savedtask
        let body = {
          content: contentUser,
          mode: modeIcon,
        }
        let schedulingTask = {
              dueDate: scheduleDate,
              creationDate: moment().format('DD/MM/YYYY HH:mm:ss'),
        }
        try{
          if(contentUser != props.task.content || props.task.mode != modeIcon){
              savedtask = await modifyTask(props.task.id, body)
            }
          if(scheduleDate != props.task.scheduling.dueDate){
            await addDueDateTask(props.task.company.id, props.task.id, schedulingTask)
          }
          if(savedtask){
            setTaskOrInt(savedtask)
          } else {
              let task = await getATask(props.task.id)
              setTaskOrInt(task)
            }
        
          if (imageOrDocToShow) setSaveImagesAndDocs(true)
          else uploadingFinished(true)
         
          
          } catch(error){
            showUpSalesMessage('danger','Ha ocurrido un error al guardar los cambios.', true, true)
            console.log(error)
          } 
      }
    }

    const cancelForm =  () => {
        //setPreview(false)
        //setImageOrDocToShow(null)
        //setSaveImagesAndDocs(false)
        //setTaskOrInt(null)
        //setScheduleDate(null) 
        //setModeIcon('text')  
        setShow(false)  
    }

    const callToShowImageOrDoc = e => {
        setImageOrDocToShow(e)
        setPreview(true)
      }
    
    const closeTaskScheduleModal = ()=>{
      setAddOrChangeScheduleTaskShowModal(false)
    }

    // vuelve de AddScheduleInCreateTaskOrInteraction con la fecha seleccionada por el usuario
    const scheduleDateCallback = (date)=> {
      setScheduleDate(date)
    }

    const showUpSalesMessage = (level, message, show, close) => {
      setLevel(level)
      setMessage(message)
      setShowUM(show)
      setClose(close)
  }

  const uploadingFinished = (value)=>{
    if(value) {
      showUpSalesMessage('success','Tarea editada. Refrescando...', true, false)
      setTimeout(() => {
        setShowUM(false)
        cancelForm()
        setPreview(false)
        setImageOrDocToShow(null)
        setSaveImagesAndDocs(false)
        setTaskOrInt(null)
        setScheduleDate(null) 
        setModeIcon('text')  
        props.refresh()
      }, 1500); 
    } else {
        props.showUpSalesMessage('danger', 'Ha ocurrido un error guardando archivos.', true, false)
    }
    setSavingAnimation(false)
  }

  const closeMsg = () => {
    setShowUM(false)
  }

    return (
      <React.Fragment>

        <UpSalesMessage
            level={level}
            message={message}
            visible={showUM}
            autoClose={close}
            closeMessage={closeMsg}
        />

        <Media>
          <img src={lapiz} className="mr-2 mouse" title="Editar una tarea" onClick={() => setShow(true)}></img>
        </Media>
       
        <Modal
          onEntered={()=>{document.getElementById('taskForm') && document.getElementById('taskForm').focus()}}
          id="modalPositionRight"
          show={show}
          onHide={() => setShow(false)}
          dialogClassName="bg-white"
          aria-labelledby="example-custom-modal-styling-title"
          centered
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header className="justify-content-center modal-border">
            <Modal.Title id="example-custom-modal-styling-title" className="dark-blue d-column">

            <b className="mb-3 text-center">
                EDITAR TAREA
            </b>
              {props.task.project ? <img src={icProyectos}></img> : <img src={imgBigOportunity}></img>}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {loadingAnimation && 
              <div className="overlay-loading-Animation">
                <LoadingAnimation text={'Cargando...'} />
              </div>
            }
            <div className="d-column generaltextInputStyle">

            <p  id="companyName1" className="mb-3 l-fs-18 blue-up text-center">{props.task ? props.task.company.alias : null}</p>

            <div>
              {props.task && props.task.project && <ProjectStateIcon projectState={props.task.project.state}/>}
              {props.task && props.task.project && <span className="my-3 light-grey ml-2">{props.task.project.name}</span>}
              {props.task && props.task.opportunity && <OpportunityIconState opportunity={props.task.opportunity ? props.task.opportunity : null}/>}
              {props.task && props.task.opportunity && <span className="my-3 light-grey ml-2">{props.task.opportunity.name}</span>}
            </div>

            <div className="d-flex flex-row justify-content-center align-items-center mt-3">
                <div>
                    <span className="light-grey pr-3 l-fs-14">Seleccionar: </span>
                </div>
                <div onClick={()=> setModeIcon('text')}>
                    <img className={`${modeIcon == 'text' ? 'icon-mode-style-selected' : ''} icon-mode-style `} 
                      src={imgModeText} alt="Icono Texto" title="Icono Texto"/>
                </div>
                <div onClick={()=> setModeIcon('phone')}>
                    <img className={`${modeIcon == 'phone' ? 'icon-mode-style-selected' : ''} icon-mode-style `} 
                      src={imgModePhone} alt="Icono Teléfono" title="Icono Teléfono"/>
                </div>
                <div onClick={()=> setModeIcon('email')}>
                    <img className={`${modeIcon == 'email' ? 'icon-mode-style-selected' : ''} icon-mode-style `} 
                      src={imgModeEmail} alt="Icono E-mail" title="Icono E-mail"/>
                </div>
                <div onClick={()=> setModeIcon('photo')}>
                    <img className={`${modeIcon == 'photo' ? 'icon-mode-style-selected' : ''} icon-mode-style `} 
                      src={imgModePhoto} alt="Icono Foto" title="Icono Foto"/>
                </div>
            </div>

              <textarea style={{width: '90%'}} rows="5" className="my-3" id="taskForm" type="text"  placeholder="Comentario" defaultValue={props.task.content}></textarea>

              <div className="mt-2">

                <ScheduleTaskModal 
                  isItATask={true} 
                  show={addOrChangeScheduleTaskShowModal} 
                  onHide={closeTaskScheduleModal} 
                  scheduleDateCallbackFn={scheduleDateCallback} 
                />

                <div className="pb-3 d-flex flex-row align-items-center justify-content-center mouse" onClick={()=>setAddOrChangeScheduleTaskShowModal(true)}>
                    <img  width="25" height="25" className="mr-2" src={imgClock}/>
                    <span className="l-fs-14 light-grey text-hover-bold pt-1"><u>{!scheduleDate ? 'Añadir Fecha' : 'Modificar Fecha'}</u></span>
                </div>
                {scheduleDate && 
                  <div className="text-center">
                    <p className="l-fs-14 light-grey pb-3">(Fecha seleccionada: {scheduleDate})</p>
                  </div>
                }
                <div className='py-3'>
                  <FilesDragAndDrop fileSelected={callToShowImageOrDoc}/>
                </div>

              </div>

               {/* MEDIA MANAGER.JS*/}
              {preview && imageOrDocToShow && <div id="preview" className="w-100 py-3">
                <ShowPhotosAndDocsPreview 
                  isItATask={true}  
                  imageOrDocToShow={imageOrDocToShow} 
                  saveImagesAndDocs={saveImagesAndDocs} 
                  idCompany={idCompany} 
                  idTaskOrInteraction={taskOrInt ? taskOrInt.id : null}
                  uploadingFinished={uploadingFinished}
                />
              </div>}

              {taskOrInt && taskOrInt.documents.length != 0 && <ShowPhotosAndDocumentsMiniatures documents={taskOrInt.documents}/>}

              <div className="d-inline-flex mt-4">
                  <div className="d-center boton-cancelar mr-3" onClick={cancelForm}>Cancelar</div>
                  <button disabled={savingAnimation ? true : false} className="d-center boton-guardar" onClick={saveTask}>
                    {savingAnimation && <span className="spinner-grow spinner-grow-sm mr-2" role="status" aria-hidden="true"></span>}
                    Guardar
                  </button>

              </div>
            </div>
          </Modal.Body>
        </Modal>
      </React.Fragment>
    );
  }