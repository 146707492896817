// ************************************
// Created by Pablo Collado August 2022
// ************************************

import React, { useEffect, useState } from "react";

// COMPONENTS
import { DynamicSelectOnlyDropdown} from './selects/DynamicSelectOnlyDropdown'

// SERVICE
import { getCurrentUserData } from "../api_communication/service/UsersService";
import { listUsersForOrganization } from "../api_communication/service/UsersService";

// *****************************************************************
// Mediante este componente el User selecciona un comercial o 'TODOS' 
// y devuelve al padre el id de dicho comercial o null si es 'TODOS'
// *****************************************************************
// Este componente SOLO ES VISIBLE para los usuarios 'MANAGER' y 'SALES_DIRECTOR'
// Este select puro (sin input) muestra listado de comerciales + primera opción 'TODOS' por defecto
// *****************************************************************
// PROPS:
// handleCommercialFromSelect={Function} - Function from parent to return id commercial value to parent


export function CommercialDynamicSelectOnlyDropdown(props) {

    const [commercialsList, setCommercialsList] = useState(null)

    useEffect(() =>{
        loadCurrentUser()
    },[props])

    const PAGE = 0
    const PAGE_SIZE = 1000
    const QUERY = null

    const handleFilterCommercial = (commercialSelected) => {
        if(commercialSelected === "allOptionsSelectedcommercialsListId") commercialSelected = null
        props.handleCommercialFromSelect(commercialSelected)
    }

    const loadCurrentUser = async ()=>{
        try {
            let user = await getCurrentUserData();
            let userRolesArray =  user.roles
            if (userRolesArray.find((item) =>  item === 'SALES_DIRECTOR' || item === 'MANAGER' )) {
                loadCommercialsList()
            }
        } catch (error) {
            console.log(error)
        }
    }

    const loadCommercialsList = async () =>{
        try {
        let list = await listUsersForOrganization('SALES_PERSON', PAGE, PAGE_SIZE, QUERY)
        setCommercialsList(list.data)
        } catch (error) {
        console.log(error)
        }
    }


    return (
        <React.Fragment>
          {commercialsList && <div style={{position:'absolute', top:0, left:25}}>
                <DynamicSelectOnlyDropdown 
                optionsArray={commercialsList} 
                onChange={handleFilterCommercial} 
                resetSearch={null} 
                inputId={'commercialsListId'}
                />
            </div>}
        </React.Fragment>
      )
}