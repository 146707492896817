import moment from 'moment';
import { isOpportunityOpen } from './OpportunityService';
import { isProjectActive } from './ProjectService';
import { isTaskArchived, isTaskResolved } from './TaskService';
import { getInteractionRepository } from '../repository/InteractionRepository';
import { getATask } from './ClientService';

const TIMEEXPIRED = 30 // ESPERANDO DECISIÓN SOBRE TIEMPO ANTES DE PODER ARCHIVAR (NOV 2020)

export const getInteraction = async (companyId, interactionId) => {
  try {
    var response = await getInteractionRepository(companyId, interactionId);
    return response;
  } catch (error) {
    throw Error(error)
  }
};

const getClientIdFromInteraction = (interaction) => {
  return interaction.companyId ? interaction.companyId : interaction.company.id
}

const isInteractionATask = (interaction) => {
  return (interaction.isTask || interaction.scheduling !== null)
}

// The field originalInteraction is used for the interaction created (optional) when a task is closed
const getOriginalInteractionFromInteraction = async (interaction) => {
  let originalInteraction
  if (interaction.originalInteractionId) {
    const clientId = getClientIdFromInteraction(interaction)
    try {
      originalInteraction = await getATask(interaction.originalInteractionId)
    } catch (error) {
      originalInteraction = await getInteraction(clientId, interaction.originalInteractionId)
    }
    
  } else if (interaction.originalInteraction) {
    originalInteraction = interaction.originalInteraction
  }

  return originalInteraction
}

// Interaction is not active when:
// - is archived
// - is from a closed opportunity
// - is from a closed project
// - is from an archived or resolved task 
export const isInteractionActive = async (interaction) => {
  
  if (isInteractionArchived(interaction))
    return false

  if (interaction.opportunity !== null && !isOpportunityOpen(interaction.opportunity))
    return false

  if (interaction.project !== null && !isProjectActive(interaction.project))
    return false

  let originalInteraction = await getOriginalInteractionFromInteraction(interaction)
  if (originalInteraction && isInteractionATask(originalInteraction))
    return !(isTaskResolved(originalInteraction) || isTaskArchived(originalInteraction))

  return true
}

export const isInteractionArchived = (interaction) => {
  return interaction.state === 'ARCHIVED'
}

// Interactions can only be archived during the first 30 minutes after creation
export const canInteractionBeArchived = (interaction) => {
  let interactionDate = moment(interaction.date ? interaction.date : interaction.scheduling.creationDate, "DD/MM/YYYY HH:mm:ss");
  let limitDate = moment().subtract(TIMEEXPIRED, 'minutes');

  return interactionDate.isAfter(limitDate)
}