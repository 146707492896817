// --------------------------------------------
// Created by Pablo Collado in 04/12/2020
// --------------------------------------------

import React, {useState, useEffect} from 'react'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

// IMG

// COMPONENTS
import UpSalesMessage from '../UpSalesMessage'
import {ShowPhotosAndDocumentsMiniatures, ShowPhotosAndDocsPreview} from '../MediaManager'

// BACKEND FN
import {
    getInteraction,
} from '../../api_communication/service/InteractionService';

// --------------------------------------------
// INFO BACKEND
//
// DOCUMENTS ADD
// UpSales.Interaction.uploadDocuments(companyId, interactionId, files)
// UpSales.Task.uploadDocuments(companyId, taskId, files)

// DOCUMENTS GET
// UpSales.Interaction.getDocument(companyId, interactionId, documentId)
// UpSales.Task.getDocument(companyId, taskId, documentId)
// --------------------------------------------

// FROM PARENT: 
// <AttachedFiles 
//      interaction={interactionObject} 
//      task={taskObject} 
//      refreshFn={refreshFn}  
//      showButtonToAttach={showButtonToAttach}
// />

//
// NOTA DIC 2020 --> NO HAY EN BACKEND NINGUNA FUNCIÓN PARA ELIMINAR NINGÚN ADJUNTO DE BD.
//


export default function AttachedFiles(props) {

    const [showFiles, setShowFiles] = useState(false)
    const [interaction, setInteraction] = useState(null)
    const [filesToShow, setFilesToShow] = useState([])
    const [fileAddedByUser, setFileAddedByUser] = useState(null)
    const [showingAddedFiles, setShowingAddedFiles] = useState(false)
    const [saveImagesAndDocs, setSaveImagesAndDocs] = useState(false)
    const [savingAnimation, setSavingAnimation] = useState(false)
    const [showButtonToAttach, setShowButtonToAttach] = useState(true)

    // para mensajes emergentes (Upsales Msg)
    const [level, setLevel] = useState(null)
    const [message, setMessage] = useState(null)
    const [close, setClose] = useState(null)
    const [showUM, setShowUM] = useState(null)

    // FN PARA REFRESCAR PADRE
    const refreshFn = props.refreshFn

    useEffect(() => {
        let tempFilesToShow = []

        async function findInteraction() {
            
            try {
                let result = await getInteraction(props.interaction.companyId ? props.interaction.companyId : props.interaction.company.id, props.interaction.id)
                 
                setInteraction(result)
                if (result.hasDocuments || (result.documents && result.documents.length !== 0)) {
                    tempFilesToShow = filesToShow.concat(result.documents)
                    setFilesToShow(result.documents)
                    setShowFiles(true)
                }
            } catch (e) {
                console.log(e)
            }
        }

        props.interaction && findInteraction()
        props.showButtonToAttach !== undefined && setShowButtonToAttach(props.showButtonToAttach)
    }, [props])

    // UPSALES MESSAGE
    const showUpSalesMessage = (level, message, show, close) => {
        setLevel(level)
        setMessage(message)
        setShowUM(show)
        setClose(close)
    }
  
    const closeMsg = () => {
        setLevel(null)
        setMessage(null)
        setShowUM(false)
        setClose(null)
    }

    const addFileToInteraction = ()=> {
        document.getElementById('addFile').click()
    }

    const gotoBottom = (id) => {
        var element = document.getElementById(id);
        element.style.height = "250px"
        element.scrollIntoView({block: "end", behavior: "smooth"});
     }

    const callToShowImageOrDoc = e => {
        setFileAddedByUser(e.target.files[0])
        setShowingAddedFiles(true)
    }

    const uploadingFinished = (value)=>{
        if(value) {
            refreshFn()
        } else {
            showUpSalesMessage('danger', 'Ha ocurrido un error guardando archivos.', true, false)
        }
        setSavingAnimation(false)
    }

    const saveFilesAddedByUser = ()=> {
        setSavingAnimation(true)
        setSaveImagesAndDocs(true)
        showUpSalesMessage('success', 'Guardando imágenes...', true, false)
    }

    const areThereFilesToShow = (trueOrFalse) => {
        if (trueOrFalse === true) {
            setShowingAddedFiles(true)
            gotoBottom('attached-files-section')
        }
        else setShowingAddedFiles(false)
    } 

    return (
        <Row>
            <UpSalesMessage
                level={level}
                message={message}
                visible={showUM}
                autoClose={close}
                closeMessage={closeMsg}
            />
            <Col xs={12} className="pos-r">
                <div className="bold-text text-center pt-4">FICHEROS ADJUNTOS</div>
                <input encType="multipart/form-data" 
                       id="addFile" 
                       type="file" 
                       multiple={false} 
                       accept="file/*" 
                       onChange={callToShowImageOrDoc} 
                       style={{visibility:'hidden',width:0, height:0}}>
                </input>

                {showButtonToAttach && 
                    <Button onClick={addFileToInteraction} 
                        title="Añadir adjunto" 
                        className="modal-small-button d-center" 
                        style={{position:'absolute', right:15, top: 15}} variant="primary"
                    >
                        +
                    </Button>
                }
                <div className="bg-white">
                    <div className="brd-b-blue "></div>
                </div>
            </Col>

            {interaction && showFiles && (filesToShow.length !==0) && <Col xs={12} className=" py-3">
                <div className="l-fs-14 light-grey py-3 text-center">MOSTRANDO ADJUNTOS:</div>
                <div className="d-center">
                    <ShowPhotosAndDocumentsMiniatures documents={filesToShow}/>
                </div>
            </Col>}
            
            {props.interaction && !showFiles && <Col className="d-center py-3">
                <div className="l-fs-14" style={{color:'red'}}>No hay ficheros agregados.</div>
            </Col>}

            {showingAddedFiles && interaction && fileAddedByUser &&  <Col id="attached-files-section" xs={12}>
                <ShowPhotosAndDocsPreview 
                    isItATask={false} 
                    imageOrDocToShow={fileAddedByUser} 
                    saveImagesAndDocs={saveImagesAndDocs} 
                    idCompany={interaction.companyId} 
                    idTaskOrInteraction={interaction ? interaction.id : null}
                    areThereFilesToShow={areThereFilesToShow}
                    uploadingFinished={uploadingFinished}
                    />
                <div className="w-100 d-center py-4">
                    <div onClick={saveFilesAddedByUser} className="d-center boton-guardar">
                    {savingAnimation && <span className="spinner-grow spinner-grow-sm mr-2" role="status" aria-hidden="true"></span>}
                    Guardar
                    </div>
                </div>
            </Col>}
            
        </Row>
    );

}