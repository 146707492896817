import { UpSales } from "../bundle/bundle";
const SERVER_URL = process.env.REACT_APP_SERVER_URL;
export let x;
export const configBundle = () => {
  console.log("configBundle: server_url: ", SERVER_URL);
  const redirectToLogin = () => {
    window.location.replace("/");
  };
  let store = new UpSales.LocalStorageStore();
  UpSales.Config.configure(store, SERVER_URL, redirectToLogin);
  x = UpSales;
  //return UpSales;
};
