import React, {useState, useEffect} from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Media from 'react-bootstrap/Media'

import moment from 'moment';

import btnPlus from '../../assets/img/btn-mas.png'
import imgBigOportunity from '../../assets/img/ic-oportunidad-big.png'

import UpSalesMessage from '../UpSalesMessage'
import ReasignarOpportunityTask from '../ReasignarOpportunityTask'
import {truncateText} from '../CommonScripts'
import { ResolveTaskModal } from '../task/ResolveTaskModal'
import { isTaskOverdue } from '../../api_communication/service/TaskService'
import { InteractionModeIcon } from '../CommonScripts'

import { listTasksForAnOpportunity, saveInteractionByCompany, createInteraction } 
  from '../../api_communication/service/ClientService';
import { closeTask } from '../../api_communication/service/TaskService'
import { closeWonOpportunity, closeLostOpportunity, solveAllTasksForOpportunity } 
  from '../../api_communication/service/OpportunityService';

const PAGESIZE = 5

export const ListOpportunityTask = (props) => {

  let result = []
  props.arrayItems.map((element, index) => {
    let date = element.scheduling.dueDate

    result.push(
      <Row key={index} className="closeOppTaskBorderRow py-2 flex-row justify-content-around align-items-start">
      
        <ResolveTaskModal 
          show={false} 
          onHide={false} 
          task={element ? element : null} 
          closeOpp={true} 
          refresh={props.refresh}
        />

        <Col xs={9}>
          <Row className="">
            <Col xs={12} className="d-flex flex-row">
              {/* la función que trae las tareas no incorpora el atributo 'mode' */}
              <img width={25} height={25} src={InteractionModeIcon(element.mode)} alt={element.mode} title={element.mode}/>
              <div className="ml-2" title={element.content}>
                <p className={isTaskOverdue(date) ? "rojo l-fs-12" : "orange l-fs-12"}>{date}</p>
                <p className="light-grey">{truncateText(element.content, 40)}</p>
              </div>
            </Col>
          </Row>
        </Col>
        
        <ReasignarOpportunityTask interaction={element} refresh={props.refresh}></ReasignarOpportunityTask>
      </Row>
    )
    })
  return result 
}

// ------------------------------------------------------------------------------------
//  <CloseOpportunity 
//    opportunity={this.props.oportunidad} 
//    btn={'normal' OR 'cuadrado'}  // normal= btn normal, cuadrado=btn cuadrado con una 'R'
//    refresh={refresh} // función del padre para refrescarlo
//  />
// ------------------------------------------------------------------------------------

export default function CloseOpportunity(props) {
    
    const [show, setShow] = useState(false)
    const [tasksList, setTasksList] = useState([]) 

    const [customAlertMessage, setCustomAlertMesssage] = useState(null)
    const [customAlertShowClose, setCustomAlertShowClose] = useState(null)
    const [sendCallBackAnswerQuestion, setSendCallBackAnswerQuestion] = useState(false)
    const [messageLevel, setMessageLevel] = useState(null)
    const [customAlertAutoClose, setCustomAlertAutoClose] = useState(false)

    const [countPage, setCountPage] = useState(0)
    const [noMore, setNoMore] = useState(false)

    useEffect(() => {
      callTask(0)
    },[props.opportunity])

    useEffect(() => {
      refreshTasks(0)
    },[props.refreshTasks])
    
    const callTask = async (page) => {
      // UpSales.Task.listTasksInTimeIntervalForAOpportunityFilteredByState(page, pageSize, dateFrom, dateTo, opportunityId, stateName)
      // stateName: "OPEN", "CLOSED", "ACTIVE", "REOPENED", "POSTPONED"
      let result = []
      try {
        result = await listTasksForAnOpportunity(page, PAGESIZE, '', '', props.opportunity.id, 'ACTIVE')
        setCountPage(page)

        if (result.data.length !== 0) 
          setTasksList(result.data)
        else {
          setTasksList([])
        }

        if (result.totalPages > result.currentPage + 1)
          setNoMore(false)
        else
          setNoMore(true)

      } catch (error) {
        console.log(error)
      }
    }

    const moreTasks = async() => {
      
      let result = []
      result = await listTasksForAnOpportunity(countPage + 1, PAGESIZE, '', '', props.opportunity.id, 'ACTIVE')

      if (result.data.length !== 0) {
        setCountPage(countPage + 1)
        setTasksList([...tasksList, ...result.data])
      }

      if (result.totalPages > result.currentPage + 1)
        setNoMore(false)
      else
        setNoMore(true)
    }

    const cancelForm =  () => {
      refreshTasks()
      closeMessage()
      setMessageLevel(null)
      setShow(false)
    }

    const refreshTasks = () => {
      setTasksList([])
      setCountPage(0)
      setNoMore(false)
      setSendCallBackAnswerQuestion(false)
      callTask(0)
    }

    // UpSales.Opportunity.updateOpportunity(opportunityId, body)
    const closingOpportunity = async () => {

      // IF TASKS LAS RESOLVEMOS TODAS
      try {
        if (tasksList.length !== 0) 
          await solveAllTasksForOpportunity(props.opportunity.id)
      } catch(error) {
        console.log(error)
      }

      // AHORA cerramos LA OPP y creamos interaction 
      try {
        // archivando todas las interacciones
        // let interactionsList = await listOpportunityInteractions(opportunityId)
        // interactionsList.forEach(async element => {
        //   await archiveInteraction(element.companyId, element.id)
        // });
        
        // cerrando...
        let state = checkingCheckBoxes()
        if (state === 'CLOSED LOST')
          await closeLostOpportunity(props.opportunity.id)
        else if (state === 'CLOSED WON')
          await closeWonOpportunity(props.opportunity.id)

        // creando interaction
        let comment = document.getElementById('closeOpportunityTextarea').value
        if (comment === '') {comment = `Oportunidad "${props.opportunity.name}" cerrada.`}

        let interactionBody = {
          date: moment().format('DD/MM/YYYY HH:mm:ss') ,
          mode: 'text',
          content: comment,
          opportunity: props.opportunity ? { 
            id: props.opportunity.id
          } : null
        }

        let companyId = props.opportunity.companyId
        await createInteraction(companyId, interactionBody)

        // CERRAR TODO Y REFRESCAR PADRE (POR IMPLEMENTAR)
        if (props.detailsOpp) {
          window.location.href="./Oportunidad"
        } else {
          props.refresh("goToOpportunitiesPage")
        }
        
      } catch(error) {
        console.log(error)
      }
    }

    const closeMessage = () => {
      setCustomAlertShowClose(false)
      setSendCallBackAnswerQuestion(false)
      setCustomAlertAutoClose(false)
    }

    const checkingCheckBoxes = () => {
      let result = false
      if (document.getElementById('CLOSED LOST').checked) {
        result = "CLOSED LOST"
      } else if (document.getElementById('CLOSED WON').checked) {
        result = "CLOSED WON"
      }
      return result
    }

    const saveForm = async () => {
      if (checkingCheckBoxes() === false) {
        setSendCallBackAnswerQuestion(false)
        setMessageLevel('warning')
        setCustomAlertMesssage('Debe asignar la oportunidad como ganada o perdida')
        setCustomAlertAutoClose(true)
        setCustomAlertShowClose(true)
        
        return
      }
     
      // Ahora: comprobando si hay tareas sin resolver
      if (tasksList.length !== 0) {
        setMessageLevel('danger')
        setSendCallBackAnswerQuestion(true)
        setCustomAlertMesssage('Existen tareas sin resolver. Ok para cerrar oportunidad y resolver todas las tareas automaticamente?')
        setCustomAlertShowClose(true)
        
      } else {
        closingOpportunity()
      }
    }

    // resolver tarea sin dialogo modal: directamente
    const reasignarSolveSchedulingTaskFn = async (interaction) =>{
      let body ={
          creationDate:  moment().format('DD/MM/YYYY HH:mm:ss') 
      }
      try {
          let res = await closeTask(interaction.id, body)
      } catch (error){
          console.log(error)
      }
  }

    const reasignar = async (interaction) => {
      try {
        await reasignarSolveSchedulingTaskFn(interaction)
      } catch (error) {
        console.log(error)
      }
     
      let companyId = interaction.companyId
      let body = {
        date: moment().format('DD/MM/YYYY HH:mm:ss'),
        mode: interaction.mode,
        content: interaction.content,
      }
      
      try {
        await saveInteractionByCompany(companyId, body)
        successMessage()
      } catch (error) {
        console.log(error)
      }
    }
    
    const successMessage = ()=>{
        callTask()
        setMessageLevel('success')
        setSendCallBackAnswerQuestion(false)
        setCustomAlertMesssage('Operación realizada con éxito.')
        setCustomAlertAutoClose(true)
        setCustomAlertShowClose(true)
    }

    const refresh = () =>{
      setTasksList([])
      setCountPage(0)
      setNoMore(false)
      setSendCallBackAnswerQuestion(false)
      callTask()
    }
    
    return (
      <React.Fragment>
        {props.btn === 'normal' ? <Button title="Cerrar esta oportunidad." className="modal-big-button-inverse d-center"  onClick={() => setShow(true)}>
          Cerrar Esta Oportunidad
        </Button> :
        <div className="imgContainer bg-grey" onClick={() => setShow(true)} title="Cerrar esta oportunidad">
          <span className="white-color">C</span>
        </div>
        }

        <Modal
          onEntered={()=>document.getElementById('closeOpportunityTextarea').focus()}
          id="closeOportunityModal"
          show={show}
          onHide={() => cancelForm()}
          dialogClassName=""
          aria-labelledby="example-custom-modal-styling-title"
          centered
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header className="justify-content-center modal-border">
            <Modal.Title id="example-custom-modal-styling-title" className="dark-blue d-column">
            <Media><img src={imgBigOportunity}></img></Media>
              <b className="my-2 text-center">
                CERRAR OPORTUNIDAD
              </b>
              <b>"{props.opportunity.name}"</b>
              
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="mt-4">

          <UpSalesMessage
              level={messageLevel}
              message={customAlertMessage} 
              visible={customAlertShowClose} 
              autoclose={customAlertAutoClose}
              closeMessage={closeMessage}
              callBackAnswerQuestionFn={sendCallBackAnswerQuestion ? closingOpportunity : null}
              />
            
            <form id="closeOpportunity" className="d-column generaltextInputStyle">
            <Form.Check
              custom
              type="radio"
              name="closeOpp"
              label={`Cerrar como ganada `}
              id={`CLOSED WON`}
              value="CLOSED WON"
            />

            <Form.Check
              custom
              type="radio"
              name="closeOpp"
              label={`Cerrar como perdida `}
              id={`CLOSED LOST`}
              value='CLOSED LOST'
            />

            {tasksList.length !== 0 && <Row className="mt-5 px-2 d-flex flex-row ">
              <Col xs={12} className="closeOppTaskBorderRow">
                  <p className="text-center light-grey pb-3">Listado de Tareas:</p>
              </Col>
              <Col xs={12} className="overflow-y-auto tasksListContainer">

                 <ListOpportunityTask refresh={()=>refresh()} arrayItems={tasksList} reasignar={reasignar}/>

                 {!noMore && <Row>
                  <Col className="d-flex justify-content-center align-items-center py-4 ">
                      <img className="mouse" src={btnPlus} onClick={()=>moreTasks()}/>
                  </Col>
                 </Row>}
                 {noMore && <Row>
                  <Col className="d-flex justify-content-center align-items-center py-4 ">
                      <p className="light-grey l-fs-14">No hay más tareas para mostrar.</p>
                  </Col>
                 </Row>}
              </Col>
            </Row>}

            <Row className="mt-5">
              <Col xs={12}>
                <p className="light-grey">(Opcional) Agregar comentario:</p>
                <textarea style={{width: '100%'}} rows="5" className="my-3" id="closeOpportunityTextarea" type="text"  placeholder="Comentario..."></textarea>
              </Col>
            </Row>

              <div className="d-inline-flex my-5">
                  <div title="Cancelar operación" className="d-center boton-cancelar mr-3" onClick={cancelForm}>Cancelar</div>
                  <div title="Cerrar Oportunidad" className="d-center boton-guardar px-2" onClick={()=>saveForm()}>Cerrar Oportunidad</div>
              </div>

            </form>

          </Modal.Body>
        </Modal>
      </React.Fragment>
    );
  }