import React, {} from 'react'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {Link} from 'react-router-dom'

import flechaIzq from '../../assets/img/ic-arrow-left.png'
import flechaDcha from '../../assets/img/ic-arrow-right.png'
import icTel from '../../assets/img/ic-tel-blue.png'
import icInteraction from '../../assets/img/ic-interaccion-blue.png'
import icEmail from '../../assets/img/ic-correo-blue.png'
import icCam from '../../assets/img/ic-cam-blue.png'
import blueBtnMore from '../../assets/img/btn-mas.png'
import icAdjuntar from '../../assets/img/ic-adjuntar-celeste.png'
import icRelojNaranja from '../../assets/img/ic-reloj-nrja.png'
import icRelojRojo from '../../assets/img/ic-reloj-rojo.png'
import greenTick from '../../assets/img/ic-check-tarea-resuelta.png'
import userImgPlaceholder from "../../assets/img/UserAvatarIconPlaceholder.png";

import UpSalesMessage from '../../componentes/UpSalesMessage'
import CreateInteraction from '../../componentes/CreateInteractionModal'
import { isTaskOverdue } from '../../api_communication/service/TaskService';
import {OpportunityIconState} from '../CommonScripts'
import { LoadInteractionsByCompany } from '../../api_communication/service/ClientService';
import LoadingAnimation from '../LoadingAnimation'
import ShowArchivedInteractions from '../ShowArchivedInteractions';
import ProjectStateIcon from '../proyectos/ProjectStateIcon';
import { truncateText } from '../CommonScripts';

// -----------------------------------
// FROM PARENT > DetallesClientes.js: 
// -----------------------------------
// <ClientInteractions 
//    showUpSalesMessageFn={this.showUpSalesMessage} 
//    client={this.state.clientInfo} 
//    interactionDetailsFn={this.interactionDetailsFn} 
//    refreshFn={()=>this.refreshAllClientDetails()}
// />

// TODO Create component InteractionTimelineGraph

class Item extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      cutContent: '',
      imageState: null,
      interactionSelected: false
    }
    this.interactionDetailsFn = props.interactionDetailsFn
    this.refresh = props.refresh
    this.handleScrollToElement = props.handleScrollToElement
  }

  async componentDidMount() {
    if (this.props.item && this.props.item.createdBy) {
      document.styleSheets[0].addRule(`.timeline ol li#id${this.props.listkey}:not(:last-child):after`,`content: ""; background-image: url(${this.props.item.createdBy.profilePhoto ? this.props.item.createdBy.profilePhoto : userImgPlaceholder}); background-repeat: no-repeat; background-position: center; border-radius: 50%; background-size: cover; display: block; position: absolute; top:-10px; left:calc(172%);width:30px; height:30px; z-index:99; `);
      document.getElementById(`id${this.props.listkey}`).setAttribute("title", `${this.props.item.createdBy.username}`);
    } else {
      if (this.props.item) document.styleSheets[0].addRule(`.timeline ol li#id${this.props.listkey}:not(:last-child):after`,`content: ""; background-image: url(${userImgPlaceholder}); background-repeat: no-repeat; background-position: center; border-radius: 50%; background-size: cover; display: block; position: absolute; top:-10px; left:calc(172%);width:30px; height:30px; z-index:99; `);
    }
  }

  textModeImage = (mode) => {
    switch (mode) {
      case 'text':
        return icInteraction
        break;
      case 'phone':
        return icTel
        break;
      case 'email':
        return icEmail
        break;
      case 'photo':
        return icCam
        break;
      default:
        break;
    }
  }
  
  render() {
    return(
      <li id={'id' + this.props.listkey} key={this.props.listkey} >
        <Link to={{pathname:'/DetalleInteraccion', state: this.props.item}} className="interactionContainer flex-column pos-r" style={{textDecoration: 'none'}}>
          <div className="timeline-indicators">
            {this.props.item.hasDocuments && 
              <img style={{width: 12, height: 12,}} src={icAdjuntar} alt="Adjuntos" title="Adjuntos"/>
            }
            {this.props.item.schedulingState === 'closed' ? 
              <img style={{width: 13, height: 13,}} src={greenTick} alt="Interacción con tarea resuelta" title="Interacción con tarea resuelta"/> : 
              (this.props.item.dueDate != null && this.props.item.schedulingState != 'closed' ? 
                (isTaskOverdue(this.props.item.dueDate) ? 
                  <img style={{width: 13, height: 13,}} src={icRelojRojo} alt="Tarea programada vencida" title="Tarea programada vencida"/> : 
                  <img style={{width: 15, height: 15,}} src={icRelojNaranja} alt="Tarea programada pendiente" title="Tarea programada pendiente" />)
                : null)
            }
          </div>
          <div title='' className={`${this.state.interactionSelected && this.props.intSelected == this.props.listkey ? 
            'bg-timeline-blue' : 'bg-timeline-grey'} py-2 interactionUp  px-2 borderTop d-flex align-items-center`}>
            <img style={{width: 25, height: 25}} src={this.textModeImage(this.props.item.mode)}/>
            { this.props.item.opportunity && 
              <div style={{marginLeft: 4, marginBottom:3, marginRight:4}}>
                <OpportunityIconState opportunity={this.props.item.opportunity}/>
              </div>
            }
            { this.props.item.project &&
              <div style={{marginLeft: 4, marginBottom:3, marginRight:4}}>
                <ProjectStateIcon projectState={this.props.item.project.state}/>
              </div>
            }
            <p className="ml-2 timeline-date">{this.props.item.date}</p> 
          </div>
          <div className={`${this.state.interactionSelected && this.props.intSelected == this.props.listkey ? 
            'bg-timeline-dark-blue' : 'bg-timeline-dark-grey'} interactionDown pt-1  px-2 borderBottom`}>
            {this.props.item.opportunity && 
              <p className="timeline-opportunity-description" title={this.props.item.opportunity.name}>
                {truncateText(this.props.item.opportunity.name, 35)}
              </p>
            }
            {this.props.item.project && 
              <p className="timeline-opportunity-description" title={this.props.item.project.name}>
                {truncateText(this.props.item.project.name, 35)}
              </p>
            }
            <p className="timeline-content" title={this.props.item.content}>
              {truncateText(this.props.item.content, 35)}
            </p>
          </div>
        </Link>
      </li>
    )
  }
}
  
export default class ClientInteractions extends React.Component{
    constructor(props){
      super(props)
        this.state={
          timeline: null,
          elH: null,
          arrows: null,
          arrowPrev: null,
          arrowNext: null,
          firstItem: null,
          lastItem: null,
          xScrolling: null,
          disabledClass: "disabled",
          interactions: null,
          paginationCount: 0,
          noMoreInteractions: false,
          loadingInteractions: false,
          mediaQuery: false,
          intSelected: null,
        }
        this.myRef = React.createRef();
        this.isElementInViewport = this.isElementInViewport.bind(this)
        this.interactionDetailsFn = props.interactionDetailsFn
        this.refreshFn = props.refreshFn
    }

    async componentDidMount(){
      this.setState({loadingInteractions: true,})
      let result = await this.fetchData()
      if(result && result.length !== 0){
        this.refreshInteractionListState()
        this.init()
      } 
    }

    async componentDidUpdate(prevProps) {
      if (this.props.client != null && this.props.client != prevProps.client) {
        
        this.setState({loadingInteractions: true,})
        let result = await this.fetchData()
        if(result && result.length !== 0){
          this.refreshInteractionListState()
          this.init()
        }
      }
    }

fetchData = async ()=>{
  let result = await LoadInteractionsByCompany(this.props.client.id, this.state.paginationCount)
  // console.log('CLIENT-INTERACTIONS.JS: list interactions: ', result)
  this.setState({interactions: result, loadingInteractions: false})
  return result
}

refreshInteractionListState = () =>{
  this.setState({
    timeline: document.querySelector(".timeline ol"),
    elH: document.querySelectorAll(".timeline li > div"),
    arrows: document.querySelectorAll(".timeline .arrows .arrow"),
    arrowPrev: document.querySelector(".timeline .arrows .arrow__prev"),
    arrowNext: document.querySelector(".timeline .arrows .arrow__next"),
    firstItem: document.querySelector(".timeline li:first-child"),
    lastItem: document.querySelector(".timeline li:last-child"),
    xScrolling:500,
    disabledClass: "disabled",
  })
} 

 init() {
  var x = window.matchMedia("(max-width: 768px)")
  this.mediaQueryMoreInteraction(x) // Call listener function at run time
  x.addListener(this.mediaQueryMoreInteraction) // Attach listener function on state changes
  // this.setEqualHeights(this.state.elH);
  if (this.state.arrows)
    this.animateTl(this.state.xScrolling, this.state.arrows, this.state.timeline);
  if (this.state.timeline)  
    this.setSwipeFn(this.state.timeline, this.state.arrowPrev, this.state.arrowNext);
  this.setKeyboardFn(this.state.arrowPrev, this.state.arrowNext);
}

mediaQueryMoreInteraction = (x)=>{
  if (x.matches) { // If media query matches
    this.setState({mediaQuery: true})
    // console.log('>>>>>>>> mediaQueryMoreInteraction: mediaQuery: ', this.state.mediaQuery)
  } else {
    this.setState({mediaQuery: false})
    // console.log('>>>>>>>> mediaQueryMoreInteraction: mediaQuery: ', this.state.mediaQuery)
  }
}

moreInteractions = async()=>{
  // console.log(' -------------------------------- estoy en moreInteractions !!!')
  this.setState({paginationCount: this.state.paginationCount +1})
  let result = await LoadInteractionsByCompany(this.props.client.id, this.state.paginationCount)
  // console.log(' -------------------------------- estoy en moreInteractions: result: ', result)
  if (result) {
    this.setState({interactions: this.state.interactions.concat(result)})
    this.refreshInteractionListState ()
    //this.init()
    } else {
      this.setState({
        noMoreInteractions: true
      })
    }
} 
  

// When we create a new interaction
refreshInteractions = async ()=>{
  await this.setState({
    noMoreInteractions: false,
    paginationCount: 0,
    interactions: null,
    loadingInteractions: true,
  })
  this.refreshFn()
  await this.fetchData()
  await this.refreshInteractionListState () 
  this.init()
}

// SET EQUAL HEIGHTS ---- NO BORRAR!
//  setEqualHeights(el) {
//   let counter = 0;
//   for (let i = 0; i < el.length; i++) {
//     const singleHeight = el[i].offsetHeight;

//     if (counter < singleHeight) {
//       counter = singleHeight;
//     }
//   }

//   for (let i = 0; i < el.length; i++) {
//     el[i].style.height = `${counter}px`;
//   }
// }

// CHECK IF AN ELEMENT IS IN VIEWPORT
// http://stackoverflow.com/questions/123999/how-to-tell-if-a-dom-element-is-visible-in-the-current-viewport
 isElementInViewport(el) {
  const parentPos = document.getElementById('timelineContainer').getBoundingClientRect()
  const childPos = el.getBoundingClientRect()
  const relativePos = {}
  relativePos.top = (childPos.top - parentPos.top)
  relativePos.right = (childPos.right - parentPos.right)
  relativePos.bottom = (childPos.bottom - parentPos.bottom)
  relativePos.left = (childPos.left - parentPos.left)
  return (
    relativePos.top >= 0 &&
    relativePos.left >= 0 &&
    relativePos.bottom <= 0 &&
    relativePos.right <= 0
  );
}

// SET STATE OF PREV/NEXT ARROWS
 setBtnState(el, flag = true) {
   //console.log('setBtnState: el: ', el)
   const disabledClass = this.state.disabledClass
  if (flag) {
    // console.log('>>>>>>>>> disabledClass')
    el.classList.add(disabledClass);
  } else {
    if (el.classList.contains(disabledClass)) {
      el.classList.remove(disabledClass);
    }
    el.disabled = false;
  }
}

// ANIMATE TIMELINE
 animateTl(scrolling, el, tl) {
  let counter = 0;
  for (let i = 0; i < el.length; i++) {
    el[i].addEventListener("click", ()=> {
      const arrowPrev = this.state.arrowPrev
      const arrowNext = this.state.arrowNext
      const firstItem = this.state.firstItem
      const lastItem = this.state.lastItem
      if (!arrowPrev.disabled) {
        arrowPrev.disabled = true;
      }
      if (!arrowNext.disabled) {
        arrowNext.disabled = true;
        if (this.state.noMoreInteractions == false) this.moreInteractions()
      }
      const sign = (el[i].classList.contains("arrow__prev")) ? "" : "-";
      if (counter === 0) {
        tl.style.transform = `translateX(-${scrolling}px)`;
      } else {
        const tlStyle = getComputedStyle(tl);
        // add more browser prefixes if needed here
        const tlTransform = tlStyle.getPropertyValue("-webkit-transform") || tlStyle.getPropertyValue("transform");
        const values = parseInt(tlTransform.split(",")[4]) + parseInt(`${sign}${scrolling}`);
        tl.style.transform = `translateX(${values}px)`;
      }

      setTimeout(() => {
        this.isElementInViewport(firstItem, 'first') ? this.setBtnState(arrowPrev) : this.setBtnState(arrowPrev, false);
        this.isElementInViewport(lastItem, 'last') ? (this.setBtnState(arrowNext)) : this.setBtnState(arrowNext, false);
      }, 100);

      counter++;
    });
  }
}

//ADD SWIPE SUPPORT FOR TOUCH DEVICES
 setSwipeFn(tl, prev, next) {
  var Hammer = require('hammerjs');
  const hammer = new Hammer(tl);
  hammer.on("swipeleft", () => next.click());
  hammer.on("swiperight", () => prev.click());
}

// ADD BASIC KEYBOARD FUNCTIONALITY
 setKeyboardFn(prev, next) {
  const timeline = this.state.timeline
  document.addEventListener("keydown", (e) => {
    if ((e.which === 37) || (e.which === 39)) {
      const timelineOfTop = timeline.offsetTop;
      const y = window.pageYOffset;
      if (timelineOfTop !== y) {
        window.scrollTo(0, timelineOfTop);
      }
      if (e.which === 37) {
        prev.click();
      } else if (e.which === 39) {
        next.click();
      }
    }
  });
}

interactionDetailsFn = (item)=>{
  this.setState({interaction: item})
}

showInteractions = (arrayInteractions, callback) => {
  let array = []
  arrayInteractions.forEach( element =>{
    array.push(<Item 
                  key={element.id} 
                  listkey={element.id} 
                  item={element} 
                  interactionDetailsFn={this.interactionDetailsFn} 
                  refresh={this.refresh} 
                  intSelected={this.state.intSelected} 
                  handleScrollToElement={callback}/>)
              })
  return array
}

refresh =  (listKey) => {
  this.setState({intSelected: listKey, interactions: this.state.interactions})
  this.showInteractions(this.state.interactions)
}

handleScrollToElement(event) {
  // 
  let anchor = document.getElementById('timelineContainer')
  //anchor.scrollIntoView({behavior:"smooth", block:"start"});
}

showUpSalesMessage = (level, message, show, close) => {
  this.setState({
    toastLevel: level,
    toastMessage: message,
    showToast: show,
    autoClose: close
  })
}

closeMessage = () => {
  this.setState({
    toastLevel: null,
    toastMessage: null,
    showToast: false,
    autoClose: false
  })
}

 
render() {
    return(
        <Row ref={this.myRef} className="bg-white pt-4 mt-3 d-block">
          <UpSalesMessage
              level={this.state.toastLevel}
              message={this.state.toastMessage}
              visible={this.state.showToast}
              autoClose={this.state.autoClose}
              closeMessage={this.closeMessage}
          />
          <Col className="dark-blue text-center pos-r">
            {/* <div className="btn-show-archived" title="Ver interacciones archivadas">
              <img src={icArchived}/>
            </div> */}
            <b>INTERACCIONES</b>
            <div className="row d-flex flex-row justify-content-end align-items-center" style={{position: 'absolute', left:0, top:0, width: '100%'}}>
            {this.props.client && <ShowArchivedInteractions client={this.props.client} refresh={this.refreshInteractions} msg={this.showUpSalesMessage}/>}
            {this.props.client && <CreateInteraction showUpSalesMessageFn={this.showUpSalesMessage} client={this.props.client} refresh={this.refreshInteractions} />}
            </div>
          </Col>
          <Col id="timelineContainer" className="mt-2">
            {this.state.loadingInteractions && <LoadingAnimation text="Cargando interacciones..."/>}
            {(!this.state.loadingInteractions && (this.state.interactions && this.state.interactions.length !== 0)) ? <Row className="">
              <Col className="timeline">
                <ol id="timeline">
                  
                  {this.state.interactions && !this.state.loadingInteractions && this.showInteractions(this.state.interactions, this.handleScrollToElement)}
                 
                  <li></li>
                </ol>
                {(this.state.mediaQuery && !this.state.noMoreInteractions) &&
                 <div className="row d-center p-3">
                    <img style={{cursor:'pointer'}} onClick={()=>this.moreInteractions()} src={blueBtnMore}/>
                 </div> 
                }
                <div className="arrows">
                  <button className="arrow arrow__prev disabled" disabled>
                    <img style={{width:30, height:30}} src={flechaIzq} alt="prev timeline arrow"/>
                  </button>
                  <button className="arrow arrow__next">
                    <img style={{width:30, height:30}} src={flechaDcha} alt="next timeline arrow"/>
                  </button>
                </div>
              </Col>
            </Row> :
            <div className="light-grey d-center py-5 l-fs-14">No hay interacciones</div>
            }
        </Col>
    </Row>
        
    )
    }
}