import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner'

export default class LoadingAnimationMiniSize extends React.Component{
    constructor(props){
        super(props)
        this.state = {color: 'primary'}
    }
    componentDidMount(){
        if (this.props.color){
            this.setState({
                color: this.props.color
            })
        }
    }
    render(){
        return(
        <Row>
            <Col className="d-flex align-items-center justify-content-center flex-column">
                <Spinner animation="border" variant={this.state.color} size="sm"/>
            </Col>
        </Row>
        )
    }
}