import React, { useState, useEffect } from "react";
import { Media } from "react-bootstrap";
import userImgPlaceholder from "../assets/img/UserAvatarIconPlaceholder.png";

import UserEditModal from "../componentes/usuarios/UserEdit";
import UserEditPasswordModal from "../componentes/usuarios/UserEditPassword";

import { getCurrentUserData } from "../api_communication/service/UsersService";
import { signout } from "../api_communication/service/AuthService";

export default function SearchBar(props) {
  const [storageId, setStorageId] = useState(null);
  const [userName, setUserName] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showEditPasswordModal, setEditPasswordShowModal] = useState(false);
  const [srcPhoto, setSrcPhoto] = useState(null);

  useEffect(() => {
    async function setUserData() {
      try {

        let user
        if (!props.loggedUser)
          user = await getCurrentUserData();
        else
          user = props.loggedUser
        
        setSrcPhoto(user.profilePhoto);
        setStorageId(user.id);
        setUserName(user.username);
      } catch (error) {
        console.log(error);
      }
    }

    setUserData();
  }, []);

  const showEditUserModalFn = () => {
    setShowModal(true);
  };

  const hiddeEditUserModalFn = () => {
    setShowModal(false);
  };

  const showEditUserPasswordModalFn = () => {
    setEditPasswordShowModal(true);
  };

  const hiddeEditUserPasswordModalFn = () => {
    setEditPasswordShowModal(false);
  };

  const logout = () => {
    signout();
  };

  return (
    <div className="w-100 d-flex justify-content-end">
      <UserEditModal
        userId={storageId}
        userName={userName}
        show={showModal}
        hide={hiddeEditUserModalFn}
        photo={srcPhoto}
      />
      <UserEditPasswordModal
        userId={storageId}
        userName={userName}
        show={showEditPasswordModal}
        hide={hiddeEditUserPasswordModalFn}
      />
      <div id="dropdownUserMenu" className="dropdown">
        <div
          className="dropdown-toggle"
          id="dropdownMenuButton"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <Media className="mouse d-center" title="logout">
            <div>
              <img
                alt="User Avatar"
                className="user-img"
                src={srcPhoto ? srcPhoto : userImgPlaceholder}
              ></img>
            </div>
          </Media>
        </div>
        <div
          className="dropdown-menu dropdown-menu-right"
          aria-labelledby="dropdownMenuButton"
        >
          <p
            onClick={() => showEditUserModalFn()}
            className="dropdown-item mouse color-link l-fs-15"
          >
            Editar usuario
          </p>
          {/* <p className="dropdown-item mouse color-link l-fs-15" >Otra opción</p>
                        <p className="dropdown-item mouse color-link l-fs-15" >Otra opción hover</p> */}
          <div className="dropdown-divider"></div>
          {/* Opción cambiar contraseña */}
          <p
            onClick={() => showEditUserPasswordModalFn()}
            className="dropdown-item mouse color-link l-fs-15"
          >
            Cambiar contraseña
          </p>
          <div className="dropdown-divider"></div>
          <p
            onClick={logout}
            className="dropdown-item mouse color-link l-fs-15"
          >
            Logout
          </p>
        </div>
      </div>
      {/*
                }<form className="align-self-center">
                    <input className="input-index" type="text" placeholder="Search e.g. live"/>
                    <select className="select-index">
                        <option></option>
                        <option>Hola</option>
                    </select>
                </form> onClick={this.logout}*/}
      {/* <Dropdown>
                    <Dropdown.Toggle variant="success" id="dropdown-basic-button">
                        <Media  className="user-img mouse" title="logout">
                            <img 
                            alt="User Avatar"
                            className="img-resp"
                            src={this.state.storageId ? `https://api.upsalesassistant.com/users/${this.state.storageId}/profile-picture?thumbnail=true` : userImg}></img>
                        </Media>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                        <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                        <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown> */}
    </div>
  );
}
