import React, {useState} from 'react';
import '../../assets/css/calendarDatePicker/Calendar.css'
import '../../assets/css/calendarDatePicker/DatePicker.css'
import moment from 'moment';
import { createInteraction } from '../../api_communication/service/ClientService'
import { closeTask } from '../../api_communication/service/TaskService';
import { projectCreateInteraction } from '../proyectos/funcionesProyectos';

import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Media from 'react-bootstrap/Media'

import icResolver from '../../assets/img/ic-check-tarea-resuelta.png' 

import LoadingAnimation from '../LoadingAnimation'

// <ResolveTaskModal 
//  show={resolverModalOpenClose} 
//  onHide={isItSolved} 
//  interaction={props.interaction ? props.interaction.id : null} 
//  refresh={() => this.refres()}
//  closeOpp={true or false} - if we are in close op modal. shows red icon with tick or 'resolver' word.
// />
export const ResolveTaskModal = (props) => {

    const [show, setShow] = useState(false)
    const [newInt, setnewInt] = useState(false)
    const [selected, setSelected] = useState(false)
    const [make, setMake] = useState(false)

    const cancelForm = () => {
      setShow(false)
    }
    
    const resolveTask = async () => {
      setMake(true)
      
      //Comprueba donde esta el id de la empresa, si en company.id o companyId
      let idCompañia = props.task.company ? props.task.company.id : props.task.companyId

      //Comprueba donde esta el id de la oportunidad, si en oportunity.id o opportunityId
      let idOportunidad;
      if (props.task.opportunityId)
        idOportunidad = props.task.opportunityId
      else if (props.task.opportunity)
        idOportunidad = props.task.opportunity.id
      else 
        idOportunidad = null;

      // Create interaction if requested
      if (newInt) {

        try {
          //comentario resumen para la creación de la nueva interacción de cierre si se especifica que se quiere
          let comment = document.getElementById("newInteraction").value
          if (comment == '')
            comment = `Interacción de cierre de la tarea: ${props.task.content} `

          let bodyInteraction = {
            date: moment().format('DD/MM/YYYY HH:mm:ss'),
            mode: 'text',
            content: comment,
            originalInteraction: {
              id: props.task.id
            }
          }

          if (props.task.project) {
            await projectCreateInteraction(props.task.project.id, bodyInteraction)
          } else {
            if (idOportunidad)
              bodyInteraction.opportunity = {id: idOportunidad}
            await createInteraction(idCompañia, bodyInteraction)
          }

        } catch (error) {
          console.log("Ha ocurrido un error al cerrar la tarea")
        }
      }

      // Close Task
      try {
        let body = {
          creationDate: moment().format('DD/MM/YYYY HH:mm:ss')
        }
        await closeTask(idCompañia, props.task.id, body)
      } catch (error) {
        console.log("Ha ocurrido un error al cerrar la tarea")
      }

      if (props.closeOpp) {
        props.refresh()
        setMake(false)
        cancelForm()
      } else {
        window.location.reload()
      }
    }

    var selectCreateInteraction = () => {
      if (document.getElementById("checkBox").checked && selected == true) {
        document.getElementById("checkBox").checked = false
        document.getElementById("newInteraction").classList.add("d-none")
        setSelected(false)
        setnewInt(false)
      } else {
        document.getElementById("checkBox").checked = true
        document.getElementById("newInteraction").classList.remove("d-none")
        setSelected(true)
        setnewInt(true)
      }
    }

    return (
      <React.Fragment>
        {!props.closeOpp && !props.fromTaskTabList && <Media className="mouse" onClick={() => setShow(true)}>
          <button type="button" className="modal-button btn btn-primary">Resolver</button>
        </Media>}

        {props.closeOpp && !props.fromTaskTabList && 
        <div style={{border: '1px solid green',borderRadius:'50%', padding: 5, paddingTop:0, paddingBottom:0}} 
             onClick={() => setShow(true)} 
             title="Resolver Tarea" 
             className="mouse" >
          <img height={8} width={12} src={icResolver} />
        </div>}

        {props.fromTaskTabList &&  <img width={16} className='ml-2 mouse' src={icResolver} onClick={() => setShow(true)} title="Resolver la tarea"/>}

        <Modal
          id="closeOportunityModal"
          show={show}
          onHide={() => cancelForm()}
          dialogClassName="bg-white"
          aria-labelledby="example-custom-modal-styling-title"
          centered
          backdrop="static"
          keyboard={false}
          style={{border: 'none !important'}}
        >
          <Modal.Header className="justify-content-center modal-border p-5">
            <Modal.Title id="example-custom-modal-styling-title" className="dark-blue d-column">
              <p className="my-2 text-center">
              <b>Resolver Tarea:</b> {props.task ? props.task.content : ""}</p>
            </Modal.Title>
          </Modal.Header>
          
          <Modal.Body className="mt-4">
          {make && <LoadingAnimation text={"Creando interacción..."}/>}
          {!make && 
            <form id="closeTask" className="d-column generaltextInputStyle">

              <Row className="justify-content-center">
                <Col xs={11}>
                  <Row className="d-flex align-items-center">
                    <Col xs={10}>
                      <p className="light-grey">¿Quieres resumir el resultado para visualizarlo en la línea de tiempo?</p>
                    </Col>
                    <Col xs={2}>
                    <label className="container-checkbox">
                      <input id="checkBox" type="radio" name="Tipo" className="mouse" onClick={(e)=>selectCreateInteraction(e)}></input>
                      <span className="checkmark"></span>
                    </label>
                    </Col>
                  </Row>
                
                <textarea type="text" id="newInteraction" className="d-none my-3 w-100" 
                    defaultValue={"Tarea finalizada: " + `${props.task ? props.task.content : null}` + ". "} 
                    rows="5" 
                    placeholder={props.task ? props.task.content : "Escribe aquí el resumen.."}>
                </textarea>

                </Col>
              </Row>

              <div className="d-inline-flex my-5">
                  <div title="Cancelar operación" className="d-center boton-cancelar mr-3" onClick={cancelForm}>Cancelar</div>
                  <div title="Cerrar Oportunidad" className="d-center boton-guardar px-2" onClick={resolveTask}>Guardar</div>
              </div>

            </form>
          }

          </Modal.Body>
        </Modal>
      </React.Fragment>
    );
}