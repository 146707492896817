import React, { useState, useEffect } from "react";
import "../assets/css/App.css";
import moment from "moment";

import {
  tasksForAnInteraction,
  listTasksInTimeIntervalFilteredByState,
} from "../api_communication/service/ClientService";
import {
  countOverdueTasksForOneOpportunity,
  countActiveTasksForOneOpportunity
} from "../api_communication/service/OpportunityService";
import { isTaskOverdue } from "../api_communication/service/TaskService";

import upsalesLogo from "../assets/img/logo.png";

import icmensaje from "../assets/img/ic-correo-peq.png";
import icllamada from "../assets/img/ic-tel-peq-azul.png";
import icPhoto from "../assets/img/ic-cam-blue.png";
import icText from "../assets/img/ic-interaccion-blue.png";

import icRelojNaranja from "../assets/img/ic-reloj-nrja.png";
import icRelojRojo from "../assets/img/ic-reloj-rojo.png";
import greenTick from "../assets/img/ic-check-tarea-resuelta.png";

import iconOpen from "../assets/img/ic-op-abierta-gris.png";
import iconClosedLost from "../assets/img/ic-op-cerrada-rojo.png";
import iconClosedWon from "../assets/img/ic-op-cerrada-verde.png";
import iconOpenOverdue from "../assets/img/ic-op-abierta-roja.png";
import iconOpenPending from "../assets/img/ic-op-pendiente.png";

// to download file from backend 
export const downloadFile = async (file, filename) => {
    const blob = await file.blob();
    const newBlob = new Blob([blob]);

    const blobUrl = window.URL.createObjectURL(newBlob);

    const link = document.createElement('a');
    link.href = blobUrl;
    link.setAttribute('download', `${filename}.xlsx`);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
    // clean up Url
    window.URL.revokeObjectURL(blobUrl);
      
}

const ListYears = (props) => {
  //console.log('ListYears: props: ', props)
  return (
    <div
      onClick={() =>
        props.yearSelected !== props.yearToShow &&
        props.setYearSelectedFn(props.yearToShow)
      }
      className={[
        props.yearSelected === props.yearToShow
          ? "pr-2 azul"
          : "pr-2 mouse light-grey-text",
      ]}
      id={props.yearToShow}
    >
      {props.yearToShow}
    </div>
  );
};

// <ListYearsToChose actualYear={actualYear} yearSelected= {yearSelected} callBackFn={this.userChooseYear}/>
export const ListYearsToChose = (props) => {
  const [actualYear, setActualYear] = useState(null);
  const [yearSelected, setYearSelected] = useState(null);
  const [list, setList] = useState([]);

  useEffect(() => {
    //console.log('LIST-YEARS-TO-CHOSE: props.yearSelected: ', props.yearSelected)
    setActualYear(props.actualYear);
    setYearSelected(props.yearSelected);
    let temp = [];
    if (props.yearSelected === props.actualYear) {
      temp.push(props.yearSelected - 2);
      temp.push(props.yearSelected - 1);
      temp.push(props.yearSelected);
    } else {
      temp.push(props.yearSelected - 1);
      temp.push(props.yearSelected);
      temp.push(props.yearSelected + 1);
    }
    setList(temp);
    //console.log('LIST-YEARS-TO-CHOSE: list: ', list)
  }, [props.yearSelected]);

  const setYearSelectedFn = (item) => {
    //console.log('DENTRO DE setYearSelectedFn: item: ', item)
    setYearSelected(item);
    props.callBackFn(item);
  };

  const fillData = (actualYear) => {
    let rows = [];
    list.length !== 0 &&
      list.forEach((element) => {
        rows.push(
          <ListYears
            actualYear={actualYear}
            yearSelected={yearSelected}
            yearToShow={element}
            setYearSelectedFn={setYearSelectedFn}
            key={element}
          />
        );
      });
    return rows;
  };

  const changeYear = (condition) => {
    let temp = [];
    //console.log('list: ', list)
    if (condition === "before") {
      temp = list.map((item) => item - 3);
      //console.log('temp: ', temp)
      setList(temp);
    } else {
      let lastValue = list[list.length - 1];
      let quantity = actualYear - lastValue;
      temp = list.map((item) => item + quantity);
      setList(temp);
    }
    fillData();
  };

  return (
    <React.Fragment>
      <div
        className="d-flex flex-row align-items-center justify-content-center"
        style={{ paddingRight: "20%" }}
      >
        <div
          onClick={() => changeYear("before")}
          className="pr-4"
          style={{ cursor: "pointer" }}
          title="Atrás"
        >
          &lt;
        </div>
        <div
          id="list"
          className="d-flex flex-row align-items-center justify-content-center"
        >
          {list.length !== 0 ? fillData(actualYear) : null}
        </div>
        <div
          onClick={() => changeYear("next")}
          className="pl-4"
          style={{ cursor: "pointer" }}
          title="Adelante"
        >
          &gt;
        </div>
      </div>
    </React.Fragment>
  );
};

// receives an interaction object and return mode icon img
export const InteractionModeIcon = (interactionMode) => {
  //console.log('InteractionModeIcon: interactionMode : ', interactionMode)
  switch (interactionMode) {
    case "phone":
      return icllamada;
    case "email":
      return icmensaje;
    case "photo":
      return icPhoto;
    default:
      return icText;
  }
};

// ONLY FOR INTERACTION (NOT TASK): dueDateIcon: shows red, orange or green icon
// receives an interaction object as props
// dueDate values: "OPEN", "CLOSED", "ACTIVE", "REOPENED", "POSTPONED"
export const InteractionDueDateIcon = (props) => {
  let interaction = props.interaction;
  //('CommonScripts.js: InteractionDueDateIcon: interaction: ', interaction)

  //console.log('CommonScripts.js: InteractionDueDateIcon: interaction id: ', interaction.companyId)
  if (interaction.tasks) {
    let dateState = interaction.tasks.firstDueDateForActive;
    return (
      <div>
        {interaction.tasks.active === 0 ? (
          <img
            style={{ width: 13, height: 13 }}
            src={greenTick}
            alt="Interacción con tareas resueltas"
            title="Interacción con tareas resueltas"
          />
        ) : isTaskOverdue(dateState) ? (
          <img
            style={{ width: 13, height: 13 }}
            src={icRelojRojo}
            alt="Tarea programada vencida"
            title="Tarea programada vencida"
          />
        ) : (
          <img
            style={{ width: 15, height: 15 }}
            src={icRelojNaranja}
            alt="Tarea programada pendiente"
            title="Tarea programada pendiente"
          />
        )}
      </div>
    );
  } else {
    return <div></div>;
  }
};

export const randomId = () => {
  return "_" + Math.random().toString(36).substr(2, 9);
};

// show opportunity icon
// we have printing opportunities. We want to show their opportunities icons states
// example: <OpportunityIconState littleIcon={true or false} opportunity={this.props.data}/>
export const OpportunityIconState = (props) => {
  const [state, setState] = useState(null);
  let opportunity = props.opportunity;

  useEffect(() => {
    const iconState = async () => {
      if (opportunity !== null) {
        if (opportunity.state === "CLOSED LOST") {
          setState(iconClosedLost);
        } else {
          if (opportunity.state === "CLOSED WON") {
            setState(iconClosedWon);
          } else {
            try {
              let hasActiveTasks = await countActiveTasksForOneOpportunity(
                opportunity.id
              );
              //console.log('>>> hasActiveTasks: ', hasActiveTasks)
              if (hasActiveTasks.count !== 0) {
                let dateOverdue = await countOverdueTasksForOneOpportunity(
                  opportunity.id,
                  moment().format("DD/MM/YYYY HH:mm:ss")
                );
                //console.log('>>> dateOverdue: ', dateOverdue)
                if (dateOverdue.count !== 0) {
                  setState(iconOpenOverdue);
                } else {
                  setState(iconOpenPending);
                }
              } else {
                setState(iconOpen);
              }
            } catch (error) {
              console.log(error);
            }
          }
        }
      }
    };
    iconState();
  }, [props.opportunity]);

  return (
    <React.Fragment>
      {opportunity && (
        <img
          title={opportunity ? opportunity.name : ""}
          style={props.littleIcon ? { maxWidth: 21 } : { maxWidth: 35 }}
          src={state}
          alt=""
        />
      )}
    </React.Fragment>
  );
};

// RETURN NUMBER OF TASK FROM TASKTABLE FOR AN INTERACTION
export const tareasHoy = async (taskObject) => {
  let today = moment.utc(0, "HH:mm:ss").format("DD/MM/YYYY HH:mm:ss");
  let todayEnd = moment
    .utc(0, "HH:mm:ss'")
    .add(1, "day")
    .format("DD/MM/YYYY HH:mm:ss");
  try {
    let numberOfTasks = await tasksForAnInteraction(
      0,
      10000,
      today,
      todayEnd,
      taskObject.company ? taskObject.company.id : taskObject.companyId,
      taskObject.id,
      "ACTIVE",
      "ACTIVE"
    );
    if (numberOfTasks.length === 0) {
      return 0;
    } else {
      return numberOfTasks.length;
    }
  } catch (error) {
    console.log(error);
  }
};
export const tareasVencidas = async (taskObject) => {
  let today = moment.utc(0, "HH:mm:ss").format("DD/MM/YYYY HH:mm:ss");
  let fechaInicio = moment
    .utc(0, "HH:mm:ss'")
    .subtract(2, "year")
    .format("DD/MM/YYYY HH:mm:ss");
  try {
    let numberOfTasks = await tasksForAnInteraction(
      0,
      10000,
      fechaInicio,
      today,
      taskObject.company ? taskObject.company.id : taskObject.companyId,
      taskObject.id,
      "ACTIVE",
      "ACTIVE"
    );
    console.log(">>> >> > TAREAS VENCIDAS: ", numberOfTasks);
    if (numberOfTasks.length === 0) {
      return 0;
    } else {
      return numberOfTasks.length;
    }
  } catch (error) {
    console.log(error);
  }
};

export const tareasProximas = async (taskObject) => {
  let number = 0;
  let today = moment.utc(0, "HH:mm:ss").format("DD/MM/YYYY HH:mm:ss");
  let todayEnd = moment
    .utc(0, "HH:mm:ss'")
    .add(1, "year")
    .format("DD/MM/YYYY HH:mm:ss");
  try {
    let numberOfTasks = await tasksForAnInteraction(
      0,
      10000,
      today,
      todayEnd,
      taskObject.company ? taskObject.company.id : taskObject.companyId,
      taskObject.id,
      "ACTIVE",
      "ACTIVE"
    );
    if (numberOfTasks.length === 0) {
      return 0;
    } else {
      if (numberOfTasks.length === 0) {
        return 0;
      } else {
        for (const task of numberOfTasks) {
          if (task.scheduling.dueDate != null) {
            let today = moment.utc(23, "HH:mm:ss");
            let taskdueDate = moment(
              task.scheduling.dueDate,
              "DD/MM/YYYY HH:mm:ss"
            );
            if (today.isBefore(taskdueDate)) {
              number++;
            }
          }
        }
        return number;
      }
    }
  } catch (error) {
    console.log(error);
  }
};

// RETURN NUMBER OF ALL TASK FROM TASKTABLE FOR ALL COMPANIES/INTERACTIONS
export const todasLasTareasHoy = async () => {
  let today = moment.utc(0, "HH:mm:ss").format("DD/MM/YYYY HH:mm:ss");
  let todayEnd = moment
    .utc(0, "HH:mm:ss'")
    .add(1, "day")
    .format("DD/MM/YYYY HH:mm:ss");
  try {
    let numberOfTasks = await listTasksInTimeIntervalFilteredByState(
      0,
      1,
      today,
      todayEnd,
      "ACTIVE",
      "ACTIVE"
    );
    //console.log('>>> >> > TAREAS HOY: ', numberOfTasks)
    if (numberOfTasks.totalElements === 0) {
      return 0;
    } else {
      return numberOfTasks.totalElements;
    }
  } catch (error) {
    console.log(error);
  }
};
export const todasLasTareasVencidas = async () => {
  let today = moment.utc(0, "HH:mm:ss").format("DD/MM/YYYY HH:mm:ss");
  let fechaInicio = moment
    .utc(0, "HH:mm:ss'")
    .subtract(2, "year")
    .format("DD/MM/YYYY HH:mm:ss");
  try {
    let numberOfTasks = await listTasksInTimeIntervalFilteredByState(
      0,
      1,
      fechaInicio,
      today,
      "ACTIVE",
      "ACTIVE"
    );
    //console.log('>>> >> > TAREAS VENCIDAS: ', numberOfTasks)
    if (numberOfTasks.totalElements === 0) {
      return 0;
    } else {
      return numberOfTasks.totalElements;
    }
  } catch (error) {
    console.log(error);
  }
};
export const todasLasTareasProximas = async () => {
  let number = 0;
  let tomorrow = moment
    .utc(0, "HH:mm:ss")
    .add(1, "day")
    .format("DD/MM/YYYY HH:mm:ss");
  let dateEnd = moment
    .utc(0, "HH:mm:ss'")
    .add(10, "year")
    .format("DD/MM/YYYY HH:mm:ss");
  //console.log('todasLasTareasPróximas: tomorrow: ', tomorrow)
  //console.log('todasLasTareasPróximas: dateEnd: ', dateEnd)
  try {
    let numberOfTasks = await listTasksInTimeIntervalFilteredByState(
      0,
      1,
      tomorrow,
      dateEnd,
      "ACTIVE",
      "ACTIVE"
    );
    if (numberOfTasks.totalElements === 0) {
      return 0;
    } else {
      return numberOfTasks.totalElements;
      // for (const task of numberOfTasks) {
      //   if (task.scheduling.dueDate != null) {
      //     let today = moment.utc(23, "HH:mm:ss");
      //     let taskdueDate = moment(
      //       task.scheduling.dueDate,
      //       "DD/MM/YYYY HH:mm:ss"
      //     );
      //     if (today.isBefore(taskdueDate)) {
      //       number++;
      //     }
      // }
      // return number;
      // }
    }
  } catch (error) {
    console.log(error);
  }
};

export const truncateText = (content, maxLength = 30) => {
  let ellipsis = "...";
  if (content.length > maxLength) {
    return content.substring(0, maxLength).concat(ellipsis);
  } else {
    return content;
  }
};

export const printDiv = (printdivname, titleName) => {
  document.title = `${titleName}`;
  let headstr = "<html><body>";
  let footstr = "</body>";
  let logo = `<div style="display:flex; justify-content:center;"><img src="${upsalesLogo}" width="75px" }/></div>`;
  let title = `<div style='text-align: center; margin-top: 30px;margin-bottom: 30px'><h4>${titleName}</h4></div>`;
  let newstr = document.getElementById(printdivname).innerHTML;
  let oldstr = document.body.innerHTML;
  document.body.innerHTML = headstr + logo + title + newstr + footstr;
  setTimeout(function () {
    window.print();
    document.body.innerHTML = oldstr;
    document.location.reload();
    return false;
  }, 500);
};

export const clientDetailStatusTranslations = (value) => {
  switch (value) {
    case "1":
      return "Activo";
      break;
    case "2":
      return "No activo";
      break;
    case "3":
      return "Potencial";
      break;
    default:
      return "No se ha registrado ningún estado.";
      break;
  }
};
export const clientDetailTipologyTranslations = (value) => {
  switch (value) {
    case "4":
      return "Piscinero";
      break;
    case "5":
      return "Arquitecto";
      break;
    case "6":
      return "Diseñador";
      break;
    case "7":
      return "Importador";
      break;
    case "8":
      return "Distribuidor";
      break;
    case "9":
      return "Tienda";
      break;
    case "10":
      return "Gran Superficie";
      break;
      case "23":
        return "Sitio Web";
    default:
      return "No se ha registrado ninguna tipología.";
      break;
  }
};
export const clientDetailAcquisitionTranslations = (value) => {
  switch (value) {
    case "11":
      return "Llamada";
      break;
    case "12":
      return "Redes Sociales";
      break;
    case "13":
      return "Feria Ceramica Cevisama";
      break;
    case "14":
      return "Feria Ceramica Cersaie";
      break;
    case "15":
      return "Feria Ceramica Covering";
      break;
    case "16":
      return "Feria Mosaico Barcelona";
      break;
    case "17":
      return "Feria Mosaico Lyon";
      break;
    case "18":
      return "Feria Mosaico Madrid";
      break;
    case "19":
      return "Visita";
      break;
    case "20":
      return "Agente";
      break;
    case "21":
      return "Web";
      break;
    case "22":
      return "Recomendación";
      break;
    default:
      return "No se ha registrado ninguna adquisición.";
      break;
  }
};

export const translateUserRoleToSpanish = (roles) => {
  let rolTranslated = ''
  roles.forEach(rol => {
    switch (rol) {
      case 'MANAGER':
        rolTranslated = 'Gerente'
        break
      case 'SALES_PERSON':
        rolTranslated = 'Comercial'
        break
      case 'PROJECT_MANAGER':
        rolTranslated = 'Gerente de proyectos'
        break
      case 'ADMINISTRATIVE':
        rolTranslated = 'Administrativo'
        break
      case 'SALES_DIRECTOR':
        rolTranslated = 'Director comercial'
        break
        
      default:
        return 'Rol sin definir'
        break;
    }
  })
  return rolTranslated
}
