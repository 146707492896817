import React from 'react';
import '../assets/css/App.css';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Media from "react-bootstrap/Media";
import LateralMenu from '../componentes/LateralMenu'
import SearchBar from '../componentes/SearchBar';
import {Link} from 'react-router-dom'

import defaultLogo from "../assets/img/logoPorDefecto.png";

//FUNCION CERRAR MENU
import {esconderMenuLateral} from '../assets/js/menuLateral'

import { OpportunityIconState } from '../componentes/CommonScripts';
import CloseOpportunity from "../componentes/oportunidad/CloseOpportunity";
import TaskTabList from "../componentes/task/TaskTabList";
import CreateOpportunity from "../componentes/oportunidad/CreateOpportunity";
import RandomOpportunity from '../componentes/detallesOportunidad/RandomOpportunity';
import UpSalesMessage from '../componentes/UpSalesMessage';
import InteractionTimelineChat from "../componentes/interaccion/InteractionTimeLineChat";

import { findClientById } from "../api_communication/service/ClientService";
import { fetchGetOpportunityById, isOpportunityOpen } from "../api_communication/service/OpportunityService";

// TODO extraer a componente
class ClientLogo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      clientLogo: [],
    };
  }

  async componentDidMount() {
    try {
      let clientLogo = await findClientById(this.props.client);
      this.setState({
        clientLogo: clientLogo,
      });
    } catch (error) {
      console.log(error);
    }
  }

  render() {
    let showLogo = null;
    if (this.state.clientLogo) {
      if (this.state.clientLogo.thumbnail === null) showLogo = defaultLogo;
      else showLogo = this.state.clientLogo.thumbnail;
    }

    return (
      <Media>
        {this.state.clientLogo && (
          <img
            style={{ width: 40, height: 40, borderRadius: 50 }}
            src={showLogo}
          ></img>
        )}
      </Media>
    );
  }
}

class GeneralInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      image: null,
      clientObject: ""
    };
    this.refresh = this.props.refresh;
  }
  // lo que recibe es : PABLO DIC 2020 > props.oportunidad y refreshFn
  async componentDidMount() {
    this.getClient();
  }

  async componentDidUpdate(prevProps) {
    if (
      this.props.oportunidad != prevProps.oportunidad &&
      this.props.oportunidad !== null
    ) {
      this.getClient()
    }
  }

  getClient = async () => {
    if (this.props.oportunidad !== null) {
      let companyId = null;
      if (this.props.oportunidad.companyId) {
        companyId = this.props.oportunidad.companyId;
      } else {
        try {
          let result = await fetchGetOpportunityById(this.props.oportunidad.id);
          companyId = result.companyId;
        } catch (e) {
          console.log(e);
        }
      }
      try {
        let client = await findClientById(companyId);
        this.setState({ clientObject: client });
      } catch (error) {
        console.log(error);
      }
    }
  };

  refresh = () => {
    this.refresh();
  };

  render() {
    return (
      <Row className="bg-white justify-content-center pt-3">
        {this.props.oportunidad && (
          <Col xs={11}>
            <Row className="my-2">
              <Col
                xs={2}
                className="d-center align-self-start mouse"
                title="Click para detalles de este cliente..."
              >
                <Link
                  className="row color-link"
                  to={{
                    pathname: "/DetallesClientes",
                    state: this.state.clientObject,
                  }}
                >
                  <ClientLogo client={this.props.oportunidad.companyId} />
                </Link>
              </Col>
              <Col xs={10} className="align-self-center l-fs-18 dark-blue pl-0">
                {this.state.clientObject ? this.state.clientObject.alias : ""}
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col xs={12} className="brd-b-blue"></Col>
            </Row>
            <Row className="my-2">
              <Col xs={2} className="d-center align-self-start">
                <div className="ml-2 max-ic-op">
                  <OpportunityIconState opportunity={this.props.oportunidad} />
                </div>
              </Col>
              <Col xs={10} className="pl-0">
                <Row>
                  <Col>
                    <p className="l-fs-16 dark-blue">
                      {this.props.oportunidad.name}
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <p className="l-fs-14 light-grey">
                      {this.props.oportunidad.description}
                    </p>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="my-2">
              <Col xs={12} className="d-flex justify-content-end py-2">
                
                {this.props.showCloseOpportunity ? <CloseOpportunity
                  refresh={this.refresh}
                  refreshTasks={this.props.refreshTasks}
                  detailsOpp={true}
                  opportunity={this.props.oportunidad}
                  btn={"normal"}
                /> : ''}
              </Col>
            </Row>
          </Col>
        )}
      </Row>
    );
  }
}

class DetalleOportunidad extends React.Component {
  constructor(props){
    super(props)
    this.state={
      isOpen: false,
      actualOpportunity: null,
      toastLevel: null,
      toastMessage: null,
      showToast: false,
      autoClose: false,
      refreshTasks: false
    }
  }

  async componentDidMount() {
    
      try {
        let opportunity = await fetchGetOpportunityById(this.props.location.state.id)
        //console.log('Detalle op:: ', opportunity)
        this.setState({
          actualOpportunity: opportunity,
          isOpen: isOpportunityOpen(opportunity)
        })
      } catch(e) {
        console.log(e)
      }

  }

  componentDidUpdate(prevProps) {
    if (
      this.props.oportunidad !== prevProps.oportunidad &&
      this.props.opportunidad !== null
    ) {
      this.setState({
        actualOpportunity: this.props.oportunidad,
        isOpen: isOpportunityOpen(this.props.oportunidad)
      });
    }
  }

  changeActualOpportunity = async (newOpportunity) => {
    await this.setState({
      actualOpportunity: newOpportunity,
      isOpen: isOpportunityOpen(newOpportunity)
    });
  };

  refresh = (mode) => {
    if (mode ==="goToOpportunitiesPage") {
      document.getElementById('linkToOportunidades').click()
    } 
  }

  showUpSalesMessage = (level, message, show, close) => {
    this.setState({
      toastLevel: level,
      toastMessage: message,
      showToast: show,
      autoClose: close,
    });
  };

  closeMessage = () => {
    this.setState({
      toastLevel: null,
      toastMessage: null,
      showToast: false,
      autoClose: false,
    });
  };

  refreshCloseOpModal = ()=>{
    this.setState({refreshTasks: !this.state.refreshTasks})
  }

  render() {
    return (
      <Container className="pos-r" fluid={true}>
        <Link id="linkToOportunidades" to={{pathname:'/Oportunidad'}}></Link>
        <div id="ic-menu-out" onClick={()=> esconderMenuLateral()} className="bg-arrow d-center arrow-menu-out d-none orange-up">
          <p>&gt;</p>
        </div>
        <Row>
          <LateralMenu></LateralMenu>

          <Col className="bg-blue-light">
            <Row className="nav-style brd-b-blue justify-content-center">
              <Col xs={11} className="align-self-center p-0">
                  <SearchBar/>
              </Col>
            </Row>

            <Row className="justify-content-center bg-blue-light">
              <Col xs={12} className="pt-4 pb-3 px-3 d-flex flex-row">
                <Link className="light-grey" to={"/Oportunidad"}>Oportunidades&nbsp;</Link>/
                <p className="light-grey">&nbsp;Detalles Oportunidad</p>
              </Col>
            </Row>

            {this.props.location.state && <Row className="bg-blue-light h-100">
              <Col xs={12} className="px-3">
                    {/* <TimeLine refresh={this.refresh} oportunidad={this.state.receivedProp ? this.state.receivedProp : null}></TimeLine> */}
                    <Row className="justify-content-center bg-blue-light pb-4">
                      <UpSalesMessage
                        level={this.state.toastLevel}
                        message={this.state.toastMessage}
                        visible={this.state.showToast}
                        autoClose={this.state.autoClose}
                        closeMessage={this.closeMessage}
                      />
                      {this.props.opportunidad !== null &&
                        this.state.actualOpportunity !== null && (
                          <Col xs={12}>
                            <Row className="bg-blue-light pb-4">
                              <Col xs={12} className="align-items-center">
                                <Row>
                                  <Col xs={12} className="mt-3 d-flex justify-content-end">
                                    <CreateOpportunity
                                      idCompany={this.state.actualOpportunity.companyId}
                                      companyName={this.state.actualOpportunity.companyName}
                                      showUpSalesMessageFn={this.showUpSalesMessage}
                                    />
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                            <Row className="justify-content-center pl-3">
                              <Col xs={12} md={12} lg={7} xl={8}>
                                <GeneralInfo
                                  refresh={this.refresh}
                                  refreshTasks={this.state.refreshTasks}
                                  oportunidad={this.state.actualOpportunity}
                                  showCloseOpportunity={this.state.isOpen}
                                />
                                <TaskTabList opportunity={this.state.actualOpportunity} 
                                             entity={{type: 'opportunity', id: this.state.actualOpportunity.id}}
                                             companyId={this.state.actualOpportunity.companyId}
                                             refreshCloseOpModal={this.refreshCloseOpModal}
                                             hideCreateTaskButton={!this.state.isOpen}
                                />
                                <InteractionTimelineChat opportunity={this.state.actualOpportunity} 
                                                         showUpSalesMessageFn={this.showUpSalesMessage} 
                                                         refresh={this.refresh}
                                                         hideCreateInteractionButton={!this.state.isOpen}
                                />
                              </Col>
                              <Col xs={12} lg={5} xl={4} className="mt-5 mt-lg-0">
                                <RandomOpportunity
                                  changeActualOpportunity={this.changeActualOpportunity}
                                  oportunidad={this.state.actualOpportunity}
                                />
                              </Col>
                            </Row>
                          </Col>
                        )}
                    </Row>
              </Col>
            </Row>}

          </Col>
        </Row>
      </Container>
    );
  }
}

export default DetalleOportunidad;