
import React, {useState, useEffect} from 'react'
import Modal from 'react-bootstrap/Modal'

import moment from 'moment';

import UpSalesMessage from './UpSalesMessage'

import icReasignar from '../assets/img/ic-reasignar.png'

import { getATask, createTask } from '../api_communication/service/ClientService'
import { closeTask } from '../api_communication/service/TaskService';
import { fetchGetOpportunityById, listOpenOpportunitiesForClient } 
  from '../api_communication/service/OpportunityService'

// 1.- resolver tarea
// 2.- pedir al usuario oportunidad a asociar: select
// 3.- copiar contenido tarea vieja/resuelta 
// 4.- Opción al user de modificar fecha
// 5.- crear tarea nueva con ese contenido y asociada a dicha oportunidad elegida por el usuario

export default function ReasignarOpportunityTask(props) {
    
    const [showModal, setShowModal] = useState(false)
    const [opportunities, setOpportunities] = useState([]) // list of opportunities
    const [opportunity, setOpportunity] = useState(null) // selected opportunity by user
    const [toastLevel, setToastLevel] = useState(null)
    const [toastMessage, setToastMessage] = useState(null)
    const [showToast, setShowToast] = useState(false)
    const [autoClose, setAutoClose] = useState(false)

    useEffect(() => {
        async function callOpportunities() {
            let companyId = props.interaction.company.id 
            try {
              let opportunities = await listOpenOpportunitiesForClient(companyId, 0, 1000)
              setOpportunities(opportunities)
            } catch(error){
              console.log(error)
            }
        }
        callOpportunities()
        
      },[]);

  
    const save = async (item) => {
        
        // user must select an opportunity
        if (!opportunity) {
          showUpSalesMessageFn('warning','Debe asociar una oportunidad', true, false)
        } else {
            try {
              let task = await getTask(item)

              await savingNewTask(task)
              solveOldTask(item)
              showUpSalesMessageFn('success', 'Operación realizada con éxito. Refrescando...', true, true)
              setTimeout(() => {
                setShowModal(false)
              props.refresh()
              }, 2000);
              
            } catch(error) {
              console.log(error)
              showUpSalesMessageFn('danger', 'Ha ocurrido un problema. Vuelva a intentarlo.', true, false)
            }
        }
    }

    const savingNewTask = async (item) => {

      let body = {
        content: document.getElementById('textArea').value,
        opportunity: {id: opportunity.id},
        company: {id: opportunity.companyId},
        scheduling:{
          dueDate: item.scheduling.dueDate,
          creationDate: moment().format('DD/MM/YYYY HH:mm:ss')
        },
      }

      try {
        let result = await createTask(body)
      } catch (error) {
        console.log(error)
        showUpSalesMessageFn('danger', 'Ha ocurrido un problema. Vuelva a intentarlo.', true, false)
      }
    }

    const getTask = async (item) => {
      try {
        let task = await getATask(item.id)
        return task
      } catch (error) {
        console.log(error)
      }
    }

    const solveOldTask =  async (item) => {

      let companyId = item.company.id
      let taskId = item.id
      let body = {
        creationDate: moment().format('DD/MM/YYYY HH:mm:ss')
      }

      try {
        let result = await closeTask(companyId, taskId, body)
        return result
      } catch (error) {
        console.log(error)
      }
    }

    const clickOnOpportunity = () => {
        var selectOp = document.getElementById("oportunidadSeleccionada")

        var valorIdOpportunity = selectOp.value
        if (valorIdOpportunity !== 'whitoutOpportunity') {
          obtenerOpportunidad(valorIdOpportunity)
        } else {
          setOpportunity(null)
        }
      }
  
      const obtenerOpportunidad = async(idOpportunity) =>{
        try {
          let llamarOportunidad = fetchGetOpportunityById(idOpportunity)
          .catch(error => {
            console.log(error)
            throw error;
          })
          .then(result => {
            setOpportunity(result)
            return result
          })
        } catch(error) {
          console.log(error)
        }
      }

      const showUpSalesMessageFn = (level, message, show, close) => {
          setToastLevel(level)
          setToastMessage(message)
          setShowToast(show)
          setAutoClose(close)
      }

      const closeMessage = () => {
          setToastLevel(null)
          setToastMessage(null)
          setShowToast(false)
          setAutoClose(false)
      }

    return(
        <React.Fragment>
            <div onClick={() => setShowModal(true)}  title="Reasignar Tarea" className="mouse" xs={1}>
                <img width={25} height={25} src={icReasignar} alt="Reasignar este recordatorio/tarea" title="Reasignar este recordatorio/tarea"/>
            </div>

            <Modal
                id="reasignarModal"
                show={showModal}
                onHide={() => setShowModal(false)}
                dialogClassName="bg-white h-100"
                aria-labelledby="example-custom-modal-styling-title"
                //centered
                backdrop="static"
                keyboard={false}
              >
                <Modal.Header className="justify-content-center modal-border mt-5">
                    <Modal.Title id="example-custom-modal-styling-title" className="dark-blue d-column">
                    <b className="my-3 text-center">
                        REASIGNAR
                    </b>
                    {/* <p className="l-fs-14 light-grey mb-4">"{props.interaction && props.interaction.content}"</p> */}
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body className="mt-4 h-100">
                  <UpSalesMessage
                      level={toastLevel}
                      message={toastMessage}
                      visible={showToast}
                      autoClose={autoClose}
                      closeMessage={closeMessage}
                  />

                  <form className="d-column generalTextInputStyle">
                      {/* TEXTAREA WITH CONTENT */}
                      <textarea style={{width: '90%'}} rows="5" className="my-3" id="textArea" type="text" defaultValue={props.interaction.content}></textarea>
                      {/* USER SELECT OP */}
                      {opportunities && 
                          <select autoFocus={true} 
                                id="oportunidadSeleccionada" 
                                className="custom-select" 
                                style={{width: '90%'}}  
                                onClick={()=>{clickOnOpportunity()}} 
                                placeholder="Debe asociar una de las siguientes oportunidades:" 
                                >
                                <option className="opcion l-fs-14" value="whitoutOpportunity">Sin oportunidad asociada</option>
                                {opportunities.map(item => {
                                  if (item.state === 'OPEN') {
                                    return (
                                      <React.Fragment key={item.id}>
                                          <option className="opcion l-fs-14" value={item.id}>{item.name}</option>
                                      </React.Fragment>
                                    )
                                  }
                                })}
                            </select>}

                      <div className="d-inline-flex my-5">
                          <div className="d-center boton-cancelar mr-3" onClick={()=>setShowModal(false)}>Cancelar</div>
                          <div className="d-center boton-guardar" onClick={()=>save(props.interaction)}>Guardar</div>
                      </div>
                  </form>
                    
                </Modal.Body>
            </Modal>

        </React.Fragment>
    )
}