// --------------------------------------------
// Created by Pablo Collado in 03/12/2020
// --------------------------------------------

// FROM PARENT:
// <VentasVsPresupuestoChart data={company object} year={year} /> 
// PROP data is the client/company object 
// PROP year = actual year

import React, {useState, useEffect} from 'react'

// EXTERNAL LIBRARIES
import {Line} from 'react-chartjs-2';

// BACKEND FUNCTIONS
import {getListSalesForecast, listSalesForGraph} from '../api_communication/service/ClientService'

// COMPONENTS
import LoadingAnimationMiniSize from './LoadingAnimationMiniSize'

const configData = {
    labels: ['Enero', 'Febrero', 'Marzo',
             'Abril', 'Mayo', 'Junio', 'Julio',
            'Agosto', 'Septiembre', 'Octubre', 
            'Noviembre', 'Diciembre'],
    datasets: [
      {
        label: 'Ventas',
        fill: false,
        lineTension: 0,
        backgroundColor: '#A0CBE7',
        borderColor: '#006AC1', 
        borderWidth: 2,
        data: null
      },
      {
        label: 'Presupuesto',
        fill: false,
        lineTension: 0,
        backgroundColor: '#ED255F',
        borderColor: '#ED255F', 
        borderWidth: 2,
        data: null
      },
      {
        label: 'Acumulado',
        fill: false,
        lineTension: 0,
        backgroundColor: 'orange',
        borderColor: 'orange', 
        borderWidth: 2,
        data: null
      },
    ]
  }

export default function VentasVsPresupuestoChart(props) {
    const [loadingAnimation, setLoadingAnimation] = useState(true)
    const [chartData, setChartData] = useState(configData)
    const [showChart, setShowChart] = useState(true)
    const [reDraw, setReDraw] = useState(false)

    useEffect(()=>{
        setLoadingAnimation(true)
        //setReDraw(false)
        let year = props.year
        //console.log('-------------------------------')
        // console.log('  >>> useEffect: props.year = ', year)
        //console.log('configData BEFORE: ', configData)
        let company = props.company
        async function initChart(){
            let initialDate = '01/01/' + year
            let finalDate = '31/12/' + year
            try{
              // INICIALIZAMOS VALORES GRÁFICA A CERO
              let presupuestosFinalData= [0,0,0,0,0,0,0,0,0,0,0,0]
              let ventasFinalData = [0,0,0,0,0,0,0,0,0,0,0,0,0]
              let acumuladoFinalData = [0,0,0,0,0,0,0,0,0,0,0,0]
              configData.datasets[1].data = presupuestosFinalData
              configData.datasets[0].data = ventasFinalData
              configData.datasets[2].data = acumuladoFinalData
              
              // LLAMANOS A PRESUPUESTOS 
              let result = await getListSalesForecast(company.id, 0, 15, initialDate, finalDate)
              //console.log('  >>> getListSalesForecast: ', result)
              let sales = await listSalesForGraph(company.id, year)
              //console.log('  >>> listSalesForGraph: ', sales)

              // Vamos modificando esos 0 por valor del mes. De backend puede ser que 
              // no exista objecto si no hay nada para ese mes
              result.forEach((item) => {
                let month = (parseInt(item.date.split('/')[1])-1)
                presupuestosFinalData[month] = Math.round(item.amount * 10) / 10
              })
              // console.log('  >>> year / presupuestosFinalData: ', year, presupuestosFinalData)
              sales.forEach((item) => {
                let month = (parseInt(item.date.split('/')[1])-1)
                ventasFinalData[month] = Math.round(item.amount * 10) / 10
              })
              // console.log('  >>> year / ventasFinalData: ', year, ventasFinalData)

              // CALCULAMOS EL ACUMULADO - acumulado[i] = ventas[i] + acumulado[i-1]
              for (let i = 0; i < ventasFinalData.length; i++) {
                if(i === 0) {
                  acumuladoFinalData[i] = ventasFinalData[i]
                } else {
                  acumuladoFinalData[i] = ventasFinalData[i] + acumuladoFinalData[i-1]
                }
              }

              // PONEMOS INFO EN configData objeto presupuestos
              configData.datasets[0].data = ventasFinalData
              configData.datasets[1].data = presupuestosFinalData
              configData.datasets[2].data = acumuladoFinalData
              setChartData(configData)
              setLoadingAnimation(false)
              setReDraw(true)
              //setShowChart(true)
            }catch(e){
              console.log(e)
            }
            
        }

        // INICIAMOS 
        if( props.company && props.year) {
          initChart()
        } 

      }, [props.year])

      
      return (
        <div style={{position: 'relative'}}>

        {loadingAnimation ?  <div style={{paddingRight: '0%'}}><LoadingAnimationMiniSize/></div> : <div className="py-2"></div>}
            <div>
              {showChart ? <Line
                data={chartData}
                redraw={reDraw}
                options={{
                    stacked: false,
                    interactive: true,
                    title:{
                        display:false,
                        text:'texto del título',
                        fontSize:16
                    },
                    legend:{ // hace ref a la 'label' 
                        display:true,
                        position:'right'
                    },
                    scales: {
                      y: {
                        ticks: {
                          beginAtZero: true,
                          min: 0
                        },
                        min: 0
                      }
                    },
                    plugins: {
                      datalabels: {
                        display: false
                      },
                      legend: {
                        position: 'top',
                        labels: {
                          padding: 15
                        }
                      },
                    }
                }}
              /> : <p className="text-center light-grey l-fs-14">No hay datos</p>}
            </div>
       
        </div>
      );
}