import React, { useEffect, useContext, createContext } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Dashboard from "../screens/Dashboard";
import Usuarios from "../screens/Usuarios";
import Oportunidad from "../screens/Oportunidad";
import Clientes from "../screens/Clientes";
import DetallesOportunidad from "../screens/DetallesOportunidad";
import DetallesClientes from "../screens/DetallesClientes";
import UsuariosEmpresas from "./usuarios/UsuariosEmpresas";
import DetalleInteraccion from "../screens/DetalleInteraccion";
import Login from "../screens/Login";
import { ContextProvider } from "../context/Context";
import { configBundle } from "../api_communication/bundle/config";
import ProyectosDashboard from "../screens/ProyectosDashboard";
import DetalleProyecto from "../screens/DetalleProyecto";
import Informes from "../screens/Informes";

const App = () => {
  useEffect(() => {
    configBundle();
  });

  return (
    <ContextProvider>
      <BrowserRouter>
        <Switch>
          {/* <Route exact path="/" component={Dashboard}/> */}
          <Route exact path="/dashboard" component={Dashboard} />
          <Route exact path="/" component={Login} />
          <Route
            exact
            path="/DetalleInteraccion"
            component={DetalleInteraccion}
          />
          <Route exact path="/UsuariosEmpresas" component={UsuariosEmpresas} />
          <Route exact path="/Usuarios" component={Usuarios} />
          <Route exact path="/Clientes" component={Clientes} />
          <Route exact path="/DetallesClientes" component={DetallesClientes} />
          <Route exact path="/Oportunidad" component={Oportunidad} />
          <Route exact path="/Proyectos" component={ProyectosDashboard} />
          <Route exact path="/Informes" component={Informes} />
          <Route exact path="/DetalleProyecto" component={DetalleProyecto} />
          <Route
            exact
            path="/DetallesOportunidad"
            component={DetallesOportunidad}
          />
        </Switch>
      </BrowserRouter>
    </ContextProvider>
  );
};

export default App;
