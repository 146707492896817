import React from "react";
import "../assets/css/App.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import pdfIcon from "../assets/img/ic-pdf.png";
import docIcon from "../assets/img/ic-doc.png";
import excelIcon from "../assets/img/ic-excel.png";
import genericIcon from "../assets/img/ic-doc-generico.png";
import {
  saveDocuments,
  addDocsToTask,
} from "../api_communication/service/ClientService";

// UTILS

export const getExtention = (filename) => {
  return /[.]/.exec(filename) ? /[^.]+$/.exec(filename) : undefined;
};

export const returnImages = (array) => {
  //console.log('RETURN IMAGES: array >>> ', array)
  if (Array.isArray(array)) {
    let imagesArray = [];
    array.length !== 0 &&
      array.forEach((element) => {
        // var filename = element.name.split('\\').pop().split('/').pop();
        // var lastIndex = filename.lastIndexOf(".");
        // if (lastIndex >= 1) {
        //   let ext = filename.substr(lastIndex + 1)
        // console.log('MEDIA-MANAGER: returnImages: element: ', element)
        let ext = getExtention(element.fileName);
        if (ext) {
          if (
            ext == "jpg" ||
            ext == "png" ||
            ext == "jpeg" ||
            ext == "bmp" ||
            ext == "gif" ||
            ext == "webp" 
          )
            imagesArray.push(element);
        }
      });
    return imagesArray;
  } else {
    //console.log('NO es un array')
    let ext = getExtention(array.fileName);
    if (ext) {
      if (
        ext == "jpg" ||
        ext == "png" ||
        ext == "jpeg" ||
        ext == "bmp" ||
        ext == "gif"
      )
        return array;
    }
  }
};

export const returnDocs = (array) => {
  if (Array.isArray(array)) {
    let docsArray = [];
    array.forEach((element) => {
      // var filename = element.name.split('\\').pop().split('/').pop();
      // var lastIndex = filename.lastIndexOf(".");
      // if (lastIndex >= 1) {
      //   let ext = filename.substr(lastIndex + 1)
      let ext = getExtention(element.fileName);
      //console.log('MEDIA-MANAGER: returnDocs: ext: ', ext)
      if (ext) {
        if (
          ext == "jpg" ||
          ext == "png" ||
          ext == "jpeg" ||
          ext == "bmp" ||
          ext == "gif" ||
          ext == "webp"        
          ) {
        } else docsArray.push(element);
      }
      // }
    });
    return docsArray;
  } else {
    let ext = getExtention(array.fileName);
    if (ext) {
      if (
        ext == "jpg" ||
        ext == "png" ||
        ext == "jpeg" ||
        ext == "bmp" ||
        ext == "gif"
      ) {
      } else return array;
    }
  }
};

export const returnDocumentIcon = (item) => {
  //console.log('MEDIA-MANAGER: returnDocumentIcon: item.fileName: ', item.fileName)
  // debugger
  if (item.fileName) {
    let ext = getExtention(item.fileName);
    // console.log('MEDIA-MANAGER: returnDocumentIcon: ext: ', ext)
    if (ext) {
      switch (ext[0]) {
        case "doc":
        case "docx":
        case "rtf":
          return docIcon;
        case "xls":
        case "xlt":
        case "xlsx":
        case "xml":
          return excelIcon;
        case "pdf":
          return pdfIcon;
        default:
          return genericIcon;
      }
    } else return genericIcon;
  }
};

export const downloadFile = (url, filename) => {
  // console.log('>>> url, filenam: ', url, filename)
  fetch(url, {
    method: "get",
    headers: {
      Accept: "application/octet-stream",
      "Content-Type": "application/octet-stream",
    },
  })
    .then(async function (t) {
      //console.log('t: ', t)
      if (t.ok) {
        return t.blob().then((b) => {
          var link = document.createElement("a");
          link.href = URL.createObjectURL(b);
          document.body.appendChild(link);
          link.setAttribute("download", filename);
          link.click();
          document.body.removeChild(link);
        });
      } else {
        console.log("FILE NOT FOUND");
      }
    })
    .catch((e) => {
      console.log("ERROR: ", e);
    });
};

export const goDoc = (url) => {
  window.open(url, "_blank");
};

function save2(url) {
  window.open(url, "_blank");

  var a = document.createElement("a");
  a.href = url;
  a.download = "img.png";

  a.click();
}

export const getRelativeUrl = (url) => {
  let result = "";
  let temp = url.split("/");
  // console.log('get relative url > URL.split:  ', temp)
  temp.shift();
  temp.shift();
  temp.shift();
  temp.forEach((item) => {
    result = result + "/" + item;
  });

  return result;
};

// WHEN USER CLICK ON PHOTO
export const ShowImageInModal = (props) => {
  // console.log('MEDIA-MANAGER: ShowImageInModal: props: ', props)
  let docToLoad = "http:" + props.photo.fileDownloadUri + ".pdf";
  /* console.log("docToLoad -> ", docToLoad); */

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {props.doc == "true" && <p>Mostrando documento:</p>}
          {props.doc == "false" && <p>Mostrando foto:</p>}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {props.doc == "true" && (
          <a
            className="w-100"
            id="descargarDoc"
            href={props.photo ? props.photo.fileDownloadUri : props}
          >
            <embed
              width="100%"
              height="600px"
              src={props.photo && docToLoad}
              alt={props.photo ? props.photo.fileName : "Documento ampliado."}
              title={props.photo ? props.photo.fileName : "Documento ampliado."}
              type="application/pdf"
            />
          </a>
        )}
        {props.doc == "false" && (
          <img
            className="img-fluid"
            style={{ maxHeight: 500 }}
            src={props.photo ? props.photo.fileDownloadUri : props}
            alt={props.photo ? props.photo.fileName : "Imagen ampliada."}
            title={props.photo ? props.photo.fileName : "Imagen ampliada."}
          />
        )}
        <h5 className="light-grey l-fs-14 pt-4">
          {props.photo ? props.photo.fileName : "Imagen ampliada."}
        </h5>
      </Modal.Body>
      <Modal.Footer>
        {
          <Button
            variant="outline-primary"
            onClick={() =>
              downloadFile(props.photo.fileDownloadUri, props.photo.fileName)
            }
          >
            {/* //onClick={()=>downloadFile(props.photo.fileDownloadUri, props.photo.fileName)}  */}
            {/* <a href={getRelativeUrl(props.photo.fileDownloadUri)} target="_blank" className="w-100" download={props.photo.fileName}> */}
            <img src={props.photo.fileDownloadUri} width={0} height={0}></img>
            Descargar
            {/* </a> */}
          </Button>
        }
        {/* {props.doc == "false" && <Button variant="outline-primary">
                <a onClick={()=>downloadFile(props.photo.fileDownloadUri, props.photo.fileName)} href={props.photo.fileDownloadUri} target="_blank" className="w-100" download={props.photo.fileName}>
                  <img src={props.photo.fileDownloadUri} width={0} height={0}></img>  
                  Descargar
                </a>
              </Button>} */}
        {/* {props.doc == "false" && <Button variant="outline-primary"><a className="w-100" onClick={()=>downloadFile(props.photo.fileDownloadUri, props.photo.fileName)} download>Descargar</a></Button>} */}
        {/* <Button variant="outline-primary" onClick={()=>alert('Compartir foto por implementar...')}>Compartir</Button> */}
        <Button onClick={props.onHide}>Cerrar</Button>
      </Modal.Footer>
    </Modal>
  );
};

// ------------------------------------------------------------------------------------
// <ShowPhotosAndDocumentsMiniatures documents={this.props.interaction.documents}/>}
// ------------------------------------------------------------------------------------
// ONLY SHOW FILES FROM BACKEND
// ------------------------------------------------------------------------------------
export class ShowPhotosAndDocumentsMiniatures extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      arrayPhotos: null,
      arrayDocs: null,
      modalShow: false,
      photoToShow: null,
      doc: "false",
    };
  }

  componentDidMount() {
    //console.log('MEDIA-MANAGER: ShowPhotosAndDocumentsMiniatures: PROPS: ', this.props)
    if (this.props.documents.length !== 0)
      this.setState({
        arrayPhotos: returnImages(this.props.documents),
        arrayDocs: returnDocs(this.props.documents),
      });
  }

  componentDidUpdate(prevProps) {
    if (this.props.documents.length != prevProps.documents.length) {
      //console.log('MEDIA-MANAGER: ShowPhotosAndDocumentsMiniatures: PROPS > in UPDATE === ', this.props)
      if (this.props.documents.length !== 0)
        this.setState({
          arrayPhotos: returnImages(this.props.documents),
          arrayDocs: returnDocs(this.props.documents),
        });
    }
  }

  showPhotos = (array) => {
    //console.log('MEDIA-MANAGER: showPhotos: array: ', array)
    if (Array.isArray(array)) {
      let result = null;
      if (array.length !== 0)
        result = array.map((element) => {
          // console.log('MEDIA-MANAGER: showPhotos: element: ', element)
          // console.log('MEDIA-MANAGER: showPhotos: element.fileDownloadUri: ', element.fileDownloadUri)
          return (
            <div
              key={element.id}
              onClick={() =>
                this.setState({
                  modalShow: true,
                  photoToShow: element,
                  doc: "false",
                })
              }
            >
              {/* <a onClick={()=>downloadFile(element.fileDownloadUri, element.fileName)} href={element.fileDownloadUri} download={element.fileName}> */}
              <img
                className="imagesIconStyle"
                src={element.fileDownloadUri}
                title={element.fileName}
                alt="Documento adjunto"
              />
              {/* </a> */}
            </div>
          );
        });
      return result;
    } else {
      return (
        <div
          key={array.id}
          onClick={() =>
            this.setState({ modalShow: true, photoToShow: array, doc: "false" })
          }
        >
          <img
            className="imagesIconStyle"
            src={array.fileDownloadUri}
            title={array.fileName}
            alt="Documento adjunto"
          />
        </div>
      );
    }
  };

  showDocs = (array) => {
    //console.log('MEDIA-MANAGER: showDocs: array: ', array)
    if (Array.isArray(array)) {
      let result = null;
      if (array.length !== 0)
        result = array.map((element) => {
          //console.log('MEDIA-MANAGER: showDocs: element: ', element)
          //console.log('MEDIA-MANAGER: showDocs: element.fileDownloadUri: ', element.fileDownloadUri)
          return (
            <div
              className="docsIconStyleContainer"
              key={element.id}
              onClick={() =>
                this.setState({
                  modalShow: true,
                  photoToShow: element,
                  doc: "true",
                })
              }
            >
              <img
                className="docsIconStyle"
                src={returnDocumentIcon(element)}
                title={element.fileName}
                alt="Documento adjunto"
              />
            </div>
          );
        });
      return result;
    } else {
      return (
        <div
          className="docsIconStyleContainer"
          key={array.id}
          onClick={() =>
            this.setState({ modalShow: true, photoToShow: array, doc: "true" })
          }
        >
          <img
            className="docsIconStyle"
            src={returnDocumentIcon(array)}
            title={array.fileName}
            alt="Documento adjunto"
          />
        </div>
      );
    }
  };

  render() {
    //console.log('MEDIA-MANAGER: ShowPhotosAndDocumentsMiniatures: PROPS: ', this.props)
    return (
      <Row className="pt-3 px-4">
        {this.state.photoToShow && (
          <ShowImageInModal
            show={this.state.modalShow}
            onHide={() => this.setState({ photoToShow: null })}
            photo={this.state.photoToShow}
            doc={this.state.doc}
          />
        )}
        {this.state.arrayPhotos && this.showPhotos(this.state.arrayPhotos)}
        {this.state.arrayDocs && this.showDocs(this.state.arrayDocs)}
      </Row>
    );
  }
}

// ------------------------------------------
// PREVIEW PHOTOS AND DOCS functions
// ------------------------------------------

export const isItImageOrDoc = (element) => {
  if (element) {
    let ext = getExtention(element.name);
    if (ext) {
      if (
        ext == "jpg" ||
        ext == "png" ||
        ext == "jpeg" ||
        ext == "bmp" ||
        ext == "gif" ||
        ext == "webp"
      )
        return true;
      else return false;
    }
  }
  return false;
};

// return icon doc for PREVIEW
export const returnDocumentIconForPreview = (item) => {
  // console.log('MEDIA-MANAGER: returnDocumentIconForPreview: item: ', item)
  if (item) {
    // console.log('MEDIA-MANAGER: returnDocumentIconForPreview: item.name: ', item.name)
    let ext = getExtention(item.name);
    // console.log('MEDIA-MANAGER: returnDocumentIconForPreview: ext: ', ext)
    if (ext) {
      switch (ext[0]) {
        case "doc":
        case "docx":
        case "rtf":
          return docIcon;
        case "xls":
        case "xlt":
        case "xlsx":
        case "xml":
          return excelIcon;
        case "pdf":
          return pdfIcon;
        default:
          return genericIcon;
      }
    } else return genericIcon;
  }
};

// Show and delete images and docs selected by user in Preview
export const RenderArrayOfImagesAndDocs = (props) => {
  return props.array.map((element, index) => {
    if (isItImageOrDoc(element)) {
      let newElement;
      //console.log('MEDIA-MANAGER: renderArray: element ', element)
      newElement = URL.createObjectURL(element);
      return (
        <div key={index} className="preview-container pos-r">
          {/* {console.log('MEDIA-MANAGER: renderArray: newElement ', newElement)} */}
          <img className="preview-icon-styles" src={newElement} />
          <div
            className="ic-delete-preview"
            onClick={() => props.deletePreview(element)}
          >
            <span>X</span>
          </div>
        </div>
      );
    } else
      return (
        <div key={index} className="preview-container-doc pos-r">
          {/* {console.log('MEDIA-MANAGER: renderArray: newElement ', newElement)} */}
          <img
            className="preview-icon-styles-doc"
            src={returnDocumentIconForPreview(element)}
          />
          <div
            className="ic-delete-preview-doc"
            onClick={() => props.deletePreview(element)}
          >
            <span>X</span>
          </div>
        </div>
      );
  });
};

// Individual Fn saveImagesAndDocs(array of docs, true or false if it is a Task)
export const saveImagesAndDocs = (array, isItATask) => {
  // console.log('MEDIA-MANAGER: saveImagesAndDocs: array: ', array)
  //debugger
  array.map(async (element) => {
    var imageData = new FormData();
    imageData.append("files", element);
    try {
      if (isItATask === true) {
        await saveDocuments(
          this.props.idCompany,
          this.props.idInteraction,
          imageData
        );
      }
      if (isItATask === false) {
        await addDocsToTask(
          this.props.idCompany,
          this.props.idInteraction,
          imageData
        );
      }
    } catch (error) {
      console.log(error);
    }
  });
  // console.log('CREATE-INTERACTION-MODAL: saveImages(): Guardando images')
  // this.setState({
  //   arrayDocuments: [],
  //   photoToShowInModal: null,
  // })
};

// ------------------------------------------------------------------------------------
// DIC 2020:
//  <ShowPhotosAndDocsPreview
//    isItATask={true or false}
//    imageOrDocToShow={imageOrDocToShow}
//    saveImagesAndDocs={saveImagesAndDocs}
//    idCompany={idCompany}
//    idTaskOrInteraction={interaction ? interaction.id : null}
//    areThereFilesToShow={areThereFilesToShow}
//  />
// ------------------------------------------------------------------------------------
// WHEN USER ADD FILES
// ------------------------------------------------------------------------------------
// PROPS from DashbaordCreateTaskModal: 
// isItATask={props.client && props.client.isTask}  
// imageOrDocToShow={imageOrDocToShow} 
// saveImagesAndDocs={saveImagesAndDocs} 
// idCompany={idCompany} 
// idTaskOrInteraction={taskOrInt ? taskOrInt.id : null}
// uploadingFinished={uploadingFinished} -> added 31/08/22 Pablo Collado - Fn returns when upload has finished
export class ShowPhotosAndDocsPreview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      arrayDocuments: [],
      photoToShowInModal: null,
    };
    this.deletePreview = props.deletePreview;
    this.areThereFilesToShow = props.areThereFilesToShow;
  }

  componentDidMount() {
    let newArray = this.state.arrayDocuments.push(this.props.imageOrDocToShow);
    //console.log('ShowPhotosAndDocsPreview: componentDIDMount: newArray: ', newArray)
    this.setState({
      arrayDocuments: this.state.arrayDocuments,
    });
    this.props.areThereFilesToShow && this.areThereFilesToShow(true);
  }

  componentDidUpdate(prevProps) {
    if (this.props.imageOrDocToShow !== prevProps.imageOrDocToShow) {
      let newArray = this.state.arrayDocuments.push(
        this.props.imageOrDocToShow
      );
      //console.log('componentDID-UPDATE: newArray: ', newArray)
      this.setState({
        arrayDocuments: this.state.arrayDocuments,
      });
      this.props.areThereFilesToShow && this.areThereFilesToShow(true);
    }
    // Inicialmente en el padre pasamos a este componente la prop 'saveImagesAndDocs' = false
    // Al guardar en padre, le pasamos ahora true por lo que es diferente a la anterior y entonces guarda las img
    if (
      this.props.saveImagesAndDocs !== prevProps.saveImagesAndDocs &&
      this.props.saveImagesAndDocs === true
    ) {
      //console.log('MEDIA-MANAGER: : componentDidUpdate', this.props.saveImagesAndDocs)
      this.saveImagesAndDocs(this.state.arrayDocuments);
    }
  }

  saveImagesAndDocs = () => {
    //console.log("MEDIA-MANAGER: ShowPhotosAndDocsPreview: props: ", this.props);
    let array = this.state.arrayDocuments;
    let allOk = true
    //console.log('MEDIA-MANAGER: ShowPhotosAndDocsPreview: SAVE-IMAGES-AND-DOCS > array: ', array)
    array.map(async (element, index) => {
      var imageData = new FormData();
      imageData.append("files", element);
      try {
        if (!this.props.isItATask) {
          let result = await saveDocuments(
            this.props.idCompany,
            this.props.idTaskOrInteraction,
            imageData
          );
          if (result.error) allOk = false
          //console.log('>>> saveImagesAndDocs: saveDocuments result: ', result)
        }
        if (this.props.isItATask) {
          let result = await addDocsToTask(
            this.props.idCompany,
            this.props.idTaskOrInteraction,
            imageData
          );
          //console.log('>>> saveImagesAndDocs: addDocsToTask result: ', result)
          if (result.error) allOk = false
        }
      } catch (error) {
        console.log(error);
      }
      if(index === array.length - 1) {
        this.props.uploadingFinished && this.props.uploadingFinished(allOk)
      }
    });
    
    this.setState({ arrayDocuments: [] });
  };

  deletePreviewFn = (item) => {
    let newArray = [];
    newArray = this.state.arrayDocuments.filter(function (element, index, arr) {
      return element != item;
    });
    this.setState({ arrayDocuments: newArray });
    if (!newArray || newArray.length === 0)
      this.props.areThereFilesToShow && this.areThereFilesToShow(false);
    // console.log('CREATE-INTERACTION-MODAL: DELETE PREVIEW: item: ', item)
    // console.log('CREATE-INTERACTION-MODAL: DELETE PREVIEW: newArray: ', newArray)
  };

  render() {
    //console.log('ShowPhotosAndDocsPreview >>> PROPS: ', this.props)
    //console.log('MEDIA-MANAGER: this.state.arrayDocuments: ', this.state.arrayDocuments)
    //console.log('MEDIA-MANAGER: this.props.imageToShow: ', this.props.imageToShow)
    //console.log('MEDIA-MANAGER: this.props.deletePreview: ', this.props.deletePreview)
    return (
      <Row className="pt-2 px-4">
        {this.state.arrayDocuments.length !== 0 && (
          <Col xs={12}>
            <p className="l-fs-14 light-grey pb-3 text-center">
              AÑADIENDO ADJUNTOS:
            </p>
          </Col>
        )}
        <Col
          xs={12}
          className="d-flex justify-content-center align-items-center"
        >
          <Row>
            {this.state.arrayDocuments && (
              <RenderArrayOfImagesAndDocs
                array={this.state.arrayDocuments}
                deletePreview={this.deletePreviewFn}
              />
            )}
          </Row>
        </Col>
      </Row>
    );
  }
}
