import {
  listCompaniesForAUserRepo,
  listUsersForOrganizationRepo,
  removeUserFromCompanyRepo,
  addProfilePictureRepo,
  updateProfilePictureRepo,
  getProfilePictureThumbnailRepo,
  updateUserPasswordRepo,
  getCurrentUserDataRepo,
  addUserFromCompanyRepo
} from "../repository/UsersRepository";

export const listCompaniesForAUser = async (userId, page, pageSize, query) => {
  try {
    //let url = URL + '/companies?page='+page+'&pageSize=15'+ search
    let response = await listCompaniesForAUserRepo(userId, page, pageSize, query);
    //let responseJson = await response.json();
    //console.log('REPO : findAllClientsRepository: response: ', response)
    //console.log('REPO : findAllClientsRepository: responseJson: ', responseJson)
    return response;
  } catch (error) {
    console.log("ERROR USERS-SERVICES: listCompaniesForAUser: ", error);
    throw Error (error)
  }
};

export const listUsersForOrganization = async (role, page, pageSize, query) => {
  if (!role) role = "";
  try {
    let response = await listUsersForOrganizationRepo(role, page, pageSize, query);
    if (response.error === true) {
      return false
    } else {
      return response;
    }
  } catch (error) {
    console.log("ERROR USERS-SERVICES: listUsersForOrganization: ", error);
    throw Error (error)
  }
};

//UpSales.Users.addUserToCompany(companyId, userId)
export const addUserFromCompany = async (companyId, userId) => {
  try {
    let response = await addUserFromCompanyRepo(companyId, userId);
    return response;
  } catch (error) {
    console.log("ERROR USERS-SERVICES: addUserFromCompany: ", error);
    throw Error (error)
  }
};

//UpSales.Users.removeUserFromCompany(companyId, userId)
export const removeUserFromCompany = async (companyId, userId) => {
  try {
    let response = await removeUserFromCompanyRepo(companyId, userId);
    return response;
  } catch (error) {
    console.log("ERROR USERS-SERVICES: listUsersForOrganization: ", error);
    throw Error (error)
  }
};

// UpSales.Users.addProfilePicture(userId, file)
export const addProfilePicture = async (userId, file) => {
  try {
    let response = await addProfilePictureRepo(userId, file);
    return response;
  } catch (error) {
    console.log("ERROR USERS-REPO: addProfilePictureRepo: ", error);
    throw Error (error)
  }
};

// UpSales.Users.updateProfilePicture(userId, file)
export const updateProfilePicture = async (userId, file) => {
  try {
    let response = await updateProfilePictureRepo(userId, file);
    return response;
  } catch (error) {
    console.log("ERROR USERS-REPO: updateProfilePictureRepo: ", error);
    throw Error (error)
  }
};

// UpSales.Users.updateUserPassword(userId, body)
export const updateUserPassword = async (userId, body) => {
  try {
    let response = await updateUserPasswordRepo(userId, body);
    return response;
  } catch (error) {
    console.log("ERROR USERS-REPO: updateUserPasswordRepo: ", error);
  }
};

export const getCurrentUserData = () => {
  try {
    let data = getCurrentUserDataRepo();
    return data;
  } catch (error) {
    console.log(error);
    throw Error (error)
  }
};

// // UpSales.Company.getProfilePictureThumbnail(userId)
// export const getProfilePictureThumbnail = async (userId) => {
//     try{
//         let response = await getProfilePictureThumbnailRepo(userId)
//         return response
//     } catch(error){
//         console.log('ERROR USERS-REPO: getProfilePictureThumbnail: ', error)
//     }
// }
