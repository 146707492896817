// --------------------------------------------
// Created by Pablo Collado in 03/08/2022
// --------------------------------------------

import React, { useState, useEffect, useRef } from "react";

// EXTERNAL LIBRARIES
import { Pie } from "react-chartjs-2";
import "chartjs-plugin-datalabels";
import { Chart } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";

// COMPONENTS
import LoadingAnimationMiniSize from "../LoadingAnimationMiniSize";

Chart.register(ChartDataLabels);

export default function ReportsPieChart(props) {
  const [loadingAnimation, setLoadingAnimation] = useState(true);
  const [chartData, setChartData] = useState(null);
  const [reDraw, setReDraw] = useState(false);

  useEffect(() => {
    setLoadingAnimation(true);
    setChartData(initChart);
    setLoadingAnimation(false);
    //setReDraw(true);
  }, [props.data]);

  const defaultColours = [
    "#70C8FF",
    "#4BA6DE",
    "#236791",
    "#4068DE",
  ];

  // circulos from backend: object:{email:0, phone:1, photo:4, text:0, total:0}
  const initChart = {
    labels: ['Email', 'Phone', 'Photo', 'Text'],
    datasets: [
      {
        data: [props.data.email !==0 ? props.data.email : null, props.data.phone !==0 ? props.data.phone : null, props.data.photo !==0 ? props.data.photo : null, props.data.text !==0  ? props.data.text : null],
        backgroundColor: defaultColours,
        borderWidth: 1,
      },
      
    ],
  };

  const options = {
    maintainAspectRatio: false,
    legend: {
      display: true,
    },
    layout: {
      padding: {
      },
    },
    // tooltipTemplate: "<%= value + ' %' %>",
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        display: true,
        color: "white",
        font: {
          weight: 'bold',
          size: 12,
        },
        //anchor: 'end',
        align: "center",
        //offset: 50,
        display: "auto",
        //clip: false,
        //rotation: 15,
        formatter: function (value, context) {
            if (value !==null) return context.chart.data.labels[context.dataIndex];
        },
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem, data) {
            // debugger
            var total = tooltipItem.dataset.data.reduce((a, b) => a + b, 0);
            var percentage = parseFloat(
              ((tooltipItem.raw / total) * 100).toFixed(1)
            );
            // return  tooltipItem.label + ': ' + tooltipItem.formattedValue + ' (' + percentage + ' %)'
            return tooltipItem.label + ": " + tooltipItem.formattedValue;
          },
          title: function (tooltipItem, data) {
            return tooltipItem.label;
          },
        },
      },
    },
  };


  return (
    <div style={{ position: "relative" }}>
      {loadingAnimation ? (
        <div style={{ paddingRight: "20%" }}>
          <LoadingAnimationMiniSize />
        </div>
      ) : (
        <div className="py-2"></div>
      )}
      <div>
        {chartData ? (
          <Pie
            id={props.id}
            width={160}
            height={160}
            //plugins={[ChartDataLabels]}
            data={chartData}
            redraw={reDraw}
            options={options}
          />
        ) : (
          <p className="text-center light-grey l-fs-14">No hay datos</p>
        )}
      </div>
    </div>
  );
}
