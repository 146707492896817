import React, { useState, useEffect } from "react";

// COMPONENTS
import LateralMenu from "../componentes/LateralMenu";
import SearchBar from "../componentes/SearchBar";
import LoadingAnimationMiniSize from "../componentes/LoadingAnimationMiniSize";

// EXTERNAL LIBRARIES
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { Link } from "react-router-dom";

// COMPONENTS
import UpSalesMessage from "../componentes/UpSalesMessage";

// COMMON SCRIPTS
import { translateUserRoleToSpanish } from "../componentes/CommonScripts";

// SERVICE
import { listUsersForOrganization } from "../api_communication/service/UsersService";
import UserEditModal from "../componentes/usuarios/UserEdit";

// HIDE LATERAL MENU FUNCTION
import { esconderMenuLateral } from "../assets/js/menuLateral";

//IMAGENES
import btnMas from '../assets/img/btn-mas.png'
import icEditar from "../assets/img/ic-editar-azul.png";
import icVer from "../assets/img/ic-ver.png";
import userImgPlaceholder from "../assets/img/UserAvatarIconPlaceholder.png";


function Rowuser(props) {
  const user = {
    id: props.id,
    roles: props.roles,
    name: props.name,
    photo: props.photo,
    key: props.id,
  };

  

  return (
    <Row className="brd-b-blue py-2 d-flex align-items-center">
      <Col className="col-2 light-grey">
        <div>
          <img className="avatarUsersList" src={props.photo} />
        </div>
      </Col>
      <Col className="col-4 light-grey">{translateUserRoleToSpanish(props.roles)}</Col>
      <Col className="col-4 light-grey">{user.name}</Col>
      <Col className="col-2 light-grey d-flex justify-content-start">
        <div className="mr-3" title="Editar Usuario">
          <img
            onClick={() => props.showModal(user.name, user.id, user.photo)}
            className="mouse"
            src={icEditar}
          />
        </div>
        {(user.roles.includes("SALES_PERSON") || user.roles.includes('ADMINISTRATIVE')) && (
          <div title="Ver Empresas de Usuario">
            <Link to={{ pathname: "/UsuariosEmpresas", state: user }}>
              <img id={user.id + "icVer"} className="mouse" src={icVer} />
            </Link>
          </div>
        )}
      </Col>
    </Row>
  );
}

function UsersList(props) {
  const rowlist = [];
  const showModal = props.showEditUserModal;
  //console.log("UsersList: props: ", props);
  props.listProp &&
    props.listProp.forEach((element) => {
      rowlist.push(
        <Rowuser
          id={element.id}
          roles={element.roles}
          name={element.username}
          photo={element.profilePhoto ? element.profilePhoto : userImgPlaceholder}
          key={element.id}
          showModal={showModal}
        />
      );
    });
  if (props.listProp) {
    return <React.Fragment>{rowlist}</React.Fragment>;
  } else
    return (
      <Col>
        <LoadingAnimationMiniSize />
      </Col>
    );
}

export default function Usuarios() {
  const [list, setList] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [userName, setUserName] = useState(null);
  const [userId, setUserId] = useState(null);
  const [photo, setPhoto] = useState(null);
  const [usersPage, setUsersPage] = useState(0)
  const [usersLoadingSpinner, setUsersLoadingSpinner] = useState(false)
  const [showBtnMoreUsers, setShowBtnMoreUsers] = useState(false)
  const [showErrorUpsalesMsg, setShowUpsalesMsg] = useState(false)

  const PAGE_SIZE = 10

  useEffect(() => {
    document.title = "Usuarios | UpSales";
    
    getUsersData();
  }, []);

  async function getUsersData(page = 0, PAGE_SIZE = 10) {
    try {
      setUsersLoadingSpinner(true)
      const [users, nextUsers] = await Promise.all(
          [listUsersForOrganization("", page, PAGE_SIZE), listUsersForOrganization("", page + 1, PAGE_SIZE)]
      )
      //console.log('get companies user: ', users)
      //console.log('get companies NEXT user: ', nextUsers)
      
      if (!nextUsers.data || nextUsers.data.length === 0) {
        setShowBtnMoreUsers(false)
    } else {
        setShowBtnMoreUsers(true)
    }

    if (users === false || nextUsers === false) {
        setShowUpsalesMsg(true)
        return false
    } else {
        setList([...list,...users.data]);
        setUsersPage(page)
        setUsersLoadingSpinner(false)
    }

    } catch (error) {
      console.log(error);
    }
  }

  const closeMessage = ()=> {
    setShowUpsalesMsg(false)
}

  const showEditUserModalFn = (userName, userId, photo) => {
    setUserName(userName);
    setUserId(userId);
    setPhoto(photo);
    setShowModal(true);
  };

  const hiddeEditUserModalFn = () => {
    setShowModal(false);
  };

  const loadMoreUsers = ()=>{
    getUsersData(usersPage + 1, PAGE_SIZE)
}


  return (
    <Container className="pos-r" fluid={true}>
      <UpSalesMessage
          level={'danger'}
          message={'Ha ocurrido un error cargando los datos.'} 
          visible={showErrorUpsalesMsg} 
          autoClose={false}
          closeMessage={closeMessage} 
      />
      <div
        id="ic-menu-out"
        onClick={() => esconderMenuLateral()}
        className="bg-arrow d-center arrow-menu-out d-none orange-up"
      >
        <p>&gt;</p>
      </div>
      <Row>
        <LateralMenu></LateralMenu>

        <Col className="bg-blue-light">
          <UserEditModal
            userId={userId}
            userName={userName}
            show={showModal}
            photo={photo}
            hide={hiddeEditUserModalFn}
          />

          <Row className="nav-style brd-b-blue bg-white justify-content-center">
            <Col xs={11} className="align-self-center p-0">
              <SearchBar />
            </Col>
          </Row>

          <Row className="d-center bg-blue-light">
            <Col xs={12} className="pt-4 pb-3 ">
              <p className="light-grey"> Usuarios</p>
            </Col>
          </Row>

          <Row className="d-center bg-blue-light">
            <Col xs={12} className="d-flex justify-content-end">
              <div>
                {/* <button type="button" className="modal-big-button btn btn-primary">Agregar Usuario</button> */}
              </div>
            </Col>
          </Row>

          <Row className="d-center bg-blue-light pb-5 px-3">
            <Col xs={12} className="bg-white pb-5">
              <Row className="py-3">
                <Col className="col-2 l-fs-12 font-weigth-bold">AVATAR</Col>
                <Col className="col-4 l-fs-12 font-weigth-bold">ROL</Col>
                <Col className="col-4 l-fs-12 font-weigth-bold">
                  NOMBRE USUARIO
                </Col>
                <Col className="col-2 l-fs-12 font-weigth-bold">OPCIONES</Col>
              </Row>

              <UsersList
                listProp={list}
                showEditUserModal={showEditUserModalFn}
              />

              {!usersLoadingSpinner ? '' : <div className="mt-5"><LoadingAnimationMiniSize /></div>}

              {showBtnMoreUsers && <div className="btn-mas-container row mt-5" onClick={()=>loadMoreUsers()}>
                  <img src={btnMas}></img>
              </div>}

            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}
