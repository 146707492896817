// --------------------------------------------
// Created by Pablo Collado 18/08/2022
// --------------------------------------------

import React, { useState, useEffect, useRef } from "react";
import { Row } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import Col from "react-bootstrap/Col";

import { InteractionModeIcon, InteractionDueDateIcon } from "../CommonScripts";

// BACKEND FUNCTIONS
import { getCurrentUserData } from "../../api_communication/service/UsersService";
import { getProjectActiveInteractions } from "./funcionesProyectos";

// IMAGES
import icProyectos from '../../assets/img/ic-proyecto-nuevo-big.png'
import icAdjunto from "../../assets/img/ic-adjuntar.png";
import ProjectStateIcon from "./ProjectStateIcon";
import ShowTextWithBreakLines from '../commons/ShowTextWithBreakLines'
import upsalesLogo from "../../assets/img/logo.png";

// PROPS
// ProjectDetailsData={ProjectDetailsData}
// isProjectClosedOrArchived={isProjectClosedOrArchived}
// title={pdfTitle}
export default function PrintReportPdf(props) {

  const [interactions, setInteractions] = useState(null)

  useEffect(() => {
    const getInteractions = async () => {
      let interactions
      try {
        interactions = await getProjectActiveInteractions(props.ProjectDetailsData.id, 0, 100000)
        setInteractions(interactions) 
        
        document.title = props.title;
        let headstr = "<html><body>";
        let footstr = "</body>"; 
        let newstr = document.getElementById('showPrintableReport').innerHTML;
        document.body.innerHTML = headstr + newstr + footstr
        document.body.children[0].classList.add('printDialogModal')
        setTimeout(function(){
          window.print();
          document.location.reload();
          return false;
        },1500)
          
      } catch (error) {
        console.log(error)
        //props.showUpSalesMessageFn('danger', 'Ha ocurrido un error al obtener las interacciones', true, false)
      }
    }
    getInteractions()
  }, [props]);

  const showInteractions = () => {
    let rows = []
    interactions.forEach(element =>{
        rows.push(
            <InteractionItem interaction={element} key={element.id} />
          );
    })
    return rows
  }

  const InteractionItem = (props) => {
    return(
      <div className="pagebreak">
        <Row className="row " style={{borderTop: '1px solid #C7E6F9', padding: '3px'}}>
          <Col xs={4}>
            <div className="d-flex flex-row align-items-center justify-content-start">
              <div className="d-flex flex-row align-items-center">
                <img className="mx-3 icon-mode-style" src={InteractionModeIcon(props.interaction.mode)}/>
                <p className="orange-up">
                    {props.interaction.createdBy 
                    ? props.interaction.createdBy.username
                    : ""}
                </p>
              </div>
              <div className="d-flex flex-row align-items-center">
                <div className="">
                  <div className="ml-2 light-grey-text l-fs-12">
                      {props.interaction.date}
                  </div>
                </div>
                <div className="ml-2">
                    {props.interaction.hasDocuments ? (
                    <img width={15} src={icAdjunto} />
                    ) : null}
                </div>
                <div className="ml-2">
                  <InteractionDueDateIcon interaction={props.interaction}/>
                </div>
              </div>
            </div>
          </Col>
            
            <Col xs={8} className="d-flex justify-content-start align-items-center">
              {props.interaction.content}
                {/* <ShowTextWithBreakLines content={props.interaction.content} ></ShowTextWithBreakLines> */}
            </Col>
        </Row>
      </div>
    )
  }

  return (
    <React.Fragment>
      <Modal
        style={{height: '100%',opacity:1}}
        id="showPrintableReport"
        className="modal-border"
        show={true}
        //onHide={() => setShowEditProject(false)}
        fullscreen="true"
        dialogClassName="printDialogModal"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header className="container modal-border px-4 pos-r">
          
          <Modal.Title id="example-custom-modal-styling-title" className="dark-blue  mt-2">
            <div className="d-flex justify-content-start align-items-center ">
              <div className="pl-3"><img src={upsalesLogo} width="35px"/></div>
              <div className="ml-3">{props.title}</div>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-border container">
          
        <Row id="" className="mb-3 l-fs-16">
              <Col className="col-12">
                <Row className="greyColor">
                  <div className="col-12 ">
                    <div className="row px-3 divWithBorder">
                      <div className="col-12">
                        
                        <div className="row pl-3 d-flex justify-content-start align-items-start ">
                          <div className="col-12 pt-3 pb-3 ">
                            <div className="d-flex justify-content-between align-items-center flex-wrap">
                              <div className="d-flex align-items-center mb-2">
                                {props.ProjectDetailsData && (
                                  <ProjectStateIcon
                                    classNames="mr-2"
                                    projectState={props.ProjectDetailsData.state}
                                  />
                                )}
                                <p className="dark-blue">
                                  <b>
                                    {props.ProjectDetailsData &&
                                      props.ProjectDetailsData.name}
                                  </b>
                                </p>
                              </div>
                              <div className="d-flex justify-content-start align-items-start  mb-2">
                                <p className="bolder">Fecha Solicitud:</p>
                                <p className="pl-2">
                                  {props.ProjectDetailsData &&
                                    props.ProjectDetailsData.startingDate}
                                </p>
                              </div>
                              {props.isProjectClosedOrArchived === true && (
                                <div className="d-flex justify-content-start align-items-start  mb-2">
                                  <p className="bolder">Fecha Cierre:</p>
                                  <p className="pl-2">
                                    {props.ProjectDetailsData &&
                                      props.ProjectDetailsData.endDate}
                                  </p>
                                </div>
                              )} 
                              <div className="dark-blue d-flex flex-row align-items-center  mb-2">
                                <div className="mr-2">
                                  <img
                                    id="projectCompanyLogo"
                                    width={30}
                                    src={props.ProjectDetailsData.company.thumbnail}
                                  />
                                </div>
                                <b>
                                  {props.ProjectDetailsData &&
                                    props.ProjectDetailsData.company.alias}
                                </b>
                              </div>

                            </div>
                            {/*<p className=" pt-3">{props.ProjectDetailsData && props.ProjectDetailsData.description}</p>*/}
                            <div className="pt-3">
                              <ShowTextWithBreakLines
                                content={
                                  props.ProjectDetailsData &&
                                  props.ProjectDetailsData.description
                                }
                              ></ShowTextWithBreakLines>
                            </div>
                          </div>

                        </div>
                        
                      </div>
                    </div>
                  </div>
                </Row>

                <Row className="greyColor pt-3">
                  <div className="col-12 ">
                    <div className="row px-3 divWithBorder">
                      <div className="col-12 pb-2 pt-3 bg-white dark-blue text-center pos-r">
                        <b className="">DATOS DEL PROYECTO</b>
                      </div>
                      <div className="col-12 bg-white">
                        <div className="row show" id="datos-proyecto">
                          <div className="col-12 px-4">
                            <p className="title">
                              CARACTERÍSTICAS DEL PRODUCTO
                            </p>
                            <div className="grey-underline"></div>
                            <div className="row px-3 d-flex justify-content-start align-items-start ">
                              <div className="col-6 col-md-4 d-flex justify-content-start align-items-start mb-2">
                                <p className="bolder">Pasta:</p>
                                <p className="pl-3">
                                  {props.ProjectDetailsData &&
                                  props.ProjectDetailsData.paste
                                    ? props.ProjectDetailsData.paste
                                    : "-"}
                                </p>
                              </div>
                              <div className="col-6 col-md-4 d-flex justify-content-start align-items-start mb-2">
                                <p className="bolder">Orígen:</p>
                                <p className="pl-3">
                                  {props.ProjectDetailsData &&
                                  props.ProjectDetailsData.origin
                                    ? props.ProjectDetailsData.origin
                                    : "-"}
                                </p>
                              </div>
                              <div className="col-6 col-md-4 d-flex justify-content-start align-items-start mb-2">
                                <p className="bolder">Formato:</p>
                                <p className="pl-3">
                                  {props.ProjectDetailsData &&
                                  props.ProjectDetailsData.format
                                    ? props.ProjectDetailsData.format
                                    : "-"}
                                </p>
                              </div>
                              <div className="col-6 col-md-4 d-flex justify-content-start align-items-start mb-2">
                                <p className="bolder">Perímetro:</p>
                                <p className="pl-3">
                                  {props.ProjectDetailsData &&
                                  props.ProjectDetailsData.perimeter
                                    ? props.ProjectDetailsData.perimeter
                                    : "-"}
                                </p>
                              </div>
                              <div className="col-6 col-md-4 d-flex justify-content-start align-items-start mb-2">
                                <p className="bolder">Nombre Colores:</p>
                                <p className="pl-3">
                                  {props.ProjectDetailsData &&
                                  props.ProjectDetailsData.colour
                                    ? props.ProjectDetailsData.colour
                                    : "-"}
                                </p>
                              </div>
                              <div className="col-6 col-md-4 d-flex justify-content-start align-items-start mb-2">
                                <p className="bolder">Superficie:</p>
                                <p className="pl-3">
                                  {props.ProjectDetailsData &&
                                  props.ProjectDetailsData.surface
                                    ? props.ProjectDetailsData.surface
                                    : "-"}
                                </p>
                              </div>
                              {props.ProjectDetailsData &&
                                props.ProjectDetailsData.surface === "Relieves" && (
                                  <React.Fragment>
                                    <div className="col-6 col-md-4 d-flex justify-content-start align-items-start mb-2">
                                      <p className="bolder">Nombre Relieve:</p>
                                      <p className="pl-3">
                                        {props.ProjectDetailsData &&
                                        props.ProjectDetailsData.reliefName
                                          ? props.ProjectDetailsData.reliefName
                                          : "-"}
                                      </p>
                                    </div>
                                    <div className="col-6 col-md-4 d-flex justify-content-start align-items-start mb-2">
                                      <p className="bolder">
                                        Número de Relieves:
                                      </p>
                                      <p className="pl-3">
                                        {props.ProjectDetailsData &&
                                        props.ProjectDetailsData.numberOfReliefs
                                          ? props.ProjectDetailsData.numberOfReliefs
                                          : "-"}
                                      </p>
                                    </div>
                                    <div className="col-6 col-md-4 d-flex justify-content-start align-items-start mb-2">
                                      <p className="bolder">
                                        Lector de Relieves:
                                      </p>
                                      <p className="pl-3">
                                        {props.ProjectDetailsData &&
                                        props.ProjectDetailsData.hasReliefReader
                                          ? "Si"
                                          : "No"}
                                      </p>
                                    </div>
                                  </React.Fragment>
                                )}
                              <div className="col-6 col-md-4 d-flex justify-content-start align-items-start mb-2">
                                <p className="bolder">Tipología:</p>
                                <p className="pl-3">
                                  {props.ProjectDetailsData &&
                                  props.ProjectDetailsData.typology
                                    ? props.ProjectDetailsData.typology
                                    : "-"}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 pt-3 pb-2 px-4">
                            <p className="title">TÉCNICA</p>
                            <div className="grey-underline"></div>
                            <div className="row px-3 d-flex justify-content-start align-items-start">
                              <div className="col-6 col-md-3 d-flex justify-content-start align-items-start mb-2">
                                <p className="bolder">Glaze:</p>
                                <p className="pl-3">
                                  {props.ProjectDetailsData &&
                                  props.ProjectDetailsData.glaze
                                    ? props.ProjectDetailsData.glaze
                                    : "-"}
                                </p>
                              </div>
                              <div className="col-6 col-md-3 d-flex justify-content-start align-items-start mb-2">
                                <p className="bolder">Rodillo:</p>
                                <p className="pl-3">
                                  {props.ProjectDetailsData &&
                                  props.ProjectDetailsData.roller
                                    ? "Si"
                                    : "No"}
                                </p>
                              </div>
                              <div className="col-6 col-md-3 d-flex justify-content-start align-items-start mb-2">
                                <p className="bolder">Pantalla:</p>
                                <p className="pl-3">
                                  {props.ProjectDetailsData &&
                                  props.ProjectDetailsData.screen
                                    ? "Si"
                                    : "No"}
                                </p>
                              </div>

                              <div className="col-6 col-md-3 d-flex justify-content-start align-items-start mb-2">
                                <p className="bolder">Inyección:</p>
                                <p className="pl-3">
                                  {props.ProjectDetailsData &&
                                  props.ProjectDetailsData.injection
                                    ? "Si"
                                    : "No"}
                                </p>
                              </div>
                              <div className="col-12 pt-2">
                                <p>Efectos:</p>
                                <div className="grey-underline"></div>
                              </div>
                              <div className="col-6 col-md-3 d-flex justify-content-start align-items-start mb-2">
                                <p className="bolder">Mica:</p>
                                <p className="pl-3">
                                  {props.ProjectDetailsData && props.ProjectDetailsData.mica
                                    ? "Si"
                                    : "No"}
                                </p>
                              </div>
                              <div className="col-6 col-md-3 d-flex justify-content-start align-items-start mb-2">
                                <p className="bolder">Brillo:</p>
                                <p className="pl-3">
                                  {props.ProjectDetailsData &&
                                  props.ProjectDetailsData.shine
                                    ? "Si"
                                    : "No"}
                                </p>
                              </div>
                              <div className="col-6 col-md-3 d-flex justify-content-start align-items-start mb-2">
                                <p className="bolder">Granilla:</p>
                                <p className="pl-3">
                                  {props.ProjectDetailsData &&
                                  props.ProjectDetailsData.grain
                                    ? "Si"
                                    : "No"}
                                </p>
                              </div>
                              <div className="col-6 col-md-3 d-flex justify-content-start align-items-start mb-2">
                                <p className="bolder">Rocker:</p>
                                <p className="pl-3">
                                  {props.ProjectDetailsData &&
                                  props.ProjectDetailsData.rocker
                                    ? "Si"
                                    : "No"}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Row>

                <Row className="greyColor pt-3">
                  <div className="col-12 ">
                      <div className="row px-3">
                        <div className="col-12 pb-2 pt-3 bg-white dark-blue text-center pos-r">
                          <b className="">INTERACCIONES</b>
                        </div>
                        <Col xs={12}>
                          {(interactions && interactions.length !== 0) ? showInteractions() :
                            <p style={{color: 'red', textAlign:'center', paddingTop:23}}>
                            No hay interacciones para mostrar
                            </p>}
                        </Col>
                      </div>
                  </div>
                </Row>
              </Col>
            </Row>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
}
