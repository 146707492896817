// ------------------------------------------
// Last Modified by Pablo Collado in 27/11/2020
// ------------------------------------------

import React, {useState, useEffect} from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import baul from '../assets/img/ic-archivar-gris.png';
import archivar from '../assets/img/ic-archivar-blco.png';
import Media from 'react-bootstrap/Media'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

// COMPONENTES
import { archiveInteraction } from '../api_communication/service/ClientService'
import { archiveTask } from '../api_communication/service/TaskService'
import { canInteractionBeArchived } from '../api_communication/service/InteractionService'
import LoadingAnimation from './LoadingAnimation';

// ----------------------------------------------------------
// <BtnArchivar interaction = {interactionObject} timeline={false}/>
// timeline = true > muestra botón fondo gris / false = solo icono archivar blanco
// ------------------------------------------------------------

const MyVerticallyCenteredModal = (props) => {

  const [mensaje, setMensaje] = useState(null)
  const [fileInteractionDenied, setFileInteractionDenied] = useState(null)
  const [archiving, setArchiving] = useState(false)
  
  useEffect(() => {   
    
    if (props.interaction.isTask !== true) { 
      
      if (canInteractionBeArchived(props.interaction)) {
          setFileInteractionDenied(false)
          setMensaje('¿Estás seguro de que deseas archivar?')
      } else {
          setFileInteractionDenied(true)
          setMensaje('Lo sentimos, para archivar una interacción, no debe de haber pasado 30 minutos desde su creación')
      }
    } else {
      setMensaje('¿Estás seguro de que deseas archivar?')
    }
  },[props.interaction])

  const archive = async(interaction) => {
    
    setArchiving(true)

    let clientId = interaction.company ? interaction.company.id : interaction.companyId
    
    try {
      if (interaction.isTask) {
        await archiveTask(interaction)
        window.location.reload();
      } else {
        await archiveInteraction(clientId, interaction.id)
        window.history.back();
      }

    } catch (error) {
      console.log('BtnArchivar Error : ', error)
    }

  }
  
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      {!archiving && <Modal.Body className="text-center light-grey mt-5 px-1">
        <Row className="justify-content-center">
          <Col xs={10}>
            <p className="l-fs-18">{mensaje}</p>
          </Col>
        </Row>
      </Modal.Body>}
      {archiving && <div>
        <LoadingAnimation text={"Archivando..."} />
        </div>}
      {!archiving && !fileInteractionDenied && <Modal.Footer className="modal-border d-center mb-5 px-1">
        <Button onClick={props.onHide} className="modal-button">No</Button>
        <Button onClick={() => {archive(props.interaction)}} className="modal-button">Si</Button>
       
      </Modal.Footer>}
      {!archiving && fileInteractionDenied && <Modal.Footer className="modal-border d-center mb-5 px-1">
        <Button onClick={()=>props.onHide()} className="modal-button">Cerrar</Button>
        
      </Modal.Footer> }
    </Modal>
  );
}

function App(props) {
  const [modalShow, setModalShow] = useState(false);

  return (
    <ButtonToolbar title="Archivar" className="d-center">
      {props.timeline && <div onClick={() => setModalShow(true)} className="imgContainer bg-grey" >
        <img className="icono-archivar"  src={archivar} title="Archivar interacción" alt="Archivar interacción"></img>
      </div> }
      {!props.timeline && <Media className="mouse mr-2 pt-1" onClick={() => setModalShow(true)}>
        <img src={baul}></img>
      </Media>}
      
      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        interaction={props.interaction}
      />
     
    </ButtonToolbar>
  );
}

class BtnArchivar extends React.Component{
  constructor(props){
    super(props)
    this.state={
      
    }
  }
  
  render() {
    return(
     <App interaction={this.props.interaction}
          timeline={this.props.timeline}
     />
    )
  }
}

export default BtnArchivar;