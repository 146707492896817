import React, { useEffect, useState } from "react";
import "../assets/css/App.css";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

// COMPONENTS
import LateralMenu from "../componentes/LateralMenu";
import SearchBar from "../componentes/SearchBar";
import InteractionsReportList from "../componentes/informes/InteractionsReportList";
import { DynamicSelect } from "../componentes/selects/DynamicSelect";
import InteractionsReportIndicators from "../componentes/informes/InteractionsReportIndicators";
import { DynamicSelectOnlyDropdown } from "../componentes/selects/DynamicSelectOnlyDropdown";
import { CountryDynamicSelect } from "../componentes/selects/CountryDynamicSelect";
import { ProvinceDynamicSelect } from "../componentes/selects/ProvinceDynamicSelect";
import { CountryDynamicSelectReports } from "../componentes/selects/CountryDynamicSelectReports";
import { ProvinceDynamicSelectReports } from "../componentes/selects/ProvinceDynamicSelectReports";
import ReportsPieChart from "../componentes/informes/ReportsPieChart";
import ExportReport from "../componentes/informes/ExportReport";

//CLOSE MENU FUNCTION
import { esconderMenuLateral } from "../assets/js/menuLateral";

// SERVICE
import { getCurrentUserData } from "../api_communication/service/UsersService";
import { listUsersForOrganization, listCompaniesForAUser } from "../api_communication/service/UsersService";
import { findAllClientsService } from "../api_communication/service/ClientService";
import { commercialsInteractionsReportList,clientsInteractionsReportList } from "../api_communication/service/InteractionsReportService";
import { loadReportsIndicators } from "../api_communication/service/ReportsService";
import InteractionsReportDates from "../componentes/informes/InteractionsReportDates";

// IMAGES
import btnMas from '../assets/img/btn-mas.png'
import imgModeText from '../assets/img/ic-interaccion-blue.png'
import imgModePhone from '../assets/img/ic-tel-peq-azul.png'
import imgModeEmail from '../assets/img/ic-correo-blue.png'
import imgModePhoto from '../assets/img/ic-cam-blue.png'

// EXTERNAL LIBRARIES
import moment from 'moment';

moment.locale('es');


export default function Informes() {
    const [userComercialsAccess, setUserComercialsAccess] = useState(null) // if user role = PERSON_SALES 
    const [showExportBtn, setShowExportBtn] = useState(false) // export btn only visible for: 'MANAGER','SALES_DIRECTOR' and 'ADMIN'

    const [comercialListTable, setComercialListTable] = useState([]) // para listar tabla de comercial
    const [clientListTable, setClientsListTable] = useState([]) // para listar tabla de clientes 
    const [showClientTable, setShowClientTable] = useState(true) // muestra u oculta tabla clientes
    const [comercial, setComercial] = useState(null) // state id comercial seleccionado por user (null / valor)
    const [showComercialTable, setShowComercialTable] = useState(true) // muestra u oculta tabla comercial
    const [comercialsArray, setComercialsArray] = useState([]) // para rellenar select comercial
    const [clientsArray, setClientsArray] = useState([]) // para rellenear select clients
    const [client, setClient] = useState(null) // state id cliente seleccionado por user (null / valor)

    const [countryId, setCountryId] = useState(null) // state pais seleccionado por user
    const [provinceId, setProvinceId] = useState(null) // state provincia seleccionado por user
    const [resetProvince, setResetProvince] = useState(false)

    const [indicatorsData, setIndicatorsData] = useState(null)
    const [resetClientSearch, setResetClientSearch] = useState(false) // reset client input search in DynamicSelect.js

    const [initialDate, setInitialDate] = useState(null)
    const [endDate, setEndDate] = useState(null)

    const [clientPaginationSize, setClientPaginationSize] = useState(10)
    const [clientPage, setClientPage] = useState(0)
    const [clientTotalPages, setClientTotalPages] = useState(0)

    const [showExportReportModal, setShowExportReportModal] = useState(false)

    useEffect(() => {
        document.title = "Informes | Upsales"
        async function loadInitialData(){
            let user = await getCurrentUserData();
            let userRolesArray = user.roles//['SALES_PERSON']
            let comercialAccessInfo = false
            if (userRolesArray.find((item) => {return item === 'SALES_PERSON'})) {
                setComercial(user.id)
                setUserComercialsAccess(false)
                setShowComercialTable(false)
            } else {
                comercialAccessInfo = true
                setUserComercialsAccess(true)
            }

            if (userRolesArray.find((item) => {return (item === 'MANAGER' || item ==="SALES_DIRECTOR" || item ==="ADMIN")})) {
                setShowExportBtn(true)
            }
            
            try {
                if (comercialAccessInfo) {
                    let comercialsList = await listUsersForOrganization('SALES_PERSON', 0, 10000)
                    setComercialsArray(comercialsList.data)
                 }
                
                let today1 = moment()
                setEndDate(today1.format("DD/MM/YYYY"))
                let today2 = moment()
                let yesterday = today2.subtract(1, 'days')
                setInitialDate(yesterday.format("DD/MM/YYYY"))
                
                if (comercialAccessInfo) {
                    let comercialList = await commercialsInteractionsReportList(null, 0, 1000, yesterday.format("DD/MM/YYYY"), today1.format("DD/MM/YYYY"), countryId, provinceId)
                    setComercialListTable(comercialList.data)
                }
                let clientList = await clientsInteractionsReportList(!comercialAccessInfo ? user.id : null, 0, clientPaginationSize, yesterday.format("DD/MM/YYYY"), today1.format("DD/MM/YYYY"), countryId, provinceId)
                setClientsListTable(clientList.data)
                setClientPage(0)
                setClientTotalPages(clientList.totalPages)
                loadIndicatorsData(null, !comercialAccessInfo ? user.id : null, yesterday.format("DD/MM/YYYY"), today1.format("DD/MM/YYYY"), countryId, provinceId)
            } catch (error) {
                console.log(error)
            }
        }
        loadInitialData()
    }, []);

    const reloadDataWithDates = async (initialDate,endDate)=>{
        setClientsListTable([])
        setComercialListTable([])
        setIndicatorsData(null)
        try {
            if (userComercialsAccess) {
                let comercialList = await commercialsInteractionsReportList(client, 0, 1000, initialDate, endDate, countryId, provinceId)
                setComercialListTable(comercialList.data)
            }
            let clientList = await clientsInteractionsReportList(comercial, 0, clientPaginationSize, initialDate, endDate, countryId, provinceId)
            setClientsListTable(clientList.data)
            setClientPage(0)
            setClientTotalPages(clientList.totalPages)
            loadIndicatorsData(client, comercial, initialDate, endDate, countryId, provinceId)
        } catch (error) {
            console.log(error)
        }
    }

    const reloadDataWithNewCountryOrProvince = async (country,province)=>{
        // if (country && !province) province = provinceId
        // if (!country && province) country = countryId
        setClientsListTable([])
        setComercialListTable([])
        setIndicatorsData(null)
        try {
            if (userComercialsAccess) {
                let comercialList = await commercialsInteractionsReportList(client, 0, 1000, initialDate, endDate, country, province)
                setComercialListTable(comercialList.data)
            }
            let clientList = await clientsInteractionsReportList(comercial, 0, clientPaginationSize, initialDate, endDate, country, province)
            setClientsListTable(clientList.data)
            setClientPage(0)
            setClientTotalPages(clientList.totalPages)
            loadIndicatorsData(client, comercial, initialDate, endDate, country, province)
        } catch (error) {
            console.log(error)
        }
    }

    // BTN VER MÁS EN TABLA CLIENTES
    const moreClientsTable = async ()=> {
        try {
            let clientList = await clientsInteractionsReportList(comercial, clientPage + 1, clientPaginationSize, initialDate, endDate, countryId, provinceId)
            setClientsListTable(clientList.data)
            if (clientPage < clientTotalPages -1) setClientPage(clientPage + 1)
        } catch (error) {
            console.log(error)
        }
    }

    // CUANDO USER ESCRIBE EN PAÍS PARA RESETEAR PROVINCIAS
    const resetProvinceFn = (trueOrFalse)=>{
        setProvinceId(null)
        setResetProvince(trueOrFalse)
    }

    // --------------------
    // HANDLES
    // --------------------

    // handle select simple
    const handleComercial =  (userOptionSelected) =>{
        filtrarSoloPorComercial(userOptionSelected)
    }

    // handle select con input 
    const handleClient = async (userClientInput, userClientSelect) => {
        // userClientInput: user está escribiendo en input 
        // userClientSelect: user ha seleccionado una opción del desplegable
        setResetClientSearch(false)
        try {
            if (userClientSelect === null) {
                // SI USER BORRA BÚSQUEDA, INICIALIZAMOS TABLAS
                if(!userClientInput || userClientInput.length ===0) {
                    setClient(null)
                    setClientsArray([])
                    setClientsListTable([])
                    // si hay algún comercial seleccionado mostramos solo tabla clientes con todas las empresas de dicho comercial
                    if(comercial) {
                        await filtrarSoloPorComercial(comercial)
                    // si no hay comercial (='TODOS') mostramos las dos tablas como al inicio de la page
                    } else {
                        await filtrarSoloPorComercial(null)
                    }
                }
                // RELLENAMOS DESPLEGABLE
                if (userClientInput && userClientInput.length > 2) {
                    if (comercial) {
                        let result = await listCompaniesForAUser(comercial, 0, clientPaginationSize, userClientInput)
                        setClientsArray(result.data)
                    } else {
                        // findAllClientsRepository = async (page, pageSize, query, countryId, provinceId)
                        let result = await findAllClientsService(0, clientPaginationSize, userClientInput, countryId, provinceId)
                        setClientsArray(result)
                    }
                }
            } else {
                setClient(userClientSelect)
                // si comercial es === 'TODOS'
                if(!comercial) filtrarSoloPorCliente(userClientSelect)
                // si hay algún comercial seleccionado
                if (comercial) filtrarPorComercialYCliente(comercial,userClientSelect)
            }
        } catch (error) {
            console.log(error)
        }
    }

    // handel select con input
    const handleCountry = async (countryIdParam) => {
        setCountryId(countryIdParam)
        reloadDataWithNewCountryOrProvince(countryIdParam, null)
    }

    // handel select con input
    const handleProvince = async (provinceIdParam) => {
        setProvinceId(provinceIdParam)
        reloadDataWithNewCountryOrProvince(countryId,provinceIdParam)
    }


    // ------------------
    // CALLS TO BACK
    // ------------------

    // INDICADORES
    // UpSales.InteractionReport.countByModeKPIs(companyId, userId, dateFrom, dateTo)
    const loadIndicatorsData = async (companyId, userId, dateFrom, dateTo, countryId, provinceId)=>{
        setIndicatorsData(null)
        try {
            let result = await loadReportsIndicators(companyId, userId, dateFrom, dateTo, countryId, provinceId)
            setIndicatorsData(result)
            
        } catch (error) {
            console.log(error)
        }
    }

    // FILTRAR SOLO POR COMERCIAL (clientes lo reseteamos - recibe id comercial)
    const filtrarSoloPorComercial = async (userOptionSelected)=>{
        try {
            // Reseteamos Cliente siempre que user modifica el select comercial
            setClient(null)
            setClientsArray([])
            setClientsListTable([])
            setResetClientSearch(true)
            // si en comercial está la opcion de 'TODOS'
            if (userOptionSelected === 'allOptionsSelectedcomercial' || userOptionSelected === null) {
                loadIndicatorsData(null, null, initialDate, endDate, countryId, provinceId)
                // hay que mostrar las dos tablas (comerciales y clientes)
                if (userComercialsAccess) {
                    setComercialListTable([])
                    let comercialList = await commercialsInteractionsReportList(null, 0, 1000, initialDate, endDate, countryId, provinceId)
                    setComercialListTable(comercialList.data)
                }
                let clientList = await clientsInteractionsReportList(null, 0, clientPaginationSize, initialDate, endDate, countryId, provinceId)
                setClientsListTable(clientList.data)
                setClientPage(0)
                setClientTotalPages(clientList.totalPages)
                setShowClientTable(true)
                setShowComercialTable(true)
                setComercial(null)
            // si en comercial la opcion es !== 'TODOS'
            } else {
                loadIndicatorsData(client, userOptionSelected, initialDate, endDate, countryId, provinceId)
                // Hay que mostrar solo la tabla clientes (mostrando las empresas en las que está el comercial seleccionado)
                // y esconder la de comerciales
                setComercial(userOptionSelected)
                setComercialListTable([])
                setShowComercialTable(false)
                setClientsListTable([])
                setShowClientTable(true)
                let clientList = await clientsInteractionsReportList(userOptionSelected, 0, clientPaginationSize, initialDate, endDate, countryId, provinceId)
                setClientsListTable(clientList.data)
                setClientPage(0)
                setClientTotalPages(clientList.totalPages)
            }
        } catch (error) {
            console.log(error)
        }
    }

    // FILTRAR SOLO POR CLIENTE (comercial = todos - recibe id cliente) 
    const filtrarSoloPorCliente = async (userClientSelect)=>{
        // Ocultamos la tabla clientes
        // tabla comerciales con comerciales que estén en esta empresa seleccionada
        try {
            loadIndicatorsData(userClientSelect, null, initialDate, endDate, countryId, provinceId)
            setClient(userClientSelect)
            setClientsListTable([])
            setComercialListTable([])
            setShowClientTable(false)
            setShowComercialTable(true)
            if (userComercialsAccess) {
                let comercialList = await commercialsInteractionsReportList(userClientSelect, 0, 1000, initialDate, endDate, countryId, provinceId)
                setComercialListTable(comercialList.data)
            }
        } catch (error) {
            console.log(error)
        }
    }

    // FILTRAR POR COMERCIAL Y CLIENTE (recibe )
    const filtrarPorComercialYCliente = async (comercialParam, clienteParam)=>{
        try {
            loadIndicatorsData(clienteParam, comercialParam, initialDate, endDate, countryId, provinceId)
            // ocultamos las dos tablas (comercial y clientes)
            setClientsListTable([])
            setComercialListTable([])
            setShowClientTable(false)
            setShowComercialTable(false)
        } catch (error) {
            console.log(error)
        }
    }

    // RECIBE FECHAS Y RELOAD DATA
    const getDatesFromFilters = (initDate, dateType) => {
        let finalDate = moment(initDate)
        if (initDate) setInitialDate(initDate.format("DD/MM/YYYY"))
        if (dateType === 'yesterday') {
            finalDate.add(1,'day')
            setEndDate(finalDate.format("DD/MM/YYYY"))
        }
        if (dateType === 'lastWeek') {
            finalDate.add(1,'week')
            setEndDate(finalDate.format("DD/MM/YYYY"))
        }
        if (dateType === 'lastMonth') {
            finalDate.add(1,'month')
            setEndDate(finalDate.format("DD/MM/YYYY"))
        }
        // launch reload function with new dates when initDate and endDate not null
        reloadDataWithDates(initDate.format("DD/MM/YYYY"), finalDate.format("DD/MM/YYYY"))
    }

    // EXPORT REPORT TO EXCEL
    const openExportReportModal = ()=>{
        setShowExportReportModal(true)
    }

    const closeExportReportModal = ()=>{
        setShowExportReportModal(false)
    }

    return (
    <Container className="pos-r" fluid={true}>
        <div id="ic-menu-out" onClick={() => esconderMenuLateral()} className="bg-arrow d-center arrow-menu-out d-none orange-up">
            <p>&gt;</p>
        </div>
        <Row>
            <LateralMenu></LateralMenu>
            <Col className="bg-blue-light">
                <Row className="nav-style brd-b-blue bg-white justify-content-center">
                    <Col xs={11} className="align-self-center p-0">
                        <SearchBar />
                    </Col>
                </Row>

                <Row className="justify-content-center bg-blue-light">
                    <Col xs={12} className="pt-4 pb-3 px-3">
                        <p className="light-grey" >{" "}Informes</p>
                    </Col>
                </Row>

                <Row className="justify-content-center bg-blue-light px-3 pb-3">
                    <Col xs={12} className="py-3 bg-white light-grey pt-4">
                        <Row>
                            <Col xs={6}>
                                <Row className="align-items-center ">
                                    {userComercialsAccess && <Col xs={12} md={6}>
                                        <div className="bg-input">
                                        {comercialsArray && <DynamicSelectOnlyDropdown
                                            optionsArray={comercialsArray}
                                            searchTerm={null}
                                            onChange={handleComercial}
                                            inputId={'comercial'}
                                        />}
                                        </div>
                                    </Col>}
                                    <Col xs={12} md={6}>
                                        <div className="bg-input">
                                        <DynamicSelect
                                            optionsArray={clientsArray}
                                            searchTerm={null}
                                            onChange={handleClient}
                                            inputId={'clients'}
                                            inputPlaceholder={'Cliente'}
                                            resetSearch={resetClientSearch}
                                        />
                                        </div>
                                    </Col>
                                
                                    <Col xs={12} md={6}>
                                        <div className="bg-input">
                                        <CountryDynamicSelectReports
                                            onChange={handleCountry}
                                            resetProvinceFn={resetProvinceFn}
                                        />
                                        </div>
                                    </Col>
                                    <Col xs={12} md={6}>
                                        <div className="bg-input">
                                        <ProvinceDynamicSelectReports
                                            onChange={handleProvince}
                                            countryId={countryId}
                                            resetProvince={resetProvince}
                                            resetProvinceFn={resetProvinceFn}
                                        />
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={6} className="bg-white light-grey d-flex align-items-center justify-content-around">
                                <InteractionsReportDates sendDates={getDatesFromFilters}/>
                                {showExportBtn && <div onClick={()=>openExportReportModal()} title="Exportar a Excel" className="modal-big-button d-center btn btn-primary">Exportar a Excel</div>}
                            </Col>
                        </Row>
                        {showExportReportModal && <ExportReport 
                            showExportReportModal={showExportReportModal}
                            closeExportReportModal={closeExportReportModal}
                            dateFrom={initialDate}
                            dateTo={endDate}
                        />}
                    </Col>
                    
                </Row>

                <Row className="bg-blue-light pl-3 pb-3">
                    <Col xs={6} className="py-3 bg-white light-grey">
                        <div className="dark-blue text-center pb-3"><b>INDICADORES</b></div>
                        <InteractionsReportIndicators indicatorsData={indicatorsData}/>
                    </Col>
                    <Col xs={6} className="bg-blue-light light-grey" style={{overflow: 'hidden'}}>
                        <div className="dark-blue text-center py-3 bg-white"><b>GRÁFICA</b></div>
                        <div className="bg-white pb-3 pl-3 h-100">
                        {indicatorsData && indicatorsData.total !==0 && <div className="d-center"><ReportsPieChart data={indicatorsData}/></div>}
                        </div> 
                    </Col>
                </Row>

                {showComercialTable && <Row className="justify-content-center bg-blue-light px-3 pb-3">
                    <div className="col-12 dark-blue py-3 bg-white text-center"><b>COMERCIALES</b></div>
                    <Col xs={12} className="pt-2 px-5 bg-white">
                        <Row className=" brd-b-blue">
                            <Col xs={3} className="d-flex align-items-center">Nombre</Col>
                            <Col className="d-flex align-items-center justify-content-center">
                                <img style={{width:25, height:25, margin:'5px'}} src={imgModeText} alt="Icono Texto" title="Icono Texto"/>
                                Texto
                            </Col>
                            <Col className="d-flex align-items-center justify-content-center">
                                <img style={{width:25, height:25, margin:'5px'}} src={imgModePhone} alt="Icono Teléfono" title="Icono Teléfono"/>
                                Teléfono
                            </Col>
                            <Col className="d-flex align-items-center justify-content-center">
                                <img style={{width:25, height:25, margin:'5px'}} src={imgModeEmail} alt="Icono E-mail" title="Icono E-mail"/>
                                E-mail
                            </Col>
                            <Col className="d-flex align-items-center justify-content-center">
                                <img style={{width:25, height:25, margin:'5px'}} src={imgModePhoto} alt="Icono Foto" title="Icono Foto"/>
                                Foto
                            </Col>
                            <Col className="d-flex align-items-center justify-content-center">Total</Col>
                        </Row>
                    </Col>
                   
                    {comercialListTable.length !==0 ? 
                        <Col xs={12} className="bg-white px-5 pb-5 light-grey">
                            <InteractionsReportList list={comercialListTable} />
                        </Col> : 
                        <Col xs={12} className="py-3 bg-white light-grey text-center">Ningún resultado para mostrar</Col>
                    }
                </Row> }

                {showClientTable && <Row className="justify-content-center bg-blue-light px-3 pb-3">
                    <div className="col-12 dark-blue text-center py-3 bg-white"><b>CLIENTES</b></div>
                    <Col xs={12} className="pt-2 px-5 bg-white">
                        <Row className=" brd-b-blue">
                            <Col xs={3} className="d-flex align-items-center">Nombre</Col>
                            <Col className="d-flex align-items-center justify-content-center">
                                <img style={{width:25, height:25, margin:'5px'}} src={imgModeText} alt="Icono Texto" title="Icono Texto"/>
                                Texto
                            </Col>
                            <Col className="d-flex align-items-center justify-content-center">
                                <img style={{width:25, height:25, margin:'5px'}} src={imgModePhone} alt="Icono Teléfono" title="Icono Teléfono"/>
                                Teléfono
                            </Col>
                            <Col className="d-flex align-items-center justify-content-center">
                                <img style={{width:25, height:25, margin:'5px'}} src={imgModeEmail} alt="Icono E-mail" title="Icono E-mail"/>
                                E-mail
                            </Col>
                            <Col className="d-flex align-items-center justify-content-center">
                                <img style={{width:25, height:25, margin:'5px'}} src={imgModePhoto} alt="Icono Foto" title="Icono Foto"/>
                                Foto
                            </Col>
                            <Col className="d-flex align-items-center justify-content-center">Total</Col>
                        </Row>
                    </Col>
                   
                    {clientListTable.length !==0 ? 
                        <Col xs={12} className="bg-white px-5 pb-5 light-grey">
                            <InteractionsReportList list={clientListTable} />
                            {clientPage < clientTotalPages -1 && <div onClick={moreClientsTable} className="pt-4 w-100 d-center">
                                <img style={{cursor: 'pointer'}} title="Ver más" src={btnMas} />
                            </div>}
                        </Col> : 
                        <Col xs={12} className="py-3 bg-white light-grey text-center">Ningún resultado para mostrar</Col>}
                </Row> 
                }
                <Row className="mb-5"></Row>
            </Col>
            
        </Row>
    </Container>
    );
}


