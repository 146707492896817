import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import defaultLogo from "../../assets/img/logoPorDefecto.png";
import Media from "react-bootstrap/Media";
import btnEconomiaOrange from "../../assets/img/btn-entidad-fiscal-orange.png";
import btnEconomiaGris from "../../assets/img/btn-entidad-fiscal-gris.png";
import btnDatosGeneralesOrange from "../../assets/img/btn-datos-generales-orange.png";
import btnDatosGeneralesGris from "../../assets/img/btn-datos-generales-gris.png";
import btnDatosFinancierosOrange from "../../assets/img/btn-datos-financieros-orange.png";
import btnDatosFinancierosGris from "../../assets/img/btn-datos-financieros-gris.png";
import btnDatosComercialesOrange from "../../assets/img/btn-datos-comerciales-orange.png";
import btnDatosComercialesGris from "../../assets/img/btn-datos-comerciales-gris.png";

export default class DataClientPanel extends React.Component {
  constructor(props) {
    super(props);
    this.changeShowData = props.changeShowData;
    this.openCloseModal = props.openCloseModal;
  }

  render() {
    //onClick={()=>{this.props.showIcons !='all' && this.openCloseModal(this.props.showIcons)}}
    return (
      <div
        id="clientMenu"
        className={
          "client-menu d-center" +
          (this.props.showIcons != "all" ? " mouse" : "")
        }
        onClick={() => {
          this.props.showIcons != "all" &&
            this.openCloseModal(this.props.showIcons);
        }}
      >
        <div
          className="bg-blue-light open-close-button"
          onClick={() => this.openCloseModal(this.props.showIcons)}
        >
          {this.props.showIcons == "all" ? ">" : "<"}
        </div>

        {this.props.showIcons == "all" && (
          <React.Fragment>
            <Media className="d-column ml-3 mr-2  mouse">
              <img
                className="circularHoverWhiteShadow"
                alt="Ver datos generales"
                title="Ver datos generales"
                onClick={() => this.changeShowData("basic")}
                src={
                  this.props.showData == "basic"
                    ? btnDatosGeneralesOrange
                    : btnDatosGeneralesGris
                }
              ></img>
              <Media.Body className="white-color l-fs-12 mt-1">
                GENERAL
              </Media.Body>
            </Media>

            <Media className="d-column mx-2  mouse">
              <img
                className="circularHoverWhiteShadow"
                alt="Ver datos fiscales"
                title="Ver datos fiscales"
                onClick={() => this.changeShowData("fiscal")}
                src={
                  this.props.showData == "fiscal"
                    ? btnEconomiaOrange
                    : btnEconomiaGris
                }
              ></img>
              <Media.Body className="white-color l-fs-12 mt-1">
                FISCAL
              </Media.Body>
            </Media>

            <Media className="d-column mx-2  mouse">
              <img
                className="circularHoverWhiteShadow"
                alt="Ver datos económicos"
                title="Ver datos económicos"
                onClick={() => this.changeShowData("economic")}
                src={
                  this.props.showData == "economic"
                    ? btnDatosFinancierosOrange
                    : btnDatosFinancierosGris
                }
              ></img>
              <Media.Body className="white-color l-fs-12 mt-1">
                ECONÓMICO
              </Media.Body>
            </Media>

            <Media className="d-column ml-2 mr-3  mouse">
              <img
                className="circularHoverWhiteShadow"
                alt="Ver datos comerciales"
                title="Ver datos comerciales"
                onClick={() => this.changeShowData("comercial")}
                src={
                  this.props.showData == "comercial"
                    ? btnDatosComercialesOrange
                    : btnDatosComercialesGris
                }
              ></img>
              <Media.Body className="white-color l-fs-12 mt-1">
                CONTACTOS
              </Media.Body>
            </Media>
          </React.Fragment>
        )}

        {this.props.showIcons == "basic" && (
          <Media className="d-column">
            <img
              src={
                this.props.showData == "basic"
                  ? btnDatosGeneralesOrange
                  : btnDatosGeneralesGris
              }
            ></img>
            <Media.Body className="white-color l-fs-12 mt-1">
              GENERAL
            </Media.Body>
          </Media>
        )}
        {this.props.showIcons == "fiscal" && (
          <Media className="d-column">
            <img
              src={
                this.props.showData == "fiscal"
                  ? btnEconomiaOrange
                  : btnEconomiaGris
              }
            ></img>
            <Media.Body className="white-color l-fs-12 mt-1">FISCAL</Media.Body>
          </Media>
        )}
        {this.props.showIcons == "economic" && (
          <Media className="d-column">
            <img
              src={
                this.props.showData == "economic"
                  ? btnDatosFinancierosOrange
                  : btnDatosFinancierosGris
              }
            ></img>
            <Media.Body className="white-color l-fs-12 mt-1">
              ECONOMIC
            </Media.Body>
          </Media>
        )}
        {this.props.showIcons == "comercial" && (
          <Media className="d-column">
            <img
              src={
                this.props.showData == "comercial"
                  ? btnDatosComercialesOrange
                  : btnDatosComercialesGris
              }
            ></img>
            <Media.Body className="white-color l-fs-12 mt-1">
              CONTACTOS
            </Media.Body>
          </Media>
        )}
      </div>
    );
  }
}
