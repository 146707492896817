import React from 'react';
import '../assets/css/App.css';
import logoUpsales from '../assets/img/logo.png'

// component in parent
/* <UpSalesMessage
   level={this.state.level} -string: 'warning', 'danger' or 'success' - backgroundColor and icon
   message={this.state.message} - string 
   visible={this.state.showToast} - boolean
   autoClose={this.state.autoClose} - boolean - automax close after 5 sec
   closeMessage={closeMessage} // PASSING CLOSE MESSAGE FUNCTION
/> */

// Passing prop to children where we want to have Upsales message
// showUpSalesMessageFn={this.showUpSalesMessage}

// Calling component example from children:
// this.props or props.showUpSalesMessageFn('warning','Editar interacción por implementar...', true, true)

// Functions in parent:
// showUpSalesMessage = (level, message, show, close) => {
//     this.setState({
//       level: level,
//       message: message,
//       visible: show,
//       autoClose: close
//     })
//   }

//   closeMessage = () => {
//     this.setState({
//       level: null,
//       message: null,
//       visible: false,
//       autoClose: false
//     })
//   }

// OR directy in your component:
// <UpSalesMessage
//               level={'warning'}
//               message={customAlertMessage} 
//               visible={customAlertShowClose} 
//               autoClose={false}
//               closeMessage={closeMessage} // PASSING CLOSE MESSAGE FUNCTION
//               callBackAnswerQuestionFn={callBackAnswerQuestion} // Fn que pasamos para que se ejecute cuando user click en ok// OPTIONAL
//               />

// UpSalesMessage Error showUpSalesMessageFn('error',"", true, false)
// Mostrará un mensaje de UpSales por defecto con el siguiente mensaje: Ha ocurrido un problema. Vuelva a intentarlo o contacte con soporte@borealos.com


export default class UpSalesMessage extends React.Component {
  
    constructor (props) {
      super(props)
      this.state = {
        visible: false,
        level: null,
        show: false
      }
      this.closeMessage = props.closeMessage;
    }

    componentDidMount () {
      if (this.props.visible && this.props.autoClose) {
          this.setState({
              visible: true
          })
          setTimeout(() => {
              this.closeMessage()
          }, 5000);
      } else {
          this.setState({
              visible: this.props.visible
          })
      }
    }

    componentDidUpdate (prevProps) {
      
      if (this.props.visible !== prevProps.visible) {
          if (this.props.visible && this.props.autoClose) {
              this.setState({
                  visible: true
              })
              setTimeout(() => {
                  this.closeMessage()
              }, 5000);
          } else {
              this.setState({
                  visible: this.props.visible
              })
          }
         
      }
    }
    
    render () {
      let classes = `d-flex flex-column up-sales-message ${this.props.level == "error" ? "danger" : this.props.level} `
      classes += this.state.visible === true ? 'up-sales-message-visible' : ''
      return (
        <React.Fragment>
          <div className={classes}>
            <div className="d-flex flex-row py-2 pos-r">
                {/* <img className="mr-2 logo mb-1" width={25} height={25} src={logoUpsales}/> */}
                <p className="mensaje">MENSAJE DE UPSALES:</p>
                <div className="close-box" style={{zIndex: 999}} onClick={this.closeMessage}>
                    <span>X</span>
                </div>
            </div>
            <div  className="d-flex flex-row py-3">
                <img className="mr-3" width={20} height={20} src={this.getIcon()}/>
                {this.props.level == "error" ? 
                <div className='white'>
                  <p>Ha ocurrido un problema.</p>
                  <p>Vuelva a intentarlo o contacte con soporte@borealos.com</p>
                </div> : <p className='white'>{this.props.message}</p>}
            </div>
            {this.props.callBackAnswerQuestionFn && <div className="d-flex flex-row py-3 justify-content-end">
              <div className={this.props.level === 'danger' ? 'UpSalesMessageBtnRed' : (this.props.level === 'warning' ? 'UpSalesMessageBtnOrange' : 'UpSalesMessageBtnGreen')} onClick={()=>this.props.callBackAnswerQuestionFn()}>
                OK
              </div>
              <div className={this.props.level === 'danger' ? 'UpSalesMessageBtnRed' : (this.props.level === 'warning' ? 'UpSalesMessageBtnOrange' : 'UpSalesMessageBtnGreen')} onClick={()=>this.closeMessage()}>
                CANCELAR
              </div>
            </div>}
        </div>
        </React.Fragment>
      )
    }
    
    getIcon () {
      switch (this.props.level) {
        case 'warning': return 'http://svgshare.com/i/19x.svg'
        case 'danger': return 'http://svgshare.com/i/19E.svg'
        case 'error': return 'http://svgshare.com/i/19E.svg'
        case 'success': return 'http://svgshare.com/i/19y.svg'
      }
    }
    
    
  }
  
//   UpSalesMessage.propTypes = {
//     visible: React.PropTypes.bool.isRequired,
//     message: React.PropTypes.string.isRequired
//   }
  

  
  