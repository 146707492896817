import { UpSales } from "../bundle/bundle";

export const listCompaniesForAUserRepo = async (userId, page, pageSize, query) => {
  // console.log(
  //   "REPO : listCompaniesForAUser page: ",
  //   page,
  //   " - query: ",
  //   query,
  //   " - userId: ",
  //   userId
  // );
  try {
    let response = await UpSales.Users.listCompaniesForUser(userId,page,pageSize, query);
    return response;
  } catch (error) {
    console.log("ERROR USERS-REPO: listCompaniesForAUser: ", error);
    throw Error (error)
  }
};

export const listUsersForOrganizationRepo = async (role, page, pageSize, query) => {
  try {
    let response = await UpSales.Users.listUsersForOrganization(role,page,pageSize,query);
    return response;
  } catch (error) {
    console.log("ERROR USERS-REPO: listUsersForOrganizationRepo: ", error);
    throw Error (error)
  }
};

//UpSales.Users.addUserToCompany(companyId, userId)
export const addUserFromCompanyRepo = async (companyId, userId) => {
  try {
    let response = await UpSales.Users.addUserToCompany(companyId, userId);
    return response;
  } catch (error) {
    console.log("ERROR USERS-REPO: addUserToCompany: ", error);
    throw Error (error)
  }
};

//UpSales.Users.removeUserFromCompany(companyId, userId)
export const removeUserFromCompanyRepo = async (companyId, userId) => {
  try {
    let response = await UpSales.Users.removeUserFromCompany(companyId, userId);
    return response;
  } catch (error) {
    console.log("ERROR USERS-REPO: listUsersForOrganization: ", error);
    throw Error (error)
  }
};

// UpSales.Users.addProfilePicture(userId, file)
export const addProfilePictureRepo = async (userId, file) => {
  try {
    let response = await UpSales.Users.addProfilePicture(userId, file);
    return response;
  } catch (error) {
    console.log("ERROR USERS-REPO: addProfilePictureRepo: ", error);
    throw Error (error)
  }
};

// UpSales.Users.updateProfilePicture(userId, file)
export const updateProfilePictureRepo = async (userId, file) => {
  try {
    let response = await UpSales.Users.updateProfilePicture(userId, file);
    return response;
  } catch (error) {
    console.log("ERROR USERS-REPO: updateProfilePictureRepo: ", error);
    throw Error (error)
  }
};

export const updateUserPasswordRepo = async (userId, body) => {
  console.log(
    "USERS-REPO: updateUserPasswordRepo: userId y body: ",
    userId,
    body
  );
  try {
    let response = await UpSales.Users.updateUserPassword(userId, body);
    console.log("USERS-REPO: updateUserPasswordRepo RESPONSE: ", response);
    return response;
  } catch (error) {
    console.log("ERROR USERS-REPO: updateUserPasswordRepo: ", error);
    return "error";
  }
};

export const getCurrentUserDataRepo = () => {
  try {
    let data = UpSales.Users.getCurrentUser();
    return data;
  } catch (error) {
    console.log(error);
    throw Error (error)
  }
};
// // UpSales.Company.getProfilePictureThumbnail(userId)
// export const getProfilePictureThumbnailRepo = async (userId) => {
//     console.log('ERROR USERS-REPO: getProfilePictureThumbnail: userId = ', userId)
//     try{
//         let response = await UpSales.Company.getProfilePictureThumbnail(userId)
//         return response
//     } catch(error){
//         console.log('ERROR USERS-REPO: getProfilePictureThumbnail: ', error)
//     }
// }
